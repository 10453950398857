import { HttpMethod, initFetch } from "../../utils/fetcher";
import { adaptedToPrixBase, prixBaseToAdapted } from "../../adapter/prixBaseAdapter";

export const SAVE_SUCCESS_PRIX_BASE = "SAVE_SUCCESS_PRIX_BASE";
export const SAVE_FAIL_PRIX_BASE = "SAVE_FAIL_PRIX_BASE";
export const UPDATE_SUCCESS_PRIX_BASE = "UPDATE_SUCCESS_PRIX_BASE";
export const UPDATE_FAIL_PRIX_BASE = "UPDATE_FAIL_PRIX_BASE";
export const DELETE_SUCCESS_PRIX_BASE = "DELETE_SUCCESS_PRIX_BASE";
export const DELETE_FAIL_PRIX_BASE = "DELETE_FAIL_PRIX_BASE";

export function savePrixBase(jsonData, lot) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`prixBase`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToPrixBase(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.prix_base);
          dispatch({
            type: SAVE_SUCCESS_PRIX_BASE,
            payload: prixBaseToAdapted(res.prix_base),
            lot,
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_PRIX_BASE,
            error: err,
          })
        );
    });
  };
}

export function updatePrixBase(jsonData, idPrixBase, lot) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`prixBase/${idPrixBase}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToPrixBase(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.prix_base);
          dispatch({
            type: UPDATE_SUCCESS_PRIX_BASE,
            payload: prixBaseToAdapted(res.prix_base),
            lot,
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_PRIX_BASE,
            error: err,
          })
        );
    });
  };
}

export function deletePrixBase(idPrixBase, lot) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`prixBase/${idPrixBase}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.prix_base);
          dispatch({
            type: DELETE_SUCCESS_PRIX_BASE,
            payload: prixBaseToAdapted(res.prix_base),
            lot,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_PRIX_BASE,
            error: err,
          });
        });
    });
  };
}

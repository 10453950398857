import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "antd";
import { formatDateToAPP } from "../../utils/tools";
import dayjs from "dayjs";

const ViewMail = (props) => {
  const { location, templatesLangues, typesLots, setNotificationProprietaire } = props;
  const [text, setText] = useState(null);

  useEffect(() => {
    function getTemplate() {
      const langueId =
        location.location_requete.requete_locataire &&
        location.location_requete.requete_locataire.locataire_personne.personne_langue
          ? location.location_requete.requete_locataire.locataire_personne.personne_langue.langue_id
          : null;

      const templateLangue = langueId
        ? templatesLangues.find(
            (item) =>
              item.template_langue_template.template_notification &&
              item.template_langue_langue.langue_id === langueId
          )
        : templatesLangues.find((item) => item.template_langue_template.template_notification);

      let str =
        templateLangue && templateLangue.template_langue_text
          ? templateLangue.template_langue_text
          : "";

      let objet =
        templateLangue && templateLangue.template_langue_titre
          ? templateLangue.template_langue_titre
          : "";

      const locataire = location.location_requete.requete_locataire;
      str = fusionChamps(getMapObjLoc(locataire), str);

      const lot = location.location_lot;
      str = fusionChamps(getMapObjLot(lot, langueId), str);

      const proprietaire = lot.lot_proprietaire;
      str = fusionChamps(getMapObjPro(proprietaire), str);

      str = fusionChamps(getMapObjLct(location), str);

      setText(str);
      setNotificationProprietaire(str, objet);
    }

    function fusionChamps(obj, text) {
      let request = new RegExp(Object.keys(obj).join("|"), "gi");
      return text.replace(request, function (matched) {
        return obj[matched.toLowerCase()];
      });
    }

    const getMapObjLot = (lot, langueId) => {
      const description = lot.lot_descriptions.find(
        (description) => description && description.description_langue.langue_id === langueId
      );

      const typeLot = typesLots.find((type) => type.type_lot_id === lot.lot_type_lot_id);

      let blocMatrice = "";
      lot.lot_matrice?.matrice_ligne.forEach((ligne) => {
        blocMatrice += `${ligne.matrice_ligne_rubrique.rubrique_libelle} : ${parseFloat(
          ligne.matrice_ligne_montant_mois
        ).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}<br />`;
      });

      return {
        "#lot_reference": lot.lot_reference,
        "#lot_code_porte": lot.lot_code_porte ? lot.lot_code_porte : "",
        "#lot_type": typeLot ? typeLot.type_lot_libelle : "",
        "#lot_surface": lot.lot_surface ? lot.lot_surface : "",
        "#lot_etage": lot.lot_etage ? lot.lot_etage : "",
        "#lot_couchages": lot.lot_couchage ? lot.lot_couchage : "",
        "#lot_url": description ? description.description_lot_url : "",
        "#lot_adresse": lot.lot_adresse.adresse_gmaps_adresse,
        "#lot_numero": lot.lot_adresse.adresse_numero,
        "#lot_voie": lot.lot_adresse.adresse_voie,
        "#lot_cp": lot.lot_adresse.adresse_code_postal,
        "#lot_ville": lot.lot_adresse.adresse_ville,
        "#lot_departement": lot.lot_adresse.adresse_departement
          ? lot.lot_adresse.adresse_departement
          : "",
        "#lot_pays": lot.lot_adresse.adresse_pays ? lot.lot_adresse.adresse_pays : "",
        "#lot_situation": lot.lot_adresse.adresse_situation,
        "#lot_depot_garantie": parseFloat(lot.lot_depot_garantie),
        "#lot_bloc_matrice": blocMatrice,
      };
    };

    const getMapObjLoc = (locataire) => {
      return {
        "#locataire_qualite":
          locataire.locataire_personne.personne_qualite &&
          locataire.locataire_personne.personne_qualite.qualite_libelle_long,
        "#locataire_nom": locataire.locataire_personne.personne_nom,
        "#locataire_prenom": locataire.locataire_personne.personne_prenom
          ? locataire.locataire_personne.personne_prenom
          : "",
        "#locataire_adresse": locataire.locataire_personne.personne_adresse.adresse_ville,
        "#locataire_pays": locataire.locataire_personne.personne_adresse.adresse_pays,
      };
    };

    const getMapObjPro = (proprietaire) => {
      return {
        "#proprietaire_qualite":
          proprietaire.proprietaire_personne.personne_qualite &&
          proprietaire.proprietaire_personne.personne_qualite.qualite_libelle_long,
        "#proprietaire_nom": proprietaire && proprietaire.proprietaire_personne.personne_nom,
        "#proprietaire_prenom": proprietaire.proprietaire_personne.personne_prenom
          ? proprietaire.proprietaire_personne.personne_prenom
          : "",
        "#proprietaire_adresse": proprietaire.proprietaire_personne.personne_adresse.adresse_ville,
      };
    };

    const getMapObjLct = (location) => {
      const tarif = location.location_ligne.reduce(
        (a, b) => a + parseFloat(b.location_ligne_montant),
        0
      );

      const montantNet = location.location_ligne
        ? location.location_ligne
            .filter((ligne) => ligne.location_ligne_rubrique.rubrique_type === "P")
            .reduce((a, b) => {
              let sum = 0;
              b.location_ligne_rubrique.rubrique_signe === "-"
                ? (sum = a - parseFloat(b.location_ligne_montant))
                : (sum = a + parseFloat(b.location_ligne_montant));
              return sum;
            }, 0)
        : 0;

      let occupants = "";
      location.location_occupant.forEach((occupant) => {
        occupants += `\n ${occupant.occupant_nom} ${occupant.occupant_prenom}`;
        // `née le ${occupant.occupant_date_naissance} à ${occupant.occupant_lieu_naissance}`
      });

      return {
        "#location_id": location.location_id,
        "#location_lot_reference": location.location_lot.lot_reference,
        "#location_date_debut": formatDateToAPP(location.location_date_debut),
        "#location_date_fin": formatDateToAPP(location.location_date_fin),
        "#location_nombre_adultes": location.location_nb_adultes,
        "#location_nombre_enfants": location.location_nb_enfants,
        "#location_nombre_bebes": location.location_nb_bebes,
        "#location_nombre_nuits": dayjs(location.location_date_fin).diff(
          dayjs(location.location_date_debut),
          "days"
        ),
        "#location_desiderata": location.location_desiderata,
        "#location_tarif": parseFloat(tarif).toFixed(2),
        "#location_montant_net": parseFloat(montantNet).toFixed(2),
        "#location_occupants": occupants,
      };
    };

    getTemplate();
  }, [location, templatesLangues, typesLots, setNotificationProprietaire]);

  const getHtml = () => {
    return { __html: text };
  };

  return (
    <Row>
      <Col span={24}>
        <Card size="small" title="Visualisation du mail">
          <div dangerouslySetInnerHTML={getHtml()} />
        </Card>
      </Col>
    </Row>
  );
};

export default ViewMail;

import {
  LOAD_SUCCESS_PARCOURS_CLIENTS,
  LOAD_FAIL_PARCOURS_CLIENTS,
  SAVE_SUCCESS_PARCOURS_CLIENTS,
  SAVE_FAIL_PARCOURS_CLIENTS,
  UPDATE_SUCCESS_PARCOURS_CLIENTS,
  UPDATE_FAIL_PARCOURS_CLIENTS,
  DELETE_SUCCESS_PARCOURS_CLIENTS,
  DELETE_FAIL_PARCOURS_CLIENTS,
} from "../actions/parcoursClientAction";

const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS_PARCOURS_CLIENTS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };
    case LOAD_FAIL_PARCOURS_CLIENTS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error,
      };
    case SAVE_SUCCESS_PARCOURS_CLIENTS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: [...state.data, action.payload],
        error: null,
      };
    case SAVE_FAIL_PARCOURS_CLIENTS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_PARCOURS_CLIENTS:
      const updatedListe = [];
      state.data.forEach((item) => {
        if (
          item.parcours_client_etape.etape_id ===
            action.payload.parcours_client_etape.etape_id &&
          item.parcours_client_groupe.groupe_id ===
            action.payload.parcours_client_groupe.groupe_id
        ) {
          item = action.payload;
        }
        updatedListe.push(item);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: updatedListe,
        error: null,
      };
    case UPDATE_FAIL_PARCOURS_CLIENTS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case DELETE_SUCCESS_PARCOURS_CLIENTS:
      const filtered = state.data.filter((item) => {
        // check sur l'étape uniquement, le groupe est forcément le même
        return item.parcours_client_etape.etape_id !== action.idE;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filtered,
        error: null,
      };
    case DELETE_FAIL_PARCOURS_CLIENTS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    default:
      return state;
  }
}

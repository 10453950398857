module.exports = {
  schedulerConfig: {
    // Bryntum Scheduler config options
    // viewPreset: "weekAndDayLetter",
    viewPreset: {
      name: "weekAndDayLetter",
      timeResolution: {
        unit: "day",
        increment: 1,
      },
      headers: [
        {
          unit: "week",
          dateFormat: "MMM YYYY",
        },
        {
          unit: "day",
          dateFormat: "dd",
        },
        {
          unit: "day",
          dateFormat: "DD",
        },
      ],
    },
    weekStartDay: 1,
    columns: [{ type: "tree", text: "LOT", field: "name", width: 100 }],
    // rowHeight: 50,
    eventTooltipFeature: {
      // A custom HTML template
      template: (data) => {
        return data.eventRecord.eventMenage
          ? `
            <p>Statut: ${data.eventRecord.etat}</p>
            <p>Type: ${data.eventRecord.type ? data.eventRecord.type : "Non défini"}</p>
            <p>Agent: ${data.eventRecord.agent ? data.eventRecord.agent : "Non défini"}</p>
            <p>Heure: ${data.eventRecord.heure}</p>
            ${
              data.eventRecord.location_nb_personnes
                ? `<p>Nombre de couchages de la location: ${data.eventRecord.location_nb_personnes} 
              (dont ${data.eventRecord.location_nb_adultes} adultes / ${data.eventRecord.location_nb_enfants} enfants / ${data.eventRecord.location_nb_bebes} bebes)</p>`
                : ""
            }
            ${data.eventRecord.remarques ? `<p>Remarques: ${data.eventRecord.remarques}</p>` : ""}
          `
          : `
            <p>Statut: ${data.eventRecord.etat}</p>
            <p>${data.eventRecord.name} du ${data.eventRecord.debut} au ${data.eventRecord.fin} (${data.eventRecord.nuits} nuits) </p>
            <p>Heure d'arrivée: ${data.eventRecord.heureArrivee}</p>
            <p>Heure de départ: ${data.eventRecord.heureDepart}</p>
            <p>Lot: ${data.eventRecord.lot.lot_reference}</p>
            <p>Nombre de couchages: ${data.eventRecord.location_nb_personnes} 
              (dont ${data.eventRecord.location_nb_adultes} adultes / ${data.eventRecord.location_nb_enfants} enfants / ${data.eventRecord.location_nb_bebes} bebes)</p>
            <p>Remarques: ${data.eventRecord.remarques}</p>
          `;
      },
    },
    eventMenuFeature: {
      items: {
        copyEvent: false,
        cutEvent: false,
        deleteEvent: false,
        unassignEvent: false,
        // extraItem: {
        //   text: "?",
        //   onItem({ eventRecord }) {
        //     eventRecord.flagged = true;
        //   },
        // },
      },
    },
    scheduleMenuFeature: false,
    eventDragFeature: false,
    eventDragCreateFeature: false,
    eventResizeFeature: false,
    eventEditFeature: false,
    nonWorkingTimeFeature: true,
  },
};

import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Form, Row, Select } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
// import FormAgent from "../FormAgent";

import { getInfoAgent } from "../../utils/tools";
import FormAgent from "../FormAgent";

const { Option, OptGroup } = Select;

const SelectAgent = (props) => {
  const [personne, setPersonne] = useState(null);
  const [edition, setEdition] = useState(false);
  const [code, setCode] = useState(null);
  const {
    utilisateur,
    readOnly,
    personneProps,
    loading,
    societes,
    etablissements,
    qualites,
    langues,
    typesPaiements,
    listData,
    parametre,
  } = props;

  // Référence du formulaire
  const formRefPersonne = useRef();

  useEffect(() => {
    listData && personneProps ? onSelectPer(personneProps.agent_id) : getCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listData, personneProps]);

  function onClickAddPer() {
    setPersonne(null);
    getCode();
    setEdition(true);
  }

  function disablePersonne(personne) {
    onClickAddPer();
    props.archivePersonne(personne.agent_id);
  }

  function onSelectPer(id) {
    formRefPersonne.current.setFieldsValue({ personne_id: id });
    const personne = props.listData?.find((e) => e.agent_id === id);
    setPersonne(personne);
    setEdition(false);
  }

  function getCode() {
    setCode(generateCodeAgent());
  }

  function generateCodeAgent() {
    let maxCode = 0;
    let maxNum = 0;
    let code = "";
    props.listData &&
      props.listData.forEach((item) => {
        if (parseInt(item.agent_code) > parseInt(maxCode)) maxCode = item.agent_code;
      });
    maxNum = parseInt(maxCode) + 1;
    for (var i = 0; i < 6 - maxNum.toString().length; i++) {
      code = code + "0";
    }
    return code + maxNum;
  }

  const addPersonneButton = (
    <Button type="primary" htmlType="reset" onClick={onClickAddPer}>
      <PlusOutlined />
    </Button>
  );

  const archiverPersonne = (
    <Button type="primary" htmlType="reset" onClick={() => disablePersonne(personne)}>
      <DeleteOutlined />
    </Button>
  );

  return (
    <div style={{ padding: 20 }}>
      <Form ref={formRefPersonne}>
        <Row>
          <Col span={11}>
            <Form.Item name="personne_id">
              <Select
                showSearch
                disabled={readOnly}
                optionLabelProp="label"
                placeholder={"Agent"}
                onSelect={onSelectPer}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <OptGroup label={"Agent"}>
                  {listData &&
                    listData.map((item, i) => {
                      return (
                        <Option key={i} value={item.agent_id} label={getInfoAgent(item)}>
                          <Row>
                            <Col sm={8}>{getInfoAgent(item)}</Col>
                          </Row>
                        </Option>
                      );
                    })}
                </OptGroup>
              </Select>
            </Form.Item>
          </Col>
          <Col span={1}>{!readOnly && <Form.Item>{addPersonneButton}</Form.Item>}</Col>
          <Col span={1}>{!readOnly && personne && <Form.Item>{archiverPersonne}</Form.Item>}</Col>
        </Row>
      </Form>
      {(personne || code) && (
        <FormAgent
          readOnly={readOnly}
          edition={edition}
          code={code}
          utilisateur={utilisateur}
          personne={personne}
          loading={loading}
          societes={societes}
          etablissements={etablissements}
          qualites={qualites}
          langues={langues}
          typesPaiements={typesPaiements}
          parametre={parametre}
          handleSubmit={props.savePersonne}
          handleEdit={props.updatePersonne}
        />
      )}
    </div>
  );
};

export default SelectAgent;

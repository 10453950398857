import React, { Component } from "react";

import { Row, Col, Card, Form, Switch, Dropdown, Modal, Input, Button, Tooltip } from "antd";

import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";

import { toast } from "react-toastify";

class FormEquipement extends Component {
  state = {
    size: 0,
    previewVisible: false,
    previewTitre: "",
    inputEquipement: "",
    equipement: null,
  };

  componentDidMount() {
    this.setState({
      size: Math.trunc(24 / this.props.eachRow),
    });
  }

  handleChangeSwitch = (value, equipement) => {
    if (this.props.saveLotsEquipements && this.props.deleteLotsEquipements) {
      value
        ? this.props.saveLotsEquipements(equipement)
        : this.props.deleteLotsEquipements(equipement);
    }
  };

  handleAjouter = () => {
    this.props
      .saveEquipement({
        equipement_libelle: this.state.inputEquipement,
        equipement_groupement_id: this.props.utilisateur.groupement_id,
      })
      .then((res) => {
        this.setState({ previewVisible: false, inputEquipement: "" });
        toast.success("Équipement créé !", {
          containerId: "A",
        });
      })
      .catch((err) => {
        toast.error("Échec de la création !", {
          containerId: "A",
        });
      });
  };

  handleEditer = () => {
    this.props
      .updateEquipement(
        {
          equipement_libelle: this.state.inputEquipement,
        },
        this.state.equipement.equipement_id
      )
      .then((res) => {
        this.setState({
          previewVisible: false,
          inputEquipement: "",
          equipement: null,
        });
        toast.success("Équipement modifié !", {
          containerId: "A",
        });
      })
      .catch((err) => {
        toast.error("Échec de la modification !", {
          containerId: "A",
        });
      });
  };

  handleSupprimer = () => {
    this.props
      .deleteEquipement(this.state.equipement.equipement_id)
      .then((res) => {
        this.setState({
          previewVisible: false,
          previewTitre: "",
          equipement: null,
        });
        toast.success("Équipement supprimé !", {
          containerId: "A",
        });
      })
      .catch((err) => {
        toast.error("Échec de la suppression !", {
          containerId: "A",
        });
      });
  };

  addEquipement = () => {
    this.setState({ previewVisible: true, previewTitre: "Ajouter" });
    this.props.formRef.current.setFieldsValue({ input_equipement: "" });
  };

  editEquipement = (equipement) => {
    this.setState({
      previewVisible: true,
      previewTitre: "Editer",
      inputEquipement: equipement.equipement_libelle,
      equipement,
    });

    this.props.formRef.current.setFieldsValue({
      input_equipement: equipement.equipement_libelle,
    });
  };

  deleteEquipement = (equipement) =>
    this.setState({
      previewVisible: true,
      previewTitre: "Supprimer",
      equipement,
    });

  onChangeInput = (e) => {
    this.setState({ inputEquipement: e.target.value });
  };

  handleCancel = () => {
    this.setState({ previewVisible: false, previewTitre: "" });
  };

  getMenu = (equipement) => {
    return [
      {
        key: "1",
        label: "Nouvel équipement",
        icon: <PlusOutlined />,
        onClick: () => this.addEquipement(),
      },
      {
        key: "2",
        label: "Modifier",
        icon: <EditOutlined />,
        onClick: () => this.editEquipement(equipement),
        disabled: !equipement,
      },
      {
        key: "3",
        label: "Supprimer",
        icon: <DeleteOutlined />,
        onClick: () => this.deleteEquipement(equipement),
        disabled: !equipement,
      },
    ];
  };

  render() {
    const { size, previewVisible, previewTitre } = this.state;
    const {
      isArchive,
      equipements,
      fromAppt,
      // lot
    } = this.props;

    const footer = (
      <div>
        <Button type="primary" danger onClick={this.handleCancel}>
          Annuler
        </Button>
        <Button type="primary" onClick={this["handle" + previewTitre]}>
          {previewTitre}
        </Button>
      </div>
    );

    const switchItem = (item, i) => (
      <Tooltip placement="top" title={item.equipement_libelle}>
        <Form.Item
          name={`equipement_${item.equipement_id}`}
          initialValue={false}
          valuePropName="checked"
        >
          <Switch
            disabled={isArchive}
            className="switch-equipement"
            checkedChildren={item.equipement_libelle}
            unCheckedChildren={item.equipement_libelle}
            onChange={(value) => this.handleChangeSwitch(value, this.props.equipements[i])}
          />
        </Form.Item>
      </Tooltip>
    );

    return (
      <div>
        <Card size="small" type="inner" bordered={false}>
          <Row>
            {equipements &&
              equipements.map((item, i) => {
                return (
                  <Col md={size} key={i}>
                    {fromAppt ? (
                      <Dropdown menu={{ items: this.getMenu(item) }} trigger={["contextMenu"]}>
                        {switchItem(item, i)}
                      </Dropdown>
                    ) : (
                      <div>{switchItem(item, i)}</div>
                    )}
                  </Col>
                );
              })}
            {equipements && equipements.length === 0 && (
              <Dropdown menu={{ items: this.getMenu(null) }} trigger={["contextMenu"]}>
                <div
                  className="site-dropdown-context-menu"
                  style={{
                    textAlign: "center",
                    height: 100,
                    lineHeight: "100px",
                    backgroundColor: "#e9e9e9",
                  }}
                >
                  Clic droit pour ajouter un nouvel équipement
                </div>
              </Dropdown>
            )}
          </Row>
        </Card>
        <Modal
          open={previewVisible}
          title={previewTitre}
          onCancel={this.handleCancel}
          footer={footer}
        >
          <Row>
            {previewTitre !== "Supprimer" ? (
              <Form.Item name={"input_equipement"}>
                <Input addonBefore={"Libellé"} onChange={this.onChangeInput} />
              </Form.Item>
            ) : (
              <div style={{ fontSize: 16 }}>
                Supprimer cet équipement entraînera sa suppression pour <b>TOUS</b> les appartements
              </div>
            )}
          </Row>
        </Modal>
      </div>
    );
  }
}

export default FormEquipement;

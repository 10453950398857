import {
  LOAD_SUCCESS_FRAIS_AGENCE,
  LOAD_FAIL_FRAIS_AGENCE,
  SAVE_SUCCESS_FRAIS_AGENCE,
  SAVE_FAIL_FRAIS_AGENCE,
  UPDATE_SUCCESS_FRAIS_AGENCE,
  UPDATE_FAIL_FRAIS_AGENCE,
  DELETE_SUCCESS_FRAIS_AGENCE,
  DELETE_FAIL_FRAIS_AGENCE,
} from "../actions/fraisAgenceAction";

const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS_FRAIS_AGENCE:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };
    case LOAD_FAIL_FRAIS_AGENCE:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error,
      };
    case SAVE_SUCCESS_FRAIS_AGENCE:
      const liste = state.data.concat(action.payload);
      return {
        ...state,
        loading: false,
        loaded: false,
        data: liste,
        error: null,
      };
    case SAVE_FAIL_FRAIS_AGENCE:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_FRAIS_AGENCE:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };
    case UPDATE_FAIL_FRAIS_AGENCE:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case DELETE_SUCCESS_FRAIS_AGENCE:
      const filtered = state.data.filter((item) => {
        return item.groupe_id !== action.id;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filtered,
        error: null,
      };
    case DELETE_FAIL_FRAIS_AGENCE:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    default:
      return state;
  }
}

import React from "react";
import { EyeOutlined } from "@ant-design/icons";
import { Button } from "antd";

export const columns = (onClickAfficher) => [
  {
    title: "#",
    dataIndex: "requete_id",
    key: "requete_id",
    sorter: (a, b) => parseInt(a.requete_id) - parseInt(b.requete_id),
    width: 100,
  },
  {
    title: "Locataire",
    dataIndex: "requete_locataire",
    key: "requete_locataire",
    render: (locataire) => (
      <>{`${locataire.locataire_personne.personne_nom} ${locataire.locataire_personne.personne_prenom}`}</>
    ),
  },
  {
    title: "Groupe",
    dataIndex: "requete_groupe",
    key: "requete_groupe",
    render: (groupe) => groupe?.groupe_libelle,
  },
  {
    title: "Provenance",
    dataIndex: "requete_provenance",
    key: "requete_provenance",
    render: (provenance) => provenance?.provenance_libelle,
  },
  {
    title: "Afficher",
    dataIndex: "requete_id",
    key: "requete_id",
    render: (requete_id, requete) => (
      <Button onClick={() => onClickAfficher(requete)}>
        <EyeOutlined />
      </Button>
    ),
    width: 100,
  },
];

export function champsPersosToAdapted(items) {
  return items
    ? items.map((item) => {
        return champPersoToAdapted(item);
      })
    : null;
}

export function champPersoToAdapted(item) {
  return item
    ? {
        champ_perso_id: item.id,
        champ_perso_libelle: item.libelle,
        champ_perso_type: item.type,
        champ_perso_ordre: item.ordre,
        champ_perso_visible: item.visible,
        champ_perso_groupe: item.groupe,
        champ_perso_groupe_type: item.groupe_type,
      }
    : null;
}

export function adaptedToChampsPersos(items) {
  return items
    ? items.map((item) => {
        return adaptedToChampPerso(item);
      })
    : null;
}

export function adaptedToChampPerso(item) {
  return item
    ? {
        id: item.champ_perso_id,
        libelle: item.champ_perso_libelle,
        type: item.champ_perso_type,
        ordre: item.champ_perso_ordre,
        visible: item.champ_perso_visible,
        groupe_id: item.champ_perso_groupe_id,
      }
    : null;
}

import { LOAD_SUCCESS_AUTH, LOAD_FAIL_AUTH } from "../actions/utilisateurAction";

const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS_AUTH:
      const utilisateur = action.payload;
      const { utilisateur_etablissement } = utilisateur;
      const { etablissement_societe } = utilisateur_etablissement;
      const { societe_groupement } = etablissement_societe;

      action.payload = {
        ...utilisateur,
        key: 0,
        groupement_id: societe_groupement.groupement_id,
        groupement_logo_width: societe_groupement.groupement_logo_width,
        groupement_logo_height: societe_groupement.groupement_logo_height,
        societe_id: etablissement_societe.societe_id,
        etablissement_id: utilisateur_etablissement.etablissement_id,
      };

      return {
        ...state,
        loaded: true,
        data: action.payload,
        error: null,
      };
    case LOAD_FAIL_AUTH:
      return {
        ...state,
        loaded: false,
        data: null,
        error: action.error,
      };
    default:
      return state;
  }
}

import { HttpMethod, initFetch } from "../../utils/fetcher";

export const SAVE_SUCCESS_LOT_EQUIPEMENT = "SAVE_SUCCESS_LOT_EQUIPEMENT";
export const SAVE_FAIL_LOT_EQUIPEMENT = "SAVE_FAIL_LOT_EQUIPEMENT";
export const DELETE_SUCCESS_LOT_EQUIPEMENT = "DELETE_SUCCESS_LOT_EQUIPEMENT";
export const DELETE_FAIL_LOT_EQUIPEMENT = "DELETE_FAIL_LOT_EQUIPEMENT";

export function saveLotsEquipements(idLot, idEquipement) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(
        `lotsEquipements/${idLot}/${idEquipement}`,
        HttpMethod.POST
      );
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.lot_equipement);
          dispatch({
            type: SAVE_SUCCESS_LOT_EQUIPEMENT,
            payload: res.lot_equipement,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: SAVE_FAIL_LOT_EQUIPEMENT,
            error: err,
          });
        });
    });
  };
}

export function deleteLotsEquipements(idLot, idEquipement) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(
        `lotsEquipements/${idLot}/${idEquipement}`,
        HttpMethod.DELETE
      );
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.lot_equipement);
          dispatch({
            type: DELETE_SUCCESS_LOT_EQUIPEMENT,
            payload: res.lot_equipement,
            idLot: idLot,
            idEquipement: idEquipement,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_LOT_EQUIPEMENT,
            payload: err,
          });
        });
    });
  };
}

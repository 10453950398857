export const headers = () => {
  return [
    {
      label: "Compte général",
      key: "ecriture_ligne_compte.compte_compte_general",
    },
    {
      label: "Ecriture",
      key: "ecriture_id",
    },
    {
      label: "Date",
      key: "ecriture_date_ecriture",
    },
    {
      label: "Libellé",
      key: "ecriture_ligne_libelle",
    },
    {
      label: "Journal",
      key: "ecriture_journal_code",
    },
    {
      label: "Montant débit",
      key: "ecriture_ligne_montant_debit",
    },
    {
      label: "Montant crédit",
      key: "ecriture_ligne_montant_credit",
    },
    {
      label: "Pointage",
      key: "ecriture_ligne_lettrage",
    },
  ];
};

import {
  adaptedToLotsChampsPersosList,
  lotsChampsPersosToAdapted,
} from "../../adapter/lotChampPersoAdapter";
import { HttpMethod, initFetch } from "../../utils/fetcher";

export const SAVE_UPDATE_SUCCESS_LOT_CHAMP_PERSO = "SAVE_UPDATE_SUCCESS_LOT_CHAMP_PERSO";
export const SAVE_UPDATE_FAIL_LOT_CHAMP_PERSO = "SAVE_UPDATE_FAIL_LOT_CHAMP_PERSO";

export function saveUpdateLotsChampsPersos(jsonData, idLot) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`lotsChampsPersos/${idLot}`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToLotsChampsPersosList(jsonData)) })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.lot_champ_perso);
          dispatch({
            type: SAVE_UPDATE_SUCCESS_LOT_CHAMP_PERSO,
            payload: lotsChampsPersosToAdapted(res.lot_champ_perso),
            idLot,
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_UPDATE_FAIL_LOT_CHAMP_PERSO,
            error: err,
          })
        );
    });
  };
}

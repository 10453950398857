import React from "react";
import dayjs from "dayjs"
import { EyeOutlined } from "@ant-design/icons";
import { Button } from "antd";

export const columns = (onClickAfficher, getColumnSearchProps, getColumnSearchPropsDate) => [
  {
    title: "n°",
    dataIndex: "location_id",
    key: "location_id",
    sorter: (a, b) => parseInt(a.location_id) - parseInt(b.location_id),
    width: "5%",
  },
  {
    title: "Date Création",
    dataIndex: "location_date_creation",
    key: "location_date_creation",
    ...getColumnSearchPropsDate("location_date_creation"),
    render: (date) => dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
    sorter: (a, b) =>
      dayjs(a.location_date_creation).unix() - dayjs(b.location_date_creation).unix(),
    width: "8%",
  },
  {
    title: "Date contrat",
    dataIndex: "location_date_contrat",
    key: "location_date_contrat",
    render: (date) => date ? dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY") : "",
    width: "8%",
  },
  // {
  //   title: "Date Option",
  //   dataIndex: "location_date_fin_option",
  //   key: "location_date_fin_option",
  //   ...getColumnSearchPropsDate("location_date_fin_option"),
  //   render: (date) => dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
  //   width: "8%",
  // },
  {
    title: "Lot",
    dataIndex: "location_lot",
    key: "location_lot",
    render: (lot) => lot.lot_reference,
    width: "15%",
  },
  {
    title: "Date Début",
    dataIndex: "location_date_debut",
    key: "location_date_debut",
    ...getColumnSearchPropsDate("location_date_debut"),
    render: (date) => dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
    sorter: (a, b) => dayjs(a.location_date_debut).unix() - dayjs(b.location_date_debut).unix(),
    width: "8%",
  },
  {
    title: "Date Fin",
    dataIndex: "location_date_fin",
    key: "location_date_fin",
    ...getColumnSearchPropsDate("location_date_fin"),
    render: (date) => dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
    sorter: (a, b) => dayjs(a.location_date_fin).unix() - dayjs(b.location_date_fin).unix(),
    width: "8%",
  },
  {
    title: "Locataire",
    dataIndex: "location_requete",
    key: "location_requete",
    render: (requete) =>
      requete &&
      requete.requete_locataire &&
      `${requete.requete_locataire.locataire_personne.personne_nom} ${requete.requete_locataire.locataire_personne.personne_prenom}`,
    width: "15%",
  },
  {
    title: "Nb. Adultes",
    dataIndex: "location_nb_adultes",
    key: "location_nb_adultes",
  },
  {
    title: "Nb. Enfants",
    dataIndex: "location_nb_enfants",
    key: "location_nb_enfants",
  },
  {
    title: "Total",
    dataIndex: "quittance_ligne",
    key: "quittance_ligne",
    render: (lignes) => {
      let total = 0;
      lignes.forEach((ligne) => {
        total += parseFloat(ligne.quittance_ligne_montant);
      });
      return parseFloat(total).toLocaleString("fr-FR", {
        style: "currency",
        currency: "EUR",
      });
    },
  },
  {
    title: "Montant réglé",
    dataIndex: "reglement_ligne",
    key: "reglement_ligne",
    render: (lignes) => {
      let total = 0;
      lignes.forEach((ligne) => {
        total += parseFloat(ligne.reglement_ligne_montant);
      });
      return parseFloat(total).toLocaleString("fr-FR", {
        style: "currency",
        currency: "EUR",
      });
    },
  },
  {
    title: "Solde dû",
    dataIndex: "solde",
    key: "solde",
    render: (solde) => {
      return solde.toLocaleString("fr-FR", {
        style: "currency",
        currency: "EUR",
      });
    },
  },
  {
    title: "Afficher",
    dataIndex: "location_requete",
    key: "location_requete",
    render: (requete) => (
      <Button onClick={() => onClickAfficher(requete)}>
        <EyeOutlined />
      </Button>
    ),
    width: 100,
  },
];

export const columnsProprietaire = (onClickAfficher, getColumnSearchProps) => [
  {
    title: "Proprietaire",
    dataIndex: "location_lot",
    key: "location_lot",
    render: (lot) =>
      `${lot.lot_proprietaire.proprietaire_code} ${lot.lot_proprietaire.proprietaire_personne.personne_nom} ${lot.lot_proprietaire.proprietaire_personne.personne_prenom}`,
    width: "15%",
  },
  {
    title: "Lot",
    dataIndex: "location_lot",
    key: "location_lot",
    render: (lot) => lot.lot_reference,
    width: "15%",
  },
  {
    title: "Date Début",
    dataIndex: "location_date_debut",
    key: "location_date_debut",
    render: (date) => dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
    sorter: (a, b) => dayjs(a.location_date_debut).unix() - dayjs(b.location_date_debut).unix(),
    width: "8%",
  },
  {
    title: "Date Fin",
    dataIndex: "location_date_fin",
    key: "location_date_fin",
    render: (date) => dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
    sorter: (a, b) => dayjs(a.location_date_fin).unix() - dayjs(b.location_date_fin).unix(),
    width: "8%",
  },
  {
    title: "Motif",
    dataIndex: "location_remarques",
    key: "location_remarques",
    width: "54%",
  },
];

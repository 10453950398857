import { matricesLignesToAdapted } from "./matriceLigneAdapter";

export function matricesToAdapted(items) {
  return items
    ? items.map((item) => {
        return matriceToAdapted(item);
      })
    : null;
}

export function matriceToAdapted(item) {
  return item
    ? {
        matrice_id: item.id,
        matrice_ligne: matricesLignesToAdapted(item.matrice_ligne),
      }
    : null;
}

export function adaptedToMatrice(item) {
  return item
    ? {
        lot_id: item.matrice_lot_id,
      }
    : null;
}

import React, { Component } from "react";
import { Breadcrumb } from "antd";

class MyBreadcrumb extends Component {
  render() {
    return (
      <Breadcrumb style={{ margin: "16px" }}>
        <Breadcrumb.Item>Paramètres</Breadcrumb.Item>
        <Breadcrumb.Item>Parcours Clients</Breadcrumb.Item>
        <Breadcrumb.Item>Etapes</Breadcrumb.Item>
      </Breadcrumb>
    );
  }
}

export default MyBreadcrumb;

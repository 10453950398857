import {
  LOAD_SUCCESS_ECRITURES,
  LOAD_FAIL_ECRITURES,
  SAVE_SUCCESS_ECRITURES,
  SAVE_FAIL_ECRITURES,
  UPDATE_SUCCESS_ECRITURES,
  UPDATE_FAIL_ECRITURES,
  UPDATE_SUCCESS_ALL_ECRITURES,
  UPDATE_FAIL_ALL_ECRITURES,
  DELETE_SUCCESS_ECRITURES,
  DELETE_FAIL_ECRITURES,
  UPDATE_SUCCESS_ECRITURES_LIGNES,
} from "../actions/ecritureAction";

import { formatDateToAPP } from "../../utils/tools";

const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS_ECRITURES:
      const ecritures = action.payload.sort((a, b) => {
        return a.ecriture_id - b.ecriture_id;
      });
      ecritures.forEach((item) => {
        item.ecriture_date_ecriture = formatDateToAPP(item.ecriture_date_ecriture);
        item.ecriture_date_saisie = formatDateToAPP(item.ecriture_date_saisie);
        // item.ecriture_ligne = item.lignes;
        // item.ecriture_journal = item.journal;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: ecritures,
        error: null,
      };
    case LOAD_FAIL_ECRITURES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error,
      };
    case SAVE_SUCCESS_ECRITURES:
      const ecriture = action.payload;
      ecriture.ecriture_date_ecriture = formatDateToAPP(ecriture.ecriture_date_ecriture);
      return {
        ...state,
        loading: false,
        loaded: false,
        data: [...state.data, action.payload],
        error: null,
      };
    case SAVE_FAIL_ECRITURES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_ECRITURES:
      const updatedListe = [];
      state.data.forEach((item) => {
        if (item.ecriture_id === action.payload.ecriture_id) {
          item = action.payload;
          item.ecriture_date_ecriture = formatDateToAPP(item.ecriture_date_ecriture);
          item.ecriture_date_saisie = formatDateToAPP(item.ecriture_date_saisie);
        }
        updatedListe.push(item);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: updatedListe,
        error: null,
      };
    case UPDATE_FAIL_ECRITURES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_ALL_ECRITURES:
      const _updatedListe = [];
      state.data.forEach((item) => {
        if (action.payload.some((ecr) => ecr.ecriture_id === item.ecriture_id)) {
          const updatedEcriture = action.payload.find(
            (ecr) => ecr.ecriture_id === item.ecriture_id
          );
          item = updatedEcriture;
          item.ecriture_date_ecriture = formatDateToAPP(item.ecriture_date_ecriture);
          item.ecriture_date_saisie = formatDateToAPP(item.ecriture_date_saisie);
        }
        updatedListe.push(item);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: _updatedListe,
        error: null,
      };
    case UPDATE_FAIL_ALL_ECRITURES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_ECRITURES_LIGNES:
      const updated = [];
      state.data.forEach((item) => {
        const lignes = [];
        if (item.ecriture_id === action.payload.ecriture_ligne_ecriture_id) {
          item.ecriture_ligne.forEach((ligne) => {
            if (ligne.ecriture_ligne_id === action.payload.ecriture_ligne_id) {
              ligne = action.payload;
            }
            lignes.push(ligne);
          });
          item.ecriture_ligne = lignes;
        }
        updated.push(item);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: updated,
        error: null,
      };
    case DELETE_SUCCESS_ECRITURES:
      const filtered = state.data.filter((item) => {
        return item.ecriture_id !== action.id;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filtered,
        error: null,
      };
    case DELETE_FAIL_ECRITURES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    default:
      return state;
  }
}

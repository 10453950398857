import React, { Component } from "react";
import ReactDOMServer from "react-dom/server";

import {
  Row,
  Col,
  Form,
  Table,
  Button,
  Input,
  Space,
  Select,
  DatePicker,
  Radio,
  Spin,
  Typography,
  Modal,
  Checkbox,
} from "antd";

import { RedoOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

import dayjs from "dayjs";

import FloatLabel from "../FloatLabel";
import BalancePDF from "../Pdf/Comptabilite/BalancePDF";

import { HttpMethod, initFetch } from "../../utils/fetcher";
import { openPdf } from "../../utils/utils";

const dateFormat = "DD/MM/YYYY";

const { Text } = Typography;
const { Option, OptGroup } = Select;

class FormBalance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      refresh: false,
      idFirstCompte: null,
      idLastCompte: null,
      lignesEcritures: [],
      modalEcriture: false,
      modalPdf: false,
      searchText: "",
      searchedColumn: "",
      societe: "",
      compteCentralisateur: null,
      compteDu: null,
      compteAu: null,
      dateDu: null,
      dateAu: dayjs(),
      balanceComptes: [],
      balanceComptesFiltered: [],
      filtres: ["0"],
      tri: 1,
    };
  }

  // Référence du formulaire
  formRef = React.createRef();

  componentDidMount() {
    this.initComptesAuxiliaire(this.props.comptes, this.state.compteCentralisateur);
  }

  init = () => {
    const lignesEcritures = [];
    let ecrituresFiltered = this.props.ecritures;

    if (this.state.dateDu)
      ecrituresFiltered = ecrituresFiltered.filter((e) =>
        dayjs(e.ecriture_date_ecriture, "DD/MM/YYYY").isSameOrAfter(this.state.dateDu)
      );
    if (this.state.dateAu)
      ecrituresFiltered = ecrituresFiltered.filter((e) =>
        dayjs(e.ecriture_date_ecriture, "DD/MM/YYYY").isSameOrBefore(this.state.dateAu)
      );

    ecrituresFiltered &&
      ecrituresFiltered.forEach((ecriture) => {
        ecriture.ecriture_ligne &&
          ecriture.ecriture_ligne.forEach((ligne) => {
            ligne.ecriture_id = ecriture.ecriture_id;
            ligne.ecriture_journal_code = ecriture.ecriture_journal.journal_code;
            ligne.ecriture_date_ecriture = ecriture.ecriture_date_ecriture;
            lignesEcritures.push(ligne);
          });
      });
    lignesEcritures.sort((a, b) =>
      a.ecriture_ligne_compte.compte_compte_general.localeCompare(
        b.ecriture_ligne_compte.compte_compte_general
      )
    );

    let balanceComptes = [];
    let balanceComptesFiltered = [];
    let totalDebit = 0;
    let totalCredit = 0;
    let totalSolde = 0;
    let totalSoldeDebit = 0;
    let totalSoldeCredit = 0;

    this.props.comptes &&
      this.props.comptes.forEach((compte) => {
        const conditionGeneral =
          compte.compte_compte_general >= this.state.compteDu &&
          compte.compte_compte_general <= this.state.compteAu &&
          compte.compte_type_compte !== "A";
        const conditionAuxiliaire = this.state.compteCentralisateur
          ? compte.compte_compte_auxiliaire >= this.state.compteDu &&
            compte.compte_compte_auxiliaire <= this.state.compteAu &&
            compte.compte_type_compte === "A" &&
            compte.compte_compte_general ===
              this.props.comptes.find(
                (compte) => compte.compte_id === this.state.compteCentralisateur
              ).compte_compte_general
          : false;

        if (this.state.compteCentralisateur ? conditionAuxiliaire : conditionGeneral) {
          totalDebit = 0;
          totalCredit = 0;
          totalSolde = 0;
          totalSoldeDebit = 0;
          totalSoldeCredit = 0;

          let lignesCompte = [];
          if (compte.compte_type_compte === "C") {
            lignesCompte = lignesEcritures.filter(
              (l) => l.ecriture_ligne_compte.compte_compte_general === compte.compte_compte_general
            );
          } else {
            lignesCompte = lignesEcritures.filter(
              (l) => l.ecriture_ligne_compte_id === compte.compte_id
            );
          }

          lignesCompte &&
            lignesCompte.forEach((ligne) => {
              totalDebit += parseFloat(ligne.ecriture_ligne_montant_debit);
              totalCredit += parseFloat(ligne.ecriture_ligne_montant_credit);
            });

          totalSolde = totalCredit - totalDebit;
          if (totalSolde < 0) {
            totalSoldeDebit = Math.abs(totalSolde);
          } else if (totalSolde > 0) {
            totalSoldeCredit = totalSolde;
          }

          const ligneBalance = {
            compte_id: compte.compte_id,
            compte_general: compte.compte_compte_general,
            compte_auxiliaire: compte.compte_compte_auxiliaire,
            compte_libelle: compte.compte_libelle,
            total_debit: totalDebit,
            total_credit: totalCredit,
            total_solde_debit: totalSoldeDebit,
            total_solde_credit: totalSoldeCredit,
          };
          if (totalDebit !== 0 || totalCredit !== 0) balanceComptes.push(ligneBalance);
        }
      });

    balanceComptes.sort(
      (a, b) =>
        a.compte_general.localeCompare(b.compte_general) ||
        a.compte_auxiliaire.localeCompare(b.compte_auxiliaire)
    );

    balanceComptesFiltered = balanceComptes;
    if (this.state.filtres[0] === "0" || this.state.filtres.length === 0) {
      balanceComptesFiltered = balanceComptes.filter(
        (item) => item.total_debit !== item.total_credit
      );
    }

    this.setState({ lignesEcritures, balanceComptes, refresh: false });
    this.setState({ lignesEcritures, balanceComptesFiltered, refresh: false });
  };

  initComptesAuxiliaire = (comptes, compteCentralisateur) => {
    const comptesFiltered = this.filterByCompte(comptes, compteCentralisateur);

    this.formRef.current.setFieldsValue({
      societe_id: this.props.societes[0].societe_raison_sociale,
      compteDu:
        comptesFiltered.length > 0
          ? compteCentralisateur
            ? comptesFiltered[0].compte_compte_auxiliaire
            : comptesFiltered[0].compte_compte_general
          : null,
      compteAu:
        comptesFiltered.length > 0
          ? compteCentralisateur
            ? comptesFiltered[comptesFiltered.length - 1].compte_compte_auxiliaire
            : comptesFiltered[comptesFiltered.length - 1].compte_compte_general
          : null,
    });
    this.setState({
      societe: this.props.societes[0],
      compteDu:
        comptesFiltered.length > 0
          ? compteCentralisateur
            ? comptesFiltered[0].compte_compte_auxiliaire
            : comptesFiltered[0].compte_compte_general
          : null,
      compteAu:
        comptesFiltered.length > 0
          ? compteCentralisateur
            ? comptesFiltered[comptesFiltered.length - 1].compte_compte_auxiliaire
            : comptesFiltered[comptesFiltered.length - 1].compte_compte_general
          : null,
    });
  };

  filterByCompte = (comptes, compteCentralisateur) => {
    if (comptes === undefined) return [];
    let comptesFiltered = [];
    compteCentralisateur
      ? (comptesFiltered = comptes.filter(
          (item) =>
            item.compte_compte_general ===
              comptes.find((item) => item.compte_id === compteCentralisateur)
                .compte_compte_general && item.compte_type_compte === "A"
        ))
      : (comptesFiltered = comptes.filter((item) => item.compte_type_compte !== "A"));
    return compteCentralisateur
      ? comptesFiltered.sort((a, b) =>
          a.compte_compte_auxiliaire.localeCompare(b.compte_compte_auxiliaire)
        )
      : comptesFiltered.sort((a, b) =>
          a.compte_compte_general.localeCompare(b.compte_compte_general)
        );
  };

  onChangeCompteCentralisateur = (idCompte) => {
    this.formRef.current.setFieldsValue({
      compteDu: null,
      compteAu: null,
    });
    this.initComptesAuxiliaire(this.props.comptes, idCompte);
    this.setState({ compteCentralisateur: idCompte });
  };

  onChangeCompteDu = (idCompte) => {
    this.formRef.current.setFieldsValue({
      compteAu: idCompte,
    });
    this.setState({ compteDu: idCompte, compteAu: idCompte });
  };

  onChangeCompteAu = (idCompte) => {
    this.setState({ compteAu: idCompte });
  };

  onChangeDateDu = (dateDu) => {
    this.setState({ dateDu });
  };

  onChangeDateAu = (dateAu) => {
    this.setState({ dateAu });
  };

  onRefresh = () => {
    this.setState({ refresh: true }, () => this.init());
  };

  onChangeFilter = (checkedValues) => {
    this.setState({ filtres: checkedValues }, () => this.init());
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Rechercher ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Rechercher
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  createPdf = () => {
    const html = ReactDOMServer.renderToStaticMarkup(
      <BalancePDF
        balanceComptes={this.state.balanceComptesFiltered}
        dateDu={this.state.dateDu}
        dateAu={this.state.dateAu}
        compteCentralisateur={this.state.compteCentralisateur}
        compteDu={this.state.compteDu}
        compteAu={this.state.compteAu}
        comptes={this.props.comptes}
        tri={this.state.tri}
      />
    );
    const pdf = {
      titre: "Balance",
      destinataire: "",
      pdf_page: [],
    };
    const pdfPage = {
      html: html,
    };
    pdf.pdf_page.push(pdfPage);
    const { url, params } = initFetch(`pdfs`, HttpMethod.POST);
    fetch(url, { ...params, body: JSON.stringify(pdf) })
      .then(function (response) {
        return response.json();
      })
      .then(
        function (result) {
          this.setState({ idPdf: result.pdf.id, modalPdf: true });
        }.bind(this)
      );
  };

  closeModal = () => {
    this.setState({ modalPdf: false });
  };

  onChangeTri = (e) => {
    this.setState({ tri: e.target.value });
  };

  render() {
    const {
      loading,
      refresh,
      modalPdf,
      idPdf,
      societe,
      compteCentralisateur,
      compteDu,
      compteAu,
      dateDu,
      dateAu,
      balanceComptes,
      filtres,
      tri,
    } = this.state;
    const { societes, comptes } = this.props;

    let balanceComptesFiltered = balanceComptes;

    const options = [{ label: "Afficher les soldes nuls", value: "1" }];

    if (filtres[0] === "0" || filtres.length === 0) {
      balanceComptesFiltered = balanceComptes.filter(
        (item) => item.total_debit !== item.total_credit
      );
    }

    let columns = [
      {
        title: "Compte Général",
        dataIndex: "compte_general",
        key: "compte_general",
        align: "right",
        width: "10%",
      },
      {
        title: "Compte Auxiliaire",
        dataIndex: "compte_auxiliaire",
        key: "compte_auxiliaire",
        align: "right",
        width: "10%",
      },
      {
        title: "Libellé",
        dataIndex: "compte_libelle",
        key: "compte_libelle",
        align: "left",
        width: "25%",
      },
      {
        title: "Montant Débit",
        dataIndex: "total_debit",
        key: "total_debit",
        render: (montant) =>
          parseFloat(montant) !== 0 ? (
            parseFloat(montant).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })
          ) : (
            <></>
          ),
        className: "column-money",
        align: "right",
        width: "11%",
      },
      {
        title: "Montant Crédit",
        dataIndex: "total_credit",
        key: "total_credit",
        render: (montant) =>
          parseFloat(montant) !== 0 ? (
            parseFloat(montant).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })
          ) : (
            <></>
          ),
        className: "column-money",
        align: "right",
        width: "11%",
      },
      {
        title: "Solde débit",
        dataIndex: "total_solde_debit",
        key: "total_solde_debit",
        render: (montant) =>
          parseFloat(montant) !== 0 ? (
            parseFloat(montant).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })
          ) : (
            <></>
          ),
        className: "column-money",
        align: "right",
        width: "11%",
      },
      {
        title: "Solde crédit",
        dataIndex: "total_solde_credit",
        key: "total_solde_credit",
        render: (montant) =>
          parseFloat(montant) !== 0 ? (
            parseFloat(montant).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })
          ) : (
            <></>
          ),
        className: "column-money",
        align: "right",
        width: "11%",
      },
    ];

    return (
      <Spin spinning={loading} size="large" tip="Chargement...">
        <Modal footer={null} title="Edition" open={modalPdf} onCancel={this.closeModal}>
          {idPdf && (
            <Button type="text" onClick={() => openPdf(idPdf)}>
              Document PDF
            </Button>
          )}
        </Modal>

        <Form ref={this.formRef} size="large" style={{ padding: 20 }}>
          <Row gutter={12}>
            <Col span={12}>
              <FloatLabel label="Société" name="societe" value={societe}>
                <Form.Item name="societe_id">
                  <Select
                    showSearch
                    optionFilterProp="label"
                    optionLabelProp="label"
                    onChange={(id) => this.setState({ societe: id })}
                  >
                    <OptGroup label="Société">
                      {societes &&
                        societes.map((item, i) => {
                          return (
                            <Option
                              key={i}
                              value={item.societe_id}
                              label={item.societe_raison_sociale}
                            >
                              <Row>
                                <Col sm={8}>{item.societe_raison_sociale}</Col>
                              </Row>
                            </Option>
                          );
                        })}
                    </OptGroup>
                  </Select>
                </Form.Item>
              </FloatLabel>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <FloatLabel
                label="Compte centralisateur"
                name="compteCentralisateur"
                value={compteCentralisateur}
              >
                <Form.Item name="compte_centralisateur">
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="label"
                    optionLabelProp="label"
                    onChange={(value) => this.onChangeCompteCentralisateur(value)}
                  >
                    <OptGroup label="Compte">
                      {comptes &&
                        comptes
                          .filter((item) => item.compte_type_compte === "C")
                          .map((item, i) => (
                            <Option
                              key={i}
                              value={item.compte_id}
                              label={`${item.compte_compte_general} - ${item.compte_libelle}`}
                            >
                              <Row>
                                <Col
                                  sm={8}
                                >{`${item.compte_compte_general} - ${item.compte_libelle}`}</Col>
                              </Row>
                            </Option>
                          ))}
                    </OptGroup>
                  </Select>
                </Form.Item>
              </FloatLabel>

              <Row gutter={12}>
                <Col span={12}>
                  <FloatLabel label="Compte" name="compteDu" value={compteDu}>
                    <Form.Item name="compteDu">
                      <Select
                        showSearch
                        optionFilterProp="label"
                        optionLabelProp="label"
                        onChange={(value) => this.onChangeCompteDu(value)}
                      >
                        <OptGroup label="Compte">
                          {this.filterByCompte(comptes, compteCentralisateur).map((item, i) => (
                            <Option
                              key={i}
                              value={
                                compteCentralisateur
                                  ? item.compte_compte_auxiliaire
                                  : item.compte_compte_general
                              }
                              label={`${
                                compteCentralisateur
                                  ? item.compte_compte_auxiliaire
                                  : item.compte_compte_general
                              } - ${item.compte_libelle}`}
                            >
                              <Row>
                                <Col sm={8}>{`${
                                  compteCentralisateur
                                    ? item.compte_compte_auxiliaire
                                    : item.compte_compte_general
                                } - ${item.compte_libelle}`}</Col>
                              </Row>
                            </Option>
                          ))}
                        </OptGroup>
                      </Select>
                    </Form.Item>
                  </FloatLabel>
                </Col>

                <Col span={12}>
                  <FloatLabel label="Au compte" name="compteAu" value={compteAu}>
                    <Form.Item name="compteAu">
                      <Select
                        showSearch
                        optionFilterProp="label"
                        optionLabelProp="label"
                        onChange={(value) => this.onChangeCompteAu(value)}
                      >
                        <OptGroup label="Compte">
                          {this.filterByCompte(comptes, compteCentralisateur).map((item, i) => (
                            <Option
                              key={i}
                              value={
                                compteCentralisateur
                                  ? item.compte_compte_auxiliaire
                                  : item.compte_compte_general
                              }
                              label={`${
                                compteCentralisateur
                                  ? item.compte_compte_auxiliaire
                                  : item.compte_compte_general
                              } - ${item.compte_libelle}`}
                            >
                              <Row>
                                <Col sm={8}>{`${
                                  compteCentralisateur
                                    ? item.compte_compte_auxiliaire
                                    : item.compte_compte_general
                                } - ${item.compte_libelle}`}</Col>
                              </Row>
                            </Option>
                          ))}
                        </OptGroup>
                      </Select>
                    </Form.Item>
                  </FloatLabel>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <Button type="primary" style={{ float: "right" }} onClick={() => this.createPdf()}>
                Générer PDF
              </Button>
              <Radio.Group
                style={{ float: "right" }}
                onChange={(e) => this.onChangeTri(e)}
                value={tri}
              >
                <Radio value={1}>tri alphanumérique</Radio>
                <Radio value={2}>tri alphabétique</Radio>
              </Radio.Group>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={6}>
              <FloatLabel label="Du" name="dateDu" value={dateDu}>
                <Form.Item name="date_du">
                  <DatePicker
                    format={dateFormat}
                    placeholder=""
                    onChange={(e) => this.onChangeDateDu(e)}
                  />
                </Form.Item>
              </FloatLabel>
            </Col>
            <Col span={6}>
              <FloatLabel label="Au" name="dateAu" value={dateAu}>
                <Form.Item name="date_au">
                  <DatePicker
                    defaultValue={dayjs()}
                    format={dateFormat}
                    placeholder=""
                    onChange={(e) => this.onChangeDateAu(e)}
                  />
                </Form.Item>
              </FloatLabel>
            </Col>
            <Col span={2}>
              <RedoOutlined onClick={this.onRefresh} spin={refresh} style={{ fontSize: "32px" }} />
            </Col>
            <Col span={8} />
            <Col span={2}>
              <Checkbox.Group
                options={options}
                defaultValue={["0"]}
                onChange={this.onChangeFilter}
              />
            </Col>
          </Row>
        </Form>

        <Table
          className="table-ecritures"
          columns={columns}
          dataSource={balanceComptesFiltered}
          rowKey="compte_id"
          pagination={false}
          scroll={{ y: 600 }}
          summary={(pageData) => {
            let totalDebit = 0;
            let totalCredit = 0;
            let solde = 0;

            pageData.forEach(({ total_solde_debit, total_solde_credit }) => {
              totalDebit += parseFloat(total_solde_debit);
              totalCredit += parseFloat(total_solde_credit);
            });

            solde = parseFloat(totalDebit - totalCredit);

            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <strong>TOTAL :</strong>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text style={{ float: "right" }}>
                      <strong>
                        {parseFloat(totalDebit).toLocaleString("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </strong>
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text style={{ float: "right" }}>
                      <strong>
                        {parseFloat(totalCredit).toLocaleString("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </strong>
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <strong>SOLDE :</strong>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text style={{ float: "right" }}>
                      <strong>
                        {solde >= 0 ? (
                          parseFloat(Math.abs(solde)).toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          })
                        ) : (
                          <></>
                        )}
                      </strong>
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text style={{ float: "right" }}>
                      <strong>
                        {solde <= 0 ? (
                          parseFloat(Math.abs(solde)).toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          })
                        ) : (
                          <></>
                        )}
                      </strong>
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Spin>
    );
  }
}

export default FormBalance;

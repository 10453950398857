import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Spin } from "antd";

import {
  deleteReglement,
  loadReglements,
  updateReglements,
} from "../../store/actions/reglementAction";
import { loadLocataires } from "../../store/actions/locataireAction";
import { loadLocations } from "../../store/actions/locationAction";
import { loadComptes } from "../../store/actions/compteAction";
import { loadEcritures, saveEcriture } from "../../store/actions/ecritureAction";

import FormSuppressionReglement from "../../components/FormSuppressionReglement";

class SuppressionReglement extends Component {
  componentDidMount() {
    const groupement_id = this.props.utilisateur.groupement_id;
    const societe_id = this.props.utilisateur.societe_id;

    Promise.all([
      !this.props.comptes && this.props.loadComptes(societe_id),
      !this.props.locataires && this.props.loadLocataires(groupement_id),
      !this.props.locations && this.props.loadLocations(groupement_id),
      !this.props.reglements && this.props.loadReglements(societe_id),
      !this.props.ecritures && this.props.loadEcritures(societe_id),
    ]);
  }

  render() {
    const { societes, comptes, locataires, locations, reglements, ecritures, utilisateur } =
      this.props;

    return (
      <Spin
        size="large"
        tip="Chargement en cours..."
        spinning={!comptes || !locataires || !locations || !reglements || !ecritures}
      >
        <Card title="" bordered={false} className="card-panel">
          <FormSuppressionReglement
            utilisateur={utilisateur}
            societes={societes.filter((s) => s.societe_id === this.props.utilisateur.societe_id)}
            locataires={locataires}
            locations={
              locations &&
              locations.filter(
                (item) => item.location_lot.lot_societe_id === utilisateur.societe_id
              )
            }
            comptes={comptes}
            reglements={reglements}
            ecritures={ecritures}
            saveEcriture={this.props.saveEcriture}
            deleteReglement={this.props.deleteReglement}
            updateReglements={this.props.updateReglements}
          />
        </Card>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadComptes: (societe_id) => dispatch(loadComptes(societe_id)),
    loadLocataires: (groupement_id) => dispatch(loadLocataires(groupement_id)),
    loadLocations: (groupement_id) => dispatch(loadLocations(groupement_id)),
    loadReglements: (societe_id) => dispatch(loadReglements(societe_id)),
    loadEcritures: (societe_id) => dispatch(loadEcritures(societe_id)),
    deleteReglement: (id) => dispatch(deleteReglement(id)),
    saveEcriture: (ecriture) => dispatch(saveEcriture(ecriture)),
    updateReglements: (jsonData) => dispatch(updateReglements(jsonData)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    societes: state.societes.data,
    journals: state.journals.data,
    comptes: state.comptes.data,
    locataires: state.locataires.data,
    locations: state.locations.data,
    reglements: state.reglements.data,
    ecritures: state.ecritures.data,
    typesPaiements: state.typesPaiements.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SuppressionReglement);

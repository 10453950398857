import { HttpMethod, initFetch } from "./fetcher";

export const openPdf = (id, type = "a") => {
  const { url, params } = initFetch(
    `pdf/document_${type}.php?num=${id}&name=document`,
    HttpMethod.GET,
    {
      Authorization: true,
      formData: false,
      pdf: true,
    }
  );
  fetch(url, params)
    .then((e) => {
      return e.blob();
    })
    .then((blob) => {
      const _url = window.URL.createObjectURL(blob);
      window.open(_url, "_blank").focus(); // window.open + focus
    });
};

import { etapeToAdapted } from "./etapeAdapter";
import { simpleRequeteToAdapted } from "./requeteAdapter";

export function renduHistoriquesToAdapted(items) {
  return items
    ? items.map((item) => {
        return renduHistoriqueToAdapted(item);
      })
    : null;
}

export function renduHistoriqueToAdapted(item) {
  //Resource
  return item
    ? {
        historique_requete: simpleRequeteToAdapted(item.requete),
        historique_etape_a: etapeToAdapted(item.etape_a),
        historique_etape_b: etapeToAdapted(item.etape_b),
        historique_type: item.type,
        historique_date_at: item.date_at,
      }
    : null;
}

export function adaptedToRenduHistorique(item) {
  //Model
  return item
    ? {
        requete_id: item.historique_requete_id,
        etape_a_id: item.historique_etape_a_id,
        etape_b_id: item.historique_etape_b_id,
        type: item.historique_type,
        date_at: item.historique_date_at,
      }
    : null;
}

import {
  LOAD_SUCCESS_LOTS,
  LOAD_FAIL_LOTS,
  SAVE_SUCCESS_LOTS,
  SAVE_FAIL_LOTS,
  UPDATE_SUCCESS_LOTS,
  UPDATE_FAIL_LOTS,
  DELETE_SUCCESS_LOTS,
  DELETE_FAIL_LOTS,
} from "../actions/lotAction";

import {
  SAVE_SUCCESS_MATRICE_LIGNE,
  UPDATE_SUCCESS_MATRICE_LIGNE,
  DELETE_SUCCESS_MATRICE_LIGNE,
} from "../actions/matriceLigneAction";

import {
  SAVE_SUCCESS_PRIX_BASE,
  UPDATE_SUCCESS_PRIX_BASE,
  DELETE_SUCCESS_PRIX_BASE,
} from "../actions/prixBaseAction";

import {
  SAVE_SUCCESS_NUITES,
  UPDATE_SUCCESS_NUITES,
  DELETE_SUCCESS_NUITES,
} from "../actions/nuiteAction";

import { SAVE_SUCCESS_MATRICE } from "../actions/matriceAction";

import { SAVE_SUCCESS_LOT_PHOTO } from "../actions/photoAction";
import { UPDATE_SUCCESS_LOT_PHOTO } from "../actions/photoAction";
import { DELETE_SUCCESS_LOT_PHOTO } from "../actions/photoAction";

import { SAVE_SUCCESS_LOT_DESCRIPTION } from "../actions/descriptionAction";
import { UPDATE_SUCCESS_LOT_DESCRIPTION } from "../actions/descriptionAction";

import { SAVE_UPDATE_SUCCESS_LOT_CHAMP_PERSO } from "../actions/lotChampPersoAction";

import { SAVE_SUCCESS_LOT_EMAIL } from "../actions/emailAction";

import {
  SAVE_SUCCESS_LOTS_NOTES,
  UPDATE_SUCCESS_LOTS_NOTES,
  DELETE_SUCCESS_LOTS_NOTES,
} from "../actions/lotNoteAction";

const initialState = {
  loaded: false,
};

const filterElements = (lots) => {
  lots.forEach((item, i) => {
    let quartiers = [];
    let metros = [];
    let elements = [];

    item.key = i;
    item.lot_champs_persos.forEach((i) => {
      switch (i.lot_champ_perso_champ_perso.champ_perso_groupe_type) {
        case 1:
          return quartiers.push(i.lot_champ_perso_champ_perso);
        case 2:
          return metros.push(i.lot_champ_perso_champ_perso);
        default:
          return elements.push(i);
      }
    });
    item.lot_quartiers = quartiers;
    item.lot_metros = metros;
    item.lot_elements = elements;
  });

  return lots;
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS_LOTS:
      const lots = action.payload.sort((a, b) => {
        return a.lot_id - b.lot_id;
      });

      return {
        ...state,
        loading: false,
        loaded: true,
        data: filterElements(lots),
        error: null,
      };
    case LOAD_FAIL_LOTS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error,
      };
    case SAVE_SUCCESS_LOTS:
      let lot = action.payload;
      let quartiers = [];
      let metros = [];
      let elements = [];

      lot.key = lot.lot_id;
      lot.lot_champs_persos.forEach((i) => {
        switch (i.lot_champ_perso_champ_perso.champ_perso_groupe_type) {
          case 1:
            return quartiers.push(i.lot_champ_perso_champ_perso);
          case 2:
            return metros.push(i.lot_champ_perso_champ_perso);
          default:
            return elements.push(i);
        }
      });
      lot.lot_quartiers = quartiers;
      lot.lot_metros = metros;
      lot.lot_elements = elements;
      return {
        ...state,
        loading: false,
        loaded: false,
        data: [...state.data, lot],
        error: null,
      };
    case SAVE_FAIL_LOTS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_LOTS:
      const updatedListe = [];

      state.data.forEach((item) => {
        if (item.lot_id === action.payload.lot_id) item = action.payload;

        updatedListe.push(item);
      });

      return {
        ...state,
        loading: false,
        loaded: true,
        data: filterElements(updatedListe),
        error: null,
      };
    case UPDATE_FAIL_LOTS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case DELETE_SUCCESS_LOTS:
      const filtered = state.data.filter((item) => {
        return item.lot_id !== action.id;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filtered,
        error: null,
      };
    case DELETE_FAIL_LOTS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case SAVE_SUCCESS_MATRICE_LIGNE:
      const newListe = [];

      state.data.forEach((lot) => {
        if (lot === action.lot) {
          let array = lot.lot_matrice.matrice_ligne;
          array = [...array, action.payload];
          lot.lot_matrice.matrice_ligne = array;
        }
        newListe.push(lot);
      });

      return {
        ...state,
        loading: false,
        loaded: true,
        data: newListe,
        error: null,
      };
    case UPDATE_SUCCESS_MATRICE_LIGNE:
      const filterLM = [];
      state.data.forEach((lot) => {
        if (lot === action.lot) {
          const l = [];
          lot.lot_matrice.matrice_ligne.forEach((ligne) => {
            if (
              ligne.matrice_ligne_matrice_id === action.payload.matrice_ligne_matrice_id &&
              ligne.matrice_ligne_rubrique.rubrique_id ===
                action.payload.matrice_ligne_rubrique.rubrique_id
            ) {
              ligne = action.payload;
            }
            l.push(ligne);
          });
          lot.lot_matrice.matrice_ligne = l;
        }
        filterLM.push(lot);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filterLM,
        error: null,
      };
    case DELETE_SUCCESS_MATRICE_LIGNE:
      const filteredLM = [];
      state.data.forEach((item) => {
        if (item === action.lot) {
          item.lot_matrice.matrice_ligne = item.lot_matrice.matrice_ligne.filter((i) => {
            return (
              i.matrice_ligne_matrice_id !== action.payload.matrice_ligne_matrice_id ||
              i.matrice_ligne_rubrique.rubrique_id !==
                action.payload.matrice_ligne_rubrique.rubrique_id
            );
          });
        }
        filteredLM.push(item);
      });

      return {
        ...state,
        loading: false,
        loaded: true,
        data: filteredLM,
        error: null,
      };
    case SAVE_SUCCESS_MATRICE:
      const addMatrice = [];

      state.data.forEach((lot) => {
        if (lot === action.lot) lot.lot_matrice = action.payload;

        addMatrice.push(lot);
      });

      return {
        ...state,
        loading: false,
        loaded: true,
        data: addMatrice,
        error: null,
      };
    case SAVE_SUCCESS_LOT_PHOTO:
      const addPhoto = [];

      state.data.forEach((lot) => {
        if (lot.lot_id === action.id) lot.lot_photos.push(action.payload);

        addPhoto.push(lot);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: addPhoto,
        error: null,
      };
    case UPDATE_SUCCESS_LOT_PHOTO:
      const updatePhotos = [];
      state.data.forEach((i) => {
        if (i.lot_id === action.payload.photo_lot_id) {
          const lotPhoto = [];
          i.lot_photos.forEach((item) => {
            if (item.photo_id === action.payload.photo_id) {
              item = action.payload;
            }
            lotPhoto.push(item);
          });
          i.lot_photos = lotPhoto;
        }
        updatePhotos.push(i);
      });

      return {
        ...state,
        loading: false,
        loaded: true,
        data: updatePhotos,
        error: null,
      };
    case DELETE_SUCCESS_LOT_PHOTO:
      const deletePhoto = [];

      state.data.forEach((lot) => {
        if (lot.lot_id === action.payload.photo_lot_id) {
          lot.lot_photos = lot.lot_photos.filter((i) => i.photo_id !== action.id);
        }

        deletePhoto.push(lot);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: deletePhoto,
        error: null,
      };
    case SAVE_SUCCESS_LOT_DESCRIPTION:
      const addDescription = [];

      state.data.forEach((lot) => {
        if (lot.lot_id === action.id) lot.lot_descriptions.push(action.payload);

        addDescription.push(lot);
      });

      return {
        ...state,
        loading: false,
        loaded: true,
        data: addDescription,
        error: null,
      };
    case SAVE_SUCCESS_LOT_EMAIL:
      const addEmail = [];

      state.data.forEach((lot) => {
        if (lot.lot_id === action.payload.email_lot_id) lot.lot_emails.push(action.payload);

        addEmail.push(lot);
      });

      return {
        ...state,
        loading: false,
        loaded: true,
        data: addEmail,
        error: null,
      };
    case UPDATE_SUCCESS_LOT_DESCRIPTION:
      const updateDescription = [];
      state.data.forEach((i) => {
        if (i.lot_id === action.lot_id) {
          const lotDesc = [];
          i.lot_descriptions.forEach((item) => {
            if (item.description_langue.langue_id === action.langue_id) {
              item = action.payload;
            }
            lotDesc.push(item);
          });
          i.lot_descriptions = lotDesc;
        }
        updateDescription.push(i);
      });

      return {
        ...state,
        loading: false,
        loaded: true,
        data: updateDescription,
        error: null,
      };
    case SAVE_UPDATE_SUCCESS_LOT_CHAMP_PERSO:
      const updatedLots = [];
      state.data.forEach((item) => {
        if (item.lot_id === action.idLot) {
          item.lot_champs_persos = action.payload;
        }
        updatedLots.push(item);
      });

      return {
        ...state,
        loading: false,
        loaded: true,
        data: filterElements(updatedLots),
        error: null,
      };

    case SAVE_SUCCESS_PRIX_BASE:
      const prixBase = [];

      state.data.forEach((lot) => {
        if (lot === action.lot) {
          let array = lot.lot_prix_base;
          array = [...array, action.payload];
          lot.lot_prix_base = array;
        }
        prixBase.push(lot);
      });

      return {
        ...state,
        loading: false,
        loaded: true,
        data: prixBase,
        error: null,
      };

    case SAVE_SUCCESS_NUITES:
      const nuite = [];

      state.data.forEach((lot) => {
        if (lot === action.lot) {
          let array = lot.lot_nuites;
          array = [...array, action.payload];
          lot.lot_nuites = array;
        }
        nuite.push(lot);
      });

      return {
        ...state,
        loading: false,
        loaded: true,
        data: nuite,
        error: null,
      };

    case DELETE_SUCCESS_PRIX_BASE:
      const filteredPX = [];
      state.data.forEach((item) => {
        if (item === action.lot) {
          item.lot_prix_base = item.lot_prix_base.filter((i) => {
            return i.prix_base_id !== action.payload.prix_base_id;
          });
        }
        filteredPX.push(item);
      });

      return {
        ...state,
        loading: false,
        loaded: true,
        data: filteredPX,
        error: null,
      };

    case DELETE_SUCCESS_NUITES:
      const filteredN = [];
      state.data.forEach((item) => {
        if (item === action.lot) {
          item.lot_nuites = item.lot_nuites.filter((i) => {
            return i.nuite_id !== action.payload.nuite_id;
          });
        }
        filteredN.push(item);
      });

      return {
        ...state,
        loading: false,
        loaded: true,
        data: filteredN,
        error: null,
      };

    case UPDATE_SUCCESS_PRIX_BASE:
      const filterPX = [];
      state.data.forEach((lot) => {
        if (lot === action.lot) {
          const l = [];
          lot.lot_prix_base.forEach((ligne) => {
            if (ligne.prix_base_id === action.payload.prix_base_id) {
              ligne = action.payload;
            }
            l.push(ligne);
          });
          lot.lot_prix_base = l;
        }
        filterPX.push(lot);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filterPX,
        error: null,
      };

    case UPDATE_SUCCESS_NUITES:
      const filterN = [];
      state.data.forEach((lot) => {
        if (lot === action.lot) {
          const l = [];
          lot.lot_nuites.forEach((ligne) => {
            if (ligne.nuite_id === action.payload.nuite_id) {
              ligne = action.payload;
            }
            l.push(ligne);
          });
          lot.lot_nuites = l;
        }
        filterN.push(lot);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filterN,
        error: null,
      };

    // Reducers notes
    case SAVE_SUCCESS_LOTS_NOTES:
      const noteAdded = [];
      state.data.forEach((lot) => {
        if (lot.lot_id === action.payload.lot_id) {
          let array = lot.notes;
          array = [...array, action.payload];
          lot.notes = array;
        }
        noteAdded.push(lot);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: noteAdded,
        error: null,
      };
    case UPDATE_SUCCESS_LOTS_NOTES:
      const noteUpdated = [];
      state.data.forEach((lot) => {
        const notes = [];
        if (lot.lot_id === action.payload.lot_id) {
          lot.notes.forEach((note) => {
            if (note.id === action.id) {
              note = action.payload;
            }
            notes.push(note);
          });
          lot.notes = notes;
        }
        noteUpdated.push(lot);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: noteUpdated,
        error: null,
      };
    case DELETE_SUCCESS_LOTS_NOTES:
      const noteDeleted = [];
      state.data.forEach((lot) => {
        if (lot.lot_id === action.payload.lot_id) {
          lot.notes = lot.notes.filter((note) => {
            return note.id !== action.id;
          });
        }
        noteDeleted.push(lot);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: noteDeleted,
        error: null,
      };

    default:
      return state;
  }
}

import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Input, Row, Table, Typography } from "antd";

const { Search } = Input;
const { Title } = Typography;

const ListProposition = (props) => {
  const { listData, tableOption, titre } = props;
  const [lignesProp, setPropositions] = useState();
  const componentRef = useRef();

  let label = "Propositions";

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const init = () => {
    applyFilter();
  };

  const applyFilter = () => {
    let propositions = listData;

    if (propositions) {
      let lignesProp = [];
      let listLots = "";
      let idReq = 0;

      propositions.forEach((ligne) => {
        if (idReq !== 0 && idReq !== ligne.requete_lot_requete.requete_id) {
          const ligneProp = {
            requete_cle: ligne.key,
            requete_id: ligne.requete_lot_requete.requete_id,
            requete_locataire_id: ligne.requete_lot_requete.requete_locataire.locataire_id,
            requete_locataire:
              ligne.requete_lot_requete.requete_locataire.locataire_personne.personne_nom +
              " " +
              ligne.requete_lot_requete.requete_locataire.locataire_personne.personne_prenom,
            requete_lot_reference: listLots,
            requete_date_debut: ligne.requete_lot_date_debut,
            requete_date_fin: ligne.requete_lot_date_fin,
            requete_creation: ligne.requete_lot_requete.requete_created,
          };

          lignesProp.push(ligneProp);
          listLots = "";
        }
        listLots += ligne.requete_lot_lot.lot_reference + " / ";
        idReq = ligne.requete_lot_requete.requete_id;
      });

      setPropositions(lignesProp);
    }
  };

  const deleteRequete = (idReq) => {
    const requete = props.requetes.find((req) => req.requete_id === idReq);
    if (requete) {
      requete.requete_locataire_id = requete.requete_locataire?.locataire_id;
      requete.requete_dossier_id = requete.requete_dossier?.dossier_id;
      requete.requete_provenance_id = requete.requete_provenance?.provenance_id;
      requete.requete_fin = true;
      props.updateRequete(requete, idReq).then(() => {
        props.syncRequetesLots();
      });
    }
  };

  const getColumnSearchPropsDate = () => {};

  return (
    <>
      <div style={{ padding: 20 }}>
        <Row>
          <Title level={2}>
            {`${titre ? "Liste des " + label : ""} `}
            {/* Liste des {label} */}
          </Title>
        </Row>
        <Row>
          <Col span={21}>
            <Search
              placeholder="Recherche..."
              allowClear
              //onSearch={search}
              style={{ width: 300 }}
            />
          </Col>
          <Col span={3}>
            <div style={{ float: "right" }}>
              <Button className="btn-primary">
                {/* onClick={handlePrint} */}
                PDF
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <div ref={componentRef}>
        {lignesProp && (
          <Table
            className="list-data"
            dataSource={lignesProp}
            columns={tableOption(getColumnSearchPropsDate, deleteRequete)}
            pagination={false}
            rowKey={"requete_cle"}
          />
        )}
      </div>
    </>
  );
};

export default ListProposition;

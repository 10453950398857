import { etablissementToAdapted } from "./etablissementAdapter";

export function utilisateurToAdapted(item) {
  //Resource
  return item
    ? {
        token: item.token,
        utilisateur_id: item.id,
        utilisateur_nom: item.nom,
        utilisateur_email: item.email,
        utilisateur_etablissement: etablissementToAdapted(item.etablissement),
        utilisateur_admin: item.roles[0]?.id === 1,
      }
    : null;
}

import React, { Component } from "react";

import {
  Table,
  Spin,
  Popconfirm,
  Card,
  Row,
  Col,
  Upload,
  Button,
  Tag,
  Input,
  Tooltip,
  Dropdown,
  Menu,
  Modal,
} from "antd";

import Constants from "../../utils/constants";

import {
  PlusOutlined,
  FolderViewOutlined,
  MailOutlined,
  DeleteOutlined,
  EditOutlined,
  LinkOutlined,
  ReloadOutlined,
  FileProtectOutlined,
} from "@ant-design/icons";

import { toast } from "react-toastify";

import { HttpMethod, initFetch } from "../../utils/fetcher";

import dayjs from "dayjs";

const { CheckableTag } = Tag;

class FormDocument extends Component {
  state = {
    dossier: null,
    documents: [],
    loading: true,
    tags: [],
    inputVisible: false,
    inputValue: "",
    editInputIndex: -1,
    editInputValue: "",
    selectedTag: null,
  };

  componentDidMount() {
    if (this.props.dossier !== null && this.props.dossier !== undefined) {
      this.initEdit(this.props);
      this.checkStatut(this.props);
    } else {
      this.initCreate();
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.dossier !== null && props.dossier !== undefined) {
      this.initEdit(props);
    } else {
      this.initCreate();
    }
  }

  initCreate = () => {
    this.setState({ dossier: null });
  };

  initEdit = (props) => {
    const dossier = props.dossier;
    const documents = this.filterDocumentsByDossier(props.documents, dossier);

    documents.sort((a, b) => b.document_id - a.document_id);

    this.setState({
      dossier,
      tags: props.tags,
      documents,
      loading: false,
    });
  };

  checkStatut = (props) => {
    const dossier = props.dossier;
    const documents = this.filterDocumentsByDossier(props.documents, dossier);
    const docsYousign = documents.filter((d) => d.document_yousign_document_id !== null);

    docsYousign.forEach((doc) => {
      if (doc.document_yousign_statut === "ongoing") {
        this.handleRefresh(doc);
      }
    });
  };

  filterDocumentsByDossier = (documents, dossier) => {
    return documents.filter(
      (document) =>
        document.document_dossier && document.document_dossier.dossier_id === dossier.dossier_id
    );
  };

  handleRefresh = (document) => {
    const urlRefresh =
      "https://api.yousign.app/v3/signature_requests/" + document.document_yousign_requete_id;
    fetch(urlRefresh, {
      method: "GET",
      headers: {
        Authorization: "Bearer 8lj0pevKaOYCEpceDU3P4hBMhw2kl9fA",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // mise à jour document
        this.props.updateDocument(
          {
            document_nom: document.document_nom,
            document_dispo_mail: false,
            document_yousign_document_id: document.document_yousign_document_id,
            document_yousign_requete_id: document.document_yousign_requete_id,
            document_yousign_statut: res.status,
          },
          document.document_id
        );
      });
  };

  downloadYousign = (document) => {
    fetch(
      "https://api.yousign.app/v3/signature_requests/" +
        document.document_yousign_requete_id +
        "/documents/download",
      {
        method: "GET",
        headers: {
          Accept: "application/pdf",
          "Content-Type": "application/pdf",
          Authorization: "Bearer 8lj0pevKaOYCEpceDU3P4hBMhw2kl9fA",
        },
      }
    )
      .then((e) => {
        return e.blob();
      })
      .then((blob) => {
        let b = blob;
        this.showFile(b);
      });
  };

  showFile = (blob) => {
    // Create a new blob object with mime-type
    var newBlob = new Blob([blob], { type: "application/pdf" });

    // Use msSaveOrOpenBlob for IE
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.href = data;
    link.download = "Contrat.pdf";
    link.click();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  };

  handleDisable = (document) => {
    const annulation = {
      reason: "other",
      custom_note: "rien",
    };
    fetch(
      "https://api.yousign.app/v3/signature_requests/" +
        document.document_yousign_requete_id +
        "/cancel",
      {
        method: "POST",
        headers: {
          Authorization: "Bearer 8lj0pevKaOYCEpceDU3P4hBMhw2kl9fA",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(annulation),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.handleRefresh(document);
      });
  };

  handleDelete = (document) => {
    this.props.deleteDocument(document.document_id);
  };

  handleEmail = (fromRequete, document) => {
    // Cas fiche proprietaire -> tab id 7
    this.props.onChangeTab(fromRequete ? "2" : "7", document);
  };

  handleSignature = (document) => {
    const { url, params } = initFetch(`documents/display/${document.document_id}`, HttpMethod.GET, {
      Authorization: true,
      formData: true,
      pdf: false,
    });
    fetch(url, params)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], `${document.document_nom}`);
        let formData = new FormData();
        formData.append("file", file);
        formData.append("nature", "signable_document");
        fetch("https://api.yousign.app/v3/documents", {
          method: "POST",
          headers: {
            Authorization: "Bearer 8lj0pevKaOYCEpceDU3P4hBMhw2kl9fA",
          },
          body: formData,
        })
          .then((res) => res.json())
          .then((res) => {
            // préparation de la requête
            let signers = [];
            if (this.props.utilisateur.societe_id === 19) {
              signers = [
                {
                  document_id: res.id,
                  type: "signature",
                  page: 4,
                  width: 180,
                  x: 300,
                  y: 730,
                },
                {
                  document_id: res.id,
                  type: "signature",
                  page: 5,
                  width: 180,
                  x: 300,
                  y: 730,
                },
              ];
            } else {
              signers = [
                {
                  document_id: res.id,
                  type: "signature",
                  page: 1,
                  width: 180,
                  x: 300,
                  y: 730,
                },
              ];
            }
            const dateExpiration = dayjs().add(7, "days").format("YYYY-MM-DD");
            const requete = {
              name: "Contrat de location",
              delivery_mode: "email",
              timezone: "Europe/Paris",
              documents: [res.id],
              expiration_date: dateExpiration,
              signers: [
                {
                  info: {
                    first_name:
                      this.props.location.location_requete.requete_locataire.locataire_personne
                        .personne_prenom,
                    last_name:
                      this.props.location.location_requete.requete_locataire.locataire_personne
                        .personne_nom,
                    email:
                      this.props.location.location_requete.requete_locataire.locataire_personne
                        .personne_email1,
                    phone_number: "+33673735003",
                    locale: "fr",
                  },
                  signature_level: "electronic_signature",
                  signature_authentication_mode: "no_otp",
                  fields: signers,
                },
              ],
            };
            fetch("https://api.yousign.app/v3/signature_requests", {
              method: "POST",
              headers: {
                Authorization: "Bearer 8lj0pevKaOYCEpceDU3P4hBMhw2kl9fA",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requete),
            })
              .then((res2) => res2.json())
              .then((res2) => {
                // activation de la requete
                const urlActivation =
                  "https://api.yousign.app/v3/signature_requests/" + res2.id + "/activate";
                fetch(urlActivation, {
                  method: "POST",
                  headers: {
                    Authorization: "Bearer 8lj0pevKaOYCEpceDU3P4hBMhw2kl9fA",
                  },
                })
                  .then((res3) => res3.json())
                  .then((res3) => {
                    // ajout followers
                    const follower = [
                      {
                        email: this.props.parametre.parametre_email,
                        locale: "fr",
                      },
                    ];
                    const urlFollower =
                      "https://api.yousign.app/v3/signature_requests/" + res2.id + "/followers";
                    fetch(urlFollower, {
                      method: "POST",
                      headers: {
                        Authorization: "Bearer 8lj0pevKaOYCEpceDU3P4hBMhw2kl9fA",
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(follower),
                    })
                      .then((res4) => res4.json())
                      .then(() => {
                        toast.success("Invitation YOUSIGN envoyée !", { containerId: "A" });
                        // mise à jour documents
                        this.props.updateDocument(
                          {
                            document_nom: document.document_nom,
                            document_dispo_mail: false,
                            document_yousign_document_id: res.id,
                            document_yousign_requete_id: res3.id,
                            document_yousign_statut: res3.status,
                            document_utilisateur_id: this.props.utilisateur.utilisateur_nom,
                            document_locataire_id:
                              this.props.location.location_requete.requete_locataire.locataire_id,
                          },
                          document.document_id
                        );
                        // envoi mail de confirmation
                        this.envoiMail();
                      });
                  });
              });
          });
      });
  };

  envoiMail = () => {
    const arrayDestinataire = [];
    arrayDestinataire.push({
      email: this.props.parametre.parametre_email,
      name: this.props.utilisateur.utilisateur_nom,
    });

    const objet = "Invitation YOUSIGN envoyée";
    const message =
      "Votre invitation a bien été envoyée au locataire suivant : " +
      "<br/>" +
      this.props.location.location_requete.requete_locataire.locataire_personne.personne_nom +
      " " +
      this.props.location.location_requete.requete_locataire.locataire_personne.personne_prenom +
      "<br/>" +
      this.props.location.location_requete.requete_locataire.locataire_personne.personne_email1;

    const jsonData = {
      email_objet: objet,
      email_message: message,
      email_destinataire: this.props.parametre.parametre_email,
      email_destinataire_array: arrayDestinataire,
      email_copie: "",
      email_copie_array: [],
      email_pieces_jointes: [],
      email_utilisateur_id: this.props.utilisateur.utilisateur_id,
      email_requete_id: this.props.location.location_requete.requete_id,
      email_lot_id: this.props.location.location_lot.lot_id,
      parametre_smtp: this.props.parametre.parametre_smtp,
      parametre_port: this.props.parametre.parametre_port,
      parametre_email: this.props.parametre.parametre_email,
      parametre_mdp: this.props.parametre.parametre_mdp,
      parametre_copie_agence: this.props.parametre.parametre_copie_agence,
      parametre_email_copie: this.props.parametre.parametre_email_copie,
    };

    // Envoi du mail puis sauvegarde de celui-ci
    this.props.saveEmail(jsonData);
  };

  handleClose = (removedTag) => {
    const tags = this.state.tags.filter((tag) => tag !== removedTag);
    this.setState({ tags });
  };

  showInput = () => {
    this.setState({ inputVisible: true, inputValue: "" }, () => this.input.focus());
  };

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    if (this.state.inputValue.trim() === "") {
      this.setState({
        inputVisible: false,
      });
      return;
    }

    this.props
      .saveTag({
        tag_libelle: this.state.inputValue,
        tag_ordre: this.state.tags.length + 1,
        tag_etablissement_id: this.props.utilisateur.etablissement_id,
        tag_type: this.props.typeTag,
      })
      .then((res) => {
        this.setState({
          inputVisible: false,
          inputValue: "",
        });
      });
  };

  handleEditInputChange = (e) => {
    this.setState({ editInputValue: e.target.value });
  };

  handleEditInputConfirm = () => {
    this.setState(({ tags, editInputIndex, editInputValue }) => {
      const newTags = [...tags];
      newTags[editInputIndex] = editInputValue;

      return {
        tags: newTags,
        editInputIndex: -1,
        editInputValue: "",
      };
    });
  };

  saveInputRef = (input) => {
    this.input = input;
  };

  saveEditInputRef = (input) => {
    this.editInput = input;
  };

  handleChange(tag) {
    this.setState({ selectedTag: tag });
  }

  handleEditer = () => {
    if (this.state.inputValue.trim() === "") {
      toast.error("Libellé vide !", {
        containerId: "A",
      });
      return;
    }

    this.props
      .updateTag(
        {
          tag_libelle: this.state.inputValue,
        },
        this.state.selectedTag.tag_id
      )
      .then((res) => {
        this.setState({
          previewVisible: false,
          inputValue: "",
          selectedTag: res,
        });
        toast.success("Dossier modifié !", {
          containerId: "A",
        });
      })
      .catch((err) => {
        toast.error("Échec de la modification !", {
          containerId: "A",
        });
      });
  };

  handleSupprimer = () => {
    const tag = this.state.selectedTag;

    if (
      this.props.documents.length > 0 &&
      this.props.documents.some((i) => i.document_tag && i.document_tag.tag_id === tag.tag_id)
    ) {
      toast.error("Ce dossier est lié à au moins un document !", {
        containerId: "A",
      });
      return;
    }

    this.props
      .deleteTag(tag.tag_id)
      .then((res) => {
        this.setState({
          previewVisible: false,
          previewTitre: "",
          selectedTag: null,
        });
        toast.success("Dossier supprimé !", {
          containerId: "A",
        });
      })
      .catch((err) => {
        toast.error("Échec de la suppression !", {
          containerId: "A",
        });
      });
  };

  editTag = (tag) => {
    this.setState({
      previewVisible: true,
      previewTitre: "Editer",
      inputValue: tag.tag_libelle,
      selectedTag: tag,
    });
  };

  deleteTag = (tag) =>
    this.setState({
      previewVisible: true,
      previewTitre: "Supprimer",
      inputValue: tag.tag_libelle,
      selectedTag: tag,
    });

  handleCancel = () => {
    this.setState({ previewVisible: false, previewTitre: "" });
  };

  beforeUpload = (file) => {
    const isLt10M = file.size / 1024 / 1024 < 12;
    if (!isLt10M) {
      toast.error("Image supérieure à 10Mo !");
    }

    return isLt10M;
  };

  render() {
    const {
      dossier,
      documents,
      loading,
      tags,
      inputVisible,
      inputValue,
      editInputIndex,
      editInputValue,
      selectedTag,
      previewVisible,
      previewTitre,
    } = this.state;
    const { fromRequete, isArchive } = this.props;

    const filteredDocuments =
      selectedTag && documents.length > 0
        ? documents
            .filter((i) => i.document_tag && i.document_tag.tag_id === selectedTag.tag_id)
            .sort((a, b) => b.document_id - a.document_id)
        : [];

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">Ajouter</div>
      </div>
    );

    const columns = [
      {
        title: "Nom",
        dataIndex: "document_nom",
        key: "document_nom",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) => a.document_nom.localeCompare(b.document_nom),
      },
      {
        title: "Statut",
        dataIndex: "document_yousign_statut",
        key: "document_yousign_statut",
      },
      {
        title: "Actualiser",
        dataIndex: "",
        key: "x",
        render: (document) => (
          <Button disabled={isArchive} onClick={() => this.handleRefresh(document)}>
            <ReloadOutlined />
          </Button>
        ),
      },
      {
        title: "Désactiver signature",
        dataIndex: "",
        key: "x",
        render: (document) => (
          <Popconfirm
            title="Suprimer le lien vers la signature électronique ?"
            onConfirm={() => this.handleDisable(document)}
          >
            <Button disabled={isArchive}>
              <LinkOutlined />
            </Button>
          </Popconfirm>
        ),
      },
      {
        title: "Afficher",
        dataIndex: "",
        key: "x",
        render: (document) => {
          return document.document_yousign_requete_id === null ? (
            <Button
              href={`${Constants.directory}documents/${document.document_dossier.dossier_url}/${selectedTag.tag_libelle}/${document.document_nom}`}
              target="_blank"
            >
              <FolderViewOutlined />
            </Button>
          ) : (
            <Button onClick={() => this.downloadYousign(document)}>
              <FolderViewOutlined />
            </Button>
          );
        },
      },
      {
        title: "Supprimer",
        dataIndex: "",
        key: "x",
        render: (document) => (
          <Popconfirm title="Supprimer le fichier ?" onConfirm={() => this.handleDelete(document)}>
            <Button disabled={isArchive}>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        ),
      },
      {
        title: "Envoi",
        dataIndex: "",
        key: "x",
        render: (document) => (
          <Popconfirm
            title="Envoyer le fichier ?"
            onConfirm={() => this.handleEmail(fromRequete, document)}
          >
            <Button disabled={isArchive}>
              <MailOutlined />
            </Button>
          </Popconfirm>
        ),
      },
      {
        title: "Envoi Yousign",
        dataIndex: "",
        key: "x",
        render: (document) => (
          <Popconfirm
            title="Envoyer le fichier avec YouSign ?"
            onConfirm={() => this.handleSignature(document)}
          >
            <Button disabled={isArchive}>
              <FileProtectOutlined />
            </Button>
          </Popconfirm>
        ),
      },
    ];

    const props = {
      accept: ".pdf, .docx, .jpg, .jpeg, .png",
      multiple: true,
      customRequest: (componentsData) => {
        this.props.saveDocument(componentsData, dossier, selectedTag);
      },
      name: "file",
    };

    const menu = (tag) =>
      tag && (
        <Menu>
          <Menu.Item key="1" onClick={() => this.editTag(tag)}>
            <EditOutlined /> Modifier {<b>{tag.tag_libelle}</b>}
          </Menu.Item>
          <Menu.Item key="2" onClick={() => this.deleteTag(tag)}>
            <DeleteOutlined /> Supprimer {<b>{tag.tag_libelle}</b>}
          </Menu.Item>
        </Menu>
      );

    const footer = (
      <div>
        <Button type="primary" danger onClick={this.handleCancel}>
          Annuler
        </Button>
        <Button type="primary" onClick={this["handle" + previewTitre]}>
          {previewTitre}
        </Button>
      </div>
    );

    return (
      <div style={{ padding: 20 }}>
        <Spin spinning={loading} size="large" tip="Chargement...">
          <Card className="appt-card" bordered={false}>
            <Row>
              <Col offset={3} md={21}>
                {tags
                  .sort((a, b) => a.tag_ordre - b.tag_ordre)
                  .map((tag, i) => {
                    if (editInputIndex === i) {
                      return (
                        <Input
                          ref={this.saveEditInputRef}
                          key={i}
                          size="small"
                          className="tag-input"
                          value={editInputValue}
                          onChange={this.handleEditInputChange}
                          onBlur={this.handleEditInputConfirm}
                          onPressEnter={this.handleEditInputConfirm}
                        />
                      );
                    }

                    const isLongTag = tag.tag_libelle.length > 20;

                    const tagElem = (
                      <Dropdown key={i} menu={menu(tag)} trigger={["contextMenu"]}>
                        <CheckableTag
                          className="edit-tag"
                          key={i}
                          checked={selectedTag === tag}
                          onChange={(checked) => this.handleChange(tag, checked)}
                        >
                          <span>
                            {isLongTag ? `${tag.tag_libelle.slice(0, 20)}...` : tag.tag_libelle}
                          </span>
                        </CheckableTag>
                      </Dropdown>
                    );
                    return isLongTag ? (
                      <Tooltip title={tag.tag_libelle} key={i}>
                        {tagElem}
                      </Tooltip>
                    ) : (
                      tagElem
                    );
                  })}
                {inputVisible && (
                  <Input
                    ref={this.saveInputRef}
                    type="text"
                    size="small"
                    className="tag-input"
                    value={inputValue}
                    onChange={this.handleInputChange}
                    onBlur={this.handleInputConfirm}
                    onPressEnter={this.handleInputConfirm}
                  />
                )}
                {!inputVisible && (
                  <Tag className="site-tag-plus" onClick={this.showInput}>
                    <PlusOutlined /> Ajouter
                  </Tag>
                )}
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col offset={1} md={2}>
                <Upload
                  {...props}
                  disabled={isArchive || !selectedTag}
                  listType="picture-card"
                  showUploadList={false}
                  beforeUpload={this.beforeUpload}
                >
                  {uploadButton}
                </Upload>
              </Col>
              <Col md={16}>
                <Table columns={columns} dataSource={filteredDocuments} />
              </Col>
            </Row>
          </Card>
        </Spin>
        <Modal
          open={previewVisible}
          title={previewTitre}
          onCancel={this.handleCancel}
          footer={footer}
        >
          <Row>
            {previewTitre !== "Supprimer" ? (
              <Input value={inputValue} addonBefore={"Libellé"} onChange={this.handleInputChange} />
            ) : (
              <div style={{ fontSize: 16 }}>
                Supprimer le dossier <strong>{selectedTag.tag_libelle}</strong> ?
              </div>
            )}
          </Row>
        </Modal>
      </div>
    );
  }
}

export default FormDocument;

import {
  DELETE_FAIL_REGLEMENTS,
  DELETE_FAIL_REGLEMENTS_LIGNES,
  DELETE_SUCCESS_REGLEMENTS,
  DELETE_SUCCESS_REGLEMENTS_LIGNES,
  LOAD_FAIL_REGLEMENTS,
  LOAD_SUCCESS_REGLEMENTS,
  SAVE_FAIL_REGLEMENTS,
  SAVE_FAIL_REGLEMENTS_LIGNES,
  SAVE_SUCCESS_REGLEMENTS,
  SAVE_SUCCESS_REGLEMENTS_LIGNES,
  UPDATE_SUCCESS_REGLEMENTS,
  UPDATE_FAIL_REGLEMENTS,
  UPDATE_SUCCESS_ALL_REGLEMENTS,
  UPDATE_FAIL_ALL_REGLEMENTS,
} from "../actions/reglementAction";

import dayjs from "dayjs";

const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS_REGLEMENTS:
      return {
        ...state,
        loaded: true,
        data: action.payload,
        error: null,
      };
    case LOAD_FAIL_REGLEMENTS:
      return {
        ...state,
        loaded: false,
        data: [],
        error: action.error,
      };
    case SAVE_SUCCESS_REGLEMENTS:
      const updatedListeReg = [];
      action.payload.forEach((reg) => {
        reg.reglement_date = dayjs(reg.reglement_date, "DD/MM/YYYY").format("YYYY-MM-DD");
        updatedListeReg.push(reg);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: [...state.data, ...updatedListeReg],
        error: null,
      };
    case SAVE_FAIL_REGLEMENTS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_REGLEMENTS:
      const updatedListe = [];
      state.data.forEach((item) => {
        if (item.reglement_id === action.payload.reglement_id) {
          item = action.payload;
        }
        updatedListe.push(item);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: updatedListe,
        error: null,
      };
    case UPDATE_FAIL_REGLEMENTS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_ALL_REGLEMENTS:
      const _updatedListe = [];
      state.data.forEach((item) => {
        if (action.payload.some((reg) => reg.reglement_id === item.reglement_id)) {
          const updatedReglement = action.payload.find(
            (reg) => reg.reglement_id === item.reglement_id
          );
          item = updatedReglement;
        }
        _updatedListe.push(item);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: _updatedListe,
        error: null,
      };
    case UPDATE_FAIL_ALL_REGLEMENTS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case DELETE_SUCCESS_REGLEMENTS:
      const filtered = state.data.filter((item) => {
        return item.reglement_id !== action.id;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filtered,
        error: null,
      };
    case DELETE_FAIL_REGLEMENTS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case SAVE_SUCCESS_REGLEMENTS_LIGNES:
      const reglements = [];

      state.data.forEach((reglement) => {
        const lignes = action.payload.filter(
          (item) => item.reglement_ligne_reglement_id === reglement.reglement_id
        );
        if (lignes.length > 0) reglement.reglement_ligne = lignes;
        reglements.push(reglement);
      });

      return {
        ...state,
        loading: false,
        loaded: true,
        data: reglements,
        error: null,
      };
    case SAVE_FAIL_REGLEMENTS_LIGNES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case DELETE_SUCCESS_REGLEMENTS_LIGNES:
      const lignesFiltered = state.data.map((reglement) => {
        return reglement.reglement_ligne.filter((item) => {
          return item.reglement_ligne_id !== action.id;
        });
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: lignesFiltered,
        error: null,
      };
    case DELETE_FAIL_REGLEMENTS_LIGNES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    default:
      return state;
  }
}

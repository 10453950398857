import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Spin } from "antd";
import { loadComptes } from "../../store/actions/compteAction";
import { loadLocations } from "../../store/actions/locationAction";
import { loadQuittances, saveQuittance } from "../../store/actions/quittanceAction";
import { loadReglements } from "../../store/actions/reglementAction";
import { loadEcritures, saveEcriture } from "../../store/actions/ecritureAction";
import { saveEmail } from "../../store/actions/emailAction";
import { saveDocument } from "../../store/actions/documentAction";

import FormQuittancement from "../../components/FormQuittancement";

import { TYPE_TAG } from "../../utils/constants";

class Quittancement extends Component {
  componentDidMount() {
    const groupement_id = this.props.utilisateur.groupement_id;
    const societe_id = this.props.utilisateur.societe_id;
    this.props.loadComptes(societe_id);
    this.props.loadLocations(groupement_id);
    this.props.loadQuittances(groupement_id);
    this.props.loadReglements(societe_id);
    this.props.loadEcritures(societe_id);
  }

  render() {
    const {
      utilisateur,
      parametre,
      idLocation,
      comptes,
      journals,
      locations,
      quittances,
      reglements,
      ecritures,
      tags,
    } = this.props;

    return (
      <Spin spinning={!comptes || !locations} size="large" tip="Chargement en cours...">
        <Card title="" bordered={false} className="card-panel">
          <FormQuittancement
            utilisateur={utilisateur}
            parametre={parametre}
            idLocation={idLocation}
            comptes={comptes}
            journals={journals}
            locations={
              locations &&
              locations
                .filter(
                  (item) =>
                    item.location_etat === "r" &&
                    item.location_lot.lot_societe_id === utilisateur.societe_id
                )
                .sort((a, b) => b.location_id - a.location_id)
            }
            quittances={quittances}
            reglements={reglements}
            ecritures={ecritures}
            tags={tags
              .filter((tag) => tag.tag_type === TYPE_TAG[2].value)
              .sort((a, b) => a.tag_ordre - b.tag_ordre)}
            saveQuittance={this.props.saveQuittance}
            saveEcriture={this.props.saveEcriture}
            saveEmail={this.props.saveEmail}
            saveDocument={this.props.saveDocument}
            addPanesRequete={this.props.addPanesRequete}
          />
        </Card>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadComptes: (societe_id) => dispatch(loadComptes(societe_id)),
    loadLocations: (groupement_id) => dispatch(loadLocations(groupement_id)),
    loadQuittances: (groupement_id) => dispatch(loadQuittances(groupement_id)),
    saveQuittance: (jsonData) => dispatch(saveQuittance(jsonData)),
    loadReglements: (societe_id) => dispatch(loadReglements(societe_id)),
    loadEcritures: (jsonData) => dispatch(loadEcritures(jsonData)),
    saveEcriture: (ecriture) => dispatch(saveEcriture(ecriture)),
    saveEmail: (jsonData) => dispatch(saveEmail(jsonData)),
    saveDocument: (jsonData, dossier, tag) => dispatch(saveDocument(jsonData, dossier, tag)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    parametre: state.parametre.data,
    comptes: state.comptes.data,
    journals: state.journals.data,
    locations: state.locations.data,
    quittances: state.quittances.data,
    reglements: state.reglements.data,
    ecritures: state.ecritures.data,
    tags: state.tags.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Quittancement);

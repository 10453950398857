import React, { Component } from "react";
import { connect } from "react-redux";
import FormBalance from "../../../components/FormBalance";
import dayjs from "dayjs"
import { loadComptes } from "../../../store/actions/compteAction";
import { loadEcritures } from "../../../store/actions/ecritureAction";
import { Spin } from "antd";

class Balance extends Component {
  componentDidMount() {
    const societe_id = this.props.utilisateur.societe_id;
    this.props.loadComptes(societe_id);
    this.props.loadEcritures(societe_id);
  }

  render() {
    const { societes, comptes, ecritures, balance } = this.props;

    const societesFiltered = societes.data.filter((s) => s.societe_id === this.props.utilisateur.societe_id);

    return (
      <Spin size="large" tip="Chargement en cours..." spinning={!comptes || !ecritures}>
        {comptes && ecritures && (
          <FormBalance
            societes={societesFiltered}
            comptes={comptes}
            ecritures={
              ecritures &&
              ecritures.sort(
                (a, b) =>
                  dayjs(a.ecriture_date_ecriture, "DD/MM/YYYY").unix() -
                  dayjs(b.ecriture_date_ecriture, "DD/MM/YYYY").unix()
              )
            }
            balance={balance}
          />
        )}
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadComptes: (societe_id) => dispatch(loadComptes(societe_id)),
    loadEcritures: (societe_id) => dispatch(loadEcritures(societe_id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    societes: state.societes,
    comptes: state.comptes.data,
    ecritures: state.ecritures.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Balance);

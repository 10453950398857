import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Spin } from "antd";

import ListEcartReglement from "../../components/ListEcartReglement";
import { columns } from "./columns";

import { loadLocataires } from "../../store/actions/locataireAction";
import { loadLocations } from "../../store/actions/locationAction";
import { loadReglements } from "../../store/actions/reglementAction";
import { loadEcritures } from "../../store/actions/ecritureAction";

class EcartReglement extends Component {
  componentDidMount() {
    const societe_id = this.props.utilisateur.societe_id;
    const groupement_id = this.props.utilisateur.groupement_id;
    this.props.loadLocataires(groupement_id);
    this.props.loadLocations(groupement_id);
    this.props.loadReglements(societe_id);
    this.props.loadEcritures(societe_id);
  }

  render() {
    const { locataires, locations, reglements, ecritures } = this.props;

    return (
      <Spin
        spinning={!locataires || !locations || !reglements || !ecritures}
        size="large"
        tip="Chargement en cours..."
      >
        <Card title="" bordered={false} className="card-panel">
          {locataires && locations && reglements && ecritures && (
            <ListEcartReglement
              listData={locataires}
              tableOption={columns}
              locations={locations}
              reglements={reglements}
              ecritures={ecritures}
            />
          )}
        </Card>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadLocataires: (groupement_id) => dispatch(loadLocataires(groupement_id)),
    loadLocations: (groupement_id) => dispatch(loadLocations(groupement_id)),
    loadReglements: (societe_id) => dispatch(loadReglements(societe_id)),
    loadEcritures: (societe_id) => dispatch(loadEcritures(societe_id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    locataires: state.locataires.data,
    locations: state.locations.data,
    reglements: state.reglements.data,
    ecritures: state.ecritures.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EcartReglement);

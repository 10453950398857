import { DownOutlined } from "@ant-design/icons";
import { Avatar, Dropdown } from "antd";
import React from "react";

const UserInfo = (props) => {
  const { utilisateur, collapsed } = props;

  const classNames =
    utilisateur.societe_id === 19
      ? "ast-user-info-inner ast-user-info ast-user-glam"
      : "ast-user-info-inner ast-user-info";

  const items = [
    {
      key: "1",
      label: "Mon profil",
    },
    {
      key: "2",
      label: "Déconnexion",
    },
  ];

  return (
    <div className={classNames}>
      <Dropdown menu={{ items }} trigger={["click"]}>
        <a className="ast-user-info-inner" href="/" onClick={(e) => e.preventDefault()}>
          <Avatar className="ast-user-info-avatar" size={40}>
            {utilisateur.utilisateur_nom.charAt()}
          </Avatar>
          {!collapsed && (
            <span className="ast-user-info-content">
              <span className="ast-user-name-info">
                <h4 className="ast-user-name text-truncate">{utilisateur.utilisateur_nom}</h4>
                <span className="ast-user-arrow">
                  <DownOutlined />
                </span>
              </span>
              <span className="ast-user-societe text-truncate">
                {utilisateur.utilisateur_etablissement.etablissement_societe.societe_raison_sociale}
              </span>
            </span>
          )}
        </a>
      </Dropdown>
    </div>
  );
};

export default UserInfo;

import React, { useRef } from "react";
import { Row, Table, Typography } from "antd";

const { Title } = Typography;

const ListActions = (props) => {
  const { listData, tableOption, etapes } = props;

  const componentRef = useRef();

  return (
    <>
      <div style={{ padding: 20 }}>
        <Row>
          <Title level={2}>Liste des Timers par étapes</Title>
        </Row>
      </div>
      <div ref={componentRef}>
        {listData && (
          <Table
            className="list-data"
            dataSource={listData}
            columns={tableOption(etapes)}
            pagination={false}
            rowKey="etape_id"
          />
        )}
      </div>
    </>
  );
};

export default ListActions;

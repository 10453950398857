import {
  LOAD_SUCCESS_ETAPE_TEMPLATE,
  LOAD_FAIL_ETAPE_TEMPLATE,
  SAVE_SUCCESS_ETAPE_TEMPLATE,
  SAVE_FAIL_ETAPE_TEMPLATE,
  DELETE_SUCCESS_ETAPE_TEMPLATE,
  DELETE_FAIL_ETAPE_TEMPLATE,
} from "../actions/etapeTemplateAction";

const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS_ETAPE_TEMPLATE:
      return {
        ...state,
        loaded: true,
        data: action.payload,
        error: null,
      };
    case LOAD_FAIL_ETAPE_TEMPLATE:
      return {
        ...state,
        loaded: false,
        data: null,
        error: action.error,
      };
    case SAVE_SUCCESS_ETAPE_TEMPLATE:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: [...state.data, action.payload],
        error: null,
      };
    case SAVE_FAIL_ETAPE_TEMPLATE:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };

    case DELETE_SUCCESS_ETAPE_TEMPLATE:
      const filtered = state.data.filter(
        (item) => item.etape_template_template.template_id !== action.idTemplate
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filtered,
        error: null,
      };
    case DELETE_FAIL_ETAPE_TEMPLATE:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: state.data,
        error: action.payload,
      };
    default:
      return state;
  }
}

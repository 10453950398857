import { langueToAdapted } from "./langueAdapter";
import { templateToAdapted } from "./templateAdapter";

export function templateLanguesToAdapted(items) {
  return items
    ? items.map((item) => {
        return templateLangueToAdapted(item);
      })
    : null;
}

export function templateLangueToAdapted(item) {
  //Resource
  return item
    ? {
        template_langue_template: templateToAdapted(item.template),
        template_langue_langue: langueToAdapted(item.langue),
        template_langue_titre: item.titre,
        template_langue_text: item.text,
      }
    : null;
}

export function adaptedToTemplateLangue(item) {
  //Model
  return item
    ? {
        template_id: item.template_langue_template_id,
        langue_id: item.template_langue_langue_id,
        titre: item.template_titre,
        text: item.template_text,
      }
    : null;
}

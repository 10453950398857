import { HttpMethod, initFetch } from "../../utils/fetcher";
import {
  adaptedToSociete,
  societesToAdapted,
  societeToAdapted,
} from "../../adapter/societeAdapter";

export const LOAD_SUCCESS_SOCIETES = "LOAD_SUCCESS_SOCIETES";
export const LOAD_FAIL_SOCIETES = "LOAD_FAIL_SOCIETES";
export const SAVE_SUCCESS_SOCIETES = "SAVE_SUCCESS_SOCIETES";
export const SAVE_FAIL_SOCIETES = "SAVE_FAIL_SOCIETES";
export const UPDATE_SUCCESS_SOCIETES = "UPDATE_SUCCESS_SOCIETES";
export const UPDATE_FAIL_SOCIETES = "UPDATE_FAIL_SOCIETES";
export const DELETE_SUCCESS_SOCIETES = "DELETE_SUCCESS_SOCIETES";
export const DELETE_FAIL_SOCIETES = "DELETE_FAIL_SOCIETES";

export function loadSocietes() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`societes`, HttpMethod.GET);

      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.societe);
          dispatch({
            type: LOAD_SUCCESS_SOCIETES,
            payload: societesToAdapted(res.societe),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_SOCIETES,
            error: err,
          });
        });
    });
  };
}

export function saveSociete(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`societes`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToSociete(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.societe);
          dispatch({
            type: SAVE_SUCCESS_SOCIETES,
            payload: societeToAdapted(res.societe),
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_SOCIETES,
            error: err,
          })
        );
    });
  };
}

export function updateSociete(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`societes/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToSociete(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.societe);
          dispatch({
            type: UPDATE_SUCCESS_SOCIETES,
            payload: societeToAdapted(res.societe),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_SOCIETES,
            error: err,
          })
        );
    });
  };
}

export function deleteSociete(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`societes/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.societe);
          dispatch({
            type: DELETE_SUCCESS_SOCIETES,
            payload: societeToAdapted(res.societe),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_SOCIETES,
            error: err,
          });
        });
    });
  };
}

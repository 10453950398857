import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Spin } from "antd";
import ListLocation from "../../components/ListLocation";
import { columns, columnsProprietaire } from "./columns";
import { loadLocations } from "../../store/actions/locationAction";
import { loadReglements } from "../../store/actions/reglementAction";
import { loadQuittances } from "../../store/actions/quittanceAction";

class Locations extends Component {
  componentDidMount() {
    const groupement_id = this.props.utilisateur.groupement_id;
    const societe_id = this.props.utilisateur.societe_id;    
    this.props.loadLocations(groupement_id);
    this.props.loadReglements(societe_id);
    this.props.loadQuittances(groupement_id);
  }

  render() {
    const { locations, reglements, etat, quittances, utilisateur } = this.props;

    return (
      <Spin spinning={!locations} size="large" tip="Chargement en cours...">
        <Card title="" bordered={false} className="card-panel">
          <ListLocation
            listData={
              locations &&
              locations
                .filter((item) => item.location_etat === etat && item.location_lot.lot_societe_id === utilisateur.societe_id)
                .sort((a, b) => b.location_id - a.location_id)
            }
            reglements={reglements}
            quittances={quittances}
            tableOption={etat === "b" ? columnsProprietaire : columns}
            etat={etat}
            addPanesRequete={this.props.addPanesRequete}
            titre={true}
          />
        </Card>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadLocations: (groupement_id) => dispatch(loadLocations(groupement_id)),
    loadReglements: (societe_id) => dispatch(loadReglements(societe_id)),
    loadQuittances: (groupement_id) => dispatch(loadQuittances(groupement_id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    locations: state.locations.data,
    reglements: state.reglements.data,
    quittances: state.quittances.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Locations);

import { HttpMethod, initFetch } from "../../utils/fetcher";
import { adaptedToPhotos, photoToAdapted } from "../../adapter/photoAdapter";

export const SAVE_FAIL_PHOTOS = "SAVE_FAIL_PHOTOS";
export const SAVE_SUCCESS_IMAGES = "SAVE_SUCCESS_IMAGES";
export const SAVE_FAIL_IMAGES = "SAVE_FAIL_IMAGES";
export const UPDATE_SUCCESS_PHOTOS = "UPDATE_SUCCESS_PHOTOS";
export const UPDATE_FAIL_PHOTOS = "UPDATE_FAIL_PHOTOS";
export const DELETE_SUCCESS_PHOTOS = "DELETE_SUCCESS_PHOTOS";
export const DELETE_FAIL_PHOTOS = "DELETE_FAIL_PHOTOS";

export const SAVE_SUCCESS_LOT_PHOTO = "SAVE_SUCCESS_LOT_PHOTO";
export const UPDATE_SUCCESS_LOT_PHOTO = "UPDATE_SUCCESS_LOT_PHOTO";
export const DELETE_SUCCESS_LOT_PHOTO = "DELETE_SUCCESS_LOT_PHOTO";

export function savePhoto(componentsData, order, lot_id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`uploadPhoto`, HttpMethod.POST, {
        Authorization: true,
        formData: true,
        pdf: false,
      });
      let formData = new FormData();
      formData.append("file", componentsData.file);
      formData.append("libelle", componentsData.file.name);
      formData.append("chemin", `Lots/${lot_id}/`);
      formData.append("ordre", order);
      formData.append("lot_id", lot_id);
      formData.append("edition", false);
      // make async call to database
      fetch(url, { ...params, body: formData })
        .then((res) => res.json())
        .then((res) => {
          resolve(photoToAdapted(res.photo));
          dispatch({
            type: SAVE_SUCCESS_LOT_PHOTO,
            payload: photoToAdapted(res.photo),
            id: lot_id,
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_PHOTOS,
            error: err,
          })
        );
    });
  };
}

export function saveImage(componentsData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`uploadImage`, HttpMethod.POST, {
        Authorization: true,
        formData: true,
        pdf: false,
      });
      let formData = new FormData();
      formData.append("file", componentsData);
      formData.append("libelle", componentsData.lastModified + "_" + componentsData.name);
      // make async call to database
      fetch(url, { ...params, body: formData })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
          dispatch({
            type: SAVE_SUCCESS_IMAGES,
            payload: res,
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_IMAGES,
            error: err,
          })
        );
    });
  };
}

export function updatePhotos(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`photos/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToPhotos(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(photoToAdapted(res.photo));
          dispatch({
            type: UPDATE_SUCCESS_LOT_PHOTO,
            payload: photoToAdapted(res.photo),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_PHOTOS,
            error: err,
          })
        );
    });
  };
}

export function deletePhoto(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`photos/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.photo);
          dispatch({
            type: DELETE_SUCCESS_LOT_PHOTO,
            payload: photoToAdapted(res.photo),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_PHOTOS,
            error: err,
          });
        });
    });
  };
}

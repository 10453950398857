import React, { Component } from "react";
import Constants from "../../../utils/constants";

export default class DescriptifPDF extends Component {
  render() {
    const { lot, pieces } = this.props;

    let chemin = "";

    return (
      <div>
        <table>
          <thead>
            <tr>
              <th width="100%">
                <strong>{lot.lot_societe.societe_raison_sociale}</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td width="50%" align="left">
                {lot.lot_societe.societe_adresse.adresse_numero}{" "}
                {lot.lot_societe.societe_adresse.adresse_voie}
              </td>
              <td width="50%" align="right">
                Tél : {lot.lot_societe.societe_telephone}
              </td>
            </tr>
            <tr>
              <td width="50%" align="left">
                {lot.lot_societe.societe_adresse.adresse_code_postal}{" "}
                {lot.lot_societe.societe_adresse.adresse_ville}
              </td>
              <td width="50%" align="right">
                E-mail : {lot.lot_societe.societe_email}
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        <div>
          <p>
            <strong>
              {lot.lot_reference}
              {" - "}
              {lot.lot_designation}
            </strong>
          </p>
          <p>
            {lot.lot_adresse.adresse_numero} {lot.lot_adresse.adresse_voie}
          </p>
          <p>
            {lot.lot_adresse.adresse_code_postal} {lot.lot_adresse.adresse_ville}
          </p>
        </div>
        <div>
          <p>
            <strong>DESCRITPIF</strong>
          </p>
          <p>
            {"Nombre de couchages : "}
            {lot.lot_couchage}
          </p>
          <p>
            {"Nombre de pièces : "}
            {lot.lot_nb_pieces}
          </p>
          <p>
            {"Surface totale : "}
            {lot.lot_surface}
            {" m²"}
          </p>
          <p>
            {"Nombre de niveaux : "}
            {lot.lot_niveau}
          </p>
          <p>
            {"Nombre de chambres : "}
            {"à calculer"}
          </p>
        </div>
        <div>
          <table className="table table-striped" border="1">
            <thead>
              <tr>
                <th width="50%" scope="col" align="center">
                  <strong>EQUIPEMENTS</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {lot.lot_equipements.map((equipement, i) => {
                return (
                  <tr>
                    <td width="50%" align="left">
                      {equipement.equipement_libelle}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div>
          <table className="table table-striped" border="1">
            <thead>
              <tr>
                <th width="100%" scope="col" align="center">
                  <strong>PIECES</strong>
                </th>
              </tr>
            </thead>
          </table>
        </div>
        {pieces.map((piece, i) => {
          return (
            <div>
              <table className="table table-striped" border="1">
                <thead>
                  <tr>
                    <th width="30%" scope="col" align="center">
                      <strong>{piece.piece_libelle}</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {piece.piece_contenu.map((contenu, j) => {
                    if (contenu.piece_contenu_champ_perso.champ_perso_groupe_type === 4) {
                      if (contenu.piece_contenu_champ_perso.champ_perso_type === 1) {
                        if (
                          contenu.piece_contenu_valeur === "true" ||
                          contenu.piece_contenu_valeur === "1"
                        ) {
                          return (
                            <tr>
                              <td width="30%" align="left">
                                {contenu.piece_contenu_champ_perso.champ_perso_libelle}
                              </td>
                            </tr>
                          );
                        }
                      }
                      if (
                        contenu.piece_contenu_champ_perso.champ_perso_type === 2 ||
                        contenu.piece_contenu_champ_perso.champ_perso_type === 3
                      ) {
                        if (contenu.piece_contenu_valeur !== "") {
                          return (
                            <tr>
                              <td width="30%" align="left">
                                {contenu.piece_contenu_champ_perso.champ_perso_libelle}{" "}
                                {contenu.piece_contenu_valeur}
                              </td>
                            </tr>
                          );
                        }
                      }
                    }
                    return null;
                  })}
                </tbody>
              </table>
            </div>
          );
        })}
        ;
        <div>
          <table className="table table-striped" border="1">
            <thead>
              <tr>
                <th width="50%" scope="col" align="center">
                  <strong>ELEMENTS</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {lot.lot_elements.map((element, i) => {
                if (element.lot_champ_perso_champ_perso.champ_perso_groupe_type === 3) {
                  if (element.lot_champ_perso_champ_perso.champ_perso_type === 1) {
                    if (element.lot_champ_perso_valeur === "true") {
                      return (
                        <tr>
                          <td width="50%" align="left">
                            {element.lot_champ_perso_champ_perso.champ_perso_libelle}
                          </td>
                        </tr>
                      );
                    }
                  }
                  if (
                    element.lot_champ_perso_champ_perso.champ_perso_type === 2 ||
                    element.lot_champ_perso_champ_perso.champ_perso_type === 3
                  ) {
                    if (element.lot_champ_perso_valeur !== "") {
                      return (
                        <tr>
                          <td width="50%" align="left">
                            {element.lot_champ_perso_champ_perso.champ_perso_libelle}{" "}
                            {element.lot_champ_perso_valeur}
                          </td>
                        </tr>
                      );
                    }
                  }
                }
                return null;
              })}
            </tbody>
          </table>
        </div>
        <div>
          <table className="table table-striped" border="1">
            <thead>
              <tr>
                <th width="100%" scope="col" align="center">
                  <strong>DESCRIPTIF</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {lot.lot_descriptions.map((desc, i) => {
                if (i === 0) {
                  return (
                    <tr>
                      <td width="100%" align="left">
                        {desc.description_titre}
                        <br />
                        {desc.description_html}
                      </td>
                    </tr>
                  );
                }
                return null;
              })}
            </tbody>
          </table>
        </div>
        <div>
          <table className="table table-striped" border="1">
            <thead>
              <tr>
                <th width="100%" scope="col" align="center">
                  <strong>PHOTOS</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {lot.lot_photos.map((photo, i) => {
                if (photo.photo_edition) {
                  chemin = `${Constants.mode}://${Constants.domain}:${Constants.port}/api/photos/display/${photo.photo_id}`;
                  return (
                    <tr>
                      <td width="100%" align="center">
                        <img align="center" src={chemin} alt="" />
                      </td>
                    </tr>
                  );
                }
                return null;
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

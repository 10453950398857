import {
  LOAD_SUCCESS_CAUTION,
  LOAD_FAIL_CAUTION,
  SAVE_SUCCESS_CAUTION,
  SAVE_FAIL_CAUTION,
  UPDATE_SUCCESS_CAUTION,
  UPDATE_FAIL_CAUTION,
  DELETE_SUCCESS_CAUTION,
  DELETE_FAIL_CAUTION,
} from "../actions/cautionAction";

const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS_CAUTION:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };
    case LOAD_FAIL_CAUTION:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error,
      };
    case SAVE_SUCCESS_CAUTION:
      const liste = state.data.concat(action.payload);
      return {
        ...state,
        loading: false,
        loaded: false,
        data: liste,
        error: null,
      };
    case SAVE_FAIL_CAUTION:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_CAUTION:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };
    case UPDATE_FAIL_CAUTION:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case DELETE_SUCCESS_CAUTION:
      const filtered = state.data.filter((item) => {
        return item.groupe_id !== action.id;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filtered,
        error: null,
      };
    case DELETE_FAIL_CAUTION:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    default:
      return state;
  }
}

export function groupementToAdapted(item) {
  return item
    ? {
        groupement_id: item.id,
        groupement_libelle: item.libelle,
        groupement_logo_width: item.logo_width,
        groupement_logo_height: item.logo_height,
      }
    : null;
}

export function adaptedToAgent(item) {
  // Non implémenté
  return item;
}

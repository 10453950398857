import {
  adaptedToLocataireAffectation,
  locataireAffectationToAdapted,
} from "../../adapter/locataireAffectationAdapter";
import { HttpMethod, initFetch } from "../../utils/fetcher";

export const SAVE_SUCCESS_LOCATAIRES_AFFECTATIONS = "SAVE_SUCCESS_LOCATAIRES_AFFECTATIONS";
export const SAVE_FAIL_LOCATAIRES_AFFECTATIONS = "SAVE_FAIL_LOCATAIRES_AFFECTATIONS";

export function saveLocataireAffectation(jsonData, locataire_id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch("locatairesAffectations", HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToLocataireAffectation(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(locataireAffectationToAdapted(res.locataire_affectation));
          dispatch({
            type: SAVE_SUCCESS_LOCATAIRES_AFFECTATIONS,
            payload: locataireAffectationToAdapted(res.locataire_affectation),
            id: locataire_id,
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_LOCATAIRES_AFFECTATIONS,
            error: err,
          })
        );
    });
  };
}

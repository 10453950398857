export function dossiersToAdapted(items) {
  return items
    ? items.map((item) => {
        return dossierToAdapted(item);
      })
    : null;
}

export function dossierToAdapted(item) {
  return item
    ? {
        dossier_id: item.id,
        dossier_url: item.url,
      }
    : null;
}

export function adaptedToDossier(item) {
  return item
    ? {
        url: item.dossier_url,
      }
    : null;
}

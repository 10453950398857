import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Form, Row, Select, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import FormTypePaiement from "../FormTypePaiement";

const { Option, OptGroup } = Select;

const SelectTypePaiement = (props) => {
  const [typePaiement, setTypePaiement] = useState(null);
  const { listData } = props;

  // Référence du formulaire
  const formRefTypePaiement = useRef();

  useEffect(() => {
    typePaiement && onSelectTypePaiement(typePaiement.type_paiement_id);
  });

  function onClickAddTypePaiement() {
    setTypePaiement(null);
  }

  function onSelectTypePaiement(id) {
    formRefTypePaiement.current.setFieldsValue({ type_paiement_id: id });
    const typePaiement = props.listData.find((e) => e.type_paiement_id === id);
    setTypePaiement(typePaiement);
  }

  const addTypePaiementButton = (
    <Button type="primary" htmlType="reset" onClick={onClickAddTypePaiement}>
      <PlusOutlined />
    </Button>
  );

  return (
    <Spin spinning={false} size="large" tip="Chargement...">
      <Form ref={formRefTypePaiement}>
        <Row gutter={12} style={{ padding: 20 }}>
          <Col span={11}>
            <Form.Item name="type_paiement_id">
              <Select
                showSearch
                optionLabelProp="label"
                placeholder={"Type de paiement"}
                onSelect={onSelectTypePaiement}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <OptGroup label={"Type de paiement"}>
                  {listData &&
                    listData.map((item, i) => {
                      return (
                        <Option
                          key={i}
                          value={item.type_paiement_id}
                          label={`${item.type_paiement_libelle}`}
                        >
                          <Row>
                            <Col sm={8}>{`${item.type_paiement_libelle}`}</Col>
                          </Row>
                        </Option>
                      );
                    })}
                </OptGroup>
              </Select>
            </Form.Item>
          </Col>
          <Col span={1}>
            <Form.Item>{addTypePaiementButton}</Form.Item>
          </Col>
        </Row>
      </Form>
      <FormTypePaiement
        typePaiement={typePaiement}
        journals={props.journals}
        handleSubmit={props.saveTypePaiement}
        handleEdit={props.updateTypePaiement}
        handleDelete={props.deleteTypePaiement}
      />
    </Spin>
  );
};

export default SelectTypePaiement;

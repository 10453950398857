import React, { Component } from "react";
import TableEcriture from "../../../components/TableEcriture/index";
import { connect } from "react-redux";

import {
  saveEcriture,
  updateEcriture,
  deleteEcriture,
  loadEcritures,
} from "../../../store/actions/ecritureAction";
import { Spin } from "antd";

class Recherche extends Component {
  componentDidMount() {
    const societe_id = this.props.utilisateur.societe_id;
    this.props.loadEcritures(societe_id);
  }

  render() {
    const { utilisateur, ecritures } = this.props;

    return (
      <Spin size="large" tip="Chargement en cours..." spinning={!ecritures}>
        <div style={{ padding: 10 }}>
          <TableEcriture
            utilisateur={utilisateur}
            ecritures={ecritures}
            saveEcriture={this.props.saveEcriture}
            addPanesEcriture={this.props.addPanesEcriture}
          />
        </div>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadEcritures: (societe_id) => dispatch(loadEcritures(societe_id)),
    saveEcriture: (ecriture) => dispatch(saveEcriture(ecriture)),
    updateEcriture: (ecriture, id) => dispatch(updateEcriture(ecriture, id)),
    deleteEcriture: (id) => dispatch(deleteEcriture(id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    ecritures: state.ecritures.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Recherche);

import {
  adaptedToJournals,
  journalToAdapted,
  journalsToAdapted,
} from "../../adapter/journalAdapter";
import { HttpMethod, initFetch } from "../../utils/fetcher";

export const LOAD_SUCCESS_JOURNALS = "LOAD_SUCCESS_JOURNALS";
export const LOAD_FAIL_JOURNALS = "LOAD_FAIL_JOURNALS";
export const SAVE_SUCCESS_JOURNALS = "SAVE_SUCCESS_JOURNALS";
export const SAVE_FAIL_JOURNALS = "SAVE_FAIL_JOURNALS";
export const UPDATE_SUCCESS_JOURNALS = "UPDATE_SUCCESS_JOURNALS";
export const UPDATE_FAIL_JOURNALS = "UPDATE_FAIL_JOURNALS";
export const DELETE_SUCCESS_JOURNALS = "DELETE_SUCCESS_JOURNALS";
export const DELETE_FAIL_JOURNALS = "DELETE_FAIL_JOURNALS";

export function loadJournals() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`journals`, HttpMethod.GET);

      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.journal);
          dispatch({
            type: LOAD_SUCCESS_JOURNALS,
            payload: journalsToAdapted(res.journal),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_JOURNALS,
            error: err,
          });
        });
    });
  };
}

export function saveJournal(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`journals`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToJournals(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.journal);
          dispatch({
            type: SAVE_SUCCESS_JOURNALS,
            payload: journalToAdapted(res.journal),
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_JOURNALS,
            error: err,
          })
        );
    });
  };
}

export function updateJournal(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`journals/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToJournals(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.journal);
          dispatch({
            type: UPDATE_SUCCESS_JOURNALS,
            payload: journalToAdapted(res.journal),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_JOURNALS,
            error: err,
          })
        );
    });
  };
}

export function deleteJournal(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`journals/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.journal);
          dispatch({
            type: DELETE_SUCCESS_JOURNALS,
            payload: journalToAdapted(res.journal),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_JOURNALS,
            error: err,
          });
        });
    });
  };
}

import React, { Component } from "react";

import { Row, Col, Form, Input, Spin, Radio, Card } from "antd";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const { TextArea } = Input;

class FormDescription extends Component {
  state = {
    lot: null,
    langues: [],
    loading: true,
  };

  componentDidMount() {
    if (this.props.lot !== null && this.props.lot !== undefined) {
      this.initEdit(this.props.lot);
    } else {
      this.initCreate();
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.lot !== null && props.lot !== undefined) {
      this.initEdit(props.lot);
    } else {
      this.initCreate();
    }
  }

  initCreate = () => {
    this.setState({ lot: null });
  };

  initEdit = (lot) => {
    const langues = this.props.langues;
    this.setState({ lot, langues, loading: false });
  };

  onChangeLangue = (e) => {
    this.props.onChangeLangue(e.target.value);
  };

  onChangeTitre = (e) => {
    this.props.onChangeTitre(e.target.value);
  };

  onChangeResume = (e) => {
    this.props.onChangeResume(e.target.value);
  };

  onChangeUrl = (e) => {
    this.props.onChangeUrl(e.target.value);
  };

  onEditorStateChange = (editorState) => {
    this.props.onEditorStateChange(editorState);
  };

  onChangeIndication = (e) => {
    this.props.onChangeIndication(e.target.value);
  };

  onChangeDescriptionLibre = (e) => {
    this.props.onChangeDescriptionLibre(e.target.value);
  };

  render() {
    const { langues, loading, contentState } = this.state;
    const { editorState, langueSelected, isArchive } = this.props;

    return (
      <div>
        <Spin spinning={loading} size="large" tip="Chargement...">
          <Col md={16}>
            <Card className="appt-card">
              <Row>
                <Col md={8}>
                  <Radio.Group onChange={this.onChangeLangue} defaultValue={langueSelected}>
                    {langues &&
                      langues.map((item, i) => {
                        return (
                          <Radio.Button key={i} value={item.langue_id}>
                            {item.langue_code}
                          </Radio.Button>
                        );
                      })}
                  </Radio.Group>
                </Col>
                {/* <Col offset={13} md={3}>
                  <Popconfirm
                    title="Confirmer pour remplacer les descriptifs de ce lot"
                    onConfirm={this.props.onClickDescriptifAuto}
                  >
                    <Button disabled={isArchive}>Descriptifs auto</Button>
                  </Popconfirm>
                </Col> */}
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <Form.Item name="description_titre">
                        <Input
                          disabled={isArchive}
                          onChange={this.onChangeTitre}
                          placeholder="Titre"
                          rules={[
                            {
                              required: true,
                              message: "Titre obligatoire",
                            },
                          ]}
                        />
                      </Form.Item>
                      <Form.Item name="description_resume">
                        <Input
                          disabled={isArchive}
                          onChange={this.onChangeResume}
                          placeholder="Accroche"
                        />
                      </Form.Item>
                      <Form.Item name="description_lot_url">
                        <Input
                          disabled={isArchive}
                          onChange={this.onChangeUrl}
                          placeholder="Lien URL"
                        />
                      </Form.Item>
                      <Form.Item name="description_indication_acces">
                        <TextArea
                          disabled={isArchive}
                          onChange={this.onChangeIndication}
                          placeholder="Indications d'accès"
                          autoSize={{ minRows: 2, maxRows: 4 }}
                        />
                      </Form.Item>
                      <Form.Item name="description_html">
                        <Editor
                          initialContentState={contentState}
                          editorState={editorState}
                          toolbarClassName="toolbar-class"
                          wrapperClassName="wrapper-class"
                          editorClassName="editor-class"
                          onEditorStateChange={this.onEditorStateChange}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
          {/* <Col md={12}>
            <Card className="appt-card">
              <Row>
                <Col>
                  <Form.Item name="description_libre">
                    <TextArea
                      disabled={isArchive}
                      onChange={this.onChangeDescriptionLibre}
                      placeholder="Description"
                      autoSize={{ minRows: 4, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col> */}
        </Spin>
      </div>
    );
  }
}

export default FormDescription;

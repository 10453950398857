import React, { useState } from "react";
import { Select } from "antd";
import { HttpMethod, initFetch } from "../../utils/fetcher";
import { proprietaireToAdapted } from "../../adapter/proprietaireAdapter";
import { locataireToAdapted } from "../../adapter/locataireAdapter";
import { lotToAdapted } from "../../adapter/lotAdapter";

const { Option } = Select;

let timeout;
let currentValue;

const customFetch = (value, callback, utilisateur) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }

  currentValue = value;

  const search = () => {
    const { url, params } = initFetch(
      `search/${value}/groupement/${utilisateur.groupement_id}`,
      HttpMethod.GET
    );
    fetch(url, params)
      .then((res) => res.json())
      .then((res) => {
        if (currentValue === value) {
          const data = res.map((item, i) => {
            let result = {};
            if (item.type === "personnes_proprietaires") {
              result = {
                key: i,
                value: item,
                text: `PROPRIETAIRE - ${item.searchable.code} ${item.searchable.personne.nom} ${item.searchable.personne.prenom}`,
              };
            }
            if (item.type === "lots") {
              result = {
                key: i,
                value: item,
                text: `LOT - ${item.searchable.lot_reference}`,
              };
            }
            if (item.type === "personnes_locataires") {
              result = {
                key: i,
                value: item,
                text: `LOCATAIRE - ${item.searchable.code} ${item.searchable.personne.nom} ${item.searchable.personne.prenom}`,
              };
            }
            return result;
          });
          callback(data);
        }
      });
  };

  timeout = setTimeout(search, 300);
};

const SearchInput = (props) => {
  const [data, setData] = useState([]);
  const [value] = useState();
  const { utilisateur } = props;

  const handleSearch = (newValue) => {
    if (newValue) {
      customFetch(newValue, setData, utilisateur);
    } else {
      setData([]);
    }
  };

  const handleChange = (value) => {
    const obj = data.find((item) => item.key === parseInt(value));
    switch (obj.value.type) {
      case "personnes_proprietaires":
        props.addPanesPersonne(proprietaireToAdapted(obj.value.searchable));
        break;
      case "lots":
        props.addPanesLot(lotToAdapted(obj.value.searchable));
        break;
      case "personnes_locataires":
        props.addPanesPersonne(locataireToAdapted(obj.value.searchable), false);
        break;
      default:
        break;
    }
    // setValue(value);
  };

  const options = data.map((d) => <Option key={d.key}>{d.text}</Option>);

  return (
    <Select
      showSearch
      value={value}
      placeholder={props.placeholder}
      style={props.style}
      defaultActiveFirstOption={false}
      suffixIcon={null}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={null}
    >
      {options}
    </Select>
  );
};

export default SearchInput;

import { adaptedToLocationLigne, locationLigneToAdapted } from "../../adapter/locationLigneAdapter";
import { HttpMethod, initFetch } from "../../utils/fetcher";

export const SAVE_SUCCESS_LOCATIONS_LIGNES = "SAVE_SUCCESS_LOCATIONS_LIGNES";
export const SAVE_FAIL_LOCATIONS_LIGNES = "SAVE_FAIL_LOCATIONS_LIGNES";
export const UPDATE_SUCCESS_LOCATIONS_LIGNES = "UPDATE_SUCCESS_LOCATIONS_LIGNES";
export const UPDATE_FAIL_LOCATIONS_LIGNES = "UPDATE_FAIL_LOCATIONS_LIGNES";
export const DELETE_SUCCESS_LOCATIONS_LIGNES = "DELETE_SUCCESS_LOCATIONS_LIGNES";
export const DELETE_FAIL_LOCATIONS_LIGNES = "DELETE_FAIL_LOCATIONS_LIGNES";

export function saveLocationLigne(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`locationsLignes`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToLocationLigne(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.location_ligne);
          dispatch({
            type: SAVE_SUCCESS_LOCATIONS_LIGNES,
            payload: locationLigneToAdapted(res.location_ligne),
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_LOCATIONS_LIGNES,
            error: err,
          })
        );
    });
  };
}

export function updateLocationLigne(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`locationsLignes/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToLocationLigne(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.location_ligne);
          dispatch({
            type: UPDATE_SUCCESS_LOCATIONS_LIGNES,
            payload: locationLigneToAdapted(res.location_ligne),
            id: id,
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_LOCATIONS_LIGNES,
            error: err,
          })
        );
    });
  };
}

export function deleteLocationLigne(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`locationsLignes/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.location_ligne);
          dispatch({
            type: DELETE_SUCCESS_LOCATIONS_LIGNES,
            payload: locationLigneToAdapted(res.location_ligne),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_LOCATIONS_LIGNES,
            error: err,
          });
        });
    });
  };
}

import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Form, Row, Select, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import FormJournal from "../FormJournal";

const { Option, OptGroup } = Select;

const SelectJournal = (props) => {
  const [journal, setJournal] = useState(null);
  const { listData } = props;

  // Référence du formulaire
  const formRefJournal = useRef();

  useEffect(() => {
    journal && onSelectJournal(journal.journal_id);
  });

  function onClickAddJournal() {
    setJournal(null);
  }

  function onSelectJournal(id) {
    formRefJournal.current.setFieldsValue({ journal_id: id });
    const journal = props.listData.find((e) => e.journal_id === id);
    setJournal(journal);
  }

  const addJournalButton = (
    <Button type="primary" htmlType="reset" onClick={onClickAddJournal}>
      <PlusOutlined />
    </Button>
  );

  return (
    <Spin spinning={false} size="large" tip="Chargement...">
      <div style={{ padding: 20 }}>
        <Form ref={formRefJournal}>
          <Row>
            <Col span={11}>
              <Form.Item name="journal_id">
                <Select
                  showSearch
                  optionLabelProp="label"
                  placeholder={"Journal"}
                  onSelect={onSelectJournal}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <OptGroup label={"Journal"}>
                    {listData &&
                      listData.map((item, i) => {
                        return (
                          <Option
                            key={i}
                            value={item.journal_id}
                            label={`${item.journal_code} - ${item.journal_libelle}`}
                          >
                            <Row>
                              <Col sm={8}>{`${item.journal_code} - ${item.journal_libelle}`}</Col>
                            </Row>
                          </Option>
                        );
                      })}
                  </OptGroup>
                </Select>
              </Form.Item>
            </Col>
            <Col span={1}>
              <Form.Item>{addJournalButton}</Form.Item>
            </Col>
          </Row>
        </Form>
        <FormJournal
          utilisateur={props.utilisateur}
          journal={journal}
          societes={props.societes}
          comptes={props.comptes}
          typesPaiements={props.typesPaiements}
          handleSubmit={props.saveJournal}
          handleEdit={props.updateJournal}
        />
      </div>
    </Spin>
  );
};

export default SelectJournal;

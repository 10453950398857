import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Spin } from "antd";

import { loadAgents } from "../../store/actions/agentAction";
import { loadLots } from "../../store/actions/lotAction";
import { loadLocations } from "../../store/actions/locationAction";
import { loadMenages } from "../../store/actions/menageAction";

import { columns } from "./columns";

import ListMenage from "../../components/ListMenage";

class Menages extends Component {
  componentDidMount() {
    const groupement_id = this.props.utilisateur.groupement_id;
    const societe_id = this.props.utilisateur.societe_id;
    this.props.loadAgents(groupement_id);
    this.props.loadLots(societe_id);
    this.props.loadLocations(groupement_id);
    this.props.loadMenages(groupement_id);
  }

  render() {
    const { agents, lots, locations, menages } = this.props;

    return (
      <Spin spinning={!menages} size="large" tip="Chargement en cours...">
        <Card title="" bordered={false} className="card-panel">
          <ListMenage
            listData={menages}
            agents={agents}
            lots={lots}
            locations={locations}
            tableOption={columns}
          />
        </Card>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadAgents: (groupement_id) => dispatch(loadAgents(groupement_id)),
    loadLots: (societe_id) => dispatch(loadLots(societe_id)),
    loadLocations: (groupement_id) => dispatch(loadLocations(groupement_id)),
    loadMenages: (groupement_id) => dispatch(loadMenages(groupement_id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    agents: state.agents.data,
    lots: state.lots.data,
    locations: state.locations.data,
    menages: state.menages.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menages);

import React, { Component } from "react";
import constants from "../../utils/constants";

import { Table, Row, Col, Button, Tag, Tooltip, Input, Space, Modal } from "antd";

import { EyeOutlined, SaveOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

import { toast } from "react-toastify";
import { getAdresse, getNomProprietaire } from "../../utils/tools";
import { formatFrDateToBDD } from "../../utils/tools";

import ViewLot from "../ViewLot";

class FormResultat extends Component {
  state = {
    selectedRows: [],
    modalAppt: false,
    appartement: null,
  };

  onClickSelectAppt = () => {
    const requete = this.props.requete;
    if (!requete) {
      toast.error("Veuillez sélectionner une requête !", { containerId: "A" });
      return;
    }

    this.state.selectedRows.forEach((i) => {
      const blocage = this.props.parametre.parametre_blocage_option;
      const result = blocage ? (i.dispo === 2 ? false : true) : true;
      if (result) {
        this.props
          .saveRequeteLot({
            requete_lot_lot_id: i.lot_id,
            requete_lot_requete_id: requete.requete_id,
            requete_lot_date_debut: formatFrDateToBDD(this.props.debut),
            requete_lot_date_fin: this.props.fin
              ? formatFrDateToBDD(this.props.fin)
              : this.props.fin,
            requete_lot_type: 1,
          })
          .catch((err) =>
            toast.error(`Lot ${i.lot_reference} déjà lié à la requête en cours`, {
              containerId: "A",
            })
          );
      }
    });
  };

  onClickAfficher = (id) => {
    const appartement = this.props.resultats.find((item) => item.lot_id === id);
    this.props.afficherAppartement(appartement);
  };

  onClickPreview = (id) => {
    const appartement = this.props.resultats.find((item) => item.lot_id === id);
    this.setState({ appartement, modalAppt: true });
  };

  closeModal = () => {
    this.setState({ modalAppt: false });
  };

  getDispo = (reference, lot) => {
    let ref;
    switch (lot.dispo) {
      case constants.LOT_DISPONIBLE:
        ref = (
          <Tooltip title="Lot disponible">
            <Tag color="#87d068">{reference}</Tag>
          </Tooltip>
        );
        break;
      case constants.LOT_OPTION:
        ref = (
          <Tooltip title="Lot en option">
            <Tag color="#FFBF00">{reference}</Tag>
          </Tooltip>
        );
        break;
      case constants.LOT_NON_DISPONIBLE:
        ref = (
          <Tooltip title="Lot indisponible">
            <Tag color="#D46A6A">{reference}</Tag>
          </Tooltip>
        );
        break;
      case constants.LOT_SANS_CORRESPONDANCE:
        ref = <span>{reference}</span>;
        break;
      default:
        ref = <span>{reference}</span>;
        break;
    }

    return ref;
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Rechercher ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Rechercher
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => this.onSearch(value, record, dataIndex),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  onSearch = (value, record, dataIndex) => {
    let result = null;
    if (dataIndex.includes("lot_proprietaire")) {
      result =
        record[dataIndex].proprietaire_personne.personne_nom
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        record[dataIndex].proprietaire_personne.personne_prenom
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
    } else {
      result = record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
    }
    return result ? result : "";
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const { selectedRows, modalAppt, appartement } = this.state;
    const { resultats, locations, quittances } = this.props;

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRows });
      },
    };

    const columns = [
      {
        title: "Référence",
        dataIndex: "lot_reference",
        key: "lot_reference",
        sortDirections: ["ascend", "descend"],
        ...this.getColumnSearchProps("lot_reference"),
        sorter: (a, b) => a.lot_reference.localeCompare(b.lot_reference),
        render: (reference, lot) => this.getDispo(reference, lot),
        width: 200,
      },
      {
        title: "Adresse",
        dataIndex: "lot_adresse",
        key: "lot_adresse",
        sortDirections: ["ascend", "descend"],
        render: (adresse) => getAdresse(adresse),
        // width: 400,
      },
      {
        title: "Propriétaire",
        dataIndex: "lot_proprietaire",
        key: "lot_proprietaire",
        sortDirections: ["ascend", "descend"],
        ...this.getColumnSearchProps("lot_proprietaire"),
        sorter: (a, b) =>
          a.lot_proprietaire.proprietaire_personne.personne_nom.localeCompare(
            b.lot_proprietaire.proprietaire_personne.personne_nom
          ),
        render: (proprietaire) => getNomProprietaire(proprietaire),
        // width: 300,
      },
      {
        title: "Location €",
        dataIndex: "loyer",
        key: "loyer",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) => a.loyer - b.loyer,
        render: (loyer) =>
          parseFloat(loyer).toLocaleString("fr-FR", {
            style: "currency",
            currency: "EUR",
          }),
        width: 100,
      },
      // {
      //   title: "Frais fixes €",
      //   dataIndex: "charges",
      //   key: "charges",
      //   sortDirections: ["ascend", "descend"],
      //   sorter: (a, b) => a.charges - b.charges,
      //   width: 100,
      // },
      {
        title: "Pers. max",
        dataIndex: "lot_couchage",
        key: "lot_couchage",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) => a.lot_couchage - b.lot_couchage,
        width: 100,
      },
      {
        title: "Etage",
        dataIndex: "lot_etage",
        key: "lot_etage",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) => a.lot_etage - b.lot_etage,
        width: 100,
      },
      {
        title: "Surface",
        dataIndex: "lot_surface",
        key: "lot_surface",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) => a.lot_surface - b.lot_surface,
        width: 100,
      },
      {
        title: "Fiche Lot",
        dataIndex: "lot_id",
        key: "lot_id",
        render: (lot) => (
          <Button onClick={() => this.onClickAfficher(lot)}>
            <EyeOutlined />
          </Button>
        ),
        width: 100,
      },
      {
        title: "Afficher",
        dataIndex: "lot_id",
        key: "lot_id",
        render: (lot) => (
          <Button onClick={() => this.onClickPreview(lot)}>
            <EyeOutlined />
          </Button>
        ),
        width: 100,
      },
    ];

    const footer = resultats && resultats.length > 0 && (
      <>
        <Row>
          <Col md={6}>
            <Button
              type={"primary"}
              size="small"
              onClick={this.onClickSelectAppt}
              disabled={selectedRows.length === 0}
            >
              <SaveOutlined /> Ajouter sélection
            </Button>
          </Col>
          <Col offset={12} md={6}>
            {`${resultats.length} appartement.s trouvé.s`}
          </Col>
        </Row>
      </>
    );

    return (
      <div>
        <Modal open={modalAppt} onCancel={this.closeModal} width={1600} footer={null}>
          {appartement && (
            <ViewLot lotProps={appartement} locations={locations} quittances={quittances} />
          )}
        </Modal>
        <Row>
          <Col md={24}>
            <Table
              size="small"
              columns={columns}
              scroll={{ y: 250 }}
              dataSource={resultats}
              showSorterTooltip={false}
              rowSelection={rowSelection}
              rowKey="lot_id"
              pagination={false}
              footer={() => footer}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default FormResultat;

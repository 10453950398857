export const columns = () => [
  {
    title: "#",
    dataIndex: "proprietaire_id",
    key: "proprietaire_id",
    sorter: (a, b) => parseInt(b.proprietaire_id) - parseInt(a.proprietaire_id),
    render: () => <></>,
    width: "10%",
  },
  {
    title: "Code",
    dataIndex: "proprietaire_code",
    key: "proprietaire_code",
    width: "10%",
  },
  {
    title: "Proprietaire",
    dataIndex: "proprietaire_nom",
    key: "proprietaire_nom",
    width: "20%",
  },
  {
    title: "Reddition",
    dataIndex: "montantHT",
    key: "montantHT",
    render: (montantHT) => {
      return montantHT.toLocaleString("fr-FR", {
        style: "currency",
        currency: "EUR",
      });
    },
    width: "20%",
  },
  {
    title: "Comptabilité",
    dataIndex: "solde",
    key: "solde",
    render: (solde) => {
      return solde.toLocaleString("fr-FR", {
        style: "currency",
        currency: "EUR",
      });
    },
    width: "20%",
  },
  {
    title: "Écart",
    dataIndex: "ecart",
    key: "ecart",
    render: (ecart) => {
      return ecart.toLocaleString("fr-FR", {
        style: "currency",
        currency: "EUR",
      });
    },
    width: "20%",
  },
];

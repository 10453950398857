import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Form, Row, Select, Spin } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import FormImmeuble from "../FormImmeuble";

const { Option, OptGroup } = Select;

const SelectImmeuble = (props) => {
  const { listData, lots } = props;
  const [organisation, setOrganisation] = useState(props.immeubleProps ? props.immeubleProps : null);

  // Référence du formulaire
  const formRefImmeuble = useRef();

  useEffect(() => {
    organisation && onSelectImm(organisation.immeuble_id);
  });

  function onClickAddImm() {
    setOrganisation(null);
  }

  function onClickDeleteImm() {
    props.handleDeleteImmeuble(organisation.immeuble_id)
    formRefImmeuble.current.resetFields();
    setOrganisation(null);
  }

  function onSelectImm(id) {
    formRefImmeuble.current.setFieldsValue({ organisation_id: id });
    const organisation = props.listData.find((e) => e.immeuble_id === id);
    setOrganisation(organisation);
  }

  const addImmeubleButton = (
    <Button type="primary" htmlType="reset" onClick={onClickAddImm}>
      <PlusOutlined />
    </Button>
  );

  const deleteImmeubleButton = (
    <Button type="primary" htmlType="reset" onClick={onClickDeleteImm} disabled={!organisation}>
      <DeleteOutlined />
    </Button>
  );

  const afficherAppartement = (appartement) => {
    props.addPanesLot(appartement);
  };

  return (
    <Spin spinning={false} size="large" tip="Chargement...">
      <div style={{ padding: 20 }}>
        <Form ref={formRefImmeuble}>
          <Row>
            <Col span={11}>
              <Form.Item name="organisation_id">
                <Select
                  showSearch
                  optionLabelProp="label"
                  placeholder={"Immeuble"}
                  onSelect={onSelectImm}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <OptGroup label={"Immeuble"}>
                    {listData &&
                      listData.map((item, i) => {
                        return (
                          <Option
                            key={i}
                            value={item.immeuble_id}
                            label={`${item.immeuble_nom} - ${item.immeuble_adresse.adresse_gmaps_adresse}`}
                          >
                            <Row>
                              <Col sm={8}>
                                {`${item.immeuble_nom} - ${item.immeuble_adresse.adresse_gmaps_adresse}`}
                              </Col>
                            </Row>
                          </Option>
                        );
                      })}
                  </OptGroup>
                </Select>
              </Form.Item>
            </Col>
            <Col span={1}>
              <Form.Item>{addImmeubleButton}</Form.Item>
            </Col>
            <Col span={1}>
              <Form.Item>{deleteImmeubleButton}</Form.Item>
            </Col>
          </Row>
        </Form>
          <FormImmeuble
            utilisateur={props.utilisateur}
            handleSubmit={props.handleSubmitImmeuble}
            handleEdit={props.handleEditImmeuble}
            immeuble={organisation}
            lotsFilter={
              organisation && lots 
                ? lots.filter((item) => item.lot_immeuble ? item.lot_immeuble.immeuble_id === organisation.immeuble_id : null)
                : []
            }
            afficherAppartement={afficherAppartement}
          />
      </div>
    </Spin>
  );
};

export default SelectImmeuble;

import React, { useRef, useState } from "react";
import { Button, DatePicker, Input, Row, Space, Table, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

import dayjs from "dayjs";
const dateFormat = "DD/MM/YYYY";

const { Title } = Typography;

const datePickerRef = React.createRef();

const ListHistorique = (props) => {
  const [searchText, setSearchText] = useState("");
  const searchInput = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [searchedColumn, setSearchedColumn] = useState(null);
  const { listData, locations, tableOption } = props;

  const componentRef = useRef();

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Rechercher ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Rechercher
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => onSearch(value, record, dataIndex),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getColumnSearchPropsDate = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <DatePicker
          ref={datePickerRef}
          placeholder={`Rechercher ${dataIndex}`}
          format={dateFormat}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e ? [e] : [])}
          onPressEnter={() => handleSearchDate(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchDate(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Rechercher
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => onSearchDate(value, record, dataIndex),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => datePickerRef.current.focus(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const onSearch = (value, record, dataIndex) => {
    let result = null;
    if (dataIndex.includes("historique_requete")) {
      result =
        record[dataIndex]?.requete_locataire?.locataire_personne.personne_nom
          ?.toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        record[dataIndex]?.requete_locataire?.locataire_personne.personne_prenom
          ?.toString()
          .toLowerCase()
          .includes(value.toLowerCase());
    } else if (dataIndex === "historique_etape_a.etape_libelle") {
      result = record.historique_etape_a?.etape_libelle
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    } else if (dataIndex === "historique_etape_b.etape_libelle") {
      result = record.historique_etape_b?.etape_libelle
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    } else {
      result = record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
    }
    return result ? result : "";
  };

  const onSearchDate = (record, value, dataIndex) => {
    return record
      ? record.startOf("day").isSame(dayjs(value[dataIndex], "YYYY-MM-DD").startOf("day"))
      : "";
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleSearchDate = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0] ? selectedKeys[0].format("DD/MM/YYYY") : "");
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText(searchText);
  };

  return (
    <>
      <div style={{ padding: 20 }}>
        <Row>
          <Title level={2}>Historique Parcours Client</Title>
        </Row>
      </div>
      <div ref={componentRef}>
        {listData && locations && (
          <Table
            className="list-data"
            dataSource={listData}
            columns={tableOption(locations, getColumnSearchProps, getColumnSearchPropsDate)}
            pagination={{ pageSize: 30 }}
            rowKey="historique_id"
          />
        )}
      </div>
    </>
  );
};

export default ListHistorique;

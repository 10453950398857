import React, { Component } from "react";
import { connect } from "react-redux";

import {
  archiveProprietaire,
  loadProprietaires,
  saveProprietaire,
  updateProprietaire,
  updateProprietairePassword,
} from "../../store/actions/proprietaireAction";
import {
  archiveLocataire,
  loadLocataires,
  saveLocataire,
  updateLocataire,
} from "../../store/actions/locataireAction";
import { saveDocument, deleteDocument, processWord } from "../../store/actions/documentAction";
import { loadEcritures } from "../../store/actions/ecritureAction";
import { loadLocations } from "../../store/actions/locationAction";
import { loadReglements } from "../../store/actions/reglementAction";
import { loadQuittances } from "../../store/actions/quittanceAction";
import { loadLots } from "../../store/actions/lotAction";
import { loadRequetes } from "../../store/actions/requeteAction";
import {
  loadEvenements,
  saveEvenement,
  updateEvenement,
  deleteEvenement,
} from "../../store/actions/evenementAction";

import { Card, Spin } from "antd";
import SelectPersonne from "../../components/SelectPersonne";

import { toast } from "react-toastify";
import { deleteTag, saveTag, updateTag } from "../../store/actions/tagAction";
import { deleteEmail, saveEmail } from "../../store/actions/emailAction";
import { updateCompte } from "../../store/actions/compteAction";
import { TYPE_TAG } from "../../utils/constants";

class Personnes extends Component {
  state = {
    personne: null,
    loading: false,
  };

  componentDidMount() {
    const groupement_id = this.props.utilisateur.groupement_id;
    const societe_id = this.props.utilisateur.societe_id;
    this.props.proprietaire
      ? this.props.loadProprietaires(groupement_id)
      : this.props.loadLocataires(groupement_id);
    this.props.loadRequetes(groupement_id);
    this.props.loadEcritures(societe_id);
    this.props.loadLocations(groupement_id);
    this.props.loadReglements(societe_id);
    this.props.loadQuittances(groupement_id);
    this.props.loadLots(societe_id);
    this.props.loadEvenements(groupement_id);
    this.initPersonne(this.props.personneProps);
  }

  initPersonne = (personne, loading = false) => {
    this.setState({ personne, loading });
  };

  savePersonne = (data, proprietaire) => {
    proprietaire
      ? this.props
          .saveProprietaire(data)
          .then((res) => {
            this.initPersonne(res);
            toast.success("Propriétaire créé !", { containerId: "A" });
          })
          .catch(() => toast.error("Échec lors de la création !", { containerId: "A" }))
      : this.props
          .saveLocataire(data)
          .then((res) => {
            this.initPersonne(res);
            toast.success("Locataire créé !", { containerId: "A" });
          })
          .catch(() => toast.error("Échec lors de la création !", { containerId: "A" }));
  };

  updatePersonne = (data, id, proprietaire) => {
    proprietaire
      ? this.props
          .updateProprietaire(data, id)
          .then((res) => {
            this.updateCompteAuxiliairePro(res);
            this.initPersonne(res);
            toast.success("Propriétaire édité !", { containerId: "A" });
          })
          .catch(() => toast.error("Échec lors de l'édition !", { containerId: "A" }))
      : this.props
          .updateLocataire(data, id)
          .then((res) => {
            this.updateCompteAuxiliaireLoc(res);
            this.initPersonne(res);
            toast.success("Locataire édité !", { containerId: "A" });
          })
          .catch((err) => {
            console.log(err, "err");
            toast.error("Échec lors de l'édition !", { containerId: "A" });
          });
  };

  updateCompteAuxiliairePro = (proprietaire) => {
    const libelle = `${proprietaire.proprietaire_personne.personne_nom} ${proprietaire.proprietaire_personne.personne_prenom}`;
    const affectation = proprietaire.proprietaire_affectations.find(
      (affectation) =>
        affectation.proprietaire_affectation_societe.societe_id ===
        this.props.utilisateur.societe_id
    );
    if (affectation) {
      const compte = affectation.proprietaire_affectation_compte;
      compte.compte_libelle = libelle;
      this.props.updateCompte(compte, compte.compte_id);
    }
  };

  updateCompteAuxiliaireLoc = (locataire) => {
    const libelle = `${locataire.locataire_personne.personne_nom} ${locataire.locataire_personne.personne_prenom}`;
    const affectation = locataire.locataire_affectations.find(
      (affectation) =>
        affectation.locataire_affectation_societe.societe_id === this.props.utilisateur.societe_id
    );
    if (affectation) {
      const compte = affectation.locataire_affectation_compte;
      compte.compte_libelle = libelle;
      this.props.updateCompte(compte, compte.compte_id);
    }
  };

  updateProprietaire = (data, id) => {
    this.props
      .updateProprietairePassword(data, id)
      .then((res) => {
        this.initPersonne(res);
        toast.success("Compte extranet créé !", { containerId: "A" });
      })
      .catch(() =>
        toast.error("Échec lors de la création du compte extranet !", { containerId: "A" })
      );
  };

  archivePersonne = (id, proprietaire) => {
    proprietaire
      ? this.props
          .archiveProprietaire(id)
          .then((res) => {
            this.initPersonne(null);
            toast.success("Propriétaire archivé !", { containerId: "A" });
          })
          .catch((err) => toast.error("Échec lors de l'archivage !", { containerId: "A" }))
      : this.props
          .archiveLocataire(id)
          .then((res) => {
            this.initPersonne(null);
            toast.success("Locataire archivé !", { containerId: "A" });
          })
          .catch((err) => toast.error("Échec lors de l'archivage !", { containerId: "A" }));
  };

  render() {
    const {
      utilisateur,
      proprietaire,
      tabCompta,
      readOnly,
      societes,
      etablissements,
      qualites,
      langues,
      typesPaiements,
      proprietaires,
      locataires,
      ecritures,
      documents,
      tags,
      locations,
      reglements,
      quittances,
      lots,
      requetes,
      provenances,
      parametre,
      evenements,
      words,
    } = this.props;
    const { personne, loading } = this.state;

    return (
      <Spin
        size="large"
        tip="Chargement en cours..."
        spinning={proprietaire ? !proprietaires : !locataires}
      >
        <Card title="" bordered={false} className="card-panel">
          <SelectPersonne
            utilisateur={utilisateur}
            proprietaire={proprietaire}
            personneProps={personne}
            loading={loading}
            tabCompta={tabCompta}
            readOnly={readOnly}
            societes={societes}
            etablissements={etablissements}
            qualites={qualites}
            langues={langues}
            provenances={provenances}
            typesPaiements={typesPaiements}
            listData={
              proprietaire
                ? proprietaires &&
                  proprietaires
                    .filter((pro) => (pro.proprietaire_personne.personne_actif = true))
                    .sort((a, b) => a.proprietaire_code.localeCompare(b.proprietaire_code))
                : locataires &&
                  locataires
                    .filter((loc) => loc.locataire_personne.personne_actif === true)
                    .sort((a, b) => a.locataire_code.localeCompare(b.locataire_code))
            }
            ecritures={ecritures}
            documents={documents}
            tags={tags.filter((tag) => tag.tag_type === TYPE_TAG[0].value)}
            locations={
              locations &&
              locations.filter(
                (item) =>
                  item.location_etat === "r" &&
                  item.location_lot.lot_societe_id === utilisateur.societe_id
              )
            }
            reglements={reglements}
            quittances={quittances}
            evenements={evenements}
            parametre={parametre}
            savePersonne={this.savePersonne}
            updatePersonne={this.updatePersonne}
            updateProprietaire={this.updateProprietaire}
            archivePersonne={this.archivePersonne}
            saveDocument={this.props.saveDocument}
            deleteDocument={this.props.deleteDocument}
            saveTag={this.props.saveTag}
            updateTag={this.props.updateTag}
            deleteTag={this.props.deleteTag}
            loadEcritures={this.props.loadEcritures}
            addPanesRequete={this.props.addPanesRequete}
            templates={this.props.templates}
            templatesLangues={this.props.templatesLangues}
            lots={lots}
            addPanesLot={this.props.addPanesLot}
            saveEmail={this.props.saveEmail}
            deleteEmail={this.props.deleteEmail}
            requetes={requetes}
            saveEvenement={this.props.saveEvenement}
            updateEvenement={this.props.updateEvenement}
            deleteEvenement={this.props.deleteEvenement}
            words={words}
            processWord={this.props.processWord}
          />
        </Card>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadProprietaires: (groupement_id) => dispatch(loadProprietaires(groupement_id)),
    loadLocataires: (groupement_id) => dispatch(loadLocataires(groupement_id)),
    loadEcritures: (societe_id) => dispatch(loadEcritures(societe_id)),
    loadLocations: (groupement_id) => dispatch(loadLocations(groupement_id)),
    saveProprietaire: (jsonData) => dispatch(saveProprietaire(jsonData)),
    updateProprietaire: (jsonData, id) => dispatch(updateProprietaire(jsonData, id)),
    updateProprietairePassword: (jsonData, id) =>
      dispatch(updateProprietairePassword(jsonData, id)),
    archiveProprietaire: (id) => dispatch(archiveProprietaire(id)),
    saveLocataire: (jsonData) => dispatch(saveLocataire(jsonData)),
    updateLocataire: (jsonData, id) => dispatch(updateLocataire(jsonData, id)),
    archiveLocataire: (id) => dispatch(archiveLocataire(id)),
    saveDocument: (jsonData, dossier, tag) => dispatch(saveDocument(jsonData, dossier, tag)),
    deleteDocument: (id) => dispatch(deleteDocument(id)),
    saveTag: (jsonData) => dispatch(saveTag(jsonData)),
    updateTag: (jsonData, id) => dispatch(updateTag(jsonData, id)),
    deleteTag: (id) => dispatch(deleteTag(id)),
    loadReglements: (societe_id) => dispatch(loadReglements(societe_id)),
    loadQuittances: (groupement_id) => dispatch(loadQuittances(groupement_id)),
    loadLots: (societe_id) => dispatch(loadLots(societe_id)),
    saveEmail: (jsonData) => dispatch(saveEmail(jsonData)),
    deleteEmail: (id) => dispatch(deleteEmail(id)),
    loadRequetes: (groupement_id) => dispatch(loadRequetes(groupement_id)),
    loadEvenements: (groupement_id) => dispatch(loadEvenements(groupement_id)),
    saveEvenement: (jsonData) => dispatch(saveEvenement(jsonData)),
    updateEvenement: (jsonData, id) => dispatch(updateEvenement(jsonData, id)),
    deleteEvenement: (id) => dispatch(deleteEvenement(id)),
    updateCompte: (compte, id) => dispatch(updateCompte(compte, id)),
    processWord: (jsonData, idWord, idDossier) =>
      dispatch(processWord(jsonData, idWord, idDossier)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    societes: state.societes.data,
    etablissements: state.etablissements.data,
    qualites: state.qualites.data,
    langues: state.langues.data,
    typesPaiements: state.typesPaiements.data,
    proprietaires: state.proprietaires.data,
    locataires: state.locataires.data,
    ecritures: state.ecritures.data,
    documents: state.documents.data,
    tags: state.tags.data,
    locations: state.locations.data,
    reglements: state.reglements.data,
    quittances: state.quittances.data,
    templates: state.templates.data,
    templatesLangues: state.templatesLangues.data,
    lots: state.lots.data,
    requetes: state.requetes.data,
    provenances: state.provenances.data,
    parametre: state.parametre.data,
    evenements: state.evenements.data,
    words: state.words.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Personnes);

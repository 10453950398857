import React, { Component } from "react";
import { Spin } from "antd";

import { connect } from "react-redux";

import { loadComptes } from "../../../store/actions/compteAction";
import { loadEcritures } from "../../../store/actions/ecritureAction";

import TablePointe from "../../../components/TablePointe/index";

class Pointes extends Component {
  componentDidMount() {
    const societe_id = this.props.utilisateur.societe_id;
    this.props.loadComptes(societe_id);
    this.props.loadEcritures(societe_id);
  }

  render() {
    const { comptes, ecritures, parametre } = this.props;

    return (
      <Spin size="large" tip="Chargement en cours..." spinning={!comptes && !ecritures}>
        <div style={{ padding: 10 }}>
          <TablePointe
            comptes={comptes?.filter(
              (compte) =>
                compte.compte_type_compte !== "A" &&
                compte.compte_compte_general.substring(0, 1) === "4"
            )}
            ecritures={ecritures}
            parametre={parametre}
          />
        </div>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadComptes: (societe_id) => dispatch(loadComptes(societe_id)),
    loadEcritures: (societe_id) => dispatch(loadEcritures(societe_id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    comptes: state.comptes.data,
    ecritures: state.ecritures.data,
    parametre: state.parametre.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pointes);

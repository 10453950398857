import {
  adaptedToDossier,
  dossierToAdapted,
  dossiersToAdapted,
} from "../../adapter/dossierAdapter";
import { HttpMethod, initFetch } from "../../utils/fetcher";

export const LOAD_SUCCESS_DOSSIERS = "LOAD_SUCCESS_DOSSIERS";
export const LOAD_FAIL_DOSSIERS = "LOAD_FAIL_DOSSIERS";
export const SAVE_SUCCESS_DOSSIERS = "SAVE_SUCCESS_DOSSIERS";
export const SAVE_FAIL_DOSSIERS = "SAVE_FAIL_DOSSIERS";
export const UPDATE_SUCCESS_DOSSIERS = "UPDATE_SUCCESS_DOSSIERS";
export const UPDATE_FAIL_DOSSIERS = "UPDATE_FAIL_DOSSIERS";
export const DELETE_SUCCESS_DOSSIERS = "DELETE_SUCCESS_DOSSIERS";
export const DELETE_FAIL_DOSSIERS = "DELETE_FAIL_DOSSIERS";

export function loadDossiers() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`dossiers`, HttpMethod.GET);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.dossier);
          dispatch({
            type: LOAD_SUCCESS_DOSSIERS,
            payload: dossiersToAdapted(res.dossier),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_DOSSIERS,
            error: err,
          });
        });
    });
  };
}

export function saveDossier(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`dossiers`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToDossier(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(dossierToAdapted(res.dossier));
          dispatch({
            type: SAVE_SUCCESS_DOSSIERS,
            payload: dossierToAdapted(res.dossier),
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_DOSSIERS,
            error: err,
          })
        );
    });
  };
}

export function updateDossier(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`dossiers/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToDossier(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(dossierToAdapted(res.dossier));
          dispatch({
            type: UPDATE_SUCCESS_DOSSIERS,
            payload: dossierToAdapted(res.dossier),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_DOSSIERS,
            error: err,
          })
        );
    });
  };
}

export function deleteDossier(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`dossiers/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(dossierToAdapted(res.dossier));
          dispatch({
            type: DELETE_SUCCESS_DOSSIERS,
            payload: dossierToAdapted(res.dossier),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_DOSSIERS,
            error: err,
          });
        });
    });
  };
}

import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Spin } from "antd";
import { columns } from "./columns";
import { loadLocations } from "../../store/actions/locationAction";
import { loadQuittances } from "../../store/actions/quittanceAction";
import { loadReglements } from "../../store/actions/reglementAction";
import ListQuittance from "../../components/ListQuittance";

class Quittances extends Component {
  componentDidMount() {
    const groupement_id = this.props.utilisateur.groupement_id;
    const societe_id = this.props.utilisateur.societe_id;
    this.props.loadLocations(groupement_id);
    this.props.loadQuittances(groupement_id);
    this.props.loadReglements(societe_id);
  }

  render() {
    const { locations, quittances, reglements, utilisateur } = this.props;

    return (
      <Spin
        spinning={!locations || !quittances || !reglements}
        size="large"
        tip="Chargement en cours..."
      >
        <Card title="" bordered={false} className="card-panel">
          <ListQuittance
            listData={
              quittances &&
              locations &&
              quittances
                .filter((quittance) => {
                  const location = locations.find(
                    (location) => location.location_id === quittance.quittance_location_id
                  );
                  return (
                    location.location_etat === "r" &&
                    location.location_lot.lot_societe_id === utilisateur.societe_id
                  );
                })
                .sort((a, b) => b.quittance_id - a.quittance_id)
            }
            locations={locations}
            reglements={reglements}
            tableOption={columns}
            addPanesRequete={this.props.addPanesRequete}
          />
        </Card>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadLocations: (groupement_id) => dispatch(loadLocations(groupement_id)),
    loadQuittances: (groupement_id) => dispatch(loadQuittances(groupement_id)),
    loadReglements: (societe_id) => dispatch(loadReglements(societe_id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    locations: state.locations.data,
    quittances: state.quittances.data,
    reglements: state.reglements.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Quittances);

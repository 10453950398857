import React, { Component } from "react";

import { connect } from "react-redux";

import {
  Card,
  Button,
  Form,
  Row,
  Select,
  Input,
  Col,
  Popconfirm,
  Table,
  Radio,
  Switch,
} from "antd";

import { toast } from "react-toastify";
import Constants from "../../utils/constants";

import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { saveTemplate, updateTemplate, deleteTemplate } from "../../store/actions/templateAction";
import {
  saveEtapesTemplates,
  deleteEtapesTemplates,
} from "../../store/actions/etapeTemplateAction";
import {
  saveTemplatesLangues,
  updateTemplatesLangues,
} from "../../store/actions/templateLangueAction";
import { deleteWord, saveWord, updateWord } from "../../store/actions/wordAction";
import { deleteDocument, saveDocument } from "../../store/actions/documentAction";
import { saveImage } from "../../store/actions/photoAction";

import { DeleteOutlined } from "@ant-design/icons";
import { etapeTitle, twoDigits } from "../../utils/tools";
import FormWord from "../../components/FormWord";
import htmlToDraft from "html-to-draftjs";
// import FormDocumentDispo from "../../components/FormDocumentDispo";

const { Option, OptGroup } = Select;

class Template extends Component {
  constructor(props) {
    super(props);

    this.state = {
      template: 1,
      isSearching: false,
      words: [],
      descriptions: [],
      etapes: this.props.etapes,
      selectedRowKeys: [],
      selectedRowKeysSearched: [],
      uploadedImages: [],
      langueSelected: this.props.langues[0].langue_id,
      templateSelected: null,
      titre: "",
      editorState: EditorState.createEmpty(),
    };
    this._uploadImageCallBack = this._uploadImageCallBack.bind(this);
  }

  formRef = React.createRef();

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ words: props.words });
  }

  onSelectTemplate = (e) => {
    const template = this.props.templates.find((template) => template.template_id === e);

    const templateLangue = this.props.templatesLangues.find(
      (item) => item.template_langue_template.template_id === e
    );

    const selectedRowKeys = this.props.etapesTemplates
      .filter((item) => item.etape_template_template.template_id === e)
      .map((i) => i.etape_template_etape.etape_id);

    this.formRef.current.setFieldsValue({
      template_libelle: templateLangue.template_langue_template.template_libelle,
      template_langue: templateLangue.template_langue_langue.langue_id,
      template_titre: templateLangue.template_langue_titre,
      template_notification: template.template_notification,
    });

    this.setState({
      editorState: EditorState.createWithContent(
        ContentState.createFromBlockArray(htmlToDraft(templateLangue.template_langue_text))
      ),
      templateSelected: templateLangue.template_langue_template.template_id,
      langueSelected: templateLangue.template_langue_langue.langue_id,
      selectedRowKeys,
    });
  };

  onSelectWord = (e) => {
    const words = this.props.words.filter((item) => item.word_type === e);

    this.setState({ words, templateSelected: e });
  };

  onFinish = (data) => {
    let text = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));

    // Suppression des href pour ne plus avoir de lien cliquable sur les champs
    const html = document.createElement("div");
    html.innerHTML = text;

    const allTags = html.getElementsByTagName("a");
    for (let i = 0; i < allTags.length; i++) {
      if (allTags[i].className === "wysiwyg-mention") {
        const span = document.createElement("span");
        span.innerHTML = allTags[i].innerHTML;
        allTags[i].replaceWith(span);
      }
    }

    text = html.innerHTML;

    text = text.replaceAll('href=""', "");
    text = text.replaceAll('href="undefined"', "");
    text = text.replaceAll('href="http://localhost:3000/"', "");
    text = text.replaceAll("https://app.aster-soft.fr/", "");

    const jsonData = {
      template_libelle: data.template_libelle,
      template_langue: data.template_langue,
      template_text: text,
      template_titre: data.template_titre,
      template_notification: data.template_notification ? data.template_notification : false,
      template_etablissement_id: this.props.utilisateur.etablissement_id,
      template_type_id: data.validation,
    };

    if (data.template_select) {
      this.props
        .updateTemplate(jsonData, data.template_select)
        .then((res) => {
          this.linkTemplates();
          toast.success("Template modifié !", {
            containerId: "A",
          });

          if (
            this.props.templatesLangues.find(
              (item) =>
                item.template_langue_template.template_id === res.template_id &&
                item.template_langue_langue.langue_id === this.state.langueSelected
            )
          ) {
            this.props.updateTemplatesLangues(jsonData, res.template_id, this.state.langueSelected);
          } else {
            this.props.saveTemplatesLangues(jsonData, res.template_id, this.state.langueSelected);
          }
        })
        .catch((err) => {
          toast.error("Échec de la modification !", {
            containerId: "A",
          });
        });
    } else {
      this.props
        .saveTemplate(jsonData)
        .then((res) => {
          toast.success("Template créé !", {
            containerId: "A",
          });

          this.state.descriptions.forEach((item) => {
            this.props.saveTemplatesLangues(
              {
                template_titre: item.titre,
                template_text: item.text,
              },
              res.template_id,
              item.langue
            );
          });

          this.props.saveTemplatesLangues(jsonData, res.template_id, this.state.langueSelected);

          this.formRef.current.setFieldsValue({
            template_select: res.template_id,
          });
          this.setState({ templateSelected: res.template_id }, () => {
            this.linkTemplates();
          });
        })
        .catch((err) => {
          toast.error("Échec de la création !", {
            containerId: "A",
          });
        });
    }
  };

  linkTemplates = () => {
    const idTemplate = this.state.templateSelected;

    this.props.deleteEtapesTemplates(idTemplate).then(() => {
      this.state.selectedRowKeys.forEach((idEtape) => {
        if (
          this.props.etapesTemplates.find(
            (item) =>
              item.etape_template_etape.etape_id === idEtape &&
              item.etape_template_template.template_id === idTemplate
          )
        )
          return;

        this.props.saveEtapesTemplates(idEtape, idTemplate).catch(() => {
          toast.error("Échec de la liaison !", {
            containerId: "A",
          });
        });
      });
    });

    toast.success("Liaison.s créée.s !", {
      containerId: "A",
    });
  };

  onClickAddTemplate = () => {
    this.formRef.current.resetFields();
    this.setState({
      editorState: EditorState.createEmpty(),
      langueSelected: this.props.langues[0].langue_id,
      templateSelected: null,
      descriptions: [],
      selectedRowKeys: [],
      validation: "1",
      template: 1,
    });
  };

  onClickDeleteTemplate = () => {
    this.props
      .deleteTemplate(this.state.templateSelected)
      .then((res) => {
        toast.success("Template supprimé !", {
          containerId: "A",
        });
        this.onClickAddTemplate();
      })
      .catch((err) =>
        toast.error("Échec de la suppression !", {
          containerId: "A",
        })
      );
  };

  onTitreChange = (e) => this.setState({ titre: e.target.value });

  onEditorStateChange = (input) => {
    let editorState = input;
    let change = false;
    let text = draftToHtml(convertToRaw(input.getCurrentContent()));

    // Si le text contient le champ #debut_selection, le champ #fin_liste est ajouté pour englober les données de la liste
    if (
      text.includes(
        '<p><a href="undefined" class="wysiwyg-mention" data-mention data-value="debut_selection">#debut_selection</a>&nbsp;</p>'
      )
    ) {
      text = text.replace(
        '<p><a href="undefined" class="wysiwyg-mention" data-mention data-value="debut_selection">#debut_selection</a>&nbsp;</p>',
        "<p><span>#debut_selection</span>&nbsp;</p><p><span>#fin_selection</span>&nbsp;</p>"
      );
      change = true;
    }
    // Si le text contient le champ #debut_panier, le champ #fin_panier est ajouté pour englober les données de la liste
    if (
      text.includes(
        '<p><a href="undefined" class="wysiwyg-mention" data-mention data-value="debut_panier">#debut_panier</a>&nbsp;</p>'
      )
    ) {
      text = text.replace(
        '<p><a href="undefined" class="wysiwyg-mention" data-mention data-value="debut_panier">#debut_panier</a>&nbsp;</p>',
        "<p><span>#debut_panier</span>&nbsp;</p><p><span>#fin_panier</span>&nbsp;</p>"
      );
      change = true;
    }

    if (change)
      editorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(text))
      );

    this.setState({ editorState });
  };

  onSelectChange = (selectedRowKeys) => {
    if (this.state.isSearching) {
      const newSelectedRowKeys = this.state.selectedRowKeys.filter(
        (el) => !selectedRowKeys.includes(el)
      );

      this.setState({
        selectedRowKeysSearched: selectedRowKeys,
        selectedRowKeys: newSelectedRowKeys,
      });
    } else {
      this.setState({ selectedRowKeys, selectedRowKeysSearched: [] });
    }
  };

  onAbort = () => this.onClickAddTemplate();

  onChangeType = (e) => {
    this.onClickAddTemplate();
    this.setState({ template: e.target.value });
    this.formRef.current.setFieldsValue({
      template_select: null,
      validation: e.target.value,
    });

    if (e.target.value === 3) {
      const temp = this.props.templates.find((i) => i.template_type === 3);
      if (temp && temp !== undefined) {
        this.onSelectTemplate(temp.template_id);
        this.formRef.current.setFieldsValue({
          template_select: temp.template_id,
        });
      }
    }
  };

  onChangeLangue = (e) => {
    if (this.state.templateSelected) {
      const templateLangue = this.props.templatesLangues.find(
        (item) =>
          item.template_langue_template.template_id === this.state.templateSelected &&
          item.template_langue_langue.langue_id === e.target.value
      );

      this.formRef.current.setFieldsValue({
        template_titre: templateLangue ? templateLangue.template_langue_titre : "",
        template_text: templateLangue ? templateLangue.template_langue_text : "",
      });

      this.setState({
        editorState: EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(templateLangue ? templateLangue.template_langue_text : "")
          )
        ),
        langueSelected: e.target.value,
      });
    } else {
      const desc = {
        titre: this.state.titre,
        langue: this.state.langueSelected,
        text: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
      };

      this.formRef.current.setFieldsValue({
        template_text: "",
      });

      this.setState({
        editorState: EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(""))
        ),
        langueSelected: e.target.value,
        descriptions: [...this.state.descriptions, desc],
      });
    }
  };

  _uploadImageCallBack(file) {
    // long story short, every time we upload an image, we
    // need to save it to the state so we can get it's data
    // later when we decide what to do with it.

    this.props.saveImage(file);
    // Make sure you have a uploadImages: [] as your default state
    let uploadedImages = this.state.uploadedImages;

    const imageObject = {
      file,
      // localSrc: `${Constants.directory}images/${file.lastModified}_${file.name}`,
      localSrc: `${Constants.mode}://${Constants.domain}:${Constants.port}/api/photos/displayImage/${file.lastModified}_${file.name}`,
    };

    uploadedImages.push(imageObject);

    this.setState({ uploadedImages });

    // We need to return a promise with the image src
    // the img src we will use here will be what's needed
    // to preview it in the browser. This will be different than what
    // we will see in the index.md file we generate.
    return new Promise((resolve, reject) => {
      resolve({ data: { link: imageObject.localSrc } });
    });
  }

  render() {
    const { utilisateur, langues, tags } = this.props;
    const {
      template,
      etapes,
      editorState,
      selectedRowKeys,
      templateSelected,
      selectedRowKeysSearched,
    } = this.state;

    const templates = this.props.templates.filter((item) => item.template_type === template);

    const footer = (
      <div>
        <Button
          type="primary"
          danger
          style={{ width: 100, marginBottom: 10 }}
          onClick={this.onAbort}
        >
          Annuler
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          style={{ width: 100, marginLeft: 10, marginBottom: 10 }}
        >
          Valider
        </Button>
      </div>
    );

    const title = (
      <div>
        <Col span={16}>
          <Row>
            <Col offset={1} span={20}>
              {template === 4 && (
                <Form.Item name="word_select">
                  <Select
                    showSearch
                    optionFilterProp="label"
                    placeholder="WORD"
                    optionLabelProp="label"
                    onSelect={this.onSelectWord}
                    disabled={template < 4 && Constants.TYPE.length}
                  >
                    <OptGroup label="Word">
                      {Constants.TYPE &&
                        Constants.TYPE.map((item, i) => {
                          return (
                            <Option key={i} value={item.value} label={`${item.name}`}>
                              <Row>
                                <Col sm={8}>{`${item.name}`}</Col>
                              </Row>
                            </Option>
                          );
                        })}
                    </OptGroup>
                  </Select>
                </Form.Item>
              )}
              {template < 4 && (
                <Form.Item name="template_select">
                  <Select
                    showSearch
                    optionFilterProp="label"
                    placeholder="TEMPLATE"
                    optionLabelProp="label"
                    onSelect={this.onSelectTemplate}
                    disabled={template === 3 && templates.length}
                  >
                    <OptGroup label="Template">
                      {templates &&
                        templates
                          .sort((a, b) => a.template_libelle.localeCompare(b.template_libelle))
                          .map((item, i) => {
                            return (
                              <Option
                                key={i}
                                value={item.template_id}
                                label={`${item.template_libelle}`}
                              >
                                <Row>
                                  <Col sm={8}>{`${item.template_libelle}`}</Col>
                                </Row>
                              </Option>
                            );
                          })}
                    </OptGroup>
                  </Select>
                </Form.Item>
              )}
            </Col>
            {template < 4 && (
              <>
                <Col span={1}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="reset"
                      onClick={this.onClickAddTemplate}
                      disabled={template === 3 && templates.length}
                    >
                      +
                    </Button>
                  </Form.Item>
                </Col>
                {templateSelected && (
                  <Col span={1}>
                    <Form.Item>
                      <Popconfirm
                        title="Confirmez-vous la suppression?"
                        onConfirm={this.onClickDeleteTemplate}
                      >
                        <Button type="primary" danger>
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                    </Form.Item>
                  </Col>
                )}
              </>
            )}
          </Row>
        </Col>
      </div>
    );

    const columns = [
      {
        title: "Libellé",
        dataIndex: "etape_libelle",
        key: "etape_libelle",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) => a.etape_libelle.localeCompare(b.etape_libelle),
        render(text, record) {
          return {
            children: etapeTitle(record),
          };
        },
      },
    ];

    const rowSelection = {
      selectedRowKeys: selectedRowKeys.concat(selectedRowKeysSearched),
      onChange: this.onSelectChange,
    };

    const suggestions =
      template === 1 || template === 3
        ? [
            {
              text: "PROPRIETAIRE_QUALITE",
              value: "proprietaire_qualite",
            },
            {
              text: "PROPRIETAIRE_NOM",
              value: "proprietaire_nom",
            },
            {
              text: "PROPRIETAIRE_PRENOM",
              value: "proprietaire_prenom",
            },
            {
              text: "PROPRIETAIRE_ADRESSE",
              value: "proprietaire_adresse",
            },
            { text: "LOT_REFERENCE", value: "lot_reference" },
            { text: "LOT_CODEPORTE", value: "lot_code_porte" },
            { text: "LOT_TYPE", value: "lot_type" },
            { text: "LOT_SURFACE", value: "lot_surface" },
            { text: "LOT_ETAGE", value: "lot_etage" },
            { text: "LOT_COUCHAGES", value: "lot_couchages" },
            { text: "LOT_URL", value: "lot_url" },
            { text: "LOT_ADRESSE", value: "lot_adresse" },
            { text: "LOT_NUMERO", value: "lot_numero" },
            { text: "LOT_VOIE", value: "lot_voie" },
            { text: "LOT_CP", value: "lot_cp" },
            { text: "LOT_VILLE", value: "lot_ville" },
            { text: "LOT_DEPARTEMENT", value: "lot_departement" },
            { text: "LOT_PAYS", value: "lot_pays" },
            { text: "LOT_SITUATION", value: "lot_situation" },
            { text: "LOT_EQUIPEMENTS", value: "lot_equipements" },
            { text: "LOT_QUARTIERS", value: "lot_quartiers" },
            { text: "LOT_METROS", value: "lot_metros" },
            { text: "LOT_DEPOT_GARANTIE", value: "lot_depot_garantie" },
            { text: "LOT_TOTAL_PRIX", value: "lot_total_prix" },
            { text: "LOT_CHAMP_PERSO_", value: "lot_champ_perso_" },
            ...(template === 1
              ? [
                  {
                    text: "LOCATAIRE_QUALITE",
                    value: "locataire_qualite",
                  },
                  {
                    text: "LOCATAIRE_NOM",
                    value: "locataire_nom",
                  },
                  {
                    text: "LOCATAIRE_PRENOM",
                    value: "locataire_prenom",
                  },
                  {
                    text: "LOCATAIRE_ADRESSE",
                    value: "locataire_adresse",
                  },
                  {
                    text: "LOCATAIRE_PAYS",
                    value: "locataire_pays",
                  },
                  {
                    text: "RECHERCHE_DATE_DEBUT",
                    value: "recherche_date_debut",
                  },
                  {
                    text: "RECHERCHE_DATE_FIN",
                    value: "recherche_date_fin",
                  },
                  {
                    text: "LOCATION_DATE_DEBUT",
                    value: "location_date_debut",
                  },
                  {
                    text: "LOCATION_DATE_FIN",
                    value: "location_date_fin",
                  },
                  {
                    text: "LOCATION_ID",
                    value: "location_id",
                  },
                  {
                    text: "LOCATION_LOT_REFERENCE",
                    value: "location_lot_reference",
                  },
                  {
                    text: "LOCATION_NOMBRE_ADULTES",
                    value: "location_nombre_adultes",
                  },
                  {
                    text: "LOCATION_NOMBRE_ENFANTS",
                    value: "location_nombre_enfants",
                  },
                  {
                    text: "LOCATION_NOMBRE_BEBES",
                    value: "location_nombre_bebes",
                  },
                  {
                    text: "LOCATION_NOMBRE_NUITS",
                    value: "location_nombre_nuits",
                  },
                  {
                    text: "LOCATION_DESIDERATA",
                    value: "location_desiderata",
                  },
                  {
                    text: "LOCATION_FRAIS_DOSSIER",
                    value: "location_frais_dossier",
                  },
                  {
                    text: "LOCATION_TAXE_SEJOUR",
                    value: "location_taxe_sejour",
                  },
                  {
                    text: "LOCATION_TOTAL_HORS_FRAIS_DOSSIER",
                    value: "location_total_hors_frais_dossier",
                  },
                  {
                    text: "LOCATION_TOTAL_HORS_TAXE_ET_FRAIS_DOSSIER",
                    value: "location_total_hors_taxe_et_frais_dossier",
                  },
                  {
                    text: "LOCATION_TOTAL_PRIX",
                    value: "location_total_prix",
                  },
                  {
                    text: "LOCATION_DEJA_REGLE",
                    value: "location_deja_regle",
                  },
                  {
                    text: "LOCATION_SOLDE",
                    value: "location_solde",
                  },
                  { text: "LOCATION_TARIF", value: "location_tarif" },
                  { text: "LOCATION_MONTANT_NET", value: "location_montant_net" },
                  { text: "LOCATION_OCCUPANTS", value: "location_occupants" },
                  { text: "LISTE_LOTS_SELECTION", value: "debut_selection" },
                  { text: "LISTE_LOTS_PANIER", value: "debut_panier" },
                  { text: "RUBRIQUE_LIBELLE_", value: "rubrique_libelle_" },
                  { text: "LOCATION_RUBRIQUE_", value: "location_rubrique_" },
                  { text: "QUITTANCE_RUBRIQUE_", value: "quittance_rubrique_" },
                  { text: "LOCATION_BLOC_QUITTANCE", value: "location_bloc_quittance" },
                  { text: "LOCATION_BLOC_TARIF", value: "location_bloc_tarif" },
                  { text: "LOT_BLOC_MATRICE", value: "lot_bloc_matrice" },
                  { text: "LOCATION_DATE_ACOMPTE", value: "location_date_acompte" },
                  { text: "LOCATION_DATE_SOLDE", value: "location_date_solde" },
                  { text: "DATE_PAIEMENT_ATTENDU", value: "date_paiement_attendu" },
                ]
              : []),
          ]
        : [];

    return (
      <Form ref={this.formRef} onFinish={this.onFinish} style={{ padding: "20px" }}>
        <Row>
          <Col span={24}>
            <Form.Item name="validation" initialValue={1}>
              <Radio.Group onChange={this.onChangeType}>
                <Radio.Button className="template-radio" value={1}>
                  TEMPLATE MAIL
                </Radio.Button>
                {/* <Radio.Button className="template-radio" value={6}>
                  NOTIF. PROPRIÉTAIRE
                </Radio.Button> */}
                <Radio.Button className="template-radio" value={2}>
                  FAQ MAIL
                </Radio.Button>
                {/* <Radio.Button className="template-radio" value={5}>
                  DOCUMENT DISPOS
                </Radio.Button> */}
                <Radio.Button className="template-radio" value={3}>
                  DESCRIPTION LOT
                </Radio.Button>
                <Radio.Button className="template-radio" value={4}>
                  TEMPLATE DOCS
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Card
          title={title}
          bordered={false}
          actions={template < 4 || template === 6 ? [footer] : []}
          className="card-panel"
          headStyle={{ padding: 0 }}
        >
          <Row>
            <Col span={16} style={{ height: 600 }}>
              {(template < 4 || template === 6) && (
                <>
                  <Row>
                    <Col offset={1} span={22}>
                      <Form.Item
                        name="template_libelle"
                        rules={[
                          {
                            required: true,
                            message: "Libellé obligatoire",
                          },
                        ]}
                      >
                        <Input placeholder="Libellé" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col offset={1} span={3}>
                      <Form.Item
                        name="template_langue"
                        initialValue={langues[0].langue_id}
                        rules={[
                          {
                            required: true,
                            message: "Langue obligatoire",
                          },
                        ]}
                      >
                        <Radio.Group onChange={this.onChangeLangue}>
                          {langues &&
                            langues.map((item, i) => {
                              return (
                                <Radio.Button key={i} value={item.langue_id}>
                                  {item.langue_code}
                                </Radio.Button>
                              );
                            })}
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        label="Notification propriétaire"
                        name="template_notification"
                        valuePropName="checked"
                      >
                        <Switch
                        // checked={null}
                        // onChange={null}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        label="Notification annulation"
                        name="template_annulation"
                        valuePropName="checked"
                      >
                        <Switch
                        // checked={null}
                        // onChange={null}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {(template === 3 || template === 1) && (
                    <Row>
                      <Col offset={1} span={22}>
                        <Form.Item
                          name="template_titre"
                          rules={[
                            {
                              required: true,
                              message: "Titre obligatoire",
                            },
                          ]}
                        >
                          <Input placeholder="Titre" onChange={this.onTitreChange} />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col offset={1} span={22}>
                      <Form.Item name="template_text">
                        <Editor
                          style={{ height: "600px" }}
                          editorState={editorState}
                          toolbarClassName="toolbar-class"
                          wrapperClassName="wrapper-class"
                          editorClassName="editor-template"
                          onEditorStateChange={this.onEditorStateChange}
                          mention={{
                            separator: " ",
                            trigger: "#",
                            suggestions,
                          }}
                          toolbar={{
                            image: {
                              alt: { present: true, mandatory: false },
                              previewImage: true,
                              uploadEnabled: true,
                              uploadCallback: this._uploadImageCallBack,
                            },
                            inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
              {template === 4 && (
                <FormWord
                  utilisateur={utilisateur}
                  type={templateSelected}
                  words={this.state.words.filter((i) => i.word_type === templateSelected)}
                  tags={tags}
                  saveWord={this.props.saveWord}
                  updateWord={this.props.updateWord}
                  deleteWord={this.props.deleteWord}
                />
              )}
              {template === 5 && (
                // <FormDocumentDispo
                //   utilisateur={utilisateur}
                //   documents={this.props.documents.filter(
                //     (document) => document.document_dispo_mail === true
                //   )}
                //   saveDocument={this.props.saveDocument}
                //   deleteDocument={this.props.deleteDocument}
                // />
                <span>Documents dispos</span>
              )}
            </Col>
            <Col span={8}>
              {template === 1 && (
                <Row>
                  <Col offset={1} span={22} style={{ height: 500 }}>
                    <Row type="flex" gutter={10} style={{ marginBottom: 10 }}>
                      <Col>
                        <Input.Search
                          allowClear
                          placeholder="Rechercher"
                          onChange={(nameSearch) =>
                            this.setState({
                              etapes: this.props.etapes.filter((etape) =>
                                etape.etape_libelle
                                  .toLowerCase()
                                  .includes(nameSearch.target.value.toLowerCase())
                              ),
                              isSearching: nameSearch.target.value === "" ? false : true,
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Table
                        size={"small"}
                        columns={columns}
                        expandable={false}
                        pagination={false}
                        scroll={{ y: 500 }}
                        rowSelection={rowSelection}
                        childrenColumnName={"aucun"}
                        dataSource={etapes
                          .map((etape) => {
                            if (etape.etape_etape_parente?.etape_id === 0)
                              etape.etape_str = `${twoDigits(etape.etape_ordre)}0000`;
                            else if (etape.etape_etape_parente?.etape_etape_parente?.etape_id === 0)
                              etape.etape_str = `${twoDigits(
                                etape.etape_etape_parente.etape_ordre
                              )}${twoDigits(etape.etape_ordre)}00`;
                            else
                              etape.etape_str = `${twoDigits(
                                etape.etape_etape_parente?.etape_etape_parente?.etape_ordre
                              )}${twoDigits(etape.etape_etape_parente?.etape_ordre)}${twoDigits(
                                etape.etape_ordre
                              )}`;
                            return etape;
                          })
                          .sort((a, b) => a.etape_str - b.etape_str)}
                      />
                    </Row>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Card>
      </Form>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveTemplate: (jsonData) => dispatch(saveTemplate(jsonData)),
    updateTemplate: (jsonData, id) => dispatch(updateTemplate(jsonData, id)),
    saveTemplatesLangues: (jsonData, idTemplate, idLangue) =>
      dispatch(saveTemplatesLangues(jsonData, idTemplate, idLangue)),
    updateTemplatesLangues: (jsonData, idTemplate, idLangue) =>
      dispatch(updateTemplatesLangues(jsonData, idTemplate, idLangue)),
    deleteTemplate: (id) => dispatch(deleteTemplate(id)),
    saveEtapesTemplates: (idEtape, idTemplate) =>
      dispatch(saveEtapesTemplates(idEtape, idTemplate)),
    deleteEtapesTemplates: (idTemplate) => dispatch(deleteEtapesTemplates(idTemplate)),
    saveWord: (jsonData, dossier, etablissement_id) =>
      dispatch(saveWord(jsonData, dossier, etablissement_id)),
    updateWord: (jsonData, id) => dispatch(updateWord(jsonData, id)),
    deleteWord: (id, type) => dispatch(deleteWord(id, type)),
    saveImage: (jsonData) => dispatch(saveImage(jsonData)),
    saveDocument: (jsonData, dossier, tag) => dispatch(saveDocument(jsonData, dossier, tag)),
    deleteDocument: (id) => dispatch(deleteDocument(id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    etapes: state.etapes.data,
    langues: state.langues.data,
    templates: state.templates.data,
    etapesTemplates: state.etapesTemplates.data,
    templatesLangues: state.templatesLangues.data,
    words: state.words.data,
    documents: state.documents.data,
    tags: state.tags.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Template);

import React, { Component } from "react";

import { Row, Col, Table, Button, Modal, Form, Checkbox, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import { EditableRow, EditableCell } from "../EditableTable";

import dayjs from "dayjs";

import CustomDatePicker from "../CustomFormItem/DatePicker";
import { round } from "../../utils/tools";

const { Column } = Table;
const { Text } = Typography;

class FormNuiteNew extends Component {
  state = {
    lot: null,
    typeTarif: 1,
    periodes: [],
    rubriques: [],
    selectedRowKeys: [],
    selectedRows: [],
    arrivee: false,
    sejourMin: false,
    modalPeriode: false,
    modalRubriqueNew: false,
    modalNuiteVisible: false,
    nuiteDebut: null,
    nuiteFin: null,
    nuiteMontant: 0,
    fraisAgence: 0,
  };

  componentDidMount() {
    this.init(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.init(props);
  }

  init = (props) => {
    let nuiteDebut = props.nuiteDebut;
    let nuiteFin = props.nuiteFin;

    this.props.formRefNuite.current.setFieldsValue({
      selection_debut: dayjs(nuiteDebut),
      selection_fin: dayjs(nuiteFin),
    });

    this.setState({
      nuiteDebut,
      nuiteFin,
    });

    let rubriques = this.props.rubriques.sort(
      (a, b) => parseInt(a.rubrique_code) - parseInt(b.rubrique_code)
    );
    props.lot.lot_nuites.forEach((ligne) => {
      if (
        ligne.nuite_date_debut === this.state.nuiteDebut &&
        ligne.nuite_date_fin === this.state.nuiteFin
      ) {
        rubriques = rubriques.filter((i) => i.rubrique_id !== ligne.nuite_rubrique.rubrique_id);
      }
    });

    rubriques = rubriques.filter((r) => r.rubrique_contrat === true);

    this.setState({
      rubriques,
      lot: props.lot,
    });
  };

  getRubriques = (value) => {
    this.getRubriquesAvailable(value);
  };

  getRubriquesAvailable = (value) => {
    let rubriques = this.props.rubriques;

    if (!value || (value && value.target.checked === false)) {
      rubriques = rubriques.filter((i) => i.rubrique_contrat === true);
    }

    this.props.lot.lot_nuites.forEach((ligne) => {
      if (
        ligne.nuite_date_debut === this.state.nuiteDebut &&
        ligne.nuite_date_fin === this.state.nuiteFin
      ) {
        rubriques = rubriques.filter((i) => i.rubrique_id !== ligne.nuite_rubrique.rubrique_id);
      }
    });

    this.setState({ rubriques });
  };

  openModalRubrique = () => {
    this.setState({ selectedRowKeys: [], selectedRows: [], modalRubriqueNew: true });
  };

  closeModalRubrique = () => {
    this.setState({ selectedRowKeys: [], selectedRows: [], modalRubriqueNew: false });
  };

  openModalPeriode = () => {
    this.setState({ modalPeriode: true });
  };

  closeModalPeriode = () => {
    this.setState({ modalPeriode: false });
  };

  onClickAddPeriode = () => {
    this.openModalPeriode();
  };

  onSelectPeriode = (id) => {
    console.log(id);
  };

  handleSubmitAddPeriode = (periode) => {
    this.setState((prevState) => ({
      periodes: prevState.periodes.concat(periode),
    }));
    this.closeModalPeriode();
  };

  handleSubmitAddRubriques = () => {
    const lot = this.state.lot;

    this.state.selectedRows.forEach((rubrique) => {
      let montant = 0;

      if (rubrique.rubrique_pourcentage !== null || rubrique.rubrique_pourcentage !== 0) {
        rubrique.rubrique_liees.forEach((item) => {
          const prix = lot.lot_nuites.find(
            (p) =>
              p.nuite_rubrique.rubrique_id === item.rubrique_id &&
              p.nuite_date_debut === this.state.nuiteDebut &&
              p.nuite_date_fin === this.state.nuiteFin
          );
          if (prix) {
            montant += (parseFloat(prix.nuite_montant) * rubrique.rubrique_pourcentage) / 100;
          }
        });
      }

      if (rubrique.rubrique_montant_fixe === true) {
        montant = rubrique.rubrique_montant_defaut;
      }

      const ligne = {
        nuite_date_debut: this.state.nuiteDebut,
        nuite_date_fin: this.state.nuiteFin,
        nuite_rubrique_id: rubrique.rubrique_id,
        nuite_montant: montant,
        nuite_rubrique: rubrique,
        nuite_lot_id: lot.lot_id,
      };
      this.props.saveNuite(ligne, lot);
    });

    this.closeModalRubrique();
  };

  handleSave = (row) => {
    const lot = this.state.lot;
    this.props.updateNuite(
      {
        nuite_rubrique_id: row.nuite_rubrique.rubrique_id,
        nuite_montant: row.nuite_montant,
        nuite_date_debut: this.state.nuiteDebut,
        nuite_date_fin: this.state.nuiteFin,
      },
      row.nuite_id,
      lot
    );

    lot.lot_nuites.forEach((item) => {
      if (item.nuite_rubrique.rubrique_liees.length > 0) {
        let montant = 0;
        item.nuite_rubrique.rubrique_liees.forEach((ligne) => {
          if (ligne.rubrique_id === row.nuite_rubrique.rubrique_id) {
            montant +=
              (parseFloat(row.nuite_montant) * item.nuite_rubrique.rubrique_pourcentage) / 100;
          }
        });

        this.props.updateNuite(
          {
            nuite_rubrique_id: item.nuite_rubrique.rubrique_id,
            nuite_montant: montant,
            nuite_date_debut: this.state.nuiteDebut,
            nuite_date_fin: this.state.nuiteFin,
          },
          item.nuite_id,
          lot
        );
      }
    });
  };

  deleteNuite = (id) => {
    const lot = this.state.lot;
    let nuite = 0;
    lot.lot_nuites.forEach((item) => {
      if (item.nuite_rubrique.rubrique_id === id) {
        nuite = item.nuite_id;
      }
    });
    this.props.deleteNuite(nuite, lot);
  };

  onChangeRadio = (e) => {
    this.setState({ typeTarif: e.target.value });
  };

  onChangeNuit = (e) => {
    this.setState({ nuit: !this.state.nuit });
  };

  onChangeSemaine = (e) => {
    this.setState({ semaine: !this.state.semaine });
  };

  onChangeMois = (e) => {
    this.setState({ mois: !this.state.mois });
  };

  onChangeSejourMin = (e) => {
    this.setState({ sejourMin: !this.state.sejourMin });
  };

  onChangeArrivee = (e) => {
    this.setState({ arrivee: !this.state.arrivee });
  };

  onChangeDateDebut = (e) => {
    this.props.onChangeDateDebut(e);
    this.setState({ nuiteDebut: dayjs(e, "DD/MM/YYYY").add(1, "minutes").format("YYYY-MM-DD") });
  };

  onChangeDateFin = (e) => {
    this.props.onChangeDateFin(e);
    this.setState({ nuiteFin: dayjs(e, "DD/MM/YYYY").add(1, "minutes").format("YYYY-MM-DD") });
  };

  handleDateSelect = (selectInfo) => {
    // ajouter 1min à la date de début et enlever 1min à la date de fin
    // pour obtenir les jours sélectionnés et non un jour supplémentaire
    // this.formRefNuiteNew.current.setFieldsValue({
    //   selection_debut: dayjs(selectInfo.startStr)
    //               .add(1, "minutes")
    //               .format("YYYY-MM-DD"),
    //   selection_fin: dayjs(selectInfo.endStr)
    //               .subtract(1, "minutes")
    //               .format("YYYY-MM-DD"),
    // });
    this.setState({
      modalNuiteVisible: true,
      nuiteDebut: dayjs(selectInfo.startStr).add(1, "minutes").format("YYYY-MM-DD"),
      nuiteFin: dayjs(selectInfo.endStr).subtract(1, "minutes").format("YYYY-MM-DD"),
    });
  };

  handleCancelNuite = () => {
    this.setState({ modalNuiteVisible: false });
  };

  render() {
    const { lot, rubriques, modalRubriqueNew, nuiteDebut, nuiteFin, selectedRowKeys } = this.state;

    const { isArchive } = this.props;

    let rtvNuites = lot && lot.lot_nuites;

    let lignesNuites = [];
    if (this.state.nuiteDebut !== null) {
      lignesNuites = rtvNuites
        .filter((r) => r.nuite_date_debut === this.state.nuiteDebut)
        .filter((r) => r.nuite_date_fin === this.state.nuiteFin)
        .sort(
          (a, b) =>
            parseInt(a.nuite_rubrique.rubrique_code) - parseInt(b.nuite_rubrique.rubrique_code)
        );
    }

    let columnsA = [
      {
        title: "Code",
        dataIndex: "nuite_rubrique",
        key: "nuite_rubrique",
        width: "10%",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) =>
          parseInt(a.nuite_rubrique.rubrique_code) - parseInt(b.nuite_rubrique.rubrique_code),
        render: (rubrique) => rubrique.rubrique_code,
      },
      {
        title: "Libellé",
        dataIndex: "nuite_rubrique",
        key: "nuite_rubrique",
        width: "60%",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) =>
          a.nuite_rubrique.rubrique_libelle.localeCompare(b.nuite_rubrique.rubrique_libelle),
        render: (rubrique) => rubrique.rubrique_libelle,
      },
      {
        title: "Montant",
        dataIndex: "nuite_montant",
        key: "nuite_montant",
        render: (montant) =>
          parseFloat(montant) !== 0 ? (
            parseFloat(montant).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })
          ) : (
            <>0.00 €</>
          ),
        className: "column-money",
        width: "20%",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) => a.nuite_montant - b.nuite_montant,
        editable: !isArchive,
      },
      {
        title: "Supprimer",
        dataIndex: "nuite_rubrique",
        key: "nuite_rubrique",
        render: (rubrique) => (
          <Button
            disabled={isArchive}
            icon={<DeleteOutlined />}
            shape="circle"
            onClick={() => this.deleteNuite(rubrique.rubrique_id)}
          />
        ),
        width: "10%",
      },
    ];

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys, selectedRows });
      },
      selectedRowKeys: selectedRowKeys,
    };

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };

    const columns = columnsA.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    return (
      <>
        {/* MODAL RUBRIQUES */}
        <Modal
          footer={null}
          width="600px"
          title="Rubriques"
          open={modalRubriqueNew}
          onCancel={this.closeModalRubrique}
        >
          <div>
            <Row>
              <Col span={20}></Col>
              <Col span={4}>
                <Checkbox onChange={(value) => this.getRubriques(value)}>Voir toutes</Checkbox>
              </Col>
            </Row>
            <Row>
              <Table
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                rowKey="rubrique_id"
                dataSource={rubriques.sort(
                  (a, b) => parseInt(a.rubrique_code) - parseInt(b.rubrique_code)
                )}
              >
                <Column title="Code" dataIndex="rubrique_code" key="code" />
                <Column title="Libelle" dataIndex="rubrique_libelle" key="libelle" />
              </Table>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Button
                type="primary"
                onClick={this.handleSubmitAddRubriques}
                style={{ float: "right" }}
              >
                Valider
              </Button>
            </Row>
          </div>
        </Modal>

        <Form ref={this.props.formRefNuite}>
          <Row gutter={10}>
            <Col span={12}>
              <CustomDatePicker
                label="Début"
                inputName="selection_debut"
                formItemName="selection_debut"
                onChange={(e) => this.onChangeDateDebut(e)}
                objectValue={dayjs(nuiteDebut)}
              />
            </Col>
            <Col span={12}>
              <CustomDatePicker
                label="Fin"
                inputName="selection_fin"
                formItemName="selection_fin"
                onChange={(e) => this.onChangeDateFin(e)}
                objectValue={dayjs(nuiteFin)}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 5 }}>
            <Col span={2}>
              <Button type="primary" disabled={isArchive} onClick={this.openModalRubrique}>
                Ajout Rubrique
              </Button>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={24}>
              <Table
                columns={columns}
                components={components}
                dataSource={lignesNuites}
                rowClassName={() => "editable-row"}
                rowKey={(r) => r.nuite_rubrique.rubrique_id}
                summary={(pageData) => {
                  let total = 0;
                  pageData.forEach((item) => {
                    if (item.nuite_rubrique.rubrique_montant_fixe === false) {
                      total += parseFloat(item.nuite_montant);
                    }
                  });
                  return (
                    <Table.Summary.Row>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <b>TOTAL*</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text>
                          <b>{round(total, 2)} €</b>
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                    </Table.Summary.Row>
                  );
                }}
              />
            </Col>
          </Row>
          <Row>
            <i>*Hors montants fixes</i>
          </Row>
        </Form>
      </>
    );
  }
}

export default FormNuiteNew;

import React, { Component } from "react";
import constants from "../../utils/constants";

import { toast } from "react-toastify";

import { Row, Col, Select, Tag, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const { Option, OptGroup } = Select;

class FormApptChoisis extends Component {
  state = {
    requete: null,
    inputVisible: false,
  };

  componentDidMount() {
    this.init(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.init(props);
  }

  init = (props) => {
    this.setState({
      requete: props.requete,
      requetesLots: props.requetesLots.filter((req) => req.requete_lot_type === 1),
      lotsAjoutables: props.lotsAjoutables,
    });
  };

  showInput = () => {
    this.setState({ inputVisible: true });
  };

  looseInput = () => {
    this.setState({ inputVisible: false });
  };

  onConfirmDelete = (appartement) => {
    this.props.deleteRequeteLot(this.state.requete.requete_id, appartement.lot_id, 2).then((res) =>
      toast.success("Le lot n'est plus lié à la requête !", {
        containerId: "A",
      })
    );
  };

  onClickAfficher = (appartement) => {
    this.props.afficherAppartement(appartement);
  };

  onSelectLot = (id) => {
    if (!this.props.requete) {
      toast.error("Veuillez sélectionner une requête !", { containerId: "A" });
      return;
    }

    if (this.select !== undefined) {
      this.select.blur();
    }

    this.props
      .saveRequeteLot({
        requete_lot_requete_id: this.props.requete.requete_id,
        requete_lot_lot_id: id,
        requete_lot_etat: "",
        requete_lot_type: 2,
      })
      .then((res) => toast.success("Lot lié à la requête !", { containerId: "A" }))
      .catch((err) =>
        toast.error("Ce lot est déjà lié à cette requête !", {
          containerId: "A",
        })
      );
  };

  handleClose = (tag) => {
    this.props.deleteRequeteLot(this.state.requete.requete_id, tag, 2);
  };

  getColor = (etat) => {
    let color = "";
    switch (etat) {
      case constants.LOT_DISPONIBLE.toString():
        color = "#87d068";
        break;
      case constants.LOT_OPTION.toString():
        color = "#ffbf00";
        break;
      case constants.LOT_NON_DISPONIBLE.toString():
        color = "#D46A6A";
        break;
      case constants.LOT_SANS_CORRESPONDANCE.toString():
        break;
      default:
        break;
    }
    return color;
  };

  getText = (etat) => {
    let text = "";
    switch (etat) {
      case constants.LOT_DISPONIBLE.toString():
        text = "Lot disponible";
        break;
      case constants.LOT_OPTION.toString():
        text = "Lot en option";
        break;
      case constants.LOT_NON_DISPONIBLE.toString():
        text = "Lot indisponible";
        break;
      case constants.LOT_SANS_CORRESPONDANCE.toString():
        break;
      default:
        break;
    }
    return text;
  };

  render() {
    const {
      // requete,
      lotsAjoutables,
    } = this.props;
    const { inputVisible } = this.state;

    const requetesLots = this.props.requetesLots.filter((req) => req.requete_lot_type === 2);

    return (
      <Row>
        {requetesLots.map((item, i) => {
          return (
            <Tooltip key={item.requete_lot_lot.lot_id} title={this.getText(item.requete_lot_etat)}>
              <Tag
                key={item.requete_lot_lot.lot_id}
                color={this.getColor(item.requete_lot_etat)}
                closable
                onClose={() => this.handleClose(item.requete_lot_lot.lot_id)}
              >
                {item.requete_lot_lot.lot_reference}
              </Tag>
            </Tooltip>
          );
        })}
        {inputVisible && (
          <Select
            ref={(input) => {
              this.select = input;
            }}
            showSearch
            size="small"
            optionLabelProp="label"
            placeholder="APPT. CHOISIS"
            onSelect={this.onSelectLot}
            onBlur={this.looseInput}
            style={{ width: "150px" }}
            dropdownRender={(menu) => <div>{menu}</div>}
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <OptGroup label="Appartement">
              {lotsAjoutables &&
                lotsAjoutables.map((item, i) => {
                  return (
                    <Option key={i} value={item.lot_id} label={`${item.lot_reference}`}>
                      <Row>
                        <Col sm={8}>{`${item.lot_reference}`}</Col>
                      </Row>
                    </Option>
                  );
                })}
            </OptGroup>
          </Select>
        )}
        {!inputVisible && (
          <Tag className="site-tag-plus" onClick={this.showInput}>
            <PlusOutlined /> Ajouter appt.
          </Tag>
        )}
      </Row>
    );
  }
}

export default FormApptChoisis;
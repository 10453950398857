import { EyeOutlined } from "@ant-design/icons";
import { Button } from "antd";
import dayjs from "dayjs";

export const columns = (onClickAfficher) => [
  {
    title: "#",
    dataIndex: "quittance_id",
    key: "quittance_id",
    sorter: (a, b) => parseInt(a.quittance_id) - parseInt(b.quittance_id),
    width: "6%",
  },
  {
    title: "N° Contrat",
    dataIndex: "quittance_location_id",
    key: "quittance_location_id",
    width: "10%",
  },
  {
    title: "Date début",
    dataIndex: "quittance_date_debut",
    key: "quittance_date_debut",
    render: (date) => dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
    sorter: (a, b) => dayjs(a.quittance_date_debut).unix() - dayjs(b.quittance_date_debut).unix(),
    width: "10%",
  },
  {
    title: "Date fin",
    dataIndex: "quittance_date_fin",
    key: "quittance_date_fin",
    render: (date) => dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
    sorter: (a, b) => dayjs(a.quittance_date_fin).unix() - dayjs(b.quittance_date_fin).unix(),
    width: "10%",
  },
  {
    title: "Locataire",
    dataIndex: "locataire",
    key: "locataire",
    sorter: (a, b) => a.locataire?.localeCompare(b.locataire),
    width: "24%",
  },
  {
    title: "Montant Total",
    dataIndex: "montantTotal",
    key: "montantTotal",
    render: (montantTotal) => {
      return montantTotal?.toLocaleString("fr-FR", {
        style: "currency",
        currency: "EUR",
      });
    },
    width: "10%",
  },
  {
    title: "Montant réglé",
    dataIndex: "montantReglements",
    key: "montantReglements",
    render: (montantReglements) => {
      return montantReglements?.toLocaleString("fr-FR", {
        style: "currency",
        currency: "EUR",
      });
    },
    width: "10%",
  },
  {
    title: "Solde dû",
    dataIndex: "solde",
    key: "solde",
    render: (solde) => {
      return solde?.toLocaleString("fr-FR", {
        style: "currency",
        currency: "EUR",
      });
    },
    width: "10%",
  },
  {
    title: "Requete",
    dataIndex: "requete",
    key: "requete",
    render: (requete) => (
      <Button onClick={() => onClickAfficher(requete)}>
        <EyeOutlined />
      </Button>
    ),
    width: "10%",
  },
];

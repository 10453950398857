import { adaptedToAdresse, adresseToAdapted } from "./adresseAdapter";
import { adaptedToCompteBancaire, compteBancaireToAdapted } from "./compteBancaireAdapter";
import { groupementToAdapted } from "./groupementAdapter";
import { langueToAdapted } from "./langueAdapter";
import { qualiteToAdapted } from "./qualiteAdapter";

export function personnesToAdapted(items) {
  return items
    ? items.map((item) => {
        return personneToAdapted(item);
      })
    : null;
}

export function personneToAdapted(item) {
  return item
    ? {
        personne_id: item.id,
        personne_nom: item.nom,
        personne_prenom: item.prenom,
        personne_portable1: item.portable1,
        personne_portable2: item.portable2,
        personne_tel1: item.tel1,
        personne_tel2: item.tel2,
        personne_commentaire_portable1: item.commentaire_portable1,
        personne_commentaire_portable2: item.commentaire_portable2,
        personne_commentaire_tel1: item.commentaire_tel1,
        personne_commentaire_tel2: item.commentaire_tel2,
        personne_email1: item.email1,
        personne_email2: item.email2,
        personne_commentaire: item.commentaire,
        personne_actif: item.actif,
        personne_groupement: groupementToAdapted(item.groupement),
        personne_langue: langueToAdapted(item.langue),
        personne_adresse: adresseToAdapted(item.adresse),
        personne_qualite: qualiteToAdapted(item.qualite),
        personne_compte_bancaire: compteBancaireToAdapted(item.compte_bancaire),
        personne_lieu_naissance: item.lieu_naissance,
        personne_date_naissance: item.date_naissance,
      }
    : null;
}

export function simplePersonneToAdapted(item) {
  return item
    ? {
        personne_id: item.id,
        personne_nom: item.nom,
        personne_prenom: item.prenom,
        personne_tel1: item.tel1,
        personne_tel2: item.tel2,
        personne_portable1: item.portable1,
        personne_portable2: item.portable2,
        personne_email1: item.email1,
        personne_email2: item.email2,
        personne_adresse: adresseToAdapted(item.adresse),
        personne_qualite: qualiteToAdapted(item.qualite),
        personne_langue: langueToAdapted(item.langue),
        personne_groupement_id: item.groupement_id,
      }
    : null;
}

export function adaptedToPersonne(item) {
  const personne = item
    ? {
        nom: item.personne_nom,
        prenom: item.personne_prenom,
        portable1: item.personne_portable1,
        portable2: item.personne_portable2,
        tel1: item.personne_tel1,
        tel2: item.personne_tel2,
        commentaire_portable1: item.personne_commentaire_portable1,
        commentaire_portable2: item.personne_commentaire_portable2,
        commentaire_tel1: item.personne_commentaire_tel1,
        commentaire_tel2: item.personne_commentaire_tel2,
        email1: item.personne_email1,
        email2: item.personne_email2,
        commentaire: item.personne_commentaire,
        actif: item.personne_actif,
        langue_id: item.personne_langue_id,
        groupement_id: item.personne_groupement_id,
        adresse_id: item.personne_adresse_id,
        qualite_id: item.personne_qualite_id,
        compte_bancaire_id: item.personne_compte_bancaire_id,
        lieu_naissance: item.personne_lieu_naissance,
        date_naissance: item.personne_date_naissance,
      }
    : null;

  const adresse = item ? adaptedToAdresse(item) : null;

  const compteBancaire = item ? adaptedToCompteBancaire(item) : null;

  return item
    ? {
        ...personne,
        ...personne,
        ...adresse,
        ...compteBancaire,
      }
    : null;
}

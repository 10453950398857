import { societeToAdapted } from "./societeAdapter";
import { adaptedToCompte, compteToAdapted } from "./compteAdapter";
import { dossierToAdapted } from "./dossierAdapter";

export function proprietaireAffectionsToAdapted(items) {
  return items
    ? items.map((item) => {
        return proprietaireAffectationToAdapted(item);
      })
    : null;
}

export function proprietaireAffectationToAdapted(item) {
  //Resource
  return item
    ? {
        proprietaire_affectation_proprietaire_id: item.proprietaire_id,
        proprietaire_affectation_societe: societeToAdapted(item.societe),
        proprietaire_affectation_compte: compteToAdapted(item.compte),
        proprietaire_affectation_compte_id: item.compte_id,
        proprietaire_affectation_dossier: dossierToAdapted(item.dossier),
      }
    : null;
}

export function simplesProprietaireAffectionsToAdapted(items) {
  return items
    ? items.map((item) => {
        return proprietaireAffectationToAdapted(item);
      })
    : null;
}

export function simpleProprietaireAffectationToAdapted(item) {
  //Resource
  return item
    ? {
        proprietaire_affectation_proprietaire_id: item.proprietaire_id,
        proprietaire_affectation_societe_id: item.societe_id,
        proprietaire_affectation_compte_id: item.compte_id,
        proprietaire_affectation_dossier_id: item.dossier_id,
      }
    : null;
}

export function adaptedToProprietaireAffectation(item) {
  //Model
  const affectation = item
    ? {
        proprietaire_id: item.proprietaire_affectation_proprietaire_id,
        societe_id: item.proprietaire_affectation_societe_id,
        compte_id: item.proprietaire_affectation_compte_id,
        dossier_id: item.proprietaire_affectation_dossier_id,
      }
    : null;

  const compte = item ? adaptedToCompte(item) : null;

  return item
    ? {
        ...affectation,
        ...compte,
      }
    : null;
}

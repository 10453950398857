import React, { Component } from "react";
import { DeleteOutlined, EditOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Row, Col, Modal, Button, Form, Popconfirm, Card, Select, Popover } from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import CardPiece from "./CardPiece";
const { Option } = Select;

class FormPiece extends Component {
  constructor(props) {
    super(props);

    this.state = {
      piece: null,
      groupe: null,
      length: 0,
      show: false,
      modalVisible: false,
    };
  }

  form = React.createRef();

  openModal = (groupe_id) => {
    const groupe = this.props.groupes.filter((item) => {
      return item.champ_perso_groupe_id === groupe_id;
    })[0];

    const length =
      this.props.pieces.filter(
        (item) =>
          item.piece_lot_id === this.props.lot.lot_id &&
          item.piece_champ_perso_groupe === groupe.champ_perso_groupe_id
      ).length + 1;

    this.setState({ groupe, length, modalVisible: true }, () => {
      setTimeout(() => {
        if (this.form.current) {
          if (this.state.piece) {
            this.state.piece.piece_contenu.forEach((item) => {
              this.form.current.setFieldsValue({
                [`${item.piece_contenu_champ_perso.champ_perso_id}`]: this.getContenuPieceValue(
                  item.piece_contenu_champ_perso.champ_perso_type,
                  item.piece_contenu_valeur
                ),
              });
            });
          } else {
            this.form.current.resetFields();
          }
        }

        return null;
      }, 100);
    });
  };

  getContenuPieceValue = (type, value) => {
    let parsedValue = null;
    switch (type) {
      case 1:
        value === true || value === "1" ? (parsedValue = true) : (parsedValue = false);
        break;
      case 2:
        parsedValue = parseInt(value);
        break;
      case 3:
        parsedValue = value;
        break;
      default:
        break;
    }
    return parsedValue;
  };

  closeModal = () => {
    this.setState({ piece: null, modalVisible: false });
  };

  onFinish = (data) => {
    if (this.state.piece) {
      this.handleEditPiecesContenus(data, this.state.piece);
    } else {
      this.props
        .savePiece({
          piece_libelle: this.state.groupe.champ_perso_groupe_libelle + " " + this.state.length,
          piece_champ_perso_groupe_id: this.state.groupe.champ_perso_groupe_id,
          piece_lot_id: this.props.lot.lot_id,
        })
        .then((res) => this.handleSavePiecesContenus(data, res))
        .catch((err) => console.log("err", err));
    }

    this.closeModal();
  };

  handleSavePiecesContenus = (data, piece) => {
    this.state.groupe.champ_perso.forEach((item) => {
      data[item.champ_perso_id] !== undefined &&
        this.props.savePiecesContenus({
          piece_contenu_piece_id: piece.piece_id,
          piece_contenu_champ_perso_id: item.champ_perso_id,
          piece_contenu_valeur: data[item.champ_perso_id],
        });
    });
  };

  handleEditPiecesContenus = (data, piece) => {
    this.state.groupe.champ_perso.forEach((item) => {
      const existingPieceContenu = piece.piece_contenu.find(
        (contenu) => contenu.piece_contenu_champ_perso.champ_perso_id === item.champ_perso_id
      );
      if (existingPieceContenu) {
        data[item.champ_perso_id] !== undefined &&
          this.props.updatePiecesContenus(
            {
              piece_contenu_valeur: data[item.champ_perso_id],
            },
            piece.piece_id,
            item.champ_perso_id
          );
      } else {
        data[item.champ_perso_id] !== undefined &&
          this.props.savePiecesContenus({
            piece_contenu_piece_id: piece.piece_id,
            piece_contenu_champ_perso_id: item.champ_perso_id,
            piece_contenu_valeur: data[item.champ_perso_id],
          });
      }
    });
  };

  onEdit = (piece) => {
    this.setState({ piece }, () => this.openModal(piece.piece_champ_perso_groupe));
  };

  onDelete = (piece) => {
    this.props.deletePiece(piece.piece_id).then((res) => {
      this.props.pieces
        .filter(
          (item) =>
            item.piece_lot_id === this.props.lot.lot_id &&
            item.piece_champ_perso_groupe === piece.piece_champ_perso_groupe
        )
        .forEach((item, i) => {
          this.props.updatePiece(
            {
              piece_libelle:
                item.piece_libelle.substring(0, item.piece_libelle.lastIndexOf(" ")) +
                " " +
                (i + 1),
            },
            item.piece_id
          );
        });
    });
  };

  mouseHover = () => {
    this.setState({ show: !this.state.show });
  };

  render() {
    const { lot, groupes, pieces, isArchive } = this.props;
    const { piece, groupe, show, hovered, modalVisible } = this.state;

    const content = (
      <>
        <Select
          showSearch
          size="medium"
          disabled={isArchive}
          optionLabelProp="label"
          optionFilterProp="label"
          defaultValue={null}
          onSelect={(id) => this.openModal(id)}
          style={{ width: "150px" }}
        >
          {groupes &&
            groupes
              .filter((groupe) => groupe.champ_perso_groupe_type === 4)
              .map((item, i) => {
                return (
                  <Option
                    key={i}
                    value={item.champ_perso_groupe_id}
                    label={item.champ_perso_groupe_libelle}
                  >
                    <Row>
                      <Col sm={8}>{item.champ_perso_groupe_libelle}</Col>
                    </Row>
                  </Option>
                );
              })}
        </Select>
      </>
    );

    return (
      <>
        <Modal
          open={modalVisible}
          onCancel={this.closeModal}
          title="Pièce"
          okText={piece ? "Enregistrer" : "Ajouter"}
          cancelText="Annuler"
          onOk={() => {
            this.form.current
              .validateFields()
              .then((data) => {
                this.form.current.resetFields();
                this.onFinish(data);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          <Form
            ref={this.form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{
              modifier: "public",
            }}
          >
            <Row>{groupe && <CardPiece groupe={groupe} />}</Row>
          </Form>
        </Modal>

        <Row gutter={16} style={{ padding: 10 }}>
          <Col span={24}>
            <Row style={{ textAlign: "left", paddingBottom: 5, paddingLeft: 7 }}>
              <Popover content={content} title="Title">
                <PlusSquareOutlined style={{ fontSize: 36 }} disabled={isArchive} />
              </Popover>
            </Row>

            <Row style={{ display: "flex" }}>
              {groupes.map((groupe) => {
                return (
                  groupe.champ_perso_groupe_type === 4 &&
                  pieces.some(
                    (item) =>
                      item.piece_champ_perso_groupe === groupe.champ_perso_groupe_id &&
                      item.piece_lot_id === lot.lot_id
                  ) && (
                    <Col
                      key={groupe.champ_perso_groupe_id}
                      span={4}
                      style={{
                        padding: 10,
                      }}
                    >
                      {pieces.map((item, i) => {
                        return (
                          item.piece_lot_id === lot.lot_id &&
                          item.piece_champ_perso_groupe === groupe.champ_perso_groupe_id && (
                            <Card
                              key={i}
                              hoverable
                              title={item.piece_libelle}
                              headStyle={{ height: 20, background: "#f4c181" }}
                              size="small"
                              onMouseEnter={() => {
                                this.setState({
                                  show: true,
                                  hovered: item.piece_id,
                                });
                              }}
                              onMouseLeave={() => {
                                this.setState({
                                  show: false,
                                  hovered: item.piece_id,
                                });
                              }}
                              extra={
                                show &&
                                hovered === item.piece_id && (
                                  <>
                                    <Button
                                      size="small"
                                      disabled={isArchive}
                                      onClick={() => this.onEdit(item)}
                                    >
                                      <EditOutlined />
                                    </Button>
                                    <Popconfirm
                                      title="Confirmez-vous la suppression?"
                                      onConfirm={() => this.onDelete(item)}
                                    >
                                      <Button size="small" disabled={isArchive} danger>
                                        <DeleteOutlined />
                                      </Button>
                                    </Popconfirm>
                                  </>
                                )
                              }
                              style={
                                {
                                  // marginBottom: 10,
                                }
                              }
                            >
                              <Scrollbars autoHide style={{ height: 150, width: "100%" }}>
                                {item.piece_contenu
                                  .sort(
                                    (a, b) =>
                                      a.piece_contenu_champ_perso.champ_perso_ordre -
                                      b.piece_contenu_champ_perso.champ_perso_ordre
                                  )
                                  .map((item, i) => {
                                  return (
                                    item.piece_contenu_valeur !== 0 &&
                                    item.piece_contenu_valeur !== false &&
                                    item.piece_contenu_valeur !== "" &&
                                    item.piece_contenu_valeur !== "0" && (
                                      <Row key={i}>
                                        <Col
                                          span={16}
                                          style={{
                                            fontSize: 12,
                                            textAlign: "left",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {item.piece_contenu_champ_perso.champ_perso_libelle}
                                        </Col>
                                          {item.piece_contenu_champ_perso.champ_perso_type !==
                                            1 && (
                                        <Col
                                          span={8}
                                          style={{
                                            textAlign: "right",
                                          }}
                                        >
                                          {item.piece_contenu_valeur}
                                        </Col>
                                          )}
                                      </Row>
                                    )
                                  );
                                })}
                              </Scrollbars>
                            </Card>
                          )
                        );
                      })}
                    </Col>
                  )
                );
              })}
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export default FormPiece;

import React from "react";
import {
  FolderViewOutlined, ReloadOutlined,
} from "@ant-design/icons";
import { Button } from "antd";

export const columns = (document, onClickRefresh, onClickAfficher) => {
  return [
    {
      title: "N°",
      dataIndex: "document_id",
      key: "document_id",
      sorter: (a, b) => parseInt(a.document_id) - parseInt(b.document_id),
      width: "5%",
    },
    {
      title: "Nom",
      dataIndex: "document_nom",
      key: "document_nom",
      width: "40%",
    },
    {
      title: "Date et heure",
      dataIndex: "created_at",
      key: "created_at",
      width: "15%",
    },
    {
      title: "Expéditeur",
      dataIndex: "document_utilisateur",
      key: "document_utilisateur",
      width: "15%",
      render: (utilisateur) => utilisateur ? utilisateur.utilisateur_nom : "",
    },
    {
      title: "Destinataire",
      dataIndex: "document_locataire",
      key: "document_locataire",
      width: "15%",
      render: (destinataire) => destinataire ? destinataire.locataire_personne.personne_email1 : "",
    },
    {
      title: "Statut",
      dataIndex: "document_yousign_statut",
      key: "document_yousign_statut",
      width: "10%",
    },
    {
      title: "Actualiser",
      dataIndex: "",
      key: "x",
      render: (document) => (
        <Button
          icon={<ReloadOutlined />}
          onClick={() => onClickRefresh(document)}
        />
      ),
    },
    {
      title: "Afficher",
      dataIndex: "",
      key: "x",
      render: (document) => (
        <Button
          icon={<FolderViewOutlined />}
          onClick={() => onClickAfficher(document)}
        />
      ),
    },
  ];
};



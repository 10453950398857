import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Spin } from "antd";
import ListMouvements from "../../components/ListMouvements";
import { columns } from "./columns";
import { loadLocations } from "../../store/actions/locationAction";
import { loadQuittances } from "../../store/actions/quittanceAction";
import { loadReglements } from "../../store/actions/reglementAction";
import { loadTypesLots } from "../../store/actions/typeLotAction";
import { loadImmeubles } from "../../store/actions/immeubleAction";
import { loadRubriques } from "../../store/actions/rubriqueAction";

class Mouvements extends Component {
  componentDidMount() {
    const groupement_id = this.props.utilisateur.groupement_id;
    const societe_id = this.props.utilisateur.societe_id;
    const etablissement_id = this.props.utilisateur.etablissement_id;
    this.props.loadLocations(groupement_id);
    this.props.loadQuittances(groupement_id);
    this.props.loadReglements(societe_id);
    this.props.loadTypesLots(groupement_id);
    this.props.loadImmeubles(groupement_id);
    this.props.loadRubriques(etablissement_id);
  }

  render() {
    const { locations, quittances, reglements, typesLots, immeubles, rubriques, utilisateur } =
      this.props;

    return (
      <Spin spinning={!locations && !quittances} size="large" tip="Chargement en cours...">
        <Card title="" bordered={false} className="card-panel">
          <ListMouvements
            locations={
              locations &&
              locations
                .sort((a, b) => a.location_id - b.location_id)
                .filter((item) => item.location_lot.lot_societe_id === utilisateur.societe_id)
            }
            quittances={quittances}
            reglements={reglements}
            tableOption={columns}
            typesLots={typesLots}
            immeubles={immeubles}
            rubriques={rubriques}
          ></ListMouvements>
        </Card>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadLocations: (groupement_id) => dispatch(loadLocations(groupement_id)),
    loadQuittances: (groupement_id) => dispatch(loadQuittances(groupement_id)),
    loadReglements: (societe_id) => dispatch(loadReglements(societe_id)),
    loadTypesLots: (groupement_id) => dispatch(loadTypesLots(groupement_id)),
    loadImmeubles: (groupement_id) => dispatch(loadImmeubles(groupement_id)),
    loadRubriques: (etablissement_id) => dispatch(loadRubriques(etablissement_id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    locations: state.locations.data,
    quittances: state.quittances.data,
    reglements: state.reglements.data,
    typesLots: state.typesLots.data,
    immeubles: state.immeubles.data,
    rubriques: state.rubriques.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mouvements);

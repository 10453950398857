import React, { Component } from "react";
import { connect } from "react-redux";
import FormLogin from "../../components/FormLogin";
import { login } from "../../store/actions/utilisateurAction";

class Login extends Component {
  render() {
    return <FormLogin isLoading={false} serverStatus={"success"} login={this.props.login} />;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    login: (jsonData) => dispatch(login(jsonData)),
  };
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);

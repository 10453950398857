import { dossierToAdapted } from "./dossierAdapter";
import { locataireToAdapted } from "./locataireAdapter";
import { tagToAdapted } from "./tagAdapter";
import { utilisateurToAdapted } from "./utilisateurAdapter";

export function documentsToAdapted(items) {
  return items
    ? items.map((item) => {
        return documentToAdapted(item);
      })
    : null;
}

export function documentToAdapted(item) {
  return item
    ? {
        document_id: item.id,
        document_nom: item.nom,
        document_dispo_mail: item.dispo_mail,
        document_dossier: dossierToAdapted(item.dossier),
        document_tag: tagToAdapted(item.tag),
        document_yousign_document_id: item.yousign_document_id,
        document_yousign_requete_id: item.yousign_requete_id,
        document_yousign_statut: item.yousign_statut,
        document_utilisateur: utilisateurToAdapted(item.utilisateur),
        document_locataire: locataireToAdapted(item.locataire),
      }
    : null;
}

export function adaptedToDocument(item) {
  return item
    ? {
        nom: item.document_nom,
        dispo_mail: item.document_dispo_mail,
        dossier_id: item.document_dossier_id,
        tag_id: item.document_tag_id,
        yousign_document_id: item.document_yousign_document_id,
        yousign_requete_id: item.document_yousign_requete_id,
        yousign_statut: item.document_yousign_statut,
        user_id: item.document_utilisateur_id,
        locataire_id: item.document_locataire_id,
      }
    : null;
}

export function adaptedToDocumentWord(item) {
  if (item) {
    item.nom = item.document_nom;
    item.word_nom = item.document_word_nom;
    item.dispo_mail = item.document_dispo_mail;
    item.dossier_id = item.document_dossier_id;
    item.tag_id = item.document_tag_id;
    item.yousign_document_id = item.document_yousign_document_id;
    item.yousign_requete_id = item.document_yousign_requete_id;
    item.yousign_statut = item.document_yousign_statut;
    item.user_id = item.document_utilisateur_id;
    item.locataire_id = item.document_locataire_id;
  }
  return item;
}

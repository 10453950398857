import React, { Component } from "react";

import {
  Row,
  Col,
  Table,
  Button,
  Card,
  Modal,
  Radio,
  Divider,
  Checkbox,
  Select,
  Typography,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { EditableRow, EditableCell } from "../EditableTable";
import { round } from "../../utils/tools";

import FormNuiteNew from "../FormNuiteNew";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // for selectable

import { toast } from "react-toastify";

import frLocale from "@fullcalendar/core/locales/fr";
import dayjs from "dayjs";

const { Option, OptGroup } = Select;
const { Column } = Table;
const { Text } = Typography;

class FormTarifLot extends Component {
  state = {
    lot: null,
    typeTarif: 2,
    rubriques: [],
    selectedRows: [],
    mois: true,
    semaine: false,
    nuit: false,
    arrivee: false,
    sejourMin: false,
    modalPeriode: false,
    modalRubrique: false,
    modalNuiteVisible: false,
    modalListeNuitees: false,
    modalCopieNuitees: false,
    nuiteDebut: dayjs(),
    nuiteFin: dayjs(),
    nuiteMontant: 0,
    fraisAgence: 0,
    idLotToPaste: null,
  };

  formRefNuite = React.createRef();

  componentDidMount() {
    this.init(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.init(props);
  }

  init = (props) => {
    this.getRubriquesAvailable(false);

    this.setState({
      lot: props.lot,
    });
  };

  getRubriques = (value) => {
    this.getRubriquesAvailable(value);
  };

  getRubriquesAvailable = (value) => {
    let rubriques = this.props.rubriques;

    if (!value || (value && value.target.checked === false)) {
      rubriques = rubriques.filter((i) => i.rubrique_contrat === true);
    }

    if (this.state.typeTarif === 1) {
      this.props.lot.lot_matrice.matrice_ligne.forEach((ligne) => {
        rubriques = rubriques.filter(
          (i) => i.rubrique_id !== ligne.matrice_ligne_rubrique.rubrique_id
        );
      });
    }
    if (this.state.typeTarif === 2 || this.state.typeTarif === 4) {
      this.props.lot.lot_prix_base.forEach((ligne) => {
        rubriques = rubriques.filter((i) => i.rubrique_id !== ligne.prix_base_rubrique.rubrique_id);
      });
    }

    this.setState({ rubriques });
  };

  openModalRubrique = () => {
    this.setState({ selectedRows: [], modalRubrique: true });
  };

  closeModalRubrique = () => {
    this.setState({ selectedRows: [], modalRubrique: false });
  };

  openModalListeNuitees = () => {
    this.setState({ modalListeNuitees: true });
  };

  closeModalListeNuitees = () => {
    this.setState({ modalListeNuitees: false });
  };

  openModalCopieNuitees = () => {
    this.setState({ modalCopieNuitees: true });
  };

  closeModalCopieNuitees = () => {
    this.setState({ modalCopieNuitees: false });
  };

  onClickAddPeriode = () => {
    this.openModalPeriode();
  };

  onSelectPeriode = (id) => {
    console.log(id);
  };

  handleSubmitAddRubriques = () => {
    const lot = this.state.lot;

    if (this.state.typeTarif === 1) {
      this.state.selectedRows.forEach((rubrique) => {
        const ligne = {
          matrice_ligne_matrice_id: lot.lot_matrice.matrice_id,
          matrice_ligne_rubrique_id: rubrique.rubrique_id,
          matrice_ligne_montant_nuite: "0",
          matrice_ligne_montant_semaine: "0",
          matrice_ligne_montant_quinzaine: "0",
          matrice_ligne_montant_mois: rubrique.rubrique_montant_defaut,
          matrice_ligne_rubrique: rubrique,
        };
        this.props.saveLigneMatrice(ligne, lot);
      });
    }

    if (this.state.typeTarif === 2 || this.state.typeTarif === 4) {
      this.state.selectedRows.forEach((rubrique) => {
        let montant = 0;

        if (rubrique.rubrique_pourcentage !== null || rubrique.rubrique_pourcentage !== 0) {
          rubrique.rubrique_liees.forEach((item) => {
            let prix = lot.lot_prix_base.find(
              (p) => p.prix_base_rubrique.rubrique_id === item.rubrique_id
            );
            if (prix) {
              montant += (parseFloat(prix.prix_base_montant) * rubrique.rubrique_pourcentage) / 100;
            }
          });
        }
        if (rubrique.rubrique_montant_fixe === true) {
          montant = rubrique.rubrique_montant_defaut;
        }

        const ligne = {
          prix_base_rubrique_id: rubrique.rubrique_id,
          prix_base_montant: montant,
          prix_base_rubrique: rubrique,
          prix_base_proprietaire: this.state.typeTarif === 4,
          prix_base_lot_id: lot.lot_id,
        };
        this.props.savePrixBase(ligne, lot);
      });
    }

    this.closeModalRubrique();
  };

  handleSave = (row) => {
    const lot = this.state.lot;
    if (this.state.typeTarif === 1) {
      this.props.updateLigneMatrice(
        {
          matrice_ligne_matrice_id: lot.lot_matrice.matrice_id,
          matrice_ligne_rubrique_id: row.matrice_ligne_rubrique.rubrique_id,
          matrice_ligne_montant_mois: row.matrice_ligne_montant_mois,
        },
        lot.lot_matrice.matrice_id,
        row.matrice_ligne_rubrique.rubrique_id,
        lot
      );
    }
    if (this.state.typeTarif === 2 || this.state.typeTarif === 4) {
      this.props.updatePrixBase(
        {
          prix_base_rubrique_id: row.prix_base_rubrique.rubrique_id,
          prix_base_montant: row.prix_base_montant,
          prix_base_proprietaire: this.state.typeTarif === 4,
        },
        row.prix_base_id,
        lot
      );

      lot.lot_prix_base.forEach((item) => {
        if (item.prix_base_rubrique.rubrique_liees.length > 0) {
          let montant = 0;
          item.prix_base_rubrique.rubrique_liees.forEach((ligne) => {
            if (ligne.rubrique_id === row.prix_base_rubrique.rubrique_id) {
              montant +=
                (parseFloat(row.prix_base_montant) * item.prix_base_rubrique.rubrique_pourcentage) /
                100;
            }
          });

          this.props.updatePrixBase(
            {
              prix_base_rubrique_id: item.prix_base_rubrique.rubrique_id,
              prix_base_montant: montant,
              prix_base_proprietaire: this.state.typeTarif === 4,
            },
            item.prix_base_id,
            lot
          );
        }
      });
    }
  };

  deleteLigneMatrice = (id) => {
    const lot = this.state.lot;
    this.props.deleteLigneMatrice(lot.lot_matrice.matrice_id, id, lot);
  };

  deletePrixBase = (id) => {
    const lot = this.state.lot;
    let prixBase = 0;
    lot.lot_prix_base.forEach((item) => {
      if (item.prix_base_rubrique.rubrique_id === id) {
        prixBase = item.prix_base_id;
      }
    });
    this.props.deletePrixBase(prixBase, lot);
  };

  onChangeRadio = (e) => {
    this.setState({ typeTarif: e.target.value }, () => {
      this.init(this.props);
    });
  };

  onChangeNuit = (e) => {
    this.setState({ nuit: !this.state.nuit });
  };

  onChangeSemaine = (e) => {
    this.setState({ semaine: !this.state.semaine });
  };

  onChangeMois = (e) => {
    this.setState({ mois: !this.state.mois });
  };

  onChangeSejourMin = (e) => {
    this.setState({ sejourMin: !this.state.sejourMin });
  };

  onChangeArrivee = (e) => {
    this.setState({ arrivee: !this.state.arrivee });
  };

  onChangeDateDebut = (e) => {
    this.setState({ nuiteDebut: dayjs(e, "DD/MM/YYYY").add(1, "minutes").format("YYYY-MM-DD") });
  };

  onChangeDateFin = (e) => {
    this.setState({ nuiteFin: dayjs(e, "DD/MM/YYYY").add(1, "minutes").format("YYYY-MM-DD") });
  };

  handleDateSelect = (selectInfo) => {
    // ajouter 1min à la date de début et enlever 1min à la date de fin
    // pour obtenir les jours sélectionnés et non un jour supplémentaire
    this.setState({
      modalListeNuitees: false,
      modalNuiteVisible: true,
      nuiteDebut: dayjs(selectInfo.startStr).add(1, "minutes").format("YYYY-MM-DD"),
      nuiteFin: dayjs(selectInfo.endStr).subtract(1, "minutes").format("YYYY-MM-DD"),
    });
  };

  handleCancelNuite = () => {
    this.setState({ modalNuiteVisible: false });
  };

  getNuitees = (lot_nuites) => {
    const nuites = [];

    const flatArr = lot_nuites.map((nuites) => {
      return {
        date: `${nuites.nuite_date_debut}|${nuites.nuite_date_fin}`,
        montant: nuites.nuite_montant,
      };
    });

    const groupedByDate = flatArr.reduce(
      (acc, { date, montant }) => ({
        ...acc,
        [date]: (acc[date] ?? 0) + parseFloat(montant),
      }),
      {}
    );

    for (const property in groupedByDate) {
      nuites.push({
        nuite_id: property,
        nuite_date_debut: property.split("|")[0],
        nuite_date_fin: property.split("|")[1],
        total: groupedByDate[property],
      });
    }

    return nuites.sort((a, b) =>
      dayjs(a.nuite_date_debut).isAfter(dayjs(b.nuite_date_debut)) ? 1 : -1
    );
  };

  onSelectLot = (idLotToPaste) => {
    this.setState({ idLotToPaste });
  };

  onPaste = (nuitees) => {
    this.props.updateLotNuitees({ lot_nuites: nuitees }, this.state.idLotToPaste).then(() => {
      toast.success("Calendrier copié !", { containerId: "A" });
      this.closeModalCopieNuitees();
    });
  };

  render() {
    const {
      lot,
      typeTarif,
      nuit,
      semaine,
      mois,
      rubriques,
      nuiteDebut,
      nuiteFin,
      // modalPeriode,
      modalRubrique,
      modalNuiteVisible,
      modalListeNuitees,
      modalCopieNuitees,
    } = this.state;

    const { home, lots, isArchive } = this.props;

    const lignesMatrice = lot && lot.lot_matrice.matrice_ligne;

    const lignesPrixBase =
      lot &&
      lot.lot_prix_base
        .filter(
          (p) => p.prix_base_rubrique.rubrique_montant_fixe === false && !p.prix_base_proprietaire
        )
        .sort((a, b) => a.prix_base_rubrique.rubrique_code - b.prix_base_rubrique.rubrique_code);
    const lignesPrixBaseFixe =
      lot &&
      lot.lot_prix_base
        .filter(
          (p) => p.prix_base_rubrique.rubrique_montant_fixe === true && !p.prix_base_proprietaire
        )
        .sort((a, b) => a.prix_base_rubrique.rubrique_code - b.prix_base_rubrique.rubrique_code);
    const lignesDepensesProprietaire =
      lot &&
      lot.lot_prix_base
        .filter(
          (p) => p.prix_base_rubrique.rubrique_montant_fixe === true && p.prix_base_proprietaire
        )
        .sort((a, b) => a.prix_base_rubrique.rubrique_code - b.prix_base_rubrique.rubrique_code);

    let columnsA = [
      {
        title: "Code",
        dataIndex: "matrice_ligne_rubrique",
        key: "matrice_ligne_rubrique",
        width: "10%",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) =>
          a.matrice_ligne_rubrique.rubrique_code.localeCompare(
            b.matrice_ligne_rubrique.rubrique_code
          ),
        render: (rubrique) => rubrique.rubrique_code,
      },
      {
        title: "Libellé",
        dataIndex: "matrice_ligne_rubrique",
        key: "matrice_ligne_rubrique",
        width: "20%",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) =>
          a.matrice_ligne_rubrique.rubrique_libelle.localeCompare(
            b.matrice_ligne_rubrique.rubrique_libelle
          ),
        render: (rubrique) => rubrique.rubrique_libelle,
      },
      {
        title: "Montant Nuit",
        dataIndex: "rubrique_montant_nuit",
        key: "rubrique_montant_nuit",
        width: "20%",
        sortDirections: ["ascend", "descend"],
        editable: !isArchive,
      },
      {
        title: "Montant Semaine",
        dataIndex: "rubrique_montant_semaine",
        key: "rubrique_montant_semaine",
        width: "20%",
        sortDirections: ["ascend", "descend"],
        editable: !isArchive,
      },
      {
        title: "Montant Mois",
        dataIndex: "matrice_ligne_montant_mois",
        key: "matrice_ligne_montant_mois",
        width: "20%",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) => a.matrice_ligne_montant_mois - b.matrice_ligne_montant_mois,
        editable: !isArchive,
      },
      {
        title: "Supprimer",
        dataIndex: "matrice_ligne_rubrique",
        key: "matrice_ligne_rubrique",
        render: (rubrique) => (
          <Button
            disabled={isArchive}
            icon={<DeleteOutlined />}
            shape="circle"
            onClick={() => this.deleteLigneMatrice(rubrique.rubrique_id)}
          />
        ),
        width: "10%",
      },
    ];

    let columnsB = [
      {
        title: "Code",
        dataIndex: "prix_base_rubrique",
        key: "prix_base_rubrique",
        width: "10%",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) =>
          a.prix_base_rubrique.rubrique_code.localeCompare(b.prix_base_rubrique.rubrique_code),
        render: (rubrique) => rubrique.rubrique_code,
      },
      {
        title: "Libellé",
        dataIndex: "prix_base_rubrique",
        key: "prix_base_rubrique",
        width: "20%",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) =>
          a.prix_base_rubrique.rubrique_libelle.localeCompare(
            b.prix_base_rubrique.rubrique_libelle
          ),
        render: (rubrique) => rubrique.rubrique_libelle,
      },
      {
        title: "Montant",
        dataIndex: "prix_base_montant",
        key: "prix_base_montant",
        render: (montant) =>
          parseInt(montant) !== 0 ? (
            parseFloat(montant).toLocaleString("fr-FR", {
              style: "currency",
              currency: "EUR",
            })
          ) : (
            <></>
          ),
        className: "column-money",
        width: "20%",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) => a.prix_base_montant - b.prix_base_montant,
        editable: !isArchive,
      },
      {
        title: "Supprimer",
        dataIndex: "prix_base_rubrique",
        key: "prix_base_rubrique",
        render: (rubrique) => (
          <Button
            disabled={isArchive}
            icon={<DeleteOutlined />}
            shape="circle"
            onClick={() => this.deletePrixBase(rubrique.rubrique_id)}
          />
        ),
        width: "10%",
      },
    ];

    if (!nuit) columnsA = columnsA.filter((col) => col.dataIndex !== "rubrique_montant_nuit");
    if (!semaine) columnsA = columnsA.filter((col) => col.dataIndex !== "rubrique_montant_semaine");
    if (!mois) columnsA = columnsA.filter((col) => col.dataIndex !== "matrice_ligne_montant_mois");

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRows });
      },
    };

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };

    const columns = columnsA.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    const columnsNew = columnsB.map((col) => {
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    const injectCellContent = (args) => {
      // nuités du lot -- Plus récentes aux plus anciennes
      const nuites = lot.lot_nuites
        .filter((n) => n.nuite_rubrique.rubrique_montant_fixe === false)
        .sort((a, b) => b.nuite_id - a.nuite_id);
      const prixBase = lot.lot_prix_base.filter(
        (p) => p.prix_base_rubrique.rubrique_montant_fixe === false
      );

      if (
        nuites.filter((e) =>
          dayjs(args.date).isBetween(e.nuite_date_debut, e.nuite_date_fin, "days", "[]")
        ).length > 0
      ) {
        let totNuites = nuites.filter((e) =>
          dayjs(args.date).isBetween(e.nuite_date_debut, e.nuite_date_fin, "days", "[]")
        );
        let total = 0;
        totNuites.forEach((ligne) => {
          total += parseFloat(ligne.nuite_montant);
        });
        return (
          <div>
            <div className={"day-number"}>{args.dayNumberText}</div>
            <div className={"lot-nuite"}>
              <b>{parseFloat(total).toFixed(2)}€ </b>
              <span className={"circle"}></span>
            </div>
          </div>
        );
      } else {
        let totBase = 0;
        prixBase.forEach((ligne) => {
          totBase += parseFloat(ligne.prix_base_montant);
        });
        return (
          <div className={"calendar-cell"}>
            <div className={"day-number"}>{args.dayNumberText}</div>
            <div className={"lot-prix-base"}>
              {parseFloat(totBase).toFixed(2)}€{/* <span className={"circle"}></span> */}
            </div>
          </div>
        );
      }
    };

    return (
      <>
        {/* MODAL RUBRIQUES */}
        <Modal
          footer={null}
          width="600px"
          title="Rubriques"
          open={modalRubrique}
          onCancel={this.closeModalRubrique}
        >
          <div>
            <Row>
              <Col span={18}></Col>
              <Col span={6}>
                <Checkbox onChange={(value) => this.getRubriques(value)}>Voir toutes</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Table
                  rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                  }}
                  rowKey="rubrique_id"
                  dataSource={rubriques}
                >
                  <Column title="Code" dataIndex="rubrique_code" key="code" />
                  <Column title="Libelle" dataIndex="rubrique_libelle" key="libelle" />
                </Table>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Button
                type="primary"
                onClick={this.handleSubmitAddRubriques}
                style={{ float: "right" }}
              >
                Valider
              </Button>
            </Row>
          </div>
        </Modal>
        {/* MODAL NUITÉ */}
        <Modal
          title="Nuité"
          width={800}
          open={modalNuiteVisible}
          onCancel={this.handleCancelNuite}
          footer={null}
        >
          <FormNuiteNew
            formRefNuite={this.formRefNuite}
            lot={lot}
            rubriques={this.props.rubriques}
            nuiteDebut={nuiteDebut}
            nuiteFin={nuiteFin}
            onChangeDateDebut={this.onChangeDateDebut}
            onChangeDateFin={this.onChangeDateFin}
            handleCancelNuite={this.handleCancelNuite}
            onFinishFormNuite={this.onFinishFormNuite}
            saveNuite={this.props.saveNuite}
            updateNuite={this.props.updateNuite}
            deleteNuite={this.props.deleteNuite}
          />
        </Modal>
        {/* MODAL LISTE NUITÉES */}
        <Modal
          title="Liste des nuitées"
          open={modalListeNuitees}
          onCancel={this.closeModalListeNuitees}
          footer={null}
        >
          <Table
            dataSource={lot ? this.getNuitees(lot.lot_nuites) : null}
            rowKey={(r) => r.nuite_id}
          >
            <Column
              title="Date début"
              dataIndex="nuite_date_debut"
              key="nuite_date_debut"
              render={(dd) => dayjs(dd).format("DD/MM/YYYY")}
            />
            <Column
              title="Date fin"
              dataIndex="nuite_date_fin"
              key="nuite_date_fin"
              render={(df) => dayjs(df).format("DD/MM/YYYY")}
            />
            <Column
              title="Montant"
              dataIndex="total"
              key="total"
              render={(total) => (
                <>
                  {parseFloat(total).toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </>
              )}
            />
            <Column
              title="Modifier"
              dataIndex="nuite_id"
              key="nuite_id"
              render={(nuite_id, nuite) => (
                <Button
                  icon={<EditOutlined />}
                  shape="circle"
                  onClick={() =>
                    this.handleDateSelect({
                      startStr: nuite.nuite_date_debut,
                      endStr: dayjs(nuite.nuite_date_fin).add(1, "day").format("YYYY-MM-DD"),
                    })
                  }
                />
              )}
            />
          </Table>
        </Modal>
        {/* MODAL COPIE CALENDRIER */}
        <Modal
          title="Copier le calendrier sur"
          open={modalCopieNuitees}
          onCancel={this.closeModalCopieNuitees}
          footer={null}
        >
          <Row>
            <Col span={24}>
              <Select
                showSearch
                optionLabelProp="label"
                placeholder="Lots"
                style={{ width: "100%" }}
                onSelect={(id) => this.onSelectLot(id)}
                dropdownRender={(menu) => <div>{menu}</div>}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <OptGroup label="Lots">
                  {lots &&
                    lots.map((item, i) => {
                      return (
                        <Option key={i} value={item.lot_id} label={`${item.lot_reference}`}>
                          <Row>
                            <Col sm={8}>{`${item.lot_reference}`}</Col>
                          </Row>
                        </Option>
                      );
                    })}
                </OptGroup>
              </Select>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={24}>
              <Button
                type="primary"
                onClick={() => this.onPaste(lot.lot_nuites)}
                style={{ float: "right" }}
              >
                Valider
              </Button>
            </Col>
          </Row>
        </Modal>

        <Card className="appt-card" bordered={false}>
          <Row style={{ marginBottom: "10px" }}>
            <Col span={12}>
              <Radio.Group
                size={home ? "small" : "large"}
                onChange={this.onChangeRadio}
                defaultValue={2}
              >
                <Radio.Button key={2} value={2}>
                  Tarif de base
                </Radio.Button>
                {!this.props.inModal && (
                  <>
                    <Radio.Button key={4} value={4}>
                      Dépenses propriétaire
                    </Radio.Button>
                    <Radio.Button key={3} value={3}>
                      Nuité
                    </Radio.Button>
                    <Radio.Button key={1} value={1}>
                      Base Mensuelle
                    </Radio.Button>
                  </>
                )}
              </Radio.Group>
            </Col>
          </Row>
          {typeTarif === 1 && (
            <>
              <Row style={{ marginBottom: 5 }}>
                <Col span={2} offset={2}>
                  <Button type="primary" disabled={isArchive} onClick={this.openModalRubrique}>
                    Ajout Rubrique
                  </Button>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={14} offset={2}>
                  <Table
                    columns={columns}
                    components={components}
                    dataSource={lignesMatrice}
                    rowClassName={() => "editable-row"}
                    rowKey={(r) => r.matrice_ligne_rubrique.rubrique_id}
                  />
                </Col>
              </Row>
            </>
          )}
          {typeTarif === 2 && (
            <>
              <Row style={{ marginBottom: 10 }}>
                <Col span={2}>
                  <Button type="primary" disabled={isArchive} onClick={this.openModalRubrique}>
                    Ajout Rubrique
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Divider orientation="left">Montants applicables par nuit</Divider>
                </Col>
                <Col span={12}>
                  <Divider orientation="left">Montants fixes</Divider>
                </Col>
              </Row>
              <Row></Row>
              <Row>
                <Col span={10}>
                  <Table
                    columns={columnsNew}
                    components={components}
                    dataSource={lignesPrixBase}
                    rowClassName={() => "editable-row"}
                    rowKey={(r) => r.prix_base_rubrique.rubrique_id}
                    summary={(pageData) => {
                      let total = 0;
                      pageData.forEach(({ prix_base_montant }) => {
                        total += parseFloat(prix_base_montant);
                      });

                      return (
                        <Table.Summary.Row>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <b>TOTAL</b>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text>
                              <b>{round(total, 2)} €</b>
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                        </Table.Summary.Row>
                      );
                    }}
                  />
                </Col>
                <Col span={2}></Col>
                <Col span={10}>
                  <Table
                    columns={columnsNew}
                    components={components}
                    dataSource={lignesPrixBaseFixe}
                    rowClassName={() => "editable-row"}
                    rowKey={(r) => r.prix_base_rubrique.rubrique_id}
                    summary={(pageData) => {
                      let total = 0;
                      pageData.forEach(({ prix_base_montant }) => {
                        total += parseFloat(prix_base_montant);
                      });

                      return (
                        <Table.Summary.Row>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <b>TOTAL</b>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text>
                              <b>{round(total, 2)} €</b>
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                        </Table.Summary.Row>
                      );
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
          {typeTarif === 3 && (
            <>
              <Row>
                <Col span={24}>
                  <FullCalendar
                    locale="fr"
                    editable={true}
                    weekends={true}
                    height={"500px"}
                    selectable={true}
                    dayMaxEvents={true}
                    selectMirror={true}
                    locales={[frLocale]}
                    initialView="dayGridMonth"
                    select={this.handleDateSelect}
                    dayCellContent={injectCellContent}
                    plugins={[dayGridPlugin, interactionPlugin]}
                    headerToolbar={{
                      start: "title",
                      center: "",
                      end: "prev,next today",
                    }}
                  />
                </Col>
              </Row>
              <Row gutter={12} style={{ marginTop: 5, marginBottom: 5 }}>
                <Col span={3}>
                  <Button style={{ width: "100%" }} type="primary" onClick={this.handleDateSelect}>
                    Nouvelle nuitée
                  </Button>
                </Col>
                <Col offset={15} span={3}>
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={this.openModalListeNuitees}
                  >
                    Liste des nuitées
                  </Button>
                </Col>
                <Col span={3}>
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={this.openModalCopieNuitees}
                  >
                    Copier le calendrier
                  </Button>
                </Col>
              </Row>
            </>
          )}
          {typeTarif === 4 && (
            <>
              <Row style={{ marginBottom: 10 }}>
                <Col span={2}>
                  <Button type="primary" disabled={isArchive} onClick={this.openModalRubrique}>
                    Ajout Rubrique
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Divider orientation="left">Montants fixes</Divider>
                </Col>
              </Row>
              <Row></Row>
              <Row>
                <Col span={2}></Col>
                <Col span={10}>
                  <Table
                    columns={columnsNew}
                    components={components}
                    dataSource={lignesDepensesProprietaire}
                    rowClassName={() => "editable-row"}
                    rowKey={(r) => r.prix_base_rubrique.rubrique_id}
                    summary={(pageData) => {
                      let total = 0;
                      pageData.forEach(({ prix_base_montant }) => {
                        total += parseFloat(prix_base_montant);
                      });

                      return (
                        <Table.Summary.Row>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <b>TOTAL</b>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text>
                              <b>{round(total, 2)} €</b>
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                        </Table.Summary.Row>
                      );
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
        </Card>
      </>
    );
  }
}

export default FormTarifLot;

// DEPENDENCY
import React from "react";
import { Table } from "antd";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import arrayMove from "array-move";

const DragHandle = sortableHandle(() => (
  <MenuOutlined className="grab" style={{ color: "#999" }} />
));

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

export default class CustomTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 20,
    };
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { dataSource } = this.props;
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      this.setState({ dataSource: newData });
      this.props.sortChange(newData);
    }
  };

  DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { dataSource } = this.props;
    // function findIndex base on Table rowKey props and should always be a right array index
    const index =
      dataSource &&
      dataSource.findIndex((x) => x.index === restProps["data-row-key"]);
    return (
      dataSource && (
        <SortableItem className={className} index={index} {...restProps} />
      )
    );
  };

  setRowClassName = (record) => {
    const selectedRow = this.props.selectedRow;
    const rowPointer = this.props.rowPointer;
    let className = "";
    if (selectedRow) {
      className +=
        record.index === selectedRow.index ? "ant-table-row-selected " : "";
    }
    if (rowPointer) {
      className += "pointer ";
    }
    return className;
  };

  // HANDLER
  handleClickRow = (record) => {
    return {
      onClick: (event) => {
        if (this.props.onClickRow) {
          this.props.onClickRow(record);
        }
      },
    };
  };
  handlePaginationChange = (e) => {
    this.setState({ pagesize: e });
  };

  render() {
    const { pageSize } = this.state;
    const { columns, draggable } = this.props;
    const defaultScroll = {
      x: "900px",
      y: "75vh",
    };

    const draggableColumns = [
      {
        className: "drag-visible",
        dataIndex: "sort",
        width: 32,
        render: () => <DragHandle />,
      },
    ];
    this.props.columns && draggableColumns.push(...this.props.columns);

    const DraggableContainer = (props) => (
      <SortableContainer
        useDragHandle
        helperClass="row-dragging"
        onSortEnd={(e) => this.onSortEnd(e)}
        {...props}
      />
    );
    return (
      <Table
        components={
          draggable && {
            body: {
              wrapper: DraggableContainer,
              row: this.DraggableBodyRow,
            },
          }
        }
        onChange={this.handlePaginationChange}
        onRow={this.handleClickRow}
        pagination={{ defaultPageSize: pageSize, position: ["bottomCenter"] }}
        rowClassName={(record) => this.setRowClassName(record)}
        rowKey="index"
        scroll={defaultScroll}
        showSorterTooltip={false}
        size="middle"
        {...this.props}
        columns={draggable ? draggableColumns : columns}
      />
    );
  }
}

import React, { Component } from "react";

export default class ComptePDF extends Component {
  render() {
    const { lignesEcritures, dateDu, dateAu, compteDu, compteAu, comptes, soldeEvolutif } =
      this.props;

    // let totalDebit = 0;
    // let totalCredit = 0;
    // let totalSolde = 0;
    // let totalSoldeDebit = 0;
    // let totalSoldeCredit = 0;
    // lignesEcritures.forEach((ligne, i) => {
    //   totalDebit += parseFloat(ligne.ecriture_ligne_montant_debit);
    //   totalCredit += parseFloat(ligne.ecriture_ligne_montant_credit);
    // });

    // totalSolde = totalCredit - totalDebit;

    // if (totalSolde < 0) {
    //   totalSoldeDebit = totalSolde;
    // } else if (totalSolde > 0) {
    //   totalSoldeCredit = totalSolde;
    // }

    let totalDebitF = 0;
    let totalCreditF = 0;
    let totalSoldeF = 0;
    let totalSoldeDebitF = 0;
    let totalSoldeCreditF = 0;

    let soldeEv = 0;

    return (
      <div>
        {/* Titre */}
        <div className="entitre row">
          <h1>Interrogation de compte</h1>
          <p className="center">
            <span>
              <strong>
                {`Situation ${
                  dateDu ? "du " + dateDu.format("DD/MM/YYYY") + " " : ""
                }au ${dateAu.format("DD/MM/YYYY")}`}
              </strong>
            </span>
          </p>
          <p>
            <strong>
              Compte du{" "}
              {
                comptes.find((compte) => compte.compte_compte_general === compteDu)
                  .compte_compte_general
              }
              {" -  "}
              {comptes.find((compte) => compte.compte_compte_general === compteDu).compte_libelle}
            </strong>
          </p>
        </div>

        {/* boucle comptes + sort
        condition compteDu compteAu
        lignesEcrituresFiltered = filtre ligneEcriture (compte = compte) */}

        {comptes
          .filter((compte) => compte.compte_type_compte !== "A")
          .sort((a, b) => a.compte_compte_general - b.compte_compte_general)
          .map((compte, j) => {
            if (
              compte.compte_compte_general >= compteDu &&
              compte.compte_compte_general <= compteAu
            ) {
              const lignesEcrituresFiltered = lignesEcritures.filter(
                (e) => e.ecriture_ligne_compte_id === compte.compte_id
              );
              if (lignesEcrituresFiltered.length !== 0) {
                return (
                  <div>
                    <table>
                      <thead>
                        <tr>
                          <th width="100%">
                            <strong>
                              {" "}
                              {compte.compte_compte_general}
                              {" - "}
                              {compte.compte_libelle}
                            </strong>
                          </th>
                        </tr>
                        <tr>
                          <th width="5%" scope="col" align="center">
                            <strong>JRN</strong>
                          </th>
                          <th width="10%" scope="col" align="center">
                            <strong>DATE</strong>
                          </th>

                          <th width="43%" scope="col" align="center">
                            <strong> LIBELLE</strong>
                          </th>
                          <th width="12%" scope="col" align="center">
                            <strong>DEBIT </strong>
                          </th>
                          <th width="12%" scope="col" align="center">
                            <strong>CREDIT </strong>
                          </th>
                          <th width="12%" scope="col" align="center">
                            <strong>SOLDE </strong>
                          </th>
                          <th width="6%" scope="col" align="center">
                            <strong>LET </strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {lignesEcrituresFiltered.map((ligneF, i) => {
                          if (i === 0) {
                            totalDebitF = 0;
                            totalCreditF = 0;
                            totalDebitF += parseFloat(ligneF.ecriture_ligne_montant_debit);
                            totalCreditF += parseFloat(ligneF.ecriture_ligne_montant_credit);
                          } else {
                            totalDebitF += parseFloat(ligneF.ecriture_ligne_montant_debit);
                            totalCreditF += parseFloat(ligneF.ecriture_ligne_montant_credit);
                          }
                          if (i === lignesEcrituresFiltered.length - 1) {
                            totalSoldeDebitF = 0;
                            totalSoldeCreditF = 0;
                            totalSoldeF = totalCreditF - totalDebitF;
                            if (totalSoldeF < 0) {
                              totalSoldeDebitF = Math.abs(totalSoldeF);
                            } else if (totalSoldeF > 0) {
                              totalSoldeCreditF = totalSoldeF;
                            }
                          }
                          if (!soldeEvolutif) {
                            soldeEv = 0;
                          } else {
                            soldeEv = ligneF.solde;
                          }
                          if (i % 2) {
                            return (
                              <tr className="odd">
                                <td width="5%" align="center">
                                  {ligneF.ecriture_journal_code}
                                </td>
                                <td width="10%" align="center">
                                  {ligneF.ecriture_date_ecriture}
                                </td>

                                <td width="43%"> {ligneF.ecriture_ligne_libelle}</td>
                                <td width="12%" style={{ textAlign: "right" }}>
                                  {parseInt(ligneF.ecriture_ligne_montant_debit) !== 0 ? (
                                    parseFloat(ligneF.ecriture_ligne_montant_debit).toLocaleString(
                                      "fr-FR",
                                      {
                                        style: "currency",
                                        currency: "EUR",
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </td>
                                <td width="12%" style={{ textAlign: "right" }}>
                                  {parseInt(ligneF.ecriture_ligne_montant_credit) !== 0 ? (
                                    parseFloat(ligneF.ecriture_ligne_montant_credit).toLocaleString(
                                      "fr-FR",
                                      {
                                        style: "currency",
                                        currency: "EUR",
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </td>
                                <td width="12%" style={{ textAlign: "right" }}>
                                  {parseInt(soldeEv) !== 0 ? (
                                    parseFloat(soldeEv).toLocaleString("fr-FR", {
                                      style: "currency",
                                      currency: "EUR",
                                    })
                                  ) : (
                                    <></>
                                  )}
                                </td>
                                <td width="6%" align="center">
                                  {ligneF.ecriture_ligne_lettrage}
                                </td>
                              </tr>
                            );
                          } else {
                            return (
                              <tr>
                                <td width="5%" align="center">
                                  {ligneF.ecriture_journal_code}
                                </td>
                                <td width="10%" align="center">
                                  {ligneF.ecriture_date_ecriture}
                                </td>

                                <td width="43%"> {ligneF.ecriture_ligne_libelle}</td>
                                <td width="12%" style={{ textAlign: "right" }}>
                                  {parseInt(ligneF.ecriture_ligne_montant_debit) !== 0 ? (
                                    parseFloat(ligneF.ecriture_ligne_montant_debit).toLocaleString(
                                      "fr-FR",
                                      {
                                        style: "currency",
                                        currency: "EUR",
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </td>
                                <td width="12%" style={{ textAlign: "right" }}>
                                  {parseInt(ligneF.ecriture_ligne_montant_credit) !== 0 ? (
                                    parseFloat(ligneF.ecriture_ligne_montant_credit).toLocaleString(
                                      "fr-FR",
                                      {
                                        style: "currency",
                                        currency: "EUR",
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </td>
                                <td width="12%" style={{ textAlign: "right" }}>
                                  {parseInt(soldeEv) !== 0 ? (
                                    parseFloat(soldeEv).toLocaleString("fr-FR", {
                                      style: "currency",
                                      currency: "EUR",
                                    })
                                  ) : (
                                    <></>
                                  )}
                                </td>
                                <td width="6%" align="center">
                                  {ligneF.ecriture_ligne_lettrage}
                                </td>
                              </tr>
                            );
                          }
                        })}
                        <tr>
                          <td width="58%" align="right">
                            <strong>TOTAL </strong>
                          </td>
                          <td width="12%" align="right">
                            {parseFloat(totalDebitF).toLocaleString("fr-FR", {
                              style: "currency",
                              currency: "EUR",
                            })}
                          </td>
                          <td width="12%" align="right">
                            {parseFloat(totalCreditF).toLocaleString("fr-FR", {
                              style: "currency",
                              currency: "EUR",
                            })}
                          </td>
                          <td width="12%" align="right"></td>
                          <td width="6%" align="center"></td>
                        </tr>
                        <tr>
                          <td width="58%" align="right">
                            <strong>
                              SOLDE DU COMPTE {compte.compte_compte_general}
                              {" - "}
                              {compte.compte_libelle}{" "}
                            </strong>
                          </td>
                          <td width="12%" align="right">
                            {parseFloat(totalSoldeDebitF).toLocaleString("fr-FR", {
                              style: "currency",
                              currency: "EUR",
                            })}
                          </td>
                          <td width="12%" align="right">
                            {parseFloat(totalSoldeCreditF).toLocaleString("fr-FR", {
                              style: "currency",
                              currency: "EUR",
                            })}
                          </td>
                          <td width="12%" align="right"></td>
                          <td width="6%" align="center"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                );
              }
            }
            return null;
          })}
      </div>
    );
  }
}

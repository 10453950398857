import React, { Component } from "react";
import { Row, Col, Form, Input, Switch, Button } from "antd";

class MyFormGroupe extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  formRef = React.createRef();

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.groupeSelected) {
      this.formRef.current.setFieldsValue({
        libelleInput: newProps.groupeSelected.groupe_libelle,
        groupe_alur: newProps.groupeSelected.groupe_alur,
        groupe_long_terme: newProps.groupeSelected.groupe_long_terme,
        groupe_court_terme: newProps.groupeSelected.groupe_court_terme,
        groupe_visible: newProps.groupeSelected.groupe_visible,
      });
    }
  }

  handleCreateGroupe = (values) => {
    this.props.handleCreateGroupe(values);
  };

  handleEditGroupe = (values) => {
    this.props.handleEditGroupe(values);
  };

  render() {
    const groupe = this.props.groupeSelected;

    const getGroupeToEditLibelle = (groupe) => {
      if (groupe !== null) return groupe.groupe_libelle;
    };

    const initialValues = {
      libelleInput: getGroupeToEditLibelle(groupe),
      groupe_alur: groupe ? groupe.groupe_alur : false,
      groupe_long_terme: groupe ? groupe.groupe_long_terme : false,
      groupe_court_terme: groupe ? groupe.groupe_court_terme : false,
      groupe_visible: groupe ? groupe.groupe_visible : true,
    };

    return (
      <Form
        ref={this.formRef}
        initialValues={initialValues}
        className="ant-advanced-search-form"
        onFinish={groupe ? this.handleEditGroupe : this.handleCreateGroupe}
      >
        <Form.Item
          name="libelleInput"
          label="Libellé Groupe"
          rules={[{ required: true, message: "Libelle du groupe obligatoire" }]}
        >
          <Input />
        </Form.Item>
        <Row>
          <Col span={8}>
            <Form.Item name="groupe_alur" valuePropName="checked">
              <Switch
                className="switch-contrat"
                // onChange={this.onChangeAlur}
                style={{ height: "50px" }}
                checkedChildren="Alur"
                unCheckedChildren="Alur"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="groupe_long_terme" valuePropName="checked">
              <Switch
                className="switch-contrat"
                // onChange={this.onChangeLongTerme}
                style={{ height: "50px" }}
                checkedChildren="Long terme"
                unCheckedChildren="Long terme"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="groupe_court_terme" valuePropName="checked">
              <Switch
                className="switch-contrat"
                // onChange={this.onChangeCourtTerme}
                style={{ height: "50px" }}
                checkedChildren="Court terme"
                unCheckedChildren="Court terme"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item name="groupe_visible" valuePropName="checked">
              <Switch
                className="switch-contrat"
                // onChange={this.onChangeCourtTerme}
                style={{ height: "50px" }}
                checkedChildren="Visible"
                unCheckedChildren="Visible"
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {groupe !== null ? "Editer" : "Ajouter"}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default MyFormGroupe;

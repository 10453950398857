import React, { Component } from "react";
import { Button, Col, Form, Row, Select } from "antd";
import { EyeOutlined } from "@ant-design/icons";

import CustomSelect from "../CustomFormItem/Select";
import CustomDatePicker from "../CustomFormItem/DatePicker";
import CustomTimePicker from "../CustomFormItem/TimePicker";

import { getInfoAgent, getInfoLocation } from "../../utils/tools";

import dayjs from "dayjs";

const { Option, OptGroup } = Select;

class FormMenage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  // Référence du formulaire
  formRef = React.createRef();

  componentDidMount() {
    this.props.menage
      ? this.initMenage(this.props.menage)
      : this.resetMenage(this.props.daySelected);
  }

  UNSAFE_componentWillReceiveProps(props) {
    props.menage ? this.initMenage(props.menage) : this.resetMenage(props.daySelected);
  }

  initMenage = (data) => {
    this.resetMenage();

    this.formRef.current.setFieldsValue({
      menage_date: data.menage_date ? dayjs(data.menage_date) : null,
      menage_heure: data.menage_heure ? dayjs(data.menage_heure, "HH:mm:ss") : null,
      menage_etat: data.menage_etat,
      menage_type: data.menage_type,
      menage_location_id: data.menage_location_id,
      menage_agent_id: data.menage_agent ? data.menage_agent.agent_id : null,
    });
  };

  resetMenage = (date) => {
    this.formRef.current.resetFields([
      "menage_date",
      "menage_heure",
      "menage_etat",
      "menage_type",
      "menage_compte_id",
      "menage_location_id",
      "menage_agent_id",
    ]);
    date &&
      this.formRef.current.setFieldsValue({
        menage_date: dayjs(date),
      });
  };

  onFinish = (data) => {
    data.menage_date = dayjs(data.menage_date).format("YYYY-MM-DD");
    data.menage_heure = data.menage_heure ? dayjs(data.menage_heure).format("HH:mm:ss") : null;
    data.menage_lot_id = this.props.lot;
    this.props.menage
      ? this.props.handleEditMenage(data, this.props.menage.menage_id)
      : this.props.handleSubmitMenage(data);
  };

  onDeleteMenage = () => {
    this.props.handleDeleteMenage(this.props.menage.menage_id);
  };

  render() {
    const { daySelected, lot, menage, locations, agents } = this.props;

    const showRequeteButton = (
      <Button type="primary" onClick={() => this.props.showRequete(menage.menage_location_id)}>
        <EyeOutlined />
      </Button>
    );

    return (
      <div>
        <Form ref={this.formRef} onFinish={this.onFinish} size="large" className="form-requete">
          <Row gutter={12}>
            <Col span={8}>
              <CustomDatePicker
                label="Date"
                inputName="date"
                formItemName="menage_date"
                objectValue={daySelected || (menage && menage.menage_date)}
                rules={{ required: true, message: "Date obligatoire" }}
              />
            </Col>
            <Col span={8}>
              <CustomTimePicker
                label="Heure"
                inputName="heure"
                formItemName="menage_heure"
                objectValue={
                  menage && menage.menage_heure && dayjs(menage.menage_heure, "HH:mm:ss")
                }
              />
            </Col>
            <Col span={8}>
              <CustomSelect
                label="État"
                inputName="etat"
                formItemName="menage_etat"
                objectValue={menage && menage.menage_etat}
                rules={{ required: true, message: "État obligatoire" }}
              >
                <OptGroup label="État">
                  <Option key={1} value={"n"} label={"Non planifié"}>
                    Non planifié
                  </Option>
                  <Option key={2} value={"p"} label={"Planifié"}>
                    Planifié
                  </Option>
                  <Option key={3} value={"f"} label={"Fait"}>
                    Fait
                  </Option>
                </OptGroup>
              </CustomSelect>
            </Col>
            <Col span={8}>
              <CustomSelect
                label="Type"
                inputName="type"
                formItemName="menage_type"
                objectValue={menage && menage.menage_type}
                rules={{ required: true, message: "Type obligatoire" }}
              >
                <OptGroup label="État">
                  <Option key={1} value={"classique"} label={"Classique"}>
                    Classique
                  </Option>
                  <Option key={2} value={"intermediaire"} label={"Intermédiaire"}>
                    Intermédiaire
                  </Option>
                  <Option key={3} value={"proprietaire"} label={"Propriétaire"}>
                    Propriétaire
                  </Option>
                  <Option key={4} value={"finition"} label={"Finition"}>
                    Finition
                  </Option>
                </OptGroup>
              </CustomSelect>
            </Col>
            <Col span={menage && menage.menage_location_id ? 14 : 16}>
              <CustomSelect
                label="Location"
                inputName="location"
                formItemName="menage_location_id"
                objectValue={menage && menage.menage_location_id}
              >
                <OptGroup label="Location">
                  {lot &&
                    locations &&
                    locations
                      .filter(
                        (location) =>
                          location.location_lot.lot_id === lot &&
                          location.location_etat !== "a" &&
                          dayjs(location.location_date_fin).isAfter(dayjs(daySelected))
                      )
                      .sort((a, b) =>
                        dayjs(a.location_date_debut).isAfter(dayjs(b.location_date_debut)) ? 1 : -1
                      )
                      .map((item, i) => {
                        return (
                          <Option key={i} value={item.location_id} label={getInfoLocation(item)}>
                            <Row>
                              <Col sm={8}>{getInfoLocation(item)}</Col>
                            </Row>
                          </Option>
                        );
                      })}
                </OptGroup>
              </CustomSelect>
            </Col>
            {menage && menage.menage_location_id && <Col span={2}>{showRequeteButton}</Col>}
            <Col span={8}>
              <CustomSelect
                label="Agent"
                inputName="agent"
                formItemName="menage_agent_id"
                objectValue={menage && menage.menage_agent && menage.menage_agent.agent_id}
              >
                <OptGroup label="Agent">
                  {agents &&
                    agents.map((item, i) => {
                      return (
                        <Option key={i} value={item.agent_id} label={getInfoAgent(item)}>
                          <Row>
                            <Col sm={8}>{getInfoAgent(item)}</Col>
                          </Row>
                        </Option>
                      );
                    })}
                </OptGroup>
              </CustomSelect>
            </Col>
          </Row>
          <Row gutter={12} style={{ marginTop: 10, textAlign: "right" }}>
            {menage && (
              <Button type="primary" danger onClick={this.onDeleteMenage}>
                Supprimer
              </Button>
            )}
            <Button type="primary" htmlType="submit" style={{ marginLeft: 10 }}>
              Valider
            </Button>
          </Row>
        </Form>
      </div>
    );
  }
}

export default FormMenage;

import React, { Component } from "react";
import { Button, Col, Form, Input, Popconfirm, Popover, Row, Select } from "antd";
import {
  CheckSquareOutlined,
  CloseOutlined,
  CloseSquareOutlined,
  QuestionOutlined,
} from "@ant-design/icons";
import CustomTable from "../CustomTable/CustomTable";
import ColumnAction from "../CustomTable/ColumnAction";

import { toast } from "react-toastify";

import { CONTACT } from "../../utils/constants";

const { Option } = Select;

export default class FormChampPerso extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupSelected: undefined,
    };
  }

  groupForm = React.createRef();
  fieldForm = React.createRef();
  equipementForm = React.createRef();

  componentDidMount() {
    this.defaultGroupIdSelected(this.props.champsPersosGroupes);
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.champsPersosGroupes &&
      nextProps.champsPersosGroupes !== this.props.champsPersosGroupes
    ) {
      this.defaultGroupIdSelected(nextProps.champsPersosGroupes);
    }
  };

  defaultGroupIdSelected = (champsPersosGroupes) => {
    let group;
    if (champsPersosGroupes.length > 0) {
      let id;
      if (this.state.groupSelected) {
        id = this.state.groupSelected.champ_perso_groupe_id;
      } else {
        id = champsPersosGroupes[0].champ_perso_groupe_id;
      }
      group = this.getGroupSelected(champsPersosGroupes, id);
    } else {
      group = undefined;
    }

    this.setState({ groupSelected: group }, () => {
      if (this.props.type === 3) this.initEquipement(group);
    });
  };

  initEquipement = (group) => {
    this.equipementForm.current.setFieldsValue({
      champ_perso_groupe_equipement_id: group.champ_perso_groupe_equipement_id
        ? group.champ_perso_groupe_equipement_id.equipement_id
        : null,
    });
  };

  getGroupSelected = (champsPersosGroupes, id) => {
    const filtered = champsPersosGroupes.filter(
      (item) => item !== undefined && item.champ_perso_groupe_id === id
    );
    return champsPersosGroupes && filtered.length > 0 && filtered[0];
  };

  onFinishCustomGroup = (values) => {
    const data = {
      champ_perso_groupe_libelle: values.group,
      champ_perso_groupe_type: parseInt(this.props.typeGroupe),
      champ_perso_groupe_ordre: this.props.champsPersosGroupes.length + 1,
      champ_perso_groupe_groupement_id: this.props.utilisateur.groupement_id,
    };
    this.props.onCreateCustomGroup(data);
    this.groupForm.current.resetFields();
  };

  onFinishCustomField = (values) => {
    const data = {
      champ_perso_libelle: values.field,
      champ_perso_type:
        values.type === CONTACT.CUSTOM.FIELD.TYPE[2].name
          ? CONTACT.CUSTOM.FIELD.TYPE[2].value
          : parseInt(values.type),
      champ_perso_ordre: this.state.groupSelected.champ_perso.length + 1,
      champ_perso_groupe_id: this.state.groupSelected.champ_perso_groupe_id,
    };
    this.props.onCreateCustomField(data);
    this.fieldForm.current.resetFields();
  };

  getTypeName = (type) => {
    const filtered = CONTACT.CUSTOM.FIELD.TYPE.filter((item) => item.value === type);
    return filtered.length > 0 && filtered[0].name;
  };

  // HANDLER
  handleCustomGroupChange = (value, record) => {
    const data = {
      champ_perso_groupe_id: record.champ_perso_groupe_id,
      champ_perso_groupe_libelle: value,
      champ_perso_groupe_type: parseInt(this.props.typeGroupe),
      champ_perso_groupe_ordre: record.index,
      champ_perso_groupe_groupement_id: this.props.utilisateur.groupement_id,
    };
    this.props.onCustomGroupChange(data);
  };

  handleCustomGroupOrderChange = (data) => {
    data.id = this.props.society.id;
    this.props.onCustomGroupListChange(data);
  };

  handleGroupDelete = (record, e) => {
    e.stopPropagation();
    if (this.state.groupSelected?.champ_perso.length > 0) {
      toast.error("Supprimer les éléments du groupe en premier lieu", { containerId: "A" });
    } else this.props.onDeleteGroup(record.champ_perso_groupe_id);
  };

  handleGroupRowClick = (e) => {
    const group = this.getGroupSelected(this.props.champsPersosGroupes, e.champ_perso_groupe_id);
    if (this.props.typeGroupe === 3) {
      this.equipementForm.current.setFieldsValue({
        champ_perso_groupe_equipement_id: group.champ_perso_groupe_equipement_id
          ? group.champ_perso_groupe_equipement_id.equipement_id
          : null,
      });
    }
    this.setState({ groupSelected: group });
  };

  handleCustomFieldChange = (value, record) => {
    const data = {
      champ_perso_id: record.champ_perso_id,
      champ_perso_libelle: value,
      champ_perso_type: parseInt(record.champ_perso_type),
      champ_perso_ordre: record.index,
      champ_perso_visible: record.champ_perso_visible,
      champ_perso_groupe_id: this.state.groupSelected.champ_perso_groupe_id,
    };
    this.props.onCustomFieldChange(data);
  };

  handleCustomFieldOrderChange = (data) => {
    data.id = this.state.groupSelected.id;
    this.props.onCustomFieldListChange(data);
  };

  handleFieldDelete = (record, e) => {
    e.stopPropagation();
    this.props.onDeleteField(record.champ_perso_id);
  };

  handleChangeVisible = (record) => {
    const data = {
      champ_perso_id: record.champ_perso_id,
      champ_perso_libelle: record.champ_perso_libelle,
      champ_perso_type: parseInt(record.champ_perso_type),
      champ_perso_ordre: record.index,
      champ_perso_visible: !record.champ_perso_visible,
      champ_perso_groupe_id: this.state.groupSelected.champ_perso_groupe_id,
    };
    this.props.onCustomFieldChange(data);
  };

  handle = (e) => {
    e.stopPropagation();
  };

  onChangeEquipement = (id) => {
    const groupe = this.state.groupSelected;
    groupe.champ_perso_groupe_equipement_id = id;
    this.props.onCustomGroupChange(groupe);
    this.setState({ idEquipement: id });
  };

  sortByIndex = (a, b) => {
    if (a.index < b.index) {
      return -1;
    }
    if (a.index > b.index) {
      return 1;
    }
    return 0;
  };

  sortByOrdre = (a, b) => {
    if (a.champ_perso_groupe_ordre < b.champ_perso_groupe_ordre) {
      return -1;
    }
    if (a.champ_perso_groupe_ordre > b.champ_perso_groupe_ordre) {
      return 1;
    }
    return 0;
  };

  sortByOrdre2 = (a, b) => {
    if (a.champ_perso_ordre < b.champ_perso_ordre) {
      return -1;
    }
    if (a.champ_perso_ordre > b.champ_perso_ordre) {
      return 1;
    }
    return 0;
  };

  render() {
    const { champsPersosGroupes, typeGroupe, equipements } = this.props;
    const { groupSelected } = this.state;

    const TYPE = CONTACT.CUSTOM.FIELD.TYPE;

    const groupsColumns = [
      {
        className: "drag-visible",
        dataIndex: "index",
        title: "Ordre",
        width: 56,
      },
      {
        dataIndex: "champ_perso_groupe_id",
        title: "#",
        width: 56,
      },
      {
        className: "drag-visible",
        dataIndex: "champ_perso_groupe_libelle",
        title: "Nom du groupe",
        width: "50%",
        render: (text, record) => (
          <ColumnAction
            defaultValue={record.champ_perso_groupe_libelle}
            placement="right"
            title={"Voulez vous renommer ce groupe ?"}
            okText="Valider"
            onOk={this.handleCustomGroupChange}
            record={record}
          ></ColumnAction>
        ),
      },
      {
        // Clickable area
        width: 96,
      },
      {
        dataIndex: "champ_perso_groupe_id",
        title: "Action",
        width: 64,
        render: (text, record) => (
          <Popconfirm
            placement="topRight"
            title={
              "Êtes vous sûr de supprimer " +
              record.champ_perso_groupe_libelle +
              " ainsi que tous les champs liés et leur données ?"
            }
            okText="Supprimer"
            onClick={this.handle}
            onConfirm={(e) => this.handleGroupDelete(record, e)}
          >
            <Button onClick={this.handle} icon={<CloseOutlined style={{ color: "red" }} />} />
          </Popconfirm>
        ),
      },
    ];

    const fieldsColumns = [
      {
        className: "drag-visible",
        dataIndex: "index",
        title: "Ordre",
        width: 56,
      },
      {
        dataIndex: "champ_perso_id",
        title: "#",
        width: 56,
      },
      {
        className: "drag-visible",
        dataIndex: "champ_perso_libelle",
        title: "Nom du champ",
        width: "50%",
        render: (text, record) => (
          <ColumnAction
            defaultValue={record.champ_perso_libelle}
            placement="left"
            title={"Voulez vous renommer ce champ ?"}
            okText="Valider"
            onOk={this.handleCustomFieldChange}
            record={record}
          ></ColumnAction>
        ),
      },
      {
        className: "drag-visible",
        dataIndex: "champ_perso_type",
        title: "Type",
        width: 96,
        render: (text, record) => this.getTypeName(record.champ_perso_type),
      },
      {
        dataIndex: "champ_perso_id",
        title: "Action",
        width: 64,
        render: (text, record) => (
          <Popconfirm
            placement="topRight"
            title={"Êtes vous sûr de supprimer " + record.name + " ainsi que toutes les données ?"}
            okText="Supprimer"
            onConfirm={(e) => this.handleFieldDelete(record, e)}
          >
            <Button onClick={this.handle} icon={<CloseOutlined style={{ color: "red" }} />} />
          </Popconfirm>
        ),
      },
      {
        dataIndex: "champ_perso_id",
        title: "Visible ?",
        width: 64,
        render: (text, record) =>
          record.champ_perso_visible ? (
            <Button
              onClick={() => this.handleChangeVisible(record)}
              icon={<CheckSquareOutlined style={{ color: "green" }} />}
            />
          ) : (
            <Button
              onClick={() => this.handleChangeVisible(record)}
              icon={<CloseSquareOutlined style={{ color: "red" }} />}
            />
          ),
      },
    ];

    const defaultScroll = {
      x: "400px",
      y: "600px",
    };

    const content = (
      <div>
        Le groupe apparaîtra sur la fiche lot si l'équipement correspondant est sélectionné.
      </div>
    );

    return (
      <Row gutter={[16]} style={{ margin: "8px 0" }}>
        <Col lg={12} md={12} sm={24}>
          <Row type="flex" justify="center" align="middle">
            <h4>Groupes</h4>
          </Row>

          {champsPersosGroupes && (
            <CustomTable
              columns={groupsColumns}
              dataSource={champsPersosGroupes.sort(this.sortByOrdre)}
              draggable
              onClickRow={this.handleGroupRowClick}
              rowPointer
              selectedRow={groupSelected}
              sortChange={this.handleCustomGroupOrderChange}
              scroll={defaultScroll}
            />
          )}

          <Row type="flex" justify="center" style={{ margin: "16px 0" }}>
            <Form
              layout="inline"
              onFinish={this.onFinishCustomGroup}
              initialValues={{}}
              ref={this.groupForm}
            >
              <Form.Item name="group" rules={[{ required: true, message: "Nom requis" }]}>
                <Input autoComplete="off" placeholder="Nom du groupe" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Ajouter
                </Button>
              </Form.Item>
            </Form>
          </Row>
        </Col>

        <Col lg={12} md={12} sm={24}>
          {groupSelected ? (
            <>
              <Row gutter={12}>
                <Col span={6}>
                  <h4>{groupSelected.champ_perso_groupe_libelle}</h4>
                </Col>
                {parseInt(typeGroupe) === 3 && (
                  <>
                    <Col span={6}>
                      <Form layout="inline" initialValues={{}} ref={this.equipementForm}>
                        <Form.Item name="champ_perso_groupe_equipement_id">
                          <Select
                            showSearch
                            allowClear
                            optionFilterProp="label"
                            placeholder="Équipement"
                            onChange={(id) => this.onChangeEquipement(id)}
                          >
                            {equipements.map((item, i) => (
                              <Option key={i} value={item.equipement_id}>
                                {item.equipement_libelle}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Form>
                    </Col>
                    <Col span={1}>
                      <Popover placement="left" content={content} trigger="hover">
                        <Button shape="circle" icon={<QuestionOutlined />} />
                      </Popover>
                    </Col>
                  </>
                )}
              </Row>

              <CustomTable
                columns={fieldsColumns}
                dataSource={groupSelected.champ_perso.sort(this.sortByOrdre2)}
                sortChange={this.handleCustomFieldOrderChange}
                draggable
                scroll={defaultScroll}
              />

              <Row type="flex" justify="center" align="middle" style={{ margin: "16px 0" }}>
                <Form
                  layout="inline"
                  onFinish={this.onFinishCustomField}
                  initialValues={{
                    type: TYPE[2].value,
                  }}
                  ref={this.fieldForm}
                >
                  <Form.Item name="field" rules={[{ required: true, message: "Nom requis" }]}>
                    <Input autoComplete="off" placeholder="Nom du champ" />
                  </Form.Item>

                  <Form.Item name="type" rules={[{ required: true, message: "Type requis" }]}>
                    <Select
                      showSearch
                      optionFilterProp="label"
                      placeholder="Type du champ"
                      style={{ width: "122px" }}
                    >
                      {TYPE.map((type, i) => (
                        <Option key={i} value={type.value}>
                          {type.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Ajouter
                    </Button>
                  </Form.Item>
                </Form>
              </Row>
            </>
          ) : (
            <Row justify="center" align="middle" style={{ minHeight: "256px" }}>
              <p>Sélectionner un groupe pour afficher la liste de ses champs</p>
            </Row>
          )}
        </Col>
      </Row>
    );
  }
}

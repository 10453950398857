export function qualitesToAdapted(items) {
  return items
    ? items.map((item) => {
        return qualiteToAdapted(item);
      })
    : null;
}

export function qualiteToAdapted(item) {
  //Resource
  return item
    ? {
        index: item.index,
        qualite_id: item.id,
        qualite_libelle: item.libelle,
        qualite_libelle_long: item.libelle_long,
        qualite_groupement_id: item.groupement_id,
      }
    : null;
}

export function adaptedToQualite(item) {
  //Model
  return item
    ? {
        libelle: item.qualite_libelle,
        libelle_long: item.qualite_libelle_long,
        groupement_id: item.qualite_groupement_id,
      }
    : null;
}

import { simpleLotToAdapted } from "./lotAdapter";
import { simpleRequeteToAdapted } from "./requeteAdapter";

export function requeteLotsToAdapted(items) {
  return items
    ? items.map((item) => {
        return requeteLotToAdapted(item);
      })
    : null;
}

export function requeteLotToAdapted(item) {
  //Resource
  return item
    ? {
        requete_lot_requete: simpleRequeteToAdapted(item.requete),
        requete_lot_lot: simpleLotToAdapted(item.lot),
        requete_lot_date_debut: item.date_debut,
        requete_lot_date_fin: item.date_fin,
        requete_lot_etat: item.etat,
        requete_lot_type: item.type,
      }
    : null;
}

export function adaptedToRequeteLot(item) {
  //Model
  return item
    ? {
        requete_id: item.requete_lot_requete_id,
        lot_id: item.requete_lot_lot_id,
        date_debut: item.requete_lot_date_debut,
        date_fin: item.requete_lot_date_fin,
        etat: item.requete_lot_etat,
        type: item.requete_lot_type,
      }
    : null;
}

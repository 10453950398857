export function occupantsToAdapted(items) {
  return items
    ? items.map((item) => {
        return occupantToAdapted(item);
      })
    : null;
}

export function occupantToAdapted(item) {
  return item
    ? {
        occupant_id: item.id,
        occupant_nom: item.nom,
        occupant_prenom: item.prenom,
        occupant_date_naissance: item.date_naissance,
        occupant_lieu_naissance: item.lieu_naissance,
        occupant_location_id: item.location_id,
      }
    : null;
}

export function adaptedToOccupants(items) {
  return items
    ? {
        occupants: items.occupants.map((item) => {
          return adaptedToOccupant(item);
        }),
        occupants_to_delete: items.occupants_to_delete?.map((item) => {
          return adaptedToOccupant(item);
        }),
      }
    : null;
}

export function adaptedToOccupant(item) {
  return item
    ? {
        id: item.occupant_id,
        nom: item.occupant_nom,
        prenom: item.occupant_prenom,
        date_naissance: item.occupant_date_naissance,
        lieu_naissance: item.occupant_lieu_naissance,
        location_id: item.occupant_location_id,
      }
    : null;
}

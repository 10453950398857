import { dossierToAdapted } from "./dossierAdapter";
import { emailsToAdapted } from "./emailAdapter";
import { groupeToAdapted } from "./groupeAdapter";
import { locataireToAdapted, simpleLocataireToAdapted } from "./locataireAdapter";
import { provenanceToAdapted } from "./provenanceAdapter";
import { rechercheToAdapted } from "./rechercheAdapter";

export function requetesToAdapted(items) {
  return items
    ? items.map((item) => {
        return requeteToAdapted(item);
      })
    : null;
}

export function requeteToAdapted(item) {
  //Resource
  return item
    ? {
        requete_id: item.id,
        requete_locataire: locataireToAdapted(item.locataire),
        requete_groupe: groupeToAdapted(item.groupe),
        requete_dossier: dossierToAdapted(item.dossier),
        requete_fin: item.fin,
        requete_created: item.created,
        requete_provenance: provenanceToAdapted(item.provenance),
        requete_recherche: rechercheToAdapted(item.recherche),
        requete_emails: emailsToAdapted(item.emails),
        requete_commentaire: item.commentaire,
        notes: item.notes, // format v2
      }
    : null;
}

export function simpleRequeteToAdapted(item) {
  //Resource
  return item
    ? {
        requete_id: item.id,
        requete_locataire: simpleLocataireToAdapted(item.locataire),
        requete_dossier: dossierToAdapted(item.dossier),
        requete_fin: item.fin,
        requete_created: item.created,
      }
    : null;
}

export function renduARequeteToAdapted(item) {
  //Resource
  return item
    ? {
        requete_id: item.id,
        requete_locataire_id: item.locataire_id,
        requete_groupe_id: item.groupe_id,
        requete_fin: item.fin,
        requete_created: item.created,
      }
    : null;
}

export function adaptedToRequete(item) {
  //Model
  return item
    ? {
        locataire_id: item.requete_locataire_id,
        groupe_id: item.requete_groupe_id,
        dossier_id: item.requete_dossier_id,
        fin: item.requete_fin,
        at: item.created_at,
        provenance_id: item.requete_provenance_id,
        commentaire: item.requete_commentaire,
      }
    : null;
}

// DEPENDENCY
import { Card, Form, Input, Checkbox } from "antd";
import React, { Component } from "react";
import { CONTACT } from "../../utils/constants";

export default class CardElement extends Component {
  render() {
    const { groupe, isArchive } = this.props;
    const TYPES = CONTACT.CUSTOM.FIELD.TYPE;

    return (
      <Card title={<h5>{groupe.champ_perso_groupe_libelle}</h5>} bordered={false}>
        {groupe.champ_perso &&
          groupe.champ_perso
            .sort((a, b) => a.champ_perso_ordre - b.champ_perso_ordre)
            .map((field) => {
              const key = field.champ_perso_id;
              switch (field.champ_perso_type) {
                case TYPES[0].value:
                  return (
                    <Form.Item colon={false} key={key} name={key} valuePropName="checked">
                      <Checkbox disabled={isArchive}>{field.champ_perso_libelle}</Checkbox>
                    </Form.Item>
                  );
                case TYPES[1].value:
                  return (
                    <Form.Item colon={false} key={key} name={key}>
                      <Input
                        disabled={isArchive}
                        addonBefore={field.champ_perso_libelle}
                        type="number"
                        min={0}
                      />
                    </Form.Item>
                  );
                case TYPES[2].value:
                default:
                  return (
                    <Form.Item colon={false} key={key} name={key}>
                      <Input
                        disabled={isArchive}
                        addonBefore={field.champ_perso_libelle}
                        maxLength="250"
                      />
                    </Form.Item>
                  );
              }
            })}
      </Card>
    );
  }
}

export function templatesToAdapted(items) {
  return items
    ? items.map((item) => {
        return templateToAdapted(item);
      })
    : null;
}

export function templateToAdapted(item) {
  //Resource
  return item
    ? {
        template_id: item.id,
        template_libelle: item.libelle,
        template_type: item.type,
        template_notification: item.notification,
      }
    : null;
}

export function adaptedToTemplate(item) {
  //Model
  return item
    ? {
        libelle: item.template_libelle,
        notification: item.template_notification,
        etablissement_id: item.template_etablissement_id,
        type_id: item.template_type_id,
      }
    : null;
}

import React, { Component } from "react";
import { connect } from "react-redux";

import {
  loadChampsPersosGroupes,
  saveChampPersoGroupe,
  updateChampPersoGroupe,
  updateOrdreChampPersoGroupe,
  deleteChampPersoGroupe,
} from "../../store/actions/champPersoGroupeAction";

import {
  saveChampPerso,
  updateChampPerso,
  updateOrdreChampPerso,
  deleteChampPerso,
} from "../../store/actions/champPersoAction";

import { Card, Radio, Row } from "antd";

import FormChampPerso from "../../components/FormChampPerso";

class ChampPerso extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: 3,
    };
  }

  componentDidMount() {
    this.init(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.init(props);
  }

  init = (props) => {
    this.setState({ champsPersosGroupes: props.champsPersosGroupes });
  };

  champsPersosGroupeToTable = (champsPersosGroupes, type) => {
    return champsPersosGroupes
      .filter(function(obj) {
        return obj.champ_perso_groupe_type === parseInt(type);
      })
      .map((groupe) => {
        return this.groupeToTable(groupe);
      });
  };

  groupeToTable = (gp) => {
    gp.index = gp.champ_perso_groupe_ordre;
    gp.champ_perso = gp.champ_perso ? this.champsPersoToTable(gp.champ_perso) : [];
    return gp;
  };

  champsPersoToTable = (champsPersos) => {
    return champsPersos.map((champ) => {
      return this.champToTable(champ);
    });
  };

  champToTable(cp) {
    cp.index = cp.champ_perso_ordre;
    return cp;
  }

  onChangeType = (e) => {
    this.setState({ type: e.target.value });
  };

  // CUSTOM GROUP
  createCustomGroup = (data) => {
    this.props.saveChampPersoGroupe(data);
  };

  updateCustomGroup = (data) => {
    this.props.updateChampPersoGroupe(data, data.champ_perso_groupe_id);
  };

  updateCustomGroupList = (data) => {
    data.forEach((item, i) => {
      item.champ_perso_groupe_ordre = i + 1;
    });

    let champsPersosGroupes = this.state.champsPersosGroupes;
    champsPersosGroupes = data;
    this.setState({ champsPersosGroupes });
    this.props.updateOrdreChampPersoGroupe({ data });
  };

  deleteGroup = (id) => {
    this.props.deleteChampPersoGroupe(id);
  };

  // CUSTOM FIELD
  createCustomField = (data) => {
    this.props.saveChampPerso(data);
    this.props.syncChampsPersosGroupes();
  };

  updateCustomField = (data) => {
    this.props.updateChampPerso(data, data.champ_perso_id);
    this.props.syncChampsPersosGroupes();
  };

  updateCustomFieldList = (data) => {
    data.forEach((item, i) => {
      item.champ_perso_ordre = i + 1;
    });

    let champsPersosGroupes = this.state.champsPersosGroupes;
    champsPersosGroupes.forEach((i) => {
      if (i.champ_perso_groupe_id === data[0].champ_perso_groupe_id) i.champ_perso = data;
      return i;
    });

    this.setState({ champsPersosGroupes });
    this.props.updateOrdreChampPerso({ data });
  };

  deleteField = (id) => {
    this.props.deleteChampPerso(id);
    this.props.syncChampsPersosGroupes();
  };

  render() {
    const { equipements } = this.props;
    const { type, champsPersosGroupes } = this.state;

    return (
      <div>
        <Row>
          <Radio.Group onChange={this.onChangeType} defaultValue="3">
            <Radio.Button value="3">Élément</Radio.Button>
            <Radio.Button value="4">Pièce</Radio.Button>
          </Radio.Group>
        </Row>
        <Card title="" bordered={false} className="card-panel">
          {champsPersosGroupes && (
            <FormChampPerso
              utilisateur={this.props.utilisateur}
              champsPersosGroupes={this.champsPersosGroupeToTable(champsPersosGroupes, type)}
              equipements={equipements}
              typeGroupe={type}
              society={1}
              onCreateCustomGroup={this.createCustomGroup}
              onCustomGroupChange={this.updateCustomGroup}
              onCustomGroupListChange={this.updateCustomGroupList}
              onDeleteGroup={this.deleteGroup}
              onCreateCustomField={this.createCustomField}
              onCustomFieldChange={this.updateCustomField}
              onCustomFieldListChange={this.updateCustomFieldList}
              onDeleteField={this.deleteField}
            />
          )}
        </Card>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadChampsPersosGroupes: (groupement_id) => dispatch(loadChampsPersosGroupes(groupement_id)),
    saveChampPersoGroupe: (champPersoGroupe) => dispatch(saveChampPersoGroupe(champPersoGroupe)),
    updateChampPersoGroupe: (champPersoGroupe, id) =>
      dispatch(updateChampPersoGroupe(champPersoGroupe, id)),
    deleteChampPersoGroupe: (id) => dispatch(deleteChampPersoGroupe(id)),
    saveChampPerso: (champPerso) => dispatch(saveChampPerso(champPerso)),
    updateChampPerso: (champPerso, id) => dispatch(updateChampPerso(champPerso, id)),
    deleteChampPerso: (id) => dispatch(deleteChampPerso(id)),
    updateOrdreChampPerso: (jsonData) => dispatch(updateOrdreChampPerso(jsonData)),
    updateOrdreChampPersoGroupe: (jsonData) => dispatch(updateOrdreChampPersoGroupe(jsonData)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    champsPersosGroupes: state.champsPersosGroupes.data,
    equipements: state.equipements.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChampPerso);

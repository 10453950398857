import React, { Component } from "react";
import {
  loadReglements,
  saveReglement,
  saveReglementLigne,
} from "../../store/actions/reglementAction";
import { saveEcriture } from "../../store/actions/ecritureAction";

import { Card, Spin } from "antd";
import FormReglement from "../../components/FormReglement";
import { connect } from "react-redux";
import { loadLocataires } from "../../store/actions/locataireAction";
import { loadLocations } from "../../store/actions/locationAction";
import { loadQuittances } from "../../store/actions/quittanceAction";
import { loadComptes } from "../../store/actions/compteAction";

class Reglement extends Component {
  componentDidMount() {
    const groupement_id = this.props.utilisateur.groupement_id;
    const societe_id = this.props.utilisateur.societe_id;    
    Promise.all([
      !this.props.quittances && this.props.loadQuittances(groupement_id),
      !this.props.reglements && this.props.loadReglements(societe_id),
      !this.props.comptes && this.props.loadComptes(societe_id),
      !this.props.locataires && this.props.loadLocataires(groupement_id),
      !this.props.locations && this.props.loadLocations(groupement_id),
    ]);
  }

  render() {
    const {
      idLocataire,
      societes,
      journals,
      comptes,
      locataires,
      locations,
      quittances,
      reglements,
      typesPaiements,
      utilisateur,
    } = this.props;

    return (
      <Spin
        size="large"
        tip="Chargement en cours..."
        spinning={!comptes || !locataires || !locations || !quittances || !reglements}
      >
        <Card title="" bordered={false} className="card-panel">
          <FormReglement
            idLocataire={idLocataire}
            societes={societes.filter((s) => s.societe_id === this.props.utilisateur.societe_id)}
            locataires={locataires}
            journals={journals}
            comptes={comptes}
            locations={locations && locations.filter((item) => item.location_lot.lot_societe_id === utilisateur.societe_id)}
            quittances={quittances}
            reglements={reglements}
            typesPaiements={typesPaiements}
            saveReglement={this.props.saveReglement}
            saveReglementLigne={this.props.saveReglementLigne}
            saveEcriture={this.props.saveEcriture}
          />
        </Card>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadComptes: (societe_id) => dispatch(loadComptes(societe_id)),
    loadLocataires: (groupement_id) => dispatch(loadLocataires(groupement_id)),
    loadLocations: (groupement_id) => dispatch(loadLocations(groupement_id)),
    loadQuittances: (groupement_id) => dispatch(loadQuittances(groupement_id)),
    loadReglements: (societe_id) => dispatch(loadReglements(societe_id)),
    saveReglement: (jsonData) => dispatch(saveReglement(jsonData)),
    saveReglementLigne: (jsonData) => dispatch(saveReglementLigne(jsonData)),
    saveEcriture: (ecriture) => dispatch(saveEcriture(ecriture)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    societes: state.societes.data,
    journals: state.journals.data,
    comptes: state.comptes.data,
    locataires: state.locataires.data,
    locations: state.locations.data,
    quittances: state.quittances.data,
    reglements: state.reglements.data,
    typesPaiements: state.typesPaiements.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reglement);

import React, { Component } from "react";
import dayjs from "dayjs";

export default class MouvementsRubriquePDF extends Component {
  render() {
    const { lignesMouvements, dateDu, dateAu } = this.props;

    return (
      <div>
        <div>
          <h1>LISTE ENTREES/SORTIES</h1>
          <p className="center">
            <span>
              <strong>
                {`Période : ${dateDu.format("DD/MM/YYYY") + " "} au ${dateAu.format("DD/MM/YYYY")}`}
              </strong>
            </span>
          </p>
        </div>
        <br />

        <table className="table-signature">
          <thead>
            <tr bgcolor="#afaeae">
              <th style={{ width: "30%" }}>
                <strong>Locataire</strong>
              </th>
              <th style={{ width: "30%" }}>
                <strong>Lot</strong>
              </th>
              <th style={{ width: "20%" }}>
                <strong>Entrée</strong>
              </th>
              <th style={{ width: "20%" }}>
                <strong>Sortie</strong>
              </th>
            </tr>
          </thead>
        </table>

        <br />
        <br />

        {lignesMouvements.map((mouvement, i) => {
          const total = mouvement.quittance_ligne.reduce(
            (a, b) => a + parseFloat(b.quittance_ligne_montant),
            0
          );
          return (
            <>
              <table className="table-signature" width="100%">
                <thead>
                  <tr bgcolor="#f4c181">
                    <th style={{ width: "30%" }}>
                      <strong>
                        {mouvement.location_requete
                          ? `${mouvement.location_requete.requete_locataire.locataire_personne.personne_nom} ${mouvement.location_requete.requete_locataire.locataire_personne.personne_prenom}`
                          : "Blocage propriétaire"}
                      </strong>
                    </th>
                    <th style={{ width: "30%" }}>
                      <strong>{`${mouvement.location_lot.lot_reference}`}</strong>
                    </th>
                    <th style={{ width: "20%" }}>
                      <strong>
                        {dayjs(mouvement.location_date_debut, "YYYY-MM-DD").format("DD/MM/YYYY")}
                      </strong>
                    </th>
                    <th style={{ width: "20%" }}>
                      <strong>
                        {dayjs(mouvement.location_date_fin, "YYYY-MM-DD").format("DD/MM/YYYY")}
                      </strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {mouvement.quittance_ligne.map((ligne, i) => {
                    return (
                      <tr key={i} bgcolor={i % 2 === 0 ? "#ffffff" : "#eeeeee"}>
                        <td>{ligne.quittance_ligne_rubrique.rubrique_libelle}</td>
                        <td align="right">
                          {parseFloat(ligne.quittance_ligne_montant).toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          })}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr bgcolor="#afaeae">
                    <th align="right"></th>
                    <td align="right">
                      <strong>
                        {parseFloat(total).toLocaleString("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </strong>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
              <br />
              <br />
            </>
          );
        })}
      </div>
    );
  }
}

import React, { Component } from "react";
import dayjs from "dayjs"

export default class MouvementsLocationPDF extends Component {
  render() {
    const { lignesMouvements, dateDu, dateAu, mouvement } = this.props;

    let lignesEntrees = [];
    let lignesSorties = [];

    // ENTREES
    if (mouvement === 1) {
      lignesEntrees = lignesMouvements;
    }
    if (mouvement === 2) {
      lignesSorties = lignesMouvements;
    }
    if (mouvement === 3) {
      lignesEntrees = lignesMouvements.filter((item) =>
        dayjs(item.location_date_debut, "YYYY-MM-DD").isBetween(
          dayjs(dateDu, "YYYY-MM-DD"),
          dayjs(dateAu, "YYYY-MM-DD")
        )
      );
      lignesSorties = lignesMouvements.filter((item) =>
        dayjs(item.location_date_fin, "YYYY-MM-DD").isBetween(
          dayjs(dateDu, "YYYY-MM-DD"),
          dayjs(dateAu, "YYYY-MM-DD")
        )
      );
    }

    return (
      <div>
        <div>
          <h1>LISTE ENTREES/SORTIES</h1>
          <p className="center">
            <span>
              <strong>
                {`Période : ${dateDu.format("DD/MM/YYYY") + " "} au ${dateAu.format("DD/MM/YYYY")}`}
              </strong>
            </span>
          </p>
        </div>
        <br />

        {lignesEntrees.length > 0 && (
          <table className="table table-striped" border="1">
            <tr>
              <th width="100%" align="center" bgcolor="#afaeae">
                <strong>ENTREES</strong>
              </th>
            </tr>
          </table>
        )}

        <br />
        <br />

        {lignesEntrees.map((ligne, i) => {
          let portable1 = "";
          let portable2 = "";
          let telephone1 = "";

          if (ligne.location_requete) {
            portable1 =
              ligne.location_requete.requete_locataire.locataire_personne.personne_portable1;
            portable2 =
              ligne.location_requete.requete_locataire.locataire_personne.personne_portable2;
            telephone1 = ligne.location_requete.requete_locataire.locataire_personne.personne_tel1;
          } else {
            portable1 =
              ligne.location_lot.lot_proprietaire.proprietaire_personne.personne_portable1;
            portable2 =
              ligne.location_lot.lot_proprietaire.proprietaire_personne.personne_portable2;
            telephone1 = ligne.location_lot.lot_proprietaire.proprietaire_personne.personne_tel1;
          }

          return (
            <table>
              <tr bgcolor="#f4c181">
                <td>
                  {ligne.location_requete
                    ? ligne.location_requete.requete_locataire.locataire_personne.personne_nom
                    : "PROPRIETAIRE"}
                </td>
                <td>
                  {ligne.location_lot.lot_reference} - {ligne.location_lot.lot_designation}
                </td>
                <td>Arrivée : le {dayjs(ligne.location_date_debut, "YYYY-MM-DD").format("DD/MM/YYYY")} à {ligne.location_heure_arrivee}</td>
              </tr>
              <tr bgcolor="#f0f0f0">
                <td>
                  {portable1}
                  {portable2 ? portable2 : ""}
                  {telephone1 ? telephone1 : ""}
                </td>
                <td>{ligne.location_lot.lot_proprietaire.proprietaire_personne.personne_nom}</td>
                <td>Départ : le {dayjs(ligne.location_date_fin, "YYYY-MM-DD").format("DD/MM/YYYY")} à {ligne.location_heure_depart}</td>
              </tr>
              <tr bgcolor="#f0f0f0">
                <td>
                  {ligne.location_requete
                    ? ligne.location_requete.requete_locataire.locataire_personne.personne_adresse
                        .adresse_gmaps_adresse
                    : ligne.location_lot.lot_proprietaire.proprietaire_personne.personne_adresse
                        .adresse_gmaps_adresse}
                </td>
                <td>{ligne.location_lot.lot_adresse.adresse_gmaps_adresse}</td>
                <td></td>
              </tr>
              <tr bgcolor="#f0f0f0">
                <td>
                  {parseInt(ligne.solde) !== 0 ? (
                    parseFloat(ligne.solde).toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    })
                  ) : (
                    <></>
                  )}
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr bgcolor="#f0f0f0">
                <td>
                  {ligne.location_nb_adultes} adultes {ligne.location_nb_enfants} enfants{" "}
                  {ligne.location_nb_bebes} bébés
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr bgcolor="#f0f0f0">
                <td>Desiderata : {ligne.location_desiderata}</td>
                <td>Commentaire : {ligne.location_commentaire}</td>
                <td></td>
              </tr>
              <tr bgcolor="#f0f0f0">
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr bgcolor="#f0f0f0">
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr bgcolor="#f0f0f0">
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr bgcolor="#f0f0f0">
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </table>
          );
        })}

        <br />
        <br />

        {lignesSorties.map((ligne, i) => {
          let portable1 = "";
          let portable2 = "";
          let telephone1 = "";

          if (ligne.location_requete) {
            portable1 =
              ligne.location_requete.requete_locataire.locataire_personne.personne_portable1;
            portable2 =
              ligne.location_requete.requete_locataire.locataire_personne.personne_portable2;
            telephone1 = ligne.location_requete.requete_locataire.locataire_personne.personne_tel1;
          } else {
            portable1 =
              ligne.location_lot.lot_proprietaire.proprietaire_personne.personne_portable1;
            portable2 =
              ligne.location_lot.lot_proprietaire.proprietaire_personne.personne_portable2;
            telephone1 = ligne.location_lot.lot_proprietaire.proprietaire_personne.personne_tel1;
          }

          return (
            <table>
              <tr bgcolor="#f4c181">
                <td>
                  {ligne.location_requete
                    ? ligne.location_requete.requete_locataire.locataire_personne.personne_nom
                    : "PROPRIETAIRE"}
                </td>
                <td>
                  {ligne.location_lot.lot_reference} - {ligne.location_lot.lot_designation}
                </td>
                <td>
                  Arrivée : le{" "}
                  {dayjs(ligne.location_date_debut, "YYYY-MM-DD").format("DD/MM/YYYY")} à{" "}
                  {ligne.location_heure_arrivee}
                </td>
              </tr>
              <tr bgcolor="#f0f0f0">
                <td>
                  {portable1}
                  {portable2 ? portable2 : ""}
                  {telephone1 ? telephone1 : ""}
                </td>
                <td>{ligne.location_lot.lot_proprietaire.proprietaire_personne.personne_nom}</td>
                <td>
                  Départ : le {dayjs(ligne.location_date_fin, "YYYY-MM-DD").format("DD/MM/YYYY")} à{" "}
                  {ligne.location_heure_depart}
                </td>
              </tr>
              <tr bgcolor="#f0f0f0">
                <td>
                  {ligne.location_requete
                    ? ligne.location_requete.requete_locataire.locataire_personne.personne_adresse
                        .adresse_gmaps_adresse
                    : ligne.location_lot.lot_proprietaire.proprietaire_personne.personne_adresse
                        .adresse_gmaps_adresse}
                </td>
                <td>{ligne.location_lot.lot_adresse.adresse_gmaps_adresse}</td>
                <td></td>
              </tr>
              <tr bgcolor="#f0f0f0">
                <td>
                  {parseInt(ligne.solde) !== 0 ? (
                    parseFloat(ligne.solde).toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    })
                  ) : (
                    <></>
                  )}
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr bgcolor="#f0f0f0">
                <td>
                  {ligne.location_nb_adultes} adultes {ligne.location_nb_enfants} enfants{" "}
                  {ligne.location_nb_bebes} bébés
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr bgcolor="#f0f0f0">
                <td>Desiderata : {ligne.location_desiderata}</td>
                <td>Commentaire : {ligne.location_commentaire}</td>
                <td></td>
              </tr>
              <tr bgcolor="#f0f0f0">
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr bgcolor="#f0f0f0">
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr bgcolor="#f0f0f0">
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr bgcolor="#f0f0f0">
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </table>
          );
        })}
      </div>
    );
  }
}

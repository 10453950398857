export const columns = (etapes) => [
  {
    title: "Étape",
    dataIndex: "etape_libelle",
    key: "etape_libelle",
    sorter: (a, b) => parseInt(b.etape_id) - parseInt(a.etape_id),
    width: "30%",
  },
  {
    title: "Destination",
    dataIndex: "etape_etape_action",
    key: "destination",
    render: (actions) => {
      const timer = actions.find((action) => action.etape_action_type === 1);
      const destination = etapes.find(
        (etape) => etape.etape_id === timer.etape_action_etape_destination_timer_id
      );
      return destination.etape_libelle;
    },
    width: "30%",
  },
  {
    title: "Repère",
    dataIndex: "etape_etape_action",
    key: "repere",
    render: (actions) => {
      let libelle = "";
      const timer = actions.find((action) => action.etape_action_type === 1);
      switch (timer.etape_action_repere) {
        case 1:
          libelle = "Arrivée dans l'étape";
          break;
        case 2:
          libelle = "Check-In";
          break;
        case 3:
          libelle = "Check-Out";
          break;
        default:
          libelle = "?";
          break;
      }
      return libelle;
    },
    width: "30%",
  },
  {
    title: "Timer",
    dataIndex: "etape_etape_action",
    key: "timer",
    render: (actions) => {
      const timer = actions.find((action) => action.etape_action_type === 1);
      return `${timer.etape_action_timer} ${
        timer.etape_action_timer < -1 || timer.etape_action_timer > 1 ? "jours" : "jour"
      }`;
    },
    width: "10%",
  },
];

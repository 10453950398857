import {
  cautionToAdapted,
  adaptedToCautions,
  cautionsToAdapted,
} from "../../adapter/cautionAdapter";
import { HttpMethod, initFetch } from "../../utils/fetcher";

export const LOAD_SUCCESS_CAUTION = "LOAD_SUCCESS_CAUTION";
export const LOAD_FAIL_CAUTION = "LOAD_FAIL_CAUTION";
export const SAVE_SUCCESS_CAUTION = "SAVE_SUCCESS_CAUTION";
export const SAVE_FAIL_CAUTION = "SAVE_FAIL_CAUTION";
export const UPDATE_SUCCESS_CAUTION = "UPDATE_SUCCESS_CAUTION";
export const UPDATE_FAIL_CAUTION = "UPDATE_FAIL_CAUTION";
export const DELETE_SUCCESS_CAUTION = "DELETE_SUCCESS_CAUTION";
export const DELETE_FAIL_CAUTION = "DELETE_FAIL_CAUTION";

export function loadCautions() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`cautions`, HttpMethod.GET);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.caution);
          dispatch({
            type: LOAD_SUCCESS_CAUTION,
            payload: cautionsToAdapted(res.caution),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_CAUTION,
            error: err,
          });
        });
    });
  };
}

export function saveCautions(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`cautions`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToCautions(jsonData)) })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.caution);
          dispatch({
            type: SAVE_SUCCESS_CAUTION,
            payload: cautionsToAdapted(res.caution),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: SAVE_FAIL_CAUTION,
            error: err,
          });
        });
    });
  };
}

export function updateCautions(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`cautions`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToCautions(jsonData)) })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.caution);
          dispatch({
            type: UPDATE_SUCCESS_CAUTION,
            payload: cautionsToAdapted(res.caution),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: UPDATE_FAIL_CAUTION,
            error: err,
          });
        });
    });
  };
}

export function deleteCautions(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`cautions/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.caution);
          dispatch({
            type: DELETE_SUCCESS_CAUTION,
            payload: cautionToAdapted(res.caution),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_CAUTION,
            payload: err,
          });
        });
    });
  };
}

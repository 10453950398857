import React, { Component } from "react";
import { Button, Col, Form, InputNumber, Popconfirm, Row, Select, Table } from "antd";

import { ArrowLeftOutlined } from "@ant-design/icons";
import Column from "antd/lib/table/Column";
import { formatDateToAPP, getInfoLocataireSimple } from "../../utils/tools";
//import { getInfoLocataireSimple } from "../../utils/tools";

import CustomSelect from "../CustomFormItem/Select";
import CustomDatePicker from "../CustomFormItem/DatePicker";
import CustomInput from "../CustomFormItem/Input";

import { toast } from "react-toastify";

import dayjs from "dayjs";

const { Option, OptGroup } = Select;

class FormReglement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      societe: null,
      journal: null,
      montants: [],
      reglements: [],
      lignesReglements: [],
      selectedRowKeys: [],
      selectedRows: [],
      hidden: false,
      nomLocataire: "",
      dateReglement: null,
      typePaiement: this.props.typesPaiements[0].type_paiement_id,
      numeroCheque: "",
      libelle: "",
      montantReglement: 0,
      quittanceLignes: [],
      tropPercu: false,
      popConfirmOpen: false,
      loading: false,
      isAcompte: false,
    };
  }

  formRef = React.createRef();

  componentDidMount() {
    if (this.props.journals) {
      this.init();
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const idLocataire = this.state.idLocataire;
    if (idLocataire && idLocataire !== nextProps.idLocataire) {
      this.onSelectLocataire(idLocataire, nextProps.reglements);
    }
  };

  init = () => {
    const jouralsFiltered = this.props.journals.filter((item) => item.journal_de_banque === true)[0]
      ?.journal_id;
    this.formRef.current.setFieldsValue({
      societe_id: this.props.societes[0].societe_id,
      ecriture_journal_id: jouralsFiltered ? jouralsFiltered : null,
      typePaiement: this.props.typesPaiements[0].type_paiement_id,
    });
    this.setState(
      {
        societe: this.props.societes[0].societe_id,
        journal: jouralsFiltered ? jouralsFiltered : null,
        typePaiement: this.props.typesPaiements[0].type_paiement_id,
      },
      () => {
        if (this.props.idLocataire) {
          this.formRef.current.setFieldsValue({ requete_locataire_id: this.props.idLocataire });
          this.setState({ idLocataire: this.props.idLocataire });
          this.onSelectLocataire(this.props.idLocataire);
        }
      }
    );
  };

  onChangeSociete = (id) => {
    this.setState({ societe: id });
  };

  onChangeJournal = (id) => {
    this.setState({ journal: id });
  };

  onSelectLocataire = (idLocataire, propsReglements) => {
    const result = [];
    let lignesReglements = [];
    const locataire = this.props.locataires.find((item) => item.locataire_id === idLocataire);
    const quittances = this.props.quittances;
    const reglements = propsReglements ? propsReglements : this.props.reglements;
    const locations = this.props.locations.filter(
      (loc) =>
        loc.location_requete &&
        loc.location_requete.requete_locataire?.locataire_id === idLocataire &&
        loc.location_etat !== "a"
    );

    let datePaiement = null;
    if (quittances) {
      locations.forEach((location) => {
        let acompte = 0;
        if (location.location_etat === "r") {
          location.location_ligne.forEach((ligne) => {
            acompte +=
              (parseFloat(ligne.location_ligne_montant) *
                parseFloat(ligne.location_ligne_pourcentage_acompte)) /
              100;
          });
          acompte = parseFloat(acompte).toFixed(2);
        }

        quittances
          .filter((quit) => quit.quittance_location_id === location.location_id)
          .sort((a, b) => a.quittance_id - b.quittance_id)
          .forEach((quittance) => {
            const filteredReglement =
              reglements &&
              reglements.filter(
                (regl) => quittance.quittance_id === regl.reglement_quittance.quittance_id
              );

            let montantPaye = 0;
            let total = 0;
            quittance.quittance_ligne.forEach((ligne) => {
              total +=
                parseFloat(ligne.quittance_ligne_montant) *
                parseInt(ligne.quittance_ligne_quantite);
            });
            total = parseFloat(total).toFixed(2);

            filteredReglement &&
              filteredReglement.forEach((regl) => {
                montantPaye += parseFloat(regl.reglement_montant, 2);
                lignesReglements = [...lignesReglements, ...regl.reglement_ligne];
              });
            let resteDu = parseFloat(total - montantPaye).toFixed(2);
            montantPaye = parseFloat(montantPaye).toFixed(2);

            if (!datePaiement) datePaiement = quittance.quittance_date_paiement;

            // Montant déjà réglé par ligne quittance
            quittance.quittance_ligne.map((item) => {
              const lignesReglementsFiltered = lignesReglements.filter(
                (reg) =>
                  reg.reglement_ligne_quittance_ligne_id.quittance_ligne_id ===
                  item.quittance_ligne_id
              );
              item.quittance_ligne_regle = lignesReglementsFiltered.reduce(
                (a, b) => a + parseFloat(b.reglement_ligne_montant),
                0
              );
              const locationLigne = location.location_ligne.find(
                (ligne) =>
                  ligne.location_ligne_rubrique.rubrique_id ===
                  item.quittance_ligne_rubrique.rubrique_id
              );
              item.quittance_ligne_acompte = locationLigne
                ? parseFloat(
                    parseFloat(item.quittance_ligne_montant * item.quittance_ligne_quantite) *
                      parseFloat(locationLigne.location_ligne_pourcentage_acompte)
                  ) / 100
                : 0;
              return item;
            });

            quittance.quittance_ligne = this.sortedAsc(quittance.quittance_ligne);

            const reglement = {
              quittance_id: quittance.quittance_id,
              quittance_ligne: quittance.quittance_ligne,
              location_id: location.location_id,
              appartement: location.location_lot.lot_reference,
              date:
                quittance.quittance_date_paiement !== null
                  ? formatDateToAPP(quittance.quittance_date_paiement)
                  : "Non renseigné",
              date_debut: formatDateToAPP(quittance.quittance_date_debut),
              date_fin: formatDateToAPP(quittance.quittance_date_fin),
              total: total,
              acompte: acompte,
              paye: montantPaye,
              du: resteDu,
              reglement: 0,
              expand: false,
            };
            result.push(reglement);
          });
      });
      this.formRef.current.resetFields(["montant_reglement"]);
      this.formRef.current.setFieldsValue({
        date: datePaiement ? dayjs(datePaiement) : null,
      });
    }
    this.setState(
      {
        montants: [],
        reglements: result,
        lignesReglements,
        nomLocataire: locataire ? locataire.locataire_personne.personne_nom : "",
        dateReglement: datePaiement ? dayjs(datePaiement) : null,
        // isAcompte: false,
      },
      () => {
        this.uncheckAll();
        this.onChangeLibelle();
      }
    );
  };

  sortedAsc = (arr) => {
    return arr.sort((a, b) => {
      if (a.quittance_ligne_rubrique.rubrique_ordre === null) {
        return 1;
      }

      if (b.quittance_ligne_rubrique.rubrique_ordre === null) {
        return -1;
      }

      if (a.quittance_ligne_rubrique.rubrique_ordre === b.quittance_ligne_rubrique.rubrique_ordre) {
        return 0;
      }

      return a.quittance_ligne_rubrique.rubrique_ordre - b.quittance_ligne_rubrique.rubrique_ordre;
    });
  };

  prepareReglementsLignes = (reglements) => {
    const quittances = this.props.quittances;
    const locations = this.props.locations;
    let reglementsLignes = [];
    let montantRestant = 0;
    reglements.forEach((reglement) => {
      // Imputation automatique ou manuelle ?
      const imputationManuelle = this.state.quittanceLignes.length > 0;
      montantRestant = reglement.reglement_montant;
      // Get lignes reglements
      const lignesReglements = this.state.lignesReglements;
      // Get quittance from reglement
      const quittance = quittances.find(
        (quittance) => quittance.quittance_id === reglement.reglement_quittance.quittance_id
      );
      // Get location from quittance
      const location = locations.find(
        (location) => quittance.quittance_location_id === location.location_id
      );
      // Mode acompte ?
      let modeAcompte = false;
      let acompte = 0;
      location.location_ligne.forEach((ligne) => {
        acompte +=
          (parseFloat(ligne.location_ligne_montant) *
            parseFloat(ligne.location_ligne_pourcentage_acompte)) /
          100;
      });
      acompte = parseFloat(acompte);
      if (
        this.state.isAcompte &&
        parseFloat(reglement.reglement_montant) === acompte &&
        !imputationManuelle &&
        lignesReglements.filter(
          (ligne) =>
            ligne.reglement_ligne_quittance_ligne_id.quittance_ligne_quittance_id ===
            reglement.reglement_quittance.quittance_id
        ).length === 0
      )
        modeAcompte = true;

      const quittanceLignes = imputationManuelle
        ? this.state.quittanceLignes
        : quittance.quittance_ligne.sort(
            (a, b) =>
              a.quittance_ligne_rubrique.rubrique_ordre - b.quittance_ligne_rubrique.rubrique_ordre
          );

      quittanceLignes.forEach((quittance_ligne) => {
        let montant = 0;
        if (!imputationManuelle && modeAcompte) {
          const locationLigne = location.location_ligne.find(
            (ligne) =>
              ligne.location_ligne_rubrique.rubrique_id ===
              quittance_ligne.quittance_ligne_rubrique.rubrique_id
          );
          montant = locationLigne
            ? parseFloat(
                parseFloat(
                  quittance_ligne.quittance_ligne_montant * quittance_ligne.quittance_ligne_quantite
                ) * parseFloat(locationLigne.location_ligne_pourcentage_acompte)
              ) / 100
            : 0;
        } else if (imputationManuelle) {
          montant = quittance_ligne.montant;
        } else {
          montant = quittance_ligne.quittance_ligne_montant;
        }

        // If there is still something to pay
        if (montantRestant > 0) {
          let reglement_ligne_montant = 0;

          // Get montant quittance_ligne
          let quittance_ligne_montant =
            parseFloat(montant) * parseInt(quittance_ligne.quittance_ligne_quantite);

          if (imputationManuelle) {
            reglement_ligne_montant = quittance_ligne_montant;
          } else {
            // Get montant already sold by some reglement_ligne
            let montantLignesReglements = 0;
            lignesReglements.forEach((ligne) => {
              if (
                ligne.reglement_ligne_quittance_ligne_id.quittance_ligne_id ===
                quittance_ligne.quittance_ligne_id
              ) {
                montantLignesReglements += parseFloat(ligne.reglement_ligne_montant);
              }
            });
            // If montant already sold by some reglement_ligne: deduct this montant
            if (montantLignesReglements > 0) {
              quittance_ligne_montant = quittance_ligne_montant - montantLignesReglements;
            }
            // If we can pay this quittance_ligne with montant restant
            if (montantRestant >= quittance_ligne_montant && quittance_ligne_montant !== 0) {
              reglement_ligne_montant = quittance_ligne_montant;
            } else if (montantRestant < quittance_ligne_montant) {
              reglement_ligne_montant = montantRestant;
            }
          }

          // Push reglement_ligne with reglement_ligne_montant if there is something to pay
          if (reglement_ligne_montant !== 0) {
            reglementsLignes.push({
              reglement_ligne_reglement_id: reglement.reglement_id,
              reglement_ligne_quittance_ligne_id: quittance_ligne.quittance_ligne_id,
              reglement_ligne_quittance_ligne: quittance_ligne,
              reglement_ligne_montant: reglement_ligne_montant,
              locataire_compte_id:
                location.location_requete.requete_locataire.locataire_affectations[0]
                  .locataire_affectation_compte_id,
              proprietaire_compte_id:
                location.location_lot.lot_proprietaire.proprietaire_affectations[0]
                  .proprietaire_affectation_compte_id,
            });
            // Deduct this montant from montant restant
            montantRestant -= reglement_ligne_montant;
          }
        }
      });
    });
    return reglementsLignes;
  };

  onFinishReglement = (values) => {
    this.setState({ loading: true }, () => this.createReglement(values));
  };

  createReglement = (values) => {
    let du = 0;
    const selectedRowKeys = this.state.selectedRowKeys;
    const montants = this.state.montants;
    // get selected montant quittance on Table
    const selectedMontants = montants.filter((item) => selectedRowKeys.includes(item.id));
    if (selectedMontants.length > 0 && !selectedMontants.some((montants) => montants.value === 0)) {
      // get typePaiement Object
      const typePaiementObject = this.props.typesPaiements.find(
        (item) => item.type_paiement_id === parseInt(values.typePaiement)
      );
      // Prepare reglements
      const reglements = selectedMontants.map((element) => {
        du = element.du;
        return {
          reglement_banque: values.banque ? values.banque : "",
          reglement_tire: values.tire,
          reglement_numero_cheque: values.numero_cheque ? values.numero_cheque : "",
          reglement_date: dayjs(values.date).format("DD/MM/YYYY"),
          reglement_trop_percu: this.state.tropPercu,
          reglement_montant: element.value,
          reglement_bordereau: typePaiementObject.type_paiement_bordereau ? true : false,
          reglement_reddition: false,
          reglement_groupe_par: 0,
          reglement_type_paiement_id: parseInt(typePaiementObject.type_paiement_id),
          reglement_quittance_id: element.id,
          reglement_journal_id: values.ecriture_journal_id,
        };
      });
      const journal = this.props.journals.find(
        (item) => item.journal_id === values.ecriture_journal_id
      );
      if (journal.journal_compte) {
        // Send requests
        this.props.saveReglement({ reglements: reglements }).then((res) => {
          // Prepare ligne reglement
          const reglementsLignes = this.prepareReglementsLignes(res);
          this.props.saveReglementLigne({ reglementsLignes: reglementsLignes }).then(() => {
            toast.success("Encaissement validé", { containerId: "A" });
            this.setState({ loading: false }, () => {
              this.resetReglements();
              this.uncheckAll();
            });
          });
          res.forEach((reglement) => {
            this.createEcriture(
              reglement.reglement_id,
              values.tire,
              reglement.reglement_montant,
              values.date,
              this.props.comptes.find((compte) => compte.compte_compte_general === "480000")
                .compte_id,
              // TODO: Ici, passer de préférence par la quittance -> location -> requete -> locataire
              reglementsLignes.filter(
                (ligne) => ligne.reglement_ligne_reglement_id === reglement.reglement_id
              )[0].locataire_compte_id
            );
            if (!reglement.reglement_bordereau) {
              const element = selectedMontants.find(
                (element) => element.id === reglement.reglement_quittance.quittance_id
              );
              this.createEcriture2(
                reglement.reglement_id,
                reglementsLignes.filter(
                  (ligne) => ligne.reglement_ligne_reglement_id === reglement.reglement_id
                ),
                `${typePaiementObject.type_paiement_libelle} ${
                  parseFloat(values.montant_reglement) < parseFloat(du) ? "Acompte" : "solde"
                } du ${dayjs(values.date).format("DD/MM/YYYY")} (${this.state.nomLocataire})`,
                reglement.reglement_montant,
                reglement.reglement_montant - element?.du,
                values.date,
                this.props.comptes.find(
                  (compte) =>
                    compte.compte_compte_general === journal.journal_compte.compte_compte_general
                ).compte_id,
                // TODO: Ici, passer de préférence par la quittance -> location -> requete -> locataire
                reglementsLignes.filter(
                  (ligne) => ligne.reglement_ligne_reglement_id === reglement.reglement_id
                )[0].locataire_compte_id
              );
            }
          });
        });
      } else {
        this.setState({ loading: false }, () =>
          toast.error("Le jounal sélectionné n'a pas de compte de contrepartie !", {
            containerId: "A",
          })
        );
      }
    } else {
      this.setState({ loading: false }, () =>
        toast.error("Un des règlements est à 0, validation impossible !", {
          containerId: "A",
        })
      );
    }
  };

  createEcriture = (reglementId, libelle, montant, date, cpt1, cpt2) => {
    const ecritureLignes = [];
    const ligneDebit = {
      ecriture_ligne_libelle: libelle,
      ecriture_ligne_montant_debit: montant,
      ecriture_ligne_montant_credit: 0,
      ecriture_ligne_compte_id: cpt1,
      ecriture_ligne_locataire_code: "",
      ecriture_ligne_en_reddition: false,
    };
    const ligneCredit = {
      ecriture_ligne_libelle: libelle,
      ecriture_ligne_montant_debit: 0,
      ecriture_ligne_montant_credit: montant,
      ecriture_ligne_compte_id: cpt2,
      ecriture_ligne_locataire_code: "",
      ecriture_ligne_en_reddition: false,
    };
    ecritureLignes.push(ligneDebit);
    ecritureLignes.push(ligneCredit);
    const ecriture = {
      ecriture_journal_id: this.state.journal,
      ecriture_date_ecriture: dayjs(date).format("DD/MM/YYYY"),
      ecriture_libelle: libelle,
      ecriture_action: `REG${reglementId}`,
      ecriture_ligne: ecritureLignes,
    };
    this.props
      .saveEcriture(ecriture)
      .catch((err) => toast.error("Erreur création écriture !", { containerId: "A" }));
  };

  createEcriture2 = (reglementId, lignes, libelle, montant, montantTropPercu, date, cpt1, cpt2) => {
    const ecritureLignes = [];
    const ligneDebit = {
      ecriture_ligne_libelle: libelle,
      ecriture_ligne_montant_debit: montant,
      ecriture_ligne_montant_credit: 0,
      ecriture_ligne_compte_id: cpt1,
      ecriture_ligne_locataire_code: "",
      ecriture_ligne_en_reddition: false,
    };
    ecritureLignes.push(ligneDebit);
    lignes.forEach((ligne) => {
      const ligneCredit = {
        ecriture_ligne_libelle: libelle,
        ecriture_ligne_montant_debit: 0,
        ecriture_ligne_montant_credit: parseFloat(ligne.reglement_ligne_montant),
        ecriture_ligne_compte_id: ligne.reglement_ligne_quittance_ligne.quittance_ligne_rubrique
          .rubrique_compte_id
          ? ligne.reglement_ligne_quittance_ligne.quittance_ligne_rubrique.rubrique_compte_id
          : ligne.proprietaire_compte_id,
        ecriture_ligne_locataire_code: "",
        ecriture_ligne_en_reddition: false,
      };
      ecritureLignes.push(ligneCredit);
    });
    if (montantTropPercu > 0) {
      const ligneTropPercu = {
        ecriture_ligne_libelle: libelle,
        ecriture_ligne_montant_debit: 0,
        ecriture_ligne_montant_credit: montantTropPercu,
        ecriture_ligne_compte_id: this.props.comptes.find(
          (compte) => compte.compte_compte_general === "467001"
        ).compte_id,
        ecriture_ligne_locataire_code: `${
          this.props.comptes.find((compte) => compte.compte_id === cpt2).compte_compte_auxiliaire
        }`,
        ecriture_ligne_en_reddition: false,
      };
      ecritureLignes.push(ligneTropPercu);
    }
    const ecriture = {
      ecriture_journal_id: this.state.journal,
      ecriture_date_ecriture: dayjs(date).format("DD/MM/YYYY"),
      ecriture_libelle: libelle,
      ecriture_action: `REG${reglementId}`,
      ecriture_ligne: ecritureLignes,
    };
    this.props
      .saveEcriture(ecriture)
      .catch((err) => toast.error("Erreur création écriture !", { containerId: "A" }));
  };

  checkTopPercu = (array, du) => {
    let montantReglement = 0;
    array.length && array.forEach((m) => (montantReglement += m.value));
    this.setState({ tropPercu: montantReglement > du });
  };

  setMontantReglement = (array) => {
    let montantReglement = 0;
    array.length && array.forEach((m) => (montantReglement += parseFloat(m.value)));
    this.formRef.current.setFieldsValue({
      montant_reglement: parseFloat(montantReglement).toFixed(2),
    });
    return parseFloat(montantReglement).toFixed(2);
  };

  reglementChange = (data) => {
    // Set montant state
    this.setState((state) => {
      const quittance = state.reglements.filter((item) => item.quittance_id === data.id);
      state.montants.map((item) => {
        if (item.id === quittance.quittance_id) {
          item.value = data.value;
          item.du = data.du;
        }
        return item;
      });
    });
    // set Montant total
    let newMontants = [];
    const exist =
      this.state.montants.length &&
      this.state.montants.filter((item) => item.id === data.id).length > 0;
    if (exist) {
      const montantsRefresh = this.state.montants.map((item) => {
        if (item.id === data.id) {
          item.value = data.value;
          item.du = data.du;
        }
        return item;
      });
      newMontants = montantsRefresh;
    } else {
      newMontants = [...this.state.montants, data];
    }

    this.setState({ montantReglement: data.value, montants: newMontants });

    const newSelectedRowKeys = parseFloat(data.value) > 0 ? this.checkReglement(data.id) : [];

    const selectedMontants = newMontants.filter((item) => newSelectedRowKeys.includes(item.id));
    this.checkTopPercu(selectedMontants, data.du);
    this.setMontantReglement(selectedMontants);
  };

  checkReglement = (id) => {
    const exist = this.state.selectedRowKeys.filter((item) => item === id).length > 0; // Do not add to array if already exist
    const newSelectedRowKeys = exist
      ? this.state.selectedRowKeys
      : [...this.state.selectedRowKeys, id];
    this.setState({
      selectedRowKeys: newSelectedRowKeys,
    });
    return newSelectedRowKeys;
  };

  uncheckReglement = (id) => {
    const selectedRowKeysFiltered = this.state.selectedRowKeys.filter((item) => item !== id);
    this.setState({ selectedRowKeys: selectedRowKeysFiltered, quittanceLignes: [] });
    return selectedRowKeysFiltered;
  };

  uncheckAll = () => {
    this.setState({ selectedRowKeys: [] });
  };

  resetReglements = () => {
    const newMontants = this.state.montants.map((item) => {
      item.value = null;
      return item;
    });
    this.formRef.current.resetFields([
      "requete_locataire_id",
      "date",
      // "typePaiement",
      "banque",
      "numero_cheque",
      "tire",
      "montant_reglement",
    ]);
    this.handleLocataire(null);
    this.setState({ montants: newMontants, libelle: "", isAcompte: false });
  };

  onChangeTypePaiement = (value) => {
    const typePaiement = this.props.typesPaiements.find(
      (item) => item.type_paiement_id === parseInt(value)
    );
    this.setState(
      { typePaiement: value, hidden: typePaiement.type_paiement_libelle === "Chèque" },
      () => this.onChangeLibelle()
    );

    if (typePaiement.type_paiement_journal) {
      const journal = this.props.journals.find(
        (item) => item.journal_id === typePaiement.type_paiement_journal.journal_id
      );
      journal && this.onChangeJournal(journal.journal_id);
      journal &&
        this.formRef.current.setFieldsValue({
          ecriture_journal_id: journal.journal_id,
        });
    } else {
      const journal = this.props.journals.find((item) => item.journal_de_banque === true);
      this.onChangeJournal(journal.journal_id);
      this.formRef.current.setFieldsValue({
        ecriture_journal_id: journal.journal_id,
      });
    }
  };

  onChangeDateReglement = (value) => {
    this.setState({ dateReglement: value }, () => this.onChangeLibelle());
  };

  onChangeNumeroCheque = (e) => {
    this.setState({ numeroCheque: e.target.value }, () => this.onChangeLibelle());
  };

  onChangeLibelle = () => {
    const { typePaiement, numeroCheque, dateReglement, nomLocataire } = this.state;
    const typePaiementObject = this.props.typesPaiements.find(
      (item) => item.type_paiement_id === parseInt(typePaiement)
    );
    const stringTypePaiement = typePaiementObject.type_paiement_libelle;
    const libelle = `${stringTypePaiement}${
      typePaiementObject.type_paiement_libelle === "Chèque" ? ` n°${numeroCheque}` : ""
    } du ${
      dateReglement ? dayjs(dateReglement, "DD/MM/YYYY").format("DD/MM/YYYY") : "?"
    } (${nomLocataire})`;
    this.formRef.current.setFieldsValue({
      tire: libelle,
    });
    this.setState({ libelle });
  };

  acompte = (quittance) => {
    const data = {
      id: quittance.quittance_id,
      value: quittance.acompte,
      du: quittance.du,
    };
    this.setState({ quittanceLignes: [], isAcompte: true }, () => {
      this.reglementChange(data);
    });
  };

  solde = (quittance) => {
    const data = {
      id: quittance.quittance_id,
      value: quittance.du,
      du: quittance.du,
    };
    this.setState({ quittanceLignes: [], isAcompte: false }, () => {
      this.reglementChange(data);
    });
  };

  getReglement = (quittance_id) => {
    const quittanceLignesReglement = this.state.quittanceLignes.filter(
      (ligne) => ligne.quittance_id === quittance_id
    );
    if (quittanceLignesReglement.length > 0) {
      return quittanceLignesReglement.reduce((a, b) => a + parseFloat(b.montant), 0);
    }
    const montant = this.state.montants.filter((item) => item.id === quittance_id);
    return montant.length && parseFloat(montant[0].value);
  };

  reglement = (quittance, value) => {
    const data = {
      id: quittance.quittance_id,
      value: value,
      du: quittance.du,
    };
    this.reglementChange(data);
    if (value == null || value === "" || value <= 0) {
      this.uncheckReglement(quittance.quittance_id);
    } else {
      this.checkReglement(quittance.quittance_id);
    }
    this.setState({ quittanceLignes: [], isAcompte: false });
  };

  onChangeQuittanceLigne = (quittance_ligne, value) => {
    if (!value || value < 0) value = 0;
    const max = parseFloat(
      quittance_ligne.quittance_ligne_montant - quittance_ligne.quittance_ligne_regle
    );
    const ligneExiste = this.state.quittanceLignes.find(
      (l) => l.quittance_ligne_id === quittance_ligne.quittance_ligne_id
    );
    if (ligneExiste) {
      const newQuittanceLignes = this.state.quittanceLignes.map((l) => {
        if (l.quittance_ligne_id === quittance_ligne.quittance_ligne_id) {
          l.montant = parseFloat(value) > max ? max : parseFloat(value);
        }
        return l;
      });
      this.setState({ quittanceLignes: newQuittanceLignes }, () => {
        this.reglementChange({
          id: quittance_ligne.quittance_ligne_quittance_id,
          value: this.state.quittanceLignes.reduce((a, b) => a + parseFloat(b.montant), 0),
          du: this.state.quittanceLignes.reduce((a, b) => a + parseFloat(b.du), 0),
        });
      });
    } else {
      const ligne = {
        quittance_id: quittance_ligne.quittance_ligne_quittance_id,
        quittance_ligne_id: quittance_ligne.quittance_ligne_id,
        montant: parseFloat(value) > max ? max : parseFloat(value),
        quittance_ligne_quantite: quittance_ligne.quittance_ligne_quantite,
        quittance_ligne_rubrique: quittance_ligne.quittance_ligne_rubrique,
        du: max,
      };
      this.setState({ quittanceLignes: [...this.state.quittanceLignes, ligne] }, () => {
        this.reglementChange({
          id: quittance_ligne.quittance_ligne_quittance_id,
          value: this.state.quittanceLignes.reduce((a, b) => a + parseFloat(b.montant), 0),
          du: this.state.quittanceLignes.reduce((a, b) => a + parseFloat(b.du), 0),
        });
      });
    }
  };

  getMontantLigne = (quittance_ligne_id) => {
    const ligne = this.state.quittanceLignes.find(
      (item) => item.quittance_ligne_id === quittance_ligne_id
    );
    return ligne ? ligne.montant : 0;
  };

  acompteLigne = (ligne) => {
    this.onChangeQuittanceLigne(ligne, ligne.quittance_ligne_acompte);
  };

  soldeLigne = (ligne) => {
    this.onChangeQuittanceLigne(ligne, ligne.quittance_ligne_montant);
  };

  handleLocataire = (idLocataire) => {
    this.setState({ idLocataire });
    this.onSelectLocataire(idLocataire);
  };

  handleOpenChange = (newOpen) => {
    if (!newOpen) {
      this.setState({ popConfirmOpen: newOpen });
      return;
    }

    if (!this.state.tropPercu) {
      this.popConfirmConfirm();
    } else {
      this.setState({ popConfirmOpen: newOpen });
    }
  };

  handleChangeExpand = (expand, row) => {
    const newRows = this.state.reglements.map((item) => {
      if (item.quittance_id === row.quittance_id) {
        item.expand = expand;
      }
      return item;
    });
    const data = {
      id: row.quittance_id,
      value: "0",
      du: row.du,
    };
    expand ? this.checkReglement(row.quittance_id) : this.uncheckReglement(row.quittance_id);
    this.reglementChange(data);
    this.setState({ reglements: newRows });
  };

  popConfirmConfirm = () => {
    this.formRef.current.submit();
    this.setState({ popConfirmOpen: false });
  };

  popConfirmCancel = () => {
    this.setState({ popConfirmOpen: false });
  };

  render() {
    const { societes, journals, locataires, typesPaiements } = this.props;
    const {
      societe,
      journal,
      montants,
      reglements,
      selectedRowKeys,
      hidden,
      nomLocataire,
      dateReglement,
      typePaiement,
      numeroCheque,
      libelle,
      montantReglement,
      popConfirmOpen,
      loading,
    } = this.state;

    const reglementsActif = reglements.filter((regl) => regl.du > 0);

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys, selectedRows });
        const selectedMontants = montants.filter((item) => selectedRowKeys.includes(item.id));
        this.setMontantReglement(selectedMontants);
      },
      selectedRowKeys: selectedRowKeys,
    };

    const expandedRowRender = (row) => {
      return (
        <Table dataSource={row.quittance_ligne} pagination={false} rowKey="quittance_ligne_id">
          <Column
            title="Rubriques"
            dataIndex="quittance_ligne_libelle"
            key="quittance_ligne_libelle"
            render={(libelle, quittance_ligne) =>
              libelle ? libelle : quittance_ligne.quittance_ligne_rubrique.rubrique_libelle
            }
          />
          <Column
            title="Type"
            dataIndex="quittance_ligne_rubrique"
            key="quittance_ligne_rubrique"
            render={(quittance_ligne_rubrique) => quittance_ligne_rubrique.rubrique_type}
          />
          <Column
            title="Signe"
            dataIndex="quittance_ligne_rubrique"
            key="quittance_ligne_rubrique"
            render={(quittance_ligne_rubrique) => quittance_ligne_rubrique.rubrique_signe}
          />
          <Column
            title="Montant dû"
            dataIndex="quittance_ligne_montant"
            key="quittance_ligne_montant"
          />
          <Column
            title="Montant Acompte"
            dataIndex="quittance_ligne_acompte"
            key="quittance_ligne_acompte"
          />
          <Column
            title="Montant déjà Réglé"
            dataIndex="quittance_ligne_regle"
            key="quittance_ligne_regle"
          />
          <Column
            title="Règlement"
            key="montant"
            render={(quittance_ligne) => (
              <InputNumber
                onChange={(value) => this.onChangeQuittanceLigne(quittance_ligne, value)}
                value={this.getMontantLigne(quittance_ligne.quittance_ligne_id)}
              />
            )}
          />
          <Column
            title="Acompte"
            key="acompte"
            render={(quittance_ligne) => (
              <Button
                icon={<ArrowLeftOutlined />}
                onClick={() => this.acompteLigne(quittance_ligne)}
              />
            )}
          />
          <Column
            title="Solde Total"
            key="solde"
            render={(quittance_ligne) => (
              <Button
                icon={<ArrowLeftOutlined />}
                onClick={() => this.soldeLigne(quittance_ligne)}
              />
            )}
          />
        </Table>
      );
    };

    const onExpand = (expanded, row) => {
      this.handleChangeExpand(expanded, row);
    };

    return (
      <Form ref={this.formRef} onFinish={this.onFinishReglement} style={{ padding: "20px" }}>
        <Row gutter={10}>
          <Col span={12}>
            <CustomSelect
              label="Société"
              inputName="societe"
              formItemName="societe_id"
              objectValue={societe}
              onChange={(id) => this.onChangeSociete(id)}
            >
              <OptGroup label="Société">
                {societes &&
                  societes.map((item, i) => {
                    return (
                      <Option key={i} value={item.societe_id} label={item.societe_raison_sociale}>
                        <Row>
                          <Col sm={8}>{item.societe_raison_sociale}</Col>
                        </Row>
                      </Option>
                    );
                  })}
              </OptGroup>
            </CustomSelect>
          </Col>
          <Col span={12}>
            <CustomSelect
              label="Journal"
              inputName="ecriture_journal_id"
              formItemName="ecriture_journal_id"
              objectValue={journal}
              onChange={(id) => this.onChangeJournal(id)}
            >
              <OptGroup label="Journal">
                {journals &&
                  journals.map((item, i) => {
                    return (
                      <Option
                        key={i}
                        value={item.journal_id}
                        label={item.journal_code + " - " + item.journal_libelle}
                      >
                        <Row>
                          <Col sm={8}>
                            {item.journal_code} - {item.journal_libelle}
                          </Col>
                        </Row>
                      </Option>
                    );
                  })}
              </OptGroup>
            </CustomSelect>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CustomSelect
              label="Locataire"
              inputName="requete_locataire_id"
              formItemName="requete_locataire_id"
              onSelect={this.handleLocataire}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              objectValue={nomLocataire}
            >
              <OptGroup label="Locataire">
                {locataires &&
                  locataires.map((item, i) => {
                    return (
                      <Option
                        key={i}
                        value={item.locataire_id}
                        label={getInfoLocataireSimple(item)}
                      >
                        <Row>
                          <Col sm={8}>{getInfoLocataireSimple(item)}</Col>
                        </Row>
                      </Option>
                    );
                  })}
              </OptGroup>
            </CustomSelect>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={6}>
            <CustomDatePicker
              label="Date"
              inputName="date"
              formItemName="date"
              objectValue={dateReglement}
              onChange={this.onChangeDateReglement}
              rules={{ required: true, message: "Date obligatoire" }}
            />
          </Col>
          <Col span={6}>
            <CustomSelect
              label="Type de paiement"
              inputName="typePaiement"
              formItemName="typePaiement"
              onChange={this.onChangeTypePaiement}
              objectValue={typePaiement}
            >
              <OptGroup label="Type de paiement">
                {typesPaiements &&
                  typesPaiements
                    .sort((a, b) => a.type_paiement_id - b.type_paiement_id)
                    .map((item, i) => {
                      return (
                        <Option
                          key={i}
                          value={item.type_paiement_id}
                          label={item.type_paiement_libelle}
                        >
                          <Row>
                            <Col sm={8}>{item.type_paiement_libelle}</Col>
                          </Row>
                        </Option>
                      );
                    })}
              </OptGroup>
            </CustomSelect>
          </Col>
          {hidden && (
            <Col span={6}>
              <CustomInput
                label="Banque"
                inputName="banque"
                formItemName="banque"
                objectValue={null}
              />
            </Col>
          )}
          {hidden && (
            <Col span={6}>
              <CustomInput
                label="Numéro chèque"
                inputName="numero_cheque"
                formItemName="numero_cheque"
                objectValue={numeroCheque}
                onChange={this.onChangeNumeroCheque}
              />
            </Col>
          )}
        </Row>
        <Row gutter={10}>
          <Col md={12}>
            <CustomInput
              label="Libellé"
              inputName="tire"
              formItemName="tire"
              objectValue={libelle}
            />
          </Col>
          <Col md={12}>
            <CustomInput
              label="Montant règlement"
              inputName="montant_reglement"
              formItemName="montant_reglement"
              type="number"
              readOnly={true}
              objectValue={montantReglement}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Table
            dataSource={reglementsActif}
            expandable={{ expandedRowRender, onExpand }}
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            rowKey="quittance_id"
            scroll={{ y: 512 }}
            size="small"
          >
            <Column title="N° Quittance" dataIndex="quittance_id" key="quittance_id" />
            <Column title="N° Contrat" dataIndex="location_id" key="location_id" />
            <Column title="Appartement" dataIndex="appartement" key="appartement" />
            <Column title="Date" dataIndex="date" key="date" />
            <Column title="Arrivée" dataIndex="date_debut" key="date_debut" />
            <Column title="Départ" dataIndex="date_fin" key="date_fin" />
            <Column title="Montant Total" dataIndex="total" key="total" />
            <Column title="Mt Acompte" dataIndex="acompte" key="acompte" />
            <Column title="Montant réglé" dataIndex="paye" key="paye" />
            <Column title="Solde dû" dataIndex="du" key="du" />
            <Column
              title="Règlement"
              dataIndex="quittance_id"
              key="reglement"
              render={(quittance_id, quittance) => (
                <InputNumber
                  onChange={(value) => this.reglement(quittance, value)}
                  value={this.getReglement(quittance.quittance_id)}
                  disabled={quittance.expand}
                />
              )}
            />
            <Column
              title="Acompte"
              dataIndex="quittance_id"
              key="quittance_id"
              render={(quittance_id, quittance) => (
                <Button
                  icon={<ArrowLeftOutlined />}
                  onClick={() => this.acompte(quittance)}
                  disabled={quittance.expand}
                />
              )}
            />
            <Column
              title="Solde Total"
              dataIndex="quittance_id"
              key="quittance_id"
              render={(quittance_id, quittance) => (
                <Button
                  icon={<ArrowLeftOutlined />}
                  onClick={() => this.solde(quittance)}
                  disabled={quittance.expand}
                />
              )}
            />
          </Table>
        </Row>
        <Row style={{ marginTop: "10px", float: "right" }}>
          <Popconfirm
            title={
              <div>
                <span>Attention, montant saisi supérieur au montant de la location ! </span>
                <br />
                <span>
                  A la validation, cette différence sera créditée sur le compte de Trop perçu
                  (467001)
                </span>
                <br />
                <span>Etes vous sûr de vouloir confirmer cet encaissement ?</span>
              </div>
            }
            open={popConfirmOpen}
            onOpenChange={this.handleOpenChange}
            onConfirm={this.popConfirmConfirm}
            onCancel={this.popConfirmCancel}
            okText="Oui"
            cancelText="Non"
            disabled={!selectedRowKeys.length > 0}
          >
            <Button loading={loading} type="primary" disabled={!selectedRowKeys.length > 0}>
              Valider
            </Button>
          </Popconfirm>
        </Row>
      </Form>
    );
  }
}

export default FormReglement;

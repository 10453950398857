import { adaptedToLot, lotToAdapted, lotsToAdapted } from "../../adapter/lotAdapter";
import { HttpMethod, initFetch } from "../../utils/fetcher";

export const LOAD_SUCCESS_LOTS = "LOAD_SUCCESS_LOTS";
export const LOAD_FAIL_LOTS = "LOAD_FAIL_LOTS";
export const SAVE_SUCCESS_LOTS = "SAVE_SUCCESS_LOTS";
export const SAVE_FAIL_LOTS = "SAVE_FAIL_LOTS";
export const UPDATE_SUCCESS_LOTS = "UPDATE_SUCCESS_LOTS";
export const UPDATE_FAIL_LOTS = "UPDATE_FAIL_LOTS";
export const DELETE_SUCCESS_LOTS = "DELETE_SUCCESS_LOTS";
export const DELETE_FAIL_LOTS = "DELETE_FAIL_LOTS";

export function loadLots() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`lots`, HttpMethod.GET);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.lot);
          dispatch({
            type: LOAD_SUCCESS_LOTS,
            payload: lotsToAdapted(res.lot),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_LOTS,
            error: err,
          });
        });
    });
  };
}

export function saveLot(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`lots`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToLot(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(lotToAdapted(res.lot));
          dispatch({
            type: SAVE_SUCCESS_LOTS,
            payload: lotToAdapted(res.lot),
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_LOTS,
            error: err,
          })
        );
    });
  };
}

export function updateLot(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`lots/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToLot(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(lotToAdapted(res.lot));
          dispatch({
            type: UPDATE_SUCCESS_LOTS,
            payload: lotToAdapted(res.lot),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_LOTS,
            error: err,
          })
        );
    });
  };
}

export function updateLotNuitees(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`lots/paste/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToLot(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.lot);
          dispatch({
            type: UPDATE_SUCCESS_LOTS,
            payload: lotToAdapted(res.lot),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_LOTS,
            error: err,
          })
        );
    });
  };
}

export function archiverLot(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`lots/archiver/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, params)
        .then((res) => res.json())
        .then((res) => {
          resolve(res.lot);
          dispatch({
            type: UPDATE_SUCCESS_LOTS,
            payload: lotToAdapted(res.lot),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_LOTS,
            error: err,
          })
        );
    });
  };
}

export function desarchiverLot(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`lots/desarchiver/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, params)
        .then((res) => res.json())
        .then((res) => {
          resolve(res.lot);
          dispatch({
            type: UPDATE_SUCCESS_LOTS,
            payload: lotToAdapted(res.lot),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_LOTS,
            error: err,
          })
        );
    });
  };
}

export function deleteLot(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`lots/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.lot);
          dispatch({
            type: DELETE_SUCCESS_LOTS,
            payload: lotToAdapted(res.lot),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_LOTS,
            error: err,
          });
        });
    });
  };
}

import React, { Component } from "react";
import { connect } from "react-redux";

import { saveSociete, updateSociete, deleteSociete } from "../../store/actions/societeAction";
import {
  saveEtablissement,
  updateEtablissement,
  deleteEtablissement,
} from "../../store/actions/etablissementAction";

import { Card } from "antd";
import SelectOrganisation from "../../components/SelectOrganisation";

import { toast } from "react-toastify";

class Personnes extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleSubmitSociete = (data, numClient) => {
    data.societe_num_client = numClient;
    this.props
      .saveSociete(data)
      .then((res) => {
        toast.success("Société créée !", { containerId: "A" });
      })
      .catch((err) => {
        toast.error("Échec de la création !", { containerId: "A" });
      });
  };

  handleEditSociete = (data, id, numClient) => {
    data.societe_num_client = numClient;
    this.props
      .updateSociete(data, id)
      .then((res) => {
        toast.success("Société éditée !", { containerId: "A" });
      })
      .catch((err) => {
        toast.error("Échec de l'édition !", { containerId: "A" });
      });
  };

  handleDeleteSociete = (id) => {
    this.props
      .deleteSociete(id)
      .then((res) => {
        toast.success("Société supprimée !", { containerId: "A" });
      })
      .catch((err) => {
        toast.error("Échec de la suppression !", { containerId: "A" });
      });
  };

  handleSubmitEtablissement = (data) => {
    this.props
      .saveEtablissement(data)
      .then((res) => {
        toast.success("Établissement créé !", { containerId: "A" });
      })
      .catch((err) => {
        toast.error("Échec de la création !", { containerId: "A" });
      });
  };

  handleEditEtablissement = (data, id) => {
    this.props
      .updateEtablissement(data, id)
      .then((res) => {
        toast.success("Établissement édité !", { containerId: "A" });
      })
      .catch((err) => {
        toast.error("Échec de l'édition !", { containerId: "A" });
      });
  };

  handleDeleteEtablissement = (id) => {
    this.props
      .deleteEtablissement(id)
      .then((res) => {
        toast.success("Établissement supprimé !", { containerId: "A" });
      })
      .catch((err) => {
        toast.error("Échec de la suppression !", { containerId: "A" });
      });
  };

  render() {
    const { societe, societes, etablissements } = this.props;

    societes.data.sort((a, b) => {
      return a.societe_id - b.societe_id;
    });

    const societesFilter = societes.data.filter((s) => s.societe_id === etablissements.data[0].etablissement_societe.societe_id);

    etablissements.data.sort((a, b) => {
      return a.etablissement_id - b.etablissement_id;
    });

    return (
      <div>
        <Card title="" bordered={false} className="card-panel">
          <SelectOrganisation
            societe={societe}
            listData={societe ? societesFilter : etablissements.data}
            societes={societes.data}
            handleSubmitSociete={this.handleSubmitSociete}
            handleEditSociete={this.handleEditSociete}
            handleDeleteSociete={this.handleDeleteSociete}
            handleSubmitEtablissement={this.handleSubmitEtablissement}
            handleEditEtablissement={this.handleEditEtablissement}
            handleDeleteEtablissement={this.handleDeleteEtablissement}
          />
        </Card>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveSociete: (jsonData) => dispatch(saveSociete(jsonData)),
    updateSociete: (jsonData, id) => dispatch(updateSociete(jsonData, id)),
    deleteSociete: (id) => dispatch(deleteSociete(id)),
    saveEtablissement: (jsonData) => dispatch(saveEtablissement(jsonData)),
    updateEtablissement: (jsonData, id) => dispatch(updateEtablissement(jsonData, id)),
    deleteEtablissement: (id) => dispatch(deleteEtablissement(id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    societes: state.societes,
    etablissements: state.etablissements,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Personnes);

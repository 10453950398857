import { journalToAdapted } from "./journalAdapter";

export function typePaiementsToAdapted(items) {
  return items
    ? items.map((item) => {
        return typePaiementToAdapted(item);
      })
    : null;
}

export function typePaiementToAdapted(item) {
  //Resource
  return item
    ? {
        type_paiement_id: item.id,
        type_paiement_libelle: item.libelle,
        type_paiement_groupement_id: item.groupement_id,
        type_paiement_compte_bancaire: item.compte_bancaire,
        type_paiement_bordereau: item.bordereau,
        type_paiement_journal: journalToAdapted(item.journal),
      }
    : null;
}

export function adaptedToTypePaiement(item) {
  //Model
  return item
    ? {
        id: item.type_paiement_id,
        libelle: item.type_paiement_libelle,
        groupement_id: item.type_paiement_groupement_id,
        compte_bancaire: item.type_paiement_compte_bancaire,
        bordereau: item.type_paiement_bordereau,
        journal_id: item.type_paiement_journal_id,
      }
    : null;
}

import React, { useEffect, useState } from "react";
import { Button, Col, DatePicker, Input, Row, Select, Table, Typography, Modal } from "antd";
import ReactDOMServer from "react-dom/server";

import { toast } from "react-toastify";

import BordereauPDF from "../Pdf/Comptabilite/BordereauPDF";

import dayjs from "dayjs";
import { HttpMethod, initFetch } from "../../utils/fetcher";
import { openPdf } from "../../utils/utils";

const dateFormat = "DD/MM/YYYY";

const { Option, OptGroup } = Select;
const { Text } = Typography;
const { Title } = Typography;

const TableValidationBordereau = (props) => {
  const [typePaiement, setTypePaiement] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [reglementsAValider, setReglementsAValider] = useState([]);
  const [total, setTotal] = useState(0);
  const [dateRemise, setDateRemise] = useState(null);
  const [numeroRemise, setNumeroRemise] = useState("");
  const [idPdf, setIdPdf] = useState(0);
  const { reglements, comptes, locations, typesPaiements, societes, journals } = props;
  const [modalPdf, setModalPdf] = useState(false);

  useEffect(() => {
    typePaiement
      ? setReglementsAValider(
          reglements.filter(
            (item) => item.reglement_type_paiement.type_paiement_id === parseInt(typePaiement)
          )
        )
      : setReglementsAValider([]);
  }, [reglements, typePaiement]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      getTotal(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    selectedRowKeys: selectedRowKeys,
    selectedRows: selectedRows,
  };

  const getTotal = (selectedRows) => {
    let totalSelectedRows = 0;
    selectedRows.forEach((row) => {
      totalSelectedRows += parseFloat(row.reglement_montant);
    });
    setTotal(totalSelectedRows);
  };

  const onChangeDate = (e) => {
    setDateRemise(e ? e : dayjs());
  };

  const handleValidation = () => {
    createEcriture(selectedRows, total);
    createPdf(selectedRows, total);
  };

  const handleEdition = () => {
    createPdf(selectedRows, total);
  };

  const createEcriture = (reglements, montant) => {
    const ecritureLignes = [];
    const libelle = `Validation bordereau n°${numeroRemise}`;
    const ligneDebit = {
      ecriture_ligne_libelle: libelle,
      ecriture_ligne_montant_debit: montant,
      ecriture_ligne_montant_credit: 0,
      ecriture_ligne_compte_id: reglements[0].reglement_journal.journal_compte.compte_id,
      ecriture_ligne_locataire_code: "",
      ecriture_ligne_en_reddition: false,
    };
    ecritureLignes.push(ligneDebit);
    reglements.forEach((reglement) => {
      const location = locations.find(
        (item) => item.location_id === reglement.reglement_quittance.quittance_location_id
      );

      const montantTropPercu = getMontantTropPercu(reglement);
      reglement.reglement_ligne.forEach((ligne) => {
        const ligneCredit = {
          ecriture_ligne_libelle: `${location.location_requete?.requete_locataire?.locataire_personne?.personne_nom} ${location.location_requete?.requete_locataire?.locataire_personne?.personne_prenom}`,
          ecriture_ligne_montant_debit: 0,
          ecriture_ligne_montant_credit: parseFloat(ligne.reglement_ligne_montant),
          ecriture_ligne_compte_id: ligne.reglement_ligne_quittance_ligne_id
            .quittance_ligne_rubrique.rubrique_compte_id
            ? ligne.reglement_ligne_quittance_ligne_id.quittance_ligne_rubrique.rubrique_compte_id
            : location.location_lot.lot_proprietaire.proprietaire_affectations[0]
                .proprietaire_affectation_compte_id,
          ecriture_ligne_locataire_code: "",
          ecriture_ligne_en_reddition: false,
        };
        ecritureLignes.push(ligneCredit);
      });
      if (montantTropPercu > 0) {
        const ligneTropPercu = {
          ecriture_ligne_libelle: libelle,
          ecriture_ligne_montant_debit: 0,
          ecriture_ligne_montant_credit: montantTropPercu,
          ecriture_ligne_compte_id: comptes.find(
            (compte) => compte.compte_compte_general === "467001"
          ).compte_id,
          ecriture_ligne_locataire_code: "",
          ecriture_ligne_en_reddition: false,
        };
        ecritureLignes.push(ligneTropPercu);
      }
    });
    const ecriture = {
      ecriture_journal_id: reglements[0].reglement_journal.journal_id,
      ecriture_date_ecriture: dateRemise
        ? dateRemise.format("DD/MM/YYYY")
        : dayjs().format("DD/MM/YYYY"),
      ecriture_libelle: libelle,
      ecriture_action: `VRL`,
      ecriture_ligne: ecritureLignes,
    };
    props
      .saveEcriture(ecriture)
      .then((res) => {
        updateReglements(reglements);
        setSelectedRows([]);
        setSelectedRowKeys([]);
        toast.success("Écriture créée !", { containerId: "A" });
      })
      .catch((err) => toast.error("Erreur création écriture !", { containerId: "A" }));
  };

  const getMontantTropPercu = (reglement) => {
    let total = 0;
    reglement.reglement_ligne.forEach((ligne) => {
      total += parseFloat(ligne.reglement_ligne_montant);
    });
    return parseFloat(reglement.reglement_montant - total);
  };

  const updateReglements = (reglements) => {
    reglements.forEach((reglement) => {
      reglement.reglement_bordereau = false;
      reglement.reglement_type_paiement_id = reglement.reglement_type_paiement.type_paiement_id;
      reglement.reglement_quittance_id = reglement.reglement_quittance.quittance_id;
      reglement.reglement_journal_id = reglement.reglement_journal.journal_id;
      props.updateReglement(reglement, reglement.reglement_id);
    });
  };

  const createPdf = (reglements) => {
    const html = ReactDOMServer.renderToStaticMarkup(
      <BordereauPDF
        reglements={reglements}
        numRemise={numeroRemise}
        dateRemise={dateRemise}
        societes={societes}
        comptes={comptes}
        journals={journals}
      />
    );
    const pdf = {
      titre: "Bordereau",
      destinataire: "",
      pdf_page: [],
    };
    const pdfPage = {
      html: html,
    };
    pdf.pdf_page.push(pdfPage);
    const { url, params } = initFetch(`pdfs`, HttpMethod.POST);
    fetch(url, { ...params, body: JSON.stringify(pdf) })
      .then(function (response) {
        return response.json();
      })
      .then(function (result) {
        setIdPdf(result.pdf.id);
        setModalPdf(true);
      });
  };

  const closeModal = () => {
    setModalPdf(false);
  };

  const columns = [
    {
      title: "Date",
      key: "reglement_date",
      render: (record) => dayjs(record.reglement_date, "YYYY-MM-DD").format("DD/MM/YYYY"),
      width: "10%",
    },
    {
      title: "Libelle",
      key: "",
      render: (record) => record.reglement_tire,
      width: "70%",
    },
    {
      title: "Montant",
      key: "reglement_montant",
      render: (record) =>
        parseFloat(record.reglement_montant).toLocaleString("fr-FR", {
          style: "currency",
          currency: "EUR",
        }),
      align: "right",
      width: "20%",
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Modal footer={null} title="Bordereau" open={modalPdf} onCancel={closeModal}>
        {idPdf && (
          <Button type="text" onClick={() => openPdf(idPdf)}>
            Édition du bordereau
          </Button>
        )}
      </Modal>
      <Row>
        <Title level={2}>
          <strong>Sélection des encaissements</strong>
        </Title>
      </Row>
      <Row>
        <Col span={6}>
          <Select
            showSearch
            optionFilterProp="label"
            optionLabelProp="label"
            allowClear
            placeholder="Types de paiements"
            style={{ width: "100%" }}
            onChange={(id) => setTypePaiement(id)}
          >
            <OptGroup label="Type de paiement">
              {typesPaiements &&
                typesPaiements.map((item, i) => {
                  return (
                    <Option
                      key={i}
                      value={item.type_paiement_id}
                      label={item.type_paiement_libelle}
                    >
                      <Row>
                        <Col sm={8}>{item.type_paiement_libelle}</Col>
                      </Row>
                    </Option>
                  );
                })}
            </OptGroup>
          </Select>
        </Col>
      </Row>
      <br />
      <br />
      <Row gutter={10}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={reglementsAValider}
            pagination={false}
            rowKey="reglement_id"
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            scroll={{ y: 450 }}
            size="small"
            showSorterTooltip={false}
            //title={() => "Sélection des encaissements"}
            summary={() => {
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text style={{ float: "right" }}>
                        <strong>TOTAL :</strong>
                      </Text>
                    </Table.Summary.Cell>

                    <Table.Summary.Cell>
                      <Text style={{ float: "right" }}>
                        <strong>
                          {parseFloat(total).toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          })}
                        </strong>
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={14}></Col>
        <Col span={3} align="right">
          <DatePicker
            format={dateFormat}
            placeholder="Date de la remise"
            style={{ marginTop: 20 }}
            // defaultValue={dayjs()}
            onChange={(e) => onChangeDate(e)}
          />
        </Col>
        <Col span={3} align="right">
          <Input
            placeholder="Numéro de remise"
            onChange={(e) => setNumeroRemise(e.target.value)}
            style={{ marginTop: 20 }}
          />
        </Col>
        <Col span={2} align="right">
          <Button type="primary" style={{ marginTop: 20 }} onClick={() => handleEdition()}>
            Edition bordereau
          </Button>
        </Col>
        <Col span={2} align="right">
          <Button
            type="primary"
            disabled={selectedRows.length === 0}
            style={{ marginTop: 20 }}
            onClick={() => handleValidation()}
          >
            Validation
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default TableValidationBordereau;

import React, { Component } from "react";
import { connect } from "react-redux";

import { Card, Spin } from "antd";
import ListPersonne from "../../components/ListPersonne";
import { columnsPro, columnsLoc } from "./columns";
import { loadProprietaires } from "../../store/actions/proprietaireAction";
import { loadLocataires } from "../../store/actions/locataireAction";

class PersonnesListe extends Component {
  state = {};

  componentDidMount() {
    const groupement_id = this.props.utilisateur.groupement_id;
    this.props.proprietaire
      ? this.props.loadProprietaires(groupement_id)
      : this.props.loadLocataires(groupement_id);
  }

  render() {
    const { proprietaire, proprietaires, locataires } = this.props;

    return (
      <Spin
        size="large"
        tip="Chargement en cours..."
        spinning={proprietaire ? !proprietaires : !locataires}
      >
        <Card title="" bordered={false} className="card-panel">
          <ListPersonne
            proprietaire={proprietaire}
            //listData={proprietaire ? proprietaires : locataires}
            tableOption={proprietaire ? columnsPro : columnsLoc}
            listData={
              proprietaire
                ? proprietaires &&
                  proprietaires.filter((pro) => (pro.proprietaire_personne.personne_actif = true))
                               .sort((a, b) => a.proprietaire_code.localeCompare(b.proprietaire_code))
                : locataires &&
                  locataires.filter((loc) => loc.locataire_personne.personne_actif === true)
                            .sort((a, b) => a.locataire_code.localeCompare(b.locataire_code))
            }
          />
        </Card>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadProprietaires: (groupement_id) => dispatch(loadProprietaires(groupement_id)),
    loadLocataires: (groupement_id) => dispatch(loadLocataires(groupement_id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    proprietaires: state.proprietaires.data,
    locataires: state.locataires.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonnesListe);

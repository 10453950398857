export function adresseToAdapted(item) {
  return item
    ? {
        adresse_id: item.id,
        adresse_numero: item.numero,
        adresse_voie: item.voie,
        adresse_code_postal: item.code_postal,
        adresse_ville: item.ville,
        adresse_secteur: item.secteur,
        adresse_departement: item.departement,
        adresse_region: item.region,
        adresse_pays: item.pays,
        adresse_gmaps_id: item.gmaps_id,
        adresse_gmaps_lat: item.gmaps_lat,
        adresse_gmaps_lng: item.gmaps_lng,
        adresse_gmaps_adresse: item.gmaps_adresse,
        adresse_situation: item.situation,
      }
    : null;
}

export function adaptedToAdresse(item) {
  return item
    ? {
        numero: item.adresse_numero,
        voie: item.adresse_voie,
        code_postal: item.adresse_code_postal,
        ville: item.adresse_ville,
        secteur: item.adresse_secteur,
        departement: item.adresse_departement,
        region: item.adresse_region,
        pays: item.adresse_pays,
        gmaps_id: item.adresse_gmaps_id,
        gmaps_lat: item.adresse_gmaps_lat,
        gmaps_lng: item.adresse_gmaps_lng,
        gmaps_adresse: item.adresse_gmaps_adresse,
        situation: item.adresse_situation,
      }
    : null;
}

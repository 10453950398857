import { compteToAdapted } from "./compteAdapter";

export function rapprochementsBancairesToAdapted(items) {
  return items
    ? items.map((item) => {
        return rapprochementBancaireToAdapted(item);
      })
    : null;
}

export function rapprochementBancaireToAdapted(item) {
  return item
    ? {
        rapprochement_bancaire_id: item.id,
        rapprochement_bancaire_date_releve: item.date_releve,
        rapprochement_bancaire_montant_releve: item.montant_releve,
        rapprochement_bancaire_date_validation: item.date_validation,
        rapprochement_bancaire_commentaire: item.commentaire,
        rapprochement_bancaire_compte: compteToAdapted(item.compte),
      }
    : null;
}

export function adaptedToRapprochementBancaire(item) {
  return item
    ? {
        compte_id: item.rapprochement_bancaire_compte_id,
        date_releve: item.rapprochement_bancaire_date_releve,
        montant_releve: item.rapprochement_bancaire_montant_releve,
        date_validation: item.rapprochement_bancaire_date_validation,
        commentaire: item.rapprochement_bancaire_commentaire,
      }
    : null;
}

import {
  adaptedToCodeCouleur,
  codeCouleurToAdapted,
  codesCouleursToAdapted,
} from "../../adapter/codeCouleurAdapter";
import { HttpMethod, initFetch } from "../../utils/fetcher";

export const LOAD_CODES_COULEURS = "LOAD_CODES_COULEURS";
export const LOAD_SUCCESS_CODES_COULEURS = "LOAD_SUCCESS_CODES_COULEURS";
export const LOAD_FAIL_CODES_COULEURS = "LOAD_FAIL_CODES_COULEURS";
export const SAVE_SUCCESS_CODES_COULEURS = "SAVE_SUCCESS_CODES_COULEURS";
export const SAVE_FAIL_CODES_COULEURS = "SAVE_FAIL_CODES_COULEURS";
export const UPDATE_SUCCESS_CODES_COULEURS = "UPDATE_SUCCESS_CODES_COULEURS";
export const UPDATE_FAIL_CODES_COULEURS = "UPDATE_FAIL_CODES_COULEURS";
export const DELETE_SUCCESS_CODES_COULEURS = "DELETE_SUCCESS_CODES_COULEURS";
export const DELETE_FAIL_CODES_COULEURS = "DELETE_FAIL_CODES_COULEURS";

export function loadCodesCouleurs() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`codesCouleurs`, HttpMethod.GET);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.code_couleur);
          dispatch({
            type: LOAD_SUCCESS_CODES_COULEURS,
            payload: codesCouleursToAdapted(res.code_couleur),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_CODES_COULEURS,
            error: err,
          });
        });
    });
  };
}

export function saveCodeCouleur(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`codesCouleurs`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToCodeCouleur(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(codeCouleurToAdapted(res.code_couleur));
          dispatch({
            type: SAVE_SUCCESS_CODES_COULEURS,
            payload: codeCouleurToAdapted(res.code_couleur),
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_CODES_COULEURS,
            error: err,
          })
        );
    });
  };
}

export function updateCodeCouleur(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`codesCouleurs/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToCodeCouleur(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(codeCouleurToAdapted(res.code_couleur));
          dispatch({
            type: UPDATE_SUCCESS_CODES_COULEURS,
            payload: codeCouleurToAdapted(res.code_couleur),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_CODES_COULEURS,
            error: err,
          })
        );
    });
  };
}

export function deleteCodeCouleur(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`codesCouleurs/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.code_couleur);
          dispatch({
            type: DELETE_SUCCESS_CODES_COULEURS,
            payload: codeCouleurToAdapted(res.code_couleur),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_CODES_COULEURS,
            error: err,
          });
        });
    });
  };
}

// DEPENDENCY
import { Input, Popconfirm } from 'antd';
import React, { Component } from 'react'

export default class ColumnAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.defaultValue || undefined,
        }
    }

    // HANDLER
    handleValueChange = value => {
        this.setState({ value: value });
    }

    handleCustomGroupChange = e => {
        e.stopPropagation();
        const value = this.state.value;
        const record = this.props.record;
        value && this.props.onOk(value, record);
    }

    handle = e => {
        e.stopPropagation();
    }

    render() {
        const { defaultValue } = this.props;
        return (
            <Popconfirm
                onConfirm={e => this.handleCustomGroupChange(e)}
                {...this.props}
            >
                <Input defaultValue={defaultValue} onChange={e => this.handleValueChange(e.target.value)} onClick={this.handle} />
            </Popconfirm>
        )
    }
}

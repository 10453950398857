import { langueToAdapted } from "./langueAdapter";

export function descriptionsToAdapted(items) {
  return items
    ? items.map((item) => {
        return descriptionToAdapted(item);
      })
    : null;
}

export function descriptionToAdapted(item) {
  return item
    ? {
        description_langue: langueToAdapted(item.langue),
        description_titre: item.titre,
        description_resume: item.resume,
        description_html: item.html,
        description_indication_acces: item.indication_acces,
        description_libre: item.libre,
        description_lot_url: item.lot_url,
        description_lot_id: item.lot_id,
      }
    : null;
}

export function adaptedToDescription(item) {
  return item
    ? {
        langue_id: item.description_langue_id,
        titre: item.description_titre,
        resume: item.description_resume,
        html: item.description_html,
        indication_acces: item.description_indication_acces,
        libre: item.description_libre,
        lot_url: item.description_lot_url,
        lot_id: item.description_lot_id,
      }
    : null;
}

import {
  adaptedToDocument,
  documentToAdapted,
  documentsToAdapted,
  adaptedToDocumentWord,
} from "../../adapter/documentAdapter";
import { HttpMethod, initFetch } from "../../utils/fetcher";

export const LOAD_SUCCESS_DOCUMENTS = "LOAD_SUCCESS_DOCUMENTS";
export const LOAD_FAIL_DOCUMENTS = "LOAD_FAIL_DOCUMENTS";
export const SAVE_SUCCESS_DOCUMENTS = "SAVE_SUCCESS_DOCUMENTS";
export const SAVE_FAIL_DOCUMENTS = "SAVE_FAIL_DOCUMENTS";
export const UPDATE_SUCCESS_DOCUMENTS = "UPDATE_SUCCESS_DOCUMENTS";
export const UPDATE_FAIL_DOCUMENTS = "UPDATE_FAIL_DOCUMENTS";
export const DELETE_SUCCESS_DOCUMENTS = "DELETE_SUCCESS_DOCUMENTS";
export const DELETE_FAIL_DOCUMENTS = "DELETE_FAIL_DOCUMENTS";

export const SAVE_SUCCESS_ICALS = "SAVE_SUCCESS_ICALS";
export const SAVE_FAIL_ICALS = "SAVE_FAIL_ICALS";

export function loadDocuments() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`documents`, HttpMethod.GET);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.document);
          dispatch({
            type: LOAD_SUCCESS_DOCUMENTS,
            payload: documentsToAdapted(res.document),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_DOCUMENTS,
            error: err,
          });
        });
    });
  };
}

export function saveDocument(componentsData, dossier, tag) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`uploadDocument`, HttpMethod.POST, {
        Authorization: true,
        formData: true,
        pdf: false,
      });
      let formData = new FormData();
      formData.append("file", componentsData.file);
      formData.append("nom", componentsData.file.name);
      formData.append("dossier_id", dossier.dossier_id);
      formData.append("tag_id", tag.tag_id);
      formData.append("url", `${dossier.dossier_url}/${tag.tag_libelle}/`);
      // make async call to database
      fetch(url, { ...params, body: formData })
        .then((res) => res.json())
        .then((res) => {
          resolve(documentToAdapted(res.document));
          dispatch({
            type: SAVE_SUCCESS_DOCUMENTS,
            payload: documentToAdapted(res.document),
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_DOCUMENTS,
            error: err,
          })
        );
    });
  };
}

export function uploadICal(str, lot) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`uploadICal`, HttpMethod.POST, {
        Authorization: true,
        formData: true,
        pdf: false,
      });
      let formData = new FormData();
      formData.append("file", str);
      formData.append("nom", lot.lot_reference + ".ics");
      // make async call to database
      fetch(url, { ...params, body: formData })
        .then((res) => resolve(res))
        .catch((err) => resolve(err));
    });
  };
}

export function uploadRapprochement(componentsData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`uploadRapprochement`, HttpMethod.POST, {
        Authorization: true,
        formData: true,
        pdf: false,
      });
      let formData = new FormData();
      formData.append("file", componentsData.file);
      formData.append("nom", id + ".pdf");
      // make async call to database
      fetch(url, { ...params, body: formData })
        .then((res) => resolve(res))
        .catch((err) => resolve(err));
    });
  };
}

export function processWord(jsonData, idWord, idDossier) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(
        `documents/processWord/${idWord}/${idDossier}`,
        HttpMethod.POST
      );
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToDocumentWord(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(documentToAdapted(res.document));
          dispatch({
            type: SAVE_SUCCESS_DOCUMENTS,
            payload: documentToAdapted(res.document),
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_DOCUMENTS,
            error: err,
          })
        );
    });
  };
}

export function convertToPdf(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`documents/convertToPdf/${id}`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToDocument(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(documentToAdapted(res.document));
          dispatch({
            type: SAVE_SUCCESS_DOCUMENTS,
            payload: documentToAdapted(res.document),
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_DOCUMENTS,
            error: err,
          })
        );
    });
  };
}

export function updateDocument(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`documents/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToDocument(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(documentToAdapted(res.document));
          dispatch({
            type: UPDATE_SUCCESS_DOCUMENTS,
            payload: documentToAdapted(res.document),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_DOCUMENTS,
            error: err,
          })
        );
    });
  };
}

export function deleteDocument(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`documents/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.document);
          dispatch({
            type: DELETE_SUCCESS_DOCUMENTS,
            payload: documentToAdapted(res.document),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_DOCUMENTS,
            error: err,
          });
        });
    });
  };
}

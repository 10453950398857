import React, { Component } from "react";
import { Row, Col } from "antd";

import { round } from "../../../utils/tools";

import dayjs from "dayjs";

export default class BordereauPDF extends Component {
  render() {
    const { reglements, numRemise, dateRemise, societes, journals } = this.props;

    let nbElements = 0;
    let montant = 0;

    reglements.forEach((reglement) => {
      montant += parseFloat(reglement.reglement_montant);
      nbElements++;
    });

    montant = round(montant, 2);

    const jrnBanque = journals.filter(
      (j) => j.journal_libelle === "BANQUE" || j.journal_libelle === "Banque"
    );

    return (
      <div>
        {/* ENTETE */}
        <Row>
          <Col>
            <div align="center">
              <strong>{"BORDEREAU DE REMISE"}</strong>
            </div>
            <br />
            <table className="table-signature">
              <thead></thead>
              <tbody>
                <tr>
                  <td width="50%">
                    <p>
                      Date de remise :{" "}
                      {dayjs(dateRemise, "YYYY-MM-DD").isValid()
                        ? dayjs(dateRemise, "YYYY-MM-DD").format("DD/MM/YYYY")
                        : dayjs().format("DD/MM/YYYY")}
                    </p>
                    <p>Type de remise : Chèque</p>
                    <p>Numéro de remise : {numRemise}</p>
                  </td>
                  <td width="50%">
                    <p>Raison sociale : {societes[0].societe_raison_sociale}</p>
                    <p>
                      Compte à créditer :{" "}
                      {jrnBanque[0].journal_compte_bancaire.compte_bancaire_num_compte}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>

        {/* TOTAL */}
        <table className="table table-striped" border="1px">
          <thead>
            <tr>
              <th width="15%" align="center" bgcolor="#afaeae">
                <b>Banque</b>
              </th>
              <th width="15%" align="center" bgcolor="#afaeae">
                <b>N° chèque</b>
              </th>
              <th width="50%" align="center" bgcolor="#afaeae">
                <b>Libellé</b>
              </th>
              <th width="20%" align="center" bgcolor="#afaeae">
                <b>Montant</b>
              </th>
            </tr>
          </thead>
          <tbody>
            {reglements.map((reglement, i) => {
              return (
                <tr key={i}>
                  <td width="15%">
                    <p>{reglement.reglement_banque}</p>
                  </td>
                  <td width="15%">
                    <p>{reglement.reglement_numero_cheque}</p>
                  </td>
                  <td width="50%">
                    <p>{reglement.reglement_tire}</p>
                  </td>
                  <td width="20%" style={{ textAlign: "right" }}>
                    <p>
                      {parseInt(reglement.reglement_montant) !== 0 ? (
                        parseFloat(reglement.reglement_montant).toLocaleString("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        })
                      ) : (
                        <></>
                      )}
                    </p>
                  </td>
                </tr>
              );
            })}
            <tr>
              <td width="30%"></td>
              <td width="50%" style={{ textAlign: "right" }}>
                <p>{nbElements} chèque(s)</p>
              </td>
              <td width="20%" align="right">
                <p>
                  <strong>
                    {parseInt(montant) !== 0 ? (
                      parseFloat(montant).toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      })
                    ) : (
                      <></>
                    )}
                  </strong>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

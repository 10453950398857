import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import dayjs from "dayjs"

export const columns = (getColumnSearchPropsDate, deleteRequete) => [
  {
    title: "n°",
    dataIndex: "requete_id",
    key: "requete_id",
    sorter: (a, b) => parseInt(b.requete_id) - parseInt(a.requete_id),
    width: "5%",
  },
  {
    title: "Date Création",
    dataIndex: "requete_creation",
    key: "requete_creation",
    ...getColumnSearchPropsDate("requete_creation"),
    render: (date) => dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
    sorter: (a, b) =>
      dayjs(a.requete_creation).unix() - dayjs(b.requete_creation).unix(),
    width: "8%",
  },
  {
    title: "Lot",
    dataIndex: "requete_lot_reference",
    key: "requete_lot_reference",
    width: "15%",
  },
  {
    title: "Date Début",
    dataIndex: "requete_date_debut",
    key: "requete_date_debut",
    ...getColumnSearchPropsDate("requete_date_debut"),
    render: (date) => dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
    sorter: (a, b) => dayjs(a.requete_date_debut).unix() - dayjs(b.requete_date_debut).unix(),
    width: "8%",
  },
  {
    title: "Date Fin",
    dataIndex: "requete_date_fin",
    key: "requete_date_fin",
    ...getColumnSearchPropsDate("requete_date_fin"),
    render: (date) => dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
    sorter: (a, b) => dayjs(a.requete_date_fin).unix() - dayjs(b.requete_date_fin).unix(),
    width: "8%",
  },
  {
    title: "Locataire",
    dataIndex: "requete_locataire",
    key: "requete_locataire",
    width: "15%",
  },  
  {
    title: "Supprimer",
    dataIndex: "requete_id",
    key: "requete_id",
    render: (requete_id) => (
      <Button
        icon={<DeleteOutlined />}
        shape="circle"
        onClick={() => deleteRequete(requete_id)}
      />
    ),
    width: "10%",
  },
];


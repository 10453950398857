import { HttpMethod, initFetch } from "../../utils/fetcher";
import { adaptedToWord, wordsToAdapted, wordToAdapted } from "../../adapter/wordAdapter";

export const LOAD_SUCCESS_WORDS = "LOAD_SUCCESS_WORDS";
export const LOAD_FAIL_WORDS = "LOAD_FAIL_WORDS";
export const SAVE_SUCCESS_WORDS = "SAVE_SUCCESS_WORDS";
export const SAVE_FAIL_WORDS = "SAVE_FAIL_WORDS";
export const UPDATE_SUCCESS_WORDS = "UPDATE_SUCCESS_WORDS";
export const UPDATE_FAIL_WORDS = "UPDATE_FAIL_WORDS";
export const DELETE_SUCCESS_WORDS = "DELETE_SUCCESS_WORDS";
export const DELETE_FAIL_WORDS = "DELETE_FAIL_WORDS";

export function loadWords() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`words`, HttpMethod.GET);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.word);
          dispatch({
            type: LOAD_SUCCESS_WORDS,
            payload: wordsToAdapted(res.word),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_WORDS,
            error: err,
          });
        });
    });
  };
}

export function saveWord(componentsData, type, etablissement_id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("file", componentsData.file);
      formData.append("nom", componentsData.file.name);
      formData.append("type", type.value);
      formData.append("type_name", type.name);
      formData.append("etablissement_id", etablissement_id);
      const { url, params } = initFetch(`uploadWord`, HttpMethod.POST, {
        Authorization: true,
        formData: true,
        pdf: false,
      });
      // make async call to database
      fetch(url, { ...params, body: formData })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.word);
          dispatch({
            type: SAVE_SUCCESS_WORDS,
            payload: wordToAdapted(res.word),
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_WORDS,
            error: err,
          })
        );
    });
  };
}

export function updateWord(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`words/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToWord(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.word);
          dispatch({
            type: UPDATE_SUCCESS_WORDS,
            payload: wordToAdapted(res.word),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_WORDS,
            error: err,
          })
        );
    });
  };
}

export function deleteWord(id, type) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`words/${id}/${type}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.word);
          dispatch({
            type: DELETE_SUCCESS_WORDS,
            payload: wordToAdapted(res.word),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_WORDS,
            error: err,
          });
        });
    });
  };
}

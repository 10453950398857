import { HttpMethod, initFetch } from "../../utils/fetcher";
import {
  adaptedToProprietaire,
  proprietairesToAdapted,
  proprietaireToAdapted,
} from "../../adapter/proprietaireAdapter";

export const LOAD_SUCCESS_PROPRIETAIRES = "LOAD_SUCCESS_PROPRIETAIRES";
export const LOAD_FAIL_PROPRIETAIRES = "LOAD_FAIL_PROPRIETAIRES";
export const SAVE_SUCCESS_PROPRIETAIRES = "SAVE_SUCCESS_PROPRIETAIRES";
export const SAVE_FAIL_PROPRIETAIRES = "SAVE_FAIL_PROPRIETAIRES";
export const UPDATE_SUCCESS_PROPRIETAIRES = "UPDATE_SUCCESS_PROPRIETAIRES";
export const UPDATE_FAIL_PROPRIETAIRES = "UPDATE_FAIL_PROPRIETAIRES";
export const DELETE_SUCCESS_PROPRIETAIRES = "DELETE_SUCCESS_PROPRIETAIRES";
export const DELETE_FAIL_PROPRIETAIRES = "DELETE_FAIL_PROPRIETAIRES";

export function loadProprietaires(groupement_id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch("personnesProprietaires", HttpMethod.GET);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.proprietaire);
          dispatch({
            type: LOAD_SUCCESS_PROPRIETAIRES,
            payload: proprietairesToAdapted(res.proprietaire),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_PROPRIETAIRES,
            error: err,
          });
        });
    });
  };
}

export function saveProprietaire(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch("personnesProprietaires", HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToProprietaire(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(proprietaireToAdapted(res.proprietaire));
          dispatch({
            type: SAVE_SUCCESS_PROPRIETAIRES,
            payload: proprietaireToAdapted(res.proprietaire),
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_PROPRIETAIRES,
            error: err,
          })
        );
    });
  };
}

export function updateProprietaire(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`personnesProprietaires/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToProprietaire(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(proprietaireToAdapted(res.proprietaire));
          dispatch({
            type: UPDATE_SUCCESS_PROPRIETAIRES,
            payload: proprietaireToAdapted(res.proprietaire),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_PROPRIETAIRES,
            error: err,
          })
        );
    });
  };
}

export function updateProprietairePassword(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`personnesProprietaires/${id}/password`, HttpMethod.PATH);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToProprietaire(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.proprietaire);
          dispatch({
            type: UPDATE_SUCCESS_PROPRIETAIRES,
            payload: proprietaireToAdapted(res.proprietaire),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_PROPRIETAIRES,
            error: err,
          })
        );
    });
  };
}

export function archiveProprietaire(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`personnesProprietaires/archive/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, params)
        .then((res) => res.json())
        .then((res) => {
          resolve(res.proprietaire);
          dispatch({
            type: UPDATE_SUCCESS_PROPRIETAIRES,
            payload: proprietaireToAdapted(res.proprietaire),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_PROPRIETAIRES,
            error: err,
          })
        );
    });
  };
}

export function deleteProprietaire(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`personnesProprietaires/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.proprietaire);
          dispatch({
            type: DELETE_SUCCESS_PROPRIETAIRES,
            payload: proprietaireToAdapted(res.proprietaire),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_PROPRIETAIRES,
            error: err,
          });
        });
    });
  };
}

import { rubriqueToAdapted } from "./rubriqueAdapter";

export function locationsLignesToAdapted(items) {
  return items
    ? items.map((item) => {
        return locationLigneToAdapted(item);
      })
    : null;
}

export function locationLigneToAdapted(item) {
  return item
    ? {
        location_ligne_id: item.id,
        location_ligne_libelle: item.ligne_libelle,
        location_ligne_montant: item.montant,
        location_ligne_pourcentage_acompte: item.pourcentage_acompte,
        location_ligne_permanente: item.permanente,
        location_ligne_date_suppression: item.date_suppression,
        location_ligne_location_id: item.location_id,
        location_ligne_rubrique: rubriqueToAdapted(item.rubrique),
      }
    : null;
}

export function adaptedToLocationLigne(item) {
  return item
    ? {
        location_id: item.location_ligne_location_id,
        rubrique_id: item.location_ligne_rubrique_id,
        ligne_libelle: item.location_ligne_libelle,
        montant: item.location_ligne_montant,
        pourcentage_acompte: item.location_ligne_pourcentage_acompte,
        permanente: item.location_ligne_permanente,
        date_suppression: item.location_ligne_date_suppression,
      }
    : null;
}

export function rubriquesPourcentagesToAdapted(items) {
  return items
    ? items.map((item) => {
        return rubriquePourcentageToAdapted(item);
      })
    : null;
}

export function rubriquePourcentageToAdapted(item) {
  return item
    ? {
        rubrique_id: item.id,
        rubrique_code: item.code,
        rubrique_libelle: item.libelle,
        rubrique_type: item.type,
        rubrique_signe: item.signe,
      }
    : null;
}

export function adaptedToRubriquePourcentage(item) {
  return item
    ? {
        principale_id: item.rubrique_pourcentage_principale_id,
        liee_id: item.rubrique_pourcentage_liee_id,
      }
    : null;
}

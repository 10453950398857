import React, { Component } from "react";

import { Row, Col, Form, Input, Select, Button, Divider, Modal, Checkbox, Space } from "antd";

import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { toast } from "react-toastify";

import { isLeaf } from "../../utils/tools";

const { Option } = Select;

class MyFormEtape extends Component {
  constructor(props) {
    super(props);

    this.state = {
      availableOrder: [],
      couleurToEdit: {},
      codeCouleurHex: "#fff",
    };
  }

  // Référence du formulaire
  formRef = React.createRef();

  componentDidMount() {
    this.setState({ availableOrder: this.props.availableOrders });
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.etape !== null) {
      this.formRef.current.setFieldsValue({
        libelleInput: props.etape.etape_libelle,
        parenteSelect: props.etape.etape_etape_parente.etape_id,
        ordreSelect: props.etape.etape_ordre,
        couleurSelect: props.etape.etape_code_couleur.code_couleur_id,
        etape_todo: props.etape.etape_todo,
      });
    }
  }

  getAvailableOrders = (value) => {
    let availableOrders = [];
    let etapes = this.props.dataEtapes;
    etapes.forEach((item) => {
      if (item.etape_etape_parente.etape_id === value) {
        availableOrders.push(item.etape_ordre);
      }
    });

    if (!this.props.etape) {
      availableOrders.push(availableOrders.length + 1);
    }
    this.setState({ availableOrder: availableOrders });
  };

  onFinishEtape = (data) => {
    const { etape } = this.props;
    if (etape) {
      this.updateEtape(data);
    } else {
      this.storeEtape(data);
    }
  };

  storeEtape = (values) => {
    const jsonData = {
      etape_libelle: values.libelleInput,
      etape_icone: "etape.ico",
      etape_ordre: values.ordreSelect,
      etape_todo: values.etape_todo,
      etape_code_couleur_id: values.couleurSelect,
      etape_etape_parente_id: values.parenteSelect,
      etape_etablissement_id: this.props.utilisateur.etablissement_id,
    };

    this.props
      .saveEtape(jsonData)
      .then((res) => {
        this.setState({
          availableOrder: [...this.state.availableOrder, this.state.availableOrder.length + 1],
        });
        this.props.initTree(this.props.utilisateur.utilisateur_id);
        this.props.getAvailableOrders(0, false);
        this.props.getAvailableParents();
        this.formRef.current.resetFields();
        toast.success("Étape ajoutée !", { containerId: "A" });
      })
      .catch((err) => {
        if (err === "Error: 500") {
          toast.error("Échec de création !", { containerId: "A" });
        } else {
          toast.error("Échec de connexion !", { containerId: "A" });
        }
      });
  };

  updateEtape = (values) => {
    const { etape } = this.props;

    const jsonData = {
      etape_libelle: values.libelleInput,
      etape_icone: "etape.ico",
      etape_ordre: values.ordreSelect,
      etape_todo: values.etape_todo,
      etape_code_couleur_id: values.couleurSelect,
      etape_etape_parente_id: values.parenteSelect,
    };

    this.props
      .updateEtape(jsonData, etape.etape_id)
      .then((res) => {
        this.props.initTree(this.props.utilisateur.utilisateur_id);
        this.formRef.current.resetFields();
        this.props.handleEditPopoverVisibleChange(false);
        toast.success("Étape modifiée !", { containerId: "A" });
      })
      .catch((err) => {
        console.log(err);

        if (err === "Error: 500") {
          toast.error("Échec de modification !", { containerId: "A" });
        } else {
          toast.error("Échec de connexion !", { containerId: "A" });
        }
      });
  };

  confirm = () => {
    Modal.confirm({
      title: "Confirmation",
      content: "Voulez-vous supprimer cette étape ?",
      okText: "Oui",
      cancelText: "Non",
      onOk: () => this.handleDelete(),
    });
  };

  handleDelete = () => {
    const { etape, etapes, renduAs } = this.props;

    if (
      etapes.some(
        (item) => item.etape_etape_parente && item.etape_etape_parente.etape_id === etape.etape_id
      )
    ) {
      toast.error("Ceci est une étape parente !", {
        containerId: "A",
      });
      return;
    }

    if (renduAs.some((item) => item.rendu_a_actif && item.rendu_a_etape_id === etape.etape_id)) {
      toast.error("Cette étape contient une requête en cours !", {
        containerId: "A",
      });
      return;
    }

    this.props
      .deleteEtape(etape.etape_id)
      .then((res) => {
        this.props.initTree(this.props.utilisateur.utilisateur_id);
        this.props.initTreeForm();
        this.props.getAvailableOrders(0, false);
        this.props.getAvailableParents();
        this.props.handleEditPopoverVisibleChange(false);

        toast.success("Étape supprimée !", { containerId: "A" });
      })
      .catch((err) => {
        toast.error("Échec : " + err, { containerId: "A" });
      });
  };

  handleEditCodeCouleur = (e) => {
    this.formRef.current.validateFields(["couleurSelect"]).then((values) => {
      this.props.openModalEditCodeCouleur();
    });
  };

  handleDeleteCodeCouleur = (e) => {
    this.formRef.current.validateFields(["couleurSelect"], (err, values) => {
      if (!err) {
        this.props
          .deleteCodeCouleur(values.couleurSelect)
          .then((res) => {
            this.props.deleteDataCodesCouleur(this.props.dataCodesCouleur, res);
            this.formRef.current.resetFields();
            toast.success("Code couleur supprimé !", { containerId: "A" });
          })
          .catch((err) => {
            if (err === "Error: 500") {
              toast.error("Code couleur utilisé !", {
                containerId: "A",
              });
            } else {
              toast.error("Échec de connexion !", { containerId: "A" });
            }
          });
      }
    });
  };

  handleChangeEtapeParente = (value) => {
    let type = true;
    if (this.props.etape === null) type = false;
    this.props.handleChangeEtapeParente(value, type);
  };

  parents = () => {
    let liste = this.props.parents;

    // Création étape
    liste = liste.filter((item) => {
      return !isLeaf(item);
    });

    // Edition étape
    if (this.props.etape) {
      liste = liste.filter((item) => {
        return (
          // l'étape sélectionnée ne peut pas être sa parente
          item.etape_id !== this.props.etape.etape_id && item.level === this.props.etape.level - 1
        );
      });
    }

    return liste.sort((a, b) => {
      return a.level - b.level;
    });
  };

  handleChangeEtapeParente = (value) => {
    let type = true;
    if (this.props.etape === null) type = false;
    this.props.handleChangeEtapeParente(value, type);
  };

  render() {
    const { etape, dataCodesCouleur, availableOrders } = this.props;
    const availableParents = this.parents();

    const getEtapeToEditLibelle = (etape) => {
      if (etape !== null) return etape.etape_libelle;
    };
    const getEtapeToEditCouleur = (etape) => {
      if (etape !== null) return etape.etape_code_couleur.code_couleur_id;
    };

    const initialValues = {
      libelleInput: etape ? getEtapeToEditLibelle(etape) : "",
      parenteSelect: etape && etape.etape_etape_parente ? etape.etape_etape_parente.etape_id : 0,
      ordreSelect: etape ? etape.etape_ordre : availableOrders[0],
      etape_todo: etape ? etape.etape_todo : false,
      couleurSelect: etape ? getEtapeToEditCouleur(etape) : this.props.codeCouleurId,
    };

    const orders = () => {
      return availableOrders;
    };

    const aucuneNeeded = () => {
      if (!etape || (etape && etape.level === 1)) return true;
    };

    const formItemLayout = {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
      labelAlign: "left",
    };

    return (
      <Form
        {...formItemLayout}
        ref={this.formRef}
        className="ant-advanced-search-form"
        initialValues={initialValues}
        onFinish={this.onFinishEtape}
      >
        <Form.Item
          name="libelleInput"
          label="Étape"
          rules={[{ required: true, message: "Libellé de l'étape obligatoire" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="parenteSelect"
          label="Affiliée à"
          rules={[{ required: true, message: "Etape parente de l'étape obligatoire" }]}
        >
          <Select optionLabelProp="label" onChange={this.handleChangeEtapeParente}>
            {aucuneNeeded() && (
              <Option key={null} value={0} label={"Aucune"}>
                <Row>
                  <Col span={8}>{"Aucune"}</Col>
                </Row>
              </Option>
            )}
            {availableParents &&
              availableParents.map((item, i) => {
                return (
                  <Option
                    key={i}
                    value={item.etape_id}
                    label={
                      <span
                        style={{
                          color: item.etape_code_couleur.code_couleur_hex,
                        }}
                      >
                        <Row>
                          <Col span={8}>{item.etape_libelle}</Col>
                        </Row>
                      </span>
                    }
                  >
                    <span
                      style={{
                        color: item.etape_code_couleur.code_couleur_hex,
                      }}
                    >
                      <Row>
                        <Col span={8}>{item.etape_libelle}</Col>
                      </Row>
                    </span>
                  </Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item
          name="ordreSelect"
          label="Ordre"
          rules={[{ required: true, message: "Ordre de l'étape obligatoire" }]}
        >
          <Select optionLabelProp="label">
            {orders() &&
              orders().map((item, i) => {
                return (
                  <Option key={i} value={item} label={item}>
                    <Row>
                      <Col span={8}>{item}</Col>
                    </Row>
                  </Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item name="etape_todo" label="Type" valuePropName="checked">
          <Checkbox>Todo ?</Checkbox>
        </Form.Item>

        <Form.Item label="Couleur">
          <Space>
            <Form.Item
              name="couleurSelect"
              label="Couleur"
              noStyle
              rules={[
                {
                  required: true,
                  message: "Code Couleur de l'étape obligatoire",
                },
              ]}
            >
              <Select
                style={{ width: 160 }}
                optionLabelProp="label"
                onChange={this.props.handleChangeCouleur}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: "4px 0" }} />
                    <div
                      style={{ padding: "8px", cursor: "pointer" }}
                      onClick={this.props.openModalCreateCodeCouleur}
                    >
                      <PlusOutlined /> Ajouter couleur
                    </div>
                  </div>
                )}
              >
                {dataCodesCouleur &&
                  dataCodesCouleur.map((item, i) => {
                    return (
                      <Option
                        key={i}
                        value={item.code_couleur_id}
                        label={
                          <div>
                            <span style={{ paddingRight: "25px" }}>
                              {item.code_couleur_libelle}
                            </span>
                            <span
                              style={{
                                backgroundColor: item.code_couleur_hex,
                                width: "50px",
                                height: "20px",
                                borderRadius: "4px",
                                paddingLeft: "50px",
                              }}
                            ></span>
                          </div>
                        }
                      >
                        <Row>
                          <Col span={8}>{item.code_couleur_libelle}</Col>
                          <Col
                            span={8}
                            style={{
                              backgroundColor: item.code_couleur_hex,
                              width: "50px",
                              height: "20px",
                              borderRadius: "4px",
                              marginLeft: "50px",
                            }}
                          />
                        </Row>
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item noStyle>
              <EditOutlined
                type="edit"
                theme="twoTone"
                style={{
                  fontSize: "18px",
                  margin: "0 15px",
                  cursor: "pointer",
                }}
                onClick={this.handleEditCodeCouleur}
              />
              <DeleteOutlined
                theme="twoTone"
                twoToneColor="#cb1d23"
                style={{ fontSize: "18px", cursor: "pointer" }}
                onClick={this.handleDeleteCodeCouleur}
              />
            </Form.Item>
          </Space>
        </Form.Item>

        {etape ? (
          <Form.Item {...formItemLayout}>
            <Button type="primary" style={{ marginRight: "20px" }} htmlType="submit">
              Editer
            </Button>
            <Button type="danger" onClick={this.confirm}>
              Supprimer
            </Button>
          </Form.Item>
        ) : (
          <Form.Item {...formItemLayout}>
            <Button type="primary" htmlType="submit">
              Ajouter
            </Button>
          </Form.Item>
        )}
      </Form>
    );
  }
}

export default MyFormEtape;
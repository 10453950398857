import { renduARequeteToAdapted } from "./requeteAdapter";

export function rendusToAdapted(items) {
  return items
    ? items.map((item) => {
        return renduToAdapted(item);
      })
    : null;
}

export function renduToAdapted(item) {
  //Resource
  return item
    ? {        
        rendu_a_requete: renduARequeteToAdapted(item.requete),
        rendu_a_etape_id: item.etape_id,
        rendu_a_actif: item.actif,
        rendu_a_signal: item.signal,
        rendu_a_date_at: item.date_at,
      }
    : null;
}

export function adaptedToRendu(item) {
  //Model
  return item
    ? {
        requete_id: item.rendu_a_requete_id,
        etape_id: item.rendu_a_etape_id,
        actif: item.rendu_a_actif,
        signal: item.rendu_a_signal,
        date_at: item.rendu_a_date_at,
      }
    : null;
}

import React, { Component } from "react";
import { Button, Card, Col, Divider, Form, Input, Row, Select, Tabs, Upload } from "antd";
import FloatLabel from "../FloatLabel";

import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";

import "cleave.js/dist/addons/cleave-phone.fr";
import Cleave from "cleave.js/react";
import 'cleave.js/dist/addons/cleave-phone.fr';

import { PlusOutlined } from "@ant-design/icons";

const { Option, OptGroup } = Select;

class FormEtablissement extends Component {
  state = {
    societe: "",
    nomCourt: "",
    raisonSociale: "",
    telephone1: "",
    telephone2: "",
    mail1: "",
    siteWeb: "",
    zoneLibre1: "",
    zoneLibre2: "",
    zoneLibre3: "",
    zoneLibre4: "",
    zoneLibre5: "",
    adresse: "",
    numero: "",
    voie: "",
    complement: "",
    cp: "",
    ville: "",
    departement: "",
    region: "",
    pays: "",
  };

  // Référence du formulaire
  formRef = React.createRef();

  UNSAFE_componentWillReceiveProps(props) {
    props.etablissement ? this.initEtablissement(props.etablissement) : this.resetEtablissement();
  }

  initEtablissement = (data) => {
    this.resetEtablissement();

    const etablissement = data;
    const adresse = data.etablissement_adresse;

    this.formRef.current.setFieldsValue({
      etablissement_societe_id: etablissement.etablissement_societe_id,
      etablissement_nom_court: etablissement.etablissement_nom_court,
      etablissement_raison_sociale: etablissement.etablissement_raison_sociale,
      etablissement_telephone: etablissement.etablissement_telephone,
      etablissement_telephone2: etablissement.etablissement_telephone2,
      etablissement_email: etablissement.etablissement_email,
      etablissement_site_web: etablissement.etablissement_site_web,
      etablissement_zone_libre_1: etablissement.etablissement_zone_libre_1,
      etablissement_zone_libre_2: etablissement.etablissement_zone_libre_2,
      etablissement_zone_libre_3: etablissement.etablissement_zone_libre_3,
      etablissement_zone_libre_4: etablissement.etablissement_zone_libre_4,
      etablissement_zone_libre_5: etablissement.etablissement_zone_libre_5,
      adresse_gmaps_adresse: adresse.adresse_gmaps_adresse,
      adresse_numero: adresse.adresse_numero,
      adresse_voie: adresse.adresse_voie,
      complement_adresse: "",
      adresse_code_postal: adresse.adresse_code_postal,
      adresse_ville: adresse.adresse_ville,
      adresse_departement: adresse.adresse_departement,
      adresse_region: adresse.adresse_region,
      adresse_pays: adresse.adresse_pays,
    });

    this.setState({
      societe: etablissement.etablissement_societe_id,
      nomCourt: etablissement.etablissement_nom_court,
      raisonSociale: etablissement.etablissement_raison_sociale,
      telephone1: etablissement.etablissement_telephone,
      telephone2: etablissement.etablissement_telephone2,
      mail1: etablissement.etablissement_email,
      siteWeb: etablissement.etablissement_site_web,
      zoneLibre1: etablissement.etablissement_zone_libre_1,
      zoneLibre2: etablissement.etablissement_zone_libre_2,
      zoneLibre3: etablissement.etablissement_zone_libre_3,
      zoneLibre4: etablissement.etablissement_zone_libre_4,
      zoneLibre5: etablissement.etablissement_zone_libre_5,
      adresse: adresse.adresse_gmaps_adresse,
      numero: adresse.adresse_numero,
      voie: adresse.adresse_voie,
      complement: "",
      cp: adresse.adresse_code_postal,
      ville: adresse.adresse_ville,
      departement: adresse.adresse_departement,
      region: adresse.adresse_region,
      pays: adresse.adresse_pays,
    });
  };

  resetEtablissement = () => {
    this.formRef.current.resetFields([
      "etablissement_societe_id",
      "etablissement_nom_court",
      "etablissement_raison_sociale",
      "etablissement_telephone",
      "etablissement_telephone2",
      "etablissement_email",
      "etablissement_site_web",
      "etablissement_zone_libre_1",
      "etablissement_zone_libre_2",
      "etablissement_zone_libre_3",
      "etablissement_zone_libre_4",
      "etablissement_zone_libre_5",
      "adresse_gmaps_adresse",
      "adresse_numero",
      "adresse_voie",
      "complement_adresse",
      "adresse_code_postal",
      "adresse_ville",
      "adresse_departement",
      "adresse_region",
      "adresse_pays",
    ]);

    this.setState({
      societe: "",
      nomCourt: "",
      raisonSociale: "",
      telephone1: "",
      telephone2: "",
      mail1: "",
      siteWeb: "",
      zoneLibre1: "",
      zoneLibre2: "",
      zoneLibre3: "",
      zoneLibre4: "",
      zoneLibre5: "",
      adresse: "",
      numero: "",
      voie: "",
      complement: "",
      cp: "",
      ville: "",
      departement: "",
      region: "",
      pays: "",
    });
  };

  onFinish = (data) => {
    data.etablissement_adresse_id = this.props.etablissement
      ? this.props.etablissement.etablissement_adresse.adresse_id
      : null;
    this.props.etablissement
      ? this.props.handleEdit(data, this.props.etablissement.etablissement_id)
      : this.props.handleSubmit(data);
  };

  handleChangeAdresse = (adresse) => {
    this.setState({ adresse });
  };

  handleSelectAdresse = (adresse) => {
    this.formRef.current.setFieldsValue({
      adresse_gmaps_adresse: adresse,
      adresse_numero: "",
      adresse_voie: "",
      adresse_ville: "",
      adresse_code_postal: "",
      adresse_region: "",
      adresse_departement: "",
      adresse_pays: "",
    });
    geocodeByAddress(adresse).then((results) => {
      results[0].address_components.forEach((component, i) => {
        if (component.types[0] === "street_number") {
          this.setState({ numero: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_numero: component.long_name,
          });
        }
        if (component.types[0] === "route") {
          this.setState({ voie: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_voie: component.long_name,
          });
        }
        if (component.types[0] === "locality") {
          this.setState({ ville: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_ville: component.long_name,
          });
        }
        if (component.types[0] === "postal_code") {
          this.setState({ cp: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_code_postal: component.long_name,
          });
        }
        if (component.types[0] === "administrative_area_level_1") {
          this.setState({ region: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_region: component.long_name,
          });
        }
        if (component.types[0] === "administrative_area_level_2") {
          this.setState({ departement: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_departement: component.long_name,
          });
        }
        if (component.types[0] === "country") {
          this.setState({ pays: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_pays: component.long_name,
          });
        }
      });
    });
    this.setState({ adresse });
  };

  render() {
    const { societes } = this.props;
    const {
      societe,
      nomCourt,
      raisonSociale,
      telephone1,
      telephone2,
      mail1,
      siteWeb,
      zoneLibre1,
      zoneLibre2,
      zoneLibre3,
      zoneLibre4,
      zoneLibre5,
      adresse,
      numero,
      voie,
      complement,
      cp,
      ville,
      departement,
      region,
      pays,
    } = this.state;

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">Ajouter</div>
      </div>
    );

    const props = {
      name: "file",
      accept: ".jpg, .png",
      listType: "picture-card",
      multiple: true,
      showUploadList: false,
      beforeUpload: this.beforeUpload,
      customRequest: (componentsData) => {
        console.log(componentsData, "componentsData");
      },
    };

    return (
      <Form ref={this.formRef} onFinish={this.onFinish} size="large" className="form-requete">
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              label: "Coordonnées",
              key: "1",
              children: (
                <Row gutter={16}>
                  <Col span={12} style={{ padding: 20 }}>
                    <Divider orientation="left">Identification</Divider>
                    <Row gutter={8}>
                      <Col span={24}>
                        <FloatLabel label="Société" name="societe" value={societe}>
                          <Form.Item name="etablissement_societe_id">
                            <Select
                              showSearch
                              optionFilterProp="label"
                              optionLabelProp="label"
                              onChange={(id) => this.setState({ societe: id })}
                            >
                              <OptGroup label="Société">
                                {societes &&
                                  societes.map((item, i) => {
                                    return (
                                      <Option
                                        key={i}
                                        value={item.societe_id}
                                        label={item.societe_raison_sociale}
                                      >
                                        <Row>
                                          <Col sm={8}>{item.societe_raison_sociale}</Col>
                                        </Row>
                                      </Option>
                                    );
                                  })}
                              </OptGroup>
                            </Select>
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col span={12}>
                        <FloatLabel label="Nom court" name="nom_court" value={nomCourt}>
                          <Form.Item name="etablissement_nom_court">
                            <Input
                              value={nomCourt}
                              onChange={(e) => this.setState({ nomCourt: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col span={12}>
                        <FloatLabel
                          label="Raison sociale"
                          name="raison_sociale"
                          value={raisonSociale}
                        >
                          <Form.Item name="etablissement_raison_sociale">
                            <Input
                              value={raisonSociale}
                              onChange={(e) => this.setState({ raisonSociale: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col span={6}>
                        <FloatLabel label="Téléphone 1" name="telephone1" value={telephone1}>
                          <Form.Item name="etablissement_tel1">
                            <Cleave
                              className="ant-input ant-input-lg"
                              value={telephone1}
                              options={{ phone: true, phoneRegionCode: "FR" }}
                              onChange={(e) => this.setState({ telephone1: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col span={6}>
                        <FloatLabel label="Téléphone 2" name="telephone2" value={telephone2}>
                          <Form.Item name="etablissement_tel2">
                            <Cleave
                              className="ant-input ant-input-lg"
                              value={telephone2}
                              options={{ phone: true, phoneRegionCode: "FR" }}
                              onChange={(e) => this.setState({ telephone2: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col span={12}>
                        <FloatLabel label="Email" name="mail1" value={mail1}>
                          <Form.Item name="etablissement_email">
                            <Input
                              value={mail1}
                              onChange={(e) => this.setState({ mail1: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col span={24}>
                        <FloatLabel label="Site web" name="site_web" value={siteWeb}>
                          <Form.Item name="etablissement_site_web">
                            <Input
                              value={siteWeb}
                              onChange={(e) => this.setState({ siteWeb: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                    </Row>

                    <Divider orientation="left">Informations diverses</Divider>
                    <Row gutter={8} style={{ marginTop: 20 }}>
                      <Col span={24}>
                        <FloatLabel label="Zone libre 1" name="zone_libre_1" value={zoneLibre1}>
                          <Form.Item name="etablissement_zone_libre_1">
                            <Input
                              value={zoneLibre1}
                              onChange={(e) => this.setState({ zoneLibre1: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col span={24}>
                        <FloatLabel label="Zone libre 2" name="zone_libre_2" value={zoneLibre2}>
                          <Form.Item name="etablissement_zone_libre_2">
                            <Input
                              value={zoneLibre2}
                              onChange={(e) => this.setState({ zoneLibre2: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col span={24}>
                        <FloatLabel label="Zone libre 3" name="zone_libre_3" value={zoneLibre3}>
                          <Form.Item name="etablissement_zone_libre_3">
                            <Input
                              value={zoneLibre3}
                              onChange={(e) => this.setState({ zoneLibre3: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col span={24}>
                        <FloatLabel label="Zone libre 4" name="zone_libre_4" value={zoneLibre4}>
                          <Form.Item name="etablissement_zone_libre_4">
                            <Input
                              value={zoneLibre4}
                              onChange={(e) => this.setState({ zoneLibre4: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col span={24}>
                        <FloatLabel label="Zone libre 5" name="zone_libre_5" value={zoneLibre5}>
                          <Form.Item name="etablissement_zone_libre_5">
                            <Input
                              value={zoneLibre5}
                              onChange={(e) => this.setState({ zoneLibre5: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12} style={{ padding: 20 }}>
                    <Divider orientation="left">Adresse</Divider>
                    <Row gutter={8}>
                      <Col span={24}>
                        <PlacesAutocomplete
                          value={adresse}
                          onChange={this.handleChangeAdresse}
                          onSelect={this.handleSelectAdresse}
                        >
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                              <FloatLabel
                                label="Recherche GOOGLE"
                                name="adresse_gmaps_adresse"
                                value={adresse}
                              >
                                <Form.Item name="adresse_gmaps_adresse">
                                  <Input
                                    {...getInputProps({
                                      className: "location-search-input",
                                    })}
                                  />
                                </Form.Item>
                              </FloatLabel>
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Chargement ...</div>}
                                {suggestions.map((suggestion, i) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                    : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                      key={i}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </Col>

                      <Col span={8}>
                        <FloatLabel label="N°" name="adresse_numero" value={numero}>
                          <Form.Item name="adresse_numero">
                            <Input
                              value={numero}
                              onChange={(e) => this.setState({ numero: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                      <Col span={16}>
                        <FloatLabel label="Libellé voie" name="adresse_voie" value={voie}>
                          <Form.Item name="adresse_voie">
                            <Input
                              value={voie}
                              onChange={(e) => this.setState({ voie: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col span={24}>
                        <FloatLabel
                          label="Complément adresse"
                          name="complement_adresse"
                          value={complement}
                        >
                          <Form.Item name="complement_adresse">
                            <Input
                              value={complement}
                              onChange={(e) => this.setState({ complement: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                      <Col span={8}>
                        <FloatLabel label="CP" name="adresse_code_postal" value={cp}>
                          <Form.Item name="adresse_code_postal">
                            <Input
                              value={cp}
                              onChange={(e) => this.setState({ cp: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                      <Col span={16}>
                        <FloatLabel label="Ville" name="adresse_ville" value={ville}>
                          <Form.Item name="adresse_ville">
                            <Input
                              value={ville}
                              onChange={(e) => this.setState({ ville: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                      <Col span={8}>
                        <FloatLabel
                          label="Département"
                          name="adresse_departement"
                          value={departement}
                        >
                          <Form.Item name="adresse_departement">
                            <Input
                              value={departement}
                              onChange={(e) => this.setState({ departement: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                      <Col span={8}>
                        <FloatLabel label="Région" name="adresse_region" value={region}>
                          <Form.Item name="adresse_region">
                            <Input
                              value={region}
                              onChange={(e) => this.setState({ region: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                      <Col span={8}>
                        <FloatLabel label="Pays" name="adresse_pays" value={pays}>
                          <Form.Item name="adresse_pays">
                            <Input
                              value={pays}
                              onChange={(e) => this.setState({ pays: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Card className="appt-card">
                        <Row>
                          <div className={"autre"}>
                            <img alt="" key="{key}" src={``} className={"image"} />
                          </div>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <Upload disabled={false} {...props}>
                              {uploadButton}
                            </Upload>
                          </Col>
                        </Row>
                      </Card>
                    </Row>
                  </Col>
                </Row>
              ),
            },
          ]}
        />
        <Row style={{ marginTop: 10, float: "right" }}>
          <Button type="primary" danger>
            Annulation
          </Button>
          <Button type="primary" htmlType="submit" style={{ marginLeft: 10 }}>
            Validation
          </Button>
        </Row>
      </Form>
    );
  }
}

export default FormEtablissement;

import { codeCouleurToAdapted } from "./codeCouleurAdapter";
import { etapesActionsToAdapted } from "./etapeActionAdapter";

export function etapesToAdapted(items) {
  return items
    ? items.map((item) => {
        return etapeToAdapted(item);
      })
    : null;
}

export function etapeToAdapted(item) {
  return item
    ? {
        etape_id: item.id,
        etape_libelle: item.libelle,
        etape_icone: item.icone,
        etape_ordre: item.ordre,
        etape_todo: item.todo,
        etape_code_couleur: codeCouleurToAdapted(item.code_couleur),
        etape_etape_parente: etapeToAdapted(item.parente),
        etape_etape_action: etapesActionsToAdapted(item.etape_action),
      }
    : null;
}

export function adaptedToEtape(item) {
  return item
    ? {
        libelle: item.etape_libelle,
        icone: item.etape_icone,
        ordre: item.etape_ordre,
        todo: item.etape_todo,
        code_couleur_id: item.etape_code_couleur_id,
        parente_id: item.etape_etape_parente_id,
        etablissement_id: item.etape_etablissement_id,
      }
    : null;
}

import { HttpMethod, initFetch } from "../../utils/fetcher";
import {
  adaptedToRubriquePourcentage,
  rubriquePourcentageToAdapted,
} from "../../adapter/rubriquePourcentageAdapter";

export const SAVE_SUCCESS_RUBRIQUE_POURCENTAGE = "SAVE_SUCCESS_RUBRIQUE_POURCENTAGE";
export const SAVE_FAIL_RUBRIQUE_POURCENTAGE = "SAVE_FAIL_RUBRIQUE_POURCENTAGE";
export const DELETE_SUCCESS_RUBRIQUE_POURCENTAGE = "DELETE_SUCCESS_RUBRIQUE_POURCENTAGE";
export const DELETE_FAIL_RUBRIQUE_POURCENTAGE = "DELETE_FAIL_RUBRIQUE_POURCENTAGE";

export function saveRubriquePourcentage(jsonData, idPrincipale) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`rubriquesPourcentages`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToRubriquePourcentage(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.rubrique);
          dispatch({
            type: SAVE_SUCCESS_RUBRIQUE_POURCENTAGE,
            payload: rubriquePourcentageToAdapted(res.rubrique),
            idPrincipale,
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_RUBRIQUE_POURCENTAGE,
            error: err,
          })
        );
    });
  };
}

export function deleteRubriquePourcentage(idPrincipale, idLiee) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(
        `rubriquesPourcentages/${idPrincipale}/${idLiee}`,
        HttpMethod.DELETE
      );
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.rubrique);
          dispatch({
            type: DELETE_SUCCESS_RUBRIQUE_POURCENTAGE,
            payload: rubriquePourcentageToAdapted(res.rubrique),
            idPrincipale,
            idLiee,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_RUBRIQUE_POURCENTAGE,
            error: err,
          });
        });
    });
  };
}

import { adaptedToQuittanceLignes, quittanceLignesToAdapted } from "./quittanceLigneAdapter";

export function quittancesToAdapted(items) {
  return items
    ? items.map((item) => {
        return quittanceToAdapted(item);
      })
    : null;
}

export function quittanceToAdapted(item) {
  //Resource
  return item
    ? {
        index: item.index,
        quittance_id: item.id,
        quittance_libelle: item.libelle,
        quittance_date_debut: item.date_debut,
        quittance_date_fin: item.date_fin,
        quittance_date_paiement: item.date_paiement,
        quittance_location_id: item.location_id,
        quittance_ligne: quittanceLignesToAdapted(item.quittance_ligne),
      }
    : null;
}

export function adaptedToQuittance(item) {
  //Model
  return item
    ? {
        location_id: item.quittance_location_id,
        libelle: item.quittance_libelle,
        date_debut: item.quittance_date_debut,
        date_fin: item.quittance_date_fin,
        date_paiement: item.quittance_date_paiement,
        quittance_ligne: adaptedToQuittanceLignes(item.quittance_ligne),
      }
    : null;
}

import { adaptedToPersonne, personneToAdapted } from "./personneAdapter";

export function agentsToAdapted(items) {
  return items
    ? items.map((item) => {
        return agentToAdapted(item);
      })
    : null;
}

export function agentToAdapted(item) {
  return item
    ? {
        agent_id: item.id,
        agent_personne: personneToAdapted(item.personne),
        agent_code: item.code,
      }
    : null;
}

export function adaptedToAgent(item) {
  const agent = item
    ? {
        personne_id: item.agent_personne_id,
        code: item.agent_code,
      }
    : null;

  const personne = item ? adaptedToPersonne(item) : null;

  return item
    ? {
        ...agent,
        ...personne,
      }
    : null;
}

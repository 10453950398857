import React, { Component } from "react";

import { Tree } from "antd";

import { StopOutlined } from "@ant-design/icons";

class MyTreeEtapeGroupe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedKeys: [],
    };
  }

  componentDidMount() {
    this.loopKeys(this.props.treeEtapes);
  }

  UNSAFE_componentWillReceiveProps() {
    this.loopKeys(this.props.treeEtapes);
  }

  loopKeys = (treeEtapes) => {
    treeEtapes.map((item) => {
      this.state.expandedKeys.push(`${item.etape_id}`);

      if (item.children && item.children.length) this.loopKeys(item.children);

      return item;
    });

    this.setState({ expandedKeys: this.state.expandedKeys });
  };

  render() {
    const { expandedKeys } = this.state;
    const { treeEtapes, treeGroupes } = this.props;

    const loop = (treeEtapes) =>
      treeEtapes.map((item) => {
        if (item.level === 1) {
          item.icon = "";
          item.key = item.etape_id;
          item.title = (
            <span style={{ color: item.etape_code_couleur.code_couleur_hex }}>
              {item.etape_libelle} <StopOutlined />
            </span>
          );
          item.selectable = false;

          loop(item.children);

          return item;
        } else if (
          item.children &&
          item.children.length &&
          treeGroupes.some((etape) => etape.etape_id === item.etape_id)
        ) {
          item.icon = "";
          item.key = item.etape_id;
          item.title = (
            <span style={{ color: item.etape_code_couleur.code_couleur_hex }}>
              <del>{item.etape_libelle}</del>
            </span>
          );
          item.selectable = false;

          loop(item.children);

          return item;
        } else if (item.children && item.children.length) {
          item.icon = "";
          item.key = item.etape_id;
          item.title = (
            <span style={{ color: item.etape_code_couleur.code_couleur_hex }}>
              {item.etape_libelle}
            </span>
          );
          item.selectable = true;

          loop(item.children);

          return item;
        }
        if (treeGroupes.some((etape) => etape.etape_id === item.etape_id)) {
          item.icon = "";
          item.key = item.etape_id;
          item.title = (
            <span style={{ color: item.etape_code_couleur.code_couleur_hex }}>
              <del>{item.etape_libelle}</del>
            </span>
          );
          item.selectable = false;

          return item;
        } else {
          item.icon = "";
          item.key = item.etape_id;
          item.title = (
            <span style={{ color: item.etape_code_couleur.code_couleur_hex }}>
              {item.etape_libelle}
            </span>
          );
          item.selectable = true;

          return item;
        }
      });

    return (
      <div>
        {expandedKeys.length > 0 && (
          <Tree
            showLine
            multiple={false}
            defaultExpandAll
            className="draggable-tree"
            treeData={loop(treeEtapes)}
            defaultExpandedKeys={expandedKeys}
            onSelect={this.props.handleSelectEtape}
          />
        )}
      </div>
    );
  }
}

export default MyTreeEtapeGroupe;

export function languesToAdapted(items) {
  return items
    ? items.map((item) => {
        return langueToAdapted(item);
      })
    : null;
}

export function langueToAdapted(item) {
  return item
    ? {
        langue_id: item.id,
        langue_libelle: item.libelle,
        langue_code: item.code,
        langue_groupement_id: item.groupement_id,
      }
    : null;
}

export function adaptedToLangue(item) {
  return item
    ? {
        libelle: item.langue_libelle,
        code: item.langue_code,
        groupement_id: item.langue_groupement_id,
      }
    : null;
}

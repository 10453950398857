import { compteToAdapted } from "./compteAdapter";
import { adaptedToCompteBancaire, compteBancaireToAdapted } from "./compteBancaireAdapter";

export function journalsToAdapted(items) {
  return items
    ? items.map((item) => {
        return journalToAdapted(item);
      })
    : null;
}

export function journalToAdapted(item) {
  return item
    ? {
        journal_id: item.id,
        journal_code: item.code,
        journal_libelle: item.libelle,
        journal_societe_emettrice: item.societe_emettrice,
        journal_domiciliation_banque: item.domiciliation_banque,
        journal_liaison_reddition: item.liaison_reddition,
        journal_reglement_direct: item.reglement_direct,
        journal_de_banque: item.de_banque,
        journal_compte: compteToAdapted(item.compte),
        journal_compte_bancaire: compteBancaireToAdapted(item.compte_bancaire),
        journal_societe_id: item.societe_id,
      }
    : null;
}

export function adaptedToJournals(item) {
  const journal = item && {
    code: item.journal_code,
    libelle: item.journal_libelle,
    societe_emettrice: item.journal_societe_emettrice,
    domiciliation_banque: item.journal_domiciliation_banque,
    liaison_reddition: item.journal_liaison_reddition,
    reglement_direct: item.journal_reglement_direct,
    de_banque: item.journal_de_banque,
    compte_id: item.journal_compte_id,
    compte_bancaire_id: item.journal_compte_bancaire_id,
    societe_id: item.journal_societe_id,
  };

  const compteBancaire = item && adaptedToCompteBancaire(item);

  return item ? { ...journal, ...compteBancaire } : null;
}

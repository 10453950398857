import {
    LOAD_SUCCESS_RENDU_A_HISTORIQUE,
    LOAD_FAIL_RENDU_A_HISTORIQUE,
    SAVE_SUCCESS_RENDU_A_HISTORIQUE,
    SAVE_FAIL_RENDU_A_HISTORIQUE,
  } from "../actions/renduAHistoriqueAction";
  
  const initialState = {
    loaded: false,
  };
  
  export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
      case LOAD_SUCCESS_RENDU_A_HISTORIQUE:
        return {
          ...state,
          loading: false,
          loaded: true,
          data: action.payload,
          error: null,
        };
      case LOAD_FAIL_RENDU_A_HISTORIQUE:
        return {
          ...state,
          loading: false,
          loaded: false,
          data: null,
          error: action.error,
        };
      case SAVE_SUCCESS_RENDU_A_HISTORIQUE:
        return {
          ...state,
          loading: false,
          loaded: true,
          data: [...state.data, action.payload],
          error: null,
        };
      case SAVE_FAIL_RENDU_A_HISTORIQUE:
        return {
          ...state,
          loading: false,
          loaded: false,
          data: state.data,
          error: action.error,
        };
      default:
        return state;
    }
  }
  
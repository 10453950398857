import {
  LOAD_SUCCESS_PARAMETRES,
  LOAD_FAIL_PARAMETRES,
  SAVE_SUCCESS_PARAMETRES,
  SAVE_FAIL_PARAMETRES,
  UPDATE_SUCCESS_PARAMETRES,
  UPDATE_FAIL_PARAMETRES,
  UPDATE_SUCCESS_PARAMETRES_TITRE,
  UPDATE_FAIL_PARAMETRES_TITRE,
  UPDATE_SUCCESS_PARAMETRES_INACTIVE,
  UPDATE_FAIL_PARAMETRES_INACTIVE,
  DELETE_SUCCESS_PARAMETRES,
  DELETE_FAIL_PARAMETRES,
} from "../actions/parametreAction";

const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS_PARAMETRES:
      return {
        ...state,
        loaded: true,
        data: action.payload,
        error: null,
      };
    case LOAD_FAIL_PARAMETRES:
      return {
        ...state,
        loaded: false,
        data: null,
        error: action.error,
      };
    case SAVE_SUCCESS_PARAMETRES:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: [...state.data, action.payload],
        error: null,
      };
    case SAVE_FAIL_PARAMETRES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_PARAMETRES:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };
    case UPDATE_FAIL_PARAMETRES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_PARAMETRES_TITRE:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };
    case UPDATE_FAIL_PARAMETRES_TITRE:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_PARAMETRES_INACTIVE:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };
    case UPDATE_FAIL_PARAMETRES_INACTIVE:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case DELETE_SUCCESS_PARAMETRES:
      const filtered = state.data.filter((item) => {
        return item.parametre_id !== action.id;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filtered,
        error: null,
      };
    case DELETE_FAIL_PARAMETRES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    default:
      return state;
  }
}

import React, { Component } from "react";
import { Button, Col, Form, Row, Select, Switch } from "antd";
import CustomInput from "../CustomFormItem/Input";
import CustomSelect from "../CustomFormItem/Select";

const { Option, OptGroup } = Select;

class FormTypePaiement extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  // Référence du formulaire
  formRef = React.createRef();

  componentDidMount() {
    this.props.typePaiement
      ? this.initTypePaiement(this.props.typePaiement)
      : this.resetTypePaiement();
  }

  UNSAFE_componentWillReceiveProps(props) {
    props.typePaiement ? this.initTypePaiement(props.typePaiement) : this.resetTypePaiement();
  }

  initTypePaiement = (data) => {
    this.resetTypePaiement();

    this.formRef.current.setFieldsValue({
      type_paiement_libelle: data.type_paiement_libelle,
      type_paiement_bordereau: data.type_paiement_bordereau,
      type_paiement_compte_bancaire: data.type_paiement_compte_bancaire,
      type_paiement_journal_id: data.type_paiement_journal?.journal_id,
    });
  };

  resetTypePaiement = () => {
    this.formRef.current.resetFields([
      "type_paiement_libelle",
      "type_paiement_bordereau",
      "type_paiement_compte_bancaire",
      "type_paiement_journal_id",
    ]);
  };

  onFinish = (data) => {
    this.props.typePaiement
      ? this.props.handleEdit(data, this.props.typePaiement.type_paiement_id)
      : this.props.handleSubmit(data);
  };

  render() {
    const { typePaiement, journals } = this.props;

    return (
      <Form
        ref={this.formRef}
        onFinish={this.onFinish}
        size="large"
        className="form-requete"
        style={{ padding: 20 }}
      >
        <Row gutter={8}>
          <Col span={12}>
            <CustomInput
              label="Libellé"
              inputName="libelle"
              formItemName="type_paiement_libelle"
              objectValue={typePaiement && typePaiement.type_paiement_libelle}
            />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={6}>
            <Form.Item name="type_paiement_bordereau" valuePropName="checked">
              <Switch
                checkedChildren="Validation bordereau"
                unCheckedChildren="Validation bordereau"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <CustomSelect
              label="Journal"
              inputName="journal"
              formItemName="type_paiement_journal_id"
              objectValue={null}
            >
              <OptGroup label="Journals">
                {journals &&
                  journals.map((item, i) => {
                    return (
                      <Option
                        key={i}
                        value={item.journal_id}
                        label={`${item.journal_code} - ${item.journal_libelle}`}
                      >
                        <Row>
                          <Col sm={8}>{`${item.journal_code} - ${item.journal_libelle}`}</Col>
                        </Row>
                      </Option>
                    );
                  })}
              </OptGroup>
            </CustomSelect>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={6}>
            <Form.Item name="type_paiement_compte_bancaire" valuePropName="checked">
              <Switch checkedChildren="Compte bancaire" unCheckedChildren="Compte bancaire" />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginTop: 10, float: "right" }}>
          <Button type="primary" danger>
            Annulation
          </Button>
          <Button type="primary" htmlType="submit" style={{ marginLeft: 10 }}>
            Validation
          </Button>
        </Row>
      </Form>
    );
  }
}

export default FormTypePaiement;

import { HttpMethod, initFetch } from "../../utils/fetcher";

import {
  adaptedToRequete,
  requetesToAdapted,
  requeteToAdapted,
} from "../../adapter/requeteAdapter";

export const LOAD_SUCCESS_REQUETES = "LOAD_SUCCESS_REQUETES";
export const LOAD_FAIL_REQUETES = "LOAD_FAIL_REQUETES";
export const SAVE_SUCCESS_REQUETES = "SAVE_SUCCESS_REQUETES";
export const SAVE_FAIL_REQUETES = "SAVE_FAIL_REQUETES";
export const UPDATE_SUCCESS_REQUETE = "UPDATE_SUCCESS_REQUETE";
export const UPDATE_SUCCESS_REQUETES = "UPDATE_SUCCESS_REQUETES";
export const UPDATE_FAIL_REQUETES = "UPDATE_FAIL_REQUETES";
export const DELETE_SUCCESS_REQUETES = "DELETE_SUCCESS_REQUETES";
export const DELETE_FAIL_REQUETES = "DELETE_FAIL_REQUETES";

export function loadRequetes() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`requetes`, HttpMethod.GET);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.requete);
          dispatch({
            type: LOAD_SUCCESS_REQUETES,
            payload: requetesToAdapted(res.requete),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_REQUETES,
            error: err,
          });
        });
    });
  };
}

export function saveRequete(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`requetes`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToRequete(jsonData)) })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(requeteToAdapted(res.requete));
          dispatch({
            type: SAVE_SUCCESS_REQUETES,
            payload: requeteToAdapted(res.requete),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: SAVE_FAIL_REQUETES,
            error: err,
          });
        });
    });
  };
}

export function updateRequete(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`requetes/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToRequete(jsonData)) })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(requeteToAdapted(res.requete));
          dispatch({
            type: UPDATE_SUCCESS_REQUETE,
            payload: requeteToAdapted(res.requete),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: UPDATE_FAIL_REQUETES,
            error: err,
          });
        });
    });
  };
}

export function deleteRequete(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`requetes/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.requete);
          dispatch({
            type: DELETE_SUCCESS_REQUETES,
            payload: requeteToAdapted(res.requete),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_REQUETES,
            payload: err,
          });
        });
    });
  };
}

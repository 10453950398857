import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Spin,
} from "antd";
import { EyeOutlined, PlusOutlined } from "@ant-design/icons";

import { getInfoLocataire, getInfoRequete } from "../../utils/tools";

import FormParcoursClient from "../FormParcoursClient";
import FormApptSelectionnes from "../FormApptSelectionnes";
import FormRecherche from "../FormRecherche";
import FormResultat from "../FormResultat";
import SelectCRUD from "../SelectCRUD";
import { toast } from "react-toastify";
import ViewContrat from "../ViewContrat";
import FormApptChoisis from "../FormApptChoisis";

import dayjs from "dayjs";

const { Option, OptGroup } = Select;

const SelectRequete = (props) => {
  const [locataire, setLocataire] = useState(null);
  const [requetesFiltered, setRequetesFiltered] = useState(
    props.requetes.filter((req) => req.requete_fin === false)
  );
  const [requete, setRequete] = useState(null);
  const [resultats, setResultats] = useState(null);
  const [debut, setDebut] = useState(null);
  const [fin, setFin] = useState(null);
  const [row, setRow] = useState(null);
  const [hasGroupe, setHasGroupe] = useState(false);
  const [etapes, setEtapes] = useState([]);
  const [currentOrdre, setCurrentOrdre] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [groupe, setGroupe] = useState(null);
  const [commentaireRequete, setCommentaireRequete] = useState(null);
  const [modalProvenance, setModalProvenance] = useState(false);
  const [inputProvenance, setInputProvenance] = useState("");
  const [checkboxProvenance, setCheckboxProvenance] = useState(false);
  const [previewTitre, setPreviewTitre] = useState("");
  const [provenance, setProvenance] = useState(null);
  const {
    utilisateur,
    locataires,
    location,
    provenances,
    groupes,
    renduAs,
    parcoursClients,
    parametre,
  } = props;

  const formRef = React.createRef();
  const formRefLocation = React.createRef();
  const formRefRecherche = React.createRef();

  useEffect(() => {
    if (props.requete) {
      const requetes = props.requetes.filter(
        (item) =>
          item.requete_locataire.locataire_id === props.requete.requete_locataire.locataire_id
      );
      setRequetesFiltered(requetes);
      onChangeReq(props.requete.requete_id, false);
      props.parametre.parametre_parcours_client && initFormRequete(props.requete, props.renduAs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.requete, props.renduAs]);

  const onClickAddLoc = () => {
    props.addPanesPersonne(null, false);
  };

  const onClickShowLoc = () => {
    props.addPanesPersonne(locataire, false);
  };

  const onClickAddReq = () => {
    props.handleCreateRequete(locataire);
  };

  const onChangeLoc = (id) => {
    const locataire = props.locataires.find((e) => e.locataire_id === id);
    const requetes = props.requetes.filter((item) => item.requete_locataire.locataire_id === id);
    setLocataire(locataire);
    setRequetesFiltered(requetes);
    setResultats(null);
    onChangeReq(requetes.length > 0 ? requetes[0].requete_id : null, true);
  };

  const onClearLoc = () => {
    props.syncRequete(null);
    setLocataire(null);
    setRequete(null);
    setResultats(null);
  };

  const onChangeReq = (id, newRequete = true) => {
    if (id !== null) {
      const requete = props.requetes.find((e) => e.requete_id === id);
      props.parametre.parametre_parcours_client && initFormRequete(requete, renduAs);
      props.syncRequete(requete);
      setRequete(requete);
      newRequete === true && setResultats(null);
      const locataire = props.locataires?.find(
        (e) => e.locataire_id === requete.requete_locataire.locataire_id
      );
      setLocataire(locataire);
      location && props.parametre.parametre_parcours_client && initFormLocation();
    } else {
      props.parametre.parametre_parcours_client && initFormRequete(null, renduAs);
      setRequete(null);
      newRequete === true && setResultats(null);
    }
  };

  const initFormRequete = (requete, renduAs) => {
    if (!requete || !requete.requete_groupe) {
      formRef.current.setFieldsValue({
        requete_provenance_id: requete?.requete_provenance
          ? requete.requete_provenance.provenance_id
          : null,
        requete_groupe_id: null,
        rendu_a_commentaire: "",
        requete_commentaire: "",
      });

      setHasGroupe(false);
      setCurrentOrdre(0);
      setEtapes([]);
      setGroupe(null);
      return;
    }

    const renduA = renduAs.find((item) => {
      return item.rendu_a_requete.requete_id === requete.requete_id && item.rendu_a_actif === true;
    });

    let currentOrdre = 0;
    if (renduA) {
      const parcoursClient = props.parcoursClients.find((item) => {
        return (
          item.parcours_client_etape.etape_id === renduA.rendu_a_etape_id &&
          item.parcours_client_groupe.groupe_id === requete.requete_groupe.groupe_id
        );
      });
      if (parcoursClient !== undefined) currentOrdre = parcoursClient.parcours_client_ordre - 1;
    }

    const groupe = requete.requete_groupe;
    formRef.current.setFieldsValue({
      requete_provenance_id: requete.requete_provenance
        ? requete.requete_provenance.provenance_id
        : null,
      requete_groupe_id: props.groupes === undefined ? groupe.groupe_libelle : groupe.groupe_id,
      rendu_a_commentaire: renduA ? renduA.rendu_a_commentaire : "",
      requete_commentaire: requete.requete_commentaire,
    });

    setEtapes(renduA ? findEtapes(groupe) : []);
    setGroupe(groupe);
    setHasGroupe(!requete.requete_groupe);
    setCurrentOrdre(currentOrdre);
    setCommentaireRequete(requete.requete_commentaire);
  };

  const initFormLocation = () => {
    formRefLocation.current && formRefLocation.current.resetFields();
    formRefLocation.current &&
      formRefLocation.current.setFieldsValue({
        location_bail_signe: location.location_bail_signe,
        location_info_sejour: location.location_info_sejour,
        location_chauffeur_reserve: location.location_chauffeur_reserve,
        location_heure_arrivee: location.location_heure_arrivee,
        location_heure_depart: location.location_heure_depart,
      });
  };

  const findEtapes = (groupe) => {
    const parcoursClient = props.parcoursClients
      .filter((item) => groupe.groupe_id === item.parcours_client_groupe.groupe_id)
      .sort((a, b) => a.parcours_client_ordre - b.parcours_client_ordre);
    let etapes = [];
    parcoursClient.forEach((i) => {
      if (i.parcours_client_etape) etapes.push(i.parcours_client_etape);
    });
    return etapes;
  };

  const fillResultats = (resultats, requete, dates, requetesLots) => {
    let debut = null;
    let fin = null;
    const resultatsSorted = resultats.sort((a, b) => a.dispo - b.dispo);
    if (dates && dates !== undefined) {
      if (Array.isArray(dates)) {
        debut = dates[0].format("DD/MM/YYYY");
        fin = dates[1].format("DD/MM/YYYY");
      } else debut = dates.format("DD/MM/YYYY");
    }
    setResultats(resultatsSorted);
    setDebut(debut);
    setFin(fin);
  };

  const afficherAppartement = (appartement) => {
    props.addPanesLot(appartement);
  };

  const handleMouseOver = (row) => {
    setRow(row);
  };

  const addProvenance = () => {
    formRef.current.setFieldsValue({ input_provenance: "" });
    setPreviewTitre("Ajouter");
    setModalProvenance(true);
  };

  const editProvenance = (e, provenanceTemp) => {
    e.stopPropagation();
    formRef.current.setFieldsValue({
      input_provenance: provenanceTemp.provenance_libelle,
      checkbox_plateforme: provenanceTemp.provenance_plateforme,
    });
    setProvenance(provenanceTemp);
    setPreviewTitre("Editer");
    setModalProvenance(true);
    setInputProvenance(provenanceTemp.provenance_libelle);
    setCheckboxProvenance(provenanceTemp.provenance_plateforme);
  };

  const deleteProvenance = (e, provenanceTemp) => {
    e.stopPropagation();
    setProvenance(provenanceTemp);
    setPreviewTitre("Supprimer");
    setModalProvenance(true);
  };

  const handleClick = (previewTitre) => {
    switch (previewTitre) {
      case "Ajouter":
        handleAjouter();
        break;
      case "Editer":
        handleEditer();
        break;
      case "Supprimer":
        handleSupprimer();
        break;
      default:
        break;
    }
  };

  const handleAjouter = () => {
    props
      .saveProvenance({
        provenance_libelle: inputProvenance,
        provenance_plateforme: checkboxProvenance,
        provenance_etablissement_id: utilisateur.utilisateur_etablissement.etablissement_id,
      })
      .then(() => {
        setModalProvenance(false);
        setInputProvenance("");
        setCheckboxProvenance(false);
      });
  };

  const handleEditer = () => {
    props
      .updateProvenance(
        {
          provenance_libelle: inputProvenance,
          provenance_plateforme: checkboxProvenance,
        },
        provenance.provenance_id
      )
      .then(() => {
        setModalProvenance(false);
        setInputProvenance("");
        setCheckboxProvenance(false);
        setProvenance(null);
      });
  };

  const handleSupprimer = () => {
    props.deleteProvenance(provenance.provenance_id).then(() => {
      setProvenance(null);
      setPreviewTitre("");
      setModalProvenance(false);
      formRef.current.setFieldsValue({
        requete_provenance_id: undefined,
      });
    });
  };

  const onFinishFormRequete = (data) => {
    formRefLocation.current && location && formRefLocation.current.submit();
    data.requete_groupe_id &&
      handleUpdateRequete(data.requete_groupe_id, data.requete_provenance_id);
  };

  const onFinishFormLocation = (data) => {
    const updatedLocation = { ...location };
    updatedLocation.location_lot_id = location.location_lot.lot_id;
    updatedLocation.location_requete_id = location.location_requete.requete_id;
    updatedLocation.location_bail_signe = data.location_bail_signe;
    updatedLocation.location_info_sejour = data.location_info_sejour;
    updatedLocation.location_chauffeur_reserve = data.location_chauffeur_reserve;
    updatedLocation.location_heure_arrivee = data.location_heure_arrivee;
    updatedLocation.location_heure_depart = data.location_heure_depart;
    props.updateLocation(updatedLocation, updatedLocation.location_id);
  };

  const handleUpdateRequete = (groupeId, provenanceId) => {
    if (requete) {
      props
        .updateRequete(
          {
            requete_fin: false,
            requete_groupe_id: groupeId,
            requete_provenance_id: provenanceId,
            requete_locataire_id: requete.requete_locataire.locataire_id,
            requete_dossier_id: requete.requete_dossier?.dossier_id,
            requete_commentaire: commentaireRequete,
          },
          requete.requete_id
        )
        .then((res) => {
          // si groupe existant
          if (requete.requete_groupe) {
            // si changement de groupe
            if (requete.requete_groupe.groupe_id !== res.requete_groupe.groupe_id) {
              // récupérer le parcours client n°1 de ce groupe
              let firstPC = props.parcoursClients.find(
                (i) =>
                  i.parcours_client_ordre === 1 &&
                  i.parcours_client_groupe.groupe_id === res.requete_groupe.groupe_id
              );
              props
                .updateRenduA(
                  {
                    rendu_a_requete_id: requete.requete_id,
                    rendu_a_etape_id: firstPC.parcours_client_etape.etape_id,
                    rendu_a_actif: true,
                    rendu_a_signal: false,
                  },
                  requete.requete_id
                )
                .catch((err) => console.log(err));
            }
          } else {
            if (groupeId) {
              // récupérer le rendu_a sur lequel la requête se trouve
              // peut être undefined si la requête n'a actuellement pas de groupe
              const currRenduA = props.renduAs.find(
                (i) => i.rendu_a_requete.requete_id === requete.requete_id
              );

              if (currRenduA !== undefined) {
                props
                  .updateRenduA(
                    {
                      rendu_a_requete_id: requete.requete_id,
                      rendu_a_etape_id: currRenduA.rendu_a_etape_id,
                      rendu_a_actif: true,
                      rendu_a_signal: false,
                    },
                    requete.requete_id
                  )
                  .catch((err) => console.log(err));
              } else {
                // récupérer le parcours client n°1 de ce groupe
                let firstPC = props.parcoursClients.find(
                  (i) =>
                    i.parcours_client_ordre === 1 && i.parcours_client_groupe.groupe_id === groupeId
                );
                props
                  .saveRenduA({
                    rendu_a_actif: true,
                    rendu_a_requete_id: res.requete_id,
                    rendu_a_etape_id: firstPC.parcours_client_etape.etape_id,
                  })
                  .catch((err) => console.log(err));
              }
            }
          }

          props.syncRequete(res);
          props.initTree(utilisateur.utilisateur_id);

          toast.success(`Modification effectuée !`, {
            containerId: "A",
          });
        });
    }
  };

  const goTo = (nextEtape) => {
    const requeteId = requete.requete_id;
    props
      .updateRenduA(
        {
          rendu_a_requete_id: requeteId,
          rendu_a_etape_id: nextEtape.etape_id,
          rendu_a_actif: true,
          rendu_a_signal: false,
        },
        requeteId
      )
      .then(() => props.initTree(utilisateur.utilisateur_id))
      .catch((err) => console.log(err));
    setCurrentOrdre(etapes.findIndex((etape) => etape.etape_id === nextEtape.etape_id));
  };

  const goToPreviousEtape = () => {
    const ordre = currentOrdre - 1;
    const requeteId = requete.requete_id;
    props
      .updateRenduA(
        {
          rendu_a_requete_id: requeteId,
          rendu_a_etape_id: etapes[ordre].etape_id,
          rendu_a_actif: true,
          rendu_a_signal: false,
        },
        requeteId
      )
      .then(() => props.initTree(utilisateur.utilisateur_id))
      .catch((err) => console.log(err));
    setCurrentOrdre(ordre);
  };

  const goToNextEtape = () => {
    const ordre = currentOrdre + 1;
    const requeteId = requete.requete_id;
    props
      .updateRenduA(
        {
          rendu_a_requete_id: requeteId,
          rendu_a_etape_id: etapes[ordre].etape_id,
          rendu_a_actif: true,
          rendu_a_signal: false,
        },
        requeteId
      )
      .then(() => props.initTree(utilisateur.utilisateur_id))
      .catch((err) => console.log(err));
    setCurrentOrdre(ordre);
  };

  const onClickFinRequete = () => {
    props
      .updateRequete(
        {
          requete_fin: true,
          requete_groupe_id: requete.requete_groupe.groupe_id,
          requete_locataire_id: requete.requete_locataire.locataire_id,
          requete_provenance_id: requete.requete_provenance.provenance_id,
          requete_dossier_id: requete.requete_dossier?.dossier_id,
          requete_commentaire: requete.requete_commentaire,
        },
        requete.requete_id
      )
      .then((res) => {
        // props.updateRenduA(
        //   {
        //     rendu_a_actif: false,
        //     rendu_a_signal: false,
        //     rendu_a_requete_id: res.requete_id,
        //   },
        //   res.requete_id
        // );

        props.syncRequete(res);

        toast.success(`Modification effectuée !`, {
          containerId: "A",
        });
      })
      .then((res) => props.initTree(utilisateur.utilisateur_id));
  };

  const onKeyUpCommentaireRequete = (e) => {
    setCommentaireRequete(e.target.value);
  };

  const handleCancel = () => {
    setModalProvenance(false);
  };

  const onChangeInput = (e) => {
    setInputProvenance(e.target.value);
  };

  const onChangeCheckbox = (e) => {
    setCheckboxProvenance(e.target.checked);
  };

  const etat = (location) => {
    let str = "";
    switch (location?.location_etat) {
      case "r":
        str = "dot-reserve";
        break;
      case "o":
        str = "dot-option";
        break;
      case "a":
        str = "dot-annule";
        break;
      default:
        str = "dot-propose";
        break;
    }
    return str;
  };

  const addLocataireButton = (
    <Button type="primary" htmlType="reset" onClick={() => onClickAddLoc()}>
      <PlusOutlined />
    </Button>
  );

  const showLocataireButton = (
    <Button type="primary" onClick={() => onClickShowLoc()}>
      <EyeOutlined />
    </Button>
  );

  const addRequeteButton = (
    <Popconfirm
      title="Confirmez vous la création d'une nouvelle demande pour ce locataire ?"
      onConfirm={() => onClickAddReq()}
    >
      <Button type="primary" htmlType="reset">
        <PlusOutlined />
      </Button>
    </Popconfirm>
  );

  const footer = (
    <div>
      <Button type="primary" danger onClick={handleCancel}>
        Annuler
      </Button>
      <Button type="primary" onClick={() => handleClick(previewTitre)}>
        {previewTitre}
      </Button>
    </div>
  );

  return (
    <Spin spinning={false} size="large" tip="Chargement...">
      <div style={{ padding: 20 }}>
        <Row gutter={10}>
          <Col span={parametre.parametre_parcours_client ? 8 : 12}>
            <Row style={{ marginBottom: -20 }}>
              <Col span={22}>
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  allowClear
                  optionLabelProp="label"
                  placeholder="Locataires"
                  onChange={onChangeLoc}
                  value={locataire ? locataire.locataire_id : null}
                  onClear={onClearLoc}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <OptGroup label="Locataires">
                    {locataires &&
                      locataires.map((item, i) => {
                        return (
                          <Option key={i} value={item.locataire_id} label={getInfoLocataire(item)}>
                            <Row>
                              <Col sm={8}>{getInfoLocataire(item)}</Col>
                            </Row>
                          </Option>
                        );
                      })}
                  </OptGroup>
                </Select>
              </Col>
              {locataire ? (
                <Col span={1}>
                  <Form.Item>{showLocataireButton}</Form.Item>
                </Col>
              ) : (
                <Col span={1}>
                  <Form.Item>{addLocataireButton}</Form.Item>
                </Col>
              )}
            </Row>
            <Row style={{ marginBottom: -20 }}>
              <Col span={22}>
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  optionLabelProp="label"
                  placeholder="Demandes"
                  onChange={(value) => onChangeReq(value, true)}
                  defaultValue={null}
                  value={requete ? requete.requete_id : null}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <OptGroup label="Demandes">
                    {requetesFiltered &&
                      requetesFiltered.map((item, i) => {
                        return (
                          <Option key={i} value={item.requete_id} label={getInfoRequete(item)}>
                            <Row>
                              <Col sm={8}>
                                <span
                                  className={etat(
                                    props.locations?.find(
                                      (loc) => loc.location_requete?.requete_id === item.requete_id
                                    )
                                  )}
                                ></span>{" "}
                                {getInfoRequete(item)}
                              </Col>
                            </Row>
                          </Option>
                        );
                      })}
                  </OptGroup>
                </Select>
              </Col>
              {(locataire || requete) && (
                <Col span={1}>
                  <Form.Item>{addRequeteButton}</Form.Item>
                </Col>
              )}
            </Row>
            {(locataire || requete) && parametre.parametre_parcours_client && (
              <Form
                ref={formRefLocation}
                size="small"
                onFinish={onFinishFormLocation}
                style={{ padding: 5 }}
              >
                <Row gutter={10}>
                  <Col span={12}>
                    <Form.Item label="Téléphone" style={{ marginBottom: -10 }}>
                      <span>{locataire && locataire.locataire_personne.personne_tel1}</span>
                    </Form.Item>
                    <Form.Item label="Mail" style={{ marginBottom: -10 }}>
                      <span>{locataire && locataire.locataire_personne.personne_email1}</span>
                    </Form.Item>
                    <Form.Item label="Langue" style={{ marginBottom: -10 }}>
                      <span>
                        {locataire &&
                          locataire.locataire_personne.personne_langue &&
                          locataire.locataire_personne.personne_langue.langue_libelle}
                      </span>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="location_bail_signe"
                      valuePropName="checked"
                      style={{ marginBottom: 0 }}
                    >
                      <Checkbox onChange={null}>Bail signé reçu</Checkbox>
                    </Form.Item>
                    <Form.Item
                      name="location_info_sejour"
                      valuePropName="checked"
                      style={{ marginBottom: 0 }}
                    >
                      <Checkbox onChange={null}>Infos check-in / check-out reçu</Checkbox>
                    </Form.Item>
                    <Form.Item
                      name="location_chauffeur_reserve"
                      valuePropName="checked"
                      style={{ marginBottom: 0 }}
                    >
                      <Checkbox onChange={null}>Chauffeur réservé</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item name="location_heure_arrivee" label="Heure d'arrivée">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="location_heure_depart" label="Heure de départ">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span>
                      {requete &&
                        `Demande créée le ${dayjs(requete.requete_created).format("DD/MM/YYYY")}`}
                    </span>
                  </Col>
                </Row>
              </Form>
            )}
          </Col>
          {parametre.parametre_parcours_client && (
            <Col span={8}>
              <Form
                id={`form-requete-${requete ? requete.requete_id : "null"}`}
                ref={formRef}
                onFinish={onFinishFormRequete}
                className="form-requete"
              >
                <Modal
                  footer={footer}
                  title={"Ajouter"}
                  onCancel={handleCancel}
                  open={modalProvenance}
                >
                  <Row gutter={12}>
                    {previewTitre !== "Supprimer" ? (
                      <>
                        <Col span={12}>
                          <Form.Item name="input_provenance">
                            <Input addonBefore="Libellé" onChange={onChangeInput} />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name="checkbox_plateforme" valuePropName="checked">
                            <Checkbox onChange={(value) => onChangeCheckbox(value)}>
                              Plateforme ?
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </>
                    ) : (
                      <div style={{ fontSize: 16 }}>
                        <b>Supprimer cette provenance ?</b>
                      </div>
                    )}
                  </Row>
                </Modal>

                <Card className="requete-card" bordered={false}>
                  <SelectCRUD
                    row={row}
                    data={provenances}
                    groupe="provenance"
                    addValue={addProvenance}
                    editValue={editProvenance}
                    deleteValue={deleteProvenance}
                    handleMouseOver={handleMouseOver}
                  />
                </Card>
                <FormParcoursClient
                  formRef={formRef}
                  requete={requete}
                  hasGroupe={hasGroupe}
                  etapes={etapes}
                  currentOrdre={currentOrdre}
                  renduAs={renduAs}
                  groupes={groupes}
                  parametre={parametre}
                  parcoursClients={parcoursClients}
                  goTo={goTo}
                  goToNextEtape={goToNextEtape}
                  goToPreviousEtape={goToPreviousEtape}
                  onClickFinRequete={onClickFinRequete}
                  onKeyUpCommentaireRequete={onKeyUpCommentaireRequete}
                />
              </Form>
              <Card className="requete-card" size="small">
                <FormApptChoisis
                  isArchive={false}
                  requete={requete}
                  requetesLots={
                    requete
                      ? props.requetesLots.filter(
                          (item) => requete.requete_id === item.requete_lot_requete.requete_id
                        )
                      : []
                  }
                  lotsAjoutables={props.lots}
                  saveRequeteLot={props.saveRequeteLot}
                  deleteRequeteLot={props.deleteRequeteLot}
                  afficherAppartement={afficherAppartement}
                />
              </Card>
            </Col>
          )}
          <Col span={parametre.parametre_parcours_client ? 8 : 12}>
            <FormApptSelectionnes
              home={false}
              parametre={parametre}
              isArchive={false}
              requete={requete}
              location={location}
              requetesLots={
                requete
                  ? props.requetesLots.filter(
                      (item) => requete.requete_id === item.requete_lot_requete.requete_id
                    )
                  : []
              }
              lots={props.lots}
              locations={props.locations}
              currentOrdre={currentOrdre}
              parcoursClients={parcoursClients}
              syncRequete={props.syncRequete}
              saveLocation={props.saveLocation}
              updateLocation={props.updateLocation}
              deleteLocation={props.deleteLocation}
              saveRequeteLot={props.saveRequeteLot}
              updateRequeteLot={props.updateRequeteLot}
              updateAllRequetesLots={props.updateAllRequetesLots}
              deleteRequeteLot={props.deleteRequeteLot}
              onChangeTab={props.onChangeTab}
              afficherAppartement={afficherAppartement}
              saveRecherche={props.saveRecherche}
            />
          </Col>
        </Row>
        {requete && (!location || location.location_etat === "o") && (
          <div style={{ marginTop: -15 }}>
            <Divider orientation="left">Recherche</Divider>
            <Row>
              <Col span={24}>
                <FormRecherche
                  home={false}
                  isArchive={false}
                  utilisateur={utilisateur}
                  requetesLots={
                    requete
                      ? props.requetesLots.filter(
                          (item) => requete.requete_id === item.requete_lot_requete.requete_id
                        )
                      : []
                  }
                  lots={props.lots}
                  typesLots={props.typesLots}
                  equipements={props.equipements}
                  champsPersos={props.champsPersos}
                  champsPersosGroupes={props.champsPersosGroupes}
                  locations={props.locations}
                  formRefRequete={formRef}
                  formRefRecherche={formRefRecherche}
                  requete={requete}
                  fillResultats={fillResultats}
                  saveRecherche={props.saveRecherche}
                  deleteRecherche={props.deleteRecherche}
                  updateRequeteLot={props.updateRequeteLot}
                  deleteAllRequeteLot={props.deleteAllRequeteLot}
                />
              </Col>
            </Row>
          </div>
        )}
        {requete && (!location || location.location_etat === "o") && (
          <div style={{ marginTop: -20 }}>
            <Divider orientation="left">Résultat</Divider>
            <Row>
              <Col span={24}>
                <FormResultat
                  parametre={parametre}
                  resultats={resultats}
                  requete={requete}
                  debut={debut}
                  fin={fin}
                  locations={props.locations}
                  quittances={props.quittances}
                  afficherAppartement={afficherAppartement}
                  saveRequeteLot={props.saveRequeteLot}
                />
              </Col>
            </Row>
          </div>
        )}
        {requete && location && location.location_etat !== "o" && (
          <div style={{ marginTop: -15 }}>
            <Divider orientation="left">Récapitulatif contrat</Divider>
            <Row>
              <Col span={24}>
                <ViewContrat location={location} />
              </Col>
            </Row>
          </div>
        )}
      </div>
    </Spin>
  );
};

export default SelectRequete;

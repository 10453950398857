import {
  LOAD_SUCCESS_TEMPLATES,
  LOAD_FAIL_TEMPLATES,
  SAVE_SUCCESS_TEMPLATES,
  SAVE_FAIL_TEMPLATES,
  UPDATE_SUCCESS_TEMPLATES,
  UPDATE_FAIL_TEMPLATES,
  DELETE_SUCCESS_TEMPLATES,
  DELETE_FAIL_TEMPLATES,
} from "../actions/templateAction";

const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS_TEMPLATES:
      action.payload.forEach((item, i) => (item.key = item.template_id));
      return {
        ...state,
        loaded: true,
        data: action.payload,
        error: null,
      };
    case LOAD_FAIL_TEMPLATES:
      return {
        ...state,
        loaded: false,
        data: null,
        error: action.error,
      };
    case SAVE_SUCCESS_TEMPLATES:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: [...state.data, action.payload],
        error: null,
      };
    case SAVE_FAIL_TEMPLATES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_TEMPLATES:
      const updatedListe = [];

      state.data.forEach((item) => {
        if (item.template_id === action.payload.template_id) {
          item = action.payload;
        }
        updatedListe.push(item);
      });

      return {
        ...state,
        loading: false,
        loaded: true,
        data: updatedListe,
        error: null,
      };
    case UPDATE_FAIL_TEMPLATES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case DELETE_SUCCESS_TEMPLATES:
      const filtered = state.data.filter((item) => {
        return item.template_id !== action.id;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filtered,
        error: null,
      };
    case DELETE_FAIL_TEMPLATES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    default:
      return state;
  }
}

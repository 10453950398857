import { champPersoToAdapted } from "./champPersoAdapter";

export function lotsChampsPersosToAdapted(items) {
  return items
    ? items.map((item) => {
        return lotChampPersoToAdapted(item);
      })
    : null;
}

export function lotChampPersoToAdapted(item) {
  return item
    ? {
        lot_champ_perso_lot_id: item.lot_id,
        lot_champ_perso_champ_perso: champPersoToAdapted(item.champ_perso),
        lot_champ_perso_valeur: item.valeur,
      }
    : null;
}

export function adaptedToLotsChampsPersosList(items) {
  return items
    ? {
        list: items.list.map((item) => {
          return adaptedToLotChampPerso(item);
        }),
      }
    : null;
}

export function adaptedToLotsChampsPersos(items) {
  return items
    ? items.map((item) => {
        return adaptedToLotChampPerso(item);
      })
    : null;
}

export function adaptedToLotChampPerso(item) {
  return item
    ? {
        lot_id: item.lot_champ_perso_lot_id,
        champ_perso_id: item.lot_champ_perso_champ_perso_id,
        valeur: item.lot_champ_perso_valeur,
      }
    : null;
}

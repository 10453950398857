import { agentToAdapted } from "./agentAdapter";

export function menagesToAdapted(items) {
  return items
    ? items.map((item) => {
        return menageToAdapted(item);
      })
    : null;
}

export function menageToAdapted(item) {
  return item
    ? {
        menage_id: item.id,
        menage_date: item.date,
        menage_heure: item.heure,
        menage_etat: item.etat,
        menage_type: item.type,
        menage_lot_id: item.lot_id,
        menage_agent: agentToAdapted(item.agent),
        menage_location_id: item.location_id,
      }
    : null;
}

export function adaptedToMenage(item) {
  return item
    ? {
        date: item.menage_date,
        heure: item.menage_heure,
        etat: item.menage_etat,
        type: item.menage_type,
        lot_id: item.menage_lot_id,
        agent_id: item.menage_agent_id,
        location_id: item.menage_location_id,
      }
    : null;
}

export const headers = (personne) => {
  return [
    {
      label: "Société",
      key: personne + ".personne_compte.compte_etablissement_id",
    },
    {
      label: "Code",
      key: "proprietaire_code",
    },
    {
      label: "Nom",
      key: personne + ".personne_nom",
    },
    {
      label: "Prénom",
      key: personne + ".personne_prenom",
    },
    {
      label: "Téléphone",
      key: personne + ".personne_tel1",
    },
    {
      label: "Mail",
      key: personne + ".personne_email1",
    },
    {
      label: "Ville",
      key: personne + ".personne_adresse.adresse_ville",
    },
    {
      label: "Pays",
      key: personne + ".personne_adresse.adresse_pays",
    },
  ];
};

import { etapeToAdapted } from "./etapeAdapter";
import { templateToAdapted } from "./templateAdapter";

export function etapesTemplatesToAdapted(items) {
  return items
    ? items.map((item) => {
        return etapesTemplatesoAdapted(item);
      })
    : null;
}

export function etapesTemplatesoAdapted(item) {
  return item
    ? {
        etape_template_etape: etapeToAdapted(item.etape),
        etape_template_template: templateToAdapted(item.template),
      }
    : null;
}

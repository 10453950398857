import {
  adaptedToChampPersoGroupe,
  adaptedToChampsPersosGroupes,
  champPersoGroupeToAdapted,
  champsPersosGroupesToAdapted,
} from "../../adapter/champPersoGroupeAdapter";
import { HttpMethod, initFetch } from "../../utils/fetcher";

export const LOAD_SUCCESS_CHAMPS_PERSOS_GROUPES = "LOAD_SUCCESS_CHAMPS_PERSOS_GROUPES";
export const LOAD_FAIL_CHAMPS_PERSOS_GROUPES = "LOAD_FAIL_CHAMPS_PERSOS_GROUPES";
export const SAVE_SUCCESS_CHAMPS_PERSOS_GROUPES = "SAVE_SUCCESS_CHAMPS_PERSOS_GROUPES";
export const SAVE_FAIL_CHAMPS_PERSOS_GROUPES = "SAVE_FAIL_CHAMPS_PERSOS_GROUPES";
export const UPDATE_SUCCESS_CHAMPS_PERSOS_GROUPES = "UPDATE_SUCCESS_CHAMPS_PERSOS_GROUPES";
export const UPDATE_SUCCESS_ORDRE_CHAMPS_PERSOS_GROUPES =
  "UPDATE_SUCCESS_ORDRE_CHAMPS_PERSOS_GROUPES";
export const UPDATE_SUCCESS_ORDRE_CHAMPS_PERSOS = "UPDATE_SUCCESS_ORDRE_CHAMPS_PERSOS";
export const UPDATE_FAIL_CHAMPS_PERSOS_GROUPES = "UPDATE_FAIL_CHAMPS_PERSOS_GROUPES";
export const DELETE_SUCCESS_CHAMPS_PERSOS_GROUPES = "DELETE_SUCCESS_CHAMPS_PERSOS_GROUPES";
export const DELETE_FAIL_CHAMPS_PERSOS_GROUPES = "DELETE_FAIL_CHAMPS_PERSOS_GROUPES";

export function loadChampsPersosGroupes() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`champsPersosGroupes`, HttpMethod.GET);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.champ_perso_groupe);
          dispatch({
            type: LOAD_SUCCESS_CHAMPS_PERSOS_GROUPES,
            payload: champsPersosGroupesToAdapted(res.champ_perso_groupe),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_CHAMPS_PERSOS_GROUPES,
            error: err,
          });
        });
    });
  };
}

export function saveChampPersoGroupe(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`champsPersosGroupes`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToChampPersoGroupe(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.champ_perso_groupe);
          dispatch({
            type: SAVE_SUCCESS_CHAMPS_PERSOS_GROUPES,
            payload: champPersoGroupeToAdapted(res.champ_perso_groupe),
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_CHAMPS_PERSOS_GROUPES,
            error: err,
          })
        );
    });
  };
}

export function updateChampPersoGroupe(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`champsPersosGroupes/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToChampPersoGroupe(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.champ_perso_groupe);
          dispatch({
            type: UPDATE_SUCCESS_CHAMPS_PERSOS_GROUPES,
            payload: champPersoGroupeToAdapted(res.champ_perso_groupe),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_CHAMPS_PERSOS_GROUPES,
            error: err,
          })
        );
    });
  };
}

export function updateOrdreChampPersoGroupe(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`ordre/champsPersosGroupes`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToChampsPersosGroupes(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.champ_perso_groupe);
          dispatch({
            type: UPDATE_SUCCESS_ORDRE_CHAMPS_PERSOS_GROUPES,
            payload: champsPersosGroupesToAdapted(res.champ_perso_groupe),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_CHAMPS_PERSOS_GROUPES,
            error: err,
          })
        );
    });
  };
}

export function deleteChampPersoGroupe(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`champsPersosGroupes/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.champ_perso_groupe);
          dispatch({
            type: DELETE_SUCCESS_CHAMPS_PERSOS_GROUPES,
            payload: champPersoGroupeToAdapted(res.champ_perso_groupe),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_CHAMPS_PERSOS_GROUPES,
            error: err,
          });
        });
    });
  };
}

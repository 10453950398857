import React, { Component } from "react";
import { Table, Typography } from "antd";

import dayjs from "dayjs";

const { Text } = Typography;

class TableSituation extends Component {
  render() {
    const { lignesEcritures } = this.props;

    let columns = [
      {
        title: "N°Ecriture",
        dataIndex: "ecriture_id",
        key: "ecriture_id",
        align: "right",
        width: "8%",
      },
      {
        title: "Date",
        dataIndex: "ecriture_date_ecriture",
        key: "ecriture_date_ecriture",
        width: "8%",
        sorter: (a, b) =>
          dayjs(a.ecriture_date_ecriture).unix() - dayjs(b.ecriture_date_ecriture).unix(),
      },
      {
        title: "Libellé",
        dataIndex: "ecriture_ligne_libelle",
        key: "ecriture_ligne_libelle",
        width: "24%",
      },
      {
        title: "Jrn",
        dataIndex: "ecriture_journal_code",
        key: "ecriture_journal_code",
        width: "5%",
      },
      {
        title: "Montant Débit",
        dataIndex: "ecriture_ligne_montant_debit",
        key: "ecriture_ligne_montant_debit",
        render: (montant) =>
          parseFloat(montant) !== 0 ? (
            parseFloat(montant).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })
          ) : (
            <></>
          ),
        className: "column-money",
        align: "right",
        width: "15%",
      },
      {
        title: "Montant Crédit",
        dataIndex: "ecriture_ligne_montant_credit",
        key: "ecriture_ligne_montant_credit",
        render: (montant) =>
          parseFloat(montant) !== 0 ? (
            parseFloat(montant).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })
          ) : (
            <></>
          ),
        className: "column-money",
        align: "right",
        width: "15%",
      },
      {
        title: "Solde évolutif",
        dataIndex: "solde",
        key: "solde",
        align: "right",
        width: "15%",
      },
    ];

    return (
      <Table
        className="table-ecritures"
        columns={columns}
        dataSource={lignesEcritures}
        rowKey="ecriture_ligne_id"
        pagination={false}
        scroll={{ y: 600 }}
        summary={(pageData) => {
          let totalDebit = 0;
          let totalCredit = 0;
          let solde = 0;

          pageData.forEach(({ ecriture_ligne_montant_debit, ecriture_ligne_montant_credit }) => {
            totalDebit += parseFloat(ecriture_ligne_montant_debit);
            totalCredit += parseFloat(ecriture_ligne_montant_credit);
          });

          solde = parseFloat(totalDebit - totalCredit);

          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell>
                  <strong>TOTAL :</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text style={{ float: "right" }}>
                    <strong>
                      {parseFloat(totalDebit).toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </strong>
                  </Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text style={{ float: "right" }}>
                    <strong>
                      {parseFloat(totalCredit).toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </strong>
                  </Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell>
                  <strong>SOLDE :</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text style={{ float: "right" }}>
                    <strong>
                      {solde >= 0 ? (
                        parseFloat(Math.abs(solde)).toLocaleString("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        })
                      ) : (
                        <></>
                      )}
                    </strong>
                  </Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text style={{ float: "right" }}>
                    <strong>
                      {solde <= 0 ? (
                        parseFloat(Math.abs(solde)).toLocaleString("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        })
                      ) : (
                        <></>
                      )}
                    </strong>
                  </Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    );
  }
}

export default TableSituation;

import React, { Component } from "react";
import ReactDOMServer from "react-dom/server";

import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Row,
  Select,
  Switch,
  Tabs,
  Modal,
  Input,
  Tooltip,
  Badge,
} from "antd";

import CustomInput from "../CustomFormItem/Input";
import CustomSelect from "../CustomFormItem/Select";
import CustomDatePicker from "../CustomFormItem/DatePicker";

import FormAdresse from "../FormAdresse";
import SelectCRUD from "../SelectCRUD";
import FormEquipement from "../FormEquipement";
import FormDescription from "../FormDescription";
import FormElement from "../FormElement";
import FormPiece from "../FormPiece";
import FormTarifLot from "../FormTarifLot";
import FormCalendrier from "../FormCalendrier";
import FormPhoto from "../FormPhoto";
import FormDocument from "../FormDocument";

import { getInfoProprietaire } from "../../utils/tools";
import { getInfoImmeuble } from "../../utils/tools";
import { EyeOutlined, GlobalOutlined, CopyOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import Constants from "../../utils/constants";

import draftToHtml from "draftjs-to-html";
import { EditorState, convertToRaw, convertFromRaw, ContentState, convertFromHTML } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import DescriptifPDF from "../Pdf/DescriptifPDF";
import { HttpMethod, initFetch } from "../../utils/fetcher";
import { openPdf } from "../../utils/utils";
import TableNote from "../TableNote";

const { Option, OptGroup } = Select;

class FormApptLarge extends Component {
  state = {
    langueSelected: this.props.langues && this.props.langues[0].langue_id,
    key: "1",
    societe: "",
    row: null,
    proprietaire: null,
    immeuble: null,
    metros: [],
    quartiers: [],
    equipements: [],
    chambres: null,
    sdb: null,
    sde: null,
    toilettes: null,
    litsDoubles: null,
    litsSimples: null,
    editorState: EditorState.createEmpty(),
    enLigne: false,
    mandatExclusif: false,
    previewTitre: "",
    groupe: "",
    uniteMinimum: null,
    uniteMaximum: null,
    adresse: null,
    dossier: null,
    premierClic: false,
    modalPdf: false,
    nbNuites: null,
    commercial: false,
    typeBlocTexte: [],
    year: dayjs().year(),
  };

  // Référence du formulaire
  formRef = React.createRef();

  componentDidMount() {
    if (this.props.lot) {
      this.initLot(this.props.lot);
      this.initLotChampsPersos(this.props.lot);
      this.initLotEquipements(this.props.lot);
      this.initLotElements(this.props.lot);
      this.initLotDescription(this.props.lot, this.state.langueSelected);
    } else this.initChampsPersos(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.initChampsPersos(this.props);
    if (props.lot && this.props.lot !== props.lot) {
      this.initLot(props.lot);
      this.initLotChampsPersos(props.lot);
      this.initLotEquipements(props.lot);
    } else props.nouveau && this.resetLot();
    props.lot && this.initLotElements(props.lot);
    props.lot && this.initLotDescription(props.lot, this.state.langueSelected);
  }

  initLot = (lot) => {
    this.resetLot();

    let lot_type_contrat = [];
    if (lot.lot_alur) lot_type_contrat.push("Alur");
    if (lot.lot_court_terme) lot_type_contrat.push("Court terme");
    if (lot.lot_long_terme) lot_type_contrat.push("Long terme");

    // Infos pièces
    const chambres = this.findNbPieces(lot, "Chambre").toString();
    const sdb = this.findNbPieces(lot, "Salle de bains").toString();
    const sde = this.findNbPieces(lot, "Salle d'eau").toString();
    const toilettes = this.findNbPieces(lot, "Toilettes").toString();
    const litsDoubles = this.findNbContenusPieces(lot, "Lits doubles").toString();
    const litsSimples = this.findNbContenusPieces(lot, "Lits simples").toString();

    this.calculNbNuites(lot);

    // Adresse
    const adresse = lot.lot_adresse;

    // Dossier
    // const dossier = lot.lot_dossier;

    this.formRef.current.setFieldsValue({
      // personne_societe_id:
      //   lot.lot_proprietaire.proprietaire_personne.personne_etablissement.etablissement_societe_id,
      // personne_etablissement_id:
      //   lot.lot_proprietaire.proprietaire_personne.personne_etablissement.etablissement_id,
      lot_type_contrat,
      lot_reference: lot.lot_reference,
      lot_designation: lot.lot_designation,
      lot_type_lot_id: lot.lot_type_lot ? lot.lot_type_lot.type_lot_id : null,
      lot_confort: lot.lot_confort,
      lot_classement: lot.lot_classement,
      lot_surface: lot.lot_surface,
      lot_etage: lot.lot_etage,
      lot_niveau: lot.lot_niveau,
      lot_nb_salles_bains: sdb,
      lot_nb_salles_eau: sde,
      lot_couchage: lot.lot_couchage,
      lot_nb_pieces: lot.lot_nb_pieces,
      lot_nb_chambres: lot.lot_nb_chambres,
      lot_nb_sdb: lot.lot_nb_sdb,
      lot_nb_se: lot.lot_nb_se,
      lot_nb_toilettes: lot.lot_nb_toilettes,
      lot_nb_lits_doubles: lot.lot_nb_lits_doubles,
      lot_nb_lits_simples: lot.lot_nb_lits_simples,
      lot_nb_canapes_lits: lot.lot_nb_canapes_lits,
      lot_depot_garantie: lot.lot_depot_garantie,
      lot_code_porte: lot.lot_code_porte,
      lot_code_wifi: lot.lot_code_wifi,
      lot_reseau_wifi: lot.lot_reseau_wifi,
      lot_reference_administrative: lot.lot_reference_administrative,
      lot_unite_minimum: lot.lot_unite_minimum,
      lot_unite_maximum: lot.lot_unite_maximum,
      lot_duree_minimum: lot.lot_duree_minimum,
      lot_duree_maximum: lot.lot_duree_maximum,
      lot_proprietaire_id: lot.lot_proprietaire.proprietaire_id,
      lot_immeuble_id: lot.lot_immeuble ? lot.lot_immeuble.immeuble_id : null,
      lot_numero_mandat: lot.lot_numero_mandat,
      lot_date_signature_mandat: this.formatDate(lot.lot_date_signature_mandat),
      lot_mandat_exclusif: lot.lot_mandat_exclusif,
      lot_date_echeance_mandat: this.formatDate(lot.lot_date_echeance_mandat),
      lot_date_renouvellement_mandat: this.formatDate(lot.lot_date_renouvellement_mandat),
      lot_date_fin_mandat: this.formatDate(lot.lot_date_fin_mandat),
      lot_nuite: lot.lot_nuite,
      lot_pourcentage_frais_agence: lot.lot_pourcentage_frais_agence,
      lot_local_commercial: lot.lot_local_commercial,
      lot_en_ligne: lot.lot_en_ligne,
      lot_commentaire: lot.lot_commentaire,
      lot_remarques: lot.lot_remarques,
      lot_frais_gestion_ct: lot.lot_frais_gestion_ct,
      lot_frais_gestion_lt: lot.lot_frais_gestion_lt,
      lot_frais_gestion_alur: lot.lot_frais_gestion_alur,

      adresse_gmaps_adresse: adresse?.adresse_gmaps_adresse,
      adresse_numero: adresse?.adresse_numero,
      adresse_voie: adresse?.adresse_voie,
      adresse_code_postal: adresse?.adresse_code_postal,
      adresse_ville: adresse?.adresse_ville,
      adresse_departement: adresse?.adresse_departement,
      adresse_region: adresse?.adresse_region,
      adresse_pays: adresse?.adresse_pays,
      adresse_gmaps_lat: adresse?.adresse_gmaps_lat,
      adresse_gmaps_lng: adresse?.adresse_gmaps_lng,
    });

    this.setState({
      lot,
      chambres,
      sdb,
      sde,
      toilettes,
      litsDoubles,
      litsSimples,
      proprietaire: lot.lot_proprietaire,
      enLigne: lot.lot_en_ligne,
      mandatExclusif: lot.lot_mandat_exclusif,
      immeuble: lot.lot_immeuble,
      adresse: lot.lot_adresse,
      dossier: lot.lot_dossier,
      uniteMinimum: lot.lot_unite_minimum,
      uniteMaximum: lot.lot_unite_maximum,
      commercial: lot.lot_local_commercial,
    });
    // this.setState({
    //   societe:
    //     lot.lot_proprietaire.proprietaire_personne.personne_etablissement.etablissement_societe_id,
    // });
  };

  calculNbNuites = (lot) => {
    // Infos locations
    let nbNuites = 0;

    if (this.props.locations) {
      const locationsFiltered = this.props.locations.filter(
        (l) =>
          l.location_lot.lot_id === lot.lot_id &&
          (l.location_etat === "r" || l.location_etat === "o")
      );

      locationsFiltered.forEach((location) => {
        const dates = this.getAllDates(location.location_date_debut, location.location_date_fin);
        dates.forEach((date) => {
          if (date.year() === this.state.year) {
            nbNuites += 1;
          }
        });
      });
    }

    this.formRef.current.setFieldsValue({ nbNuites: nbNuites });
    this.setState({ nbNuites });
  };

  getAllDates = (debut, fin) => {
    const dates = [];
    let start = dayjs(debut, "YYYY-MM-DD");
    while (start.isBefore(dayjs(fin, "YYYY-MM-DD"))) {
      dates.push(start);
      start = start.add(1, "day");
    }
    return dates;
  };

  findNbPieces = (lot, libelle) => {
    return this.props.pieces.filter(
      (piece) => piece.piece_lot_id === lot.lot_id && piece.piece_libelle.includes(libelle)
    ).length;
  };

  findNbContenusPieces = (lot, libelle) => {
    let nb = 0;
    const piece = this.props.pieces.filter(
      (piece) => piece.piece_lot_id === lot.lot_id && piece.piece_libelle.includes(libelle)
    );
    piece.length > 0 &&
      piece.piece_contenu.forEach((contenu) => {
        nb += parseInt(
          contenu.filter((item) =>
            item.piece_contenu_champ_perso.champ_perso_libelle.includes(libelle)
          ).piece_contenu_valeur
        );
      });
    return nb;
  };

  resetLot = () => {
    this.formRef.current.resetFields();

    let lot_type_contrat = [];
    lot_type_contrat.push("Court terme");

    this.formRef.current.setFieldsValue({
      lot_type_contrat,
    });
  };

  initChampsPersos = (props) => {
    let metros = [];
    let quartiers = [];
    props.champsPersos &&
      props.champsPersos.forEach((item) => {
        if (item.champ_perso_groupe_type === 1) quartiers.push(item);
        if (item.champ_perso_groupe_type === 2) metros.push(item);
      });

    quartiers.sort((a, b) => {
      if (a.champ_perso_libelle < b.champ_perso_libelle) {
        return -1;
      }
      if (a.champ_perso_libelle > b.champ_perso_libelle) {
        return 1;
      }
      return 0;
    });
    metros.sort((a, b) => a.champ_perso_libelle - b.champ_perso_libelle);
    // if (a.champ_perso_libelle < b.champ_perso_libelle) {
    //   return -1;
    // }
    // if (a.champ_perso_libelle > b.champ_perso_libelle) {
    //   return 1;
    // }
    // return 0;
    //});

    this.setState({
      metros,
      quartiers,
    });
  };

  initLotChampsPersos = (lot) => {
    let quartiers = [];
    let metros = [];
    if (lot.lot_quartiers !== undefined && lot.lot_quartiers !== null) {
      lot.lot_quartiers.forEach((item) => {
        quartiers.push(item.champ_perso_id);
      });
    }
    if (lot.lot_metros !== undefined && lot.lot_metros !== null) {
      lot.lot_metros.forEach((item) => {
        metros.push(item.champ_perso_id);
      });
    }
    metros.sort((a, b) => a.champ_perso_libelle - b.champ_perso_libelle);
    this.formRef.current.setFieldsValue({
      lot_adresse_quartier: quartiers,
      lot_adresse_metro: metros,
    });
  };

  initLotEquipements = (lot) => {
    const lotEquipements = lot.lot_equipements;
    this.props.equipements.forEach((item) => {
      if (lotEquipements?.some((i) => i.equipement_id === item.equipement_id)) {
        this.formRef.current.setFieldsValue({
          [`equipement_${item.equipement_id}`]: true,
        });
      }
    });
  };

  initLotElements = (lot) => {
    let typeBlocTexte = [];
    if (lot.lot_elements) {
      lot.lot_elements.forEach((item) => {
        let value = item.lot_champ_perso_valeur;
        if (item.lot_champ_perso_champ_perso.champ_perso_type === 1) {
          if (value === "true") value = true;
          else if (value === "false") value = false;
          else value = false;
        } else if (item.lot_champ_perso_champ_perso.champ_perso_type === 3) {
          if (value === "null" || value === "undefined" || value === "false") value = "";
          if (value === null || value === undefined) value = "";
        } else if (item.lot_champ_perso_champ_perso.champ_perso_type === 4) {
          typeBlocTexte.push(item);
        }
        item.lot_champ_perso_champ_perso.champ_perso_type !== 4 &&
          this.formRef.current.setFieldsValue({
            [`${item.lot_champ_perso_champ_perso.champ_perso_id}`]: value,
          });
      });
    }
    if (lot.lot_champs_persos) {
      lot.lot_champs_persos.forEach((item) => {
        let value = item.lot_champ_perso_valeur;
        if (item.lot_champ_perso_champ_perso.champ_perso_type === 1) {
          if (value === "true") value = true;
          else if (value === "false") value = false;
          else value = false;
        } else if (item.lot_champ_perso_champ_perso.champ_perso_type === 3) {
          if (value === "null" || value === "undefined" || value === "false") value = "";
          if (value === null || value === undefined) value = "";
        } else if (item.lot_champ_perso_champ_perso.champ_perso_type === 4) {
          typeBlocTexte.push(item);
        }
        item.lot_champ_perso_champ_perso.champ_perso_type !== 4 &&
          this.formRef.current.setFieldsValue({
            [`${item.lot_champ_perso_champ_perso.champ_perso_id}`]: value,
          });
      });
    }
    this.setState({ typeBlocTexte });
  };

  initLotDescription = (lot, i) => {
    const desc = lot.lot_descriptions?.find((item) => {
      if (item.description_langue) return item.description_langue.langue_id === i;
      return undefined;
    });

    if (desc === undefined) {
      this.formRef.current.resetFields([
        "description_titre",
        "description_resume",
        "description_lot_url",
        "description_indication_acces",
      ]);
      this.setEditorState("");
      return;
    }

    this.formRef.current.setFieldsValue({
      description_titre: desc.description_titre,
      description_resume: desc.description_resume,
      description_lot_url: desc.description_lot_url,
      description_indication_acces: desc.description_indication_acces,
      description_libre: desc.description_libre,
    });

    let html = desc.description_html;

    this.setEditorState(html);
  };

  onChangeSociete = (id) => {
    this.setState({ societe: id });
  };

  handleMouseOver = (row) => {
    this.setState({ row });
  };

  onChangeProprietaire = (id) => {
    this.setState({
      proprietaire: this.props.proprietaires.find((item) => item.proprietaire_id === id),
    });
  };

  onChangeImmeuble = (id) => {
    this.setState({
      immeuble: this.props.immeubles.find((item) => item.immeuble_id === id),
    });
  };

  onChangeEnLigne = (checked) => {
    this.setState({ enLigne: checked });
  };

  onChangeCommercial = (checked) => {
    this.setState({ commercial: checked });
  };

  onChangeMandatExclusif = (checked) => {
    this.setState({ mandatExclusif: checked });
  };

  onClickShowPro = (key, title) => {
    this.props.addPanesPersonne(this.state.proprietaire, true);
  };

  onClickShowImm = (key, title) => {
    this.props.addPanesImmeuble(this.state.immeuble);
  };

  copyAdress = () => {
    this.setState({
      adresse: this.props.lot.lot_immeuble.immeuble_adresse,
    });
  };

  onFinish = (data) => {
    this.setState({ premierClic: true });
    this.handleLot(data, this.state.proprietaire, this.state.immeuble);
  };

  handleLot = (data, proprietaire, immeuble) => {
    if (proprietaire !== null) {
      const lot = this.props.lot;
      let jsonData = this.getJsonLot(data, proprietaire, immeuble);
      if (lot) {
        this.props.updateLot(jsonData, lot.lot_id).then((res) => {
          toast.success("Informations enregistrées", { containerId: "A" });
          this.handleDescription(lot.lot_descriptions);
          this.handleElements(data, lot);
          this.setState({ premierClic: false });
        });
      } else {
        this.props.saveDossier(this.getJsonDossier()).then((dossier) => {
          jsonData = this.getJsonLot(data, proprietaire, immeuble, dossier);
          this.props.saveLot(jsonData).then((lot) => {
            this.props
              .updateDossier(this.getJsonDossier(lot), dossier.dossier_id)
              .then((dos) => (lot.lot_dossier = dos));
            lot = this.handleEquipements(data, lot);
            toast.success("Informations enregistrées", { containerId: "A" });
            this.handleElements(data, lot);
            this.setState({ premierClic: false });
          });
        });
      }
    } else {
      toast.error("Veuillez sélectionner un propriétaire", {
        containerId: "A",
      });
    }
  };

  getJsonLot = (data, proprietaire, immeuble, dossier) => {
    return {
      lot_reference: data.lot_reference,
      lot_designation: data.lot_designation,
      lot_duree_minimum:
        data.lot_duree_minimum !== undefined
          ? data.lot_duree_minimum
          : this.props.lot
          ? this.props.lot.lot_duree_minimum
          : null,
      lot_unite_minimum:
        data.lot_unite_minimum !== undefined
          ? data.lot_unite_minimum
          : this.props.lot
          ? this.props.lot.lot_unite_minimum
          : "",
      lot_duree_maximum:
        data.lot_duree_maximum !== undefined
          ? data.lot_duree_maximum
          : this.props.lot
          ? this.props.lot.lot_duree_maximum
          : null,
      lot_unite_maximum:
        data.lot_unite_maximum !== undefined
          ? data.lot_unite_maximum
          : this.props.lot
          ? this.props.lot.lot_unite_maximum
          : "",
      lot_code_porte: data.lot_code_porte,
      lot_surface: data.lot_surface,
      lot_etage: data.lot_etage,
      lot_niveau: data.lot_niveau,
      lot_couchage: data.lot_couchage,
      lot_commentaire: data.lot_commentaire,
      lot_remarques: data.lot_remarques,
      lot_nuite:
        data.lot_nuite !== undefined
          ? parseFloat(data.lot_nuite, 2)
          : this.props.lot
          ? this.props.lot.lot_nuite
          : null,
      lot_pourcentage_frais_agence:
        data.lot_pourcentage_frais_agence !== undefined
          ? parseFloat(data.lot_pourcentage_frais_agence, 2)
          : this.props.lot
          ? this.props.lot.lot_pourcentage_frais_agence
          : null,
      lot_type_lot_id: data.lot_type_lot_id,
      lot_confort: data.lot_confort,
      lot_classement: data.lot_classement,
      lot_nb_pieces: data.lot_nb_pieces,
      lot_nb_chambres: data.lot_nb_chambres,
      lot_nb_sdb: data.lot_nb_sdb,
      lot_nb_se: data.lot_nb_se,
      lot_nb_toilettes: data.lot_nb_toilettes,
      lot_nb_lits_doubles: data.lot_nb_lits_doubles,
      lot_nb_lits_simples: data.lot_nb_lits_simples,
      lot_nb_canapes_lits: data.lot_nb_canapes_lits,
      lot_depot_garantie: data.lot_depot_garantie,
      lot_code_wifi: data.lot_code_wifi,
      lot_reseau_wifi: data.lot_reseau_wifi,
      lot_reference_administrative: data.lot_reference_administrative,
      lot_adresse_id: this.props.lot ? this.props.lot.lot_adresse.adresse_id : null,
      lot_proprietaire_id: proprietaire.proprietaire_id,
      lot_immeuble_id: immeuble ? immeuble.immeuble_id : null,
      lot_dossier_id: dossier ? dossier.dossier_id : null,
      lot_alur: data.lot_type_contrat && data.lot_type_contrat.includes("Alur") ? true : false,
      lot_court_terme:
        data.lot_type_contrat && data.lot_type_contrat.includes("Court terme") ? true : false,
      lot_long_terme:
        data.lot_type_contrat && data.lot_type_contrat.includes("Long terme") ? true : false,
      adresse_gmaps_adresse: data.adresse_gmaps_adresse,
      adresse_code_postal: data.adresse_code_postal,
      adresse_ville: data.adresse_ville,
      adresse_region: data.adresse_region,
      adresse_departement: data.adresse_departement,
      adresse_pays: data.adresse_pays,
      adresse_numero: data.adresse_numero,
      adresse_voie: data.adresse_voie,
      adresse_gmaps_lat: data.adresse_gmaps_lat,
      adresse_gmaps_lng: data.adresse_gmaps_lng,
      adresse_situation: data.adresse_situation,
      lot_fin: false,
      lot_numero_mandat: data.lot_numero_mandat,
      lot_date_signature_mandat: data.lot_date_signature_mandat
        ? data.lot_date_signature_mandat.hour(12)
        : null,
      lot_mandat_exclusif: this.state.mandatExclusif,
      lot_date_echeance_mandat: data.lot_date_echeance_mandat
        ? data.lot_date_echeance_mandat.hour(12)
        : null,
      lot_date_renouvellement_mandat: data.lot_date_renouvellement_mandat
        ? data.lot_date_renouvellement_mandat.hour(12)
        : null,
      lot_date_fin_mandat: data.lot_date_fin_mandat ? data.lot_date_fin_mandat.hour(12) : null,
      lot_en_ligne: this.state.enLigne,
      lot_local_commercial: this.state.commercial,
      lot_calendrier_visible: data.lot_calendrier_visible ? data.lot_calendrier_visible : true,
      lot_frais_gestion_alur:
        data.lot_frais_gestion_alur !== undefined
          ? data.lot_frais_gestion_alur
          : this.props.lot
          ? this.props.lot.lot_frais_gestion_alur
          : 0,
      lot_frais_gestion_ct:
        data.lot_frais_gestion_ct !== undefined
          ? data.lot_frais_gestion_ct
          : this.props.lot
          ? this.props.lot.lot_frais_gestion_ct
          : 0,
      lot_frais_gestion_lt:
        data.lot_frais_gestion_lt !== undefined
          ? data.lot_frais_gestion_lt
          : this.props.lot
          ? this.props.lot.lot_frais_gestion_lt
          : 0,
      lot_quartier: data.lot_adresse_quartier !== undefined ? data.lot_adresse_quartier : [],
      lot_metro: data.lot_adresse_metro !== undefined ? data.lot_adresse_metro : [],
      alerte_active: data.alerte_active,
      alerte_date: data.alerte_date,
      alerte_texte: data.alerte_texte,
      lot_societe_id: this.props.utilisateur.societe_id,
    };
  };

  getJsonDossier = (lot) => {
    return {
      dossier_url: lot ? `Lots/${lot.lot_id}/` : Constants.directory,
    };
  };

  handleElements = (data, lot) => {
    let elementsList = [];
    this.props.champsPersosGroupes.forEach((item) => {
      if (item.champ_perso_groupe_type === 3) {
        item.champ_perso.forEach((element) => {
          if (
            String(data[`${element.champ_perso_id}`]) !== "undefined" &&
            String(data[`${element.champ_perso_id}`]) !== undefined &&
            String(data[`${element.champ_perso_id}`]) !== null
          ) {
            let _d = data[`${element.champ_perso_id}`];
            if (element.champ_perso_type === 4) {
              if (typeof _d === "object") {
                const a = convertFromRaw(_d);
                const b = EditorState.createWithContent(a);
                _d = draftToHtml(convertToRaw(b.getCurrentContent()));
              }
            }

            elementsList.push({
              lot_champ_perso_lot_id: lot.lot_id,
              lot_champ_perso_champ_perso_id: element.champ_perso_id,
              lot_champ_perso_valeur: String(_d),
            });
          }
        });
      }
    });

    this.props.saveUpdateLotsChampsPersos({ list: elementsList }, lot.lot_id);
  };

  handleEquipements = (data, lot) => {
    let equipements = [];
    this.props.equipements.forEach((item) => {
      if (data["equipement_" + item.equipement_id]) {
        this.props.saveLotsEquipements(lot.lot_id, item.equipement_id);
        equipements.push(item);
      }
    });
    lot.lot_equipements = equipements;
    return lot;
  };

  handleDescription = (descriptions) => {
    const lot = this.state.lot;
    const langue = this.state.langueSelected;

    descriptions.forEach((item) => {
      if (item.description_html === "") item.description_html = "<p></p>";

      const jsonData = {
        description_titre: item.description_titre ? item.description_titre : "",
        description_resume: item.description_resume ? item.description_resume : "",
        description_html: item.description_html,
        description_indication_acces: item.description_indication_acces
          ? item.description_indication_acces
          : "",
        description_lot_url: item.description_lot_url ? item.description_lot_url : "",
        description_libre: item.description_libre ? item.description_libre : "",
        description_lot_id: lot.lot_id,
        description_langue_id: item.description_langue.langue_id,
      };

      if (item.new) {
        this.props.saveDescription(jsonData, lot.lot_id).then((res) => {
          this.onChangeLangue(langue);
        });
      } else {
        this.props
          .updateDescription(jsonData, lot.lot_id, item.description_langue.langue_id)
          .then((res) => {
            this.onChangeLangue(langue);
          });
      }
    });
  };

  saveLotsEquipements = (equipement) => {
    const lot = this.props.lot;
    if (lot) {
      this.props.saveLotsEquipements(lot.lot_id, equipement.equipement_id);
      lot.lot_equipements.push(equipement);
      this.setState({ lot });
    }
  };

  deleteLotsEquipements = (equipement) => {
    const lot = this.props.lot;
    if (lot) {
      this.props.deleteLotsEquipements(lot.lot_id, equipement.equipement_id);
      lot.lot_equipements = lot.lot_equipements.filter(
        (item) => item.equipement_id !== equipement.equipement_id
      );
      this.setState({ lot });
    }
  };

  formatDate = (d) => {
    let date = null;
    if (d) date = dayjs(d, "YYYY-MM-DD");
    return date;
  };

  onChangeLangue = (i) => {
    this.setState({ langueSelected: i });
    this.initLotDescription(this.state.lot, i);
  };

  setEditorState = (value) => {
    this.setState({
      editorState: EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(value))
      ),
    });
  };

  getDescription = () => {
    // TODO : Renaud
    const description = this.state.lot.lot_descriptions.find(
      (item) =>
        item.description_langue && item.description_langue.langue_id === this.state.langueSelected
    );
    if (description === undefined) return this.makeDescription();
    return description;
  };

  makeDescription = () => {
    const description = {
      description_titre: "",
      description_resume: "",
      description_html: "",
      description_indication_acces: "",
      description_libre: "",
      description_lot_id: this.state.lot.lot_id,
      description_langue: this.props.langues.find(
        (item) => item.langue_id === this.state.langueSelected
      ),
      new: true,
    };

    const newListe = [...this.state.lot.lot_descriptions, description];
    const lot = this.state.lot;
    lot.lot_descriptions = newListe;
    this.setState({ lot });
    return description;
  };

  onChangeTitre = (value) => {
    const description = this.getDescription();
    description.description_titre = value;
  };

  onChangeResume = (value) => {
    const description = this.getDescription();
    description.description_resume = value;
  };

  onChangeUrl = (value) => {
    const description = this.getDescription();
    description.description_lot_url = value;
  };

  onChangeHtml = (editorState) => {
    const description = this.getDescription();
    description.description_html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    this.setState({ editorState });
  };

  onChangeIndication = (value) => {
    const description = this.getDescription();
    description.description_indication_acces = value;
  };

  onChangeDescriptionLibre = (value) => {
    const description = this.getDescription();
    description.description_libre = value;
  };

  addChampPerso = (groupe) => {
    this.setState({
      groupe,
      previewTitre: "Ajouter",
      modalChampPersoVisible: true,
    });
    this.formRef.current.setFieldsValue({ input_libelle: "" });
  };

  editChampPerso = (e, champPerso, groupe) => {
    e.stopPropagation();
    this.setState({
      groupe,
      modalChampPersoVisible: true,
      previewTitre: "Editer",
      inputChampPerso: champPerso.champ_perso_libelle,
      champPerso,
    });

    this.formRef.current.setFieldsValue({
      input_libelle: champPerso.champ_perso_libelle,
    });
  };

  deleteChampPerso = (e, champPerso, groupe) => {
    //e.stopPropagation();
    this.setState({
      groupe,
      modalChampPersoVisible: true,
      previewTitre: "Supprimer",
      champPerso,
    });
  };

  handleAjouter = () => {
    let groupeObjet = [];

    if (this.state.groupe === "quartier") {
      groupeObjet = this.props.champsPersosGroupes.filter((c) => c.champ_perso_groupe_type === 1);
    }
    if (this.state.groupe === "métro") {
      groupeObjet = this.props.champsPersosGroupes.filter((c) => c.champ_perso_groupe_type === 2);
    }
    const groupe = groupeObjet[0].champ_perso_groupe_id;

    //const groupe = this.state.groupe === "quartier" ? 1 : 2;

    let maxOrdre = 0;

    if (this.props.champsPersos.some((item) => item.champ_perso_groupe_id === groupe))
      maxOrdre = this.props.champsPersos.reduce(
        (max, item) =>
          item.champ_perso_groupe_id === groupe && item.champ_perso_ordre > max
            ? item.champ_perso_ordre
            : max,
        this.props.champsPersos[0].champ_perso_ordre
      );

    this.props
      .saveChampPerso({
        champ_perso_libelle: this.state.inputChampPerso,
        champ_perso_type: 3,
        champ_perso_ordre: maxOrdre + 1,
        champ_perso_groupe_id: groupe,
      })
      .then((res) => {
        this.setState({ modalChampPersoVisible: false, inputChampPerso: "" });
        toast.success("Création réussie !", {
          containerId: "A",
        });
      })
      .catch((err) => {
        toast.error("Échec de la création !", {
          containerId: "A",
        });
      });
  };

  handleEditer = () => {
    this.props
      .updateChampPerso(
        {
          champ_perso_libelle: this.state.inputChampPerso,
        },
        this.state.champPerso.champ_perso_id
      )
      .then((res) => {
        this.setState({
          modalChampPersoVisible: false,
          inputChampPerso: "",
          champPerso: null,
        });
        toast.success("Modification réussie !", {
          containerId: "A",
        });
      })
      .catch((err) => {
        toast.error("Échec de la modification !", {
          containerId: "A",
        });
      });
  };

  handleSupprimer = () => {
    this.props
      .deleteChampPerso(this.state.champPerso.champ_perso_id)
      .then((res) => {
        this.setState({
          modalChampPersoVisible: false,
          previewTitre: "",
          champPerso: null,
        });
        // this.props.formRef.current.setFieldsValue({
        //   personne_provenance: undefined,
        // });
        toast.success("Suppression réussie !", {
          containerId: "A",
        });
      })
      .catch((err) => {
        toast.error("Échec de la suppression !", {
          containerId: "A",
        });
      });
  };

  handleCancelChampPerso = () => {
    this.setState({ modalChampPersoVisible: false });
  };

  onChangeInput = (e) => {
    this.setState({ inputChampPerso: e.target.value });
  };

  createPdf = () => {
    const html = ReactDOMServer.renderToStaticMarkup(
      <DescriptifPDF lot={this.props.lot} pieces={this.props.pieces} />
    );
    const pdf = {
      titre: "Bordereau",
      destinataire: "",
      pdf_page: [],
    };
    const pdfPage = {
      html: html,
    };
    pdf.pdf_page.push(pdfPage);
    const { url, params } = initFetch(`pdfs`, HttpMethod.POST);
    fetch(url, { ...params, body: JSON.stringify(pdf) })
      .then(function (response) {
        return response.json();
      })
      .then(
        function (result) {
          this.setState({ idPdf: result.pdf.id, modalPdf: true });
        }.bind(this)
      );
  };

  closeModal = () => {
    this.setState({ modalPdf: false });
  };

  handleYearChanged = (event) => {
    this.setState({ year: event.currentYear }, () => this.calculNbNuites(this.props.lot));
  };

  render() {
    const {
      row,
      metros,
      quartiers,
      langueSelected,
      editorState,
      enLigne,
      commercial,
      mandatExclusif,
      modalChampPersoVisible,
      previewTitre,
      groupe,
      uniteMinimum,
      uniteMaximum,
      adresse,
      dossier,
      premierClic,
      modalPdf,
      idPdf,
      year,
      nbNuites,
      typeBlocTexte,
    } = this.state;
    const {
      readOnly,
      utilisateur,
      lot,
      typesLots,
      proprietaires,
      immeubles,
      equipements,
      champsPersosGroupes,
      langues,
      pieces,
      locations,
      quittances,
      reglements,
      lots,
      locataires,
      qualites,
      parametre,
      groupes,
      provenances,
      parcoursClients,
    } = this.props;

    const showProprietaireButton = (
      <Button type="primary" onClick={() => this.onClickShowPro("17", "Proprietaires")}>
        <EyeOutlined />
      </Button>
    );

    const showImmeubleButton = (
      <Button type="primary" onClick={() => this.onClickShowImm("37", "Immeubles")}>
        <EyeOutlined />
      </Button>
    );

    const footer = (
      <div>
        <Button type="primary" danger onClick={this.handleCancelChampPerso}>
          Annuler
        </Button>
        <Button type="primary" onClick={this["handle" + previewTitre]}>
          {previewTitre}
        </Button>
      </div>
    );

    return (
      <Form ref={this.formRef} onFinish={this.onFinish} size="large" className="form-requete">
        <Modal footer={null} title="Descriptif" open={modalPdf} onCancel={this.closeModal}>
          {idPdf && (
            <Button type="text" onClick={() => openPdf(idPdf)}>
              Édition du descriptif
            </Button>
          )}
        </Modal>
        <Modal
          open={modalChampPersoVisible}
          title={previewTitre}
          onCancel={this.handleCancelChampPerso}
          footer={footer}
        >
          <Row>
            {previewTitre !== "Supprimer" ? (
              <Form.Item name={"input_libelle"}>
                <Input addonBefore={"Libellé"} onChange={this.onChangeInput} />
              </Form.Item>
            ) : (
              <div style={{ fontSize: 16 }}>
                <b>{`Supprimer ce ${groupe} ?`}</b>
              </div>
            )}
          </Row>
        </Modal>

        <Tabs
          defaultActiveKey="1"
          onChange={(key) => this.setState({ key })}
          items={[
            {
              label: "Général",
              key: "1",
              children: (
                <>
                  <Row gutter={10}>
                    <Col span={12} style={{ padding: 20 }}>
                      <Divider orientation="left">Identification</Divider>
                      <Row gutter={10}>
                        <Col span={8}>
                          <CustomInput
                            label="Référence"
                            inputName="lot_reference"
                            formItemName="lot_reference"
                            objectValue={lot && lot.lot_reference}
                            rules={{ required: true, message: "Référence obligatoire" }}
                          />
                        </Col>
                        <Col span={14}>
                          <CustomInput
                            label="Désignation"
                            inputName="lot_designation"
                            formItemName="lot_designation"
                            objectValue={lot && lot.lot_designation}
                          />
                        </Col>
                        <Col span={2}>
                          <Button>
                            <a
                              href={
                                lot && lot.lot_descriptions?.length > 0
                                  ? lot && lot.lot_descriptions[0].description_lot_url
                                  : "/"
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <GlobalOutlined />
                            </a>
                          </Button>
                        </Col>
                      </Row>
                      <Row gutter={10}>
                        <Col span={4}>
                          <CustomSelect
                            label="Type lot"
                            inputName="typeLot"
                            formItemName="lot_type_lot_id"
                            objectValue={lot && lot.lot_type_lot && lot.lot_type_lot.type_lot_id}
                          >
                            <OptGroup label="Type lot">
                              {typesLots &&
                                typesLots.map((item, i) => {
                                  return (
                                    <Option
                                      key={i}
                                      value={item.type_lot_id}
                                      label={item.type_lot_libelle}
                                    >
                                      <Row>
                                        <Col sm={8}>{item.type_lot_libelle}</Col>
                                      </Row>
                                    </Option>
                                  );
                                })}
                            </OptGroup>
                          </CustomSelect>
                        </Col>
                        <Col span={4}>
                          <CustomInput
                            label="Surface (m²)"
                            inputName="lot_surface"
                            formItemName="lot_surface"
                            type="number"
                            objectValue={lot && lot.lot_surface}
                          />
                        </Col>
                        <Col span={4}>
                          <CustomInput
                            label="Étage"
                            inputName="lot_etage"
                            formItemName="lot_etage"
                            type="number"
                            objectValue={lot && lot.lot_etage}
                          />
                        </Col>
                        <Col span={4}>
                          <CustomInput
                            label="Niveau"
                            inputName="lot_niveau"
                            formItemName="lot_niveau"
                            type="number"
                            objectValue={lot && lot.lot_niveau}
                          />
                        </Col>
                      </Row>
                      <Row gutter={10}>
                        <Col span={4}>
                          <CustomInput
                            label="Pièces"
                            inputName="lot_nb_pieces"
                            formItemName="lot_nb_pieces"
                            type="number"
                            objectValue={lot && lot.lot_nb_pieces}
                          />
                        </Col>
                        <Col span={4}>
                          <CustomInput
                            label="Chambres"
                            inputName="lot_nb_chambres"
                            formItemName="lot_nb_chambres"
                            type="number"
                            objectValue={lot && lot.lot_nb_chambres}
                            // readOnly={true}
                          />
                        </Col>
                        <Col span={4}>
                          <CustomInput
                            label="S. de bain"
                            inputName="lot_nb_sdb"
                            formItemName="lot_nb_sdb"
                            type="number"
                            objectValue={lot && lot.lot_nb_sdb}
                            // readOnly={true}
                          />
                        </Col>
                        <Col span={4}>
                          <CustomInput
                            label="S. d'eau"
                            inputName="lot_nb_se"
                            formItemName="lot_nb_se"
                            type="number"
                            objectValue={lot && lot.lot_nb_se}
                            // readOnly={true}
                          />
                        </Col>
                        <Col span={4}>
                          <CustomInput
                            label="Toilettes"
                            inputName="lot_nb_toilettes"
                            formItemName="lot_nb_toilettes"
                            type="number"
                            objectValue={lot && lot.lot_nb_toilettes}
                            // readOnly={true}
                          />
                        </Col>
                      </Row>
                      <Row gutter={10}>
                        <Col span={4}>
                          <CustomInput
                            label="Couchages"
                            inputName="lot_couchage"
                            formItemName="lot_couchage"
                            type="number"
                            objectValue={lot && lot.lot_couchage}
                          />
                        </Col>
                        <Col span={4}>
                          <CustomInput
                            label="Lits doubles"
                            inputName="lot_nb_lits_doubles"
                            formItemName="lot_nb_lits_doubles"
                            type="number"
                            objectValue={lot && lot.lot_nb_lits_doubles}
                          />
                        </Col>
                        <Col span={4}>
                          <CustomInput
                            label="Lits simples"
                            inputName="lot_nb_lits_simples"
                            formItemName="lot_nb_lits_simples"
                            type="number"
                            objectValue={lot && lot.lot_nb_lits_simples}
                          />
                        </Col>
                        <Col span={4}>
                          <CustomInput
                            label="Canapés/lits"
                            inputName="lot_nb_canapes_lits"
                            formItemName="lot_nb_canapes_lits"
                            type="number"
                            objectValue={lot && lot.lot_nb_canapes_lits}
                          />
                        </Col>
                      </Row>
                      <Row gutter={10}>
                        <Col span={4}>
                          <CustomInput
                            label="Code porte"
                            inputName="lot_code_porte"
                            formItemName="lot_code_porte"
                            objectValue={lot && lot.lot_code_porte}
                          />
                        </Col>
                        <Col span={4}>
                          <CustomInput
                            label="Réseau WIFI"
                            inputName="lot_reseau_wifi"
                            formItemName="lot_reseau_wifi"
                            objectValue={lot && lot.lot_reseau_wifi}
                          />
                        </Col>
                        <Col span={4}>
                          <CustomInput
                            label="Code WIFI"
                            inputName="lot_code_wifi"
                            formItemName="lot_code_wifi"
                            objectValue={lot && lot.lot_code_wifi}
                          />
                        </Col>
                        <Col span={8}>
                          <CustomInput
                            label="Réf. administrative"
                            inputName="lot_reference_administrative"
                            formItemName="lot_reference_administrative"
                            objectValue={lot && lot.lot_reference_administrative}
                          />
                        </Col>
                      </Row>
                      <Row gutter={10}>
                        <Col span={22}>
                          <CustomSelect
                            label="Propriétaire"
                            inputName="proprietaire"
                            formItemName="lot_proprietaire_id"
                            objectValue={lot && lot.lot_proprietaire.proprietaire_id}
                            onChange={(id) => this.onChangeProprietaire(id)}
                          >
                            <OptGroup label="Propriétaire">
                              {proprietaires &&
                                proprietaires.map((item, i) => {
                                  return (
                                    <Option
                                      key={i}
                                      value={item.proprietaire_id}
                                      label={getInfoProprietaire(item)}
                                    >
                                      <Row>
                                        <Col sm={8}>{getInfoProprietaire(item)}</Col>
                                      </Row>
                                    </Option>
                                  );
                                })}
                            </OptGroup>
                          </CustomSelect>
                        </Col>
                        <Col span={2}>{showProprietaireButton}</Col>
                      </Row>
                      <Row gutter={10}>
                        <Col span={20}>
                          <CustomSelect
                            label="Immeuble"
                            inputName="immeuble"
                            formItemName="lot_immeuble_id"
                            objectValue={lot && lot.lot_immeuble && lot.lot_immeuble.immeuble_id}
                            onChange={(id) => this.onChangeImmeuble(id)}
                          >
                            <OptGroup label="Immeuble">
                              {immeubles &&
                                immeubles.map((item, i) => {
                                  return (
                                    <Option
                                      key={i}
                                      value={item.immeuble_id}
                                      label={getInfoImmeuble(item)}
                                    >
                                      <Row>
                                        <Col sm={8}>{getInfoImmeuble(item)}</Col>
                                      </Row>
                                    </Option>
                                  );
                                })}
                            </OptGroup>
                          </CustomSelect>
                        </Col>
                        <Col span={2}>{showImmeubleButton}</Col>
                        <Col span={2}>
                          <Tooltip title="Copier/coller adresse immeuble dans le lot">
                            <Button type="primary" onClick={() => this.copyAdress()}>
                              <CopyOutlined />
                            </Button>
                          </Tooltip>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <CustomSelect
                            label="Point d'accueil"
                            inputName="pointAccueil"
                            formItemName="lot_point_accueil_id"
                            // objectValue={data && data.lot_point_accueil_id}
                          >
                            <OptGroup label="Point d'accueil"></OptGroup>
                          </CustomSelect>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <Form.Item name="lot_type_contrat">
                            <Checkbox.Group style={{ width: "100%" }}>
                              <Checkbox value="Alur">Alur</Checkbox>
                              <Checkbox value="Long terme">Long terme</Checkbox>
                              <Checkbox value="Court terme">Court terme</Checkbox>
                            </Checkbox.Group>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item name="lot_local_commercial" initialValue={commercial}>
                            <Switch
                              checked={commercial}
                              onChange={this.onChangeCommercial}
                              checkedChildren="Local commercial"
                              unCheckedChildren="Local commercial"
                            />
                          </Form.Item>
                        </Col>
                        <Col offset={2} span={2}>
                          <Button
                            type="primary"
                            style={{ float: "right" }}
                            onClick={() => this.createPdf()}
                          >
                            Générer PDF
                          </Button>
                        </Col>
                      </Row>
                      <Row gutter={12}>
                        <Col span={4}>
                          <Form.Item name="lot_en_ligne" initialValue={enLigne}>
                            <Switch
                              checked={enLigne}
                              onChange={this.onChangeEnLigne}
                              checkedChildren="En ligne"
                              unCheckedChildren="En ligne"
                            />
                          </Form.Item>
                        </Col>
                        {/* <Col span={4}>
                          <CustomInput
                            label="Nuités 2023"
                            inputName="nbNuites"
                            formItemName="nbNuites"
                            type="number"
                            objectValue={"1"}
                            readOnly={true}
                          />
                        </Col> */}
                      </Row>
                    </Col>
                    <Col span={12} style={{ padding: 20 }}>
                      {/* ADRESSE */}
                      <FormAdresse formRef={this.formRef} adresse={lot && adresse} gps={true} />
                      <Row gutter={10}>
                        <Col span={12}>
                          <SelectCRUD
                            row={row}
                            data={quartiers}
                            groupe="quartier"
                            handleMouseOver={this.handleMouseOver}
                            addValue={this.addChampPerso}
                            editValue={this.editChampPerso}
                            deleteValue={this.deleteChampPerso}
                          />
                        </Col>
                        <Col md={12}>
                          <SelectCRUD
                            row={row}
                            data={metros}
                            groupe="métro"
                            handleMouseOver={this.handleMouseOver}
                            addValue={this.addChampPerso}
                            editValue={this.editChampPerso}
                            deleteValue={this.deleteChampPerso}
                          />
                        </Col>
                      </Row>
                      <Divider orientation="left">Mandat</Divider>
                      <Row gutter={8}>
                        <Col span={8}>
                          <CustomInput
                            label="Numéro mandat"
                            inputName="lot_numero_mandat"
                            formItemName="lot_numero_mandat"
                            objectValue={lot && lot.lot_numero_mandat}
                          />
                        </Col>
                        <Col span={8}>
                          <CustomDatePicker
                            label="Date signature"
                            inputName="lot_date_signature_mandat"
                            formItemName="lot_date_signature_mandat"
                            objectValue={lot && this.formatDate(lot.lot_date_signature_mandat)}
                          />
                        </Col>
                        <Col span={8}>
                          <Form.Item name="lot_mandat_exclusif" initialValue={mandatExclusif}>
                            <Switch
                              checked={mandatExclusif}
                              onChange={this.onChangeMandatExclusif}
                              checkedChildren="Mandat Exclusif"
                              unCheckedChildren="Mandat Exclusif"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={8}>
                          <CustomDatePicker
                            label="Date échéance"
                            inputName="lot_date_echeance_mandat"
                            formItemName="lot_date_echeance_mandat"
                            objectValue={lot && this.formatDate(lot.lot_date_echeance_mandat)}
                          />
                        </Col>
                        <Col span={8}>
                          <CustomDatePicker
                            label="Date renouvellement"
                            inputName="lot_date_renouvellement_mandat"
                            formItemName="lot_date_renouvellement_mandat"
                            objectValue={lot && this.formatDate(lot.lot_date_renouvellement_mandat)}
                          />
                        </Col>
                        <Col span={8}>
                          <CustomDatePicker
                            label="Date fin"
                            inputName="lot_date_fin_mandat"
                            formItemName="lot_date_fin_mandat"
                            objectValue={lot && this.formatDate(lot.lot_date_fin_mandat)}
                          />
                        </Col>
                      </Row>
                      <Divider orientation="left">Autres renseignements</Divider>
                      <Row gutter={8}>
                        <Col span={8}>
                          <CustomInput
                            label="Dépôt garantie"
                            inputName="lot_depot_garantie"
                            formItemName="lot_depot_garantie"
                            type="number"
                            objectValue={lot && lot.lot_depot_garantie}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={24} className="form-large">
                      {/* 1 / 2 / 3 / 4 / 6 / 8 / 12 / 24 */}
                      <FormEquipement
                        formRef={this.formRef}
                        utilisateur={utilisateur}
                        equipements={equipements}
                        saveLotsEquipements={this.saveLotsEquipements}
                        deleteLotsEquipements={this.deleteLotsEquipements}
                        // isArchive={isArchive}
                        saveEquipement={this.props.saveEquipement}
                        updateEquipement={this.props.updateEquipement}
                        deleteEquipement={this.props.deleteEquipement}
                        eachRow={8}
                        fromAppt={true}
                        lot={lot}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={24}>
                      <CustomInput
                        label="Remarques"
                        inputName="lot_remarques"
                        formItemName="lot_remarques"
                        objectValue={lot && lot.lot_remarques}
                      />
                    </Col>
                  </Row>
                </>
              ),
            },
            {
              label: "Descriptif",
              key: "2",
              disabled: !lot,
              children: (
                <>
                  {lot && (
                    <FormDescription
                      lot={lot}
                      // isArchive={isArchive}
                      langues={langues}
                      // templates={this.props.templates}
                      // templatesLangues={this.props.templatesLangues}
                      editorState={editorState}
                      langueSelected={langueSelected}
                      onChangeLangue={this.onChangeLangue}
                      onChangeTitre={this.onChangeTitre}
                      onChangeResume={this.onChangeResume}
                      onChangeUrl={this.onChangeUrl}
                      onEditorStateChange={this.onChangeHtml}
                      onChangeIndication={this.onChangeIndication}
                      onChangeDescriptionLibre={this.onChangeDescriptionLibre}
                    />
                  )}
                </>
              ),
            },
            {
              label: "Élements",
              key: "3",
              disabled: !lot,
              children: (
                <>
                  {lot && (
                    <FormElement
                      //isArchive={isArchive}
                      groupes={champsPersosGroupes}
                      lot={lot}
                      typeBlocTexte={typeBlocTexte}
                    />
                  )}
                </>
              ),
            },
            {
              label: "Pièces",
              key: "4",
              disabled: !lot,
              children: (
                <>
                  {lot && (
                    <FormPiece
                      lot={lot}
                      // isArchive={isArchive}
                      pieces={pieces}
                      groupes={champsPersosGroupes}
                      savePiece={this.props.savePiece}
                      updatePiece={this.props.updatePiece}
                      deletePiece={this.props.deletePiece}
                      savePiecesContenus={this.props.savePiecesContenus}
                      updatePiecesContenus={this.props.updatePiecesContenus}
                    />
                  )}
                </>
              ),
            },
            {
              label: "Calendrier",
              key: "5",
              disabled: !lot,
              children: (
                <>
                  {quittances && reglements && lot && (
                    <FormCalendrier
                      readOnly={readOnly}
                      utilisateur={utilisateur}
                      parametre={parametre}
                      groupes={groupes}
                      provenances={provenances}
                      lot={lot}
                      lots={lots}
                      locataires={locataires}
                      qualites={qualites}
                      year={year}
                      nuites={nbNuites}
                      // isArchive={isArchive}
                      locations={locations}
                      quittances={quittances}
                      reglements={reglements}
                      parcoursClients={parcoursClients}
                      saveRenduA={this.props.saveRenduA}
                      saveLocataire={this.props.saveLocataire}
                      saveLocation={this.props.saveLocation}
                      updateLocation={this.props.updateLocation}
                      deleteLocation={this.props.deleteLocation}
                      saveDossier={this.props.saveDossier}
                      updateDossier={this.props.updateDossier}
                      uploadICal={this.props.uploadICal}
                      saveRequete={this.props.saveRequete}
                      saveRequeteLot={this.props.saveRequeteLot}
                      addPanesRequete={this.props.addPanesRequete}
                      saveEmail={this.props.saveEmail}
                      handleYearChanged={this.handleYearChanged}
                      // handleClickRequete={this.props.handleClickRequete}
                    />
                  )}
                </>
              ),
            },
            {
              label: "Photos",
              key: "6",
              disabled: !lot,
              children: (
                <>
                  {lot && (
                    <FormPhoto
                      lot={lot}
                      // isArchive={isArchive}
                      photos={lot.lot_photos}
                      savePhoto={this.props.savePhoto}
                      updatePhotos={this.props.updatePhotos}
                      deletePhoto={this.props.deletePhoto}
                    />
                  )}
                </>
              ),
            },
            {
              label: "Tarifs",
              key: "7",
              disabled: !lot,
              children: (
                <>
                  {lot && (
                    <FormTarifLot
                      home={false}
                      lot={lot}
                      lots={lots}
                      // isArchive={isArchive}
                      rubriques={this.props.rubriques}
                      updateLotNuitees={this.props.updateLotNuitees}
                      saveLigneMatrice={this.props.saveLigneMatrice}
                      updateLigneMatrice={this.props.updateLigneMatrice}
                      deleteLigneMatrice={this.props.deleteLigneMatrice}
                      savePrixBase={this.props.savePrixBase}
                      updatePrixBase={this.props.updatePrixBase}
                      deletePrixBase={this.props.deletePrixBase}
                      saveNuite={this.props.saveNuite}
                      updateNuite={this.props.updateNuite}
                      deleteNuite={this.props.deleteNuite}
                    />
                  )}
                  {/* <Col span={2}></Col> */}
                  <Row>
                    <Col span={24}>
                      <Divider orientation="left">Autres informations</Divider>
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={2}>
                      <CustomInput
                        label="Durée min"
                        inputName="lot_duree_minimum"
                        formItemName="lot_duree_minimum"
                        objectValue={lot && lot.lot_duree_minimum}
                      />
                    </Col>
                    <Col span={2}>
                      <CustomSelect
                        label="Unité"
                        inputName="lot_unite_minimum"
                        formItemName="lot_unite_minimum"
                        objectValue={uniteMinimum}
                      >
                        <OptGroup label="Unité">
                          <Option label="Jour(s)" value="Jour(s)">
                            Jour(s)
                          </Option>
                          <Option label="Mois" value="mois">
                            Mois
                          </Option>
                        </OptGroup>
                      </CustomSelect>
                    </Col>
                    <Col span={2}>
                      <CustomInput
                        label="Durée max"
                        inputName="lot_duree_maximum"
                        formItemName="lot_duree_maximum"
                        objectValue={lot && lot.lot_duree_maximum}
                      />
                    </Col>
                    <Col span={2}>
                      <CustomSelect
                        label="Unité"
                        inputName="lot_unite_maximum"
                        formItemName="lot_unite_maximum"
                        objectValue={uniteMaximum}
                      >
                        <OptGroup label="Unité">
                          <Option label="Jour(s)" value="Jour(s)">
                            Jour(s)
                          </Option>
                          <Option label="Mois" value="mois">
                            Mois
                          </Option>
                        </OptGroup>
                      </CustomSelect>
                    </Col>
                    <Col span={4}></Col>
                    <Col span={4}>
                      <CustomInput
                        label="Frais gestion CT (% TTC)"
                        inputName="lot_frais_gestion_ct"
                        formItemName="lot_frais_gestion_ct"
                        objectValue={lot && lot.lot_frais_gestion_ct}
                      />
                    </Col>
                    <Col span={4}>
                      <CustomInput
                        label="Frais gestion LT (% TTC)"
                        inputName="lot_frais_gestion_lt"
                        formItemName="lot_frais_gestion_lt"
                        objectValue={lot && lot.lot_frais_gestion_lt}
                      />
                    </Col>
                    <Col span={4}>
                      <CustomInput
                        label="Frais gestion ALUR (% TTC)"
                        inputName="lot_frais_gestion_alur"
                        formItemName="lot_frais_gestion_alur"
                        objectValue={lot && lot.lot_frais_gestion_alur}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <CustomInput
                        label="Commentaire"
                        inputName="lot_commentaire"
                        formItemName="lot_commentaire"
                        objectValue={lot && lot.lot_commentaire}
                      />
                    </Col>
                  </Row>
                </>
              ),
            },
            {
              label: "Documents",
              key: "8",
              disabled: !lot,
              children: (
                <>
                  {lot && (
                    <FormDocument
                      dossier={dossier}
                      fromRequete={false}
                      utilisateur={this.props.utilisateur}
                      documents={this.props.documents}
                      tags={this.props.tags}
                      typeTag={2}
                      saveDocument={this.props.saveDocument}
                      deleteDocument={this.props.deleteDocument}
                      saveTag={this.props.saveTag}
                      updateTag={this.props.updateTag}
                      deleteTag={this.props.deleteTag}
                    />
                  )}
                  <Col span={2}></Col>
                </>
              ),
            },
            {
              label: (
                <>
                  {lot?.notes.length > 0 ? <strong>Notes</strong> : "Notes"}{" "}
                  <Badge count={lot ? lot.notes.filter((note) => !note.fin).length : 0} />
                </>
              ),
              key: "9",
              disabled: !lot,
              children: (
                <TableNote
                  utilisateur={this.props.utilisateur}
                  requete={false}
                  data={lot}
                  saveNote={this.props.saveLotNote}
                  updateNote={this.props.updateLotNote}
                  deleteNote={this.props.deleteLotNote}
                />
              ),
            },
          ]}
        />
        {!readOnly && (
          <Row style={{ marginTop: 10, float: "right" }}>
            <Button type="primary" danger>
              Annulation
            </Button>
            <Button
              disabled={premierClic}
              type="primary"
              htmlType="submit"
              style={{ marginLeft: 10 }}
            >
              Validation
            </Button>
          </Row>
        )}
      </Form>
    );
  }
}

export default FormApptLarge;

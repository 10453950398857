import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Spin } from "antd";

import { loadLocations } from "../../store/actions/locationAction";
import { loadReglements } from "../../store/actions/reglementAction";
import { loadComptes } from "../../store/actions/compteAction";
import { saveQuittance } from "../../store/actions/quittanceAction";
import { saveEcriture } from "../../store/actions/ecritureAction";

import FormPrestationSupplementaire from "../../components/FormPrestationSupplementaire";

class PrestationsSupplementaires extends Component {
  componentDidMount() {
    const groupement_id = this.props.utilisateur.groupement_id;
    const societe_id = this.props.utilisateur.societe_id;
    this.props.loadLocations(groupement_id);
    this.props.loadReglements(societe_id);
    this.props.loadComptes(societe_id);
  }

  render() {
    const { locations, reglements, rubriques, comptes, journals, utilisateur } = this.props;

    return (
      <Spin
        spinning={!locations || !reglements || !comptes}
        size="large"
        tip="Chargement en cours..."
      >
        <Card title="" bordered={false} className="card-panel">
          <FormPrestationSupplementaire
            locations={locations && locations.filter((item) => item.location_lot.lot_societe_id === utilisateur.societe_id)}
            reglements={reglements}
            rubriques={rubriques}
            comptes={comptes}
            journals={journals}
            saveQuittance={this.props.saveQuittance}
            saveEcriture={this.props.saveEcriture}
          />
        </Card>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadLocations: (groupement_id) => dispatch(loadLocations(groupement_id)),
    loadReglements: (societe_id) => dispatch(loadReglements(societe_id)),
    loadComptes: (societe_id) => dispatch(loadComptes(societe_id)),
    saveQuittance: (jsonData) => dispatch(saveQuittance(jsonData)),
    saveEcriture: (ecriture) => dispatch(saveEcriture(ecriture)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    locations: state.locations.data,
    reglements: state.reglements.data,
    rubriques: state.rubriques.data,
    comptes: state.comptes.data,
    journals: state.journals.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrestationsSupplementaires);

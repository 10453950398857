import {
  etapesTemplatesToAdapted,
  etapesTemplatesoAdapted,
} from "../../adapter/etapeTemplateAdapter";
import { HttpMethod, initFetch } from "../../utils/fetcher";

export const LOAD_SUCCESS_ETAPE_TEMPLATE = "LOAD_SUCCESS_ETAPE_TEMPLATE";
export const LOAD_FAIL_ETAPE_TEMPLATE = "LOAD_FAIL_ETAPE_TEMPLATE";
export const SAVE_SUCCESS_ETAPE_TEMPLATE = "SAVE_SUCCESS_ETAPE_TEMPLATE";
export const SAVE_FAIL_ETAPE_TEMPLATE = "SAVE_FAIL_ETAPE_TEMPLATE";
export const DELETE_SUCCESS_ETAPE_TEMPLATE = "DELETE_SUCCESS_ETAPE_TEMPLATE";
export const DELETE_FAIL_ETAPE_TEMPLATE = "DELETE_FAIL_ETAPE_TEMPLATE";

export function loadEtapesTemplates() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`etapesTemplates`, HttpMethod.GET);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.etape_template);
          dispatch({
            type: LOAD_SUCCESS_ETAPE_TEMPLATE,
            payload: etapesTemplatesToAdapted(res.etape_template),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_ETAPE_TEMPLATE,
            error: err,
          });
        });
    });
  };
}

export function saveEtapesTemplates(idEtape, idTemplate) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(
        `etapesTemplates/${idEtape}/${idTemplate}`,
        HttpMethod.POST
      );
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.etape_template);
          dispatch({
            type: SAVE_SUCCESS_ETAPE_TEMPLATE,
            payload: etapesTemplatesoAdapted(res.etape_template),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: SAVE_FAIL_ETAPE_TEMPLATE,
            error: err,
          });
        });
    });
  };
}

export function deleteEtapesTemplates(idTemplate) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`etapesTemplates/${idTemplate}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.etape_template);
          dispatch({
            type: DELETE_SUCCESS_ETAPE_TEMPLATE,
            payload: etapesTemplatesoAdapted(res.etape_template),
            idTemplate: idTemplate,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_ETAPE_TEMPLATE,
            payload: err,
          });
        });
    });
  };
}

import React, { Component } from "react";
import { Button, Col, Divider, Form, Input, Row, Tabs, Table } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import FloatLabel from "../FloatLabel";

import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";

class FormImmeuble extends Component {
  state = {
    nomCourt: "",
    raisonSociale: "",
    telephone1: "",
    telephone2: "",
    mail1: "",
    siteWeb: "",
    zoneLibre1: "",
    zoneLibre2: "",
    zoneLibre3: "",
    zoneLibre4: "",
    zoneLibre5: "",
    adresse: "",
    numero: "",
    voie: "",
    complement: "",
    cp: "",
    ville: "",
    departement: "",
    region: "",
    pays: "",
  };

  // Référence du formulaire
  formRef = React.createRef();

  UNSAFE_componentWillReceiveProps(props) {
    props.immeuble ? this.initImmeuble(props.immeuble) : this.resetImmeuble();
  }

  initImmeuble = (data) => {
    this.resetImmeuble();

    const immeuble = data;
    const adresse = data.immeuble_adresse;

    this.formRef.current.setFieldsValue({
      immeuble_nom: immeuble.immeuble_nom,
      immeuble_code_acces: immeuble.immeuble_code_acces,
      immeuble_nom_syndic: immeuble.immeuble_nom_syndic,
      adresse_gmaps_adresse: adresse.adresse_gmaps_adresse,
      adresse_numero: adresse.adresse_numero,
      adresse_voie: adresse.adresse_voie,
      complement_adresse: "",
      adresse_code_postal: adresse.adresse_code_postal,
      adresse_ville: adresse.adresse_ville,
      adresse_departement: adresse.adresse_departement,
      adresse_region: adresse.adresse_region,
      adresse_pays: adresse.adresse_pays,
    });

    this.setState({
      nom: immeuble.immeuble_nom,
      codeAcces: immeuble.immeuble_code_acces,
      nomSyndic: immeuble.immeuble_nom_syndic,
      adresse: adresse.adresse_gmaps_adresse,
      numero: adresse.adresse_numero,
      voie: adresse.adresse_voie,
      complement: "",
      cp: adresse.adresse_code_postal,
      ville: adresse.adresse_ville,
      departement: adresse.adresse_departement,
      region: adresse.adresse_region,
      pays: adresse.adresse_pays,
    });
  };

  resetImmeuble = () => {
    this.formRef.current.resetFields([
      "immeuble_nom",
      "immeuble_code_acces",
      "immeuble_nom_syndic",
      "adresse_gmaps_adresse",
      "adresse_numero",
      "adresse_voie",
      "complement_adresse",
      "adresse_code_postal",
      "adresse_ville",
      "adresse_departement",
      "adresse_region",
      "adresse_pays",
    ]);

    this.setState({
      nom: "",
      codeAcces: "",
      nomSyndic: "",
      adresse: "",
      numero: "",
      voie: "",
      complement: "",
      cp: "",
      ville: "",
      departement: "",
      region: "",
      pays: "",
    });
  };

  onFinish = (data) => {
    data.immeuble_adresse_id = this.props.immeuble
      ? this.props.immeuble.immeuble_adresse.adresse_id
      : null;
    if (this.props.immeuble) {
      data.immeuble_groupement_id = this.props.immeuble.immeuble_groupement_id;
      this.props.handleEdit(data, this.props.immeuble.immeuble_id);
    } else {
      data.immeuble_groupement_id = this.props.utilisateur.groupement_id;
      this.props.handleSubmit(data);
    }
  };

  handleChangeAdresse = (adresse) => {
    this.setState({ adresse });
  };

  handleSelectAdresse = (adresse) => {
    this.formRef.current.setFieldsValue({
      adresse_gmaps_adresse: adresse,
      adresse_numero: "",
      adresse_voie: "",
      adresse_ville: "",
      adresse_code_postal: "",
      adresse_region: "",
      adresse_departement: "",
      adresse_pays: "",
    });
    geocodeByAddress(adresse).then((results) => {
      results[0].address_components.forEach((component, i) => {
        if (component.types[0] === "street_number") {
          this.setState({ numero: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_numero: component.long_name,
          });
        }
        if (component.types[0] === "route") {
          this.setState({ voie: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_voie: component.long_name,
          });
        }
        if (component.types[0] === "locality") {
          this.setState({ ville: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_ville: component.long_name,
          });
        }
        if (component.types[0] === "postal_code") {
          this.setState({ cp: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_code_postal: component.long_name,
          });
        }
        if (component.types[0] === "administrative_area_level_1") {
          this.setState({ region: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_region: component.long_name,
          });
        }
        if (component.types[0] === "administrative_area_level_2") {
          this.setState({ departement: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_departement: component.long_name,
          });
        }
        if (component.types[0] === "country") {
          this.setState({ pays: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_pays: component.long_name,
          });
        }
      });
    });
    this.setState({ adresse });
  };

  onClickAfficher = (id) => {
    const appartement = this.props.lotsFilter.find((item) => item.lot_id === id);
    this.props.afficherAppartement(appartement);
  };

  render() {
    const {
      nom,
      codeAcces,
      nomSyndic,
      adresse,
      numero,
      voie,
      complement,
      cp,
      ville,
      departement,
      region,
      pays,
    } = this.state;

    const columnsLots = [
      {
        title: "Référence",
        dataIndex: "lot_reference",
        key: "lot_reference",
        width: "10%",
      },
      {
        title: "Désignation",
        dataIndex: "lot_designation",
        key: "lot_designation",
        width: "27%",
      },
      {
        title: "Adresse",
        dataIndex: "lot_adresse",
        key: "lot_adresse",
        width: "30%",
        render: (adresse) => adresse.adresse_gmaps_adresse,
      },
      {
        title: "Propriétaire",
        dataIndex: "lot_proprietaire",
        key: "lot_proprietaire",
        width: "25%",
        render: (proprietaire) =>
          proprietaire.proprietaire_personne.personne_nom +
          " " +
          proprietaire.proprietaire_personne.personne_prenom,
      },
      {
        title: "Fiche Lot",
        dataIndex: "lot_id",
        key: "lot_id",
        render: (lot) => (
          <Button onClick={() => this.onClickAfficher(lot)}>
            <EyeOutlined />
          </Button>
        ),
        width: "8%",
      },
    ];

    return (
      <Form ref={this.formRef} onFinish={this.onFinish} size="large" className="form-requete">
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              label: "Général",
              key: "1",
              children: (
                <Row gutter={16}>
                  <Col span={12} style={{ padding: 20 }}>
                    <Divider orientation="left">Identification</Divider>
                    <Row gutter={8}>
                      <Col span={24}>
                        <FloatLabel label="Nom" name="nom" value={nom}>
                          <Form.Item name="immeuble_nom">
                            <Input
                              value={nom}
                              onChange={(e) => this.setState({ nom: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col span={6}>
                        <FloatLabel label="Code accès" name="codeAcces" value={codeAcces}>
                          <Form.Item name="immeuble_code_acces">
                            <Input
                              value={codeAcces}
                              onChange={(e) => this.setState({ codeAcces: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col span={18}>
                        <FloatLabel label="Syndic" name="syndic" value={nomSyndic}>
                          <Form.Item name="immeuble_nom_syndic">
                            <Input
                              value={nomSyndic}
                              onChange={(e) => this.setState({ nomSyndic: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12} style={{ padding: 20 }}>
                    <Divider orientation="left">Adresse</Divider>
                    <Row gutter={8}>
                      <Col span={24}>
                        <PlacesAutocomplete
                          value={adresse}
                          onChange={this.handleChangeAdresse}
                          onSelect={this.handleSelectAdresse}
                        >
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                              <FloatLabel
                                label="Recherche GOOGLE"
                                name="adresse_gmaps_adresse"
                                value={adresse}
                              >
                                <Form.Item name="adresse_gmaps_adresse">
                                  <Input
                                    {...getInputProps({
                                      className: "location-search-input",
                                    })}
                                  />
                                </Form.Item>
                              </FloatLabel>
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Chargement ...</div>}
                                {suggestions.map((suggestion, i) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                    : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                      key={i}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </Col>

                      <Col span={8}>
                        <FloatLabel label="N°" name="adresse_numero" value={numero}>
                          <Form.Item name="adresse_numero">
                            <Input
                              value={numero}
                              onChange={(e) => this.setState({ numero: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                      <Col span={16}>
                        <FloatLabel label="Libellé voie" name="adresse_voie" value={voie}>
                          <Form.Item name="adresse_voie">
                            <Input
                              value={voie}
                              onChange={(e) => this.setState({ voie: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col span={24}>
                        <FloatLabel
                          label="Complément adresse"
                          name="complement_adresse"
                          value={complement}
                        >
                          <Form.Item name="complement_adresse">
                            <Input
                              value={complement}
                              onChange={(e) => this.setState({ complement: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                      <Col span={8}>
                        <FloatLabel label="CP" name="adresse_code_postal" value={cp}>
                          <Form.Item name="adresse_code_postal">
                            <Input
                              value={cp}
                              onChange={(e) => this.setState({ cp: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                      <Col span={16}>
                        <FloatLabel label="Ville" name="adresse_ville" value={ville}>
                          <Form.Item name="adresse_ville">
                            <Input
                              value={ville}
                              onChange={(e) => this.setState({ ville: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                      <Col span={8}>
                        <FloatLabel
                          label="Département"
                          name="adresse_departement"
                          value={departement}
                        >
                          <Form.Item name="adresse_departement">
                            <Input
                              value={departement}
                              onChange={(e) => this.setState({ departement: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                      <Col span={8}>
                        <FloatLabel label="Région" name="adresse_region" value={region}>
                          <Form.Item name="adresse_region">
                            <Input
                              value={region}
                              onChange={(e) => this.setState({ region: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                      <Col span={8}>
                        <FloatLabel label="Pays" name="adresse_pays" value={pays}>
                          <Form.Item name="adresse_pays">
                            <Input
                              value={pays}
                              onChange={(e) => this.setState({ pays: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ),
            },
            {
              label: "Lots",
              key: "2",
              disabled: !nom ? true : false,
              children: <Table columns={columnsLots} dataSource={this.props.lotsFilter} />,
            },
          ]}
        />
        <Row style={{ marginTop: 10, float: "right" }}>
          <Button type="primary" danger>
            Annulation
          </Button>
          <Button type="primary" htmlType="submit" style={{ marginLeft: 10 }}>
            Validation
          </Button>
        </Row>
      </Form>
    );
  }
}

export default FormImmeuble;

import React, { useEffect, useState } from "react";

import FloatLabel from "../../FloatLabel";
import { TimePicker, Form } from "antd";

import dayjs from "dayjs";

const dateFormat = "HH:mm";

const CustomTimePicker = (props) => {
  const [value, setValue] = useState(false);
  const { label, formItemName, inputName, objectValue, today } = props;

  useEffect(() => {
    objectValue ? setValue(objectValue) : setValue(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.objectValue]);

  const onChange = (e) => {
    props.onChange && props.onChange(e);
    setValue(e);
  };

  return (
    <FloatLabel label={label} name={inputName} value={value}>
      <Form.Item name={formItemName} initialValue={today ? dayjs().format("HH:mm") : null}>
        <TimePicker onChange={(e) => onChange(e)} format={dateFormat} placeholder="" />
      </Form.Item>
    </FloatLabel>
  );
};

export default CustomTimePicker;

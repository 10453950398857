import dayjs from "dayjs"

export const columns = () => {
  return [
    {
      title: "N°",
      dataIndex: "location_id",
      key: "location_id",
      sorter: (a, b) => parseInt(a.location_id) - parseInt(b.location_id),
      width: "8%",
    },
    {
      title: "Date Début",
      dataIndex: "location_date_debut",
      key: "location_date_debut",
      render: (date) => dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
      sorter: (a, b) =>
        dayjs(a.location_date_debut).unix() -
        dayjs(b.location_date_debut).unix(),
      // sortOrder: mouvement === 1 ? "ascend" : null,
      width: "8%",
    },
    {
      title: "Date Fin",
      dataIndex: "location_date_fin",
      key: "location_date_fin",
      render: (date) => dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
      sorter: (a, b) =>
        dayjs(a.location_date_fin).unix() - dayjs(b.location_date_fin).unix(),
      // sortOrder: mouvement === 2 ? "ascend" : null,
      width: "8%",
    },
    {
      title: "Locataire",
      dataIndex: "location_requete",
      key: "location_requete",
      render: (requete) =>
        requete &&
        `${requete.requete_locataire.locataire_personne.personne_nom} ${requete.requete_locataire.locataire_personne.personne_prenom}`,
      // sorter: (a, b) =>
      //   a.location_requete.requete_locataire
      //     ? a.location_requete.requete_locataire.locataire_personne.personne_nom.localeCompare(
      //         b.location_requete.requete_locataire.locataire_personne
      //           .personne_nom
      //       )
      //     : 1,
      width: "15%",
    },
    {
      title: "Mail Locataire",
      dataIndex: "location_requete",
      key: "location_requete",
      render: (requete) =>
        requete && requete.requete_locataire.locataire_personne.personne_email1,
      width: "10%",
    },
    {
      title: "Tel Locataire",
      dataIndex: "location_requete",
      key: "location_requete",
      render: (requete) =>
        requete && requete.requete_locataire.locataire_personne.personne_tel1,
      width: "10%",
    },
    {
      title: "Lot",
      dataIndex: "location_lot",
      key: "location_lot",
      render: (lot) => lot.lot_reference,
      width: "15%",
    },
    {
      title: "Proprietaire",
      dataIndex: "location_lot",
      key: "location_lot",
      render: (lot) =>
        `${lot.lot_proprietaire.proprietaire_personne.personne_nom} ${lot.lot_proprietaire.proprietaire_personne.personne_prenom}`,
      width: "15%",
    },
    {
      title: "Total",
      dataIndex: "location_ligne",
      key: "location_ligne",
      render: (lignes) => {
        let total = 0;
        lignes.forEach((ligne) => {
          total += parseInt(ligne.location_ligne_montant);
        });
        return parseFloat(total).toLocaleString("fr-FR", {
          style: "currency",
          currency: "EUR",
        });
      },
    },
    {
      title: "Réglé",
      dataIndex: "reglement_ligne",
      key: "reglement_ligne",
      render: (lignes) => {
        let total = 0;
        lignes.forEach((ligne) => {
          total += parseInt(ligne.reglement_ligne_montant);
        });
        return parseFloat(total).toLocaleString("fr-FR", {
          style: "currency",
          currency: "EUR",
        });
      },
    },
    {
      title: "Solde dû",
      dataIndex: "solde",
      key: "solde",
      render: (solde) => {
        return parseFloat(solde).toLocaleString("fr-FR", {
          style: "currency",
          currency: "EUR",
        });
      },
    },
  ];
};

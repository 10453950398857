import React, { useRef, useState } from "react";
import { Button, Col, Input, Row, Table, Typography } from "antd";

import { CSVLink } from "react-csv";
import { useReactToPrint } from "react-to-print";
import { headers } from "./headers";

const { Search } = Input;
const { Title } = Typography;

const ListPersonne = (props) => {
  const [filterData, setFilterData] = useState(null);
  const { listData, tableOption, proprietaire } = props;

  const componentRef = useRef();

  const search = (value) => {
    const { listData, proprietaire } = props;

    const filterData = listData.filter((o) => {
      const p = proprietaire ? o.proprietaire_personne : o.locataire_personne;
      return Object.keys(p).some((k) => String(p[k]).toLowerCase().includes(value.toLowerCase()));
    });

    setFilterData(value ? filterData : listData);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const personne = proprietaire ? "proprietaire_personne" : "locataire_personne";

  return (
    <>
      <div style={{ padding: 20 }}>
        <Row>
          <Title level={2}>Liste des {proprietaire ? "propriétaires" : "locataires"}</Title>
        </Row>
        <Row>
          <Col span={12}>
            <Search
              placeholder="Recherche..."
              allowClear
              onSearch={search}
              style={{ width: 300 }}
            />
          </Col>
          <Col span={12}>
            <div style={{ float: "right" }}>
              <Button onClick={handlePrint} className="btn-primary">
                PDF
              </Button>
              {listData && (
                <Button style={{ marginLeft: 10 }}>
                  <CSVLink
                    filename={"export.csv"}
                    data={listData}
                    headers={headers(personne)}
                    separator={";"}
                  >
                    Export CSV
                  </CSVLink>
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <div ref={componentRef}>
        {listData && (
          <Table
            className="list-data"
            dataSource={filterData ? filterData : listData}
            columns={tableOption}
            pagination={false}
            rowKey={proprietaire ? "proprietaire_id" : "locataire_id"}
          />
        )}
      </div>
    </>
  );
};

export default ListPersonne;

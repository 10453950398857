import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Spin } from "antd";
import ListDocuments from "../../components/ListDocuments";
import { columns } from "./columns";
import { loadDocuments, updateDocument } from "../../store/actions/documentAction";

class Documents extends Component {
  componentDidMount() {
    const etablissement_id = this.props.utilisateur.etablissement_id;
    this.props.loadDocuments(etablissement_id);
  }

  render() {
    const { documents } = this.props;

    return (
      <Spin spinning={!documents} size="large" tip="Chargement en cours...">
        <Card title="" bordered={false} className="card-panel">
          <ListDocuments
            documents={documents}
            tableOption={columns}
            updateDocument={this.props.updateDocument} 
          ></ListDocuments>
        </Card>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadDocuments: (etablissement_id) => dispatch(loadDocuments(etablissement_id)),
    updateDocument: (jsonData, id) => dispatch(updateDocument(jsonData, id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    documents: state.documents.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);

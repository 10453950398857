import { HttpMethod, initFetch } from "../../utils/fetcher";
import { treeToAdapted } from "../../adapter/treeAdapter";

export const INIT_SUCCESS_TREE = "INIT_SUCCESS_TREE";
export const INIT_FAIL_TREE = "INIT_FAIL_TREE";
export const INIT_SUCCESS_DATA = "INIT_SUCCESS_DATA";
export const INIT_FAIL_DATA = "INIT_FAIL_DATA";
export const SAVE_SUCCESS_TYPES_PAIEMENTS = "SAVE_SUCCESS_TYPES_PAIEMENTS";
export const SAVE_FAIL_TYPES_PAIEMENTS = "SAVE_FAIL_TYPES_PAIEMENTS";
export const UPDATE_SUCCESS_TYPES_PAIEMENTS = "UPDATE_SUCCESS_TYPES_PAIEMENTS";
export const UPDATE_FAIL_TYPES_PAIEMENTS = "UPDATE_FAIL_TYPES_PAIEMENTS";
export const DELETE_SUCCESS_TYPES_PAIEMENTS = "DELETE_SUCCESS_TYPES_PAIEMENTS";
export const DELETE_FAIL_TYPES_PAIEMENTS = "DELETE_FAIL_TYPES_PAIEMENTS";

export function initTree() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`initTree/utilisateur`, HttpMethod.GET);

      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.requete);
          dispatch({
            type: INIT_SUCCESS_TREE,
            payload: treeToAdapted(res.requete),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: INIT_FAIL_TREE,
            error: err,
          });
        });
    });
  };
}

import React, { Component } from "react";
import { connect } from "react-redux";

import {
  saveCompte,
  updateCompte,
  deleteCompte,
  loadComptes,
} from "../../../store/actions/compteAction";

import TableCompte from "../../../components/TableCompte";
import { Spin } from "antd";

class Comptes extends Component {
  componentDidMount() {
    const societe_id = this.props.utilisateur.societe_id;
    this.props.loadComptes(societe_id);
  }

  onChangeType = (e) => {
    this.setState({ type: e.target.value });
  };

  render() {
    const { utilisateur, comptes, societes } = this.props;

    return (
      <Spin size="large" tip="Chargement en cours..." spinning={!comptes}>
        <TableCompte
          societes={societes}
          utilisateur={utilisateur}
          comptes={comptes}
          saveCompte={this.props.saveCompte}
          updateCompte={this.props.updateCompte}
          deleteCompte={this.props.deleteCompte}
        />
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadComptes: (societe_id) => dispatch(loadComptes(societe_id)),
    saveCompte: (compte) => dispatch(saveCompte(compte)),
    updateCompte: (compte, id) => dispatch(updateCompte(compte, id)),
    deleteCompte: (id) => dispatch(deleteCompte(id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    comptes: state.comptes.data,
    societes: state.societes.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Comptes);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Spin } from "antd";
import { columns } from "./columns";

import { deleteDemande, loadDemandes } from "../../store/actions/demandeAction";
import { loadLocataires, saveLocataire } from "../../store/actions/locataireAction";
import { loadLots } from "../../store/actions/lotAction";
import { saveRequete } from "../../store/actions/requeteAction";
import { saveRequeteLot } from "../../store/actions/requeteLotAction";
import { saveDossier, updateDossier } from "../../store/actions/dossierAction";
import { saveRecherche } from "../../store/actions/rechercheAction";

import ListDemandes from "../../components/ListDemandes";
import { saveRenduA } from "../../store/actions/renduAction";

class Demandes extends Component {
  componentDidMount() {
    const groupement_id = this.props.utilisateur.groupement_id;
    const societe_id = this.props.utilisateur.societe_id;
    this.props.loadDemandes(societe_id);
    this.props.loadLocataires(groupement_id);
    this.props.loadLots(societe_id);
  }

  render() {
    const {
      utilisateur,
      demandes,
      qualites,
      locataires,
      lots,
      groupes,
      provenances,
      parcoursClients,
      parametre,
    } = this.props;

    return (
      <Spin spinning={!demandes || !locataires} size="large" tip="Chargement en cours...">
        <Card title="" bordered={false} className="card-panel">
          <ListDemandes
            utilisateur={utilisateur}
            listData={demandes && demandes.sort((a, b) => b.id - a.id)}
            tableOption={columns}
            qualites={qualites}
            locataires={locataires
              ?.filter((loc) => loc.locataire_personne.personne_actif === true)
              .sort((a, b) => a.locataire_code.localeCompare(b.locataire_code))}
            lots={lots}
            groupes={groupes}
            provenances={provenances}
            parcoursClients={parcoursClients}
            parametre={parametre}
            deleteDemande={this.props.deleteDemande}
            saveLocataire={this.props.saveLocataire}
            saveRequete={this.props.saveRequete}
            saveRenduA={this.props.saveRenduA}
            saveRequeteLot={this.props.saveRequeteLot}
            saveRecherche={this.props.saveRecherche}
            saveDossier={this.props.saveDossier}
            updateDossier={this.props.updateDossier}
            addPanesRequete={this.props.addPanesRequete}
          />
        </Card>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadDemandes: (societe_id) => dispatch(loadDemandes(societe_id)),
    deleteDemande: (id) => dispatch(deleteDemande(id)),
    loadLocataires: (groupement_id) => dispatch(loadLocataires(groupement_id)),
    loadLots: (societe_id) => dispatch(loadLots(societe_id)),
    saveLocataire: (jsonData) => dispatch(saveLocataire(jsonData)),
    saveRequete: (jsonData) => dispatch(saveRequete(jsonData)),
    saveRenduA: (jsonData) => dispatch(saveRenduA(jsonData)),
    saveRequeteLot: (jsonData) => dispatch(saveRequeteLot(jsonData)),
    saveRecherche: (jsonData) => dispatch(saveRecherche(jsonData)),
    saveDossier: (jsonData) => dispatch(saveDossier(jsonData)),
    updateDossier: (jsonData, idLot) => dispatch(updateDossier(jsonData, idLot)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    demandes: state.demandes.data,
    qualites: state.qualites.data,
    locataires: state.locataires.data,
    lots: state.lots.data,
    groupes: state.groupes.data,
    provenances: state.provenances.data,
    parcoursClients: state.parcoursClients.data,
    parametre: state.parametre.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Demandes);

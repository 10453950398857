import React, { Component } from "react";
import { connect } from "react-redux";

import { Card } from "antd";

import { saveJournal, updateJournal, deleteJournal } from "../../../store/actions/journalAction";
import SelectJournal from "../../../components/SelectJournal";
import { loadComptes } from "../../../store/actions/compteAction";

import { toast } from "react-toastify";

class Journals extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const societe_id = this.props.utilisateur.societe_id;
    this.props.loadComptes(societe_id);
  }

  saveJournal = (data) => {
    this.props
      .saveJournal(data)
      .then((res) => toast.success("Journal créé !", { containerId: "A" }))
      .catch((err) => toast.error("Échec lors de la création !", { containerId: "A" }));
  };

  updateJournal = (data, id) => {
    this.props
      .updateJournal(data, id)
      .then((res) => toast.success("Journal édité !", { containerId: "A" }))
      .catch((err) => toast.error("Échec lors de l'édition !", { containerId: "A" }));
  };

  render() {
    const { utilisateur, societes, journals, comptes, typesPaiements } = this.props;

    const journalsSorted = journals.sort((a, b) => a.journal_code.localeCompare(b.journal_code));

    return (
      <div>
        <Card title="" bordered={false} className="card-panel">
          {journals && (
            <SelectJournal
              utilisateur={utilisateur}
              societes={societes}
              listData={journalsSorted}
              comptes={comptes}
              typesPaiements={typesPaiements}
              saveJournal={this.saveJournal}
              updateJournal={this.updateJournal}
            />
          )}
        </Card>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveJournal: (journal) => dispatch(saveJournal(journal)),
    updateJournal: (journal, id) => dispatch(updateJournal(journal, id)),
    deleteJournal: (id) => dispatch(deleteJournal(id)),
    loadComptes: (societe_id) => dispatch(loadComptes(societe_id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    societes: state.societes.data,
    journals: state.journals.data,
    comptes: state.comptes.data,
    typesPaiements: state.typesPaiements.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Journals);

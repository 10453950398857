import { societeToAdapted } from "./societeAdapter";
import { adaptedToCompte, compteToAdapted } from "./compteAdapter";

export function locataireAffectionsToAdapted(items) {
  return items
    ? items.map((item) => {
        return locataireAffectationToAdapted(item);
      })
    : null;
}

export function locataireAffectationToAdapted(item) {
  //Resource
  return item
    ? {
        locataire_affectation_locataire_id: item.locataire_id,
        locataire_affectation_societe: societeToAdapted(item.societe),
        locataire_affectation_compte: compteToAdapted(item.compte),
        locataire_affectation_compte_id: item.compte_id,
      }
    : null;
}

export function simplesLocataireAffectionsToAdapted(items) {
  return items
    ? items.map((item) => {
        return simpleLocataireAffectationToAdapted(item);
      })
    : null;
}

export function simpleLocataireAffectationToAdapted(item) {
  //Resource
  return item
    ? {
        locataire_affectation_locataire_id: item.locataire_id,
        locataire_affectation_societe_id: item.societe_id,
        locataire_affectation_compte_id: item.compte_id,
      }
    : null;
}

export function adaptedToLocataireAffectation(item) {
  //Model
  const affectation = item
    ? {
        locataire_id: item.locataire_affectation_locataire_id,
        societe_id: item.locataire_affectation_societe_id,
        compte_id: item.locataire_affectation_compte_id,
      }
    : null;

  const compte = item ? adaptedToCompte(item) : null;

  return item
    ? {
        ...affectation,
        ...compte,
      }
    : null;
}

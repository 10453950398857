import React, { Component } from "react";
import { connect } from "react-redux";

import { Card } from "antd";

import {
  deleteTypePaiement,
  saveTypePaiement,
  updateTypePaiement,
} from "../../store/actions/typePaiementAction";

import SelectTypePaiement from "../../components/SelectTypePaiement";

import { toast } from "react-toastify";

class TypesPaiements extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  saveTypePaiement = (data) => {
    data.type_paiement_groupement_id = this.props.utilisateur.groupement_id;
    this.props
      .saveTypePaiement(data)
      .then(() => toast.success("Type de paiement créé !", { containerId: "A" }))
      .catch(() => toast.error("Échec lors de la création !", { containerId: "A" }));
  };

  updateTypePaiement = (data, id) => {
    data.type_paiement_groupement_id = this.props.utilisateur.groupement_id;
    this.props
      .updateTypePaiement(data, id)
      .then(() => toast.success("Type de paiement édité !", { containerId: "A" }))
      .catch(() => toast.error("Échec lors de l'édition !", { containerId: "A" }));
  };

  deleteTypePaiement = (id) => {
    this.props
      .deleteTypePaiement(id)
      .then(() => toast.success("Type de paiement supprimé !", { containerId: "A" }))
      .catch(() => toast.error("Échec lors de la suppression", { containerId: "A" }));
  };

  render() {
    const { typesPaiements, journals } = this.props;

    return (
      <div>
        <Card title="" bordered={false} className="card-panel">
          {typesPaiements && (
            <SelectTypePaiement
              listData={typesPaiements}
              journals={journals}
              saveTypePaiement={this.saveTypePaiement}
              updateTypePaiement={this.updateTypePaiement}
              deleteTypePaiement={this.deleteTypePaiement}
            />
          )}
        </Card>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveTypePaiement: (journal) => dispatch(saveTypePaiement(journal)),
    updateTypePaiement: (journal, id) => dispatch(updateTypePaiement(journal, id)),
    deleteTypePaiement: (id) => dispatch(deleteTypePaiement(id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    typesPaiements: state.typesPaiements.data,
    journals: state.journals.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TypesPaiements);

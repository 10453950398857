import {
  LOAD_SUCCESS_DEMANDES,
  LOAD_FAIL_DEMANDES,
  SAVE_SUCCESS_DEMANDES,
  SAVE_FAIL_DEMANDES,
  DELETE_SUCCESS_DEMANDES,
  DELETE_FAIL_DEMANDES,
} from "../actions/demandeAction";

const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS_DEMANDES:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };
    case LOAD_FAIL_DEMANDES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error,
      };
    case SAVE_SUCCESS_DEMANDES:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: [...state.data, action.payload],
        error: null,
      };
    case SAVE_FAIL_DEMANDES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case DELETE_SUCCESS_DEMANDES:
      const filtered = state.data.filter((item) => {
        return item.id !== action.payload.id;
      });

      return {
        ...state,
        loading: false,
        loaded: true,
        data: filtered,
        error: null,
      };
    case DELETE_FAIL_DEMANDES:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: state.data,
        error: action.payload,
      };
    default:
      return state;
  }
}

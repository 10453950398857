import React, { Component } from "react";

import { Row, Col, Form, Input, Radio, Select, Button, Popconfirm } from "antd";

import { toast } from "react-toastify";

const { Option, OptGroup } = Select;

class MyFormTimer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      etape: null,
      etapeAction: null,
      etapeTimer: null,
      timer: true,
    };
  }

  // Référence du formulaire
  formRef = React.createRef();

  componentDidMount() {
    this.init(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.init(props);
  }

  init = (props) => {
    let etapeAction = null;
    let etapeTimer = null;

    // Après la sauvegarde / suppression l'étape n'est plus à jour car au niveau supérieur
    // on ne passe dans le getEtapeToEdit que lors du clic sur les boutons du bas
    // => petit tweak pour gérer ce cas étant donné que la liste des étapes est-elle à jour
    const etape = props.etape
      ? props.etapes.find((i) => i.etape_id === props.etape.etape_id)
      : null;

    if (etape && etape.etape_etape_action.length > 0) {
      etape.etape_etape_action.forEach((item) => {
        if (item.etape_action_etape_destination_action_id) etapeAction = item;
        else etapeTimer = item;
      });

      this.setState({ etape, etapeAction, etapeTimer }, () => {
        this.initForm();
      });
    } else {
      this.setState({
        etape,
        etapeAction: null,
        etapeTimer: null,
        timer: true,
      });
      this.formRef.current.resetFields();
    }
  };

  initForm = () => {
    const { timer, etapeAction, etapeTimer } = this.state;

    if (timer) {
      this.formRef.current.setFieldsValue({
        etape: etapeTimer ? etapeTimer.etape_action_etape_destination_timer_id : null,
        repere: etapeTimer ? etapeTimer.etape_action_repere : null,
        timer: etapeTimer ? etapeTimer.etape_action_timer : null,
      });
    } else {
      this.formRef.current.setFieldsValue({
        etape: etapeAction ? etapeAction.etape_action_etape_destination_action_id : null,
        action: etapeAction ? etapeAction.etape_action_action : null,
      });
    }
  };

  onChangeRadio = (e) => {
    this.setState({ timer: e.target.value === "timer" }, () => {
      this.initForm();
    });
  };

  onFinish = (data) => {
    const { timer, etape, etapeAction, etapeTimer } = this.state;

    const jsonData = {
      etape_action_type: timer ? 1 : 2,
      etape_action_repere: timer ? data.repere : null,
      etape_action_timer: timer ? data.timer : null,
      etape_action_action: timer ? null : data.action,
      etape_action_etape_destination_action_id: timer ? null : data.etape,
      etape_action_etape_destination_timer_id: timer ? data.etape : null,
      etape_action_etape_id: etape.etape_id,
    };

    if ((timer && etapeTimer) || (!timer && etapeAction)) {
      this.props
        .updateEtapeAction(
          jsonData,
          timer && etapeTimer ? etapeTimer.etape_action_id : etapeAction.etape_action_id
        )
        .then((res) => {
          toast.success("Modification réussie !", {
            containerId: "A",
          });
        })
        .catch((err) => {
          toast.error("Échec modification !", {
            containerId: "A",
          });
          console.log("err", err);
        });
    } else {
      this.props
        .saveEtapeAction(jsonData)
        .then((res) => {
          toast.success("Création réussie !", {
            containerId: "A",
          });
        })
        .catch((err) => {
          toast.error("Échec création !", {
            containerId: "A",
          });
          console.log("err", err);
        });
    }
  };

  handleDelete = () => {
    const { timer, etapeAction, etapeTimer } = this.state;

    this.props
      .deleteEtapeAction(
        timer && etapeTimer ? etapeTimer.etape_action_id : etapeAction.etape_action_id
      )
      .then((res) => {
        toast.success("Suppression réussie !", {
          containerId: "A",
        });
      })
      .catch((err) => {
        toast.error("Échec suppression !", {
          containerId: "A",
        });
        console.log("err", err);
      });
  };

  render() {
    const { etapes } = this.props;
    const { timer, etape, etapeAction, etapeTimer } = this.state;

    const formItemLayout = {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
      labelAlign: "left",
    };

    return (
      <Form
        {...formItemLayout}
        ref={this.formRef}
        className="ant-advanced-search-form"
        onFinish={this.onFinish}
      >
        <Form.Item name="type" label="Type" initialValue="timer">
          <Radio.Group onChange={this.onChangeRadio}>
            <Radio.Button key={1} value="timer">
              Timer
            </Radio.Button>
            <Radio.Button key={2} value="action">
              Action
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="etape"
          label="Destination"
          rules={[
            {
              required: true,
              message: "Etape requise.",
            },
          ]}
        >
          <Select placeholder="ETAPES DE DESTINATION" optionLabelProp="label">
            <Option key={null} value={0} label={"Aucune"}>
              <Row>
                <Col span={8}>{"Aucune"}</Col>
              </Row>
            </Option>
            {etapes &&
              etapes.map((item, i) => {
                return (
                  <Option
                    key={i}
                    value={item.etape_id}
                    label={
                      <span
                        style={{
                          color: item.etape_code_couleur.code_couleur_hex,
                        }}
                      >
                        <Row>
                          <Col span={8}>{item.etape_libelle}</Col>
                        </Row>
                      </span>
                    }
                  >
                    <span
                      style={{
                        color: item.etape_code_couleur.code_couleur_hex,
                      }}
                    >
                      <Row>
                        <Col span={8}>{item.etape_libelle}</Col>
                      </Row>
                    </span>
                  </Option>
                );
              })}
          </Select>
        </Form.Item>

        {timer ? (
          <div>
            <Form.Item
              name="repere"
              label="Date de repère"
              rules={[
                {
                  required: timer,
                  message: "Date requise",
                },
              ]}
            >
              <Select placeholder="DATE DE REPERE" optionLabelProp="label">
                <OptGroup label="Date">
                  <Option value={1} label="Arrivée dans l'étape">
                    Arrivée dans l'étape
                  </Option>
                  <Option value={2} label="Check-in">
                    Check-in
                  </Option>
                  <Option value={3} label="Check-out">
                    Check-out
                  </Option>
                </OptGroup>
              </Select>
            </Form.Item>

            <Form.Item
              name="timer"
              label="Timer"
              rules={[
                {
                  required: timer,
                  message: "Time requis",
                },
              ]}
            >
              <Input type="number" suffix="jours" />
            </Form.Item>
          </div>
        ) : (
          <Form.Item
            name="action"
            label="Action"
            rules={[
              {
                required: !timer,
                message: "Action requise",
              },
            ]}
          >
            <Select placeholder="ACTION" optionLabelProp="label">
              <OptGroup label="Action">
                <Option value={1} label="Proposition">
                  Proposition
                </Option>
                <Option value={2} label="Option">
                  Option
                </Option>
                <Option value={3} label="Réservation">
                  Réservation
                </Option>
                <Option value={4} label="Date paiement dépassée">
                  Date paiement dépassée
                </Option>
                <Option value={5} label="Envoi mail divers">
                  Envoi mail divers
                </Option>
              </OptGroup>
            </Select>
          </Form.Item>
        )}

        {etape && (
          <Form.Item {...formItemLayout}>
            <Button type="primary" style={{ marginRight: "20px" }} htmlType="submit">
              Sauvegarder
            </Button>
            {(timer && etapeTimer) || (!timer && etapeAction) ? (
              <Popconfirm title="Supprimer cette action ?" onConfirm={() => this.handleDelete()}>
                <Button type="danger">Supprimer</Button>
              </Popconfirm>
            ) : (
              <></>
            )}
          </Form.Item>
        )}
      </Form>
    );
  }
}

export default MyFormTimer;

import {
  LOAD_SUCCESS_DOCUMENTS,
  LOAD_FAIL_DOCUMENTS,
  SAVE_SUCCESS_DOCUMENTS,
  SAVE_FAIL_DOCUMENTS,
  UPDATE_SUCCESS_DOCUMENTS,
  UPDATE_FAIL_DOCUMENTS,
  DELETE_SUCCESS_DOCUMENTS,
  DELETE_FAIL_DOCUMENTS,
} from "../actions/documentAction";

const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS_DOCUMENTS:
      const documents = action.payload;
      documents.forEach((item) => {
        item.key = item.document_id;
      });
      return {
        ...state,
        loaded: true,
        data: documents,
        error: null,
      };
    case LOAD_FAIL_DOCUMENTS:
      return {
        ...state,
        loaded: false,
        data: null,
        error: action.error,
      };
    case SAVE_SUCCESS_DOCUMENTS:
      const newDocument = action.payload;
      newDocument.key = newDocument.document_id;
      return {
        ...state,
        loading: false,
        loaded: true,
        data: [...state.data, newDocument],
        error: null,
      };
    case SAVE_FAIL_DOCUMENTS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_DOCUMENTS:
      const updatedListe = [];
      state.data.forEach((item) => {
        if (item.document_id === action.payload.document_id) {
          item = action.payload;
        }
        updatedListe.push(item);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: updatedListe,
        error: null,
      };
    case UPDATE_FAIL_DOCUMENTS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case DELETE_SUCCESS_DOCUMENTS:
      const filtered = state.data.filter((item) => {
        return item.document_id !== action.id;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filtered,
        error: null,
      };
    case DELETE_FAIL_DOCUMENTS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    default:
      return state;
  }
}

import React, { useEffect, useRef, useState } from "react";
import { Row, Table, Typography } from "antd";

const { Title } = Typography;

const ListQuittance = (props) => {
  const { listData, locations, reglements, tableOption } = props;
  const [lignesQuittances, setLignesQuittances] = useState();
  const componentRef = useRef();

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const init = () => {
    applyFilter();
  };

  const applyFilter = () => {
    const epsilon = 0.001;
    const lignes = listData?.flatMap((data) => {
      if (locations && reglements) {
        const location = locations.find(
          (location) => location.location_id === data.quittance_location_id
        );
        const requete = location.location_requete;
        const locataire = requete.requete_locataire;
        const regls = reglements.filter(
          (reg) => reg.reglement_quittance.quittance_id === data.quittance_id
        );
        const totalQuittance = data.quittance_ligne.reduce(
          (a, b) => a + parseFloat(b.quittance_ligne_montant),
          0
        );
        const totalReglements =
          regls.length > 0 ? regls.reduce((a, b) => a + parseFloat(b.reglement_montant), 0) : 0;
        data.requete = requete;
        data.locataire = `${locataire.locataire_personne.personne_nom} ${locataire.locataire_personne.personne_prenom}`;
        data.montantTotal = totalQuittance;
        data.montantReglements = totalReglements;
        data.solde = totalQuittance - totalReglements;
        return data.solde > epsilon ? data : [];
      } else return data;
    });
    setLignesQuittances(lignes);
  };

  const onClickAfficher = (requete) => {
    const pane = { key: "26", title: "Recherche de disponibilités", content: "" };
    props.addPanesRequete(pane, "26", requete);
  };

  return (
    <>
      <div style={{ padding: 20 }}>
        <Row>
          <Title level={2}>Liste des quittances en attente de règlements</Title>
        </Row>
      </div>
      <div ref={componentRef}>
        {lignesQuittances && (
          <Table
            className="list-data"
            dataSource={lignesQuittances}
            columns={tableOption(onClickAfficher)}
            pagination={false}
            rowKey="quittance_id"
          />
        )}
      </div>
    </>
  );
};

export default ListQuittance;

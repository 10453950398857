export function groupesToAdapted(items) {
  return items
    ? items.map((item) => {
        return groupeToAdapted(item);
      })
    : null;
}

export function groupeToAdapted(item) {
  return item
    ? {
        groupe_id: item.id,
        groupe_libelle: item.libelle,
        groupe_alur: item.alur,
        groupe_court_terme: item.court_terme,
        groupe_long_terme: item.long_terme,
        groupe_visible: item.visible,
      }
    : null;
}

export function adaptedToGroupe(item) {
  return item
    ? {
        libelle: item.groupe_libelle,
        alur: item.groupe_alur,
        court_terme: item.groupe_court_terme,
        long_terme: item.groupe_long_terme,
        visible: item.groupe_visible,
        etablissement_id: item.groupe_etablissement_id,
      }
    : null;
}

import { HttpMethod, initFetch } from "../../utils/fetcher";
import { adaptedToPiece, piecesToAdapted, pieceToAdapted } from "../../adapter/pieceAdapter";

export const LOAD_SUCCESS_PIECES = "LOAD_SUCCESS_PIECES";
export const LOAD_FAIL_PIECES = "LOAD_FAIL_PIECES";
export const SAVE_SUCCESS_PIECES = "SAVE_SUCCESS_PIECES";
export const SAVE_FAIL_PIECES = "SAVE_FAIL_PIECES";
export const UPDATE_SUCCESS_PIECES = "UPDATE_SUCCESS_PIECES";
export const UPDATE_SUCCESS_PIECES_FROM_CONTENUS = "UPDATE_SUCCESS_PIECES_FROM_CONTENUS";
export const UPDATE_FAIL_PIECES_FROM_CONTENUS = "UPDATE_FAIL_PIECES_FROM_CONTENUS";
export const UPDATE_FAIL_PIECES = "UPDATE_FAIL_PIECES";
export const DELETE_SUCCESS_PIECES = "DELETE_SUCCESS_PIECES";
export const DELETE_FAIL_PIECES = "DELETE_FAIL_PIECES";

export function loadPieces() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`pieces`, HttpMethod.GET);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.piece);
          dispatch({
            type: LOAD_SUCCESS_PIECES,
            payload: piecesToAdapted(res.piece),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_PIECES,
            error: err,
          });
        });
    });
  };
}

export function savePiece(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`pieces`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToPiece(jsonData)) })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.piece);
          dispatch({
            type: SAVE_SUCCESS_PIECES,
            payload: pieceToAdapted(res.piece),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: SAVE_FAIL_PIECES,
            error: err,
          });
        });
    });
  };
}

export function updatePiece(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`pieces/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToPiece(jsonData)) })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.piece);
          dispatch({
            type: UPDATE_SUCCESS_PIECES,
            payload: pieceToAdapted(res.piece),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: UPDATE_FAIL_PIECES,
            error: err,
          });
        });
    });
  };
}

export function deletePiece(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`pieces/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.piece);
          dispatch({
            type: DELETE_SUCCESS_PIECES,
            payload: pieceToAdapted(res.piece),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_PIECES,
            payload: err,
          });
        });
    });
  };
}

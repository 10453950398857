import React, { Component } from "react";
import { connect } from "react-redux";

import { loadAgents, saveAgent, updateAgent } from "../../store/actions/agentAction";

import { Card, Spin } from "antd";
import SelectAgent from "../../components/SelectAgent";

import { toast } from "react-toastify";

class Agents extends Component {
  state = {
    personne: null,
    loading: false,
  };

  componentDidMount() {
    const groupement_id = this.props.utilisateur.groupement_id;
    this.props.loadAgents(groupement_id);
  }

  initPersonne = (personne, loading = false) => {
    this.setState({ personne, loading });
  };

  savePersonne = (data) => {
    this.props
      .saveAgent(data)
      .then((res) => {
        this.initPersonne(res);
        toast.success("Agent créé !", { containerId: "A" });
      })
      .catch(() => toast.error("Échec lors de la création !", { containerId: "A" }));
  };

  updatePersonne = (data, id) => {
    this.props
      .updateAgent(data, id)
      .then((res) => {
        this.initPersonne(res);
        toast.success("Agent édité !", { containerId: "A" });
      })
      .catch(() => toast.error("Échec lors de l'édition !", { containerId: "A" }));
  };

  archivePersonne = (id) => {
    // this.props
    //   .archiveAgent(id)
    //   .then((res) => {
    //     this.initPersonne(null);
    //     toast.success("Agent archivé !", { containerId: "A" });
    //   })
    //   .catch((err) => toast.error("Échec lors de l'archivage !", { containerId: "A" }));
  };

  render() {
    const {
      utilisateur,
      readOnly,
      societes,
      etablissements,
      qualites,
      langues,
      typesPaiements,
      agents,
      parametre,
    } = this.props;
    const { personne, loading } = this.state;

    return (
      <Spin size="large" tip="Chargement en cours..." spinning={!agents}>
        <Card title="" bordered={false} className="card-panel">
          <SelectAgent
            utilisateur={utilisateur}
            readOnly={readOnly}
            personneProps={personne}
            loading={loading}
            societes={societes}
            etablissements={etablissements}
            qualites={qualites}
            langues={langues}
            typesPaiements={typesPaiements}
            listData={
              agents &&
              agents
                .filter((age) => age.agent_personne.personne_actif === true)
                .sort((a, b) => a.agent_code.localeCompare(b.agent_code))
            }
            parametre={parametre}
            savePersonne={this.savePersonne}
            updatePersonne={this.updatePersonne}
          />
        </Card>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadAgents: (groupement_id) => dispatch(loadAgents(groupement_id)),
    saveAgent: (jsonData) => dispatch(saveAgent(jsonData)),
    updateAgent: (jsonData, id) => dispatch(updateAgent(jsonData, id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    societes: state.societes.data,
    etablissements: state.etablissements.data,
    qualites: state.qualites.data,
    langues: state.langues.data,
    typesPaiements: state.typesPaiements.data,
    agents: state.agents.data,
    parametre: state.parametre.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Agents);

import Constants from "./constants";
import store from "../store";

const baseUri = `${Constants.mode}://${Constants.domain}:${Constants.port}/api/v1`;
const pdfUri = `${Constants.mode}://${Constants.domain}`;

const header = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const headerFormData = {
  Accept: "application/json",
};

export const HttpMethod = Object.freeze({
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
});

const createUri = (url, pdf) => {
  return `${pdf ? pdfUri : baseUri}/${url}`;
};

export const initFetch = (
  url,
  method,
  options = { Authorization: true, formData: false, pdf: false }
) => {
  const customHeader = options.formData ? headerFormData : header;
  if (options.Authorization === true) {
    const state = store.getState();
    const authToken = state.utilisateur.data.token;

    customHeader["Authorization"] = `Bearer ${authToken}`;
  }

  return { url: createUri(url, options.pdf), params: { method: method, headers: customHeader } };
};

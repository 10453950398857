import React from "react";
import { Badge } from "antd";

export const columnsPro = [
  {
    title: "Soc.",
    dataIndex: "proprietaire_personne",
    key: "proprietaire_personne",
    sorter: (a, b) => true,
    render: (personne) => "01",
    width: "6%",
  },
  {
    title: "Code",
    dataIndex: "proprietaire_code",
    key: "proprietaire_code",
    sorter: (a, b) => parseInt(a.proprietaire_code) - parseInt(b.proprietaire_code),
    width: "8%",
  },
  {
    title: "Nom",
    dataIndex: "proprietaire_personne",
    key: "proprietaire_personne",
    sorter: (a, b) =>
      (a.proprietaire_personne.personne_nom
        ? a.proprietaire_personne.personne_nom
        : ""
      ).localeCompare(
        b.proprietaire_personne.personne_nom ? b.proprietaire_personne.personne_nom : ""
      ),
    render: (personne) => personne.personne_nom,
    width: "14%",
  },
  {
    title: "Prénom",
    dataIndex: "proprietaire_personne",
    key: "proprietaire_personne",
    sorter: (a, b) =>
      (a.proprietaire_personne.personne_prenom
        ? a.proprietaire_personne.personne_prenom
        : ""
      ).localeCompare(
        b.proprietaire_personne.personne_prenom ? b.proprietaire_personne.personne_prenom : ""
      ),
    render: (personne) => personne.personne_prenom,
    width: "14%",
  },
  {
    title: "Téléphone",
    dataIndex: "proprietaire_personne",
    key: "proprietaire_personne",
    render: (personne) => personne.personne_tel1,
    width: "14%",
  },
  {
    title: "Mail",
    dataIndex: "proprietaire_personne",
    key: "proprietaire_personne",
    render: (personne) => personne.personne_email1,
    width: "10%",
  },
  {
    title: "Ville",
    dataIndex: "proprietaire_personne",
    key: "proprietaire_personne",
    sorter: (a, b) =>
      (a.proprietaire_personne.personne_adresse.adresse_ville
        ? a.proprietaire_personne.personne_adresse.adresse_ville
        : ""
      ).localeCompare(
        b.proprietaire_personne.personne_adresse.adresse_ville
          ? b.proprietaire_personne.personne_adresse.adresse_ville
          : ""
      ),
    render: (personne) => personne.personne_adresse.adresse_ville,
    width: "10%",
  },
  {
    title: "Pays",
    dataIndex: "proprietaire_personne",
    key: "proprietaire_personne",
    sorter: (a, b) =>
      (a.proprietaire_personne.personne_adresse.adresse_pays
        ? a.proprietaire_personne.personne_adresse.adresse_pays
        : ""
      ).localeCompare(
        b.proprietaire_personne.personne_adresse.adresse_pays
          ? b.proprietaire_personne.personne_adresse.adresse_pays
          : ""
      ),
    render: (personne) => personne.personne_adresse.adresse_pays,
    width: "10%",
  },
  {
    title: "Langue",
    dataIndex: "proprietaire_personne",
    key: "proprietaire_personne",
    sorter: (a, b) =>
      (a.proprietaire_personne.personne_langue
        ? a.proprietaire_personne.personne_langue.langue_libelle
        : ""
      ).localeCompare(
        b.proprietaire_personne.personne_langue
          ? b.proprietaire_personne.personne_langue.langue_libelle
          : ""
      ),
    render: (personne) => (personne.personne_langue ? personne.personne_langue.langue_libelle : ""),
    width: "8%",
  },
  {
    title: "Extranet",
    dataIndex: "proprietaire_password_extranet",
    key: "proprietaire_password_extranet",
    render: (proprietaire_password_extranet) =>
      proprietaire_password_extranet ? <Badge status="success" /> : <Badge status="error" />,
    width: "6%",
  },
];

export const columnsLoc = [
  {
    title: "Soc.",
    dataIndex: "locataire_personne",
    key: "locataire_personne",
    sorter: (a, b) => true,
    render: (personne) => "01",
    width: "6%",
  },
  {
    title: "Code",
    dataIndex: "locataire_code",
    key: "locataire_code",
    sorter: (a, b) => parseInt(a.locataire_code) - parseInt(b.locataire_code),
    width: "8%",
  },
  {
    title: "Nom",
    dataIndex: "locataire_personne",
    key: "locataire_personne",
    sorter: (a, b) =>
      (a.locataire_personne.personne_nom ? a.locataire_personne.personne_nom : "").localeCompare(
        b.locataire_personne.personne_nom ? b.locataire_personne.personne_nom : ""
      ),
    render: (personne) => personne.personne_nom,
    width: "15%",
  },
  {
    title: "Prénom",
    dataIndex: "locataire_personne",
    key: "locataire_personne",
    sorter: (a, b) =>
      (a.locataire_personne.personne_prenom
        ? a.locataire_personne.personne_prenom
        : ""
      ).localeCompare(
        b.locataire_personne.personne_prenom ? b.locataire_personne.personne_prenom : ""
      ),
    render: (personne) => personne.personne_prenom,
    width: "15%",
  },
  {
    title: "Téléphone",
    dataIndex: "locataire_personne",
    key: "locataire_personne",
    render: (personne) => personne.personne_tel1,
    width: "14%",
  },
  {
    title: "Mail",
    dataIndex: "locataire_personne",
    key: "locataire_personne",
    render: (personne) => personne.personne_email1,
    width: "14%",
  },
  {
    title: "Ville",
    dataIndex: "locataire_personne",
    key: "locataire_personne",
    sorter: (a, b) =>
      (a.locataire_personne.personne_adresse.adresse_ville
        ? a.locataire_personne.personne_adresse.adresse_ville
        : ""
      ).localeCompare(
        b.locataire_personne.personne_adresse.adresse_ville
          ? b.locataire_personne.personne_adresse.adresse_ville
          : ""
      ),
    render: (personne) => personne.personne_adresse.adresse_ville,
    width: "10%",
  },
  {
    title: "Pays",
    dataIndex: "locataire_personne",
    key: "locataire_personne",
    sorter: (a, b) =>
      (a.locataire_personne.personne_adresse.adresse_pays
        ? a.locataire_personne.personne_adresse.adresse_pays
        : ""
      ).localeCompare(
        b.locataire_personne.personne_adresse.adresse_pays
          ? b.locataire_personne.personne_adresse.adresse_pays
          : ""
      ),
    render: (personne) => personne.personne_adresse.adresse_pays,
    width: "10%",
  },
  {
    title: "Langue",
    dataIndex: "locataire_personne",
    key: "locataire_personne",
    sorter: (a, b) =>
      (a.locataire_personne.personne_langue
        ? a.locataire_personne.personne_langue.langue_libelle
        : ""
      ).localeCompare(
        b.locataire_personne.personne_langue
          ? b.locataire_personne.personne_langue.langue_libelle
          : ""
      ),
    render: (personne) => (personne.personne_langue ? personne.personne_langue.langue_libelle : ""),
    width: "8%",
  },
];

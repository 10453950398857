import {
  LOAD_SUCCESS_ETAPES,
  LOAD_FAIL_ETAPES,
  SAVE_SUCCESS_ETAPES,
  SAVE_FAIL_ETAPES,
  UPDATE_SUCCESS_ETAPES,
  UPDATE_FAIL_ETAPES,
  DELETE_SUCCESS_ETAPES,
  DELETE_FAIL_ETAPES,
  SAVE_SUCCESS_ETAPES_ACTIONS,
  SAVE_FAIL_ETAPES_ACTIONS,
  UPDATE_SUCCESS_ETAPES_ACTIONS,
  UPDATE_FAIL_ETAPES_ACTIONS,
  DELETE_SUCCESS_ETAPES_ACTIONS,
  DELETE_FAIL_ETAPES_ACTIONS,
} from "../actions/etapeAction";

const initialState = {
  loaded: false,
};

const setLevels = (etapes) => {
  etapes.forEach((item) => {
    if (item.etape_etape_parente !== null) {
      if (item.etape_etape_parente.etape_id === 0) {
        item.level = 1;
      } else if (item.etape_etape_parente.etape_etape_parente.etape_id === 0) {
        item.level = 2;
      } else if (item.etape_etape_parente.etape_etape_parente.etape_etape_parente.etape_id === 0) {
        item.level = 3;
      }
    }
    item.key = item.etape_id;
    item.titre = item.etape_libelle;
  });

  return etapes;
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS_ETAPES:
      const loadedList = setLevels(action.payload);
      return {
        ...state,
        loading: false,
        loaded: true,
        data: loadedList,
        error: null,
      };
    case LOAD_FAIL_ETAPES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error,
      };
    case SAVE_SUCCESS_ETAPES:
      const addedListe = setLevels([...state.data, action.payload]);
      return {
        ...state,
        loading: false,
        loaded: true,
        data: addedListe,
        error: null,
      };
    case SAVE_FAIL_ETAPES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_ETAPES:
      let updatedListe = [];
      state.data.forEach((item) => {
        if (item.etape_id === action.payload.etape_id) {
          item = action.payload;
        }
        updatedListe.push(item);
      });

      updatedListe = setLevels(updatedListe);

      return {
        ...state,
        loading: false,
        loaded: true,
        data: updatedListe,
        error: null,
      };
    case UPDATE_FAIL_ETAPES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case DELETE_SUCCESS_ETAPES:
      const filtered = state.data.filter((item) => {
        return item.etape_id !== action.id;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filtered,
        error: null,
      };
    case DELETE_FAIL_ETAPES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error,
      };
    case SAVE_SUCCESS_ETAPES_ACTIONS:
      const addedListeActions = [];
      state.data.forEach((item) => {
        if (item.etape_id === action.payload.etape_action_etape_id) {
          item.etape_etape_action.push(action.payload);
        }
        addedListeActions.push(item);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: addedListeActions,
        error: null,
      };
    case SAVE_FAIL_ETAPES_ACTIONS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_ETAPES_ACTIONS:
      let updatedListeActions = [];
      state.data.forEach((item) => {
        if (item.etape_id === action.payload.etape_action_etape_id) {
          let addedListeActionsItems = [];
          item.etape_etape_action.forEach((i) => {
            if (i.etape_action_id === action.payload.etape_action_id) {
              i = action.payload;
            }
            addedListeActionsItems.push(i);
          });
          item.etape_etape_action = addedListeActionsItems;
        }
        updatedListeActions.push(item);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: updatedListeActions,
        error: null,
      };
    case UPDATE_FAIL_ETAPES_ACTIONS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: state.data,
        error: action.payload,
      };
    case DELETE_SUCCESS_ETAPES_ACTIONS:
      let deleteListeActions = [];
      state.data.forEach((item) => {
        if (item.etape_id === action.payload.etape_action_etape_id) {
          let deletedListeActionsItems = [];
          item.etape_etape_action.forEach((i) => {
            if (i.etape_action_id !== action.payload.etape_action_id) {
              deletedListeActionsItems.push(i);
            }
          });
          item.etape_etape_action = deletedListeActionsItems;
        }
        deleteListeActions.push(item);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: deleteListeActions,
        error: null,
      };
    case DELETE_FAIL_ETAPES_ACTIONS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error,
      };
    default:
      return state;
  }
}

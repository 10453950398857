import {
  LOAD_SUCCESS_REQUETES,
  LOAD_FAIL_REQUETES,
  SAVE_SUCCESS_REQUETES,
  SAVE_FAIL_REQUETES,
  UPDATE_SUCCESS_REQUETE,
  UPDATE_SUCCESS_REQUETES,
  UPDATE_FAIL_REQUETES,
  DELETE_SUCCESS_REQUETES,
  DELETE_FAIL_REQUETES,
} from "../actions/requeteAction";

import { SAVE_SUCCESS_REQUETE_EMAIL, DELETE_SUCCESS_REQUETE_EMAIL } from "../actions/emailAction";

import { SAVE_SUCCESS_REQUETE_RECHERCHE } from "../actions/rechercheAction";

import {
  SAVE_SUCCESS_NOTES,
  UPDATE_SUCCESS_NOTES,
  DELETE_SUCCESS_NOTES,
} from "../actions/noteAction";

import { UPDATE_SUCCESS_LOCATAIRES } from "../actions/locataireAction";

const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS_REQUETES:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };
    case LOAD_FAIL_REQUETES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error,
      };
    case SAVE_SUCCESS_REQUETES:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: [...state.data, action.payload],
        error: null,
      };
    case SAVE_FAIL_REQUETES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_REQUETE:
      let updatedListeR = [];
      state.data.forEach((item) => {
        if (item.requete_id === action.payload.requete_id) {
          item = action.payload;
        }
        updatedListeR.push(item);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: updatedListeR,
        error: null,
      };
    case UPDATE_SUCCESS_REQUETES:
      let updatedListe = [];
      state.data.forEach((item) => {
        if (item.requete_id === action.payload.requete_id) {
          item.requete_fin = action.payload.requete_fin;
        }
        updatedListe.push(item);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: updatedListe,
        error: null,
      };
    case UPDATE_FAIL_REQUETES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case DELETE_SUCCESS_REQUETES:
      const filtered = state.data.filter((item) => {
        return item.requete_id !== action.payload.requete_id;
      });

      return {
        ...state,
        loading: false,
        loaded: true,
        data: filtered,
        error: null,
      };
    case DELETE_FAIL_REQUETES:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: state.data,
        error: action.payload,
      };
    case SAVE_SUCCESS_REQUETE_EMAIL:
      const addEmail = [];

      state.data.forEach((requete) => {
        if (requete.requete_id === action.payload.email_requete_id)
          requete.requete_emails.push(action.payload);

        addEmail.push(requete);
      });

      return {
        ...state,
        loading: false,
        loaded: true,
        data: addEmail,
        error: null,
      };
    case DELETE_SUCCESS_REQUETE_EMAIL:
      const deleteEmail = [];

      state.data.forEach((requete) => {
        if (requete.requete_id === action.payload.email_requete_id) {
          requete.requete_emails = requete.requete_emails.filter((item) => {
            return item.email_id !== action.payload.email_id;
          });
        }
        deleteEmail.push(requete);
      });

      return {
        ...state,
        loading: false,
        loaded: true,
        data: deleteEmail,
        error: null,
      };
    case SAVE_SUCCESS_REQUETE_RECHERCHE:
      const addRecherche = [];

      state.data.forEach((requete) => {
        if (requete.requete_id === action.payload.recherche_requete_id)
          requete.requete_recherche = action.payload;

        addRecherche.push(requete);
      });

      return {
        ...state,
        loading: false,
        loaded: true,
        data: addRecherche,
        error: null,
      };
    // Reducers notes
    case SAVE_SUCCESS_NOTES:
      const noteAdded = [];
      state.data.forEach((requete) => {
        if (requete.requete_id === action.payload.requete_id) {
          let array = requete.notes;
          array = [...array, action.payload];
          requete.notes = array;
        }
        noteAdded.push(requete);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: noteAdded,
        error: null,
      };
    case UPDATE_SUCCESS_NOTES:
      const noteUpdated = [];
      state.data.forEach((requete) => {
        const notes = [];
        if (requete.requete_id === action.payload.requete_id) {
          requete.notes.forEach((note) => {
            if (note.id === action.id) {
              note = action.payload;
            }
            notes.push(note);
          });
          requete.notes = notes;
        }
        noteUpdated.push(requete);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: noteUpdated,
        error: null,
      };
    case DELETE_SUCCESS_NOTES:
      const noteDeleted = [];
      state.data.forEach((requete) => {
        if (requete.requete_id === action.payload.requete_id) {
          requete.notes = requete.notes.filter((note) => {
            return note.id !== action.id;
          });
        }
        noteDeleted.push(requete);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: noteDeleted,
        error: null,
      };
    case UPDATE_SUCCESS_LOCATAIRES:
      const locataireUpdated = state.data.map((requete) => {
        if (requete.requete_locataire?.locataire_id === action.id) {
          requete.requete_locataire = action.payload;
        }
        return requete;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: locataireUpdated,
        error: null,
      };
    default:
      return state;
  }
}

import React, { Component } from "react";

export default class GrandLivrePDF extends Component {
  render() {
    const {
      lignesEcritures,
      dateDu,
      dateAu,
      compteCentralisateur,
      compteDu,
      compteAu,
      comptes,
      soldeEvolutif,
      tri,
    } = this.props;

    let lignesComptes = null;
    let cptGen = null;

    if (compteCentralisateur) {
      cptGen = comptes.find(
        (compte) => compte.compte_id === compteCentralisateur
      ).compte_compte_general;

      lignesComptes = comptes
        .filter((compte) => compte.compte_type_compte === "A")
        .filter((compte) => compte.compte_compte_general === cptGen)
        .sort((a, b) => a.compte_compte_auxiliaire - b.compte_compte_auxiliaire);
    } else {
      lignesComptes = comptes
        .filter((compte) => compte.compte_type_compte === "G" || compte.compte_type_compte === "C")
        .sort((a, b) => a.compte_compte_general - b.compte_compte_general);
    }

    if (tri === 2) lignesComptes.sort((a, b) => a.compte_libelle.localeCompare(b.compte_libelle));

    let totalDebit = 0;
    let totalCredit = 0;
    let totalSolde = 0;
    let totalSoldeDebit = 0;
    let totalSoldeCredit = 0;

    lignesEcritures.forEach((ligne, i) => {
      totalDebit += parseFloat(ligne.ecriture_ligne_montant_debit);
      totalCredit += parseFloat(ligne.ecriture_ligne_montant_credit);
    });

    totalSolde = totalCredit - totalDebit;
    if (totalSolde < 0) {
      totalSoldeDebit = totalSolde;
    } else if (totalSolde > 0) {
      totalSoldeCredit = totalSolde;
    }

    let totalDebitF = 0;
    let totalCreditF = 0;
    let totalSoldeF = 0;
    let totalSoldeDebitF = 0;
    let totalSoldeCreditF = 0;

    let soldeEv = 0;

    return (
      <div>
        {/* Titre */}
        <div className="entitre row">
          <h1>
            {compteCentralisateur ? (
              <>{`Grand Livre Auxiliaire ${
                comptes.find((compte) => compte.compte_id === compteCentralisateur)
                  .compte_compte_general
              } - ${
                comptes.find((compte) => compte.compte_id === compteCentralisateur).compte_libelle
              }`}</>
            ) : (
              <>{`Grand Livre Général`}</>
            )}
          </h1>
          <p className="center">
            <span>
              <strong>
                {`Situation ${
                  dateDu ? "du " + dateDu.format("DD/MM/YYYY") + " " : ""
                }au ${dateAu.format("DD/MM/YYYY")}`}
              </strong>
            </span>
          </p>
          <p>
            {compteCentralisateur ? (
              <strong>
                {`Compte du ${
                  comptes.find(
                    (compte) =>
                      compte.compte_compte_general === cptGen &&
                      compte.compte_compte_auxiliaire === compteDu
                  ).compte_compte_auxiliaire
                } - ${
                  comptes.find(
                    (compte) =>
                      compte.compte_compte_general === cptGen &&
                      compte.compte_compte_auxiliaire === compteDu
                  ).compte_libelle
                } au ${
                  comptes.find(
                    (compte) =>
                      compte.compte_compte_general === cptGen &&
                      compte.compte_compte_auxiliaire === compteAu
                  ).compte_compte_auxiliaire
                } - ${
                  comptes.find(
                    (compte) =>
                      compte.compte_compte_general === cptGen &&
                      compte.compte_compte_auxiliaire === compteAu
                  ).compte_libelle
                }`}
              </strong>
            ) : (
              <strong>
                {`Compte du ${
                  comptes.find((compte) => compte.compte_compte_general === compteDu)
                    .compte_compte_general
                } - ${
                  comptes.find((compte) => compte.compte_compte_general === compteDu).compte_libelle
                } au ${
                  comptes.find((compte) => compte.compte_compte_general === compteAu)
                    .compte_compte_general
                } - ${
                  comptes.find((compte) => compte.compte_compte_general === compteAu).compte_libelle
                }`}
              </strong>
            )}
          </p>
        </div>

        {lignesComptes.map((compte, j) => {
          if (
            ((compteCentralisateur
              ? compte.compte_compte_auxiliaire >= compteDu
              : compte.compte_compte_general >= compteDu) &&
              (compteCentralisateur
                ? compte.compte_compte_auxiliaire <= compteAu
                : compte.compte_compte_general <= compteAu)) ||
            compte.compte_type_compte === "C"
          ) {
            const lignesEcrituresFiltered = lignesEcritures.filter(
              (e) => e.ecriture_ligne_compte.compte_id === compte.compte_id
            );
            if (lignesEcrituresFiltered.length !== 0) {
              return (
                <div key={j}>
                  <table className="table table-striped" border="1">
                    <thead>
                      <tr>
                        <th width="100%">
                          {compteCentralisateur ? (
                            <strong>
                              {" "}
                              {compte.compte_compte_auxiliaire}
                              {" - "}
                              {compte.compte_libelle}
                            </strong>
                          ) : (
                            <strong>
                              {" "}
                              {compte.compte_compte_general}
                              {" - "}
                              {compte.compte_libelle}
                            </strong>
                          )}
                        </th>
                      </tr>
                      <tr>
                        <th width="5%" scope="col" align="center">
                          <strong>JRN</strong>
                        </th>
                        <th width="10%" scope="col" align="center">
                          <strong>DATE</strong>
                        </th>

                        <th width="42%" scope="col" align="center">
                          <strong>LIBELLE</strong>
                        </th>
                        <th width="12%" scope="col" align="center">
                          <strong>DEBIT</strong>
                        </th>
                        <th width="12%" scope="col" align="center">
                          <strong>CREDIT</strong>
                        </th>
                        <th width="12%" scope="col" align="center">
                          <strong>SOLDE</strong>
                        </th>
                        <th width="7%" scope="col" align="center">
                          <strong>REDD.</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {lignesEcrituresFiltered.map((ligneF, i) => {
                        console.log(ligneF, "ligne");
                        if (i === 0) {
                          totalDebitF = 0;
                          totalCreditF = 0;
                          totalDebitF += parseFloat(ligneF.ecriture_ligne_montant_debit);
                          totalCreditF += parseFloat(ligneF.ecriture_ligne_montant_credit);
                        } else {
                          totalDebitF += parseFloat(ligneF.ecriture_ligne_montant_debit);
                          totalCreditF += parseFloat(ligneF.ecriture_ligne_montant_credit);
                        }
                        if (i === lignesEcrituresFiltered.length - 1) {
                          totalSoldeDebitF = 0;
                          totalSoldeCreditF = 0;
                          totalSoldeF = totalCreditF - totalDebitF;
                          if (totalSoldeF < 0) {
                            totalSoldeDebitF = Math.abs(totalSoldeF);
                          } else if (totalSoldeF > 0) {
                            totalSoldeCreditF = totalSoldeF;
                          }
                        }
                        if (!soldeEvolutif) {
                          soldeEv = 0;
                        } else {
                          soldeEv = ligneF.solde;
                        }
                        if (i % 2) {
                          return (
                            <tr className="odd" key={i}>
                              <td width="5%" align="center">
                                {ligneF.ecriture_journal_code}
                              </td>
                              <td width="10%" align="center">
                                {ligneF.ecriture_date_ecriture}
                              </td>

                              <td width="42%"> {ligneF.ecriture_ligne_libelle}</td>
                              <td width="12%" style={{ textAlign: "right" }}>
                                {parseInt(ligneF.ecriture_ligne_montant_debit) !== 0 ? (
                                  parseFloat(ligneF.ecriture_ligne_montant_debit).toLocaleString(
                                    "fr-FR",
                                    {
                                      style: "currency",
                                      currency: "EUR",
                                    }
                                  )
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td width="12%" style={{ textAlign: "right" }}>
                                {parseInt(ligneF.ecriture_ligne_montant_credit) !== 0 ? (
                                  parseFloat(ligneF.ecriture_ligne_montant_credit).toLocaleString(
                                    "fr-FR",
                                    {
                                      style: "currency",
                                      currency: "EUR",
                                    }
                                  )
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td width="12%" style={{ textAlign: "right" }}>
                                {parseInt(soldeEv) !== 0 ? (
                                  parseFloat(soldeEv).toLocaleString("fr-FR", {
                                    style: "currency",
                                    currency: "EUR",
                                  })
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td width="7%" style={{ textAlign: "center" }}>
                                {ligneF.ecriture_ligne_en_reddition ? "*" : ""}
                              </td>
                            </tr>
                          );
                        } else {
                          return (
                            <tr key={i}>
                              <td width="5%" align="center">
                                {ligneF.ecriture_journal_code}
                              </td>
                              <td width="10%" align="center">
                                {ligneF.ecriture_date_ecriture}
                              </td>

                              <td width="42%"> {ligneF.ecriture_ligne_libelle}</td>
                              <td width="12%" style={{ textAlign: "right" }}>
                                {parseInt(ligneF.ecriture_ligne_montant_debit) !== 0 ? (
                                  parseFloat(ligneF.ecriture_ligne_montant_debit).toLocaleString(
                                    "fr-FR",
                                    {
                                      style: "currency",
                                      currency: "EUR",
                                    }
                                  )
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td width="12%" style={{ textAlign: "right" }}>
                                {parseInt(ligneF.ecriture_ligne_montant_credit) !== 0 ? (
                                  parseFloat(ligneF.ecriture_ligne_montant_credit).toLocaleString(
                                    "fr-FR",
                                    {
                                      style: "currency",
                                      currency: "EUR",
                                    }
                                  )
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td width="12%" style={{ textAlign: "right" }}>
                                {parseInt(soldeEv) !== 0 ? (
                                  parseFloat(soldeEv).toLocaleString("fr-FR", {
                                    style: "currency",
                                    currency: "EUR",
                                  })
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td width="7%" style={{ textAlign: "center" }}>
                                {ligneF.ecriture_ligne_en_reddition ? "*" : ""}
                              </td>
                            </tr>
                          );
                        }
                      })}
                      <tr>
                        <td width="57%" align="right">
                          <strong>TOTAL </strong>
                        </td>
                        <td width="12%" align="right">
                          {parseInt(totalDebitF) !== 0 ? (
                            parseFloat(totalDebitF).toLocaleString("fr-FR", {
                              style: "currency",
                              currency: "EUR",
                            })
                          ) : (
                            <></>
                          )}
                        </td>
                        <td width="12%" align="right">
                          {parseInt(totalCreditF) !== 0 ? (
                            parseFloat(totalCreditF).toLocaleString("fr-FR", {
                              style: "currency",
                              currency: "EUR",
                            })
                          ) : (
                            <></>
                          )}
                          {/* {parseFloat(totalCreditF).toLocaleString("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                          })} */}
                        </td>
                        <td width="12%" align="right"></td>
                        <td width="7%" align="right"></td>
                      </tr>
                      <tr>
                        <td width="57%" align="right">
                          {compteCentralisateur ? (
                            <strong>
                              SOLDE DU COMPTE {compte.compte_compte_auxiliaire}
                              {" - "}
                              {compte.compte_libelle}{" "}
                            </strong>
                          ) : (
                            <strong>
                              SOLDE DU COMPTE {compte.compte_compte_general}
                              {" - "}
                              {compte.compte_libelle}{" "}
                            </strong>
                          )}
                        </td>
                        <td width="12%" align="right">
                          {parseInt(totalSoldeDebitF) !== 0 ? (
                            parseFloat(totalSoldeDebitF).toLocaleString("fr-FR", {
                              style: "currency",
                              currency: "EUR",
                            })
                          ) : (
                            <></>
                          )}
                        </td>
                        <td width="12%" align="right">
                          {parseInt(totalSoldeCreditF) !== 0 ? (
                            parseFloat(totalSoldeCreditF).toLocaleString("fr-FR", {
                              style: "currency",
                              currency: "EUR",
                            })
                          ) : (
                            <></>
                          )}
                        </td>
                        <td width="12%" align="right"></td>
                        <td width="7%" align="right"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            }
          }
          return null;
        })}

        <div>
          <table className="table table-striped" border="1">
            <thead></thead>
            <tbody>
              <tr>
                <td width="57%" align="right">
                  <strong>TOTAL GENERAL </strong>
                </td>
                <td width="12%" style={{ textAlign: "right" }}>
                  {parseFloat(totalDebit).toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </td>
                <td width="12%" style={{ textAlign: "right" }}>
                  {parseFloat(totalCredit).toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </td>
                <td width="12%" style={{ textAlign: "right" }}></td>
                <td width="7%" align="right"></td>
              </tr>
              <tr>
                <td width="57%" align="right">
                  <strong>SOLDE GENERAL </strong>
                </td>
                <td width="12%" style={{ textAlign: "right" }}>
                  {parseInt(totalSoldeDebit) !== 0 ? (
                    parseFloat(totalSoldeDebit).toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    })
                  ) : (
                    <></>
                  )}
                </td>
                <td width="12%" style={{ textAlign: "right" }}>
                  {parseInt(totalSoldeCredit) !== 0 ? (
                    parseFloat(totalSoldeCredit).toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    })
                  ) : (
                    <></>
                  )}
                </td>
                <td width="12%" style={{ textAlign: "right" }}></td>
                <td width="7%" align="right"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

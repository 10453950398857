import { pieceContenusToAdapted } from "./pieceContenuAdapter";

export function piecesToAdapted(items) {
  return items
    ? items.map((item) => {
        return pieceToAdapted(item);
      })
    : null;
}

export function pieceToAdapted(item) {
  //Resource
  return item
    ? {
        index: item.index,
        piece_id: item.id,
        piece_libelle: item.libelle,
        piece_champ_perso_groupe: item.champ_perso_groupe_id,
        piece_lot_id: item.lot_id,
        piece_contenu: pieceContenusToAdapted(item.piece_contenu),
      }
    : null;
}

export function adaptedToPiece(item) {
  //Model
  return item
    ? {
        lot_id: item.piece_lot_id,
        champ_perso_groupe_id: item.piece_champ_perso_groupe_id,
        libelle: item.piece_libelle,
      }
    : null;
}

import React, { useEffect, useState } from "react";

import FloatLabel from "../../FloatLabel";
import { Form, Select } from "antd";

const CustomSelect = (props) => {
  const [value, setValue] = useState(false);
  const {
    children,
    label,
    formItemName,
    inputName,
    objectValue,
    defaultValue,
    autoFocus,
    defaultOpen,
    rules,
    labelInValue,
    allowClear,
    mode,
  } = props;

  useEffect(() => {
    objectValue ? setValue(objectValue) : setValue(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.objectValue]);

  const onChange = (id) => {
    props.onChange && props.onChange(id);
    setValue(id);
  };

  const onSelect = (id) => {
    props.onSelect && props.onSelect(id);
    setValue(id);
  };

  return (
    <FloatLabel label={label} name={inputName} value={value}>
      <Form.Item name={formItemName} rules={[rules]}>
        <Select
          showSearch
          optionFilterProp="label"
          optionLabelProp="label"
          onChange={(id) => onChange(id)}
          onSelect={(id) => onSelect(id)}
          defaultValue={defaultValue}
          autoFocus={autoFocus}
          defaultOpen={defaultOpen}
          labelInValue={labelInValue}
          allowClear={allowClear}
          mode={mode}
        >
          {children}
        </Select>
      </Form.Item>
    </FloatLabel>
  );
};

export default CustomSelect;

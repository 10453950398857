import { journalToAdapted } from "./journalAdapter";
import { quittanceToAdapted } from "./quittanceAdapter";
import { reglementsLignesToAdapted } from "./reglementLigneAdapter";
import { typePaiementToAdapted } from "./typePaiementAdapter";

export function reglementsToAdapted(items) {
  return items
    ? items.map((item) => {
        return reglementToAdapted(item);
      })
    : null;
}

export function reglementToAdapted(item) {
  //Resource
  return item
    ? {
        reglement_id: item.id,
        reglement_montant: item.montant,
        reglement_reddition: item.reddition,
        reglement_banque: item.banque,
        reglement_tire: item.tire,
        reglement_numero_cheque: item.numero_cheque,
        reglement_date: item.date,
        reglement_trop_percu: item.trop_percu,
        reglement_bordereau: item.bordereau,
        reglement_groupe_par: item.groupe_par,
        reglement_ligne: reglementsLignesToAdapted(item.reglement_ligne),
        reglement_quittance: quittanceToAdapted(item.quittance),
        reglement_type_paiement: typePaiementToAdapted(item.type_paiement),
        reglement_journal: journalToAdapted(item.journal),
      }
    : null;
}

export function adaptedToReglements(items) {
  return items
    ? {
        reglements: items.reglements.map((item) => {
          return adaptedToReglement(item);
        }),
      }
    : null;
}

export function adaptedToReglement(item) {
  //Model
  return item
    ? {
        id: item.reglement_id,
        banque: item.reglement_banque,
        bordereau: item.reglement_bordereau,
        date: item.reglement_date,
        groupe_par: item.reglement_groupe_par,
        journal_id: item.reglement_journal_id,
        montant: item.reglement_montant,
        quittance_id: item.reglement_quittance_id,
        reddition: item.reglement_reddition,
        trop_percu: item.reglement_trop_percu,
        type_paiement_id: item.reglement_type_paiement_id,
        numero_cheque: item.reglement_numero_cheque,
        tire: item.reglement_tire,
      }
    : null;
}

import { etablissementToAdapted } from "./etablissementAdapter";

export function cautionsToAdapted(items) {
  return items
    ? items.map((item) => {
        return cautionToAdapted(item);
      })
    : null;
}

export function cautionToAdapted(item) {
  return item
    ? {
        caution_id: item.id,
        caution_mois_debut: item.mois_debut,
        caution_comparateur_debut: item.comparateur_debut,
        caution_mois_fin: item.mois_fin,
        caution_comparateur_fin: item.comparateur_fin,
        caution_rubrique_id: item.rubrique_id,
        caution_rubrique_liee_id: item.rubrique_liee_id,
        caution_pourcentage: item.pourcentage,
        caution_type_contrat: item.type_contrat,
        caution_etablissement: etablissementToAdapted(item.etablissement),
      }
    : null;
}

export function adaptedToCautions(items) {
  return items
    ? items.map((item) => {
        return adaptedToCaution(item);
      })
    : null;
}

export function adaptedToCaution(item) {
  return item
    ? {
        id: item.caution_id,
        rubrique_id: item.caution_rubrique_id,
        rubrique_liee_id: item.caution_rubrique_liee_id,
        mois_debut: item.caution_mois_debut,
        comparateur_debut: item.caution_comparateur_debut,
        mois_fin: item.caution_mois_fin,
        comparateur_fin: item.caution_comparateur_fin,
        pourcentage: item.caution_pourcentage,
        type_contrat: item.caution_type_contrat,
        etablissement_id: item.caution_etablissement_id,
      }
    : null;
}

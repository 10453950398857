import { tagToAdapted } from "./tagAdapter";

export function wordsToAdapted(items) {
  return items
    ? items.map((item) => {
        return wordToAdapted(item);
      })
    : null;
}

export function wordToAdapted(item) {
  //Resource
  return item
    ? {
        word_id: item.id,
        word_nom: item.nom,
        word_type: item.type,
        word_tag_id: item.tag_id,
        word_tag: tagToAdapted(item.tag),
        word_etablissement_id: item.etablissement_id,
      }
    : null;
}

export function adaptedToWord(item) {
  //Model
  return item
    ? {
        nom: item.word_nom,
        type: item.word_type,
        tag_id: item.word_tag_id,
        etablissement_id: item.word_etablissement_id,
      }
    : null;
}

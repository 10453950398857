import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Form, Row, Select, Spin } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import FormSociete from "../FormSociete";
import FormEtablissement from "../FormEtablissement";

const { Option, OptGroup } = Select;

const SelectOrganisation = (props) => {
  const [organisation, setOrganisation] = useState(null);
  // const [selected, setSelected] = useState(null);
  const { societe, listData } = props;

  // Référence du formulaire
  const formRefOrganisation = useRef();

  useEffect(() => {
    organisation && onSelectOrg(societe ? organisation.societe_id : organisation.etablissement_id);
  });

  function onClickAddOrg() {
    setOrganisation(null);
  }

  function onClickDeleteOrg() {
    props.societe
      ? props.handleDeleteSociete(organisation.societe_id)
      : props.handleDeleteEtablissement(organisation.etablissement_id);
    formRefOrganisation.current.resetFields();
    setOrganisation(null);
  }

  function onSelectOrg(id) {
    const organisation = props.listData.find((e) =>
      props.societe ? e.societe_id === id : e.etablissement_id === id
    );
    setOrganisation(organisation);
  }

  const addOrganisationButton = (
    <Button type="primary" htmlType="reset" onClick={onClickAddOrg}>
      <PlusOutlined />
    </Button>
  );

  const deleteOrganisationButton = (
    <Button type="primary" htmlType="reset" onClick={onClickDeleteOrg} disabled={!organisation}>
      <DeleteOutlined />
    </Button>
  );

  return (
    <Spin spinning={false} size="large" tip="Chargement...">
      <div style={{ padding: 20 }}>
        <Form ref={formRefOrganisation}>
          <Row>
            <Col span={11}>
              <Form.Item name="organisation_id">
                <Select
                  showSearch
                  optionLabelProp="label"
                  placeholder={societe ? "Société" : "Établissement"}
                  onSelect={onSelectOrg}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <OptGroup label={societe ? "Société" : "Établissement"}>
                    {listData &&
                      listData.map((item, i) => {
                        return (
                          <Option
                            key={i}
                            value={societe ? item.societe_id : item.etablissement_id}
                            label={
                              societe
                                ? item.societe_raison_sociale
                                : item.etablissement_raison_sociale
                            }
                          >
                            <Row>
                              <Col sm={8}>
                                {societe
                                  ? item.societe_raison_sociale
                                  : item.etablissement_raison_sociale}
                              </Col>
                            </Row>
                          </Option>
                        );
                      })}
                  </OptGroup>
                </Select>
              </Form.Item>
            </Col>
            <Col span={1}>
              <Form.Item>{addOrganisationButton}</Form.Item>
            </Col>
            <Col span={1}>
              <Form.Item>{deleteOrganisationButton}</Form.Item>
            </Col>
          </Row>
        </Form>
        {societe ? (
          <FormSociete
            numClient={listData.length + 1}
            handleSubmit={props.handleSubmitSociete}
            handleEdit={props.handleEditSociete}
            societe={organisation}
          />
        ) : (
          <FormEtablissement
            societes={props.societes}
            handleSubmit={props.handleSubmitEtablissement}
            handleEdit={props.handleEditEtablissement}
            etablissement={organisation}
          />
        )}
      </div>
    </Spin>
  );
};

export default SelectOrganisation;

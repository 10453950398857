import { adaptedToAdresse, adresseToAdapted } from "./adresseAdapter";
import { societeToAdapted } from "./societeAdapter";

export function etablissementsToAdapted(items) {
  return items
    ? items.map((item) => {
        return etablissementToAdapted(item);
      })
    : null;
}

export function etablissementToAdapted(item) {
  return item
    ? {
        etablissement_id: item.id,
        etablissement_raison_sociale: item.raison_sociale,
        etablissement_telephone: item.telephone,
        etablissement_email: item.email,
        etablissement_adresse: adresseToAdapted(item.adresse),
        etablissement_nom_court: item.nom_court,
        etablissement_telephone_2: item.telephone_2,
        etablissement_site_web: item.site_web,
        etablissement_zone_libre_1: item.zone_libre_1,
        etablissement_zone_libre_2: item.zone_libre_2,
        etablissement_zone_libre_3: item.zone_libre_3,
        etablissement_zone_libre_4: item.zone_libre_4,
        etablissement_zone_libre_5: item.zone_libre_5,
        etablissement_societe_id: item.societe_id,
        etablissement_societe: societeToAdapted(item.societe),
      }
    : null;
}

export function adaptedToEtablissement(item) {
  const etablissement = item
    ? {
        raison_sociale: item.etablissement_raison_sociale,
        telephone: item.etablissement_telephone,
        email: item.etablissement_email,
        adresse_id: item.etablissement_adresse_id,
        nom_court: item.etablissement_nom_court,
        telephone_2: item.etablissement_telephone_2,
        site_web: item.etablissement_site_web,
        zone_libre_1: item.etablissement_zone_libre_1,
        zone_libre_2: item.etablissement_zone_libre_2,
        zone_libre_3: item.etablissement_zone_libre_3,
        zone_libre_4: item.etablissement_zone_libre_4,
        zone_libre_5: item.etablissement_zone_libre_5,
        societe_id: item.etablissement_societe_id,
      }
    : null;

  const adresse = item ? adaptedToAdresse(item) : null;

  return item ? { ...etablissement, ...adresse } : null;
}

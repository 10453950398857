import {
  LOAD_SUCCESS_RENDUA,
  LOAD_FAIL_RENDUA,
  SAVE_SUCCESS_RENDUA,
  SAVE_FAIL_RENDUA,
  UPDATE_SUCCESS_RENDUA,
  UPDATE_FAIL_RENDUA,
  DELETE_SUCCESS_RENDUA,
  DELETE_FAIL_RENDUA,
  DELETE_SUCCESS_RENDUA_PC,
  DELETE_FAIL_RENDUA_PC,
} from "../actions/renduAction";

import { SAVE_SUCCESS_RENDUA_EMAIL } from "../actions/emailAction";

import { SAVE_SUCCESS_RENDUA_RECHERCHE } from "../actions/rechercheAction";

const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS_RENDUA:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };
    case LOAD_FAIL_RENDUA:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error,
      };
    case SAVE_SUCCESS_RENDUA:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: [...state.data, action.payload],
        error: null,
      };
    case SAVE_FAIL_RENDUA:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_RENDUA:
      let updatedListe = [];
      state.data.forEach((item) => {
        if (item.rendu_a_requete.requete_id === action.payload.rendu_a_requete.requete_id) {
          item = action.payload;
        }
        updatedListe.push(item);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: updatedListe,
        error: null,
      };
    case UPDATE_FAIL_RENDUA:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case DELETE_SUCCESS_RENDUA:
      const filtered = state.data.filter((item) => item.rendu_a_requete.requete_id !== action.idR);
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filtered,
        error: null,
      };
    case DELETE_FAIL_RENDUA:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: state.data,
        error: action.payload,
      };
    case DELETE_SUCCESS_RENDUA_PC:
      const filtered2 = state.data.filter((item) => {
        return (
          item.rendu_a_requete.requete_groupe_id !== action.idG ||
          item.rendu_a_etape_id !== action.idE
        );
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filtered2,
        error: null,
      };
    case DELETE_FAIL_RENDUA_PC:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: state.data,
        error: action.payload,
      };
    case SAVE_SUCCESS_RENDUA_EMAIL:
      const addEmail = [];
      state.data.forEach((item) => {
        if (item.rendu_a_requete.requete_id === action.payload.email_requete_id)
          item.rendu_a_requete.requete_emails.push(action.payload);

        addEmail.push(item);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: addEmail,
        error: null,
      };
    case SAVE_SUCCESS_RENDUA_RECHERCHE:
      const addRecherche = [];

      state.data.forEach((item) => {
        if (item.rendu_a_requete.requete_id === action.payload.recherche_requete_id)
          item.rendu_a_requete.requete_recherche = action.payload;

        addRecherche.push(item);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: addRecherche,
        error: null,
      };
    default:
      return state;
  }
}

import { utilisateurToAdapted } from "../../adapter/utilisateurAdapter";
import { HttpMethod, initFetch } from "../../utils/fetcher";

export const LOAD_SUCCESS_AUTH = "LOAD_SUCCESS_AUTH";
export const LOAD_FAIL_AUTH = "LOAD_FAIL_AUTH";

export function login(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch("auth/login", HttpMethod.POST, false);

      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(jsonData) })
        .then((res) => res.json())
        .then((res) => {
          res.utilisateur.token = res.authorization.token;
          resolve(res);
          dispatch({
            type: LOAD_SUCCESS_AUTH,
            payload: utilisateurToAdapted(res.utilisateur),
          });
        })
        .catch((err) =>
          dispatch({
            type: LOAD_FAIL_AUTH,
            error: err,
          })
        );
    });
  };
}

import React, { Component } from "react";

import { Select, Card, Table, Input, Space, Button, Col, Modal, Row } from "antd";
import { SearchOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

import CustomSelect from "../CustomFormItem/Select";

import FormCompte from "../FormCompte";

const { Column } = Table;

const { Option, OptGroup } = Select;

class TableCompte extends Component {
  state = {
    societe: null,
    compte: null,
    modalCompte: false,
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Rechercher ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Rechercher
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  openModalCompte = () => {
    this.setState({ compte: null, modalCompte: true });
  };

  closeModalCompte = () => {
    this.setState({ modalCompte: false });
  };

  onSelectCompte = (compte) => {
    this.setState({ compte, modalCompte: true });
  };

  onChangeSociete = (id) => {
    this.setState({ societe: id });
  };

  onDeleteCompte = (compte) => {
    this.props.deleteCompte(compte.compte_id);
  };

  render() {
    const { comptes, isArchive, societes } = this.props;
    const { compte, modalCompte, societe } = this.state;

    return (
      <>
        <div>
          <Modal
            footer={null}
            width="1000px"
            title="Comptes"
            open={modalCompte}
            onCancel={this.closeModalCompte}
          >
            <FormCompte
              compte={compte}
              utilisateur={this.props.utilisateur}
              saveCompte={this.props.saveCompte}
              updateCompte={this.props.updateCompte}
              deleteCompte={this.props.deleteCompte}
              closeModalCompte={this.closeModalCompte}
            />
          </Modal>
          <Card
            // title={title}
            bordered={false}
            className="card-panel"
            headStyle={{ padding: 0 }}
            style={{ padding: 10 }}
          >
            <Row>
              <Col span={24}>
                <CustomSelect
                  label="Société"
                  inputName="societe"
                  formItemName="societe_id"
                  objectValue={societe}
                  onChange={(id) => this.onChangeSociete(id)}
                >
                  <OptGroup label="Société">
                    {societes &&
                      societes.map((item, i) => {
                        return (
                          <Option
                            key={i}
                            value={item.societe_id}
                            label={item.societe_raison_sociale}
                          >
                            <Row>
                              <Col sm={8}>{item.societe_raison_sociale}</Col>
                            </Row>
                          </Option>
                        );
                      })}
                  </OptGroup>
                </CustomSelect>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button
                  type="primary"
                  disabled={isArchive}
                  onClick={this.openModalCompte}
                  style={{ float: "right", marginTop: 16, marginBottom: 16 }}
                >
                  Ajout Compte
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {comptes && (
                  <Table
                    size="small"
                    className="table-comptes"
                    dataSource={comptes}
                    rowKey="compte_id"
                  >
                    <Column
                      title="Compte Général"
                      dataIndex="compte_compte_general"
                      key="compte_compte_general"
                      width="20%"
                      defaultSortOrder="ascend"
                      sorter={(a, b) => a.compte_compte_general - b.compte_compte_general}
                    />
                    <Column
                      title="Compte Auxiliaire"
                      dataIndex="compte_compte_auxiliaire"
                      key="compte_compte_auxiliaire"
                      width="20%"
                      defaultSortOrder="ascend"
                      //  sorter={(a, b) =>
                      //    a.compte_compte_auxiliaire - b.compte_compte_auxiliaire
                      //  }
                    />
                    <Column
                      title="Type de compte"
                      dataIndex="compte_type_compte"
                      key="compte_type_compte"
                      width="10%"
                    />
                    <Column
                      title="Libellé"
                      dataIndex="compte_libelle"
                      key="compte_libelle"
                      width="40%"
                      {...this.getColumnSearchProps("compte_libelle")}
                    />
                    <Column title="Actif" dataIndex="compte_actif" key="compte_actif" width="10%" />
                    <Column
                      title="Afficher"
                      key="compte_id"
                      dataIndex="compte_id"
                      render={(compte_id, compte) => (
                        <Button onClick={() => this.onSelectCompte(compte)}>
                          <EyeOutlined />
                        </Button>
                      )}
                    />
                    <Column
                      title="Supprimer"
                      key="compte_id"
                      dataIndex="compte_id"
                      render={(compte_id, compte) => (
                        <Button onClick={() => this.onDeleteCompte(compte)}>
                          <DeleteOutlined />
                        </Button>
                      )}
                    />
                  </Table>
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </>
    );
  }
}

export default TableCompte;

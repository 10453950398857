import { groupementToAdapted } from "./groupementAdapter";

export function codesCouleursToAdapted(items) {
  return items
    ? items.map((item) => {
        return codeCouleurToAdapted(item);
      })
    : null;
}

export function codeCouleurToAdapted(item) {
  return item
    ? {
        code_couleur_id: item.id,
        code_couleur_hex: item.hex,
        code_couleur_libelle: item.libelle,
        code_couleur_groupement: groupementToAdapted(item.groupement),
      }
    : null;
}

export function adaptedToCodeCouleur(item) {
  return item
    ? {
        hex: item.code_couleur_hex,
        libelle: item.code_couleur_libelle,
        groupement_id: item.code_couleur_groupement_id,
      }
    : null;
}

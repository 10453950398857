import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML } from "draft-js";

const CustomEditor = (props) => {
  const [value, setValue] = useState(false);
  const { formItemKey, formItemLabel, objectValue } = props;

  useEffect(() => {
    objectValue
      ? setValue(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(objectValue))
          )
        )
      : setValue(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.objectValue]);

  const onEditorStateChange = (editorState) => {
    setValue(editorState);
  };

  return (
    <Form.Item
      colon={true}
      key={formItemKey}
      name={formItemKey}
      label={formItemLabel}
      labelCol={{ span: 24 }}
    >
      <Editor
        editorState={value}
        onEditorStateChange={onEditorStateChange}
        toolbarClassName="toolbar-class"
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbar={{
          options: ["inline"],
          inline: {
            options: ["bold", "italic", "underline"],
            bold: { className: "bordered-option-classname" },
            italic: { className: "bordered-option-classname" },
            underline: { className: "bordered-option-classname" },
          },
        }}
      />
    </Form.Item>
  );
};

export default CustomEditor;

import React, { Component } from "react";
import { Button, Checkbox, Layout, Menu, Spin, Tabs } from "antd";
import {
  CalculatorOutlined,
  CloseSquareOutlined,
  ControlOutlined,
  DashboardOutlined,
  DeliveredProcedureOutlined,
  EuroOutlined,
  EyeOutlined,
  FileSearchOutlined,
  FileWordOutlined,
  FormOutlined,
  KeyOutlined,
  LoginOutlined,
  ProfileOutlined,
  ReloadOutlined,
  SendOutlined,
  SettingOutlined,
  ShopOutlined,
  SnippetsOutlined,
  SolutionOutlined,
  SwapOutlined,
  TeamOutlined,
  ToolOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { etapeTitleTree, filterRequetesArchivees } from "../../utils/tools";

import UserInfo from "../UserInfo";
import SearchInput from "../SearchInput";
import MyTreeSidebar from "../TreeSidebar/";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import { initTree } from "../../store/actions/treeAction";

import { Menu as sidebarMenu, subfolder } from "../../utils/constants";

const { Sider } = Layout;

class Sidebar extends Component {
  state = {
    tree: [],
    panes: [],
    requetesArchivees: [],
    collapsed: false,
    cacherTitre: false,
    checkedActive: false,
    todo: false,
  };

  componentDidMount() {
    this.init(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.props !== props) this.init(props);
  }

  init = (props) => {
    const requetesArchivees = filterRequetesArchivees(props.requetes);

    this.setState({ panes: props.panes, requetesArchivees });

    if (props.parametre && props.parametre.parametre_parcours_client) this.initTree(props);
  };

  initTree = (props) => {
    let tree = [];

    if (props.tree !== undefined) {
      props.tree.forEach((i) => {
        i.title = etapeTitleTree(i);

        i.children &&
          i.children.forEach((j) => {
            j.title = etapeTitleTree(j);

            j.children &&
              j.children.forEach((k) => {
                k.title = etapeTitleTree(k);

                k.children &&
                  k.children.forEach((l) => {
                    l.title = etapeTitleTree(l);
                  });
              });
          });
        if (this.state.todo) {
          tree = this.checkTodo(tree, i);
        } else tree.push(i);
      });
    }

    this.setState({ tree, loading: false });
  };

  checkTodo = (newTree, item) => {
    item.children?.forEach((child) => {
      newTree = this.checkTodo(newTree, child);
    });
    if (item.todo && item.children?.some((child) => child.hasOwnProperty("etape_id"))) {
      item.children = item.children?.filter((child) => child.hasOwnProperty("etape_id"));
      newTree.push(item);
    }
    return newTree;
  };

  resetTree = () => {
    this.props.initTree(this.props.utilisateur.utilisateur_id);
  };

  getItem = (label, key, icon, children) => {
    return {
      key,
      icon,
      children,
      label,
    };
  };

  onClickMenu = (e) => {
    const item = sidebarMenu.find((item) => item.key === e.key);
    if (item) this.handleClickMenu(item.activeKey, item.label);
  };

  handleClickMenu = (key, title) => {
    let pane = null;
    if (!this.isKeyExists(this.state.panes, key)) pane = { key, title, content: "" };
    this.props.addPanes(pane, key);
  };

  handleSelectRequeteArchivee = (id) => {
    const requete = this.props.requetes.find((item) => item.requete_id === id);
    this.props.handleSelectRequeteArchivee(requete);
  };

  isKeyExists = (panes, key) => {
    let result = panes.some((pane) => {
      return pane.key === key;
    });
    return result;
  };

  onCollapse = (collapsed) => this.setState({ collapsed });

  onChangeTodo = (value) => {
    this.setState({ todo: value.target.checked }, () => {
      this.initTree(this.props);
    });
  };

  render() {
    const { tree, collapsed } = this.state;
    const { loadingTree, loadingApplication, utilisateur, parametre } = this.props;

    const itemsMenu1 = [
      this.getItem("PROPRIÉTAIRES", "sub1", <KeyOutlined style={{ fontSize: 16 }} />, [
        this.getItem("Fiche", "1"),
        this.getItem("Relevés de gestion", "2"),
        this.getItem("Impression liste", "3"),
      ]),
      this.getItem("LOTS", "sub2", <ShopOutlined style={{ fontSize: 16 }} />, [
        this.getItem("Fiche", "4"),
        this.getItem("Impression liste", "5"),
      ]),
      this.getItem("LOCATAIRES", "sub3", <UserOutlined style={{ fontSize: 16 }} />, [
        this.getItem("Fiche", "6"),
        this.getItem("Impression liste", "7"),
      ]),
      this.getItem("FICHIERS DIVERS", "sub4", <SnippetsOutlined style={{ fontSize: 16 }} />, [
        this.getItem("Immeubles", "8"),
        this.getItem("Agents", "50"),
      ]),
      this.getItem(
        "TRAITEMENTS",
        "sub5",
        <DeliveredProcedureOutlined style={{ fontSize: 16 }} />,
        []
      ),
    ];

    const itemsMenu2 = [
      this.getItem("GESTION COMMERCIALE", "sub1", <SolutionOutlined style={{ fontSize: 16 }} />, [
        this.getItem("Recherche de disponibilités", "9"),
        this.getItem("Propositions", "10"),
        this.getItem("Options", "11"),
        this.getItem("Contrats", "12"),
        this.getItem("Quittancement", "52"),
        this.getItem("Liste des quittances", "57"),
        this.getItem("Requêtes archivées", "49"),
        this.getItem("Réservations propriétaire", "13"),
        this.getItem("Entrées / Sorties", "14"),
        this.getItem("Statut des documents", "15"),
      ]),
      this.getItem("TABLEAU DE BORD", "sub2", <DashboardOutlined style={{ fontSize: 16 }} />, [
        this.getItem("Planning global", "16"),
        this.getItem("Liste des ménages", "56"),
        this.getItem("Historique parcours client", "17"),
        this.getItem("Liste des Timers", "18"),
        this.getItem("Liste des demandes", "55"),
      ]),
      this.getItem("TRAVELS MANAGERS", "sub3", <SendOutlined style={{ fontSize: 16 }} />, []),
      this.getItem("TARIFICATION", "sub4", <EuroOutlined style={{ fontSize: 16 }} />, [
        this.getItem("Références tarifaires", "19"),
        this.getItem("Grille des périodes", "20"),
        this.getItem("Règles internet", "21"),
      ]),
    ];

    const itemsMenu3 = [
      this.getItem("CONSULTATION", "sub1", <EyeOutlined style={{ fontSize: 16 }} />, [
        this.getItem("Interrogation de compte", "22"),
        this.getItem("Pointe", "23"),
      ]),
      this.getItem("SAISIES COMPTABLES", "sub2", <FormOutlined style={{ fontSize: 16 }} />, [
        this.getItem("Encaissement locataires", "24"),
        this.getItem("Validation de bordereau", "25"),
        this.getItem("Prestations Supplémentaires", "26"),
        this.getItem("Saisie Opérations Diverses", "27"),
        this.getItem("Annulation encaissement clients", "28"),
        this.getItem("Journaux", "29"),
        this.getItem("Plan comptable", "30"),
      ]),
      this.getItem("EDITIONS", "sub3", <FileSearchOutlined style={{ fontSize: 16 }} />, [
        this.getItem("Balance", "31"),
        this.getItem("Grand livre", "32"),
      ]),
      this.getItem("GESTION FOURNISSEUR", "sub4", <UserOutlined style={{ fontSize: 16 }} />, []),
      this.getItem("OUTILS", "sub5", <ToolOutlined style={{ fontSize: 16 }} />, [
        this.getItem("Recherche / Modification écritures", "33"),
        this.getItem("Lettrage de comptes", "54"),
        this.getItem("Écarts compta / encaiss.", "34"),
        this.getItem("Écarts compta / redd.", "51"),
      ]),
      this.getItem("RAPPRO. BANCAIRE", "sub6", <SwapOutlined style={{ fontSize: 16 }} />, [
        this.getItem("Rapprochement", "35"),
      ]),
      this.getItem(
        "CLOTURE EXERCICE",
        "sub7",
        <CloseSquareOutlined style={{ fontSize: 16 }} />,
        []
      ),
    ];

    const itemsMenu4 = [
      this.getItem("ACCES", "sub1", <LoginOutlined style={{ fontSize: 16 }} />, [
        this.getItem("Gestion dossier client", "36"),
        this.getItem("Mot de passe", "37"),
      ]),
      this.getItem("UTLISATEURS", "sub2", <TeamOutlined style={{ fontSize: 16 }} />, [
        this.getItem("Gestion utilisateur", "38"),
        this.getItem("Gestion des droits", "39"),
      ]),
      this.getItem("PARAMETRAGES", "sub3", <ControlOutlined style={{ fontSize: 16 }} />, [
        this.getItem("Société", "40"),
        this.getItem("Établissement", "41"),
        this.getItem("Imputations", "42"),
        this.getItem("Types de paiements", "53"),
        this.getItem("Templates", "43"),
        this.getItem("Champs personnalisés", "44"),
        this.getItem("Étapes", "45"),
        this.getItem("Groupes", "46"),
        this.getItem("Rubriques", "47"),
        // this.getItem("Migration", "48"),
      ]),
      this.getItem("GESTION DE LA GED", "sub4", <FileWordOutlined style={{ fontSize: 16 }} />, []),
    ];

    const pathLogo = `${subfolder}/aster-white.png`;
    const pathEtoile = `${subfolder}/aster-star.png`;

    return (
      <Sider collapsible width={250} collapsed={collapsed} onCollapse={this.onCollapse}>
        <Spin size="large" spinning={loadingTree} tip="Chargement arbre...">
          {!collapsed && <img className="ast-logo" src={pathLogo} alt="" width={250} />}
          {collapsed && <img className="ast-logo" src={pathEtoile} alt="" width={80} />}

          <Spin size="large" spinning={loadingApplication} tip="Chargement app...">
            <UserInfo utilisateur={utilisateur} collapsed={collapsed} />
            {!collapsed && (
              <SearchInput
                utilisateur={utilisateur}
                addPanesPersonne={this.props.addPanesPersonne}
                addPanesLot={this.props.addPanesLot}
                placeholder="recherche..."
                style={{ width: 250 }}
              />
            )}
            {parametre && (
              <Tabs
                className="tab-or"
                defaultActiveKey={parametre?.parametre_parcours_client ? 5 : 1}
                items={[
                  {
                    label: (
                      <UserOutlined style={{ fontSize: 18, color: "#F4C181", marginRight: 0 }} />
                    ),
                    key: 1,
                    children: (
                      <Scrollbars autoHide style={{ height: 700, width: "100%" }}>
                        <Menu
                          className="menu-or"
                          mode="inline"
                          theme="dark"
                          onClick={(e) => this.onClickMenu(e)}
                          items={itemsMenu1}
                        />
                      </Scrollbars>
                    ),
                  },
                  {
                    label: (
                      <ProfileOutlined style={{ fontSize: 16, color: "#F4C181", marginRight: 0 }} />
                    ),
                    key: 2,
                    children: (
                      <Scrollbars autoHide style={{ height: 700, width: "100%" }}>
                        <Menu
                          className="menu-or"
                          mode="inline"
                          theme="dark"
                          onClick={(e) => this.onClickMenu(e)}
                          items={itemsMenu2}
                        />
                      </Scrollbars>
                    ),
                  },
                  {
                    label: (
                      <CalculatorOutlined
                        style={{ fontSize: 16, color: "#F4C181", marginRight: 0 }}
                      />
                    ),
                    key: 3,
                    children: (
                      <Scrollbars autoHide style={{ height: 700, width: "100%" }}>
                        <Menu
                          className="menu-or"
                          mode="inline"
                          theme="dark"
                          onClick={(e) => this.onClickMenu(e)}
                          items={itemsMenu3}
                        />
                      </Scrollbars>
                    ),
                  },
                  {
                    label: (
                      <SettingOutlined style={{ fontSize: 16, color: "#F4C181", marginRight: 0 }} />
                    ),
                    key: 4,
                    children: (
                      <Scrollbars autoHide style={{ height: 700, width: "100%" }}>
                        <Menu
                          className="menu-or"
                          mode="inline"
                          theme="dark"
                          onClick={(e) => this.onClickMenu(e)}
                          items={itemsMenu4}
                        />
                      </Scrollbars>
                    ),
                  },
                  parametre &&
                    parametre.parametre_parcours_client && {
                      label: (
                        <UnorderedListOutlined
                          style={{ fontSize: 16, color: "#F4C181", marginRight: 0 }}
                        />
                      ),
                      key: 5,
                      children: (
                        <>
                          {!collapsed && (
                            <>
                              <div>
                                <Checkbox
                                  style={{ marginLeft: 5, color: "white" }}
                                  onChange={(value) => this.onChangeTodo(value)}
                                >
                                  Étapes Todo
                                </Checkbox>
                                <Button style={{ float: "right" }} onClick={this.resetTree}>
                                  <ReloadOutlined />
                                </Button>
                              </div>
                              <div>
                                <Scrollbars autoHide style={{ height: 650, width: "100%" }}>
                                  <div
                                    style={{
                                      padding: 1,
                                      background: "#fff",
                                      height: 600,
                                      display: "grid",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    {tree && tree.length > 0 && (
                                      <MyTreeSidebar
                                        treeEtapes={tree}
                                        handleSelectRequete={this.props.handleSelectRequete}
                                        addPanesRequete={this.props.addPanesRequete}
                                      />
                                    )}
                                  </div>
                                </Scrollbars>
                              </div>
                            </>
                          )}
                        </>
                      ),
                    },
                ]}
                centered
              />
            )}
          </Spin>
        </Spin>
      </Sider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    initTree: (utilisateur_id) => dispatch(initTree(utilisateur_id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    requetes: state.requetes.data,
    parametre: state.parametre.data,
    tree: state.tree.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

import { champsPersosToAdapted } from "./champPersoAdapter";
import { equipementToAdapted } from "./equipementAdapter";

export function champsPersosGroupesToAdapted(items) {
  return items
    ? items.map((item) => {
        return champPersoGroupeToAdapted(item);
      })
    : null;
}

export function champPersoGroupeToAdapted(item) {
  return item
    ? {
        champ_perso_groupe_id: item.id,
        champ_perso_groupe_libelle: item.libelle,
        champ_perso_groupe_type: item.type,
        champ_perso_groupe_ordre: item.ordre,
        champ_perso: champsPersosToAdapted(item.champ_perso),
        champ_perso_groupe_equipement_id: equipementToAdapted(item.equipement),
      }
    : null;
}

export function adaptedToChampsPersosGroupes(items) {
  return items?.data
    ? items?.data.map((item) => {
        return adaptedToChampPersoGroupe(item);
      })
    : null;
}

export function adaptedToChampPersoGroupe(item) {
  return item
    ? {
        id: item.champ_perso_groupe_id,
        libelle: item.champ_perso_groupe_libelle,
        type: item.champ_perso_groupe_type,
        ordre: item.champ_perso_groupe_ordre,
        groupement_id: item.champ_perso_groupe_groupement_id,
        equipement_id: item.champ_perso_groupe_equipement_id,
      }
    : null;
}

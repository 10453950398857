import React, { Component } from "react";
import { connect } from "react-redux";

import { Spin, Modal, Row, Button, Table, Popconfirm } from "antd";

import { CloseOutlined } from "@ant-design/icons";

import CustomTable from "../../components/CustomTable/CustomTable";

import { updateImputation } from "../../store/actions/rubriqueAction";

const { Column } = Table;

class Imputations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      modalRubrique: false,
      rubriques: [],
      selectedRows: [],
      rubriquesImputees: [],
      imputationSelected: undefined,
    };
  }

  componentDidMount() {
    this.init(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.init(props);
  }

  init = (props) => {
    const rubriques = [];
    const rubriquesImputees = [];

    props.rubriques.data.forEach((i) => {
      if (!i.rubrique_ordre) rubriques.push(i);
      else rubriquesImputees.push(i);
    });

    this.setState({ rubriques, rubriquesImputees });
  };

  closeModalRubrique = () => {
    this.setState({ modalRubrique: false });
  };

  handleSubmitAddRubriques = () => {
    let maxCurrOrdre = 0;
    if (this.state.rubriquesImputees.length > 0) {
      maxCurrOrdre = Math.max.apply(
        Math,
        this.state.rubriquesImputees.map(function (o) {
          return o.rubrique_ordre;
        })
      );
    }

    this.state.selectedRows.forEach((rubrique, i) => {
      this.props.updateImputation({ rubrique_ordre: maxCurrOrdre + i + 1 }, rubrique.rubrique_id);
    });

    this.closeModalRubrique();
  };

  openModalRubrique = () => {
    this.setState({ selectedRows: [], modalRubrique: true });
  };

  closeModalRubrique = () => {
    this.setState({ selectedRows: [], modalRubrique: false });
  };

  handle = (e) => {
    e.stopPropagation();
  };

  handleImputationDelete = (rubrique, e) => {
    e.stopPropagation();
    this.props.updateImputation({ rubrique_ordre: null }, rubrique.rubrique_id).then((res) => {
      this.state.rubriquesImputees.forEach((rubrique, i) => {
        if (rubrique.rubrique_ordre)
          this.props.updateImputation({ rubrique_ordre: i + 1 }, rubrique.rubrique_id);
      });
    });
  };

  handleImputationRowClick = (e) => {
    const imputation = this.getImputationSelected(this.props.imputations, e.rubrique_id);
    this.setState({ imputationSelected: imputation });
  };

  getImputationSelected = (imputations, id) => {
    const filtered = imputations.filter((item) => item !== undefined && item.rubrique_id === id);
    return imputations && filtered.length > 0 && filtered[0];
  };

  handleCustomImputationOrderChange = (data) => {
    data.forEach((rubrique, i) => {
      this.props.updateImputation({ rubrique_ordre: i + 1 }, rubrique.rubrique_id);
    });
  };

  sortByOrdre = (a, b) => {
    if (a.rubrique_ordre < b.rubrique_ordre) {
      return -1;
    }
    if (a.rubrique_ordre > b.rubrique_ordre) {
      return 1;
    }
    return 0;
  };

  render() {
    const {
      modalRubrique,
      loading,
      rubriques,
      rubriquesImputees,
      // selectedRows,
      imputationSelected,
    } = this.state;

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRows });
      },
    };

    const imputationsColumns = [
      {
        className: "drag-visible",
        dataIndex: "rubrique_ordre",
        title: "Ordre",
        width: 56,
      },
      {
        className: "drag-visible",
        dataIndex: "rubrique_code",
        title: "Code",
        key: "code",
        width: 100,
      },
      {
        className: "drag-visible",
        dataIndex: "rubrique_libelle",
        title: "Libellé",
        key: "libelle",
      },
      {
        className: "drag-visible",
        dataIndex: "rubrique_type",
        title: "Type",
        key: "type",
      },
      {
        className: "drag-visible",
        dataIndex: "rubrique_signe",
        title: "Signe",
        key: "signe",
      },
      {
        // Clickable area
        width: 96,
      },
      {
        dataIndex: "rubrique_id",
        title: "Supprimer",
        render: (text, rubrique) => (
          <Popconfirm
            placement="topRight"
            title={"Êtes vous sûr de supprimer " + rubrique.rubrique_libelle + " des imputations ?"}
            okText="Supprimer"
            onClick={this.handle}
            onConfirm={(e) => this.handleImputationDelete(rubrique, e)}
          >
            <Button onClick={this.handle} icon={<CloseOutlined style={{ color: "red" }} />} />
          </Popconfirm>
        ),
      },
    ];

    return (
      <Spin spinning={loading} size="large" tip="Chargement...">
        <Modal
          footer={null}
          width="600px"
          title="Rubriques"
          open={modalRubrique}
          onCancel={this.closeModalRubrique}
        >
          <div>
            <Row>
              <Table
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                rowKey="rubrique_code"
                dataSource={rubriques}
              >
                <Column title="Code" dataIndex="rubrique_code" key="code" />
                <Column title="Libelle" dataIndex="rubrique_libelle" key="libelle" />
              </Table>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Button
                type="primary"
                onClick={this.handleSubmitAddRubriques}
                style={{ float: "right" }}
              >
                Valider
              </Button>
            </Row>
          </div>
        </Modal>
        <div style={{ padding: 10 }}>
          <Row>
            <Button type="primary" onClick={this.openModalRubrique}>
              Ajout Rubrique
            </Button>
          </Row>
          <Row>
            <CustomTable
              columns={imputationsColumns}
              dataSource={rubriquesImputees.sort(this.sortByOrdre)}
              draggable
              onClickRow={this.handleImputationRowClick}
              rowPointer
              selectedRow={imputationSelected}
              sortChange={this.handleCustomImputationOrderChange}
              // scroll={defaultScroll}
            />
          </Row>
        </div>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateImputation: (jsonData, id) => dispatch(updateImputation(jsonData, id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    rubriques: state.rubriques,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Imputations);

import React, { Component } from "react";

import { toast } from "react-toastify";

import { PlusOutlined } from "@ant-design/icons";

import { Spin, Upload, Modal, Row, Col, Card } from "antd";

import Photo from "./Photo";

import HTML5Backend from "react-dnd-html5-backend";

import { DragDropContext } from "react-dnd";
import Constants from "../../utils/constants";

const update = require("immutability-helper");

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class FormPhoto extends Component {
  state = {
    lot: null,
    selectedImage: null,
    fileList: [],
    loading: true,
    previewVisible: false,
    previewTitle: "",
    previewImage: "",
    nextOrderAvailable: 0,
    forbidDrag: false,
  };

  componentDidMount() {
    this.init(this.props.photos, this.props.lot);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.init(props.photos, props.lot);
  }

  init = (photos, lot) => {
    if (photos === null || lot === null) return;

    const fileList = photos;
    fileList.sort((a, b) => a.photo_ordre - b.photo_ordre);

    const nextOrderAvailable = fileList.length + 1;

    this.setState({
      fileList,
      lot,
      nextOrderAvailable,
      loading: false,
    });
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) file.preview = await getBase64(file.originFileObj);

    this.setState({
      selectedImage: file,
      previewImage:
        `${Constants.mode}://${Constants.domain}:${Constants.port}/api/photos/display/${file.uid}` ||
        file.preview,
      // previewImage: `${constants.directory}${file.url}` || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  handleUpdate = (fileList, id) => {
    this.props
      .updatePhotos(
        {
          fileList,
        },
        id
      )
      .then((res) => this.setState({ forbidDrag: false }));
  };

  handleRemove = (photo) => {
    this.props.deletePhoto(photo.photo_id);
  };

  handleEdit = (photo) => {
    let fileList = [];
    photo.photo_edition = !photo.photo_edition;
    fileList.push(photo);
    this.props.updatePhotos(
      {
        fileList,
      },
      photo.photo_id
    );
  };

  refreshOrders = (file) => {
    let photos = this.state.fileList.filter((photo) => photo.photo_ordre > file.photo_ordre);
    photos = photos.filter((i) => i.photo_id !== file.photo_id);

    photos.forEach((item) => {
      item.photo_ordre = item.photo_ordre - 1;
    });

    this.handleUpdate(photos, null);
  };

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      toast.error("Image .jpg / .png uniquement !");
    }

    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      toast.error("Image supérieure à 10Mo !");
    }

    return isJpgOrPng && isLt10M;
  };

  moveCard = (dragIndex, hoverIndex) => {
    const { fileList } = this.state;
    const dragCard = fileList[dragIndex];

    this.setState(
      update(this.state, {
        fileList: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        },
      })
    );
  };

  handleValidate = (id) => {
    this.setState({ forbidDrag: true });

    let fileList = this.state.fileList;

    fileList.forEach((item, i) => {
      item.photo_ordre = i + 1;
    });

    this.handleUpdate(fileList, id);
  };

  onClickPreview = (photo) => this.handlePreview(photo);

  onClickRemove = (photo) => this.handleRemove(photo);

  onClickEdit = (photo) => this.handleEdit(photo);

  render() {
    const {
      lot,
      fileList,
      previewVisible,
      previewTitle,
      previewImage,
      loading,
      nextOrderAvailable,
      forbidDrag,
    } = this.state;

    const { isArchive } = this.props;

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">Ajouter</div>
      </div>
    );

    const props = {
      name: "file",
      accept: ".jpg, .png",
      listType: "picture-card",
      multiple: true,
      showUploadList: false,
      beforeUpload: this.beforeUpload,
      customRequest: (componentsData) => {
        this.setState({ nextOrderAvailable: nextOrderAvailable + 1 }, () =>
          this.props.savePhoto(componentsData, nextOrderAvailable, lot.lot_id)
        );
      },
    };

    return (
      <div style={{ padding: 10 }}>
        <Spin spinning={loading} size="large" tip="Chargement...">
          <Card className="appt-card">
            <Row>
              <div className={"autre"}>
                {fileList.map((photo, i) => (
                  <Photo
                    isArchive={isArchive}
                    photo={photo}
                    index={i}
                    key={photo.photo_id}
                    id={photo.photo_id}
                    text={photo.photo_libelle}
                    forbidDrag={forbidDrag}
                    moveCard={this.moveCard}
                    handleValidate={this.handleValidate}
                    onClickPreview={this.onClickPreview}
                    onClickRemove={this.onClickRemove}
                    onClickEdit={this.onClickEdit}
                  />
                ))}
              </div>
            </Row>
            <Row>
              <Col md={4}>
                <Upload disabled={isArchive} {...props}>
                  {uploadButton}
                </Upload>
              </Col>
            </Row>
            <Modal
              title={previewTitle}
              open={previewVisible}
              onCancel={this.handleCancel}
              footer={null}
              width="80%"
            >
              <Row>
                <img
                  alt="example"
                  src={previewImage}
                  style={{ marginBottom: "5px", width: "100%" }}
                />
              </Row>
            </Modal>
          </Card>
        </Spin>
      </div>
    );
  }
}

export default DragDropContext(HTML5Backend)(FormPhoto);

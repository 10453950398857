const LOAD_SUCCESS_REQUETES_LOTS = "LOAD_SUCCESS_REQUETES_LOTS";
const LOAD_FAIL_REQUETES_LOTS = "LOAD_FAIL_REQUETES_LOTS";
const SAVE_SUCCESS_REQUETES_LOTS = "SAVE_SUCCESS_REQUETES_LOTS";
const SAVE_FAIL_REQUETES_LOTS = "SAVE_FAIL_REQUETES_LOTS";
const UPDATE_SUCCESS_REQUETES_LOTS = "UPDATE_SUCCESS_REQUETES_LOTS";
const UPDATE_SUCCESS_ALL_REQUETES_LOTS = "UPDATE_SUCCESS_ALL_REQUETES_LOTS";
const UPDATE_FAIL_REQUETES_LOTS = "UPDATE_FAIL_REQUETES_LOTS";
const DELETE_SUCCESS_REQUETES_LOTS = "DELETE_SUCCESS_REQUETES_LOTS";
const DELETE_FAIL_REQUETES_LOTS = "DELETE_FAIL_REQUETES_LOTS";
const DELETE_SUCCESS_ALL_REQUETES_LOTS = "DELETE_SUCCESS_ALL_REQUETES_LOTS";
const DELETE_FAIL_ALL_REQUETES_LOTS = "DELETE_FAIL_ALL_REQUETES_LOTS";

const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS_REQUETES_LOTS:
      const liste = action.payload;
      liste.forEach((item, i) => {
        item.key = i;
        return item;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: liste,
        error: null,
      };
    case LOAD_FAIL_REQUETES_LOTS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error,
      };
    case SAVE_SUCCESS_REQUETES_LOTS:
      const item = action.payload;
      item.key = state.data.length;
      return {
        ...state,
        loading: false,
        loaded: true,
        data: [...state.data, item],
        error: null,
      };
    case SAVE_FAIL_REQUETES_LOTS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_REQUETES_LOTS:
      let updatedListe = [];
      state.data.forEach((item) => {
        if (
          item.requete_lot_requete.requete_id ===
            action.payload.requete_lot_requete.requete_id &&
          item.requete_lot_lot.lot_id ===
            action.payload.requete_lot_lot.lot_id
        ) {
          const key = item.key;
          item = action.payload;
          item.key = key;
        }
        updatedListe.push(item);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: updatedListe,
        error: null,
      };
    case UPDATE_SUCCESS_ALL_REQUETES_LOTS:
      const listeAll = action.payload;
      listeAll.forEach((item, i) => {
        item.key = i;
        return item;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: listeAll,
        error: null,
      };
    case UPDATE_FAIL_REQUETES_LOTS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case DELETE_SUCCESS_REQUETES_LOTS:
      const filtered = state.data.filter(
        (item) =>
          item.requete_lot_type !== action.typeRL ||
          item.requete_lot_lot.lot_id !== action.idL
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filtered,
        error: null,
      };
    case DELETE_FAIL_REQUETES_LOTS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: state.data,
        error: action.payload,
      };
    case DELETE_SUCCESS_ALL_REQUETES_LOTS:
      const filteredAll = state.data.filter(
        (item) =>
          item.requete_lot_type !== action.typeRL ||
          item.requete_lot_requete.requete_id !== action.idR
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filteredAll,
        error: null,
      };
    case DELETE_FAIL_ALL_REQUETES_LOTS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: state.data,
        error: action.payload,
      };
    default:
      return state;
  }
}

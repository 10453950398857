import { compteToAdapted } from "./compteAdapter";
import { rubriquesPourcentagesToAdapted } from "./rubriquePourcentageAdapter";

export function rubriquesToAdapted(items) {
  return items
    ? items.map((item) => {
        return rubriqueToAdapted(item);
      })
    : null;
}

export function rubriqueToAdapted(item) {
  return item
    ? {
        rubrique_id: item.id,
        rubrique_code: item.code,
        rubrique_libelle: item.libelle,
        rubrique_type: item.type,
        rubrique_signe: item.signe,
        rubrique_quittance_ou_reddition: item.quittance_ou_reddition,
        rubrique_optionnelle: item.optionnelle,
        rubrique_optionnelle_montant: item.optionnelle_montant,
        rubrique_optionnelle_defaut: item.optionnelle_defaut,
        rubrique_montant_fixe: item.montant_fixe,
        rubrique_montant_defaut: item.montant_defaut,
        rubrique_tva: item.tva,
        rubrique_taxe_de_sejour: item.taxe_de_sejour,
        rubrique_ordre: item.ordre,
        rubrique_pourcentage: item.pourcentage,
        rubrique_permanente: item.permanente,
        rubrique_liees: rubriquesPourcentagesToAdapted(item.liees),
        rubrique_contrat: item.contrat,
        rubrique_pourcentage_acompte: item.pourcentage_acompte,
        rubrique_honoraires: item.honoraires,
        rubrique_compte: compteToAdapted(item.compte),
      }
    : null;
}

export function simpleRubriqueToAdapted(item) {
  return item
    ? {
        index: item.id,
        rubrique_id: item.id,
        rubrique_code: item.code,
        rubrique_libelle: item.libelle,
        rubrique_type: item.type,
        rubrique_signe: item.signe,
        rubrique_ordre: item.ordre,
        rubrique_tva: item.tva,
        rubrique_taxe_de_sejour: item.taxe_de_sejour,
        rubrique_honoraires: item.honoraires,
        rubrique_compte_id: item.compte_id,
        rubrique_etablissement_id: item.etablissement_id,
      }
    : null;
}

export function adaptedToRubrique(item) {
  return item
    ? {
        id: item.rubrique_id,
        code: item.rubrique_code,
        libelle: item.rubrique_libelle,
        type: item.rubrique_type,
        signe: item.rubrique_signe,
        quittance_ou_reddition: item.rubrique_quittance_ou_reddition,
        optionnelle: item.rubrique_optionnelle,
        optionnelle_montant: item.rubrique_optionnelle_montant,
        optionnelle_defaut: item.rubrique_optionnelle_defaut,
        montant_fixe: item.rubrique_montant_fixe,
        montant_defaut: item.rubrique_montant_defaut,
        tva: item.rubrique_tva,
        taxe_de_sejour: item.rubrique_taxe_de_sejour,
        ordre: item.rubrique_ordre,
        permanente: item.rubrique_permanente,
        contrat: item.rubrique_contrat,
        pourcentage_acompte: item.rubrique_pourcentage_acompte,
        honoraires: item.rubrique_honoraires,
        compte_id: item.rubrique_compte_id,
        etablissement_id: item.rubrique_etablissement_id,
      }
    : null;
}

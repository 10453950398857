import React, { useState, useEffect } from "react";

import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";

import { Button, Col, Divider, Row, Select, Form } from "antd";

const { Option, OptGroup } = Select;

function SelectCRUD({
  isArchive,
  row,
  data,
  groupe,
  addValue,
  editValue,
  deleteValue,
  handleMouseOver,
}) {
  const [value, setValue] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    groupe === "quartier" || groupe === "métro" ? setValue("champ_perso") : setValue("provenance");

    if (groupe === "quartier") {
      setName("lot_adresse_quartier");
    } else if (groupe === "métro") {
      setName("lot_adresse_metro");
    } else {
      setName("requete_provenance_id");
    }
  }, [groupe]);

  // const status = groupe === "provenance" ? "warning" : "";

  return (
    <div>
      <Form.Item
        name={name}
        // validateStatus={status}
        // rules={[
        //   {
        //     required: value === "provenance" ? true : false,
        //     message: "Provenance obligatoire",
        //   },
        // ]}
      >
        <Select
          showSearch
          optionFilterProp="label"
          disabled={isArchive}
          // size="small"
          mode={value !== "provenance" && "multiple"}
          maxTagCount={3}
          placeholder={groupe.toUpperCase()}
          optionLabelProp="label"
          style={{ width: "100%" }}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: "4px 0" }} />
              <div style={{ padding: "8px", cursor: "pointer" }} onClick={() => addValue(groupe)}>
                <PlusOutlined /> {`Ajouter ${groupe}`}
              </div>
            </div>
          )}
        >
          <OptGroup label="Type">
            {data &&
              data.map((item, i) => {
                return (
                  <Option key={i} value={item[value + "_id"]} label={item[value + "_libelle"]}>
                    <div onMouseOver={() => handleMouseOver(i)}>
                      <Row>
                        <Col span={18}>{item[value + "_libelle"]}</Col>
                        {row === i && (
                          <>
                            <Col span={3}>
                              <Button onClick={(e) => editValue(e, item, groupe)}>
                                <EditOutlined />
                              </Button>
                            </Col>
                            <Col span={3}>
                              <Button onClick={(e) => deleteValue(e, item, groupe)}>
                                <DeleteOutlined />
                              </Button>
                            </Col>
                          </>
                        )}
                      </Row>
                    </div>
                  </Option>
                );
              })}
          </OptGroup>
        </Select>
      </Form.Item>
    </div>
  );
}

export default SelectCRUD;

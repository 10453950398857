import { HttpMethod, initFetch } from "../../utils/fetcher";
import {
  adaptedToQuittanceLigne,
  quittanceLigneToAdapted,
} from "../../adapter/quittanceLigneAdapter";

export const SAVE_SUCCESS_QUITTANCES_LIGNES = "SAVE_SUCCESS_QUITTANCES_LIGNES";
export const SAVE_FAIL_QUITTANCES_LIGNES = "SAVE_FAIL_QUITTANCES_LIGNES";
export const UPDATE_SUCCESS_QUITTANCES_LIGNES = "UPDATE_SUCCESS_QUITTANCES_LIGNES";
export const UPDATE_FAIL_QUITTANCES_LIGNES = "UPDATE_FAIL_QUITTANCES_LIGNES";
export const DELETE_SUCCESS_QUITTANCES_LIGNES = "DELETE_SUCCESS_QUITTANCES_LIGNES";
export const DELETE_FAIL_QUITTANCES_LIGNES = "DELETE_FAIL_QUITTANCES_LIGNES";

export function saveQuittanceLigne(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch("quittancesLignes", HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToQuittanceLigne(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(quittanceLigneToAdapted(res.quittance_ligne));
          dispatch({
            type: SAVE_SUCCESS_QUITTANCES_LIGNES,
            payload: quittanceLigneToAdapted(res.quittance_ligne),
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_QUITTANCES_LIGNES,
            error: err,
          })
        );
    });
  };
}

export function updateQuittanceLigne(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`quittancesLignes/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToQuittanceLigne(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(quittanceLigneToAdapted(res.quittance_ligne));
          dispatch({
            type: UPDATE_SUCCESS_QUITTANCES_LIGNES,
            payload: quittanceLigneToAdapted(res.quittance_ligne),
            id: id,
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_QUITTANCES_LIGNES,
            error: err,
          })
        );
    });
  };
}

export function deleteQuittanceLigne(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`quittancesLignes/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.quittance_ligne);
          dispatch({
            type: DELETE_SUCCESS_QUITTANCES_LIGNES,
            payload: quittanceLigneToAdapted(res.quittance_ligne),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_QUITTANCES_LIGNES,
            error: err,
          });
        });
    });
  };
}

import { HttpMethod, initFetch } from "../../utils/fetcher";
import { adaptedToRendu, rendusToAdapted, renduToAdapted } from "../../adapter/renduAdapter";

export const LOAD_SUCCESS_RENDUA = "LOAD_SUCCESS_RENDUA";
export const LOAD_FAIL_RENDUA = "LOAD_FAIL_RENDUA";
export const SAVE_SUCCESS_RENDUA = "SAVE_SUCCESS_RENDUA";
export const SAVE_FAIL_RENDUA = "SAVE_FAIL_RENDUA";
export const SAVE_UPDATE_SUCCESS_RENDUA = "SAVE_UPDATE_SUCCESS_RENDUA";
export const SAVE_UPDATE_FAIL_RENDUA = "SAVE_UPDATE_FAIL_RENDUA";
export const UPDATE_SUCCESS_RENDUA = "UPDATE_SUCCESS_RENDUA";
export const UPDATE_FAIL_RENDUA = "UPDATE_FAIL_RENDUA";
export const UPDATES_SUCCESS_RENDUA = "UPDATES_SUCCESS_RENDUA";
export const UPDATES_FAIL_RENDUA = "UPDATES_FAIL_RENDUA";
export const DELETE_SUCCESS_RENDUA = "DELETE_SUCCESS_RENDUA";
export const DELETE_FAIL_RENDUA = "DELETE_FAIL_RENDUA";
export const DELETE_SUCCESS_RENDUA_PC = "DELETE_SUCCESS_RENDUA_PC";
export const DELETE_FAIL_RENDUA_PC = "DELETE_FAIL_RENDUA_PC";

export function loadRenduAs() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`renduAs`, HttpMethod.GET);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.rendu_a);
          dispatch({
            type: LOAD_SUCCESS_RENDUA,
            payload: rendusToAdapted(res.rendu_a),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_RENDUA,
            error: err,
          });
        });
    });
  };
}

export function saveRenduA(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`renduAs`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToRendu(jsonData)) })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.rendu_a);
          dispatch({
            type: SAVE_SUCCESS_RENDUA,
            payload: renduToAdapted(res.rendu_a),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: SAVE_FAIL_RENDUA,
            error: err,
          });
        });
    });
  };
}

export function updateRenduA(jsonData, idRequete) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`renduAs/${idRequete}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToRendu(jsonData)) })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.rendu_a);
          dispatch({
            type: UPDATE_SUCCESS_RENDUA,
            payload: renduToAdapted(res.rendu_a),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: UPDATE_FAIL_RENDUA,
            error: err,
          });
        });
    });
  };
}

export function deleteRenduA(idRequete, idEtape) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`renduAs/${idRequete}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.rendu_a);
          dispatch({
            type: DELETE_SUCCESS_RENDUA,
            payload: renduToAdapted(res.rendu_a),
            idR: idRequete,
            idE: idEtape,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_RENDUA,
            payload: err,
          });
        });
    });
  };
}

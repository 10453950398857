import { HttpMethod, initFetch } from "../../utils/fetcher";

import {
  UPDATE_SUCCESS_PIECES_FROM_CONTENUS,
  UPDATE_FAIL_PIECES_FROM_CONTENUS,
} from "./pieceAction";
import { adaptedToPieceContenu, pieceContenusToAdapted } from "../../adapter/pieceContenuAdapter";

export function savePiecesContenus(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`piecesContenus`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToPieceContenu(jsonData)) })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.piece_contenu);
          dispatch({
            type: UPDATE_SUCCESS_PIECES_FROM_CONTENUS,
            payload: pieceContenusToAdapted(res.piece_contenu),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: UPDATE_FAIL_PIECES_FROM_CONTENUS,
            payload: err,
          });
        });
    });
  };
}

export function updatePiecesContenus(jsonData, idPiece, idChampPerso) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(
        `piecesContenus/${idPiece}/${idChampPerso}`,
        HttpMethod.PUT
      );
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToPieceContenu(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.piece_contenu);
          dispatch({
            type: UPDATE_SUCCESS_PIECES_FROM_CONTENUS,
            payload: pieceContenusToAdapted(res.piece_contenu),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: UPDATE_FAIL_PIECES_FROM_CONTENUS,
            payload: err,
          });
        });
    });
  };
}

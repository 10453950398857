export function recherchesToAdapted(items) {
  return items
    ? items.map((item) => {
        return rechercheToAdapted(item);
      })
    : null;
}

export function rechercheToAdapted(item) {
  //Resource
  return item
    ? {
        recherche_id: item.id,
        recherche_alur: item.alur,
        recherche_court_terme: item.court_terme,
        recherche_long_terme: item.long_terme,
        recherche_date_debut: item.date_debut,
        recherche_date_fin: item.date_fin,
        recherche_prix_min: item.prix_min,
        recherche_prix_max: item.prix_max,
        recherche_couchages: item.couchages,
        recherche_surfaces: item.surfaces,
        recherche_type_lot: item.type_lot,
        recherche_requete_id: item.requete_id,
        recherche_type_contrat: item.type_contrat,
        recherche_equipement: item.equipement,
        recherche_champ_perso: item.champ_perso,
      }
    : null;
}

export function adaptedToRecherche(item) {
  //Model
  return item
    ? {
        alur: item.recherche_alur,
        court_terme: item.recherche_court_terme,
        long_terme: item.recherche_long_terme,
        date_debut: item.recherche_date_debut,
        date_fin: item.recherche_date_fin,
        prix_min: item.recherche_prix_min,
        prix_max: item.recherche_prix_max,
        couchages: item.recherche_couchages,
        surfaces: item.recherche_surfaces,
        type_lot: item.recherche_type_lot,
        type_contrat: item.recherche_type_contrat,
        requete_id: item.recherche_requete_id,
        equipement: item.recherche_equipement,
      }
    : null;
}

import React from "react";
import dayjs from "dayjs"

export const columns = (locations, getColumnSearchProps, getColumnSearchPropsDate) => [
  {
    title: "#",
    dataIndex: "historique_id",
    key: "historique_id",
    render: () => <></>,
    sorter: (a, b) => parseInt(a.historique_id) - parseInt(b.historique_id),
    width: "5%",
  },
  {
    title: "Requete",
    dataIndex: "historique_requete",
    key: "historique_requete",
    ...getColumnSearchProps("historique_requete"),
    render: (requete) =>
      `${requete.requete_locataire?.locataire_personne.personne_nom} ${requete.requete_locataire?.locataire_personne.personne_prenom} (${requete.requete_id})`,
    width: "20%",
  },
  {
    title: "Etape A",
    dataIndex: "historique_etape_a",
    key: "historique_etape_a",
    ...getColumnSearchProps("historique_etape_a.etape_libelle"),
    render: (etapeA) => etapeA.etape_libelle,
    width: "15%",
  },
  {
    title: "Etape B",
    dataIndex: "historique_etape_b",
    key: "historique_etape_b",
    ...getColumnSearchProps("historique_etape_b.etape_libelle"),
    render: (etapeB) => etapeB.etape_libelle,
    width: "15%",
  },
  {
    title: "Date",
    dataIndex: "historique_date_at",
    key: "historique_date_at",
    ...getColumnSearchPropsDate("historique_date_at"),
    render: (date) => dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
    sorter: (a, b) => dayjs(a.historique_date_at).unix() - dayjs(b.historique_date_at).unix(),
    width: "10%",
  },
  {
    title: "Lot",
    dataIndex: "historique_requete",
    key: "lot",
    render: (requete) => {
      const location = locations.find(
        (location) => location.location_requete?.requete_id === requete.requete_id
      );
      return location ? location.location_lot.lot_reference : "";
    },
    width: "10%",
  },
  {
    title: "Entrée / Sortie",
    dataIndex: "historique_requete",
    key: "es",
    render: (requete) => {
      const location = locations.find(
        (location) => location.location_requete?.requete_id === requete.requete_id
      );
      return location
        ? `${dayjs(location.location_date_debut).format("DD/MM/YYYY")} - ${dayjs(
            location.location_date_fin
          ).format("DD/MM/YYYY")}`
        : "";
    },
    width: "10%",
  },
];

import { compteToAdapted } from "./compteAdapter";
import { rapprochementBancaireToAdapted } from "./rapprochementBancaireAdapter";

export function ecrituresLignesToAdapted(items) {
  return items
    ? items.map((item) => {
        return ecritureLigneToAdapted(item);
      })
    : null;
}

export function ecritureLigneToAdapted(item) {
  return item
    ? {
        ecriture_ligne_id: item.id,
        ecriture_ligne_libelle: item.libelle,
        ecriture_ligne_montant_debit: item.montant_debit,
        ecriture_ligne_montant_credit: item.montant_credit,
        ecriture_ligne_lettrage: item.lettrage,
        ecriture_ligne_validee: item.validee,
        ecriture_ligne_locataire_code: item.locataire_code,
        ecriture_ligne_en_reddition: item.en_reddition,
        ecriture_ligne_compte: compteToAdapted(item.compte),
        ecriture_ligne_compte_id: item.compte_id,
        ecriture_ligne_ecriture_id: item.ecriture_id,
        ecriture_ligne_rubrique_id: item.rubrique_id,
        ecriture_ligne_rapprochement_bancaire: rapprochementBancaireToAdapted(
          item.rapprochement_bancaire
        ),
      }
    : null;
}

export function adaptedToEcrituresLignes(items) {
  return items
    ? items.map((item) => {
        return adaptedToEcritureLigne(item);
      })
    : null;
}

export function adaptedToEcritureLigne(item) {
  return item
    ? {
        id: item.ecriture_ligne_id,
        libelle: item.ecriture_ligne_libelle,
        montant_debit: item.ecriture_ligne_montant_debit,
        montant_credit: item.ecriture_ligne_montant_credit,
        lettrage: item.ecriture_ligne_lettrage,
        locataire_code: item.ecriture_ligne_locataire_code,
        en_reddition: item.ecriture_ligne_en_reddition,
        compte_id: item.ecriture_ligne_compte_id,
        ecriture_id: item.ecriture_ligne_ecriture_id,
        rubrique_id: item.ecriture_ligne_rubrique_id,
      }
    : null;
}

export function provenancesToAdapted(items) {
  return items
    ? items.map((item) => {
        return provenanceToAdapted(item);
      })
    : null;
}

export function provenanceToAdapted(item) {
  //Resource
  return item
    ? {
        provenance_id: item.id,
        provenance_libelle: item.libelle,
        provenance_plateforme: item.plateforme,
      }
    : null;
}

export function adaptedToProvenance(item) {
  //Model
  return item
    ? {
        libelle: item.provenance_libelle,
        plateforme: item.provenance_plateforme,
        etablissement_id: item.provenance_etablissement_id,
      }
    : null;
}

import { requeteToAdapted } from "./requeteAdapter";
import { locationsLignesToAdapted } from "./locationLigneAdapter";
import { occupantsToAdapted } from "./occupantAdapter";
import { simpleLotToAdapted } from "./lotAdapter";

export function locationsToAdapted(items) {
  return items
    ? items.map((item) => {
        return locationToAdapted(item);
      })
    : null;
}

export function locationToAdapted(item) {
  return item
    ? {
        location_id: item.id,
        location_date_debut: item.date_debut,
        location_date_fin: item.date_fin,
        location_etat: item.etat,
        location_fin_option: item.fin_option,
        location_montant_propose: item.montant_propose,
        location_acompte_propose: item.acompte_propose,
        location_date_creation: item.date_creation,
        location_nb_adultes: item.nb_adultes,
        location_nb_enfants: item.nb_enfants,
        location_nb_bebes: item.nb_bebes,
        location_pourcentage_acompte: item.pourcentage_acompte,
        location_desiderata: item.desiderata,
        location_remarques: item.remarques,
        location_remise_fidelite: item.remise_fidelite,
        location_heure_arrivee: item.heure_arrivee,
        location_heure_depart: item.heure_depart,
        location_entree: item.entree,
        location_sortie: item.sortie,
        location_menage: item.menage,
        location_type_contrat: item.type_contrat,
        location_lot: simpleLotToAdapted(item.lot),
        location_requete: requeteToAdapted(item.requete),
        location_occupant: occupantsToAdapted(item.occupant),
        location_ligne: locationsLignesToAdapted(item.location_ligne),
        location_date_fin_option: item.date_fin_option,
        location_date_contrat: item.date_contrat,
        location_bail_signe: item.bail_signe,
        location_info_sejour: item.info_sejour,
        location_chauffeur_reserve: item.chauffeur_reserve,
        location_date_acompte: item.date_acompte,
        location_date_solde: item.date_solde,
        feedbacks: item.feedback,
      }
    : null;
}

export function adaptedToLocation(item) {
  return item
    ? {
        date_debut: item.location_date_debut,
        date_fin: item.location_date_fin,
        etat: item.location_etat,
        fin_option: item.location_fin_option,
        montant_propose: item.location_montant_propose,
        acompte_propose: item.location_acompte_propose,
        pourcentage_acompte: item.location_pourcentage_acompte,
        date_creation: item.location_date_creation,
        desiderata: item.location_desiderata,
        remarques: item.location_remarques,
        remise_fidelite: item.location_remise_fidelite,
        nb_adultes: item.location_nb_adultes,
        nb_enfants: item.location_nb_enfants,
        nb_bebes: item.location_nb_bebes,
        heure_arrivee: item.location_heure_arrivee,
        heure_depart: item.location_heure_depart,
        entree: item.location_entree,
        sortie: item.location_sortie,
        menage: item.location_menage,
        type_contrat: item.location_type_contrat,
        lot_id: item.location_lot_id,
        requete_id: item.location_requete_id,
        date_fin_option: item.location_date_fin_option,
        date_contrat: item.location_date_contrat,
        bail_signe: item.location_bail_signe,
        info_sejour: item.location_info_sejour,
        chauffeur_reserve: item.location_chauffeur_reserve,
        date_acompte: item.location_date_acompte,
        date_solde: item.location_date_solde,
      }
    : null;
}

import React from "react";

import PropTypes from "prop-types";
import { findDOMNode } from "react-dom";
import { DragSource, DropTarget } from "react-dnd";
import flow from "lodash/flow";

import "../../App.css";

import { DeleteOutlined, FullscreenOutlined, EditOutlined } from "@ant-design/icons";

import { Button } from "antd";
import Constants from "../../utils/constants";

const photoSource = {
  beginDrag(props) {
    return {
      key: props.key,
      id: props.id,
      index: props.index,
    };
  },
};

const photoTarget = {
  hover(props, monitor, component) {
    if (!props.forbidDrag) {
      const dragIndex = monitor.getItem().index;
      const hoverIndex = props.index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) return;

      // Determine rectangle on screen
      const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleX = (hoverBoundingRect.left - hoverBoundingRect.right) / 2;

      // Determine mouse position monitor.getClientOffset()
      const clientOffset = hoverMiddleX;

      // Get pixels to the top
      const hoverClientX = clientOffset.x - hoverBoundingRect.right;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) return;

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) return;

      // Time to actually perform the action
      props.moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex;
    }
  },
  drop(props, monitor) {
    props.handleValidate(monitor.getItem().id);
  },
};

class Photo extends React.Component {
  static propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    isDragging: PropTypes.bool.isRequired,
    id: PropTypes.any.isRequired,
    text: PropTypes.string.isRequired,
    moveCard: PropTypes.func.isRequired,
  };

  onClickPreview = () => this.props.onClickPreview(this.props.photo);

  onClickRemove = () => this.props.onClickRemove(this.props.photo);

  onClickEdit = () => this.props.onClickEdit(this.props.photo);

  render() {
    const {
      isArchive,
      photo,
      key,
      forbidDrag,
      isDragging,
      connectDragSource,
      connectDropTarget,
    } = this.props;

    const opacity = isDragging ? 0 : 1;
    const cursor = forbidDrag ? "wait" : "move";

    return (
      connectDragSource &&
      connectDropTarget &&
      connectDragSource(
        connectDropTarget(
          <div
            className={`container${photo.photo_edition === true ? " bordure-or" : ""}`}
            style={{ opacity, cursor }}
          >
            <img
              alt={photo.name}
              key={key}
              src={`${Constants.mode}://${Constants.domain}:${Constants.port}/api/photos/display/${photo.uid}`}
              className={"image"}
            />

            <div className={"middle"}>
              <Button className={"text"} shape={"circle"} onClick={this.onClickPreview}>
                <FullscreenOutlined />
              </Button>
              <Button
                disabled={isArchive}
                className={"text"}
                shape={"circle-outline"}
                onClick={this.onClickRemove}
              >
                <DeleteOutlined />
              </Button>
              <Button
                disabled={isArchive}
                className={"text"}
                shape={"circle-outline"}
                onClick={this.onClickEdit}
              >
                <EditOutlined />
              </Button>
            </div>
          </div>
        )
      )
    );
  }
}

export default flow(
  DragSource("photo", photoSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  })),
  DropTarget("photo", photoTarget, (connect) => ({
    connectDropTarget: connect.dropTarget(),
  }))
)(Photo);

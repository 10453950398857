export function etapesActionsToAdapted(items) {
  return items
    ? items.map((item) => {
        return etapeActionToAdapted(item);
      })
    : null;
}

export function etapeActionToAdapted(item) {
  return item
    ? {
        etape_action_id: item.id,
        etape_action_type: item.type,
        etape_action_repere: item.repere,
        etape_action_timer: item.timer,
        etape_action_action: item.action,
        etape_action_etape_destination_action_id: item.etape_destination_action_id,
        etape_action_etape_destination_timer_id: item.etape_destination_timer_id,
        etape_action_etape_id: item.etape_id,
      }
    : null;
}

export function adaptedToEtapeAction(item) {
  return item
    ? {
        type: item.etape_action_type,
        repere: item.etape_action_repere,
        timer: item.etape_action_timer,
        action: item.etape_action_action,
        etape_destination_action_id: item.etape_action_etape_destination_action_id,
        etape_destination_timer_id: item.etape_action_etape_destination_timer_id,
        etape_id: item.etape_action_etape_id,
      }
    : null;
}

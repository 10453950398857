import React, { Component } from "react";

import { Tree } from "antd";

class MyTreeGroupe extends Component {
  onDrop = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    // from : ancienne position dans l'arbre
    let from = this.props.treeGroupes.findIndex((item) => item.key === dragKey);
    // to : nouvelle position dans l'arbre
    let to = this.props.treeGroupes.findIndex((item) => item.key === dropKey);

    // en remontant une étape
    if (to < from) to = to + 1;

    if (dropPosition === -1) to = to - 1;

    // dropPosition === 0 : drop sur une étape - DÉSACTIVÉ
    // gestion uniquement du drop au dessus et en dessous
    if (dropPosition !== 0) {
      this.props.onTreeGroupesChange(this.array_move(this.props.treeGroupes, from, to));
    }
  };

  array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }

    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  render() {
    const { treeGroupes, loadingOrdre } = this.props;

    const loop = (treeGroupes) =>
      treeGroupes.map((item) => {
        item.icon = "";
        item.isLeaf = true;
        item.option === true
          ? (item.title = (
              <span style={{ color: item.etape_code_couleur.code_couleur_hex }}>
                {item.etape_libelle} (OPTION)
              </span>
            ))
          : (item.title = (
              <span style={{ color: item.etape_code_couleur.code_couleur_hex }}>
                {item.etape_libelle}
              </span>
            ));

        return item;
      });

    return (
      <div>
        <Tree
          showLine
          draggable
          multiple={false}
          defaultExpandAll
          onDrop={this.onDrop}
          className="draggable-tree"
          treeData={loop(treeGroupes)}
          onSelect={this.props.handleSelectGroupeEtape}
          disabled={loadingOrdre}
        />
      </div>
    );
  }
}

export default MyTreeGroupe;

export const columns = () => [
  {
    title: "#",
    dataIndex: "locataire_id",
    key: "locataire_id",
    sorter: (a, b) => parseInt(b.locataire_id) - parseInt(a.locataire_id),
    render: () => <></>,
    width: "10%",
  },
  {
    title: "Locataire",
    dataIndex: "locataire_nom",
    key: "locataire_nom",
    width: "30%",
  },
  {
    title: "Règlements",
    dataIndex: "total_reglements",
    key: "total_reglements",
    render: (total_reglements) => {
      return total_reglements.toLocaleString("fr-FR", {
        style: "currency",
        currency: "EUR",
      });
    },
    width: "30%",
  },
  {
    title: "Comptabilité",
    dataIndex: "total_ecritures",
    key: "total_ecritures",
    render: (total_ecritures) => {
      return total_ecritures.toLocaleString("fr-FR", {
        style: "currency",
        currency: "EUR",
      });
    },
    width: "30%",
  },
];

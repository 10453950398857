import { adaptedToAdresse, adresseToAdapted } from "./adresseAdapter";

export function immeublesToAdapted(items) {
  return items
    ? items.map((item) => {
        return immeubleToAdapted(item);
      })
    : null;
}

export function immeubleToAdapted(item) {
  return item
    ? {
        immeuble_id: item.id,
        immeuble_nom: item.nom,
        immeuble_adresse: adresseToAdapted(item.adresse),
        immeuble_groupement_id: item.groupement_id,
        immeuble_code_acces: item.code_acces,
        immeuble_nom_syndic: item.nom_syndic,
      }
    : null;
}

export function adaptedToImmeuble(item) {
  const immeuble = item
    ? {
        nom: item.immeuble_nom,
        groupement_id: item.immeuble_groupement_id,
        adresse_id: item.immeuble_adresse_id,
        code_acces: item.immeuble_code_acces,
        nom_syndic: item.immeuble_nom_syndic,
      }
    : null;

  const adresse = item ? adaptedToAdresse(item) : null;

  return item ? { ...immeuble, ...adresse } : null;
}

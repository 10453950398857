import { rubriqueToAdapted } from "./rubriqueAdapter";

export function matricesLignesToAdapted(items) {
  return items
    ? items.map((item) => {
        return matriceLigneToAdapted(item);
      })
    : null;
}

export function matriceLigneToAdapted(item) {
  return item
    ? {
        matrice_ligne_matrice_id: item.matrice_id,
        matrice_ligne_montant_nuite: item.montant_nuite,
        matrice_ligne_montant_semaine: item.montant_semaine,
        matrice_ligne_montant_quinzaine: item.montant_quinzaine,
        matrice_ligne_montant_mois: item.montant_mois,
        matrice_ligne_rubrique: rubriqueToAdapted(item.rubrique),
      }
    : null;
}

export function adaptedToMatriceLigne(item) {
  return item
    ? {
        matrice_id: item.matrice_ligne_matrice_id,
        rubrique_id: item.matrice_ligne_rubrique_id,
        montant_nuite: item.matrice_ligne_montant_nuite,
        montant_semaine: item.matrice_ligne_montant_semaine,
        montant_quinzaine: item.matrice_ligne_montant_quinzaine,
        montant_mois: item.matrice_ligne_montant_mois,
      }
    : null;
}

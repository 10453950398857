import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Spin } from "antd";
import ListProposition from "../../components/ListProposition";
import { columns } from "./columns";
import { loadRequetes, updateRequete } from "../../store/actions/requeteAction";
import { loadRequeteLots } from "../../store/actions/requeteLotAction";

class Propositions extends Component {
  componentDidMount() {
    const groupement_id = this.props.utilisateur.groupement_id;
    this.props.loadRequetes(groupement_id);
    this.props.loadRequeteLots(groupement_id);
  }

  syncRequetesLots = () => {
    const groupement_id = this.props.utilisateur.groupement_id;
    this.props.loadRequeteLots(groupement_id);
  };

  render() {
    const { requetes, requeteLots } = this.props;

    return (
      <Spin spinning={!requeteLots} size="large" tip="Chargement en cours...">
        <Card title="" bordered={false} className="card-panel">
          <ListProposition
            requetes={requetes}
            listData={
              requeteLots &&
              requeteLots.filter((item) => item.requete_lot_requete.requete_fin === false)
            }
            tableOption={columns}
            addPanesRequete={this.props.addPanesRequete}
            updateRequete={this.props.updateRequete}
            syncRequetesLots={this.syncRequetesLots}
            titre={true}
          />
        </Card>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadRequetes: (groupement_id) => dispatch(loadRequetes(groupement_id)),
    loadRequeteLots: (groupement_id) => dispatch(loadRequeteLots(groupement_id)),
    updateRequete: (jsonData, id) => dispatch(updateRequete(jsonData, id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    requetes: state.requetes.data,
    requeteLots: state.requetesLots.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Propositions);

import { Tag } from "antd";
import dayjs from "dayjs";
import { getInfoAgentSimple } from "../../utils/tools";

export const columns = (lotsProps) => [
  {
    title: "#",
    dataIndex: "menage_id",
    key: "menage_id",
    render: () => <></>,
    sorter: (a, b) => parseInt(a.menage_id) - parseInt(b.menage_id),
    width: "5%",
  },
  {
    title: "Lot",
    dataIndex: "menage_lot_id",
    key: "menage_lot_id",
    render: (menage_lot_id) => (
      <Tag key={menage_lot_id}>
        {lotsProps?.find((e) => e.lot_id === parseInt(menage_lot_id))?.lot_reference}
      </Tag>
    ),
    width: "15%",
  },
  {
    title: "Date",
    dataIndex: "menage_date",
    key: "menage_date",
    render: (date) => dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
    sorter: (a, b) => dayjs(a.menage_date).unix() - dayjs(b.menage_date).unix(),
    width: "8%",
  },
  {
    title: "Heure",
    dataIndex: "menage_heure",
    key: "menage_heure",
    width: "8%",
  },
  {
    title: "Agent",
    dataIndex: "menage_agent",
    key: "menage_agent",
    render: (menage_agent) => (menage_agent ? getInfoAgentSimple(menage_agent) : ""),
    width: "15%",
  },
  {
    title: "Type",
    dataIndex: "menage_type",
    key: "menage_type",
    width: "8%",
  },
  {
    title: "État",
    dataIndex: "menage_etat",
    key: "manege_etat",
    render: (menage_etat) => <span className={etat(menage_etat)}></span>,
    width: "8%",
  },
];

const etat = (etat) => {
  let str = "";
  switch (etat) {
    case "n":
      str = "dot-non-planifie";
      break;
    case "p":
      str = "dot-planifie";
      break;
    case "f":
      str = "dot-fait";
      break;
    default:
      break;
  }
  return str;
};

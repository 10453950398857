import React, { useEffect } from "react";
import { Row, Col, Image } from "antd";

import Constants from "../../utils/constants";
import FormCalendrier from "../FormCalendrier";

const ViewLot = (props) => {
  const { lotProps, locations, quittances } = props;

  useEffect(() => {});

  const getPlaceholderImages = () => {
    let content = [];
    for (let i = 0; i < 4; i++) {
      const item = (
        <Image
          key={i}
          width={300}
          src={`https://via.placeholder.com/300x200`}
          style={{ padding: 10, display: i > 3 ? "none" : "block" }}
        />
      );
      content.push(item);
    }
    return content;
  };

  return (
    <>
      <Row gutter={10} style={{ minHeight: 440 }}>
        <Col span={10}>
          {lotProps && (
            <Image.PreviewGroup>
              {lotProps.lot_photos.length > 0
                ? lotProps.lot_photos.map((photo, i) => {
                    return (
                      <Image
                        key={i}
                        width={300}
                        src={`${Constants.mode}://${Constants.domain}:${Constants.port}/api/photos/display/${photo.uid}`}
                        style={{ padding: 10, display: i > 3 ? "none" : "block" }}
                      />
                    );
                  })
                : getPlaceholderImages()}
            </Image.PreviewGroup>
          )}
        </Col>
        <Col span={14}>
          <h4>
            {lotProps.lot_reference ? lotProps.lot_reference : ""}{" "}
            {lotProps.lot_designation ? lotProps.lot_designation : ""}
          </h4>
          <p>{lotProps.lot_adresse.adresse_gmaps ? lotProps.lot_adresse.adresse_gmaps : ""}</p>
          <p>
            {lotProps.lot_descriptions.length > 0
              ? lotProps.lot_descriptions[0].description_html
                  .replace(/<\/?[^>]+(>|$)/g, "")
                  .split("\n")
                  .map(function (item, i) {
                    return (
                      <span key={i}>
                        {item}
                        <br />
                      </span>
                    );
                  })
              : ""}
          </p>
          {/* <p></p> */}
          <p>
            {lotProps.lot_equipements.map((ligne, i) => {
              return ligne.equipement_libelle + ", ";
            })}
          </p>
        </Col>
      </Row>
      <Row>
        {lotProps && (
          <FormCalendrier readOnly lot={lotProps} locations={locations} quittances={quittances} />
        )}
      </Row>
    </>
  );
};

export default ViewLot;

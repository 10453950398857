import { typePaiementToAdapted } from "./typePaiementAdapter";

export function compteBancaireToAdapted(item) {
  return item
    ? {
        compte_bancaire_id: item.id,
        compte_bancaire_titulaire: item.titulaire,
        compte_bancaire_banque: item.banque,
        compte_bancaire_code_banque: item.code_banque,
        compte_bancaire_code_guichet: item.code_guichet,
        compte_bancaire_num_compte: item.num_compte,
        compte_bancaire_cle_RIB: item.cle_RIB,
        compte_bancaire_IBAN: item.IBAN, // TODO à controler uppercase / constant
        compte_bancaire_BIC: item.BIC, // TODO à controler uppercase / constant
        compte_bancaire_type_paiement: typePaiementToAdapted(item.type_paiement),
      }
    : null;
}

export function adaptedToCompteBancaire(item) {
  return item
    ? {
        titulaire: item.compte_bancaire_titulaire,
        banque: item.compte_bancaire_banque,
        code_banque: item.compte_bancaire_code_banque,
        code_guichet: item.compte_bancaire_code_guichet,
        num_compte: item.compte_bancaire_num_compte,
        cle_RIB: item.compte_bancaire_cle_RIB,
        IBAN: item.compte_bancaire_IBAN,
        BIC: item.compte_bancaire_BIC,
        type_paiement_id: item.compte_bancaire_type_paiement_id,
      }
    : null;
}

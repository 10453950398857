export function photosToAdapted(items) {
  return items
    ? items.map((item) => {
        return photoToAdapted(item);
      })
    : null;
}

export function photoToAdapted(item) {
  //Resource
  return item
    ? {
        uid: item.id,
        photo_id: item.id,
        name: item.libelle,
        photo_libelle: item.libelle,
        url: item.url,
        photo_chemin: item.chemin,
        photo_ordre: item.ordre,
        photo_lot_id: item.lot_id,
        photo_edition: item.edition,
      }
    : null;
}

export function adaptedToPhotos(items) {
  return items
    ? {
        fileList: items.fileList.map((item) => {
          return adaptedToPhoto(item);
        }),
      }
    : null;
}

export function adaptedToPhoto(item) {
  //Model
  return item
    ? {
        id: item.photo_id,
        lot_id: item.photo_lot_id,
        chemin: item.photo_chemin,
        libelle: item.photo_libelle,
        ordre: item.photo_ordre,
        edition: item.photo_edition,
      }
    : null;
}

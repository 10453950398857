import {
  LOAD_SUCCESS_WORDS,
  LOAD_FAIL_WORDS,
  SAVE_SUCCESS_WORDS,
  SAVE_FAIL_WORDS,
  UPDATE_SUCCESS_WORDS,
  UPDATE_FAIL_WORDS,
  DELETE_SUCCESS_WORDS,
  DELETE_FAIL_WORDS,
} from "../actions/wordAction";

const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS_WORDS:
      const words = action.payload;
      words.forEach((item) => {
        item.key = item.word_id;
      });
      return {
        ...state,
        loaded: true,
        data: words,
        error: null,
      };
    case LOAD_FAIL_WORDS:
      return {
        ...state,
        loaded: false,
        data: null,
        error: action.error,
      };
    case SAVE_SUCCESS_WORDS:
      const newWord = action.payload;
      newWord.key = newWord.word_id;
      newWord.word_type = parseInt(newWord.word_type);
      return {
        ...state,
        loading: false,
        loaded: true,
        data: [...state.data, newWord],
        error: null,
      };
    case SAVE_FAIL_WORDS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_WORDS:
      const updatedListe = [];
      state.data.forEach((item) => {
        if (item.word_id === action.payload.word_id) {
          item = action.payload;
        }
        updatedListe.push(item);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: updatedListe,
        error: null,
      };
    case UPDATE_FAIL_WORDS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case DELETE_SUCCESS_WORDS:
      const filtered = state.data.filter((item) => {
        return item.word_id !== action.id;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filtered,
        error: null,
      };
    case DELETE_FAIL_WORDS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    default:
      return state;
  }
}

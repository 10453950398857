import React, { useEffect, useState, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import { Button, Col, Form, Input, Row, Select, Spin, Tag } from "antd";
import { MailOutlined, PaperClipOutlined, UserOutlined } from "@ant-design/icons";

import { toast } from "react-toastify";

import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import RelevePDF from "../Pdf/RelevePDF";

import dayjs from "dayjs";
import { HttpMethod, initFetch } from "../../utils/fetcher";

const FormMailMultiple = (props) => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [objet, setObjet] = useState("Rapport de Gestion");
  const [editorContent, setEditorContent] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        htmlToDraft(
          "<p>Madame, Monsieur,</p><p>Nous vous remercions de trouver ci-joint votre relevé de gestion.</p><p>Restant à votre disposition.</p><p>Bien cordialement.</p><p>Le Service Gestion.</p>"
        )
      )
    )
  );

  // Référence du formulaire
  const formRef = useRef();
  const toastId = React.useRef(null);

  useEffect(() => {
    if (count === props.lignesReddition.length) {
      setLoading(false);
      setCount(0);
      toast.update(toastId.current, {
        render: "Emails envoyés !",
        type: toast.TYPE.SUCCESS,
        closeButton: null, // The closeButton defined on ToastContainer will be used
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  const onEditorStateChange = (content) => {
    setEditorContent(content);
  };

  const notify = () => {
    toastId.current = toast("Envoi en cours...", {
      containerId: "A",
      autoClose: false,
      closeButton: false, // Remove the closeButton
    });
  };

  const prepareMailing = () => {
    setLoading(true);
    notify();

    prepareLigne(count, props.lignesReddition.length);
  };

  const prepareLigne = (i, len) => {
    if (i < len) {
      const ligne = props.lignesReddition[i];
      if (ligne.proprietaire_affectations.length > 0) {
        const dossier = ligne.proprietaire_affectations[0].proprietaire_affectation_dossier;
        if (dossier !== null) {
          preparePdf(i, ligne, ligne.proprietaire_affectations[0]);
        } else {
          props.saveDossier(getJsonDossier(ligne)).then((dossier) => {
            props
              .updateProprietaireAffectation(
                {
                  proprietaire_affectation_compte_id:
                    ligne.proprietaire_affectations[0].proprietaire_affectation_compte_id,
                  proprietaire_affectation_dossier_id: dossier ? dossier.dossier_id : null,
                },
                ligne.proprietaire_id,
                ligne.proprietaire_affectations[0].proprietaire_affectation_societe.societe_id
              )
              .then((affectation) => {
                preparePdf(i, ligne, affectation);
              });
          });
        }
      }
    }
  };

  const getJsonDossier = (proprietaire) => {
    return {
      dossier_url: `Proprietaires/${proprietaire.proprietaire_id}/`,
    };
  };

  const preparePdf = (i, ligne, affectation) => {
    const html = ReactDOMServer.renderToStaticMarkup(
      <RelevePDF
        dateButoir={props.dateButoir}
        detaillee={props.detaillee}
        ligneReddition={ligne}
        utilisateur={props.utilisateur}
      />
    );
    const pdf = {
      titre: "Releve",
      destinataire: "",
      pdf_page: [],
    };
    const pdfPage = {
      html: html,
    };
    pdf.pdf_page.push(pdfPage);
    const { url, params } = initFetch(`pdfs`, HttpMethod.POST);
    fetch(url, { ...params, body: JSON.stringify(pdf) })
      .then(function (response) {
        return response.json();
      })
      .then((result) => {
        getFileFromUrl(i, result.pdf.id, ligne, affectation.proprietaire_affectation_dossier);
      });
  };

  const getFileFromUrl = (i, id, ligne, dossier) => {
    const { url, params } = initFetch(
      `pdf/document_b.php?num=${id}&name=document`,
      HttpMethod.GET,
      {
        Authorization: true,
        formData: false,
        pdf: true,
      }
    );
    fetch(url, params)
      .then((e) => {
        return e.blob();
      })
      .then((blob) => {
        let b = blob;
        b.lastModifiedDate = new Date();
        b.name = `Releve_de_Gestion_du_${dayjs().format("DD-MM-YYYY")}_Num-${id}.pdf`;

        if (props.tags.length > 0) {
          props.saveDocument({ file: b }, dossier, props.tags[0]).then((res) => {
            sendMail(i, res, ligne);
          });
        }
      });
  };

  const sendMail = (i, document, ligne) => {
    const piecesJointes = [];

    const doc = document;
    doc.uid = doc.document_id;
    doc.name = doc.document_nom;
    doc.status = "done";
    doc.ref = `${doc.document_dossier.dossier_url}${doc.document_tag.tag_libelle}/`;
    doc.url = `${doc.document_dossier.dossier_url}${doc.document_tag.tag_libelle}/`;
    piecesJointes.push(doc);

    const message = draftToHtml(convertToRaw(editorContent.getCurrentContent()));

    const arrayDestinataire = [];
    const arrayCopie = [];

    let email_destinataire = ligne.proprietaire.personne_email1;

    const destinataire = email_destinataire.split(";");
    destinataire.forEach((element) => {
      arrayDestinataire.push({
        email: element,
        name: element,
      });
    });

    let email_copie = "";
    if (email_copie) {
      if (email_copie.endsWith(";")) {
        email_copie = email_copie.substring(0, email_copie.lastIndexOf(";"));
      }

      const copie = email_copie.split(";");
      copie.forEach((element) => {
        arrayCopie.push({
          email: element,
          name: element,
        });
      });
    }

    let email_pieces_jointes = [];
    piecesJointes.forEach((item) => {
      const jsonDataPJ = {
        nom: item.name,
        ref: item.ref,
        url: item.url,
      };
      email_pieces_jointes.push(jsonDataPJ);
    });

    const jsonDataEmail = {
      email_objet: objet,
      email_message: message,
      email_destinataire: email_destinataire,
      email_destinataire_array: arrayDestinataire,
      email_copie: email_copie,
      email_copie_array: arrayCopie,
      email_pieces_jointes: email_pieces_jointes,
      email_utilisateur_id: props.parametre.parametre_utilisateur.utilisateur_id,
      email_requete_id: null,
      parametre_smtp: props.parametre.parametre_smtp,
      parametre_port: props.parametre.parametre_port,
      parametre_email: props.parametre.parametre_email,
      parametre_mdp: props.parametre.parametre_mdp,
      parametre_copie_agence: props.parametre.parametre_copie_agence,
      parametre_email_copie: props.parametre.parametre_email_copie,
    };

    props
      .saveEmail(jsonDataEmail)
      .then(() => {
        const len = props.lignesReddition.length;
        prepareLigne(i + 1, len);
        setCount((prevCount) => prevCount + 1);
      })
      .catch(() => {
        toast.error("Échec de l'envoi !", {
          containerId: "A",
        });
      });
  };

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ color: "black" }}
        icon={<UserOutlined />}
      >
        {label}
      </Tag>
    );
  };

  const onChangeObjet = (objet) => {
    setObjet(objet);
  };

  return (
    <Spin size="large" tip="Chargement en cours..." spinning={loading}>
      <Form size="large" ref={formRef} className="form-mail" onFinish={prepareMailing}>
        <Row>
          <Col span={24}>
            <Form.Item name="email_destinataire" initialValue={["liste propriétaires"]}>
              <Select
                mode="multiple"
                disabled
                tagRender={tagRender}
                style={{ width: "100%", marginBottom: 10 }}
                placeholder="Destinataire(s)"
              />
            </Form.Item>
            <Form.Item name="email_objet" initialValue={objet}>
              <Input
                autoComplete="off"
                style={{ marginBottom: 10 }}
                placeholder="Objet"
                onChange={(e) => onChangeObjet(e.target.value)}
              />
            </Form.Item>
            <Tag icon={<PaperClipOutlined />} style={{ marginBottom: 10 }}>
              relevé de gestion.pdf
            </Tag>
            <Form.Item name="email_message">
              <Editor
                editorState={editorContent}
                toolbarClassName="toolbar-absolute"
                wrapperClassName="wrapper-class"
                editorClassName="editor-class editor-large"
                onEditorStateChange={onEditorStateChange}
                toolbarOnFocus
                toolbar={{
                  options: ["inline", "blockType", "fontSize", "fontFamily"],
                  inline: {
                    options: ["bold", "italic", "underline", "strikethrough", "monospace"],
                    bold: { className: "bordered-option-classname" },
                    italic: { className: "bordered-option-classname" },
                    underline: { className: "bordered-option-classname" },
                    strikethrough: { className: "bordered-option-classname" },
                    code: { className: "bordered-option-classname" },
                  },
                  blockType: {
                    className: "bordered-option-classname",
                  },
                  fontSize: {
                    className: "bordered-option-classname",
                  },
                  fontFamily: {
                    className: "bordered-option-classname",
                  },
                }}
              />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              icon={<MailOutlined />}
              style={{ marginTop: 10, float: "right" }}
            >
              Envoyer
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default FormMailMultiple;

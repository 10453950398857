import { adaptedToMatriceLigne, matriceLigneToAdapted } from "../../adapter/matriceLigneAdapter";
import { HttpMethod, initFetch } from "../../utils/fetcher";

export const SAVE_SUCCESS_MATRICES_LIGNES = "SAVE_SUCCESS_MATRICES_LIGNES";
export const SAVE_FAIL_MATRICES_LIGNES = "SAVE_FAIL_MATRICES_LIGNES";
export const UPDATE_SUCCESS_MATRICES_LIGNES = "UPDATE_SUCCESS_MATRICES_LIGNES";
export const UPDATE_FAIL_MATRICES_LIGNES = "UPDATE_FAIL_MATRICES_LIGNES";
export const DELETE_SUCCESS_MATRICES_LIGNES = "DELETE_SUCCESS_MATRICES_LIGNES";
export const DELETE_FAIL_MATRICES_LIGNES = "DELETE_FAIL_MATRICES_LIGNES";

export const SAVE_SUCCESS_MATRICE_LIGNE = "SAVE_SUCCESS_MATRICE_LIGNE";
export const UPDATE_SUCCESS_MATRICE_LIGNE = "UPDATE_SUCCESS_MATRICE_LIGNE";
export const DELETE_SUCCESS_MATRICE_LIGNE = "DELETE_SUCCESS_MATRICE_LIGNE";

export function saveLigneMatrice(jsonData, lot) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`matricesLignes`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToMatriceLigne(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.matrice_ligne);
          dispatch({
            type: SAVE_SUCCESS_MATRICE_LIGNE,
            payload: matriceLigneToAdapted(res.matrice_ligne),
            lot,
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_MATRICES_LIGNES,
            error: err,
          })
        );
    });
  };
}

export function updateLigneMatrice(jsonData, idMatrice, idRubrique, lot) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(
        `matricesLignes/${idMatrice}/${idRubrique}`,
        HttpMethod.PUT
      );
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToMatriceLigne(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.matrice_ligne);
          dispatch({
            type: UPDATE_SUCCESS_MATRICE_LIGNE,
            payload: matriceLigneToAdapted(res.matrice_ligne),
            lot,
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_MATRICES_LIGNES,
            error: err,
          })
        );
    });
  };
}

export function deleteLigneMatrice(idMatrice, idRubrique, lot) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(
        `matricesLignes/${idMatrice}/${idRubrique}`,
        HttpMethod.DELETE
      );
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.matrice_ligne);
          dispatch({
            type: DELETE_SUCCESS_MATRICE_LIGNE,
            payload: matriceLigneToAdapted(res.matrice_ligne),
            lot,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_MATRICES_LIGNES,
            error: err,
          });
        });
    });
  };
}

import React, { Component } from "react";

export default class RapprochementPDF extends Component {
  render() {
    const {
      allLignesEcritures,
      lignesEcritures,
      dateDu,
      dateAu,
      compteGeneral,
      soldeTheorique,
      comptes,
      societes,
    } = this.props;

    let totalDebitF = 0;
    let totalCreditF = 0;
    let totalDebitA = 0;
    let totalCreditA = 0;

    let totalDebitC = 0;
    let totalCreditC = 0;
    let totalSoldeDebitC = 0;
    let totalSoldeCreditC = 0;
    let totalSoldeC = 0;

    let totalSoldeDebitR = 0;
    // let totalSoldeCreditR = 0;
    let totalSoldeDebitP = 0;
    let totalSoldeCreditP = 0;
    let totalSoldeP = 0;

    allLignesEcritures.forEach((ligne, i) => {
      totalDebitC += parseFloat(ligne.ecriture_ligne_montant_debit);
      totalCreditC += parseFloat(ligne.ecriture_ligne_montant_credit);
    });
    totalSoldeC = totalCreditC - totalDebitC;
    if (totalSoldeC < 0) {
      totalSoldeDebitC = Math.abs(totalSoldeC);
    } else if (totalSoldeC > 0) {
      totalSoldeCreditC = totalSoldeC;
    }

    lignesEcritures
      .filter(
        (ecriture) =>
          ecriture.ecriture_ligne_lettrage === "" || ecriture.ecriture_ligne_lettrage === null
      )
      .forEach((ligne, i) => {
        totalDebitA += parseFloat(ligne.ecriture_ligne_montant_debit);
        totalCreditA += parseFloat(ligne.ecriture_ligne_montant_credit);
      });
    totalSoldeP =
      parseFloat(soldeTheorique) +
      (parseFloat(totalDebitA) - parseFloat(totalCreditA)) +
      parseFloat(totalSoldeC);

    if (totalSoldeP < 0) {
      totalSoldeDebitP = Math.abs(totalSoldeP);
    } else if (totalSoldeP > 0) {
      totalSoldeCreditP = totalSoldeP;
    }

    return (
      <div>
        <div>
          <p>
            <strong>{societes[0].societe_raison_sociale}</strong>
          </p>
          <p>
            {societes[0].societe_adresse.adresse_numero} {societes[0].societe_adresse.adresse_voie}
          </p>
          <p>{societes[0].societe_adresse.adresse_secteur}</p>
          <p>
            {societes[0].societe_adresse.adresse_code_postal}{" "}
            {societes[0].societe_adresse.adresse_ville}
          </p>
        </div>

        {/* Titre */}
        <div className="entitre row" align="center">
          <h1>Rapprochement bancaire</h1>
          <p className="center">
            <span>
              <strong>
                {`Situation ${
                  dateDu ? "du " + dateDu.format("DD/MM/YYYY") + " " : ""
                }au ${dateAu.format("DD/MM/YYYY")}`}
              </strong>
            </span>
          </p>
          <p>
            <strong>
              Compte{" "}
              {comptes.find((compte) => compte.compte_id === compteGeneral).compte_compte_general}
              {" - "}
              {comptes.find((compte) => compte.compte_id === compteGeneral).compte_libelle}
            </strong>
          </p>
        </div>

        <div>
          <table className="table table-striped" border="1">
            <thead>
              <tr>
                <th width="100%" scope="col" align="center">
                  <strong>ECRITURES EN ATTENTE DE RAPPROCHEMENT</strong>
                </th>
              </tr>
              <tr>
                <th width="12%" scope="col" align="center">
                  <strong>DATE</strong>
                </th>
                <th width="58%" scope="col" align="center">
                  <strong> LIBELLE</strong>
                </th>
                <th width="15%" scope="col" align="center">
                  <strong>DEBIT </strong>
                </th>
                <th width="15%" scope="col" align="center">
                  <strong>CREDIT </strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {lignesEcritures
                .filter(
                  (ecriture) =>
                    ecriture.ecriture_ligne_lettrage === "" ||
                    ecriture.ecriture_ligne_lettrage === null
                )
                .map((ecriture, i) => {
                  totalDebitF += parseFloat(ecriture.ecriture_ligne_montant_debit);
                  totalCreditF += parseFloat(ecriture.ecriture_ligne_montant_credit);

                  if (i % 2) {
                    return (
                      <tr className="odd">
                        <td width="12%" align="center">
                          {ecriture.ecriture_date_ecriture}
                        </td>

                        <td width="58%"> {ecriture.ecriture_ligne_libelle}</td>
                        <td width="15%" style={{ textAlign: "right" }}>
                          {parseFloat(ecriture.ecriture_ligne_montant_debit) !== 0 ? (
                            parseFloat(ecriture.ecriture_ligne_montant_debit).toLocaleString(
                              "fr-FR",
                              {
                                style: "currency",
                                currency: "EUR",
                              }
                            )
                          ) : (
                            <></>
                          )}
                        </td>
                        <td width="15%" style={{ textAlign: "right" }}>
                          {parseFloat(ecriture.ecriture_ligne_montant_credit) !== 0 ? (
                            parseFloat(ecriture.ecriture_ligne_montant_credit).toLocaleString(
                              "fr-FR",
                              {
                                style: "currency",
                                currency: "EUR",
                              }
                            )
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr>
                        <td width="12%" align="center">
                          {ecriture.ecriture_date_ecriture}
                        </td>

                        <td width="58%"> {ecriture.ecriture_ligne_libelle}</td>
                        <td width="15%" style={{ textAlign: "right" }}>
                          {parseFloat(ecriture.ecriture_ligne_montant_debit) !== 0 ? (
                            parseFloat(ecriture.ecriture_ligne_montant_debit).toLocaleString(
                              "fr-FR",
                              {
                                style: "currency",
                                currency: "EUR",
                              }
                            )
                          ) : (
                            <></>
                          )}
                        </td>
                        <td width="15%" style={{ textAlign: "right" }}>
                          {parseFloat(ecriture.ecriture_ligne_montant_credit) !== 0 ? (
                            parseFloat(ecriture.ecriture_ligne_montant_credit).toLocaleString(
                              "fr-FR",
                              {
                                style: "currency",
                                currency: "EUR",
                              }
                            )
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    );
                  }
                })}
              <tr>
                <td width="70%" align="right">
                  <strong>TOTAL </strong>
                </td>
                <td width="15%" align="right">
                  {parseFloat(totalDebitF).toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </td>
                <td width="15%" align="right">
                  {parseFloat(totalCreditF).toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <table className="table table-striped" border="1">
            <thead></thead>
            <tbody>
              <tr>
                <td width="70%" align="right">
                  <strong>SOLDE dans la comptabilité au {dateAu.format("DD/MM/YYYY")} </strong>
                </td>
                <td width="15%" style={{ textAlign: "right" }}>
                  {parseFloat(totalSoldeDebitC).toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </td>
                <td width="15%" style={{ textAlign: "right" }}>
                  {parseFloat(totalSoldeCreditC).toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </td>
              </tr>
              <tr>
                <td width="70%" align="right">
                  <strong>SOLDE du relevé de banque au {dateAu.format("DD/MM/YYYY")} </strong>
                </td>
                <td width="15%" style={{ textAlign: "right" }}>
                  {parseFloat(totalSoldeDebitR).toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </td>
                <td width="15%" style={{ textAlign: "right" }}>
                  {parseFloat(soldeTheorique).toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </td>
              </tr>
              <tr>
                <td width="70%" align="right">
                  <strong>ECART rapprochement </strong>
                </td>
                <td width="15%" style={{ textAlign: "right" }}>
                  {parseFloat(totalSoldeDebitP).toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </td>
                <td width="15%" style={{ textAlign: "right" }}>
                  {parseFloat(totalSoldeCreditP).toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

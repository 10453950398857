import React, { Component } from "react";
import constants from "../../utils/constants";

import {
  Row,
  Col,
  Card,
  Form,
  Select,
  Button,
  DatePicker,
  Switch,
  InputNumber,
  Modal,
  Input,
} from "antd";

import { SearchOutlined } from "@ant-design/icons";

import FormEquipement from "../FormEquipement";

import dayjs from "dayjs";

import { formatDateToBDD } from "../../utils/tools";

const dateFormat = "DD/MM/YYYY";

const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;

class FormRecherche extends Component {
  state = {
    metros: [],
    quartiers: [],
    alur: false,
    longTerme: false,
    courtTerme: true,
  };

  componentDidMount() {
    this.init(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.init(props);
  }

  init = (props) => {
    this.onReset();
    let metros = [];
    let quartiers = [];
    props.champsPersos &&
      props.champsPersos.forEach((item) => {
        if (item.champ_perso_groupe_type === 1) quartiers.push(item);
        if (item.champ_perso_groupe_type === 2) metros.push(item);
      });

    this.setState({ metros, quartiers });

    if (!props.requete || !props.requete.requete_recherche) return;

    const recherche = props.requete.requete_recherche;

    if (recherche === undefined) return;

    let dates;
    if (recherche.recherche_alur)
      dates = recherche.recherche_date_debut ? dayjs(recherche.recherche_date_debut) : dayjs();
    else {
      dates = [
        recherche.recherche_date_debut ? dayjs(recherche.recherche_date_debut) : null,
        recherche.recherche_date_fin ? dayjs(recherche.recherche_date_fin) : null,
      ];

      if (!recherche.recherche_date_debut && !recherche.recherche_date_fin)
        dates = [dayjs(), dayjs().add(1, "days")];
    }

    let metrosIds = [];
    let quartiersIds = [];

    if (
      recherche.recherche_champ_perso !== undefined &&
      recherche.recherche_champ_perso.length > 0
    ) {
      recherche.recherche_champ_perso.forEach((item) => {
        if (item.champ_perso.champ_perso_groupe_type === 1)
          quartiersIds.push(item.champ_perso.champ_perso_id);
        if (item.champ_perso.champ_perso_groupe_type === 2)
          metrosIds.push(item.champ_perso.champ_perso_id);
      });
    }

    this.props.formRefRecherche.current.setFieldsValue({
      contrat_alur: recherche.recherche_alur,
      contrat_court_terme: recherche.recherche_court_terme,
      contrat_long_terme: recherche.recherche_long_terme,
      dates,
      type_contrat: recherche.recherche_type_contrat,
      prix_min: recherche.recherche_prix_min,
      prix_max: recherche.recherche_prix_max,
      couchages: recherche.recherche_couchages,
      type_lot: recherche.recherche_type_lot
        ? recherche.recherche_type_lot.split(",").map(Number)
        : [],
      quartier: quartiersIds,
      metro: metrosIds,
      surfaces: recherche.recherche_surfaces ? recherche.recherche_surfaces.split(",") : [50, 100],
    });

    this.setState({
      alur: recherche.recherche_alur,
      courtTerme: recherche.recherche_court_terme,
      longTerme: recherche.recherche_long_terme,
    });

    if (!recherche.recherche_equipement) return;
    this.props.equipements.forEach((item) => {
      this.props.formRefRecherche.current.setFieldsValue({
        [`equipement_${item.equipement_id}`]: false,
      });
    });
    recherche.recherche_equipement.forEach((item) =>
      this.props.formRefRecherche.current.setFieldsValue({
        [`equipement_${item.recherche_equipement_equipement_id}`]: true,
      })
    );
  };

  onReset = () => {
    this.props.formRefRecherche.current.resetFields();
  };

  onFinishFormRecherche = (data) => {
    if (data.submit_recherche) {
      this.saveRecherche(data);
    } else {
      if (!this.props.requete) {
        // this.props.formRefRequete.current.submit();
        this.handleFormRecherche(data);
      } else {
        if (this.props.requetesLots.filter((req) => req.requete_lot_type === 1).length === 0) {
          this.handleFormRecherche(data);
        } else {
          const first = this.props.requetesLots[0];
          if (!this.checkContrat(data, first) || !this.checkDates(data.dates, first)) {
            Modal.confirm({
              title: "Confirmation",
              content:
                "Il y a des appartements sélectionnés, une nouvelle recherche remettra cette liste à zéro",
              okText: "Ok",
              cancelText: "Annuler",
              onOk: () => {
                this.props.deleteAllRequeteLot(this.props.requete.requete_id);
                this.handleFormRecherche(data);
              },
            });
          } else {
            this.handleFormRecherche(data);
          }
        }
      }
    }
  };

  checkContrat = (data, first) => {
    return (
      data.contrat_alur === first.requete_lot_lot.lot_alur &&
      data.contrat_court_terme === first.requete_lot_lot.lot_court_terme &&
      data.contrat_long_terme === first.requete_lot_lot.lot_long_terme
    );
  };

  checkDates = (dates, first) => {
    if (this.state.alur) {
      return dates.isSame(first.requete_lot_date_debut, "d");
    } else {
      return (
        dates[0].isSame(first.requete_lot_date_debut, "d") &&
        dates[1].isSame(first.requete_lot_date_fin, "d")
      );
    }
  };

  getAnnee = (idLot) => {
    const locationLignes = [];

    const lot = this.props.lots.find((lot) => lot.lot_id === idLot);

    lot.lot_matrice.matrice_ligne.forEach((item) => {
      const locationLigne = {
        location_ligne_pourcentage_acompte: parseFloat(
          item.matrice_ligne_rubrique.rubrique_pourcentage_acompte
        ),
        location_ligne_montant: parseFloat(item.matrice_ligne_montant_mois),
        location_ligne_rubrique_id: item.matrice_ligne_rubrique.rubrique_id,
        location_ligne_permanente: item.matrice_ligne_rubrique.rubrique_permanente,
      };
      locationLignes.push(locationLigne);
    });

    return locationLignes;
  };

  getNuites = (idLot, dateDebut, dateFin) => {
    const tarifLignes = [];

    const dates = this.getAllDates(dateDebut, dateFin);

    const lot = this.props.lots.find((lot) => lot.lot_id === idLot);

    lot &&
      dates.forEach((date) => {
        const special = lot.lot_nuites.filter((nuite) =>
          dayjs(date, "DD/MM/YYYY").isBetween(
            dayjs(nuite.nuite_date_debut, "YYYY-MM-DD"),
            dayjs(nuite.nuite_date_fin, "YYYY-MM-DD"),
            null,
            "[]"
          )
        );

        if (special.length > 0) {
          special.forEach((nuite) => {
            const foundIndex = tarifLignes.findIndex(
              (x) => x.location_ligne_rubrique_id === nuite.nuite_rubrique.rubrique_id
            );

            if (foundIndex !== -1 && nuite.nuite_rubrique.rubrique_montant_fixe === false)
              tarifLignes[foundIndex].location_ligne_montant += parseInt(nuite.nuite_montant);
            else if (foundIndex === -1) {
              const tarifLigne = {
                // location_ligne_location_id: this.props.location.location_id,
                location_ligne_pourcentage_acompte: 0,
                location_ligne_montant: parseFloat(nuite.nuite_montant),
                location_ligne_rubrique_id: nuite.nuite_rubrique.rubrique_id,
                location_ligne_permanente: false,
              };
              tarifLignes.push(tarifLigne);
            }
          });
        } else {
          lot.lot_prix_base
            .filter((prixBase) => !prixBase.prix_base_proprietaire)
            .forEach((prixBase) => {
              const foundIndex = tarifLignes.findIndex(
                (x) => x.location_ligne_rubrique_id === prixBase.prix_base_rubrique.rubrique_id
              );
              if (foundIndex !== -1 && prixBase.prix_base_rubrique.rubrique_montant_fixe === false)
                tarifLignes[foundIndex].location_ligne_montant += parseInt(
                  prixBase.prix_base_montant
                );
              else if (foundIndex === -1) {
                const tarifLigne = {
                  // location_ligne_location_id: this.props.location.location_id,
                  location_ligne_pourcentage_acompte: 0,
                  location_ligne_montant: parseFloat(prixBase.prix_base_montant),
                  location_ligne_rubrique_id: prixBase.prix_base_rubrique.rubrique_id,
                  location_ligne_permanente: false,
                };
                tarifLignes.push(tarifLigne);
              }
            });
        }
      });
    return tarifLignes;
  };

  getAllDates = (debut, fin) => {
    const dates = [];
    let start = dayjs(debut, "YYYY-MM-DD");
    while (start.isBefore(dayjs(fin, "YYYY-MM-DD"))) {
      dates.push(start);
      start = start.add(1, "day");
    }
    return dates;
  };

  handleFormRecherche = (data) => {
    if (this.isPeriodePassee(data.dates))
      return this.props.fillResultats([], this.props.requete, null, null);

    this.props.requete && this.saveRecherche(data);

    let resultats = this.props.lots.filter((item) => !item.lot_fin);

    resultats = this.filterDate(resultats, data.dates);
    resultats = this.filterTypeContrat(resultats, data.type_contrat);
    resultats = this.filterCouchages(resultats, data.couchages);
    resultats = this.filterSurface(resultats, data.surfaces);
    resultats = this.filterTypeLot(resultats, data.type_lot);
    resultats = this.filterEquipements(resultats, data);
    resultats = this.filterVille(resultats, data.ville);
    resultats = this.filterQuartier(resultats, data.quartier);
    resultats = this.filterMetro(resultats, data.metro);

    resultats.forEach((item) => {
      let lignes = [];

      let idLot = item.lot_id;
      let dateDebut = dayjs(data.dates[0]).format("YYYY-MM-DD");
      let dateFin = dayjs(data.dates[1]).format("YYYY-MM-DD");

      lignes =
        this.state.alur || this.state.longTerme
          ? this.getAnnee(idLot)
          : this.getNuites(idLot, dateDebut, dateFin);

      item.loyer = 0;
      item.charges = 0;

      lignes.forEach((i) => {
        item.loyer += parseFloat(i.location_ligne_montant);
      });
    });

    /* Traitement des options */
    resultats.forEach((lot) => {
      this.props.requetesLots.some((item) => item.requete_lot_lot.lot_id === lot.lot_id)
        ? (lot.dispo = constants.LOT_DISPONIBLE)
        : (lot.dispo = constants.LOT_SANS_CORRESPONDANCE);
      const location = this.props.locations.find(
        (item) =>
          item.location_lot.lot_id === lot.lot_id &&
          (dayjs(item.location_date_debut).isBetween(
            data.dates[0],
            data.dates[1],
            undefined,
            "[)"
          ) ||
            dayjs(item.location_date_fin).isBetween(
              data.dates[0],
              data.dates[1],
              undefined,
              "(]"
            )) &&
          item.location_etat === "o"
      );
      if (location) {
        lot.dispo = constants.LOT_OPTION;
      }
    });

    /* MAJ requetesLots */
    this.props.requetesLots.forEach((req) => {
      let lot = req.requete_lot_lot;
      if (!resultats.some((item) => item.lot_id === lot.lot_id)) {
        lot.dispo = constants.LOT_NON_DISPONIBLE;
        req.requete_lot_etat = constants.LOT_NON_DISPONIBLE.toString();
        this.props.updateRequeteLot(
          req,
          req.requete_lot_requete.requete_id,
          req.requete_lot_lot.lot_id,
          req.requete_lot_type
        );
        resultats.unshift(lot);
      } else {
        const resultat = resultats.find((item) => item.lot_id === lot.lot_id);
        resultat && resultat.dispo
          ? (req.requete_lot_etat = resultat.dispo.toString())
          : (req.requete_lot_etat = constants.LOT_SANS_CORRESPONDANCE);
        this.props.updateRequeteLot(
          req,
          req.requete_lot_requete.requete_id,
          req.requete_lot_lot.lot_id,
          req.requete_lot_type
        );
      }
    });

    this.props.fillResultats(resultats, this.props.requete, data.dates, this.props.requetesLots);
  };

  saveRecherche = (data) => {
    let equipements = [];
    this.props.equipements.forEach((item) => {
      if (data["equipement_" + item.equipement_id]) equipements.push(item.equipement_id);
    });

    const jsonData = {
      recherche_alur: this.state.alur,
      recherche_court_terme: this.state.courtTerme,
      recherche_long_terme: this.state.longTerme,
      recherche_date_debut: this.state.alur
        ? data.dates && data.dates !== undefined
          ? formatDateToBDD(data.dates)
          : null
        : data.dates && data.dates !== undefined
        ? formatDateToBDD(data.dates[0])
        : null,
      recherche_date_fin: this.state.alur
        ? null
        : data.dates && data.dates !== undefined
        ? formatDateToBDD(data.dates[1])
        : null,
      recherche_type_contrat: parseInt(data.type_contrat),
      recherche_prix_min: data.prix_min,
      recherche_prix_max: data.prix_max,
      recherche_couchages: parseInt(data.couchages),
      recherche_surfaces: `${data.surfaces}`,
      recherche_type_lot: data.type_lot !== undefined ? `${data.type_lot}` : null,
      recherche_requete_id: this.props.requete.requete_id,
      recherche_equipement: equipements,
      recherche_quartier: data.quartier ? data.quartier : [],
      recherche_metro: data.metro ? data.metro : [],
    };

    this.props
      .saveRecherche(jsonData)
      .then((res) => {
        this.setState({
          alur: res.recherche_alur,
          courtTerme: res.recherche_court_terme,
          longTerme: res.recherche_long_terme,
        });

        this.props.formRefRecherche.current.setFieldsValue({
          submit_recherche: false,
        });
      })
      .catch((err) => console.log("err", err));
  };

  filterDate = (resultats, dates) => {
    const etats = ["r", "b", "n"];
    const ids = [];
    resultats.forEach((lot) => {
      const location = this.props.locations.find(
        (item) =>
          item.location_lot.lot_id === lot.lot_id &&
          etats.includes(item.location_etat) &&
          (dayjs(dates[0]).isBetween(
            dayjs(item.location_date_debut, "YYYY-MM-DD"),
            dayjs(item.location_date_fin, "YYYY-MM-DD"),
            undefined,
            "[)"
          ) ||
            dayjs(dates[1]).isBetween(
              dayjs(item.location_date_debut, "YYYY-MM-DD"),
              dayjs(item.location_date_fin, "YYYY-MM-DD"),
              undefined,
              "(]"
            ) ||
            dayjs(item.location_date_debut, "YYYY-MM-DD").isBetween(
              dayjs(dates[0]),
              dayjs(dates[1]),
              undefined,
              "[)"
            ) ||
            dayjs(item.location_date_fin, "YYYY-MM-DD").isBetween(
              dayjs(dates[0]),
              dayjs(dates[1]),
              undefined,
              "(]"
            ))
      );
      if (location !== undefined) {
        ids.push(lot.lot_id);
      }
    });

    return resultats.filter((lot) => !ids.includes(lot.lot_id));
  };

  filterTypeContrat = (resultats) => {
    if (this.state.courtTerme) resultats = resultats.filter((item) => item.lot_court_terme);
    else if (this.state.alur && this.state.longTerme)
      resultats = resultats.filter((item) => item.lot_alur || item.lot_long_terme);
    else if (this.state.alur) resultats = resultats.filter((item) => item.lot_alur);
    else if (this.state.longTerme) resultats = resultats.filter((item) => item.lot_long_terme);

    return resultats;
  };

  filterCouchages = (resultats, couchages) => {
    if (couchages !== undefined) {
      resultats = resultats.filter((item) => item.lot_couchage >= couchages);
    }

    return resultats;
  };

  filterSurface = (resultats, surfaces) => {
    surfaces = [0, 100]; // TODO: Renaud
    resultats = resultats.filter(
      (item) =>
        (surfaces[0] <= item.lot_surface && item.lot_surface <= surfaces[1]) ||
        (surfaces[0] <= item.lot_surface && surfaces[1] === 100)
    );

    return resultats;
  };

  filterTypeLot = (resultats, type) => {
    if (type !== undefined && type.length > 0) {
      resultats = resultats.filter((item) => type.includes(item.lot_type_lot.type_lot_id));
    }

    return resultats;
  };

  filterEquipements = (resultats, data) => {
    this.props.equipements.forEach((item) => {
      if (data["equipement_" + item.equipement_id]) {
        resultats = resultats.filter((item1) => {
          return item1.lot_equipements.some((a) => a["equipement_id"] === item.equipement_id);
        });
      }
    });

    return resultats;
  };

  filterVille = (resultats, ville) => {
    if (ville !== undefined) {
      resultats = resultats.filter((item) =>
        item.lot_adresse.adresse_ville.toLowerCase().includes(ville.toLowerCase())
      );
    }

    return resultats;
  };

  filterQuartier = (resultats, quartiers) => {
    if (quartiers && quartiers.length && quartiers !== undefined) {
      resultats = resultats.filter((item) => {
        return item.lot_quartiers.some((e) => {
          return quartiers.includes(e.champ_perso_id);
        });
      });
    }

    return resultats;
  };

  filterMetro = (resultats, metros) => {
    if (metros && metros.length && metros !== undefined) {
      resultats = resultats.filter((item) => {
        return item.lot_metros.some((e) => {
          return metros.includes(e.champ_perso_id);
        });
      });
    }

    return resultats;
  };

  onChangeAlur = (checked) => {
    if (checked) {
      this.checkDates(checked, this.state.longTerme);
      this.setState({ alur: checked, courtTerme: !checked }, () => {
        this.props.formRefRecherche.current.setFieldsValue({
          contrat_alur: checked,
          contrat_court_terme: !checked,
        });
      });
    } else {
      this.checkDates(checked, this.state.longTerme);
      this.setState({ alur: checked });
    }
  };

  onChangeLongTerme = (checked) => {
    if (checked) {
      this.checkDates(this.state.alur, checked);
      this.setState({ longTerme: checked, courtTerme: !checked }, () => {
        this.props.formRefRecherche.current.setFieldsValue({
          contrat_long_terme: checked,
          contrat_court_terme: !checked,
        });
      });
    } else {
      this.checkDates(this.state.alur, checked);
      this.setState({ longTerme: checked });
    }
  };

  onChangeCourtTerme = (checked) => {
    if (checked) {
      this.checkDates(!checked, !checked);
      this.setState(
        {
          alur: !checked,
          longTerme: !checked,
          courtTerme: checked,
        },
        () => {
          this.props.formRefRecherche.current.setFieldsValue({
            contrat_alur: !checked,
            contrat_long_terme: !checked,
            contrat_court_terme: checked,
          });
        }
      );
    } else {
      this.checkDates(this.state.alur, this.state.longTerme);
      this.setState({ courtTerme: checked });
    }
  };

  checkDates = (alur, lt) => {
    if (alur || (alur && lt)) {
      this.props.formRefRecherche.current.setFieldsValue({ dates: dayjs() });
    } else {
      this.props.formRefRecherche.current.setFieldsValue({
        dates: [dayjs(), dayjs().add(1, "days")],
      });
    }
  };

  isPeriodePassee = (dates) => {
    return false;
  };

  onDateRangeChange = (e) => {
    this.props.formRefRecherche.current.setFieldsValue({
      dates: [e[0], null],
    });
  };

  render() {
    const { alur, metros, quartiers, longTerme, courtTerme } = this.state;
    const { home, isArchive, typesLots, utilisateur, equipements } = this.props;

    const order = {
      alur: home ? 1 : 1,
      longTerme: home ? 2 : 2,
      courtTerme: home ? 3 : 3,
      blankFirstLine: home ? 0 : 4,
      dates: home ? 4 : 5,
      typeContrat: home ? 5 : 0,
      prixMin: home ? 6 : 13,
      prixMax: home ? 7 : 14,
      nbPax: home ? 8 : 10,
      typeLot: home ? 9 : 9,
      surface: home ? 10 : 12,
      quartier: home ? 11 : 8,
      metro: home ? 12 : 0,
      ville: home ? 13 : 7,
      nbPieces: home ? 15 : 11,
      blank: home ? 16 : 6,
      equipements: home ? 17 : 15,
    };

    const width = {
      alur: home ? 2 : 2,
      longTerme: home ? 2 : 2,
      courtTerme: home ? 2 : 2,
      blankFirstLine: home ? 0 : 18,
      dates: home ? 6 : 6,
      typeContrat: home ? 3 : 2,
      prixMin: home ? 3 : 2,
      prixMax: home ? 3 : 2,
      nbPax: home ? 2 : 2,
      typeLot: home ? 3 : 2,
      surface: home ? 3 : 2,
      quartier: home ? 3 : 3,
      metro: home ? 3 : 3,
      ville: home ? 3 : 3,
      nbPieces: home ? 3 : 2,
      blank: home ? 0 : 0,
      equipements: home ? 18 : 24,
    };

    return (
      <Form
        onFinish={this.onFinishFormRecherche}
        className="form-requete"
        ref={this.props.formRefRecherche}
        size={home ? "small" : "middle"}
      >
        <Card style={{ marginTop: "0px" }} className="requete-card" bordered={false} size="small">
          <Form.Item name="submit_recherche" style={{ display: "none" }} initialValue={false}>
            <Input />
          </Form.Item>
          <Row style={{ display: "flex" }}>
            <Col span={width.alur} order={order.alur}>
              <Form.Item name="contrat_alur" initialValue={alur}>
                <Switch
                  disabled={isArchive}
                  className="switch-contrat"
                  checked={alur}
                  onChange={this.onChangeAlur}
                  style={{ height: "30px" }}
                  checkedChildren="Alur"
                  unCheckedChildren="Alur"
                />
              </Form.Item>
            </Col>
            <Col span={width.longTerme} order={order.longTerme}>
              <Form.Item name="contrat_long_terme" initialValue={longTerme}>
                <Switch
                  disabled={isArchive}
                  className="switch-contrat"
                  checked={longTerme}
                  onChange={this.onChangeLongTerme}
                  style={{ height: "30px" }}
                  checkedChildren="Long terme"
                  unCheckedChildren="Long terme"
                />
              </Form.Item>
            </Col>
            <Col span={width.courtTerme} order={order.courtTerme}>
              <Form.Item name="contrat_court_terme" initialValue={courtTerme}>
                <Switch
                  disabled={isArchive}
                  className="switch-contrat"
                  checked={courtTerme}
                  onChange={this.onChangeCourtTerme}
                  style={{ height: "30px" }}
                  checkedChildren="Court terme"
                  unCheckedChildren="Court terme"
                />
              </Form.Item>
            </Col>
            <Col span={width.dates} order={order.dates}>
              <Form.Item
                name="dates"
                rules={[
                  {
                    required: true,
                    message: "Date obligatoire",
                  },
                ]}
                initialValue={dayjs()}
              >
                {alur || (alur && longTerme) ? (
                  <DatePicker disabled={isArchive} format={dateFormat} />
                ) : (
                  <RangePicker
                    disabled={isArchive}
                    format={dateFormat}
                    onCalendarChange={this.onDateRangeChange}
                  />
                )}
              </Form.Item>
            </Col>
            {home && (
              <Col span={width.typeContrat} order={order.typeContrat}>
                <Form.Item name="type_contrat">
                  <Select disabled={isArchive} optionLabelProp="label" placeholder="TYPE CONTRAT">
                    <Option label="ALUR" value={1}>
                      ALUR
                    </Option>
                    <Option label="ÉTUDIANT" value={2}>
                      ÉTUDIANT
                    </Option>
                    <Option label="CODE CIVIL" value={3}>
                      CODE CIVIL
                    </Option>
                    <Option label="MOBILITÉ" value={4}>
                      MOBILITÉ
                    </Option>
                    <Option label="COURTE DURÉE" value={5}>
                      COURTE DURÉE
                    </Option>
                    <Option label="BAIL SOCIÉTÉ TACITE" value={6}>
                      BAIL SOCIÉTÉ TACITE
                    </Option>
                    <Option label="BAIL SOCIÉTÉ NON TACITE" value={7}>
                      BAIL SOCIÉTÉ NON TACITE
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col span={width.prixMin} order={order.prixMin}>
              <Form.Item name="prix_min">
                <InputNumber
                  disabled={isArchive}
                  style={{ width: "100%" }}
                  placeholder="PRIX MIN"
                />
              </Form.Item>
            </Col>
            <Col span={width.prixMax} order={order.prixMax}>
              <Form.Item name="prix_max">
                <InputNumber
                  disabled={isArchive}
                  style={{ width: "100%" }}
                  placeholder="PRIX MAX"
                />
              </Form.Item>
            </Col>
            <Col span={width.nbPax} order={order.nbPax}>
              <Form.Item name="couchages">
                <Select
                  disabled={isArchive}
                  allowClear
                  optionLabelProp="label"
                  placeholder="PERSONNES"
                  style={{ width: "100%" }}
                >
                  <OptGroup label="Nombre">
                    <Option label="1 Personne" value={1}>
                      1 Personne
                    </Option>
                    <Option label="2 Personnes" value={2}>
                      2 Personnes
                    </Option>
                    <Option label="3 Personnes" value={3}>
                      3 Personnes
                    </Option>
                    <Option label="4 Personnes" value={4}>
                      4 Personnes
                    </Option>
                    <Option label="5 Personnes" value={5}>
                      5 Personnes
                    </Option>
                    <Option label="6 Personnes" value={6}>
                      6 Personnes
                    </Option>
                    <Option label="7 Personnes" value={7}>
                      7 Personnes
                    </Option>
                    <Option label="8 Personnes" value={8}>
                      8 Personnes
                    </Option>
                    <Option label="9 Personnes" value={9}>
                      9 Personnes
                    </Option>
                    <Option label="10+ Personnes" value={10}>
                      10+ Personnes
                    </Option>
                  </OptGroup>
                </Select>
              </Form.Item>
            </Col>
            <Col span={width.typeLot} order={order.typeLot}>
              <Form.Item name="type_lot">
                <Select
                  showSearch
                  optionFilterProp="label"
                  disabled={isArchive}
                  mode="multiple"
                  optionLabelProp="label"
                  placeholder="TYPE"
                  style={{ width: "100%" }}
                  maxTagCount={2}
                >
                  <OptGroup label="Type">
                    {typesLots &&
                      typesLots.map((item, i) => {
                        return (
                          <Option key={i} value={item.type_lot_id} label={item.type_lot_libelle}>
                            <Row>
                              <Col sm={8}>{item.type_lot_libelle}</Col>
                            </Row>
                          </Option>
                        );
                      })}
                  </OptGroup>
                </Select>
              </Form.Item>
            </Col>
            <Col span={width.surface} order={order.surface}>
              <Form.Item name="surface">
                <InputNumber disabled={isArchive} style={{ width: "100%" }} placeholder="SURFACE" />
              </Form.Item>
            </Col>
            <Col span={width.quartier} order={order.quartier}>
              <Form.Item name="quartier">
                <Select
                  showSearch
                  optionFilterProp="label"
                  disabled={isArchive}
                  mode="multiple"
                  maxTagCount={2}
                  placeholder="QUARTIER"
                  optionLabelProp="label"
                  style={{ width: "100%" }}
                >
                  <OptGroup label="Type">
                    {quartiers &&
                      quartiers.map((item, i) => {
                        return (
                          <Option
                            key={i}
                            value={item.champ_perso_id}
                            label={item.champ_perso_libelle}
                          >
                            <Row>
                              <Col sm={8}>{item.champ_perso_libelle}</Col>
                            </Row>
                          </Option>
                        );
                      })}
                  </OptGroup>
                </Select>
              </Form.Item>
            </Col>
            {home && (
              <Col span={width.metro} order={order.metro}>
                <Form.Item name="metro">
                  <Select
                    showSearch
                    optionFilterProp="label"
                    disabled={isArchive}
                    mode="multiple"
                    maxTagCount={3}
                    placeholder="METRO"
                    optionLabelProp="label"
                    style={{ width: "100%" }}
                  >
                    <OptGroup label="Type">
                      {metros &&
                        metros.map((item, i) => {
                          return (
                            <Option
                              key={i}
                              value={item.champ_perso_id}
                              label={item.champ_perso_libelle}
                            >
                              <Row>
                                <Col sm={8}>{item.champ_perso_libelle}</Col>
                              </Row>
                            </Option>
                          );
                        })}
                    </OptGroup>
                  </Select>
                </Form.Item>
              </Col>
            )}
            {!home && (
              <Col span={width.ville} order={order.ville}>
                <Form.Item name="ville">
                  <Input disabled={isArchive} style={{ width: "100%" }} placeholder="VILLE" />
                </Form.Item>
              </Col>
            )}
            {!home && (
              <Col span={width.nbPieces} order={order.nbPieces}>
                <Form.Item name="nb_pieces">
                  <InputNumber
                    disabled={isArchive}
                    style={{ width: "100%" }}
                    placeholder="NB PIECES"
                  />
                </Form.Item>
              </Col>
            )}
            {!home && <Col span={width.blank} order={order.blank}></Col>}
            {!home && <Col span={width.blankFirstLine} order={order.blankFirstLine}></Col>}
            <Col
              span={width.equipements}
              order={order.equipements}
              className={home ? "" : "form-large"}
              style={{ marginTop: 10 }}
            >
              {/* 1 / 2 / 3 / 4 / 6 / 8 / 12 / 24 */}
              <FormEquipement
                eachRow={8}
                fromAppt={false}
                isArchive={isArchive}
                utilisateur={utilisateur}
                equipements={equipements}
              />
            </Col>
          </Row>
        </Card>
        <Row className={home ? "recherche-validation center" : "recherche-validation right"}>
          <Col span={24}>
            <Button disabled={isArchive} size="middle" onClick={this.onReset}>
              Réinitialiser
            </Button>
            <Button
              disabled={isArchive}
              type="primary"
              size="middle"
              style={{ marginLeft: "10px" }}
              htmlType="submit"
              icon={<SearchOutlined />}
            >
              Rechercher
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default FormRecherche;

import React, { Component } from "react";
import { Table, Button, Input, DatePicker, Space, Typography } from "antd";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

import { toast } from "react-toastify";

import dayjs from "dayjs";

const dateFormat = "DD/MM/YYYY";

const { Column } = Table;
const { Text } = Typography;

class TableEcriture extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ecritures: [],
      searchText: "",
      searchedColumn: "",
    };
  }

  datePickerRef = React.createRef();

  componentDidMount() {
    this.init(this.props);
  }
  UNSAFE_componentWillReceiveProps(props) {
    this.init(props);
  }

  init = (props) => {
    this.setState({ ecritures: props.ecritures });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Rechercher ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Rechercher
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => this.onSearch(value, record, dataIndex),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  getColumnSearchPropsDate = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <DatePicker
          ref={this.datePickerRef}
          placeholder={`Rechercher ${dataIndex}`}
          format={dateFormat}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e ? [e] : [])}
          onPressEnter={() => this.handleSearchDate(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearchDate(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Rechercher
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => this.searchDate(value, record, dataIndex),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.datePickerRef.current.focus(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleSearchDate = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0].format("DD/MM/YYYY"),
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  onSearch = (value, record, dataIndex) => {
    let result = null;
    if (dataIndex.includes("montant")) {
      let montant = 0;
      record.ecriture_ligne.forEach((ligne) => {
        montant += parseFloat(ligne.ecriture_ligne_montant_debit);
      });
      result = parseFloat(value) === parseFloat(montant);
    } else if (dataIndex.includes("ecriture_id")) {
      result = parseInt(value) === record.ecriture_id;
    } else {
      result = record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
    }
    return result ? result : "";
  };

  searchDate = (record, value, dataIndex) => {
    return record
      ? record.startOf("day").isSame(dayjs(value[dataIndex], "DD/MM/YYYY").startOf("day"))
      : "";
  };

  handleEditEcriture = (e, ecriture) => {
    const admin = this.props.utilisateur.utilisateur_admin;
    if (
      ecriture.ecriture_ligne.some(
        (ligne) => ligne.ecriture_ligne_lettrage !== "" && ligne.ecriture_ligne_lettrage !== null
      ) &&
      !admin
    ) {
      toast.error("Écriture pointée, modification impossible", {
        containerId: "A",
      });
    } else if (
      ecriture.ecriture_ligne.some((ligne) => ligne.ecriture_ligne_en_reddition === true) &&
      !admin
    ) {
      toast.error("Écriture en reddition, modification impossible", {
        containerId: "A",
      });
    } else {
      const pane = { key: "35", title: "Modification Ecriture", content: "" };
      this.props.addPanesEcriture(pane, "35", ecriture);
    }
  };

  render() {
    const { ecritures } = this.state;

    const expandedRowRender = (row) => {
      return (
        <Table
          dataSource={row.ecriture_ligne}
          pagination={false}
          rowKey="ecriture_ligne_id"
          summary={(pageData) => {
            let totalDebit = 0;
            let totalCredit = 0;

            pageData.forEach(({ ecriture_ligne_montant_debit, ecriture_ligne_montant_credit }) => {
              totalDebit += parseFloat(ecriture_ligne_montant_debit);
              totalCredit += parseFloat(ecriture_ligne_montant_credit);
            });

            return (
              <>
                <Table.Summary.Row style={{ background: "#fafafa" }}>
                  <Table.Summary.Cell>Total</Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text>
                      {parseFloat(totalDebit).toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      })}{" "}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text>
                      {parseFloat(totalCredit).toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      })}{" "}
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        >
          <Column
            title="Compte Général"
            dataIndex="ecriture_ligne_compte"
            key="ecriture_ligne_compte.compte_compte_general"
            render={(compte) => <>{compte.compte_compte_general}</>}
          />
          <Column
            title="Compte Auxiliaire"
            dataIndex="ecriture_ligne_compte"
            key="ecriture_ligne_compte.compte_compte_auxiliaire"
            render={(compte) => <>{compte.compte_compte_auxiliaire}</>}
          />
          <Column
            title="Libellé Compte"
            dataIndex="ecriture_ligne_compte"
            key="ecriture_ligne_compte.compte_libelle"
            render={(compte) => <>{compte.compte_libelle}</>}
          />
          <Column title="Libellé" dataIndex="ecriture_ligne_libelle" key="ecriture_ligne_libelle" />
          <Column
            title="Montant Débit"
            dataIndex="ecriture_ligne_montant_debit"
            key="ecriture_ligne_montant_debit"
            render={(montant) =>
              parseFloat(montant) !== 0 ? (
                <>
                  {parseFloat(montant).toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </>
              ) : (
                <></>
              )
            }
          />
          <Column
            title="Montant Crédit"
            dataIndex="ecriture_ligne_montant_credit"
            key="ecriture_ligne_montant_credit"
            render={(montant) =>
              parseFloat(montant) !== 0 ? (
                <>
                  {parseFloat(montant).toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </>
              ) : (
                <></>
              )
            }
          />
        </Table>
      );
    };

    return (
      <div>
        {ecritures && (
          <Table
            className="table-ecritures"
            expandable={{ expandedRowRender }}
            dataSource={ecritures}
            rowKey="ecriture_id"
          >
            <Column
              title="Numéro"
              dataIndex="ecriture_id"
              key="ecriture_id"
              {...this.getColumnSearchProps("ecriture_id")}
              defaultSortOrder="descend"
              sorter={(a, b) => a.ecriture_id - b.ecriture_id}
              width="10%"
            />
            <Column
              title="Date comptable"
              dataIndex="ecriture_date_ecriture"
              key="ecriture_date_ecriture"
              {...this.getColumnSearchPropsDate("ecriture_date_ecriture")}
              sorter={(a, b) =>
                dayjs(a.ecriture_date_ecriture, "DD/MM/YYYY").unix() -
                dayjs(b.ecriture_date_ecriture, "DD/MM/YYYY").unix()
              }
              width="10%"
            />
            <Column
              title="Date saisie"
              dataIndex="ecriture_date_saisie"
              key="ecriture_date_saisie"
              {...this.getColumnSearchPropsDate("ecriture_date_saisie")}
              sorter={(a, b) =>
                dayjs(a.ecriture_date_saisie, "DD/MM/YYYY").unix() -
                dayjs(b.ecriture_date_saisie, "DD/MM/YYYY").unix()
              }
              width="10%"
            />
            <Column
              title="Libellé"
              dataIndex="ecriture_libelle"
              key="ecriture_libelle"
              {...this.getColumnSearchProps("ecriture_libelle")}
              width="30%"
            />
            <Column
              title="Journal"
              dataIndex="ecriture_journal"
              key="ecriture_journal"
              render={(journal) =>
                journal && (
                  <>
                    {journal.journal_code} - {journal.journal_libelle}
                  </>
                )
              }
              width="20%"
            />
            <Column
              title="Montant"
              dataIndex="ecriture_ligne"
              key="ecriture_ligne"
              {...this.getColumnSearchProps("montant")}
              render={(ecriture_ligne) => {
                let montant = 0;
                ecriture_ligne.forEach((ligne) => {
                  montant += parseFloat(ligne.ecriture_ligne_montant_debit);
                });
                return (
                  <>
                    {parseFloat(montant).toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    })}
                  </>
                );
              }}
              width="10%"
            />
            <Column
              title="Modification"
              dataIndex="ecriture_id"
              key="ecriture_id"
              render={(ecriture_id, row) => (
                <Button onClick={(e) => this.handleEditEcriture(e, row)}>
                  <EditOutlined />
                </Button>
              )}
              width="10%"
            />
          </Table>
        )}
      </div>
    );
  }
}

export default TableEcriture;

import React, { Component } from "react";
import { connect } from "react-redux";

import { Card, Spin } from "antd";
import ListLot from "../../components/ListLot";
import { columns } from "./columns";
import { loadLots } from "../../store/actions/lotAction";

class LotsListe extends Component {
  componentDidMount() {
    // const groupement_id = this.props.utilisateur.groupement_id;
    const societe_id = this.props.utilisateur.societe_id;
    this.props.loadLots(societe_id);
  }

  render() {
    const { lots } = this.props;

    return (
      <Spin size="large" tip="Chargement en cours..." spinning={!lots}>
        <Card title="" bordered={false} className="card-panel">
          <ListLot
            listData={
              lots &&
              lots
                .filter((item) => !item.lot_fin)
                .sort((a, b) => a.lot_reference.localeCompare(b.lot_reference))
            }
            tableOption={columns}
          />
        </Card>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadLots: (societe_id) => dispatch(loadLots(societe_id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    lots: state.lots.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LotsListe);

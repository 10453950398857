import React, { Component } from "react";

import { Dropdown, Tree } from "antd";
import { DownOutlined } from "@ant-design/icons";

import dayjs from "dayjs";

class MyTreeSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedKeys: [],
      requete: null,
    };
  }

  componentDidMount() {
    this.loopKeys(this.props.treeEtapes);
  }

  UNSAFE_componentWillReceiveProps() {
    this.loopKeys(this.props.treeEtapes);
  }

  onRightClick = (event) => {
    const requete = {
      prenom: event.node.prenom,
      nom: event.node.nom,
      libelle: event.node.libelle,
      groupe: event.node.groupe,
      updated_at: dayjs(event.node.updated_at).format("DD/MM/YYYY"),
    };
    event.node.libelle !== undefined
      ? this.setState({ requete: requete })
      : this.setState({ requete: null });
  };

  loopKeys = (treeEtapes) => {
    treeEtapes.forEach((item) => {
      this.state.expandedKeys.push(`${item.etape_id}`);
      if (item.children && item.children.length) this.loopKeys(item.children);
    });
    this.setState({ expandedKeys: this.state.expandedKeys });
  };

  getMenu = () => {
    return [
      {
        key: "1",
        label: `Groupe de la requête: ${this.state.requete?.groupe}`,
      },
      {
        key: "2",
        label: `Arrivée dans l'étape le ${this.state.requete?.updated_at}`,
      },
    ];
  };

  render() {
    const { expandedKeys } = this.state;
    const { treeEtapes } = this.props;

    return (
      <div>
        {expandedKeys.length > 0 && (
          <Dropdown menu={{ items: this.getMenu() }} trigger={["contextMenu"]}>
            <div>
              <Tree
                showIcon
                showLine
                switcherIcon={<DownOutlined />}
                multiple={false}
                defaultExpandAll
                treeData={treeEtapes}
                className="draggable-tree"
                defaultExpandedKeys={expandedKeys}
                onSelect={this.props.handleSelectRequete}
                onRightClick={this.onRightClick}
              />
            </div>
          </Dropdown>
        )}
      </div>
    );
  }
}

export default MyTreeSidebar;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Spin } from "antd";

import ListHistorique from "../../components/ListHistorique";
import { columns } from "./columns";

import { loadLocations } from "../../store/actions/locationAction";
import { loadRenduAsHistorique } from "../../store/actions/renduAHistoriqueAction";

class Historique extends Component {
  componentDidMount() {
    const groupement_id = this.props.utilisateur.groupement_id;
    const etablissement_id = this.props.utilisateur.etablissement_id;
    this.props.loadLocations(groupement_id);
    this.props.loadRenduAsHistorique(etablissement_id);
  }

  render() {
    const { locations, renduAsHistorique } = this.props;

    return (
      <Spin spinning={!locations || !renduAsHistorique} size="large" tip="Chargement en cours...">
        <Card title="" bordered={false} className="card-panel">
          <ListHistorique
            listData={
              renduAsHistorique &&
              renduAsHistorique.sort((a, b) => b.historique_id - a.historique_id)
            }
            locations={locations}
            tableOption={columns}
          />
        </Card>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadLocations: (groupement_id) => dispatch(loadLocations(groupement_id)),
    loadRenduAsHistorique: (etablissement_id) => dispatch(loadRenduAsHistorique(etablissement_id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    locations: state.locations.data,
    renduAsHistorique: state.renduAsHistorique.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Historique);

import React, { Component } from "react";

export default class BalancePDF extends Component {
  render() {
    const {
      balanceComptes,
      dateDu,
      dateAu,
      compteCentralisateur,
      compteDu,
      compteAu,
      comptes,
      tri,
    } = this.props;

    if (tri === 2) {
      balanceComptes.sort((a, b) => a.compte_libelle.localeCompare(b.compte_libelle));
    } else {
      if (compteCentralisateur) {
        balanceComptes.sort((a, b) => a.compte_auxiliaire - b.compte_auxiliaire);
      } else {
        balanceComptes.sort((a, b) => a.compte_general - b.compte_general);
      }
    }

    let totalDebit = 0;
    let totalCredit = 0;
    let totalSolde = 0;
    let totalSoldeDebit = 0;
    let totalSoldeCredit = 0;
    balanceComptes.forEach((ligne, i) => {
      totalDebit += parseFloat(ligne.total_debit);
      totalCredit += parseFloat(ligne.total_credit);
    });

    totalSolde = totalCredit - totalDebit;
    if (totalSolde < 0) {
      totalSoldeDebit = totalSolde;
    } else if (totalSolde > 0) {
      totalSoldeCredit = totalSolde;
    }

    const lignesEcrituresFiltered = balanceComptes;

    return (
      <div>
        {/* Titre */}
        <div className="entitre row">
          <h1>
            {compteCentralisateur ? (
              <>{`Balance Auxiliaire ${
                comptes.find((compte) => compte.compte_id === compteCentralisateur)
                  .compte_compte_general
              } - ${
                comptes.find((compte) => compte.compte_id === compteCentralisateur).compte_libelle
              }`}</>
            ) : (
              <>{`Balance Générale`}</>
            )}
          </h1>
          <p className="center">
            <span>
              <strong>
                {`Situation ${
                  dateDu ? "du " + dateDu.format("DD/MM/YYYY") + " " : ""
                }au ${dateAu.format("DD/MM/YYYY")}`}
              </strong>
            </span>
          </p>
          <p>
            {compteCentralisateur ? (
              <strong>
                {`Compte du ${
                  comptes.find((compte) => compte.compte_compte_auxiliaire === compteDu)
                    .compte_compte_auxiliaire
                } - ${
                  comptes.find((compte) => compte.compte_compte_auxiliaire === compteDu)
                    .compte_libelle
                } au ${
                  comptes.find((compte) => compte.compte_compte_auxiliaire === compteAu)
                    .compte_compte_auxiliaire
                } - ${
                  comptes.find((compte) => compte.compte_compte_auxiliaire === compteAu)
                    .compte_libelle
                }`}
              </strong>
            ) : (
              <strong>
                {`Compte du ${
                  comptes.find(
                    (compte) =>
                      compte.compte_compte_general === compteDu && compte.compte_type_compte !== "A"
                  ).compte_compte_general
                } - ${
                  comptes.find(
                    (compte) =>
                      compte.compte_compte_general === compteDu && compte.compte_type_compte !== "A"
                  ).compte_libelle
                } au ${
                  comptes.find(
                    (compte) =>
                      compte.compte_compte_general === compteAu && compte.compte_type_compte !== "A"
                  ).compte_compte_general
                } - ${
                  comptes.find(
                    (compte) =>
                      compte.compte_compte_general === compteAu && compte.compte_type_compte !== "A"
                  ).compte_libelle
                }`}
              </strong>
            )}
          </p>
        </div>

        <div>
          <table className="table table-striped" border="1">
            <thead>
              <tr>
                <th width="70%" scope="col" align="center"></th>
                <th width="30%" scope="col" align="center">
                  <strong>SOLDE</strong>
                </th>
              </tr>
              <tr>
                <th width="14%" scope="col" align="center">
                  <strong>COMPTE</strong>
                </th>

                <th width="26%" scope="col" align="center">
                  <strong> LIBELLE</strong>
                </th>
                <th width="15%" scope="col" align="center">
                  <strong>DEBIT </strong>
                </th>
                <th width="15%" scope="col" align="center">
                  <strong>CREDIT </strong>
                </th>
                <th width="15%" scope="col" align="center">
                  <strong>DEBIT</strong>
                </th>
                <th width="15%" scope="col" align="center">
                  <strong>CREDIT</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {lignesEcrituresFiltered.map((ligneF, i) => {
                if (i % 2) {
                  return (
                    <tr className="odd">
                      <td width="7%" align="center">
                        {ligneF.compte_general}
                      </td>
                      <td width="7%" align="center">
                        {ligneF.compte_auxiliaire}
                      </td>

                      <td width="26%"> {ligneF.compte_libelle}</td>
                      <td width="15%" style={{ textAlign: "right" }}>
                        {parseInt(ligneF.total_debit) !== 0 ? (
                          parseFloat(ligneF.total_debit).toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          })
                        ) : (
                          <></>
                        )}
                      </td>
                      <td width="15%" style={{ textAlign: "right" }}>
                        {parseInt(ligneF.total_credit) !== 0 ? (
                          parseFloat(ligneF.total_credit).toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          })
                        ) : (
                          <></>
                        )}
                      </td>
                      <td width="15%" style={{ textAlign: "right" }}>
                        {parseInt(ligneF.total_solde_debit) !== 0 ? (
                          parseFloat(ligneF.total_solde_debit).toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          })
                        ) : (
                          <></>
                        )}
                      </td>
                      <td width="15%" style={{ textAlign: "right" }}>
                        {parseInt(ligneF.total_solde_credit) !== 0 ? (
                          parseFloat(ligneF.total_solde_credit).toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          })
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  );
                } else {
                  return (
                    <tr>
                      <td width="7%" align="center">
                        {ligneF.compte_general}
                      </td>
                      <td width="7%" align="center">
                        {ligneF.compte_auxiliaire}
                      </td>

                      <td width="26%"> {ligneF.compte_libelle}</td>
                      <td width="15%" style={{ textAlign: "right" }}>
                        {parseInt(ligneF.total_debit) !== 0 ? (
                          parseFloat(ligneF.total_debit).toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          })
                        ) : (
                          <></>
                        )}
                      </td>
                      <td width="15%" style={{ textAlign: "right" }}>
                        {parseInt(ligneF.total_credit) !== 0 ? (
                          parseFloat(ligneF.total_credit).toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          })
                        ) : (
                          <></>
                        )}
                      </td>
                      <td width="15%" style={{ textAlign: "right" }}>
                        {parseInt(ligneF.total_solde_debit) !== 0 ? (
                          parseFloat(ligneF.total_solde_debit).toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          })
                        ) : (
                          <></>
                        )}
                      </td>
                      <td width="15%" style={{ textAlign: "right" }}>
                        {parseInt(ligneF.total_solde_credit) !== 0 ? (
                          parseFloat(ligneF.total_solde_credit).toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          })
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>

        <div>
          <table className="table table-striped" border="1">
            <thead></thead>
            <tbody>
              <tr>
                <td width="40%" align="right">
                  <strong>TOTAUX </strong>
                </td>
                <td width="15%" style={{ textAlign: "right" }}>
                  {parseFloat(totalDebit).toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </td>
                <td width="15%" style={{ textAlign: "right" }}>
                  {parseFloat(totalCredit).toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </td>
                <td width="15%" style={{ textAlign: "right" }}>
                  {parseFloat(totalSoldeDebit).toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </td>
                <td width="15%" style={{ textAlign: "right" }}>
                  {parseFloat(totalSoldeCredit).toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

import { etapeToAdapted } from "./etapeAdapter";
import { groupeToAdapted } from "./groupeAdapter";

export function parcoursClientsToAdapted(items) {
  return items
    ? items.map((item) => {
        return parcoursClientToAdapted(item);
      })
    : null;
}

export function parcoursClientToAdapted(item) {
  //Resource
  return item
    ? {
        parcours_client_groupe: groupeToAdapted(item.groupe),
        parcours_client_etape: etapeToAdapted(item.etape),
        parcours_client_ordre: item.ordre,
        parcours_client_actif: item.actif,
        parcours_client_confirmation: item.confirmation,
        parcours_client_option: item.option,
      }
    : null;
}

export function adaptedToParcoursClient(item) {
  //Model
  return item
    ? {
        groupe_id: item.parcours_client_groupe_id,
        etape_id: item.parcours_client_etape_id,
        ordre: item.parcours_client_ordre,
        actif: item.parcours_client_actif,
        confirmation: item.parcours_client_confirmation,
        option: item.parcours_client_option,
      }
    : null;
}

import { etablissementToAdapted } from "./etablissementAdapter";

export function fraisAgencesToAdapted(items) {
  return items
    ? items.map((item) => {
        return fraisAgenceToAdapted(item);
      })
    : null;
}

export function fraisAgenceToAdapted(item) {
  return item
    ? {
        frais_agence_id: item.id,
        frais_agence_mois_debut: item.mois_debut,
        frais_agence_comparateur_debut: item.comparateur_debut,
        frais_agence_mois_fin: item.mois_fin,
        frais_agence_comparateur_fin: item.comparateur_fin,
        frais_agence_pourcentage: item.pourcentage,
        frais_agence_pourcentage_type: item.pourcentage_type,
        frais_agence_type_contrat: item.type_contrat,
        frais_agence_etablissement: etablissementToAdapted(item.etablissement),
      }
    : null;
}

export function adaptedToFraisAgences(items) {
  return items
    ? items.map((item) => {
        return adaptedToFraisAgence(item);
      })
    : null;
}

export function adaptedToFraisAgence(item) {
  return item
    ? {
        mois_debut: item.frais_agence_mois_debut,
        comparateur_debut: item.frais_agence_comparateur_debut,
        mois_fin: item.frais_agence_mois_fin,
        comparateur_fin: item.frais_agence_comparateur_fin,
        pourcentage: item.frais_agence_pourcentage,
        pourcentage_type: item.frais_agence_pourcentage_type,
        type_contrat: item.frais_agence_type_contrat,
        etablissement_id: item.frais_agence_etablissement_id,
      }
    : null;
}

import { HttpMethod, initFetch } from "../../utils/fetcher";
import {
  adaptedToReglement,
  adaptedToReglements,
  reglementsToAdapted,
  reglementToAdapted,
} from "../../adapter/reglementAdapter";
import {
  adaptedToReglementsLignes,
  reglementLigneToAdapted,
  reglementsLignesToAdapted,
} from "../../adapter/reglementLigneAdapter";

export const LOAD_SUCCESS_REGLEMENTS = "LOAD_SUCCESS_REGLEMENTS";
export const LOAD_FAIL_REGLEMENTS = "LOAD_FAIL_REGLEMENTS";
export const SAVE_SUCCESS_REGLEMENTS = "SAVE_SUCCESS_REGLEMENTS";
export const SAVE_FAIL_REGLEMENTS = "SAVE_FAIL_REGLEMENTS";
export const UPDATE_SUCCESS_REGLEMENTS = "UPDATE_SUCCESS_REGLEMENTS";
export const UPDATE_FAIL_REGLEMENTS = "UPDATE_FAIL_REGLEMENTS";
export const UPDATE_SUCCESS_ALL_REGLEMENTS = "UPDATE_SUCCESS_ALL_REGLEMENTS";
export const UPDATE_FAIL_ALL_REGLEMENTS = "UPDATE_FAIL_ALL_REGLEMENTS";
export const DELETE_SUCCESS_REGLEMENTS = "DELETE_SUCCESS_REGLEMENTS";
export const DELETE_FAIL_REGLEMENTS = "DELETE_FAIL_REGLEMENTS";
export const SAVE_SUCCESS_REGLEMENTS_LIGNES = "SAVE_SUCCESS_REGLEMENTS_LIGNES";
export const SAVE_FAIL_REGLEMENTS_LIGNES = "SAVE_FAIL_REGLEMENTS_LIGNES";
export const DELETE_SUCCESS_REGLEMENTS_LIGNES = "DELETE_SUCCESS_REGLEMENTS_LIGNES";
export const DELETE_FAIL_REGLEMENTS_LIGNES = "DELETE_FAIL_REGLEMENTS_LIGNES";

export function loadReglements() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch("reglements", HttpMethod.GET);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.reglement);
          dispatch({
            type: LOAD_SUCCESS_REGLEMENTS,
            payload: reglementsToAdapted(res.reglement),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_REGLEMENTS,
            error: err,
          });
        });
    });
  };
}

export function saveReglement(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch("reglements", HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToReglements(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(reglementsToAdapted(res.reglement));
          dispatch({
            type: SAVE_SUCCESS_REGLEMENTS,
            payload: reglementsToAdapted(res.reglement),
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_REGLEMENTS,
            error: err,
          })
        );
    });
  };
}

export function updateReglement(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`reglements/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToReglement(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(reglementToAdapted(res.reglement));
          dispatch({
            type: UPDATE_SUCCESS_REGLEMENTS,
            payload: reglementToAdapted(res.reglement),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_REGLEMENTS,
            error: err,
          })
        );
    });
  };
}

export function updateReglements(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch("reglements-update", HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToReglements(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(reglementsToAdapted(res.reglement));
          dispatch({
            type: UPDATE_SUCCESS_ALL_REGLEMENTS,
            payload: reglementsToAdapted(res.reglement),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_ALL_REGLEMENTS,
            error: err,
          })
        );
    });
  };
}

export function deleteReglement(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`reglements/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.reglement);
          dispatch({
            type: DELETE_SUCCESS_REGLEMENTS,
            payload: reglementToAdapted(res.reglement),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_REGLEMENTS,
            error: err,
          });
        });
    });
  };
}

// Controller reglementsLignes/store
export function saveReglementLigne(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch("reglementsLignes", HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToReglementsLignes(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(reglementsLignesToAdapted(res.reglement_ligne));
          dispatch({
            type: SAVE_SUCCESS_REGLEMENTS_LIGNES,
            payload: reglementsLignesToAdapted(res.reglement_ligne),
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_REGLEMENTS_LIGNES,
            error: err,
          })
        );
    });
  };
}

// Controller reglementsLignes/delete
export function deleteReglementLigne(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`reglementsLignes/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(reglementLigneToAdapted(res.reglement_ligne));
          dispatch({
            type: DELETE_SUCCESS_REGLEMENTS_LIGNES,
            payload: reglementLigneToAdapted(res.reglement_ligne),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_REGLEMENTS_LIGNES,
            error: err,
          });
        });
    });
  };
}

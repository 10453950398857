import React, { useState, useEffect, useRef } from "react";

import { Form, Row, Table, Typography, Col } from "antd";

const { Title } = Typography;

const ListDocuments = (props) => {
  const [documents, setDocuments] = useState(props.documents);
  const { tableOption } = props;

  // Référence du formulaire
  const formRef = useRef();

  const componentRef = useRef();

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    applyFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.documents]);

  const init = () => {
    applyFilter();
  };

  const applyFilter = () => {
    const documents = props.documents.filter(
      (doc) => doc.document_yousign_document_id !== "" && doc.document_yousign_document_id !== null
    );

    setDocuments(documents);
  };

  const onClickRefresh = (document) => {
    const urlRefresh =
      "https://api.yousign.app/v3/signature_requests/" + document.document_yousign_requete_id;
    fetch(urlRefresh, {
      method: "GET",
      headers: {
        Authorization: "Bearer 8lj0pevKaOYCEpceDU3P4hBMhw2kl9fA",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // mise à jour document
        props.updateDocument(
          {
            document_nom: document.document_nom,
            document_dispo_mail: false,
            document_yousign_document_id: document.document_yousign_document_id,
            document_yousign_requete_id: document.document_yousign_requete_id,
            document_yousign_statut: res.status,
          },
          document.document_id
        );
      });
  };

  const onClickAfficher = (document) => {
    const urlAfficher =
      "https://api.yousign.app/v3/signature_requests/" +
      document.document_yousign_requete_id +
      "/documents/download";
    fetch(urlAfficher, {
      method: "GET",
      headers: {
        Authorization: "Bearer 8lj0pevKaOYCEpceDU3P4hBMhw2kl9fA",
      },
    });
  };

  return (
    <div style={{ padding: 20 }}>
      <Form ref={formRef}>
        <Row>
          <Col span={24}>
            <Title level={2}>Etat des documents YOUSIGN</Title>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col span={24}>
          <div ref={componentRef}>
            {documents && (
              <Table
                className="list-data"
                dataSource={documents}
                columns={tableOption(document, onClickRefresh, onClickAfficher)}
                pagination={false}
                rowKey={"document_id"}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ListDocuments;

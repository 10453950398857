import React, { Component } from "react";
import { Button, Col, Divider, Form, Input, Row, Select, Tabs } from "antd";

import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";

import FloatLabel from "../FloatLabel";
import CustomInput from "../CustomFormItem/Input";
import CustomSelect from "../CustomFormItem/Select";
import CustomDatePicker from "../CustomFormItem/DatePicker";

import dayjs from "dayjs";

const { Option, OptGroup } = Select;

class FormAgent extends Component {
  state = {
    key: "1",
    loading: false,
    typeAcompte: "",
    adresse: "",
    numero: "",
    voie: "",
    complement: "",
    cp: "",
    ville: "",
    departement: "",
    region: "",
    pays: "",
  };

  // Référence du formulaire
  formRef = React.createRef();

  componentDidMount() {
    this.props.personne
      ? this.initPersonne(this.props.personne, this.props.code)
      : this.resetPersonne(this.props.code);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.props.personne !== props.personne || this.props.code !== props.code) {
      props.personne
        ? this.initPersonne(props.personne, props.code)
        : this.resetPersonne(props.code);
    }
  }

  initPersonne = (data, code) => {
    this.resetPersonne(code);

    const personne = data.agent_personne;

    const adresse = data.agent_personne.personne_adresse;
    const compte = data.agent_personne.personne_compte_bancaire;

    // recherche langue par défaut
    const langueDefaut = this.props.langues.filter(
      (l) => l.langue_code === "FR" || l.langue_code === "fr"
    );

    this.formRef.current.setFieldsValue({
      personne_nom: personne.personne_nom,
      personne_prenom: personne.personne_prenom,
      personne_tel1: personne.personne_tel1,
      personne_tel2: personne.personne_tel2,
      personne_portable1: personne.personne_portable1,
      personne_portable2: personne.personne_portable2,
      personne_email1: personne.personne_email1,
      personne_email2: personne.personne_email2,
      personne_commentaire: personne.personne_commentaire,
      personne_date_naissance: personne.personne_date_naissance
        ? dayjs(personne.personne_date_naissance)
        : null,
      personne_lieu_naissance: personne.personne_lieu_naissance,
      personne_qualite_id: personne.personne_qualite ? personne.personne_qualite.qualite_id : null,
      personne_langue_id: personne.personne_langue
        ? personne.personne_langue.langue_id
        : langueDefaut[0].langue_id,
      adresse_gmaps_adresse: adresse?.adresse_gmaps_adresse,
      adresse_numero: adresse?.adresse_numero,
      adresse_voie: adresse?.adresse_voie,
      adresse_situation: adresse?.adresse_situation,
      adresse_code_postal: adresse?.adresse_code_postal,
      adresse_ville: adresse?.adresse_ville,
      adresse_departement: adresse?.adresse_departement,
      adresse_pays: adresse?.adresse_pays,
      compte_bancaire_titulaire: compte && compte.compte_bancaire_titulaire,
      compte_bancaire_banque: compte && compte.compte_bancaire_banque,
      compte_bancaire_code_banque: compte && compte.compte_bancaire_code_banque,
      compte_bancaire_code_guichet: compte && compte.compte_bancaire_code_guichet,
      compte_bancaire_num_compte: compte && compte.compte_bancaire_num_compte,
      compte_bancaire_cle_RIB: compte && compte.compte_bancaire_cle_RIB,
      compte_bancaire_IBAN: compte && compte.compte_bancaire_IBAN,
      compte_bancaire_BIC: compte && compte.compte_bancaire_BIC,
      compte_bancaire_type_paiement_id:
        compte && compte.compte_bancaire_type_paiement.type_paiement_id,
    });

    this.formRef.current.setFieldsValue({
      agent_code: data.agent_code,
    });

    this.setState({
      key: this.state.key,
      loading: this.props.loading,
      adresse: adresse?.adresse_gmaps_adresse,
      numero: adresse?.adresse_numero,
      voie: adresse?.adresse_voie,
      complement: adresse?.adresse_situation,
      cp: adresse?.adresse_code_postal,
      ville: adresse?.adresse_ville,
      departement: adresse?.adresse_departement,
      region: adresse?.adresse_region,
      pays: adresse?.adresse_pays,
    });
  };

  resetPersonne = (code) => {
    this.formRef.current.resetFields([
      // "personne_societe_id",
      // "personne_etablissement_id",
      "personne_nom",
      "personne_prenom",
      "personne_tel1",
      "personne_tel2",
      "personne_portable1",
      "personne_portable2",
      "personne_email1",
      "personne_email2",
      "personne_commentaire",
      "personne_qualite_id",
      "personne_langue_id",
      "adresse_gmaps_adresse",
      "adresse_numero",
      "adresse_voie",
      "adresse_situation",
      "adresse_code_postal",
      "adresse_ville",
      "adresse_departement",
      "adresse_region",
      "adresse_pays",
      "compte_bancaire_titulaire",
      "compte_bancaire_banque",
      "compte_bancaire_code_banque",
      "compte_bancaire_code_guichet",
      "compte_bancaire_num_compte",
      "compte_bancaire_cle_RIB",
      "compte_bancaire_IBAN",
      "compte_bancaire_BIC",
      "compte_bancaire_type_paiement_id",
    ]);

    this.formRef.current.resetFields(["agent_code"]);

    // recherche langue par défaut
    let langueDefaut = [];

    if (this.props.utilisateur.groupement_id === 301) {
      langueDefaut = this.props.langues.filter(
        (l) => l.langue_code === "EN" || l.langue_code === "en"
      );
    } else {
      langueDefaut = this.props.langues.filter(
        (l) => l.langue_code === "FR" || l.langue_code === "fr"
      );
    }

    this.formRef.current.setFieldsValue({
      agent_code: code,
      personne_langue_id: langueDefaut[0].langue_id,
    });

    this.setState({
      loading: this.props.loading,
      adresse: "",
      numero: "",
      voie: "",
      complement: "",
      cp: "",
      ville: "",
      departement: "",
      region: "",
      pays: "",
    });
  };

  onFinish = (data) => {
    const personne = this.props.personne ? this.props.personne.agent_personne : null;
    data.personne_actif = true;
    data.personne_adresse_id = personne ? personne.personne_adresse.adresse_id : null;
    data.personne_groupement_id = personne
      ? personne.personne_groupement.groupement_id
      : this.props.utilisateur.groupement_id;
    data.personne_compte_bancaire_id = personne
      ? personne.personne_compte_bancaire.compte_bancaire_id
      : null;
    data.compte_bancaire_type_paiement_id = data.compte_bancaire_type_paiement_id
      ? data.compte_bancaire_type_paiement_id
      : personne
      ? personne.personne_compte_bancaire.compte_bancaire_type_paiement.type_paiement_id
      : 1;

    this.setState({ loading: true });

    this.props.personne
      ? this.props.handleEdit(data, this.props.personne.agent_id)
      : this.props.handleSubmit(data);
  };

  handleChangeAdresse = (adresse) => {
    this.setState({ adresse });
  };

  handleSelectAdresse = (adresse) => {
    this.formRef.current.setFieldsValue({
      adresse_gmaps_adresse: adresse,
      adresse_numero: "",
      adresse_voie: "",
      adresse_ville: "",
      adresse_code_postal: "",
      adresse_region: "",
      adresse_departement: "",
      adresse_pays: "",
      adresse_situation: "",
    });
    geocodeByAddress(adresse).then((results) => {
      results[0].address_components.forEach((component, i) => {
        if (component.types[0] === "street_number") {
          this.setState({ numero: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_numero: component.long_name,
          });
        }
        if (component.types[0] === "route") {
          this.setState({ voie: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_voie: component.long_name,
          });
        }
        if (component.types[0] === "locality") {
          this.setState({ ville: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_ville: component.long_name,
          });
        }
        if (component.types[0] === "postal_code") {
          this.setState({ cp: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_code_postal: component.long_name,
          });
        }
        if (component.types[0] === "administrative_area_level_1") {
          this.setState({ region: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_region: component.long_name,
          });
        }
        if (component.types[0] === "administrative_area_level_2") {
          this.setState({ departement: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_departement: component.long_name,
          });
        }
        if (component.types[0] === "country") {
          this.setState({ pays: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_pays: component.long_name,
          });
        }
      });
    });
    this.setState({ adresse });
  };

  onChangeNom = (e) => {
    this.formRef.current.setFieldsValue({
      personne_nom: e.target.value.toUpperCase(),
    });
  };

  onChangeTab = (key, document) => {
    this.setState({ key, document });
  };

  render() {
    const { readOnly, code, qualites, langues, personne } = this.props;
    const {
      key,
      loading,
      adresse,
      numero,
      voie,
      complement,
      cp,
      ville,
      departement,
      region,
      pays,
    } = this.state;

    const data = personne && personne.agent_personne;

    return (
      <Form
        ref={this.formRef}
        onFinish={this.onFinish}
        size="large"
        className="form-requete"
        validateTrigger="onBlur"
      >
        <Tabs
          defaultActiveKey="1"
          activeKey={key}
          onChange={(key) => this.setState({ key })}
          items={[
            {
              label: "Coordonnées",
              key: "1",
              children: (
                <Row gutter={16}>
                  <Col span={12} style={{ padding: 20 }}>
                    <Divider orientation="left">Identification</Divider>
                    <Row gutter={8}>
                      <Col span={8}>
                        <CustomInput
                          label="Code"
                          inputName="code"
                          formItemName={"agent_code"}
                          objectValue={personne ? personne.agent_code : code}
                          rules={{ required: true, message: "Code obligatoire" }}
                          // readOnly={true}
                        />
                      </Col>
                    </Row>
                    <Row gutter={8} style={{ marginTop: 20 }}>
                      <Col span={8}>
                        <CustomSelect
                          label="Qualité"
                          inputName="qualite"
                          formItemName="personne_qualite_id"
                          objectValue={
                            data && data.personne_qualite && data.personne_qualite.qualite_id
                          }
                          rules={{ required: true, message: "Qualité obligatoire" }}
                        >
                          <OptGroup label="Qualite">
                            {qualites &&
                              qualites.map((item, i) => {
                                return (
                                  <Option
                                    key={i}
                                    value={item.qualite_id}
                                    label={item.qualite_libelle}
                                  >
                                    <Row>
                                      <Col sm={8}>{item.qualite_libelle}</Col>
                                    </Row>
                                  </Option>
                                );
                              })}
                          </OptGroup>
                        </CustomSelect>
                      </Col>
                      <Col span={8}>
                        <CustomInput
                          label="Nom"
                          inputName="nom"
                          formItemName="personne_nom"
                          objectValue={data && data.personne_nom}
                          onChange={(e) => this.onChangeNom(e)}
                          rules={{ required: true, message: "Nom obligatoire" }}
                        />
                      </Col>
                      <Col span={8}>
                        <CustomInput
                          label="Prénom"
                          inputName="prenom"
                          formItemName="personne_prenom"
                          objectValue={data && data.personne_prenom}
                        />
                      </Col>
                    </Row>
                    <Row gutter={8} style={{ marginTop: 20 }}>
                      <Col span={12}>
                        <CustomInput
                          label="Email"
                          inputName="mail1"
                          formItemName="personne_email1"
                          objectValue={data && data.personne_email1}
                        />
                      </Col>
                      <Col span={12}>
                        <CustomInput
                          label="Email"
                          inputName="mail2"
                          formItemName="personne_email2"
                          objectValue={data && data.personne_email2}
                        />
                      </Col>
                      <Col span={6}>
                        <CustomInput
                          label="Portable"
                          inputName="portable1"
                          formItemName="personne_portable1"
                          objectValue={data && data.personne_portable1}
                        />
                      </Col>
                      <Col span={6}>
                        <CustomInput
                          label="Commentaire"
                          inputName="comportable1"
                          formItemName="personne_commentaire_portable1"
                          objectValue={data && data.personne_commentaire_portable1}
                        />
                      </Col>
                      <Col span={6}>
                        <CustomInput
                          label="Téléphone"
                          inputName="telephone1"
                          formItemName="personne_tel1"
                          objectValue={data && data.personne_tel1}
                        />
                      </Col>
                      <Col span={6}>
                        <CustomInput
                          label="Commentaire"
                          inputName="comtelephone1"
                          formItemName="personne_commentaire_tel1"
                          objectValue={data && data.personne_commentaire_tel1}
                        />
                      </Col>
                      <Col span={6}>
                        <CustomInput
                          label="Portable"
                          inputName="portable2"
                          formItemName="personne_portable2"
                          objectValue={data && data.personne_portable2}
                        />
                      </Col>
                      <Col span={6}>
                        <CustomInput
                          label="Commentaire"
                          inputName="comportable2"
                          formItemName="personne_commentaire_portable2"
                          objectValue={data && data.personne_commentaire_portable2}
                        />
                      </Col>
                      <Col span={6}>
                        <CustomInput
                          label="Téléphone"
                          inputName="telephone2"
                          formItemName="personne_tel2"
                          objectValue={data && data.personne_tel2}
                        />
                      </Col>
                      <Col span={6}>
                        <CustomInput
                          label="Commentaire"
                          inputName="comtelephone2"
                          formItemName="personne_commentaire_tel2"
                          objectValue={data && data.personne_commentaire_tel2}
                        />
                      </Col>
                    </Row>
                    <Divider orientation="left">Informations diverses</Divider>
                    <Row gutter={8}>
                      <Col span={8}>
                        <CustomDatePicker
                          label="Date de naissance"
                          inputName="date_naissance"
                          formItemName="personne_date_naissance"
                          objectValue={data && data.personne_date_naissance}
                        />
                      </Col>
                      <Col span={8}>
                        <CustomInput
                          label="Lieu naissance"
                          inputName="lieu_naissance"
                          formItemName="personne_lieu_naissance"
                          objectValue={data && data.personne_lieu_naissance}
                        />
                      </Col>
                      <Col span={8}>
                        <CustomSelect
                          label="Langue"
                          inputName="langue"
                          formItemName="personne_langue_id"
                          objectValue={
                            data
                              ? data.personne_langue
                                ? data.personne_langue.langue_id
                                : "1"
                              : null
                          }
                        >
                          <OptGroup label="Langue">
                            {langues &&
                              langues.map((item, i) => {
                                return (
                                  <Option
                                    key={i}
                                    value={item.langue_id}
                                    label={item.langue_libelle}
                                  >
                                    <Row>
                                      <Col sm={8}>{item.langue_libelle}</Col>
                                    </Row>
                                  </Option>
                                );
                              })}
                          </OptGroup>
                        </CustomSelect>
                      </Col>
                    </Row>
                    <Row gutter={8}>
                      <Col span={16}>
                        <CustomInput
                          label="Remarques"
                          inputName="personne_commentaire"
                          formItemName="personne_commentaire"
                          objectValue={data && data.personne_commentaire}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12} style={{ padding: 20 }}>
                    <Divider orientation="left">Adresse</Divider>
                    <Row gutter={8}>
                      <Col span={24}>
                        <PlacesAutocomplete
                          value={adresse}
                          onChange={this.handleChangeAdresse}
                          onSelect={this.handleSelectAdresse}
                        >
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                              <FloatLabel
                                label="Recherche GOOGLE"
                                name="adresse_gmaps_adresse"
                                value={adresse}
                              >
                                <Form.Item name="adresse_gmaps_adresse">
                                  <Input
                                    {...getInputProps({
                                      className: "location-search-input",
                                    })}
                                  />
                                </Form.Item>
                              </FloatLabel>
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Chargement ...</div>}
                                {suggestions.map((suggestion, i) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                      key={i}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </Col>

                      <Col span={8}>
                        <FloatLabel label="N°" name="adresse_numero" value={numero}>
                          <Form.Item name="adresse_numero">
                            <Input
                              value={numero}
                              onChange={(e) => this.setState({ numero: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                      <Col span={16}>
                        <FloatLabel label="Libellé voie" name="adresse_voie" value={voie}>
                          <Form.Item name="adresse_voie">
                            <Input
                              value={voie}
                              onChange={(e) => this.setState({ voie: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col span={24}>
                        <FloatLabel
                          label="Complément adresse"
                          name="adresse_situation"
                          value={complement}
                        >
                          <Form.Item name="adresse_situation">
                            <Input
                              value={complement}
                              onChange={(e) => this.setState({ complement: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col span={8}>
                        <FloatLabel label="CP" name="adresse_code_postal" value={cp}>
                          <Form.Item name="adresse_code_postal">
                            <Input
                              value={cp}
                              onChange={(e) => this.setState({ cp: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                      <Col span={16}>
                        <FloatLabel label="Ville" name="adresse_ville" value={ville}>
                          <Form.Item name="adresse_ville">
                            <Input
                              value={ville}
                              onChange={(e) => this.setState({ ville: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col span={8}>
                        <FloatLabel
                          label="Département"
                          name="adresse_departement"
                          value={departement}
                        >
                          <Form.Item name="adresse_departement">
                            <Input
                              value={departement}
                              onChange={(e) => this.setState({ departement: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                      <Col span={8}>
                        <FloatLabel label="Région" name="adresse_region" value={region}>
                          <Form.Item name="adresse_region">
                            <Input
                              value={region}
                              onChange={(e) => this.setState({ region: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                      <Col span={8}>
                        <FloatLabel label="Pays" name="adresse_pays" value={pays}>
                          <Form.Item name="adresse_pays">
                            <Input
                              value={pays}
                              onChange={(e) => this.setState({ pays: e.target.value })}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ),
            },
          ]}
        />
        {!readOnly && (
          <Row style={{ marginTop: 10, float: "right" }}>
            <Button type="primary" danger>
              Annulation
            </Button>
            <Button type="primary" htmlType="submit" loading={loading} style={{ marginLeft: 10 }}>
              Validation
            </Button>
          </Row>
        )}
      </Form>
    );
  }
}

export default FormAgent;

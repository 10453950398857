import {HttpMethod, initFetch} from "../../utils/fetcher";

export const LOAD_SUCCESS_DEMANDES = "LOAD_SUCCESS_DEMANDES";
export const LOAD_FAIL_DEMANDES = "LOAD_FAIL_DEMANDES";
export const SAVE_SUCCESS_DEMANDES = "SAVE_SUCCESS_DEMANDES";
export const SAVE_FAIL_DEMANDES = "SAVE_FAIL_DEMANDES";
export const DELETE_SUCCESS_DEMANDES = "DELETE_SUCCESS_DEMANDES";
export const DELETE_FAIL_DEMANDES = "DELETE_FAIL_DEMANDES";

export function loadDemandes(societe_id) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const {url, params} = initFetch(`demandes`, HttpMethod.GET);
            // make async call to database
            fetch(url, params)
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    } else {
                        throw new Error("500");
                    }
                })
                .then((res) => {
                    resolve(res.demande);
                    dispatch({
                        type: LOAD_SUCCESS_DEMANDES,
                        payload: res.demande,
                    });
                })
                .catch((err) => {
                    reject(err);
                    dispatch({
                        type: LOAD_FAIL_DEMANDES,
                        error: err,
                    });
                });
        });
    };
}

export function saveDemande(jsonData) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const { url, params } = initFetch(`demandes`, HttpMethod.POST);
            // make async call to database
            fetch(url, { ...params, body: JSON.stringify(jsonData) })
                .then((res) => res.json())
                .then((res) => {
                    resolve(res.demande);
                    dispatch({
                        type: SAVE_SUCCESS_DEMANDES,
                        payload: res.demande,
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: SAVE_FAIL_DEMANDES,
                        error: err,
                    });
                });
        });
    };
}

export function deleteDemande(id) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const {url, params} = initFetch(`demandes/${id}`, HttpMethod.DELETE);
            // make async call to database
            fetch(url, params)
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    } else {
                        throw new Error("500");
                    }
                })
                .then((res) => {
                    resolve(res.demande);
                    dispatch({
                        type: DELETE_SUCCESS_DEMANDES,
                        payload: res.demande,
                        id: id,
                    });
                })
                .catch((err) => {
                    reject(err);
                    dispatch({
                        type: DELETE_FAIL_DEMANDES,
                        error: err,
                    });
                });
        });
    };
}

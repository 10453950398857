import { HttpMethod, initFetch } from "../../utils/fetcher";
import {
  adaptedToRenduHistorique,
  renduHistoriquesToAdapted,
  renduHistoriqueToAdapted,
} from "../../adapter/renduAHistoriqueAdapter";

export const LOAD_SUCCESS_RENDU_A_HISTORIQUE = "LOAD_SUCCESS_RENDU_A_HISTORIQUE";
export const LOAD_FAIL_RENDU_A_HISTORIQUE = "LOAD_FAIL_RENDU_A_HISTORIQUE";
export const SAVE_SUCCESS_RENDU_A_HISTORIQUE = "SAVE_SUCCESS_RENDU_A_HISTORIQUE";
export const SAVE_FAIL_RENDU_A_HISTORIQUE = "SAVE_FAIL_RENDU_A_HISTORIQUE";

export function loadRenduAsHistorique() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`renduAsHistorique`, HttpMethod.GET);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.rendu_a_historique);
          dispatch({
            type: LOAD_SUCCESS_RENDU_A_HISTORIQUE,
            payload: renduHistoriquesToAdapted(res.rendu_a_historique),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_RENDU_A_HISTORIQUE,
            error: err,
          });
        });
    });
  };
}

export function saveRenduAHistorique(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`renduAsHistorique`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToRenduHistorique(jsonData)) })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.rendu_a_historique);
          dispatch({
            type: SAVE_SUCCESS_RENDU_A_HISTORIQUE,
            payload: renduHistoriqueToAdapted(res.rendu_a_historique),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: SAVE_FAIL_RENDU_A_HISTORIQUE,
            error: err,
          });
        });
    });
  };
}

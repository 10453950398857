import React, { Component } from "react";

import { Form, Button, Col, Row, Select } from "antd";
import CustomInput from "../CustomFormItem/Input";

import CustomSelect from "../CustomFormItem/Select";

const { Option, OptGroup } = Select;

class FormCompte extends Component {
  componentDidMount() {
    this.init(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.init(props);
  }

  // Référence du formulaire
  formRef = React.createRef();

  init = (props) => {
    this.formRef.current.resetFields();
    if (props.compte) {
      this.formRef.current.setFieldsValue({
        compte_type_compte: props.compte.compte_type_compte,
        compte_compte_general: props.compte.compte_compte_general,
        compte_compte_auxiliaire: props.compte.compte_compte_auxiliaire,
        compte_libelle: props.compte.compte_libelle,
      });
    }
  };

  onFinish = (data) => {
    data.compte_societe_id = this.props.utilisateur.societe_id;
    data.compte_actif = true;
    this.props.compte
      ? this.props.updateCompte(data, this.props.compte.compte_id)
      : this.props.saveCompte(data);
    this.props.closeModalCompte();
  };

  render() {
    const { compte } = this.props;
    return (
      <Form ref={this.formRef} onFinish={this.onFinish} style={{ padding: "20px" }}>
        <Row gutter={8}>
          <Col span={4}>
            <CustomSelect
              label="Type de compte"
              inputName="compte_type_compte"
              formItemName="compte_type_compte"
              objectValue={compte ? compte.compte_type_compte : null}
            >
              <OptGroup label="Type de compte">
                <Option key={"G"} value={"G"} label={"Général"}>
                  Général
                </Option>
                <Option key={"A"} value={"A"} label={"Auxiliaire"}>
                  Auxiliaire
                </Option>
              </OptGroup>
            </CustomSelect>
          </Col>

          <Col span={5}>
            <CustomInput
              label="Compte général"
              inputName="compte_compte_general"
              formItemName="compte_compte_general"
              rules={{ required: true, message: "Compte général obligatoire" }}
              objectValue={compte ? compte.compte_compte_general : null}
              minLength="6"
              maxLength="6"
            />
          </Col>
          <Col span={5}>
            <CustomInput
              label="Compte auxiliaire"
              inputName="compte_compte_auxiliaire"
              formItemName="compte_compte_auxiliaire"
              rules={{ required: true, message: "Compte auxiliaire obligatoire" }}
              objectValue={compte ? compte.compte_compte_auxiliaire : null}
              minLength="6"
              maxLength="6"
            />
          </Col>
          <Col span={10}>
            <CustomInput
              label="Libellé"
              inputName="compte_libelle"
              formItemName="compte_libelle"
              rules={{ required: true, message: "Libellé obligatoire" }}
              objectValue={compte ? compte.compte_libelle : null}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Button type="primary" htmlType="submit" style={{ float: "right" }}>
            Valider
          </Button>
        </Row>
      </Form>
    );
  }
}

export default FormCompte;

import React, { Component } from "react";

import { SketchPicker } from "react-color";

class MySketchPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      couleurHex: "#000"
    };
  }

  handleChangeCodeCouleurComplete = value => {
    this.setState({ couleurHex: value.hex });
    this.props.handleChangeCodeCouleurComplete(value.hex);
  };

  render() {
    return (
      <SketchPicker
        width="450"
        disableAlpha={true}
        color={this.state.couleurHex}
        onChangeComplete={this.handleChangeCodeCouleurComplete}
      />
    );
  }
}

export default MySketchPicker;

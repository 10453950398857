import React, { Component } from "react";
import { Table, Button, Modal, Row, Col, Form, Rate, Checkbox } from "antd";
import CustomInput from "../CustomFormItem/Input";

import { CheckOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";

class TableFeedback extends Component {
  state = {
    modal: false,
    feedback: null,
  };

  // Référence du formulaire
  formRef = React.createRef();

  onSelectFeedback = (feedback) => {
    this.setState({ modal: true, feedback: feedback }, () => {
      if (feedback) {
        this.formRef.current.resetFields();
        this.formRef.current.setFieldsValue({
          titre: feedback.titre,
          libelle: feedback.libelle,
          note: feedback.note,
          texte: feedback.texte,
          selection: feedback.selection,
        });
      }
    });
  };

  onDeleteFeedback = (id) => {
    this.props.deleteFeedback(id);
  };

  openModal = () => {
    this.setState({ modal: true }, () =>
      this.formRef.current.resetFields(["titre", "libelle", "texte", "note", "selection"])
    );
  };

  closeModal = () => {
    this.setState({ modal: false, feedback: null });
    this.formRef.current.resetFields(["titre", "libelle", "texte", "note", "selection"]);
  };

  onAddFeedback = (data) => {
    if (this.state.feedback === null) {
      this.props.saveFeedback({
        titre: data.titre,
        libelle: data.libelle,
        note: data.note,
        texte: data.texte,
        selection: data.selection,
        location_id: this.props.location.location_id,
      });
    } else {
      this.props.updateFeedback(
        {
          titre: data.titre,
          libelle: data.libelle,
          note: data.note,
          texte: data.texte,
          selection: data.selection,
          location_id: this.props.location.location_id,
        },
        this.state.feedback.id
      );
    }
    this.closeModal();
  };

  render() {
    const { location } = this.props;
    const { modal, feedback } = this.state;

    let columns = [
      {
        title: "Selec.",
        dataIndex: "selection",
        key: "selection",
        width: "4%",
        render: (selection) => selection && <CheckOutlined />,
      },
      {
        title: "Titre",
        dataIndex: "titre",
        key: "titre",
        width: "8%",
      },
      {
        title: "Libelle",
        dataIndex: "libelle",
        key: "libelle",
        width: "8%",
      },
      {
        title: "Note",
        dataIndex: "note",
        key: "note",
        width: "14%",
        render: (note) => <Rate disabled value={note} />,
      },
      {
        title: "Détail",
        dataIndex: "texte",
        key: "texte",
        width: "50%",
        autoSize: { minRows: 3, maxRows: 3 },
      },
      {
        title: "Modifier",
        width: "8%",
        dataIndex: "feedback",
        key: "id",
        render: (id, feedback) => (
          <Button
            icon={<EditOutlined />}
            shape="circle"
            onClick={() => this.onSelectFeedback(feedback)}
          />
        ),
      },
      {
        title: "Supprimer",
        width: "8%",
        dataIndex: "id",
        key: "id",
        render: (id) => (
          <Button
            icon={<DeleteOutlined />}
            shape="circle"
            onClick={() => this.onDeleteFeedback(id)}
          />
        ),
      },
    ];

    return (
      <>
        <Modal
          footer={null}
          width="1000px"
          title="Feedback"
          open={modal}
          onCancel={this.closeModal}
        >
          <Form ref={this.formRef} onFinish={this.onAddFeedback}>
            <Row gutter={12}>
              <Col span={6}>
                <CustomInput
                  label="Titre"
                  inputName="titre"
                  formItemName="titre"
                  objectValue={feedback && feedback.titre}
                />
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={6}>
                <CustomInput
                  label="Libelle"
                  inputName="libelle"
                  formItemName="libelle"
                  objectValue={feedback && feedback.libelle}
                />
              </Col>
              <Col span={18}>
                <CustomInput
                  label="Détail"
                  inputName="texte"
                  formItemName="texte"
                  objectValue={feedback && feedback.texte}
                  rules={{ required: true, message: "Détail obligatoire" }}
                />
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={6}>
                <Form.Item name="note">
                  <Rate value={feedback && feedback.note} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="selection" valuePropName="checked">
                  <Checkbox>Sélection ?</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Button type="primary" htmlType="submit" style={{ float: "right" }}>
                Valider
              </Button>
            </Row>
          </Form>
        </Modal>

        <Row style={{ padding: 5 }}>
          <Button type="primary" onClick={this.openModal}>
            Ajout Feedback
          </Button>
        </Row>

        {location && (
          <Row style={{ padding: 5 }}>
            <Table
              className="table-feedbacks"
              columns={columns}
              dataSource={location.feedbacks}
              rowKey="id"
              pagination={false}
              scroll={{ y: 600 }}
            />
          </Row>
        )}
      </>
    );
  }
}

export default TableFeedback;

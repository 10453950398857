import { HttpMethod, initFetch } from "../../utils/fetcher";
import {
  adaptedToProprietaireAffectation,
  proprietaireAffectationToAdapted,
} from "../../adapter/proprietaireAffectationAdapter";

export const SAVE_SUCCESS_PROPRIETAIRES_AFFECTATIONS = "SAVE_SUCCESS_PROPRIETAIRES_AFFECTATIONS";
export const SAVE_FAIL_PROPRIETAIRES_AFFECTATIONS = "SAVE_FAIL_PROPRIETAIRES_AFFECTATIONS";
export const UPDATE_SUCCESS_PROPRIETAIRES_AFFECTATIONS =
  "UPDATE_SUCCESS_PROPRIETAIRES_AFFECTATIONS";
export const UPDATE_FAIL_PROPRIETAIRES_AFFECTATIONS = "UPDATE_FAIL_PROPRIETAIRES_AFFECTATIONS";

export function saveProprietaireAffectation(jsonData, proprietaire_id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch("proprietairesAffectations", HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToProprietaireAffectation(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(proprietaireAffectationToAdapted(res.proprietaire_affectation));
          dispatch({
            type: SAVE_SUCCESS_PROPRIETAIRES_AFFECTATIONS,
            payload: proprietaireAffectationToAdapted(res.proprietaire_affectation),
            id: proprietaire_id,
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_PROPRIETAIRES_AFFECTATIONS,
            error: err,
          })
        );
    });
  };
}

export function updateProprietaireAffectation(jsonData, proprietaire_id, societe_id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(
        `proprietairesAffectations/${proprietaire_id}/${societe_id}`,
        HttpMethod.PUT
      );
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToProprietaireAffectation(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(proprietaireAffectationToAdapted(res.proprietaire_affectation));
          dispatch({
            type: UPDATE_SUCCESS_PROPRIETAIRES_AFFECTATIONS,
            payload: proprietaireAffectationToAdapted(res.proprietaire_affectation),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_PROPRIETAIRES_AFFECTATIONS,
            error: err,
          })
        );
    });
  };
}

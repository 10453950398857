import {
  adaptedToChampPerso,
  champPersoToAdapted,
  adaptedToChampsPersos,
  champsPersosToAdapted,
} from "../../adapter/champPersoAdapter";
import { HttpMethod, initFetch } from "../../utils/fetcher";
import { UPDATE_SUCCESS_ORDRE_CHAMPS_PERSOS } from "./champPersoGroupeAction";

export const LOAD_SUCCESS_CHAMPS_PERSOS = "LOAD_SUCCESS_CHAMPS_PERSOS";
export const LOAD_FAIL_CHAMPS_PERSOS = "LOAD_FAIL_CHAMPS_PERSOS";
export const SAVE_SUCCESS_CHAMPS_PERSOS = "SAVE_SUCCESS_CHAMPS_PERSOS";
export const SAVE_FAIL_CHAMPS_PERSOS = "SAVE_FAIL_CHAMPS_PERSOS";
export const UPDATE_SUCCESS_CHAMPS_PERSOS = "UPDATE_SUCCESS_CHAMPS_PERSOS";
export const UPDATE_FAIL_CHAMPS_PERSOS = "UPDATE_FAIL_CHAMPS_PERSOS";
export const DELETE_SUCCESS_CHAMPS_PERSOS = "DELETE_SUCCESS_CHAMPS_PERSOS";
export const DELETE_FAIL_CHAMPS_PERSOS = "DELETE_FAIL_CHAMPS_PERSOS";

export function loadChampsPersos() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`champsPersos`, HttpMethod.GET);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.champ_perso);
          dispatch({
            type: LOAD_SUCCESS_CHAMPS_PERSOS,
            payload: champsPersosToAdapted(res.champ_perso),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_CHAMPS_PERSOS,
            error: err,
          });
        });
    });
  };
}

export function saveChampPerso(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`champsPersos`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToChampPerso(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.champ_perso);
          dispatch({
            type: SAVE_SUCCESS_CHAMPS_PERSOS,
            payload: champPersoToAdapted(res.champ_perso),
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_CHAMPS_PERSOS,
            error: err,
          })
        );
    });
  };
}

export function updateChampPerso(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`champsPersos/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToChampPerso(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.champ_perso);
          dispatch({
            type: UPDATE_SUCCESS_CHAMPS_PERSOS,
            payload: champPersoToAdapted(res.champ_perso),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_CHAMPS_PERSOS,
            error: err,
          })
        );
    });
  };
}

export function updateOrdreChampPerso(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`ordre/champsPersos`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToChampsPersos(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.champ_perso);
          dispatch({
            type: UPDATE_SUCCESS_ORDRE_CHAMPS_PERSOS,
            payload: champsPersosToAdapted(res.champ_perso),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_CHAMPS_PERSOS,
            error: err,
          })
        );
    });
  };
}

export function deleteChampPerso(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`champsPersos/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.champ_perso);
          dispatch({
            type: DELETE_SUCCESS_CHAMPS_PERSOS,
            payload: champPersoToAdapted(res.champ_perso),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_CHAMPS_PERSOS,
            error: err,
          });
        });
    });
  };
}

import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Spin } from "antd";

import ListActions from "../../components/ListActions";
import { columns } from "./columns";

import { loadEtapes } from "../../store/actions/etapeAction";

class Actions extends Component {
  componentDidMount() {
    const etablissement_id = this.props.utilisateur.etablissement_id;
    this.props.loadEtapes(etablissement_id);
  }

  render() {
    const { etapes } = this.props;

    return (
      <Spin spinning={!etapes} size="large" tip="Chargement en cours...">
        <Card title="" bordered={false} className="card-panel">
          <ListActions
            listData={
              etapes &&
              etapes
                .sort((a, b) => a.etape_id - b.etape_id)
                .filter((etape) =>
                  etape.etape_etape_action.find((action) => action.etape_action_type === 1)
                )
            }
            tableOption={columns}
            etapes={etapes && etapes.sort((a, b) => a.etape_id - b.etape_id)}
          />
        </Card>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadEtapes: (etablissement_id) => dispatch(loadEtapes(etablissement_id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    etapes: state.etapes.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Actions);

import React, { Component } from "react";
import { Button, Col, Divider, Form, Row, Select, Switch, Tabs } from "antd";
import CustomSelect from "../CustomFormItem/Select";
import CustomInput from "../CustomFormItem/Input";
import FormCompteBancaire from "../FormCompteBancaire";

const { Option, OptGroup } = Select;

class FormJournal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: "01",
      societe: null,
      compteBancaire: false,
    };
  }

  // Référence du formulaire
  formRef = React.createRef();

  componentDidMount() {
    this.props.journal ? this.initJournal(this.props.journal) : this.resetJournal();
  }

  UNSAFE_componentWillReceiveProps(props) {
    props.journal ? this.initJournal(props.journal) : this.resetJournal();
  }

  initJournal = (data) => {
    this.resetJournal();

    const compteBancaire = data.journal_compte_bancaire;

    this.formRef.current.setFieldsValue({
      journal_societe_id: data.journal_societe_id,
      journal_code: data.journal_code,
      journal_libelle: data.journal_libelle,
      journal_contrepartie: data.journal_contrepartie,
      journal_compte_id: data.journal_compte ? data.journal_compte.compte_id : null,
      journal_liaison_reddition: data.journal_liaison_reddition,
      journal_reglement_direct: data.journal_reglement_direct,
      journal_de_banque: data.journal_de_banque,
      compte_bancaire_titulaire: compteBancaire.compte_bancaire_titulaire,
      compte_bancaire_banque: compteBancaire.compte_bancaire_banque,
      compte_bancaire_code_banque: compteBancaire.compte_bancaire_code_banque,
      compte_bancaire_code_guichet: compteBancaire.compte_bancaire_code_guichet,
      compte_bancaire_num_compte: compteBancaire.compte_bancaire_num_compte,
      compte_bancaire_cle_RIB: compteBancaire.compte_bancaire_cle_RIB,
      compte_bancaire_IBAN: compteBancaire.compte_bancaire_IBAN,
      compte_bancaire_BIC: compteBancaire.compte_bancaire_BIC,
      compte_bancaire_type_paiement_id:
        compteBancaire.compte_bancaire_type_paiement.type_paiement_id,
    });
  };

  resetJournal = () => {
    this.formRef.current.resetFields([
      "journal_societe_id",
      "journal_code",
      "journal_libelle",
      "journal_contrepartie",
      "journal_compte_id",
      "journal_liaison_reddition",
      "journal_reglement_direct",
      "journal_de_banque",
      "compte_bancaire_titulaire",
      "compte_bancaire_banque",
      "compte_bancaire_code_banque",
      "compte_bancaire_code_guichet",
      "compte_bancaire_num_compte",
      "compte_bancaire_cle_RIB",
      "compte_bancaire_IBAN",
      "compte_bancaire_BIC",
      "compte_bancaire_type_paiement_id",
    ]);
  };

  onChangeTab = (key) => {
    this.setState({ key, compteBancaire: key === "2" ? true : false });
  };

  onChangeSociete = (id) => {
    this.setState({ societe: id });
  };

  onFinish = (data) => {
    if (!data.compte_bancaire_type_paiement_id) {
      const typePaie = this.props.typesPaiements.find(
        (item) => item.type_paiement_libelle === "Chèque"
      );
      data.compte_bancaire_type_paiement_id = typePaie.type_paiement_id;
      data.compte_bancaire_BIC = "";
      data.compte_bancaire_IBAN = "";
      data.compte_bancaire_banque = "";
      data.compte_bancaire_titulaire = "";
    }
    data.journal_compte_bancaire_id = this.props.journal
      ? this.props.journal.journal_compte_bancaire.compte_bancaire_id
      : null;
    if (this.state.compteBancaire === false) {
      data.compte_bancaire_titulaire =
        this.props.journal.journal_compte_bancaire.compte_bancaire_titulaire;
      data.compte_bancaire_banque =
        this.props.journal.journal_compte_bancaire.compte_bancaire_banque;
      data.compte_bancaire_code_banque =
        this.props.journal.journal_compte_bancaire.compte_bancaire_code_banque;
      data.compte_bancaire_code_guichet =
        this.props.journal.journal_compte_bancaire.compte_bancaire_code_guichet;
      data.compte_bancaire_num_compte =
        this.props.journal.journal_compte_bancaire.compte_bancaire_num_compte;
      data.compte_bancaire_cle_RIB =
        this.props.journal.journal_compte_bancaire.compte_bancaire_cle_RIB;
      data.compte_bancaire_IBAN = this.props.journal.journal_compte_bancaire.compte_bancaire_IBAN;
      data.compte_bancaire_BIC = this.props.journal.journal_compte_bancaire.compte_bancaire_BIC;
      data.compte_bancaire_type_paiement_id =
        this.props.journal.journal_compte_bancaire.compte_bancaire_type_paiement.type_paiement_id;
    }
    this.props.journal
      ? this.props.handleEdit(data, this.props.journal.journal_id)
      : this.props.handleSubmit(data);
  };

  render() {
    const { journal, societes, comptes, typesPaiements } = this.props;

    return (
      <div>
        <Form ref={this.formRef} onFinish={this.onFinish} size="large" className="form-requete">
          <Tabs
            defaultActiveKey="1"
            onChange={(key) => this.onChangeTab(key)}
            items={[
              {
                label: "Informations",
                key: "1",
                children: (
                  <Row gutter={16}>
                    <Col span={12} style={{ padding: 20 }}>
                      <Divider orientation="left">Identification</Divider>
                      <Row gutter={8}>
                        <Col span={16}>
                          <CustomSelect
                            label="Société"
                            inputName="societe"
                            formItemName="journal_societe_id"
                            objectValue={journal && journal.journal_societe_id}
                            onChange={(id) => this.onChangeSociete(id)}
                          >
                            <OptGroup label="Société">
                              {societes &&
                                societes.map((item, i) => {
                                  return (
                                    <Option
                                      key={i}
                                      value={item.societe_id}
                                      label={item.societe_raison_sociale}
                                    >
                                      <Row>
                                        <Col sm={8}>{item.societe_raison_sociale}</Col>
                                      </Row>
                                    </Option>
                                  );
                                })}
                            </OptGroup>
                          </CustomSelect>
                        </Col>
                        <Col span={8}>
                          <CustomInput
                            label="Code"
                            inputName="code"
                            formItemName="journal_code"
                            objectValue={journal && journal.journal_code}
                          />
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={24}>
                          <CustomInput
                            label="Libellé"
                            inputName="libelle"
                            formItemName="journal_libelle"
                            objectValue={journal && journal.journal_libelle}
                          />
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={12}>
                          <Form.Item name="journal_contrepartie">
                            <Switch
                              checkedChildren="Associé à un compte de contrepartie"
                              unCheckedChildren="Associé à un compte de contrepartie"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <CustomSelect
                            label="Compte"
                            inputName="compte"
                            formItemName="journal_compte_id"
                            objectValue={
                              journal && journal.journal_compte && journal.journal_compte.compte_id
                            }
                          >
                            <OptGroup label="Compte">
                              {comptes &&
                                comptes
                                  .filter((item) => item.compte_type_compte !== "A")
                                  .map((item, i) => {
                                    return (
                                      <Option
                                        key={i}
                                        value={item.compte_id}
                                        label={`${item.compte_compte_general} - ${item.compte_libelle}`}
                                      >
                                        <Row>
                                          <Col
                                            sm={8}
                                          >{`${item.compte_compte_general} - ${item.compte_libelle}`}</Col>
                                        </Row>
                                      </Option>
                                    );
                                  })}
                            </OptGroup>
                          </CustomSelect>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={12}>
                          <Form.Item name="journal_liaison_reddition" valuePropName="checked">
                            <Switch
                              checkedChildren="Pas de liaison avec les redditions"
                              unCheckedChildren="Pas de liaison avec les redditions"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name="journal_reglement_direct" valuePropName="checked">
                            <Switch
                              checkedChildren="Reglement direct au proprietaire"
                              unCheckedChildren="Reglement direct au proprietaire"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={12}>
                          <Form.Item name="journal_de_banque" valuePropName="checked">
                            <Switch
                              checkedChildren="Journal de banque"
                              unCheckedChildren="Journal de banque"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ),
              },
              {
                label: "Informations financières",
                key: "2",
                children: (
                  <>
                    <FormCompteBancaire
                      utilisateur={this.props.utilisateur}
                      compteBancaire={journal && journal.journal_compte_bancaire}
                      typesPaiements={typesPaiements}
                    />
                    <Row style={{ marginTop: 10, float: "right" }}>
                      <Button type="primary" danger>
                        Annulation
                      </Button>
                      <Button type="primary" htmlType="submit" style={{ marginLeft: 10 }}>
                        Validation
                      </Button>
                    </Row>
                  </>
                ),
              },
            ]}
          />
        </Form>
      </div>
    );
  }
}

export default FormJournal;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Spin } from "antd";

import ListEcartReddition from "../../components/ListEcartReddition";
import { columns } from "./columns";

import { loadProprietaires } from "../../store/actions/proprietaireAction";
import { loadLocations } from "../../store/actions/locationAction";
import { loadQuittances } from "../../store/actions/quittanceAction";
import { loadReglements } from "../../store/actions/reglementAction";
import { loadEcritures } from "../../store/actions/ecritureAction";

class EcartReddition extends Component {
  componentDidMount() {
    const groupement_id = this.props.utilisateur.groupement_id;
    const societe_id = this.props.utilisateur.societe_id;
    Promise.all([
      !this.props.proprietaires && this.props.loadProprietaires(groupement_id),
      !this.props.locations && this.props.loadLocations(groupement_id),
      !this.props.quittances && this.props.loadQuittances(groupement_id),
      !this.props.reglements && this.props.loadReglements(societe_id),
      !this.props.ecritures && this.props.loadEcritures(societe_id),
    ]);
  }

  render() {
    const { utilisateur, proprietaires, locations, quittances, reglements, ecritures } = this.props;

    return (
      <Spin
        spinning={!proprietaires || !locations || !quittances || !reglements || !ecritures}
        size="large"
        tip="Chargement en cours..."
      >
        <Card title="" bordered={false} className="card-panel">
          {proprietaires && locations && quittances && reglements && ecritures && (
            <ListEcartReddition
              tableOption={columns}
              locations={
                locations &&
                locations.filter(
                  (l) =>
                    l.location_etat === "r" &&
                    l.location_lot.lot_societe_id === utilisateur.societe_id
                )
              }
              listData={
                proprietaires &&
                proprietaires.filter(
                  (p) =>
                    p.proprietaire_affectations.some(
                      (ligne) =>
                        ligne.proprietaire_affectation_societe.societe_id === utilisateur.societe_id
                    ) && p.proprietaire_personne.personne_actif === true
                )
              }
              quittances={quittances}
              reglements={reglements}
              ecritures={ecritures}
            />
          )}
        </Card>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadProprietaires: (groupement_id) => dispatch(loadProprietaires(groupement_id)),
    loadLocations: (groupement_id) => dispatch(loadLocations(groupement_id)),
    loadQuittances: (groupement_id) => dispatch(loadQuittances(groupement_id)),
    loadReglements: (societe_id) => dispatch(loadReglements(societe_id)),
    loadEcritures: (societe_id) => dispatch(loadEcritures(societe_id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    proprietaires: state.proprietaires.data,
    locations: state.locations.data,
    quittances: state.quittances.data,
    reglements: state.reglements.data,
    ecritures: state.ecritures.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EcartReddition);

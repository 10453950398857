import {
  adaptedToEcriture,
  adaptedToEcritures,
  ecritureToAdapted,
  ecrituresToAdapted,
} from "../../adapter/ecritureAdapter";
import { adaptedToEcritureLigne, ecritureLigneToAdapted } from "../../adapter/ecritureLigneAdapter";
import { HttpMethod, initFetch } from "../../utils/fetcher";

export const LOAD_SUCCESS_ECRITURES = "LOAD_SUCCESS_ECRITURES";
export const LOAD_FAIL_ECRITURES = "LOAD_FAIL_ECRITURES";
export const SAVE_SUCCESS_ECRITURES = "SAVE_SUCCESS_ECRITURES";
export const SAVE_FAIL_ECRITURES = "SAVE_FAIL_ECRITURES";
export const UPDATE_SUCCESS_ALL_ECRITURES = "UPDATE_SUCCESS_ALL_ECRITURES";
export const UPDATE_FAIL_ALL_ECRITURES = "UPDATE_FAIL_ALL_ECRITURES";
export const UPDATE_SUCCESS_ECRITURES = "UPDATE_SUCCESS_ECRITURES";
export const UPDATE_FAIL_ECRITURES = "UPDATE_FAIL_ECRITURES";
export const DELETE_SUCCESS_ECRITURES = "DELETE_SUCCESS_ECRITURES";
export const DELETE_FAIL_ECRITURES = "DELETE_FAIL_ECRITURES";

export const UPDATE_SUCCESS_ECRITURES_LIGNES = "UPDATE_SUCCESS_ECRITURES_LIGNES";
export const UPDATE_FAIL_ECRITURES_LIGNES = "UPDATE_FAIL_ECRITURES_LIGNES";

export function loadEcritures() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`ecritures`, HttpMethod.GET);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.ecriture);
          dispatch({
            type: LOAD_SUCCESS_ECRITURES,
            payload: ecrituresToAdapted(res.ecriture),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_ECRITURES,
            error: err,
          });
        });
    });
  };
}

export function saveEcriture(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`ecritures`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToEcriture(jsonData)) })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(ecritureToAdapted(res.ecriture));
          dispatch({
            type: SAVE_SUCCESS_ECRITURES,
            payload: ecritureToAdapted(res.ecriture),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: SAVE_FAIL_ECRITURES,
            error: err,
          });
        });
    });
  };
}

export function updateEcriture(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`ecritures/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToEcriture(jsonData)) })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(ecritureToAdapted(res.ecriture));
          dispatch({
            type: UPDATE_SUCCESS_ECRITURES,
            payload: ecritureToAdapted(res.ecriture),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: UPDATE_FAIL_ECRITURES,
            error: err,
          });
        });
    });
  };
}

export function updateEcritures(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`updateEcritures`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToEcritures(jsonData)) })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(ecrituresToAdapted(res.ecriture));
          dispatch({
            type: UPDATE_SUCCESS_ALL_ECRITURES,
            payload: ecrituresToAdapted(res.ecriture),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: UPDATE_FAIL_ALL_ECRITURES,
            error: err,
          });
        });
    });
  };
}

export function updateLigneEcriture(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`ecrituresLignes/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToEcritureLigne(jsonData)) })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(ecritureLigneToAdapted(res.ecriture_ligne));
          dispatch({
            type: UPDATE_SUCCESS_ECRITURES_LIGNES,
            payload: ecritureLigneToAdapted(res.ecriture_ligne),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: UPDATE_FAIL_ECRITURES_LIGNES,
            error: err,
          });
        });
    });
  };
}

export function deleteEcriture(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`ecritures/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.ecriture);
          dispatch({
            type: DELETE_SUCCESS_ECRITURES,
            payload: ecritureToAdapted(res.ecriture),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_ECRITURES,
            payload: err,
          });
        });
    });
  };
}

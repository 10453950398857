import React, { Component } from "react";
import { Table, Button, Modal, Row, Col, Form } from "antd";
import CustomInput from "../CustomFormItem/Input";
import CustomDatePicker from "../CustomFormItem/DatePicker";

import dayjs from "dayjs";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

// import { formatDateToBDD } from "../../utils/tools";

class TableEvenements extends Component {
  state = {
    modalEvenement: false,
    evenement: null,
  };

  // Référence du formulaire
  formRef = React.createRef();

  onSelectEvenement = (evenement) => {
    this.setState({ modalEvenement: true, evenement: evenement }, () => {
      if (evenement) {
        this.formRef.current.resetFields();
        this.formRef.current.setFieldsValue({
          evenement_date_creation: dayjs(evenement.evenement_date_creation, "YYYY-MM-DD"),
          evenement_detail: evenement.evenement_detail,
        });
      }
    });
  };

  onDeleteEvenement = (id) => {
    this.props.deleteEvenement(id);
  };

  openModalEvenement = () => {
    this.setState({ modalEvenement: true });
  };

  closeModalEvenement = () => {
    this.setState({ modalEvenement: false, evenement: null });
    this.formRef.current.resetFields(["evenement_detail"]);
  };

  onAddEvenement = (data) => {
    if (this.state.evenement === null) {
      this.props.saveEvenement({
        evenement_personne_id: this.props.proprietaire
          ? this.props.personne.proprietaire_personne.personne_id
          : this.props.personne.locataire_personne.personne_id,
        evenement_date_creation: dayjs(data.evenement_date_creation).format("YYYY-MM-DD"),
        evenement_detail: data.evenement_detail,
      });
    } else {
      this.props.updateEvenement(
        {
          evenement_personne_id: this.state.evenement.evenement_personne.personne_id,
          evenement_date_creation: dayjs(data.evenement_date_creation, "YYYY-MM-DD").format(
            "YYYY-MM-DD"
          ),
          evenement_detail: data.evenement_detail,
        },
        this.state.evenement.evenement_id
      );
    }
    this.closeModalEvenement();
  };

  render() {
    const { lignesEvenements } = this.props;
    const { modalEvenement, evenement } = this.state;

    let columns = [
      // {
      //   title: "n°",
      //   dataIndex: "evenement_id",
      //   key: "evenement_id",
      //   align: "right",
      //   width: "8%",
      // },
      {
        title: "Date",
        dataIndex: "evenement_date_creation",
        key: "evenement_date_creation",
        width: "8%",
        render: (date) => dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
        sorter: (a, b) =>
          dayjs(b.evenement_date_creation, "DD/MM/YYYY").unix() -
          dayjs(a.evenement_date_creation, "DD/MM/YYYY").unix(),
      },
      {
        title: "Détail",
        dataIndex: "evenement_detail",
        key: "evenement_detail",
        width: "76%",
        autoSize: { minRows: 3, maxRows: 3 },
      },
      {
        title: "Modifier",
        width: "8%",
        dataIndex: "evenement",
        key: "evenement_id",
        render: (evenement_id, evenement) => (
          <Button
            icon={<EditOutlined />}
            shape="circle"
            onClick={() => this.onSelectEvenement(evenement)}
          />
        ),
      },
      {
        title: "Supprimer",
        width: "8%",
        dataIndex: "evenement_id",
        key: "evenement_id",
        render: (evenement_id) => (
          <Button
            icon={<DeleteOutlined />}
            shape="circle"
            onClick={() => this.onDeleteEvenement(evenement_id)}
          />
        ),
      },
    ];

    return (
      <>
        <div>
          <Modal
            footer={null}
            width="1000px"
            title="Evenement"
            open={modalEvenement}
            onCancel={this.closeModalEvenement}
          >
            <Form ref={this.formRef} onFinish={this.onAddEvenement}>
              <Row gutter={12}>
                <Col span={6}>
                  <CustomDatePicker
                    label="Date création"
                    inputName="evenement_date_creation"
                    formItemName="evenement_date_creation"
                    objectValue={dayjs()}
                  />
                </Col>
                <Col span={18}>
                  <CustomInput
                    label="Détail"
                    inputName="evenement_detail"
                    formItemName="evenement_detail"
                    objectValue={evenement && evenement.evenement_detail}
                    rules={{ required: true, message: "Détail obligatoire" }}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "10px" }}>
                <Button type="primary" htmlType="submit" style={{ float: "right" }}>
                  Valider
                </Button>
              </Row>
            </Form>
          </Modal>

          <Row>
            <Button type="primary" onClick={this.openModalEvenement}>
              Ajout Note
            </Button>
          </Row>

          <Table
            className="table-evenements"
            columns={columns}
            dataSource={lignesEvenements}
            rowKey="evenement_id"
            pagination={false}
            scroll={{ y: 600 }}
          />
        </div>
      </>
    );
  }
}

export default TableEvenements;

import {
  adaptedToEvenement,
  evenementToAdapted,
  evenementsToAdapted,
} from "../../adapter/evenementAdapter";
import { HttpMethod, initFetch } from "../../utils/fetcher";

export const LOAD_SUCCESS_EVENEMENTS = "LOAD_SUCCESS_EVENEMENTS";
export const LOAD_FAIL_EVENEMENTS = "LOAD_FAIL_EVENEMENTS";
export const SAVE_SUCCESS_EVENEMENTS = "SAVE_SUCCESS_EVENEMENTS";
export const SAVE_FAIL_EVENEMENTS = "SAVE_FAIL_EVENEMENTS";
export const UPDATE_SUCCESS_EVENEMENTS = "UPDATE_SUCCESS_EVENEMENTS";
export const UPDATE_FAIL_EVENEMENTS = "UPDATE_FAIL_EVENEMENTS";
export const DELETE_SUCCESS_EVENEMENTS = "DELETE_SUCCESS_EVENEMENTS";
export const HANDLE_SUCCESS_ALL_EVENEMENTS = "HANDLE_SUCCESS_ALL_EVENEMENTS";
export const DELETE_FAIL_EVENEMENTS = "DELETE_FAIL_EVENEMENTS";

export function loadEvenements() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`evenements`, HttpMethod.GET);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.evenement);
          dispatch({
            type: LOAD_SUCCESS_EVENEMENTS,
            payload: evenementsToAdapted(res.evenement),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_EVENEMENTS,
            error: err,
          });
        });
    });
  };
}

export function saveEvenement(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`evenements`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToEvenement(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.evenement);
          dispatch({
            type: SAVE_SUCCESS_EVENEMENTS,
            payload: evenementToAdapted(res.evenement),
          });
        })
        .catch((err) => {
          dispatch({
            type: SAVE_FAIL_EVENEMENTS,
            error: err,
          });
        });
    });
  };
}

export function updateEvenement(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`evenements/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToEvenement(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.evenement);
          dispatch({
            type: UPDATE_SUCCESS_EVENEMENTS,
            payload: evenementToAdapted(res.evenement),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_EVENEMENTS,
            error: err,
          })
        );
    });
  };
}

export function deleteEvenement(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`evenements/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.evenement);
          dispatch({
            type: DELETE_SUCCESS_EVENEMENTS,
            payload: evenementToAdapted(res.evenement),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_EVENEMENTS,
            error: err,
          });
        });
    });
  };
}

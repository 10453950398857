import React, { Component } from "react";
import { Table, Button, Modal, Row, Col, Form, Checkbox } from "antd";
import CustomInput from "../CustomFormItem/Input";
import CustomDatePicker from "../CustomFormItem/DatePicker";

import dayjs from "dayjs";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

class TableNote extends Component {
  state = {
    modal: false,
    note: null,
  };

  // Référence du formulaire
  formRef = React.createRef();

  onCheckFin = (e, note) => {
    note.fin = e.target.checked;
    this.props.updateNote(note, note.id);
  };

  onSelectNote = (note) => {
    this.setState({ modal: true, note: note }, () => {
      if (note) {
        this.formRef.current.resetFields();
        this.formRef.current.setFieldsValue({
          date_creation: dayjs(note.date_creation, "YYYY-MM-DD"),
          texte: note.texte,
        });
      }
    });
  };

  onDeleteNote = (id) => {
    this.props.deleteNote(id);
  };

  openModal = () => {
    this.setState({ modal: true }, () =>
      this.formRef.current.setFieldsValue({ date_creation: dayjs() })
    );
  };

  closeModal = () => {
    this.setState({ modal: false, note: null });
    this.formRef.current.resetFields(["texte"]);
  };

  onAddNote = (data) => {
    if (this.state.note === null) {
      this.props.saveNote(
        this.props.requete
          ? {
              date_creation: dayjs(data.date_creation).format("YYYY-MM-DD"),
              texte: data.texte,
              requete_id: this.props.data.requete_id,
              user_id: this.props.utilisateur.utilisateur_id,
            }
          : {
              date_creation: dayjs(data.date_creation).format("YYYY-MM-DD"),
              texte: data.texte,
              lot_id: this.props.data.lot_id,
              user_id: this.props.utilisateur.utilisateur_id,
            }
      );
    } else {
      this.props.updateNote(
        this.props.requete
          ? {
              date_creation: dayjs(data.date_creation, "YYYY-MM-DD").format("YYYY-MM-DD"),
              texte: data.texte,
              requete_id: this.props.data.requete_id,
              fin: false,
            }
          : {
              date_creation: dayjs(data.date_creation, "YYYY-MM-DD").format("YYYY-MM-DD"),
              texte: data.texte,
              lot_id: this.props.data.lot_id,
              fin: false,
            },
        this.state.note.id
      );
    }
    this.closeModal();
  };

  render() {
    const { utilisateur, data } = this.props;
    const { modal, note } = this.state;

    const admin = utilisateur.utilisateur_admin;

    let columns = [
      {
        title: "Date",
        dataIndex: "date_creation",
        key: "date_creation",
        width: "8%",
        render: (date) => dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
        sorter: (a, b) =>
          dayjs(b.date_creation, "DD/MM/YYYY").unix() - dayjs(a.date_creation, "DD/MM/YYYY").unix(),
      },
      {
        title: "Détail",
        dataIndex: "texte",
        key: "texte",
        width: "48%",
        autoSize: { minRows: 3, maxRows: 3 },
      },
      {
        title: "Utilisateur",
        dataIndex: "utilisateur",
        key: "utilisateur",
        render: (utilisateur) => (utilisateur ? utilisateur.nom : ""),
        width: "20%",
      },
      {
        title: "Traitée",
        width: "8%",
        dataIndex: "fin",
        key: "fin",
        render: (fin, note) => (
          <Checkbox checked={fin} onChange={(e) => this.onCheckFin(e, note)}></Checkbox>
        ),
      },
      {
        title: "Modifier",
        width: "8%",
        dataIndex: "note",
        key: "id",
        render: (id, note) => (
          <Button icon={<EditOutlined />} shape="circle" onClick={() => this.onSelectNote(note)} />
        ),
      },
      {
        title: "Supprimer",
        width: "8%",
        dataIndex: "id",
        key: "id",
        render: (id) => (
          <Button
            icon={<DeleteOutlined />}
            disabled={!admin}
            shape="circle"
            onClick={() => this.onDeleteNote(id)}
          />
        ),
      },
    ];

    return (
      <>
        <Modal footer={null} width="1000px" title="Note" open={modal} onCancel={this.closeModal}>
          <Form ref={this.formRef} onFinish={this.onAddNote}>
            <Row gutter={12}>
              <Col span={6}>
                <CustomDatePicker
                  label="Date création"
                  inputName="date_creation"
                  formItemName="date_creation"
                  objectValue={dayjs()}
                />
              </Col>
              <Col span={18}>
                <CustomInput
                  label="Détail"
                  inputName="texte"
                  formItemName="texte"
                  objectValue={note && note.texte}
                  rules={{ required: true, message: "Détail obligatoire" }}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Button type="primary" htmlType="submit" style={{ float: "right" }}>
                Valider
              </Button>
            </Row>
          </Form>
        </Modal>

        <Row style={{ padding: 5 }}>
          <Button type="primary" onClick={this.openModal}>
            Ajout Note
          </Button>
        </Row>

        {data && (
          <Row style={{ padding: 5 }}>
            <Table
              className="table-notes"
              columns={columns}
              dataSource={data.notes}
              rowKey="id"
              pagination={false}
              scroll={{ y: 600 }}
            />
          </Row>
        )}
      </>
    );
  }
}

export default TableNote;

import React, { Component } from "react";
import { Button, Checkbox, Col, Form, Modal, Row, Select, Spin, Tag } from "antd";
import { SyncOutlined } from "@ant-design/icons";

import { BryntumScheduler } from "@bryntum/scheduler-react";
import { schedulerConfig } from "../../utils/schedulerConfig";

import CustomDatePicker from "../../components/CustomFormItem/DatePicker";
import CustomSelect from "../../components/CustomFormItem/Select";
import CustomInput from "../../components/CustomFormItem/Input";

import dayjs from "dayjs";
import FormMenage from "../FormMenage";

const { Option, OptGroup } = Select;

class FormScheduler extends Component {
  state = {
    loading: true,
    lots: null,
    lot: null,
    locations: null,
    menages: null,
    menage: null,
    resources: [],
    events: [],
    checked: false,
    daySelected: null,
    modalMenage: false,
  };

  // Référence du formulaire
  formRef = React.createRef();
  schedulerRef = React.createRef();

  componentDidMount() {
    this.getParametre(this.props.parametre);
  }

  getParametre = (parametre) => {
    this.formRef.current.setFieldsValue({
      location_date_debut: parametre.parametre_scheduler_day_before
        ? dayjs().subtract(parametre.parametre_scheduler_day_before, "day")
        : dayjs().add(-15, "day"),
      location_date_fin: parametre.parametre_scheduler_day_after
        ? dayjs().add(parametre.parametre_scheduler_day_after, "day")
        : dayjs().add(90, "day"),
    });
    this.formRef.current.submit();
  };

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (nextProps !== this.props) this.init(nextProps);
  // }

  // init = (props) => {
  //   this.setState({ lots: props.lots, locations: props.locations }, () => this.fillScheduler());
  // };

  fillScheduler = () => {
    let resourcesWParents = [];
    this.props.typesLots.forEach((type) => {
      resourcesWParents.push({
        id: type.type_lot_id,
        name: type.type_lot_libelle,
        expanded: true,
        children: [],
      });
    });

    resourcesWParents.push({ id: 0, name: "Autre", children: [] });

    const resources = this.state.lots
      ?.filter((lot) => lot.lot_fin === false)
      .sort((a, b) => a.lot_designation?.localeCompare(b.lot_designation))
      .map((lot) => {
        return {
          id: lot.lot_id,
          name: this.props.parametre.parametre_scheduler_afficher_reference
            ? `${lot.lot_reference ? lot.lot_reference.substring(0, 7) : ""} / ${
                lot.lot_designation ? lot.lot_designation.substring(0, 19) : ""
              }`
            : `${lot.lot_designation ? lot.lot_designation.substring(0, 19) : ""}`,
          type_lot_id: lot.lot_type_lot?.type_lot_id,
        };
      });

    resourcesWParents.forEach((parent) => {
      parent.id !== 0
        ? (parent.children = resources.filter((resource) => resource.type_lot_id === parent.id))
        : (parent.children = resources.filter((resource) => resource.type_lot_id === undefined));
    });

    resourcesWParents = resourcesWParents.filter((parent) => parent.children.length > 0);

    const events = this.state.locations
      ?.filter((location) => location.location_etat !== "a")
      .map((location, i) => {
        return {
          id: i,
          resourceId: location.location_lot.lot_id,
          startDate: dayjs(location.location_date_debut).set("hour", 12).toDate(),
          endDate: dayjs(location.location_date_fin).set("hour", 12).toDate(),
          debut: dayjs(location.location_date_debut).format("DD/MM/YYYY"),
          fin: dayjs(location.location_date_fin).format("DD/MM/YYYY"),
          heure: "",
          nuits: dayjs(location.location_date_fin).diff(dayjs(location.location_date_debut), "day"),
          name: this.getNameLocation(location),
          etat: this.getEventConfig(location, location.location_etat).libelle,
          type: "",
          agent: "",
          requete: location.location_requete,
          locataire:
            location.location_requete && location.location_requete?.requete_locataire
              ? `${location.location_requete.requete_locataire.locataire_personne.personne_nom}
            ${location.location_requete.requete_locataire.locataire_personne.personne_prenom}`
              : ``,
          lot: location.location_lot,
          heureArrivee: location.location_heure_arrivee,
          heureDepart: location.location_heure_depart,
          location_nb_adultes: location.location_nb_adultes,
          location_nb_enfants: location.location_nb_enfants,
          location_nb_bebes: location.location_nb_bebes,
          location_nb_personnes:
            location.location_nb_adultes +
            location.location_nb_enfants +
            location.location_nb_bebes,
          remarques: location.location_remarques,
          eventMenage: false,
          eventColor: this.getEventConfig(location, location.location_etat).color,
          eventStyle: this.state.checked
            ? this.getClassName(
                this.state.menages.find(
                  (menage) =>
                    menage?.menage_location_id && menage.menage_location_id === location.location_id
                )
              )
            : "",
        };
      });

    if (this.state.checked) {
      this.state.menages.forEach((menage, i) => {
        const location =
          menage.menage_location_id &&
          this.state.locations.find((loc) => loc.location_id === menage.menage_location_id);
        const newMenage = {
          id: 1000 + i,
          resourceId: menage.menage_lot_id,
          startDate: dayjs(menage.menage_date).set("hour", 12).toDate(),
          endDate: dayjs(menage.menage_date).set("hour", 12).toDate(),
          heure: menage.menage_heure,
          name: "",
          etat: this.getEventMenageConfig(menage, menage.menage_etat).libelle,
          type: menage.menage_type,
          agent: menage.menage_agent?.agent_personne.personne_nom,
          location_nb_adultes: location && location.location_nb_adultes,
          location_nb_enfants: location && location.location_nb_enfants,
          location_nb_bebes: location && location.location_nb_bebes,
          location_nb_personnes:
            location &&
            location.location_nb_adultes +
              location.location_nb_enfants +
              location.location_nb_bebes,
          remarques: location && location.location_remarques,
          eventMenage: true,
          menage: menage,
          eventStyle: this.getEventMenageConfig(menage, menage.menage_etat).className,
          iconCls: "b-fa b-fa-circle",
        };
        events.push(newMenage);
      });
    }

    this.setState({ resources: resourcesWParents, events }, () =>
      !this.state.checked ? this.setDefaultConfig() : this.setState({ loading: false })
    );
  };

  setDefaultConfig = () => {
    this.schedulerRef.current.instance.zoomTo(10);
    this.schedulerRef.current.instance.startDate = dayjs().subtract(1, "week").toDate();
    this.schedulerRef.current.instance.endDate = dayjs().endOf("year").toDate();
    this.schedulerRef.current.instance.scrollToDate(dayjs().subtract(1, "week").toDate());
    this.setState({ loading: false });
    // this.schedulerRef.current.instance.zoomLevel = 9;
  };

  onFinish = (data) => {
    this.setState({ loading: true }, () => {
      let lotsFiltered = this.props.lots;
      let locationsFiltered = this.props.locations;
      let menagesFiltered = this.props.menages;

      // Date début
      if (data.location_date_debut)
        locationsFiltered = locationsFiltered.filter(
          (location) =>
            dayjs(location.location_date_debut, "YYYY-MM-DD").isSameOrAfter(
              data.location_date_debut
            ) ||
            dayjs(location.location_date_fin, "YYYY-MM-DD").isSameOrAfter(data.location_date_debut)
        );

      // Date fin
      if (data.location_date_fin)
        locationsFiltered = locationsFiltered.filter(
          (location) =>
            dayjs(location.location_date_fin, "YYYY-MM-DD").isSameOrBefore(
              data.location_date_fin
            ) ||
            dayjs(location.location_date_debut, "YYYY-MM-DD").isSameOrBefore(data.location_date_fin)
        );

      // Type lot
      if (data.lot_type_lot_id)
        lotsFiltered = lotsFiltered.filter((lot) =>
          lot.lot_type_lot ? lot.lot_type_lot.type_lot_id === data.lot_type_lot_id : false
        );

      // Nb couchage
      if (data.lot_couchage)
        lotsFiltered = lotsFiltered.filter((lot) => lot.lot_couchage >= data.lot_couchage);

      // Budget min

      // Budget max

      // Set states
      this.setState(
        {
          lots: lotsFiltered,
          locations: locationsFiltered,
          menages: menagesFiltered,
          loading: true,
        },
        () => this.fillScheduler()
      );
    });
  };

  reset = () => {
    this.formRef.current.resetFields();
    this.formRef.current.setFieldsValue({
      location_date_debut: this.props.parametre.parametre_scheduler_date_debut
        ? dayjs().subtract(this.props.parametre.parametre_scheduler_day_before, "day")
        : dayjs().add(-15, "day"),
      location_date_fin: this.props.parametre.parametre_scheduler_date_fin
        ? dayjs().add(this.props.parametre.parametre_scheduler_day_after, "day")
        : dayjs().add(90, "day"),
    });
  };

  getNameLocation = (location) => {
    let name = "";
    if (location.location_etat === "r" || location.location_etat === "o") {
      name = `${location.location_requete?.requete_locataire?.locataire_personne.personne_nom} 
      ${location.location_requete?.requete_locataire?.locataire_personne.personne_prenom} (n°${location.location_id})`;
    } else {
      name = `${this.getEventConfig(location, location.location_etat).libelle} n°${
        location.location_id
      }`;
    }
    return name;
  };

  getEventConfig = (location, etat) => {
    let config = { libelle: "", color: "" };
    switch (etat) {
      case "r":
        config.libelle = "Location";
        config.color = this.getColorReservation(location);
        break;
      case "o":
        config.libelle = "Option";
        config.color = "#ffbf00";
        break;
      case "b":
        config.libelle = "Blocage";
        config.color = "#47a5ba";
        break;
      case "n":
        config.libelle = "Non disponible";
        config.color = "#A1AAB3";
        break;
      case "a":
        config.libelle = "Annulation";
        config.color = "red";
        break;
      default:
        config.libelle = "Location";
        config.color = "#4EA655";
        break;
    }
    return config;
  };

  getClassName = (menage) => {
    let className = "location-stripped";
    if (menage !== undefined) {
      if (menage.menage_etat === "p" || menage.menage_etat === "f") className = "location";
    }
    return className;
  };

  getEventMenageConfig = (menage, etat) => {
    let config = { libelle: "", color: "" };
    switch (etat) {
      case "n":
        config.libelle = "Non Planifié";
        config.className = "menage-non-planifie";
        break;
      case "p":
        config.libelle = "Planifié";
        config.className = "menage-planifie";
        break;
      case "f":
        config.libelle = "Fait";
        config.className = "menage-fait";
        break;
      default:
        break;
    }
    return config;
  };

  getColorReservation = (location) => {
    let color = "";
    const quittancesFiltered = this.props.quittances?.filter(
      (quittance) => quittance.quittance_location_id === location.location_id
    );
    let quittancesLignes = [];
    if (quittancesFiltered?.length > 0) {
      quittancesFiltered.forEach((quittance) => {
        quittance.quittance_ligne.forEach((ligne) => {
          quittancesLignes.push(ligne);
        });
      });
    }
    const quittancesFilteredId = quittancesFiltered?.map((quittance) => quittance.quittance_id);
    const reglementsFiltered = this.props.reglements?.filter((reglement) =>
      quittancesFilteredId?.includes(reglement.reglement_quittance.quittance_id)
    );
    if (reglementsFiltered?.length > 0) {
      const totalQuittances = quittancesLignes.reduce(
        (a, b) => a + parseFloat(b.quittance_ligne_montant),
        0
      );
      const totalReglements = reglementsFiltered.reduce(
        (a, b) => a + parseFloat(b.reglement_montant),
        0
      );
      if (parseFloat(totalQuittances).toFixed(2) - parseFloat(totalReglements).toFixed(2) <= 0) {
        color = "#B6E2A1";
      } else {
        color = "#FEBE8C";
      }
    } else {
      color = "#FD8A8A";
      // Cas particulier plateforme
      const requete = location.location_requete;
      if (
        requete &&
        requete.requete_provenance &&
        requete.requete_provenance.provenance_plateforme === true
      ) {
        color = "#C991B4";
      }
    }
    return color;
  };

  showRequete = (id) => {
    const location = this.state.locations.find((loc) => loc.location_id === id);
    if (location && location.location_requete) {
      const pane = { key: "26", title: "Contrat", content: "" };
      this.setState({ modalMenage: false }, () =>
        this.props.addPanesRequete(pane, "26", location.location_requete)
      );
    }
  };

  handleRightClick = (data) => {
    // console.log(data, "data");
  };

  handleDbClick = (data, daySelected) => {
    if (!data.eventMenage) {
      const pane = { key: "26", title: "Contrat", content: "" };
      this.props.addPanesRequete(pane, "26", data.requete);
    } else {
      this.setState({ lot: data.resourceId, daySelected: daySelected, menage: data.menage }, () =>
        this.openModalMenage()
      );
    }
  };

  handleSchedulerDbClick = (resource, daySelected) => {
    if (this.state.checked && resource.id)
      this.setState({ lot: resource.id, daySelected, menage: null }, () => this.openModalMenage());
  };

  onCheckboxChange = (e) => {
    this.setState({ checked: e.target.checked }, () => this.fillScheduler());
  };

  openModalMenage = () => {
    this.setState({ modalMenage: true });
  };

  closeModalMenage = () => {
    this.setState({ modalMenage: false });
  };

  handleEditMenage = (data, id) => {
    this.props
      .updateMenage(data, id)
      .then(() => this.setState({ modalMenage: false }, () => this.formRef.current.submit()));
  };

  handleSubmitMenage = (data) => {
    this.props
      .saveMenage(data)
      .then(() => this.setState({ modalMenage: false }, () => this.formRef.current.submit()));
  };

  handleDeleteMenage = (id) => {
    this.props
      .deleteMenage(id)
      .then(() => this.setState({ modalMenage: false }, () => this.formRef.current.submit()));
  };

  render() {
    const { agents, locations, typesLots } = this.props;
    const { loading, resources, events, lot, menage, checked, daySelected, modalMenage } =
      this.state;

    return (
      <>
        <Modal
          footer={null}
          width="800px"
          title="Ménage"
          open={modalMenage}
          onCancel={this.closeModalMenage}
        >
          <FormMenage
            lot={lot}
            daySelected={daySelected}
            menage={menage}
            locations={locations}
            agents={agents}
            handleEditMenage={this.handleEditMenage}
            handleSubmitMenage={this.handleSubmitMenage}
            handleDeleteMenage={this.handleDeleteMenage}
            closeModalMenage={this.closeModalMenage}
            showRequete={this.showRequete}
          />
        </Modal>
        <Spin spinning={loading}>
          <Form ref={this.formRef} onFinish={this.onFinish} size="small" className="form-requete">
            <Row gutter={10} style={{ padding: 10 }}>
              <Col span={4}>
                <CustomDatePicker
                  label="Début"
                  inputName="location_date_debut"
                  formItemName="location_date_debut"
                  objectValue={"1"}
                />
              </Col>
              <Col span={4}>
                <CustomDatePicker
                  label="Fin"
                  inputName="location_date_fin"
                  formItemName="location_date_fin"
                  objectValue={"1"}
                />
              </Col>
              <Col span={4}>
                <CustomSelect
                  label="Type lot"
                  inputName="lot_type_lot_id"
                  formItemName="lot_type_lot_id"
                  objectValue={null}
                >
                  <OptGroup label="Type lot">
                    {typesLots &&
                      typesLots.map((item, i) => {
                        return (
                          <Option key={i} value={item.type_lot_id} label={item.type_lot_libelle}>
                            <Row>
                              <Col sm={8}>{item.type_lot_libelle}</Col>
                            </Row>
                          </Option>
                        );
                      })}
                  </OptGroup>
                </CustomSelect>
              </Col>
              <Col span={4}>
                <CustomInput
                  label="Nombre de couchages"
                  inputName="lot_couchage"
                  formItemName="lot_couchage"
                  type="number"
                  objectValue={null}
                />
              </Col>
              <Col span={4}>
                <CustomInput
                  label="Budget min"
                  inputName="location_total_min"
                  formItemName="location_total_min"
                  type="number"
                  objectValue={null}
                />
              </Col>
              <Col span={4}>
                <CustomInput
                  label="Budget max"
                  inputName="location_total_max"
                  formItemName="location_total_max"
                  type="number"
                  objectValue={null}
                />
              </Col>
            </Row>
            <Row gutter={10} style={{ padding: 10, textAlign: "start", marginTop: -20 }}>
              <Col span={3}>
                <Checkbox checked={checked} onChange={this.onCheckboxChange}>
                  Planning Ménage
                </Checkbox>
              </Col>
              <Col span={17}>
                <Row>
                  <Tag color="#FFE15D">Option</Tag>
                  <Tag color="#6D8299">Blocage proprietaire</Tag>
                  <Tag color="#D1D1D1">Non disponible</Tag>
                  <Tag color="#C991B4">Plateforme</Tag>
                  <Tag color="#B6E2A1">Réservé et soldé</Tag>
                  <Tag color="#FEBE8C">Réservé avec acompte</Tag>
                  <Tag color="#FD8A8A">Réservé sans acompte</Tag>
                </Row>
              </Col>
              <Col span={4}>
                <Button type="primary" onClick={() => this.reset()} danger>
                  Reset
                </Button>
                <Button type="primary" htmlType="submit" style={{ marginLeft: 10 }}>
                  <SyncOutlined spin={loading} />
                </Button>
              </Col>
            </Row>
          </Form>
          {resources && events && (
            <BryntumScheduler
              ref={this.schedulerRef}
              height={800}
              rowHeight={
                this.props.parametre.parametre_scheduler_row_height
                  ? parseInt(this.props.parametre.parametre_scheduler_row_height)
                  : 50
              }
              resourceMargin={
                this.props.parametre.parametre_scheduler_row_margin
                  ? parseInt(this.props.parametre.parametre_scheduler_row_margin)
                  : 10
              }
              resources={resources}
              events={events}
              treeFeature
              startDate={dayjs().subtract(1, "week").toDate()}
              endDate={dayjs().endOf("year").toDate()}
              autoAdjustTimeAxis={false}
              createEventOnDblClick={false}
              onEventContextMenu={(e) => this.handleRightClick(e.eventRecord.data)}
              onEventDblClick={(e) => this.handleDbClick(e.eventRecord.data, e.date)}
              onScheduleDblClick={(e) => this.handleSchedulerDbClick(e.resourceRecord.data, e.date)}
              // onDataChange={() => this.setDefaultConfig()}
              {...schedulerConfig}
              // other props, event handlers, etc
            />
          )}
        </Spin>
      </>
    );
  }
}

export default FormScheduler;

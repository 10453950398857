import { HttpMethod, initFetch } from "../../utils/fetcher";
import { adaptedToParametre, parametreToAdapted } from "../../adapter/parametreAdapter";

export const LOAD_SUCCESS_PARAMETRES = "LOAD_SUCCESS_PARAMETRES";
export const LOAD_FAIL_PARAMETRES = "LOAD_FAIL_PARAMETRES";
export const SAVE_SUCCESS_PARAMETRES = "SAVE_SUCCESS_PARAMETRES";
export const SAVE_FAIL_PARAMETRES = "SAVE_FAIL_PARAMETRES";
export const UPDATE_SUCCESS_PARAMETRES = "UPDATE_SUCCESS_PARAMETRES";
export const UPDATE_FAIL_PARAMETRES = "UPDATE_FAIL_PARAMETRES";
export const UPDATE_SUCCESS_PARAMETRES_TITRE = "UPDATE_SUCCESS_PARAMETRES_TITRE";
export const UPDATE_FAIL_PARAMETRES_TITRE = "UPDATE_FAIL_PARAMETRES_TITRE";
export const UPDATE_SUCCESS_PARAMETRES_INACTIVE = "UPDATE_SUCCESS_PARAMETRES_INACTIVE";
export const UPDATE_FAIL_PARAMETRES_INACTIVE = "UPDATE_FAIL_PARAMETRES_INACTIVE";
export const DELETE_SUCCESS_PARAMETRES = "DELETE_SUCCESS_PARAMETRES";
export const DELETE_FAIL_PARAMETRES = "DELETE_FAIL_PARAMETRES";

export function loadParametres(utilisateur_id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`parametres/${utilisateur_id}`, HttpMethod.GET);

      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.parametre);
          dispatch({
            type: LOAD_SUCCESS_PARAMETRES,
            payload: parametreToAdapted(res.parametre),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_PARAMETRES,
            error: err,
          });
        });
    });
  };
}

export function saveParametre(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`parametres`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToParametre(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(parametreToAdapted(res.parametre));
          dispatch({
            type: SAVE_SUCCESS_PARAMETRES,
            payload: parametreToAdapted(res.parametre),
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_PARAMETRES,
            error: err,
          })
        );
    });
  };
}

export function updateParametre(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`parametres/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToParametre(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(parametreToAdapted(res.parametre));
          dispatch({
            type: UPDATE_SUCCESS_PARAMETRES,
            payload: parametreToAdapted(res.parametre),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_PARAMETRES,
            error: err,
          })
        );
    });
  };
}

export function cacherTitreParametre(cacherTitre, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`parametres/cacherTitre/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify({ titre: cacherTitre }) })
        .then((res) => res.json())
        .then((res) => {
          resolve(parametreToAdapted(res.parametre));
          dispatch({
            type: UPDATE_SUCCESS_PARAMETRES_TITRE,
            payload: parametreToAdapted(res.parametre),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_PARAMETRES_TITRE,
            error: err,
          })
        );
    });
  };
}

export function cacherInactiveParametre(cacherInactive, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`parametres/cacherInactive/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify({ inactive: cacherInactive }) })
        .then((res) => res.json())
        .then((res) => {
          resolve(parametreToAdapted(res.parametre));
          dispatch({
            type: UPDATE_SUCCESS_PARAMETRES_INACTIVE,
            payload: parametreToAdapted(res.parametre),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_PARAMETRES_INACTIVE,
            error: err,
          })
        );
    });
  };
}

export function deleteParametre(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`parametres/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.parametre);
          dispatch({
            type: DELETE_SUCCESS_PARAMETRES,
            payload: parametreToAdapted(res.parametre),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_PARAMETRES,
            error: err,
          });
        });
    });
  };
}

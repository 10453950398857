import {
  LOAD_SUCCESS_PROPRIETAIRES,
  LOAD_FAIL_PROPRIETAIRES,
  SAVE_SUCCESS_PROPRIETAIRES,
  SAVE_FAIL_PROPRIETAIRES,
  UPDATE_SUCCESS_PROPRIETAIRES,
  UPDATE_FAIL_PROPRIETAIRES,
  DELETE_SUCCESS_PROPRIETAIRES,
  DELETE_FAIL_PROPRIETAIRES,
} from "../actions/proprietaireAction";

import { SAVE_SUCCESS_PROPRIETAIRES_AFFECTATIONS } from "../actions/proprietaireAffectationAction";

const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS_PROPRIETAIRES:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };
    case LOAD_FAIL_PROPRIETAIRES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error,
      };
    case SAVE_SUCCESS_PROPRIETAIRES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: [...state.data, action.payload],
        error: null,
      };
    case SAVE_FAIL_PROPRIETAIRES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_PROPRIETAIRES:
      const updatedListe = [];
      state.data.forEach((item) => {
        if (item.proprietaire_id === action.payload.proprietaire_id) {
          item = action.payload;
        }
        updatedListe.push(item);
      });

      return {
        ...state,
        loading: false,
        loaded: true,
        data: updatedListe,
        error: null,
      };
    case UPDATE_FAIL_PROPRIETAIRES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case DELETE_SUCCESS_PROPRIETAIRES:
      const filtered = state.data.filter((item) => {
        return item.proprietaire_id !== action.id;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filtered,
        error: null,
      };
    case DELETE_FAIL_PROPRIETAIRES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case SAVE_SUCCESS_PROPRIETAIRES_AFFECTATIONS:
      const addAffectation = [];

      state.data.forEach((proprietaire) => {
        if (proprietaire.proprietaire_id === action.id)
          proprietaire.proprietaire_affectations.push(action.payload);

        addAffectation.push(proprietaire);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: addAffectation,
        error: null,
      };
    default:
      return state;
  }
}

import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Input, Row, Table, Typography, Checkbox, Space, DatePicker } from "antd";

import dayjs from "dayjs";

// import { CSVLink } from "react-csv";
import { useReactToPrint } from "react-to-print";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
// import { headers } from "./headers";

const dateFormat = "DD/MM/YYYY";
const { Search } = Input;
const { Title } = Typography;

const datePickerRef = React.createRef();

const ListLocation = (props) => {
  const [filterData, setFilterData] = useState(null);
  const [searchText, setSearchText] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [searchedColumn, setSearchedColumn] = useState(null);
  const searchInput = useRef(null);
  const { listData, tableOption, etat, titre } = props;
  const [lignesLocations, setLocations] = useState();
  const [typeContrat, setTypeContrat] = useState([1, 2, 3]);
  const [solde, setSolde] = useState(["1"]);
  let optionsTypeContrat = [1, 2, 3];
  let optionsSolde = [1];

  const componentRef = useRef();

  let label = "";
  switch (etat) {
    case "o":
      label = "Options";
      break;
    case "r":
      label = "Contrats";
      break;
    case "b":
      label = "Réservations propriétaire";
      break;
    default:
      break;
  }

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, solde, typeContrat]);

  const init = () => {
    applyFilter(false);
  };

  const applyFilter = (value) => {
    let locations = [];
    if (value) {
      locations = filterData;
    } else {
      locations = listData;
    }

    if (locations && props.quittances && props.reglements) {
      let quittances = props.quittances;
      let reglements = props.reglements;

      let lignesLocations = [];
      locations.forEach((ligne) => {
        const quittancesFiltered = quittances.filter(
          (item) => item.quittance_location_id === ligne.location_id
        );
        const reglementsFiltered = reglements.filter(
          (item) => item.reglement_quittance.quittance_location_id === ligne.location_id
        );

        let quittancesLignes = [];
        if (quittancesFiltered.length > 0) {
          quittancesFiltered.forEach((quittance) => {
            quittance.quittance_ligne.forEach((ligne) => {
              quittancesLignes.push(ligne);
            });
          });
        }

        let reglementsLignes = [];
        if (reglementsFiltered.length > 0) {
          reglementsFiltered.forEach((reglement) => {
            reglement.reglement_ligne.forEach((ligne) => {
              reglementsLignes.push(ligne);
            });
          });
        }

        let solde = 0;
        const total = quittancesLignes.reduce(
          (a, b) => a + parseFloat(b.quittance_ligne_montant),
          0
        );
        const regle = reglementsLignes.reduce(
          (a, b) => a + parseFloat(b.reglement_ligne_montant),
          0
        );

        solde = total - regle;

        const ligneLocation = {
          location_id: ligne.location_id,
          location_date_creation: ligne.location_date_creation,
          location_date_contrat: ligne.location_date_contrat,
          location_lot: ligne.location_lot,
          location_date_debut: ligne.location_date_debut,
          location_date_fin: ligne.location_date_fin,
          location_type_contrat: ligne.location_type_contrat,
          location_requete: ligne.location_requete,
          location_nb_adultes: ligne.location_nb_adultes,
          location_nb_enfants: ligne.location_nb_enfants,
          location_ligne: ligne.location_ligne,
          quittance_ligne: quittancesLignes ? quittancesLignes : [],
          reglement_ligne: reglementsLignes ? reglementsLignes : [],
          solde: solde,
          location_remarques: ligne.location_remarques,
        };

        lignesLocations.push(ligneLocation);
      });

      if (etat === "r") {
        lignesLocations = lignesLocations.filter(
          (ligne) =>
            typeContrat.includes(ligne.location_type_contrat) ||
            ligne.location_type_contrat === null
        );

        if (solde.length === 1) {
          const epsilon = 0.001;
          lignesLocations = lignesLocations.filter((ligne) => Math.abs(ligne.solde) > epsilon);
        }
      }

      setLocations(lignesLocations);
    }
  };

  const search = (value) => {
    const { listData } = props;

    const filterData = listData.filter((o) => {
      return Object.keys(o).some(
        (k) =>
          String(o[k]).toLowerCase().includes(value.toLowerCase()) ||
          (o.location_requete &&
            String(
              `${o.location_requete.requete_locataire.locataire_personne.personne_nom} ${o.location_requete.requete_locataire.locataire_personne.personne_prenom}`
            )
              .toLowerCase()
              .includes(value.toLowerCase())) ||
          (o.location_requete &&
            String(
              `${o.location_requete.requete_locataire.locataire_personne.personne_prenom} ${o.location_requete.requete_locataire.locataire_personne.personne_nom}`
            )
              .toLowerCase()
              .includes(value.toLowerCase())) ||
          (o.location_requete &&
            String(`${o.location_lot.lot_reference}`).toLowerCase().includes(value.toLowerCase()))
      );
    });

    setFilterData(value ? filterData : listData);

    applyFilter(value);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const onClickAfficher = (requete) => {
    const pane = { key: "26", title: label.slice(0, -1), content: "" };
    props.addPanesRequete(pane, "26", requete);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Rechercher ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Rechercher
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => onSearch(value, record, dataIndex),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getColumnSearchPropsDate = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <DatePicker
          ref={datePickerRef}
          placeholder={`Rechercher ${dataIndex}`}
          format={dateFormat}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e ? [e] : [])}
          onPressEnter={() => handleSearchDate(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchDate(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Rechercher
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => searchDate(value, record, dataIndex),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => datePickerRef.current.focus(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const onSearch = (value, record, dataIndex) => {
    let result = null;
    if (dataIndex.includes("location_requete")) {
      result =
        record[dataIndex].requete_locataire.locataire_personne.personne_nom
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        record[dataIndex].requete_locataire.locataire_personne.personne_prenom
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
    } else if (dataIndex.includes("location_lot")) {
      result = record[dataIndex].lot_reference
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    } else {
      result = record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
    }
    return result ? result : "";
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleSearchDate = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0].format("DD/MM/YYYY"));
    setSearchedColumn(dataIndex);
  };

  const searchDate = (record, value, dataIndex) => {
    return record
      ? record.startOf("day").isSame(dayjs(value[dataIndex], "YYYY-MM-DD").startOf("day"))
      : "";
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText(searchText);
  };

  const onChangeTypeContrat = (checkedValues) => {
    setTypeContrat(checkedValues);
  };

  const onChangeSolde = (checkedValues) => {
    setSolde(checkedValues);
  };

  if (etat === "r") {
    optionsTypeContrat = [
      { label: "Alur", value: 1 },
      { label: "Long terme", value: 2 },
      { label: "Court terme", value: 3 },
    ];
    optionsSolde = [{ label: "Non soldés", value: 1 }];
  }

  return (
    <>
      <div style={{ padding: 20 }}>
        <Row>
          <Title level={2}>
            {`${titre ? "Liste des " + label : ""} `}
            {/* Liste des {label} */}
          </Title>
        </Row>
        <Row>
          <Col span={12}>
            <Search
              placeholder="Recherche..."
              allowClear
              // onPressEnter={search}
              onSearch={search}
              style={{ width: 300 }}
            />
          </Col>
          <Col span={9}>
            {etat === "r" && (
              <div style={{ float: "right" }}>
                <Checkbox.Group
                  style={{ marginRight: 60 }}
                  options={optionsTypeContrat}
                  defaultValue={[1, 2, 3]}
                  onChange={onChangeTypeContrat}
                />
                <Checkbox.Group
                  options={optionsSolde}
                  defaultValue={[1]}
                  onChange={onChangeSolde}
                />
              </div>
            )}
          </Col>
          <Col span={3}>
            <div style={{ float: "right" }}>
              <Button onClick={handlePrint} className="btn-primary">
                PDF
              </Button>
              {/* <CSVLink
                filename={"export.csv"}
                data={listData}
                headers={headers()}
                separator={";"}
                className="ant-btn btn-primary"
                style={{ marginLeft: 10 }}
              >
                CSV
              </CSVLink> */}
            </div>
          </Col>
        </Row>
      </div>
      <div ref={componentRef}>
        {lignesLocations && (
          <Table
            className="list-data"
            dataSource={lignesLocations}
            //dataSource={filterData ? filterData : listData}
            columns={tableOption(onClickAfficher, getColumnSearchProps, getColumnSearchPropsDate)}
            pagination={false}
            rowKey={"location_id"}
          />
        )}
      </div>
    </>
  );
};

export default ListLocation;

import { rubriqueToAdapted } from "./rubriqueAdapter";

export function nuiteesToAdapted(items) {
  return items
    ? items.map((item) => {
        return nuiteeToAdapted(item);
      })
    : null;
}

export function nuiteeToAdapted(item) {
  //Resource
  return item
    ? {
        nuite_id: item.id,
        nuite_montant: item.montant,
        nuite_date_debut: item.date_debut,
        nuite_date_fin: item.date_fin,
        nuite_lot_id: item.lot_id,
        nuite_rubrique_id: item.rubrique_id,
        nuite_rubrique: rubriqueToAdapted(item.rubrique),
      }
    : null;
}

export function adaptedToNuitee(item) {
  //Model
  return item
    ? {
        montant: item.nuite_montant,
        date_debut: item.nuite_date_debut,
        date_fin: item.nuite_date_fin,
        lot_id: item.nuite_lot_id,
        rubrique_id: item.nuite_rubrique_id,
      }
    : null;
}

import "./App.css";
import "@bryntum/scheduler/scheduler.classic-light.css";

import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { ConfigProvider } from "antd";

import { subfolder } from "./utils/constants";

import Login from "./containers/Login";
import Router from "./Router";

import dayjs from "dayjs";
import locale from "antd/es/locale/fr_FR";
import "dayjs/locale/fr";

import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isBetween from "dayjs/plugin/isBetween";
import duration from "dayjs/plugin/duration";

import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(duration);

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

dayjs.locale("fr");

const App = () => {
  const utilisateur = useSelector((state) => state.utilisateur.data);

  if (!utilisateur || utilisateur.length === 0) {
    return (
      <ConfigProvider
        locale={locale}
        theme={{
          token: {
            colorPrimary: "#f4c181",
          },
        }}
      >
        <Login />
      </ConfigProvider>
    );
  }

  const pathLogin = `${subfolder}/login`;
  const pathRouter = `${subfolder}/`;

  return (
    <ConfigProvider
      locale={locale}
      theme={{
        token: {
          colorPrimary: "#f4c181",
        },
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path={pathLogin} element={<Login />} />
          <Route path={pathRouter} element={<Router />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;

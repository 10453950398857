import { quittanceLigneToAdapted } from "./quittanceLigneAdapter";

export function reglementsLignesToAdapted(items) {
  return items
    ? items.map((item) => {
        return reglementLigneToAdapted(item);
      })
    : null;
}

export function reglementLigneToAdapted(item) {
  return item
    ? {
        reglement_ligne_id: item.id,
        reglement_ligne_reglement_id: item.reglement_id,
        reglement_ligne_montant: item.montant,
        reglement_ligne_quittance_ligne_id: quittanceLigneToAdapted(item.quittance_ligne_id),
      }
    : null;
}

export function adaptedToReglementsLignes(items) {
  return items
    ? {
        reglementsLignes: items.reglementsLignes.map((item) => {
          return adaptedToReglementLigne(item);
        }),
      }
    : null;
}

export function adaptedToReglementLigne(item) {
  return item
    ? {
        reglement_id: item.reglement_ligne_reglement_id,
        quittance_ligne_id: item.reglement_ligne_quittance_ligne_id,
        montant: item.reglement_ligne_montant,
      }
    : null;
}

import React, { Component } from "react";
import { connect } from "react-redux";
import { Spin } from "antd";

import { loadLots } from "../../store/actions/lotAction";
import { loadLocations } from "../../store/actions/locationAction";
import {
  deleteMenage,
  loadMenages,
  saveMenage,
  updateMenage,
} from "../../store/actions/menageAction";
import { loadQuittances } from "../../store/actions/quittanceAction";
import { loadReglements } from "../../store/actions/reglementAction";

import FormScheduler from "../../components/FormScheduler";
import { loadAgents } from "../../store/actions/agentAction";

class Scheduler extends Component {
  componentDidMount() {
    const groupement_id = this.props.utilisateur.groupement_id;
    const societe_id = this.props.utilisateur.societe_id;
    this.props.loadAgents(groupement_id);
    this.props.loadLots(societe_id);
    this.props.loadLocations(groupement_id);
    this.props.loadMenages(groupement_id);
    this.props.loadQuittances(groupement_id);
    this.props.loadReglements(societe_id);
  }

  render() {
    const {
      parametre,
      agents,
      lots,
      locations,
      menages,
      quittances,
      reglements,
      typesLots,
      utilisateur,
    } = this.props;    

    return (
      <Spin
        spinning={!agents || !lots || !locations || !menages || !quittances || !reglements}
        size="large"
        tip="Chargement en cours..."
      >
        {agents && lots && locations && menages && quittances && reglements && (
          <FormScheduler
            parametre={parametre}
            agents={agents}
            lots={lots}
            locations={
              locations &&
              locations.filter(
                (item) => item.location_lot.lot_societe_id === utilisateur.societe_id
              )
            }
            menages={menages}
            quittances={quittances}
            reglements={reglements}
            typesLots={typesLots}
            saveMenage={this.props.saveMenage}
            updateMenage={this.props.updateMenage}
            deleteMenage={this.props.deleteMenage}
            addPanesRequete={this.props.addPanesRequete}
          />
        )}
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadAgents: (groupement_id) => dispatch(loadAgents(groupement_id)),
    loadLots: (societe_id) => dispatch(loadLots(societe_id)),
    loadLocations: (groupement_id) => dispatch(loadLocations(groupement_id)),
    loadMenages: (groupement_id) => dispatch(loadMenages(groupement_id)),
    loadQuittances: (groupement_id) => dispatch(loadQuittances(groupement_id)),
    loadReglements: (societe_id) => dispatch(loadReglements(societe_id)),
    saveMenage: (jsonData) => dispatch(saveMenage(jsonData)),
    updateMenage: (jsonData, id) => dispatch(updateMenage(jsonData, id)),
    deleteMenage: (id) => dispatch(deleteMenage(id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    agents: state.agents.data,
    parametre: state.parametre.data,
    lots: state.lots.data,
    locations: state.locations.data,
    menages: state.menages.data,
    quittances: state.quittances.data,
    reglements: state.reglements.data,
    typesLots: state.typesLots.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Scheduler);

import React, { Component } from "react";

import { Table, Spin, Select, Popconfirm, Card, Row, Col, Upload, Button } from "antd";

import Constants from "../../utils/constants";

import { PlusOutlined, FolderViewOutlined, DeleteOutlined } from "@ant-design/icons";

import { toast } from "react-toastify";
import CustomSelect from "../CustomFormItem/Select";

const { Option, OptGroup } = Select;

class FormWord extends Component {
  state = {
    words: [],
    loading: true,
    inputVisible: false,
    inputValue: "",
    editInputIndex: -1,
    editInputValue: "",
    selectedTag: null,
  };

  componentDidMount() {
    this.init(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.init(props);
  }

  init = (props) => {
    this.setState({
      loading: false,
      words: props.words,
    });
  };

  beforeUpload = (file) => {
    const isLt10M = file.size / 1024 / 1024 < 12;
    if (!isLt10M) {
      toast.error("Document supérieur à 10Mo !");
    }

    return isLt10M;
  };

  handleDelete = (word) => {
    this.props.deleteWord(word.word_id, this.getTypeSelectionne().name);
  };

  getTypeSelectionne = () => {
    return Constants.TYPE.find((i) => i.value === this.props.type);
  };

  onChangeDossier = (word, value) => {
    word.word_tag_id = value;
    this.props.updateWord(word, word.word_id);
  };

  render() {
    const { utilisateur, tags } = this.props;
    const { words, loading } = this.state;

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">Envoyer</div>
      </div>
    );

    const columns = [
      {
        title: "Nom",
        key: "word_nom",
        dataIndex: "word_nom",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) => a.word_nom.localeCompare(b.word_nom),
      },
      {
        title: "Dossier",
        key: "word_id",
        dataIndex: "",
        render: (word) => (
          <CustomSelect
            key={word.word_id}
            label="Dossier"
            inputName={`tag-${word.word_id}`}
            formItemName={`tag-${word.word_id}`}
            onChange={(value) => this.onChangeDossier(word, value)}
            defaultValue={word.word_tag_id}
            objectValue={word.word_tag_id}
          >
            <OptGroup key={word.word_id} label="Dossier">
              {tags &&
                tags.map((item, i) => {
                  return (
                    <Option key={i} value={item.tag_id} label={item.tag_libelle}>
                      <Row>
                        <Col sm={8}>{item.tag_libelle}</Col>
                      </Row>
                    </Option>
                  );
                })}
            </OptGroup>
          </CustomSelect>
        ),
        width: "30%",
      },
      {
        title: "Télécharger",
        key: "word_id",
        dataIndex: "",
        render: (word) => (
          <Button
            href={`${Constants.directory}words/${utilisateur.etablissement_id}/${
              this.getTypeSelectionne().name
            }/${word.word_nom}`}
            target="_blank"
          >
            <FolderViewOutlined />
          </Button>
        ),
      },
      {
        title: "Supprimer",
        key: "word_id",
        dataIndex: "",
        render: (word) => (
          <Popconfirm title="Suprimer le fichier?" onConfirm={() => this.handleDelete(word)}>
            <Button>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        ),
      },
    ];

    const props = {
      accept: ".docx",
      multiple: true,
      customRequest: (componentsData) => {
        if (this.props.type) {
          this.props.saveWord(
            componentsData,
            this.getTypeSelectionne(),
            utilisateur.etablissement_id
          );
        }
      },
      name: "file",
    };

    return (
      <div>
        <Spin size="large" spinning={loading} tip="Chargement...">
          <Card className="appt-card">
            <Row style={{ marginTop: "10px" }}>
              <Col offset={1} span={4}>
                <Upload
                  {...props}
                  showUploadList={false}
                  listType="picture-card"
                  beforeUpload={this.beforeUpload}
                  disabled={this.props.type === null}
                >
                  {uploadButton}
                </Upload>
              </Col>
              <Col offset={1} span={18}>
                <Table columns={columns} dataSource={words} />
              </Col>
            </Row>
          </Card>
        </Spin>
      </div>
    );
  }
}

export default FormWord;

import { rubriqueToAdapted } from "./rubriqueAdapter";

export function prixBasesToAdapted(items) {
  return items
    ? items.map((item) => {
        return prixBaseToAdapted(item);
      })
    : null;
}

export function prixBaseToAdapted(item) {
  //Resource
  return item
    ? {
        prix_base_id: item.id,
        prix_base_montant: item.montant,
        prix_base_lot_id: item.lot_id,
        prix_base_proprietaire: item.proprietaire,
        prix_base_rubrique: rubriqueToAdapted(item.rubrique),
      }
    : null;
}

export function adaptedToPrixBase(item) {
  //Model
  return item
    ? {
        montant: item.prix_base_montant,
        lot_id: item.prix_base_lot_id,
        proprietaire: item.prix_base_proprietaire,
        rubrique_id: item.prix_base_rubrique_id,
      }
    : null;
}

import {
  LOAD_SUCCESS_RUBRIQUES,
  LOAD_FAIL_RUBRIQUES,
  SAVE_SUCCESS_RUBRIQUES,
  SAVE_FAIL_RUBRIQUES,
  UPDATE_SUCCESS_RUBRIQUES,
  UPDATE_FAIL_RUBRIQUES,
  UPDATE_SUCCESS_IMPUTATION,
  UPDATE_FAIL_IMPUTATION,
  DELETE_SUCCESS_RUBRIQUES,
  DELETE_FAIL_RUBRIQUES,
} from "../actions/rubriqueAction";
import {
  DELETE_FAIL_RUBRIQUE_POURCENTAGE,
  DELETE_SUCCESS_RUBRIQUE_POURCENTAGE,
  SAVE_FAIL_RUBRIQUE_POURCENTAGE,
  SAVE_SUCCESS_RUBRIQUE_POURCENTAGE,
} from "../actions/rubriquePourcentageAction";

const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS_RUBRIQUES:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };
    case LOAD_FAIL_RUBRIQUES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error,
      };
    case SAVE_SUCCESS_RUBRIQUES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: [...state.data, action.payload],
        error: null,
      };
    case SAVE_FAIL_RUBRIQUES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_RUBRIQUES:
      const updatedListe = [];
      state.data.forEach((item) => {
        if (item.rubrique_id === action.payload.rubrique_id) {
          item = action.payload;
        }
        updatedListe.push(item);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: updatedListe,
        error: null,
      };
    case UPDATE_FAIL_RUBRIQUES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_IMPUTATION:
      const updatedImputation = [];
      state.data.forEach((item) => {
        if (item.rubrique_id === action.payload.rubrique_id) {
          item = action.payload;
        }
        updatedImputation.push(item);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: updatedImputation,
        error: null,
      };
    case UPDATE_FAIL_IMPUTATION:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case DELETE_SUCCESS_RUBRIQUES:
      let filtered = state.data.filter((item) => {
        return item.rubrique_id !== action.id;
      });

      filtered.forEach((i) => {
        i.rubrique_liees = i.rubrique_liees.filter((item) => {
          return item.rubrique_id !== action.id;
        });
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filtered,
        error: null,
      };
    case DELETE_FAIL_RUBRIQUES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case SAVE_SUCCESS_RUBRIQUE_POURCENTAGE:
      let rubs = [];
      state.data.forEach((rubrique) => {
        if (rubrique.rubrique_id === action.idPrincipale) {
          rubrique.rubrique_liees = rubrique.rubrique_liees.concat(
            action.payload
          );
        }
        rubs.push(rubrique);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: rubs,
        error: null,
      };
    case SAVE_FAIL_RUBRIQUE_POURCENTAGE:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case DELETE_SUCCESS_RUBRIQUE_POURCENTAGE:
      const filteredRubPourc = state.data.map((rubrique) => {
        if (rubrique.rubrique_id === action.idPrincipale) {
          rubrique.rubrique_liees = rubrique.rubrique_liees.filter(
            (liees) => liees.rubrique_id !== action.idLiee
          );
        }
        return rubrique;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filteredRubPourc,
        error: null,
      };
    case DELETE_FAIL_RUBRIQUE_POURCENTAGE:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: state.data,
        error: action.payload,
      };
    default:
      return state;
  }
}

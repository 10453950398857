import React, { Component } from "react";
import { connect } from "react-redux";

import { Card, Spin } from "antd";

import { loadLocations } from "../../store/actions/locationAction";
import { loadQuittances } from "../../store/actions/quittanceAction";
import { loadComptes } from "../../store/actions/compteAction";
import { loadReglements, updateReglement } from "../../store/actions/reglementAction";
import { saveEcriture } from "../../store/actions/ecritureAction";

import TableValidationBordereau from "../../components/TableValidationBordereau";

class ValidationBordereau extends Component {
  componentDidMount() {
    const groupement_id = this.props.utilisateur.groupement_id;
    const societe_id = this.props.utilisateur.societe_id;
    Promise.all([
      !this.props.quittances && this.props.loadQuittances(groupement_id),
      !this.props.reglements && this.props.loadReglements(societe_id),
      !this.props.comptes && this.props.loadComptes(societe_id),
      !this.props.locations && this.props.loadLocations(groupement_id),
    ]);
  }

  render() {
    const {
      societes,
      journals,
      comptes,
      locations,
      quittances,
      reglements,
      typesPaiements,
      utilisateur,
    } = this.props;

    return (
      <Spin
        size="large"
        tip="Chargement en cours..."
        spinning={!comptes || !locations || !quittances || !reglements}
      >
        <Card title="" bordered={false} className="card-panel">
          <TableValidationBordereau
            societes={societes}
            journals={journals}
            comptes={comptes}
            locations={
              locations &&
              locations.filter(
                (item) => item.location_lot.lot_societe_id === utilisateur.societe_id
              )
            }
            quittances={quittances}
            reglements={reglements?.filter((item) => item.reglement_bordereau === true)}
            typesPaiements={typesPaiements.filter((item) => item.type_paiement_bordereau)}
            updateReglement={this.props.updateReglement}
            saveEcriture={this.props.saveEcriture}
          />
        </Card>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadComptes: (societe_id) => dispatch(loadComptes(societe_id)),
    loadLocations: (groupement_id) => dispatch(loadLocations(groupement_id)),
    loadQuittances: (groupement_id) => dispatch(loadQuittances(groupement_id)),
    loadReglements: (societe_id) => dispatch(loadReglements(societe_id)),
    updateReglement: (jsonData, id) => dispatch(updateReglement(jsonData, id)),
    saveEcriture: (ecriture) => dispatch(saveEcriture(ecriture)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    societes: state.societes.data,
    journals: state.journals.data,
    comptes: state.comptes.data,
    locations: state.locations.data,
    quittances: state.quittances.data,
    reglements: state.reglements.data,
    typesPaiements: state.typesPaiements.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidationBordereau);

import {
  locataireAffectionsToAdapted,
  simplesLocataireAffectionsToAdapted,
} from "./locataireAffectationAdapter";
import { adaptedToPersonne, personneToAdapted, simplePersonneToAdapted } from "./personneAdapter";
import { provenanceToAdapted } from "./provenanceAdapter";

export function locatairesToAdapted(items) {
  return items
    ? items.map((item) => {
        return locataireToAdapted(item);
      })
    : null;
}

export function locataireToAdapted(item) {
  return item
    ? {
        locataire_id: item.id,
        locataire_personne: personneToAdapted(item.personne),
        locataire_code: item.code,
        locataire_affectations: locataireAffectionsToAdapted(item.locataire_affectation),
        locataire_provenance: provenanceToAdapted(item.provenance),
      }
    : null;
}

export function simpleLocataireToAdapted(item) {
  return item
    ? {
        locataire_id: item.id,
        locataire_personne: simplePersonneToAdapted(item.personne),
        locataire_code: item.code,
        locataire_affectations: simplesLocataireAffectionsToAdapted(item.locataire_affectation),
      }
    : null;
}

export function adaptedToLocataire(item) {
  const locataire = item
    ? {
        personne_id: item.locataire_personne_id,
        code: item.locataire_code,
        provenance_id: item.locataire_provenance_id,
      }
    : null;

  const personne = item ? adaptedToPersonne(item) : null;

  return item
    ? {
        ...locataire,
        ...personne,
      }
    : null;
}

import React, { Component } from "react";

import { Row, Col, Card, Popover, Modal, Spin } from "antd";

import { PlusOutlined, EditOutlined, ClockCircleOutlined } from "@ant-design/icons";

import { Scrollbars } from "react-custom-scrollbars";

import MyTree from "../../components/Tree/index";
import MyFormEtape from "../../components/FormEtape/index";
import MyFormTimer from "../../components/FormTimer/index";
import MyFormCodeCouleur from "../../components/FormCodeCouleur/index";
import MyBreadcrumb from "../../components/Breadcrumb/index";

import { connect } from "react-redux";

import {
  saveEtape,
  updateEtape,
  deleteEtape,
  saveEtapeAction,
  updateEtapeAction,
  deleteEtapeAction,
} from "../../store/actions/etapeAction";
import {
  saveCodeCouleur,
  updateCodeCouleur,
  deleteCodeCouleur,
} from "../../store/actions/codeCouleurAction";
import { deleteRenduA } from "../../store/actions/renduAction";
import { cacherTitreParametre, cacherInactiveParametre } from "../../store/actions/parametreAction";

import { sortTree, isLeaf, sort, setChildren } from "../../utils/tools";
import { initTree } from "../../store/actions/treeAction";

class Etapes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataEtapes: [],
      dataCodesCouleur: [],
      availableParents: [{ etape_id: 0, etape_libelle: "Aucune" }],
      availableOrder: [],
      treeEtapes: [],
      createPopoverVisible: false,
      editPopoverVisible: false,
      timerPopoverVisible: false,
      modalCreateCodeCouleurVisible: false,
      modalEditCodeCouleurVisible: false,
      codeCouleurHex: "#fff",
      etapeToEdit: null,
      couleurToEdit: {},
      codeCouleurId: null,
      idCodeCouleur: null,
      loading: true,
      cacherTitre: false,
      cacherInactive: false,
    };
  }

  componentDidMount() {
    this.init(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.init(props);
  }

  init = (props) => {
    this.getEtapes(props.etapes);
    this.getCodesCouleurs(props.codesCouleurs);
  };

  getEtapes = (etapes) => {
    const dataEtapes = [];

    if (!etapes.loaded) this.setState({ loading: true });

    etapes &&
      etapes.forEach((item) => {
        if (item.etape_etape_parente !== null) {
          const etape = {
            etape_id: item.etape_id,
            etape_libelle: item.etape_libelle,
            etape_ordre: item.etape_ordre,
            etape_icone: item.etape_icone,
            etape_todo: item.etape_todo,
            level: item.level,
            etape_code_couleur: item.etape_code_couleur,
            etape_etape_parente: item.etape_etape_parente,
            etape_etape_action: item.etape_etape_action,
          };

          dataEtapes.push(etape);
        }
      });

    this.setState({ dataEtapes, loading: false }, () => {
      sortTree(dataEtapes);
      this.initTreeForm();
      this.getAvailableParents();
      this.getAvailableOrders(0, false);
    });
  };

  getCodesCouleurs = (codesCouleurs) => {
    const dataCodesCouleur = [];

    if (!codesCouleurs.loaded) this.setState({ loading: true });

    codesCouleurs &&
      codesCouleurs.data.forEach((item) => {
        dataCodesCouleur.push(item);
      });

    this.setState({ dataCodesCouleur });
  };

  getAvailableParents = () => {
    const dataEtapes = this.state.dataEtapes;
    let availableParents = [];

    dataEtapes.forEach((item) => {
      if (!isLeaf(item)) availableParents.push(item);
    });

    this.setState({ availableParents });
  };

  initTreeForm = () => {
    const dataEtapes = this.state.dataEtapes;
    const cacherTitre = this.props.parametre.parametre_cacher_titre;
    const cacherInactive = this.props.parametre.parametre_cacher_inactive;
    const niveau1 = sort(dataEtapes.filter((item) => item.level === 1));
    const niveau2 = sort(dataEtapes.filter((item) => item.level === 2));
    const niveau3 = sort(dataEtapes.filter((item) => item.level === 3));

    setChildren(niveau1, niveau2);
    setChildren(niveau2, niveau3);
    setChildren(niveau3, []);

    this.setState({
      treeEtapes: niveau1,
      loading: false,
      cacherTitre,
      cacherInactive,
    });
  };

  getEtapeToEdit = () => {
    // Retourne l'étape à éditer
    const etape = this.state.dataEtapes.find(
      (item) => item.etape_id === this.state.idEtapeSelected
    );

    // Récupération de l'étape à modifier
    // Passage à null pour rester à jour
    if (etape) {
      this.setState({
        etapeToEdit: etape,
        idCodeCouleur: etape.etape_code_couleur.code_couleur_id,
      });
      this.getAvailableOrders(etape.etape_etape_parente.etape_id, true);
    } else {
      this.setState({ etapeToEdit: null });
    }
  };

  createDataEtapes = (etape) => {
    const dataEtapes = this.state.dataEtapes;
    dataEtapes.push(etape);

    this.initTreeForm();
  };

  createDataCodesCouleur = (value) => {
    const dataCodesCouleur = this.state.dataCodesCouleur;
    dataCodesCouleur.push(value);
  };

  updateDataEtapes = (etape) => {
    const dataEtapes = this.state.dataEtapes;

    let newEtape = dataEtapes.find((item) => parseInt(item.etape_id) === etape.etape_id);

    newEtape.etape_libelle = etape.etape_libelle;
    newEtape.etape_ordre = etape.etape_ordre;
    if (etape.etape_code_couleur !== undefined && etape.etape_code_couleur !== null) {
      newEtape.etape_code_couleur = etape.etape_code_couleur;
    }
    if (etape.etape_etape_parente !== undefined && etape.etape_etape_parente !== null) {
      newEtape.etape_etape_parente = etape.etape_etape_parente;
    }

    sortTree(dataEtapes);
    this.initTreeForm();
  };

  updateDataCodesCouleur = (codeCouleur) => {
    const dataCodesCouleur = this.state.dataCodesCouleur;

    let newcodeCouleur = dataCodesCouleur.find(
      (item) => parseInt(item.code_couleur_id) === codeCouleur.code_couleur_id
    );

    newcodeCouleur.code_couleur_libelle = codeCouleur.code_couleur_libelle;
    newcodeCouleur.code_couleur_hex = codeCouleur.code_couleur_hex;
  };

  deleteDataEtapes = (dataEtapes, etape) => {
    const newDataEtapes = dataEtapes.filter((item) => {
      return item.etape_id !== etape.etape_id;
    });

    this.setState({
      dataEtapes: newDataEtapes,
    });
  };

  deleteDataCodesCouleur = (dataCodesCouleur, codeCouleur) => {
    const newDataCodesCouleur = dataCodesCouleur.filter((item) => {
      return item.code_couleur_id !== codeCouleur.code_couleur_id;
    });

    this.setState({
      dataCodesCouleur: newDataCodesCouleur,
    });
  };

  handleCreateCodeCouleur = (values, couleurHex) => {
    const jsonData = {
      code_couleur_libelle: values.couleurInput,
      code_couleur_hex: couleurHex,
      code_couleur_groupement_id: this.props.utilisateur.groupement_id,
    };

    this.props
      .saveCodeCouleur(jsonData)
      .then((res) => {
        this.createDataCodesCouleur(res);

        this.setState({
          idCodeCouleur: res.code_couleur_id,
          modalCreateCodeCouleurVisible: false,
        });
      })
      .catch((err) => console.log(err));
  };

  handleEditCodeCouleur = (values, couleurHex) => {
    const jsonData = {
      code_couleur_libelle: values.couleurInput,
      code_couleur_hex: couleurHex,
    };

    this.props
      .updateCodeCouleur(jsonData, this.state.idCodeCouleur)
      .then((res) => {
        // Récupère la nouvelle liste des codes couleur
        this.updateDataCodesCouleur(res);
        // Fermeture du form code couleur et affichage
        // du form etape
        // Modifie la couleur modifiée dans les datas
        this.setState((state) => {
          const dataEtapes = state.dataEtapes.map((item) => {
            if (res.code_couleur_id === item.etape_code_couleur.code_couleur_id) {
              item.etape_code_couleur.code_couleur_libelle = values.couleurInput;
              item.etape_code_couleur.code_couleur_hex = couleurHex;
            }
            return item;
          });
          return {
            dataEtapes,
            createPopoverVisible: true,
            modalEditCodeCouleurVisible: false,
          };
        });
        this.initTreeForm();
      })
      .catch((err) => console.log(err));
  };

  handleChangeCouleur = (value) => {
    const codeCouleur = this.state.dataCodesCouleur.find((item) => item.code_couleur_id === value);
    const etape = this.state.etapeToEdit;
    if (etape !== null) etape.etape_code_couleur = codeCouleur;
    this.setState({
      etapeToEdit: etape ? etape : null,
      idCodeCouleur: value,
    });
  };

  getCouleur = () => {
    if (this.state.idCodeCouleur) {
      const couleur = this.state.dataCodesCouleur.find(
        (codeCouleur) => codeCouleur.code_couleur_id === this.state.idCodeCouleur
      );
      this.setState({
        couleurToEdit: couleur,
        codeCouleurHex: couleur.code_couleur_hex,
      });
    }
  };

  getAvailableOrders = (id, edit) => {
    let availableOrder = [];
    let i = 1;
    this.state.dataEtapes.forEach((item) => {
      if (item.etape_etape_parente.etape_id === id) {
        availableOrder.push(i);
        i++;
      }
    });

    if (!edit) {
      availableOrder.push(availableOrder.length + 1);
    }

    this.setState({ availableOrder });
  };

  handleSelectEtape = (idEtape) => {
    if (!isNaN(parseInt(idEtape))) {
      this.setState({ idEtapeSelected: parseInt(idEtape) });
    } else {
      this.setState({ idEtapeSelected: null });
    }
  };

  handleChangeEtapeParente = (value, type) => {
    const etapeParente = this.getEtapeParente(value);
    const etape = this.state.etapeToEdit;
    if (etape !== null) {
      etape.etape_etape_parente = etapeParente;
    }

    this.setState({
      etapeToEdit: etape ? etape : null,
      codeCouleurId: etapeParente ? etapeParente.etape_code_couleur.code_couleur_id : null,
    });

    if (this.state.etapeToEdit !== null) {
      if (this.state.etapeToEdit.etape_etape_parente.etape_id !== etapeParente.etape_id)
        type = false;
    }
    this.getAvailableOrders(value, type);
  };

  getEtapeParente = (id) => {
    if (id !== 0) {
      return this.state.dataEtapes.find((item) => parseInt(item.etape_id) === id);
    }
  };

  handleCreatePopoverVisibleChange = (createPopoverVisible) => {
    this.setState({ createPopoverVisible, etapeToEdit: null });
  };

  handleEditPopoverVisibleChange = (editPopoverVisible) => {
    this.setState({ editPopoverVisible });
  };

  handleTimerPopoverVisibleChange = (timerPopoverVisible) => {
    this.setState({ timerPopoverVisible });
  };

  openModalCreateCodeCouleur = () => {
    this.setState({
      modalCreateCodeCouleurVisible: true,
      createPopoverVisible: false,
      editPopoverVisible: false,
      codeCouleurHex: "#000",
    });
  };

  closeModalCreateCodeCouleur = () => {
    this.setState({
      modalCreateCodeCouleurVisible: false,
    });
  };

  openModalEditCodeCouleur = () => {
    this.getCouleur();
    this.setState({
      modalEditCodeCouleurVisible: true,
      createPopoverVisible: false,
      editPopoverVisible: false,
    });
  };

  closeModalEditCodeCouleur = () => {
    this.setState({
      modalEditCodeCouleurVisible: false,
      createPopoverVisible: true,
    });
  };

  toggleChecked = () => {
    this.setState({ checked: !this.state.checked });
  };

  // onChangeCacherTitre = (e) => {
  //   const cacherTitre = e.target.checked;
  //   this.props.cacherTitreParametre(cacherTitre, 1);
  //   this.setState({ cacherTitre });
  // };

  // onChangeCacherInactive = (e) => {
  //   const cacherInactive = e.target.checked;
  //   this.props.cacherInactiveParametre(cacherInactive, 1);
  //   this.setState({ cacherInactive });
  // };

  render() {
    const {
      createPopoverVisible,
      editPopoverVisible,
      timerPopoverVisible,
      modalCreateCodeCouleurVisible,
      modalEditCodeCouleurVisible,
      etapeToEdit,
      couleurToEdit,
      treeEtapes,
      loading,
      // cacherTitre,
      // cacherInactive,
    } = this.state;

    const createEtapeContent = (
      <div>
        <MyFormEtape
          utilisateur={this.props.utilisateur}
          etape={null}
          codeCouleurId={this.state.codeCouleurId}
          dataEtapes={this.state.dataEtapes}
          dataCodesCouleur={this.state.dataCodesCouleur}
          parents={this.state.availableParents}
          availableOrders={this.state.availableOrder}
          initTreeForm={this.initTreeForm}
          initTree={this.props.initTree}
          sortTree={sortTree}
          handleChangeCouleur={this.handleChangeCouleur}
          handleChangeEtapeParente={this.handleChangeEtapeParente}
          createDataEtapes={this.createDataEtapes}
          updateDataEtapes={this.updateDataEtapes}
          deleteDataEtapes={this.deleteDataEtapes}
          deleteDataCodesCouleur={this.deleteDataCodesCouleur}
          getAvailableOrders={this.getAvailableOrders}
          getAvailableParents={this.getAvailableParents}
          openModalCreateCodeCouleur={this.openModalCreateCodeCouleur}
          openModalEditCodeCouleur={this.openModalEditCodeCouleur}
          saveEtape={this.props.saveEtape}
          updateEtape={this.props.updateEtape}
          deleteCodeCouleur={this.props.deleteCodeCouleur}
        />
      </div>
    );

    const createEtapeButton = (
      <Popover
        content={<div>{createEtapeContent}</div>}
        overlayStyle={{ width: "400px" }}
        title="Ajout"
        trigger="click"
        open={createPopoverVisible}
        onOpenChange={this.handleCreatePopoverVisibleChange}
      >
        <div className="button-card">
          <PlusOutlined />
        </div>
      </Popover>
    );

    const editEtapeContent = (
      <div>
        <MyFormEtape
          utilisateur={this.props.utilisateur}
          etape={etapeToEdit}
          initTreeForm={this.initTreeForm}
          initTree={this.props.initTree}
          sortTree={sortTree}
          parcoursClients={this.props.parcoursClients}
          etapes={this.props.etapes}
          renduAs={this.props.renduAs}
          dataEtapes={this.state.dataEtapes}
          dataCodesCouleur={this.state.dataCodesCouleur}
          parents={this.state.availableParents}
          availableOrders={this.state.availableOrder}
          handleChangeCouleur={this.handleChangeCouleur}
          handleEditPopoverVisibleChange={this.handleEditPopoverVisibleChange}
          handleChangeEtapeParente={this.handleChangeEtapeParente}
          updateDataEtapes={this.updateDataEtapes}
          deleteDataEtapes={this.deleteDataEtapes}
          deleteDataCodesCouleur={this.deleteDataCodesCouleur}
          getAvailableOrders={this.getAvailableOrders}
          getAvailableParents={this.getAvailableParents}
          openModalCreateCodeCouleur={this.openModalCreateCodeCouleur}
          openModalEditCodeCouleur={this.openModalEditCodeCouleur}
          updateEtape={this.props.updateEtape}
          deleteEtape={this.props.deleteEtape}
          deleteCodeCouleur={this.props.deleteCodeCouleur}
          deleteRenduA={this.props.deleteRenduA}
        />
      </div>
    );

    const editEtapeButton = (
      <Popover
        content={<div>{editEtapeContent}</div>}
        overlayStyle={{ width: "400px" }}
        title="Edition"
        trigger="click"
        open={editPopoverVisible}
        onOpenChange={this.handleEditPopoverVisibleChange}
        onClick={this.getEtapeToEdit}
      >
        {this.state.idEtapeSelected && (
          <div className="button-card">
            <EditOutlined />
          </div>
        )}
      </Popover>
    );

    const timerEtapeContent = (
      <div>
        <MyFormTimer
          etape={etapeToEdit}
          etapes={this.props.etapes}
          saveEtapeAction={this.props.saveEtapeAction}
          updateEtapeAction={this.props.updateEtapeAction}
          deleteEtapeAction={this.props.deleteEtapeAction}
        />
      </div>
    );

    const timerEtapeButton = (
      <Popover
        content={<div>{timerEtapeContent}</div>}
        overlayStyle={{ width: "400px" }}
        title="Timer / Action"
        trigger="click"
        open={timerPopoverVisible}
        onOpenChange={this.handleTimerPopoverVisibleChange}
        onClick={this.getEtapeToEdit}
      >
        {this.state.idEtapeSelected && (
          <div className="button-card">
            <ClockCircleOutlined />
          </div>
        )}
      </Popover>
    );

    return (
      <div>
        {/* A reprendre (n'est pas dynamique) */}
        <MyBreadcrumb />

        <Modal
          open={modalCreateCodeCouleurVisible}
          onCancel={this.closeModalCreateCodeCouleur}
          title="Ajout Code Couleur"
          footer={null}
        >
          <MyFormCodeCouleur
            couleurToEdit={null}
            closeModalCreateCodeCouleur={this.closeModalCreateCodeCouleur}
            handleCreateCodeCouleur={this.handleCreateCodeCouleur}
          />
        </Modal>

        <Modal
          open={modalEditCodeCouleurVisible}
          onCancel={this.closeModalEditCodeCouleur}
          title="Modification Code Couleur"
          footer={null}
        >
          <MyFormCodeCouleur
            couleurToEdit={couleurToEdit}
            closeModalEditCodeCouleur={this.closeModalEditCodeCouleur}
            handleEditCodeCouleur={this.handleEditCodeCouleur}
          />
        </Modal>

        <Row gutter={14} style={{ padding: "16px" }}>
          <Col md={8}></Col>
          <Col md={8}>
            <Spin spinning={loading} size="large" tip="Chargement...">
              <Card
                title="Etapes (sélectionnez une étape pour la modifier)"
                bordered={true}
                actions={[createEtapeButton, editEtapeButton, timerEtapeButton]}
              >
                <Scrollbars autoHide style={{ height: 600, width: "100%" }}>
                  <div
                    style={{
                      padding: 24,
                      background: "#fff",
                      minHeight: 600,
                      display: "grid",
                    }}
                  >
                    {treeEtapes && treeEtapes.length > 0 && (
                      <MyTree treeEtapes={treeEtapes} handleSelectEtape={this.handleSelectEtape} />
                    )}
                  </div>
                </Scrollbars>
              </Card>
            </Spin>
          </Col>
          <Col md={8}></Col>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    initTree: (utilisateur_id) => dispatch(initTree(utilisateur_id)),
    saveEtape: (etape) => dispatch(saveEtape(etape)),
    updateEtape: (etape, id) => dispatch(updateEtape(etape, id)),
    deleteEtape: (id) => dispatch(deleteEtape(id)),
    saveEtapeAction: (etapeAction) => dispatch(saveEtapeAction(etapeAction)),
    updateEtapeAction: (etapeAction, id) => dispatch(updateEtapeAction(etapeAction, id)),
    deleteEtapeAction: (id) => dispatch(deleteEtapeAction(id)),
    saveCodeCouleur: (codeCouleur) => dispatch(saveCodeCouleur(codeCouleur)),
    updateCodeCouleur: (codeCouleur, id) => dispatch(updateCodeCouleur(codeCouleur, id)),
    deleteCodeCouleur: (id) => dispatch(deleteCodeCouleur(id)),
    deleteRenduA: (idR, idE) => dispatch(deleteRenduA(idR, idE)),
    cacherTitreParametre: (cacherTitre, id) => dispatch(cacherTitreParametre(cacherTitre, id)),
    cacherInactiveParametre: (cacherInactive, id) =>
      dispatch(cacherInactiveParametre(cacherInactive, id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    etapes: state.etapes.data,
    codesCouleurs: state.codesCouleurs,
    parcoursClients: state.parcoursClients.data,
    renduAs: state.renduAs.data,
    parametre: state.parametre.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Etapes);

import { HttpMethod, initFetch } from "../../utils/fetcher";
import { adaptedToTag, tagsToAdapted, tagToAdapted } from "../../adapter/tagAdapter";

export const LOAD_SUCCESS_TAGS = "LOAD_SUCCESS_TAGS";
export const LOAD_FAIL_TAGS = "LOAD_FAIL_TAGS";
export const SAVE_SUCCESS_TAGS = "SAVE_SUCCESS_TAGS";
export const SAVE_FAIL_TAGS = "SAVE_FAIL_TAGS";
export const UPDATE_SUCCESS_TAGS = "UPDATE_SUCCESS_TAGS";
export const UPDATE_FAIL_TAGS = "UPDATE_FAIL_TAGS";
export const DELETE_SUCCESS_TAGS = "DELETE_SUCCESS_TAGS";
export const DELETE_FAIL_TAGS = "DELETE_FAIL_TAGS";

export function loadTags() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`tags`, HttpMethod.GET);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.tag);
          dispatch({
            type: LOAD_SUCCESS_TAGS,
            payload: tagsToAdapted(res.tag),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_TAGS,
            error: err,
          });
        });
    });
  };
}

export function saveTag(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`tags`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToTag(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.tag);
          dispatch({
            type: SAVE_SUCCESS_TAGS,
            payload: tagToAdapted(res.tag),
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_TAGS,
            error: err,
          })
        );
    });
  };
}

export function updateTag(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`tags/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToTag(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.tag);
          dispatch({
            type: UPDATE_SUCCESS_TAGS,
            payload: tagToAdapted(res.tag),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_TAGS,
            error: err,
          })
        );
    });
  };
}

export function deleteTag(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`tags/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.tag);
          dispatch({
            type: DELETE_SUCCESS_TAGS,
            payload: tagToAdapted(res.tag),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_TAGS,
            error: err,
          });
        });
    });
  };
}

import { adaptedToPersonne, personneToAdapted, simplePersonneToAdapted } from "./personneAdapter";
import {
  proprietaireAffectionsToAdapted,
  simplesProprietaireAffectionsToAdapted,
} from "./proprietaireAffectationAdapter";

export function proprietairesToAdapted(items) {
  return items
    ? items.map((item) => {
        return proprietaireToAdapted(item);
      })
    : null;
}

export function proprietaireToAdapted(item) {
  //Resource
  return item
    ? {
        proprietaire_id: item.id,
        proprietaire_personne: personneToAdapted(item.personne),
        proprietaire_code: item.code,
        proprietaire_frequence_releve: item.frequence_releve,
        proprietaire_frequence_acompte: item.frequence_acompte,
        proprietaire_type_acompte: item.type_acompte,
        proprietaire_valeur_acompte: item.valeur_acompte,
        proprietaire_password_extranet: item.password_extranet,
        proprietaire_longue_duree: item.longue_duree,
        proprietaire_affectations: proprietaireAffectionsToAdapted(item.proprietaire_affectation),
      }
    : null;
}

export function simpleProprietaireToAdapted(item) {
  //Resource
  return item
    ? {
        proprietaire_id: item.id,
        proprietaire_personne: simplePersonneToAdapted(item.personne),
        proprietaire_code: item.code,
        proprietaire_longue_duree: item.longue_duree,
        proprietaire_affectations: simplesProprietaireAffectionsToAdapted(
          item.proprietaire_affectation
        ),
      }
    : null;
}

export function adaptedToProprietaire(item) {
  const proprietaire = item
    ? {
        personne_id: item.proprietaire_personne_id,
        code: item.proprietaire_code,
        frequence_releve: item.proprietaire_frequence_releve,
        frequence_acompte: item.proprietaire_frequence_acompte,
        type_acompte: item.proprietaire_type_acompte,
        valeur_acompte: item.proprietaire_valeur_acompte,
        password_extranet: item.proprietaire_password_extranet,
        longue_duree: item.proprietaire_longue_duree,
      }
    : null;

  const personne = item ? adaptedToPersonne(item) : null;

  return item
    ? {
        ...proprietaire,
        ...personne,
      }
    : null;
}

import {
  adaptedToDescription,
  descriptionToAdapted,
  descriptionsToAdapted,
} from "../../adapter/descriptionAdapter";
import { HttpMethod, initFetch } from "../../utils/fetcher";

export const LOAD_SUCCESS_DESCRIPTIONS = "LOAD_SUCCESS_DESCRIPTIONS";
export const LOAD_FAIL_DESCRIPTIONS = "LOAD_FAIL_DESCRIPTIONS";
export const SAVE_SUCCESS_DESCRIPTIONS = "SAVE_SUCCESS_DESCRIPTIONS";
export const SAVE_FAIL_DESCRIPTIONS = "SAVE_FAIL_DESCRIPTIONS";
export const UPDATE_SUCCESS_DESCRIPTIONS = "UPDATE_SUCCESS_DESCRIPTIONS";
export const UPDATE_FAIL_DESCRIPTIONS = "UPDATE_FAIL_DESCRIPTIONS";
export const DELETE_SUCCESS_DESCRIPTIONS = "DELETE_SUCCESS_DESCRIPTIONS";
export const DELETE_FAIL_DESCRIPTIONS = "DELETE_FAIL_DESCRIPTIONS";

export const SAVE_SUCCESS_LOT_DESCRIPTION = "SAVE_SUCCESS_LOT_DESCRIPTION";
export const UPDATE_SUCCESS_LOT_DESCRIPTION = "UPDATE_SUCCESS_LOT_DESCRIPTION";

export function loadDescriptions() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`descriptions`, HttpMethod.GET);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.description);
          dispatch({
            type: LOAD_SUCCESS_DESCRIPTIONS,
            payload: descriptionsToAdapted(res.description),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_DESCRIPTIONS,
            error: err,
          });
        });
    });
  };
}

export function saveDescription(jsonData, lot_id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`descriptions`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToDescription(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.description);
          dispatch({
            type: SAVE_SUCCESS_LOT_DESCRIPTION,
            payload: descriptionToAdapted(res.description),
            id: lot_id,
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_DESCRIPTIONS,
            error: err,
          })
        );
    });
  };
}

export function updateDescription(jsonData, idLot, idLangue) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`descriptions/${idLot}/${idLangue}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToDescription(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.description);
          dispatch({
            type: UPDATE_SUCCESS_LOT_DESCRIPTION,
            payload: descriptionToAdapted(res.description),
            lot_id: idLot,
            langue_id: idLangue,
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_DESCRIPTIONS,
            error: err,
          })
        );
    });
  };
}

export function deleteDescription(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`descriptions/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.description);
          dispatch({
            type: DELETE_SUCCESS_DESCRIPTIONS,
            payload: descriptionToAdapted(res.description),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_DESCRIPTIONS,
            error: err,
          });
        });
    });
  };
}

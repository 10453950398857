import { HttpMethod, initFetch } from "../../utils/fetcher";
import {
  adaptedToRequeteLot,
  requeteLotsToAdapted,
  requeteLotToAdapted,
} from "../../adapter/requeteLotAdapter";

export const LOAD_SUCCESS_REQUETES_LOTS = "LOAD_SUCCESS_REQUETES_LOTS";
export const LOAD_FAIL_REQUETES_LOTS = "LOAD_FAIL_REQUETES_LOTS";
export const SAVE_SUCCESS_REQUETES_LOTS = "SAVE_SUCCESS_REQUETES_LOTS";
export const SAVE_FAIL_REQUETES_LOTS = "SAVE_FAIL_REQUETES_LOTS";
export const UPDATE_SUCCESS_REQUETES_LOTS = "UPDATE_SUCCESS_REQUETES_LOTS";
export const UPDATE_SUCCESS_ALL_REQUETES_LOTS = "UPDATE_SUCCESS_ALL_REQUETES_LOTS";
export const UPDATE_FAIL_REQUETES_LOTS = "UPDATE_FAIL_REQUETES_LOTS";
export const DELETE_SUCCESS_REQUETES_LOTS = "DELETE_SUCCESS_REQUETES_LOTS";
export const DELETE_FAIL_REQUETES_LOTS = "DELETE_FAIL_REQUETES_LOTS";
export const DELETE_SUCCESS_ALL_REQUETES_LOTS = "DELETE_SUCCESS_ALL_REQUETES_LOTS";
export const DELETE_FAIL_ALL_REQUETES_LOTS = "DELETE_FAIL_ALL_REQUETES_LOTS";

export function loadRequeteLots() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`requetesLots`, HttpMethod.GET);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.requete_lot);
          dispatch({
            type: LOAD_SUCCESS_REQUETES_LOTS,
            payload: requeteLotsToAdapted(res.requete_lot),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_REQUETES_LOTS,
            error: err,
          });
        });
    });
  };
}

export function saveRequeteLot(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`requetesLots`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToRequeteLot(jsonData)) })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.requete_lot);
          dispatch({
            type: SAVE_SUCCESS_REQUETES_LOTS,
            payload: requeteLotToAdapted(res.requete_lot),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: SAVE_FAIL_REQUETES_LOTS,
            error: err,
          });
        });
    });
  };
}

export function updateRequeteLot(jsonData, idRequete, idLot, requeteLotType) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(
        `requetesLots/${idRequete}/${idLot}/${requeteLotType}`,
        HttpMethod.PUT
      );
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToRequeteLot(jsonData)) })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.requete_lot);
          dispatch({
            type: UPDATE_SUCCESS_REQUETES_LOTS,
            payload: requeteLotToAdapted(res.requete_lot),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: UPDATE_FAIL_REQUETES_LOTS,
            error: err,
          });
        });
    });
  };
}

export function updateAllRequetesLots(jsonData, etablissement_id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`requetesLots/${etablissement_id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(jsonData) })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.requete_lot);
          dispatch({
            type: UPDATE_SUCCESS_ALL_REQUETES_LOTS,
            payload: res.requete_lot,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: UPDATE_FAIL_REQUETES_LOTS,
            error: err,
          });
        });
    });
  };
}

export function deleteRequeteLot(idRequete, idLot, requeteLotType) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(
        `requetesLots/${idRequete}/${idLot}/${requeteLotType}`,
        HttpMethod.DELETE
      );
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.requete_lot);
          dispatch({
            type: DELETE_SUCCESS_REQUETES_LOTS,
            payload: res.requete_lot,
            idR: idRequete,
            idL: idLot,
            typeRL: requeteLotType,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_REQUETES_LOTS,
            payload: err,
          });
        });
    });
  };
}

export function deleteAllRequeteLot(idRequete) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`requetesLots/all/${idRequete}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res);
          dispatch({
            type: DELETE_SUCCESS_ALL_REQUETES_LOTS,
            payload: res,
            idR: idRequete,
            typeRL: 1,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_ALL_REQUETES_LOTS,
            payload: err,
          });
        });
    });
  };
}

import {
  adaptedToEtablissement,
  etablissementToAdapted,
  etablissementsToAdapted,
} from "../../adapter/etablissementAdapter";
import { HttpMethod, initFetch } from "../../utils/fetcher";

export const LOAD_SUCCESS_ETABLISSEMENTS = "LOAD_SUCCESS_ETABLISSEMENTS";
export const LOAD_FAIL_ETABLISSEMENTS = "LOAD_FAIL_ETABLISSEMENTS";
export const SAVE_SUCCESS_ETABLISSEMENTS = "SAVE_SUCCESS_ETABLISSEMENTS";
export const SAVE_FAIL_ETABLISSEMENTS = "SAVE_FAIL_ETABLISSEMENTS";
export const UPDATE_SUCCESS_ETABLISSEMENTS = "UPDATE_SUCCESS_ETABLISSEMENTS";
export const UPDATE_FAIL_ETABLISSEMENTS = "UPDATE_FAIL_ETABLISSEMENTS";
export const DELETE_SUCCESS_ETABLISSEMENTS = "DELETE_SUCCESS_ETABLISSEMENTS";
export const DELETE_FAIL_ETABLISSEMENTS = "DELETE_FAIL_ETABLISSEMENTS";

export function loadEtablissements() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`etablissements`, HttpMethod.GET);

      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.etablissement);
          dispatch({
            type: LOAD_SUCCESS_ETABLISSEMENTS,
            payload: etablissementsToAdapted(res.etablissement),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_ETABLISSEMENTS,
            error: err,
          });
        });
    });
  };
}

export function saveEtablissement(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`etablissements`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToEtablissement(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.etablissement);
          dispatch({
            type: SAVE_SUCCESS_ETABLISSEMENTS,
            payload: etablissementToAdapted(res.etablissement),
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_ETABLISSEMENTS,
            error: err,
          })
        );
    });
  };
}

export function updateEtablissement(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`etablissements/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToEtablissement(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.etablissement);
          dispatch({
            type: UPDATE_SUCCESS_ETABLISSEMENTS,
            payload: etablissementToAdapted(res.etablissement),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_ETABLISSEMENTS,
            error: err,
          })
        );
    });
  };
}

export function deleteEtablissement(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`etablissements/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.etablissement);
          dispatch({
            type: DELETE_SUCCESS_ETABLISSEMENTS,
            payload: etablissementToAdapted(res.etablissement),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_ETABLISSEMENTS,
            error: err,
          });
        });
    });
  };
}

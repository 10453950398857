export function typeLotsToAdapted(items) {
  return items
    ? items.map((item) => {
        return typeLotToAdapted(item);
      })
    : null;
}

export function typeLotToAdapted(item) {
  //Resource
  return item
    ? {
        type_lot_id: item.id,
        type_lot_libelle: item.libelle,
        type_lot_groupement_id: item.groupement_id,
      }
    : null;
}

export function adaptedToTypeLot(item) {
  //Model
  return item
    ? {
        libelle: item.type_lot_libelle,
        groupement_id: item.type_lot_groupement_id,
      }
    : null;
}

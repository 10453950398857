import { champPersoToAdapted } from "./champPersoAdapter";

export function pieceContenusToAdapted(items) {
  return items
    ? items.map((item) => {
        return pieceContenuToAdapted(item);
      })
    : null;
}

export function pieceContenuToAdapted(item) {
  //Resource
  return item
    ? {
        index: item.index,
        piece_contenu_piece_id: item.piece_id,
        piece_contenu_champ_perso: champPersoToAdapted(item.champ_perso),
        piece_contenu_valeur: item.valeur,
      }
    : null;
}

export function adaptedToPieceContenu(item) {
  //Model
  return item
    ? {
        contenu_valeur: item.piece_contenu_valeur,
      }
    : null;
}

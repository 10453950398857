import React, { useEffect } from "react";
import { Button, Card, Col, Form, Input, Layout, Row, Spin } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";

const FormLogin = (props) => {
  const { isLoading } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      email: "",
      password: "",
    });
    // form.submit();
  });

  const loginButton = React.createRef();

  const handleOnFinish = (data) => {
    props.login(data);
  };

  return (
    <Layout className="login" style={{ minHeight: "100vh" }}>
      <Row type="flex" justify="center" align="middle">
        <Card
          title={
            <>
              <Row justify="center">
                <h2>Connexion</h2>
              </Row>
              {/* <Row justify="center">
                <Badge status={serverStatus} text="Serveur" />
              </Row> */}
            </>
          }
          style={{ maxWidth: "360px" }}
        >
          <Spin spinning={isLoading}>
            <Form form={form} name="login" onFinish={handleOnFinish}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Veuillez renseigner un email valide",
                  },
                ]}
              >
                <Input
                  autoComplete="on"
                  autoCapitalize="none"
                  prefix={<UserOutlined />}
                  placeholder="email"
                  type="email"
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Veuillez renseigner votre mot de passe",
                  },
                ]}
              >
                <Input.Password
                  autoComplete="on"
                  prefix={<LockOutlined />}
                  placeholder="mot de passe"
                />
              </Form.Item>

              <Form.Item>
                <Row>
                  <Button
                    ref={loginButton}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    Connexion
                  </Button>
                </Row>
              </Form.Item>

              <Row justify="center" align="middle">
                <Col>
                  <a href="/">Mot de passe oublié ?</a>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Card>
      </Row>
    </Layout>
  );
};

export default FormLogin;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Spin } from "antd";
import ListRequete from "../../components/ListRequete";
import { columns } from "./columns";
import { loadRequetes } from "../../store/actions/requeteAction";

class Requetes extends Component {
  componentDidMount() {
    const groupement_id = this.props.utilisateur.groupement_id;
    this.props.loadRequetes(groupement_id);
  }

  render() {
    const { requetes } = this.props;

    return (
      <Spin spinning={!requetes} size="large" tip="Chargement en cours...">
        <Card title="" bordered={false} className="card-panel">
          <ListRequete
            listData={requetes && requetes.filter((item) => item.requete_fin === true)}
            tableOption={columns}
            addPanesRequete={this.props.addPanesRequete}
          />
        </Card>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadRequetes: (groupement_id) => dispatch(loadRequetes(groupement_id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    requetes: state.requetes.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Requetes);

import React, { Component } from "react";

import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Toast extends Component {
  render() {
    return (
      <ToastContainer
        draggable
        rtl={false}
        pauseOnHover
        closeOnClick
        hideProgressBar
        autoClose={5000}
        transition={Slide}
        newestOnTop={false}
        pauseOnVisibilityChange
        position="bottom-center"
      />
    );
  }
}

export default Toast;

import { Row } from "antd";
import React, { Component } from "react";
import CardElement from "./CardElement";

class FormElement extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  checkIfVisible = (groupe) => {
    let bool = true;
    if (groupe.champ_perso_groupe_equipement_id)
      bool = this.props.lot.lot_equipements.some(
        (item) => item.equipement_id === groupe.champ_perso_groupe_equipement_id.equipement_id
      );
    return bool;
  };

  render() {
    const { groupes, isArchive, typeBlocTexte } = this.props;

    return (
      <>
        <Row gutter={16}>
          {groupes &&
            groupes
              .sort((a, b) => a.champ_perso_groupe_ordre - b.champ_perso_groupe_ordre)
              .map((groupe) => {
                if (groupe.champ_perso_groupe_type === 3 && this.checkIfVisible(groupe)) {
                  return (
                    <CardElement
                      isArchive={isArchive}
                      key={groupe.champ_perso_groupe_id}
                      groupe={groupe}
                      typeBlocTexte={typeBlocTexte}
                    />
                  );
                }
                return null;
              })}
          {/* .filter((groupe) => groupe.champ_perso_groupe_type === 3)
            .map((groupe) => (
              <CardElement
                isArchive={isArchive}
                key={groupe.champ_perso_groupe_id}
                groupe={groupe}
              />
            ))} */}
        </Row>
      </>
    );
  }
}

export default FormElement;

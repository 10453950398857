import React, { Component } from "react";

import { Card, Spin } from "antd";
import FormReddition from "../../components/FormReddition";
import { connect } from "react-redux";
import { loadEcritures, saveEcriture, updateEcritures } from "../../store/actions/ecritureAction";
import { loadJournals } from "../../store/actions/journalAction";
import { loadLocations } from "../../store/actions/locationAction";
import { loadProprietaires } from "../../store/actions/proprietaireAction";
import { loadQuittances } from "../../store/actions/quittanceAction";
import { loadReglements, updateReglements } from "../../store/actions/reglementAction";
import { saveEmail } from "../../store/actions/emailAction";
import { saveDossier } from "../../store/actions/dossierAction";
import { saveDocument } from "../../store/actions/documentAction";
import { updateProprietaireAffectation } from "../../store/actions/proprietaireAffectationAction";
import { TYPE_TAG } from "../../utils/constants";

class Reddition extends Component {
  componentDidMount() {
    const groupement_id = this.props.utilisateur.groupement_id;
    const societe_id = this.props.utilisateur.societe_id;
    Promise.all([
      !this.props.journals && this.props.loadJournals(societe_id),
      !this.props.locations && this.props.loadLocations(groupement_id),
      !this.props.proprietaires && this.props.loadProprietaires(groupement_id),
      !this.props.quittances && this.props.loadQuittances(groupement_id),
      !this.props.reglements && this.props.loadReglements(societe_id),
      !this.props.ecritures && this.props.loadEcritures(societe_id),
    ]);
  }

  render() {
    const {
      utilisateur,
      parametre,
      societes,
      journals,
      locations,
      proprietaires,
      quittances,
      reglements,
      ecritures,
      tags,
    } = this.props;

    return (
      <Spin
        size="large"
        tip="Chargement en cours..."
        spinning={!proprietaires || !locations || !quittances || !reglements || !ecritures}
      >
        <Card title="" bordered={false} className="card-panel">
          <FormReddition
            utilisateur={utilisateur}
            parametre={parametre}
            societes={societes.filter((s) => s.societe_id === utilisateur.societe_id)}
            journals={journals}
            locations={
              locations &&
              locations.filter(
                (l) =>
                  l.location_etat === "r" &&
                  (l.location_lot.lot_societe_id === utilisateur.societe_id ||
                    l.location_lot.lot_societe?.societe_id === utilisateur.societe_id)
              )
            }
            proprietaires={
              proprietaires &&
              proprietaires.filter(
                (p) =>
                  p.proprietaire_affectations.some(
                    (ligne) =>
                      ligne.proprietaire_affectation_societe.societe_id === utilisateur.societe_id
                  ) && p.proprietaire_personne.personne_actif === true
              )
            }
            quittances={quittances}
            reglements={reglements}
            ecritures={ecritures}
            tags={tags
              .filter((tag) => tag.tag_type === TYPE_TAG[0].value)
              .sort((a, b) => a.tag_ordre - b.tag_ordre)}
            saveEcriture={this.props.saveEcriture}
            updateEcritures={this.props.updateEcritures}
            updateReglements={this.props.updateReglements}
            saveEmail={this.props.saveEmail}
            saveDossier={this.props.saveDossier}
            saveDocument={this.props.saveDocument}
            updateProprietaireAffectation={this.props.updateProprietaireAffectation}
            addPanesPersonne={this.props.addPanesPersonne}
          />
        </Card>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadJournals: (societe_id) => dispatch(loadJournals(societe_id)),
    loadLocations: (groupement_id) => dispatch(loadLocations(groupement_id)),
    loadProprietaires: (groupement_id) => dispatch(loadProprietaires(groupement_id)),
    loadQuittances: (groupement_id) => dispatch(loadQuittances(groupement_id)),
    loadReglements: (societe_id) => dispatch(loadReglements(societe_id)),
    loadEcritures: (societe_id) => dispatch(loadEcritures(societe_id)),
    saveEcriture: (ecriture) => dispatch(saveEcriture(ecriture)),
    updateEcritures: (jsonData) => dispatch(updateEcritures(jsonData)),
    updateReglements: (jsonData) => dispatch(updateReglements(jsonData)),
    saveEmail: (jsonData) => dispatch(saveEmail(jsonData)),
    saveDossier: (jsonData) => dispatch(saveDossier(jsonData)),
    saveDocument: (jsonData, dossier, tag) => dispatch(saveDocument(jsonData, dossier, tag)),
    updateProprietaireAffectation: (jsonData, proprietaire_id, societe_id) =>
      dispatch(updateProprietaireAffectation(jsonData, proprietaire_id, societe_id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    parametre: state.parametre.data,
    societes: state.societes.data,
    journals: state.journals.data,
    locations: state.locations.data,
    proprietaires: state.proprietaires.data,
    quittances: state.quittances.data,
    reglements: state.reglements.data,
    ecritures: state.ecritures.data,
    tags: state.tags.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reddition);

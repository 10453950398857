import React, { useState, useEffect, useRef } from "react";

import { Button, Col, Form, Row, Select, Table, Typography } from "antd";

import { CSVLink } from "react-csv";
import { useReactToPrint } from "react-to-print";

import CustomSelect from "../CustomFormItem/Select";
import CustomDatePicker from "../CustomFormItem/DatePicker";

import { getInfoAgentSimple } from "../../utils/tools";

import dayjs from "dayjs";

const { Option, OptGroup } = Select;
const { Title } = Typography;

const ListMenage = (props) => {
  const { listData, lots, agents, tableOption } = props;
  const [menages, setMenages] = useState();
  const [dateDu, setDateDu] = useState(dayjs().format("DD/MM/YYYY")); // today
  const [dateAu, setDateAu] = useState(dayjs().endOf("month").format("DD/MM/YYYY")); // last day of month
  const [type, setType] = useState(null);
  const [etat, setEtat] = useState(null);
  const [agentsSelected, setAgentsSelected] = useState([]);

  // Référence du formulaire
  const formRef = useRef();

  const componentRef = useRef();

  useEffect(() => {
    function init() {
      formRef.current.setFieldsValue({
        date_du: dayjs(),
        date_au: dayjs().endOf("month"),
      });
    }
    init();
  }, []);

  useEffect(() => {
    function applyFilter() {
      let filter = [];

      // dates
      filter = listData.filter((item) =>
        dayjs(item.menage_date, "YYYY-MM-DD").isBetween(
          dayjs(dateDu, "DD/MM/YYYY"),
          dayjs(dateAu, "DD/MM/YYYY"),
          "days",
          "[]"
        )
      );

      // type
      filter = type ? filter.filter((e) => e.menage_type === type) : filter;

      // etat
      filter = etat ? filter.filter((e) => e.menage_etat === etat) : filter;

      // agents
      filter =
        agentsSelected.length > 0
          ? filter.filter((e) => agentsSelected.includes(e.menage_agent?.agent_id))
          : filter;

      setMenages(filter);
    }
    listData && applyFilter();
  }, [listData, dateDu, dateAu, type, etat, agentsSelected]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div style={{ padding: 20 }}>
      <Form ref={formRef}>
        <Row>
          <Title level={2}>Ménages</Title>
        </Row>
        <Row gutter={12}>
          <Col span={6}>
            <CustomDatePicker
              label="Date du"
              inputName="date_du"
              formItemName="date_du"
              objectValue={dateDu}
              onChange={(dateDu) => setDateDu(dateDu)}
            />
          </Col>
          <Col span={6}>
            <CustomDatePicker
              label="Date au"
              inputName="date_au"
              formItemName="date_au"
              objectValue={dateAu}
              onChange={(dateAu) => setDateAu(dateAu)}
            />
          </Col>
          <Col span={6}>
            <CustomSelect
              label="Type"
              inputName="type"
              formItemName="menage_type"
              allowClear
              objectValue={type}
              onChange={(type) => setType(type)}
            >
              <OptGroup label="Type">
                <Option key={1} value={"classique"} label={"Classique"}>
                  Classique
                </Option>
                <Option key={2} value={"intermediaire"} label={"Intermédiaire"}>
                  Intermédiaire
                </Option>
                <Option key={3} value={"proprietaire"} label={"Propriétaire"}>
                  Propriétaire
                </Option>
                <Option key={4} value={"finition"} label={"Finition"}>
                  Finition
                </Option>
              </OptGroup>
            </CustomSelect>
          </Col>
          <Col span={6}>
            <CustomSelect
              label="État"
              inputName="etat"
              formItemName="menage_etat"
              allowClear
              objectValue={etat}
              onChange={(etat) => setEtat(etat)}
            >
              <OptGroup label="État">
                <Option key={1} value={"n"} label={"Non planifié"}>
                  Non planifié
                </Option>
                <Option key={2} value={"p"} label={"Planifié"}>
                  Planifié
                </Option>
                <Option key={3} value={"f"} label={"Fait"}>
                  Fait
                </Option>
              </OptGroup>
            </CustomSelect>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <CustomSelect
              label="Agents"
              inputName="agents"
              formItemName="menage_agents"
              mode="multiple"
              allowClear
              objectValue={agentsSelected}
              onChange={(agents) => setAgentsSelected(agents)}
            >
              <OptGroup label="Agent">
                {agents &&
                  agents.map((item, i) => {
                    return (
                      <Option key={i} value={item.agent_id} label={getInfoAgentSimple(item)}>
                        <Row>
                          <Col sm={8}>{getInfoAgentSimple(item)}</Col>
                        </Row>
                      </Option>
                    );
                  })}
              </OptGroup>
            </CustomSelect>
          </Col>
        </Row>
      </Form>
      <Row gutter={12}>
        <Col offset={12} span={12}>
          <div style={{ float: "right" }}>
            <Button onClick={handlePrint} className="btn-primary">
              PDF
            </Button>
            {menages && (
              <Button style={{ marginLeft: 10 }}>
                <CSVLink filename={"export.csv"} data={menages} separator={";"}>
                  Export CSV
                </CSVLink>
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <Row gutter={12} style={{ marginTop: 10 }}>
        <Col span={24}>
          <div ref={componentRef}>
            {menages && (
              <Table
                className="list-data"
                dataSource={menages}
                columns={tableOption(lots)}
                pagination={false}
                rowKey={"menage_id"}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ListMenage;

export const columns = [
  {
    title: "#",
    dataIndex: "lot_id",
    key: "lot_id",
    sorter: (a, b) => parseInt(a.lot_id) - parseInt(b.lot_id),
    width: "6%",
  },
  {
    title: "Soc.",
    dataIndex: "lot_id",
    key: "lot_id",
    sorter: (a, b) => true,
    render: (lot) => "01",
    width: "6%",
  },
  {
    title: "Référence",
    dataIndex: "lot_reference",
    key: "lot_reference",
    sorter: (a, b) => a.lot_reference.localeCompare(b.lot_reference),
    width: "10%",
  },
  {
    title: "Proprietaire",
    dataIndex: "lot_proprietaire",
    key: "lot_proprietaire",
    render: (proprietaire) =>
      `${proprietaire.proprietaire_personne.personne_nom} ${proprietaire.proprietaire_personne.personne_prenom}`,
    width: "15%",
  },
  {
    title: "Adresse",
    dataIndex: "lot_adresse",
    key: "lot_adresse",
    render: (adresse) => adresse.adresse_gmaps_adresse,
    width: "15%",
  },
  {
    title: "Nb pièces",
    dataIndex: "lot_nb_pieces",
    key: "lot_nb_pieces",
    sorter: (a, b) => parseInt(a.lot_nb_pieces) - parseInt(b.lot_nb_pieces),
    width: "6%",
  },
];

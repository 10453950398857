export function tagsToAdapted(items) {
  return items
    ? items.map((item) => {
        return tagToAdapted(item);
      })
    : null;
}

export function tagToAdapted(item) {
  //Resource
  return item
    ? {
        index: item.index,
        tag_id: item.id,
        tag_libelle: item.libelle,
        tag_ordre: item.ordre,
        tag_type: item.type,
      }
    : null;
}

export function adaptedToTag(item) {
  //Model
  return item
    ? {
        id: item.tag_id,
        libelle: item.tag_libelle,
        ordre: item.tag_ordre,
        etablissement_id: item.tag_etablissement_id,
        type: item.tag_type,
      }
    : null;
}

import { HttpMethod, initFetch } from "../../utils/fetcher";
import {
  adaptedToProvenance,
  provenancesToAdapted,
  provenanceToAdapted,
} from "../../adapter/provenanceAdapter";

export const LOAD_PROVENANCES = "LOAD_PROVENANCES";
export const LOAD_SUCCESS_PROVENANCES = "LOAD_SUCCESS_PROVENANCES";
export const LOAD_FAIL_PROVENANCES = "LOAD_FAIL_PROVENANCES";
export const SAVE_SUCCESS_PROVENANCES = "SAVE_SUCCESS_PROVENANCES";
export const SAVE_FAIL_PROVENANCES = "SAVE_FAIL_PROVENANCES";
export const UPDATE_SUCCESS_PROVENANCES = "UPDATE_SUCCESS_PROVENANCES";
export const UPDATE_FAIL_PROVENANCES = "UPDATE_FAIL_PROVENANCES";
export const DELETE_SUCCESS_PROVENANCES = "DELETE_SUCCESS_PROVENANCES";
export const DELETE_FAIL_PROVENANCES = "DELETE_FAIL_PROVENANCES";

export function loadProvenances() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`provenances`, HttpMethod.GET);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.provenance);
          dispatch({
            type: LOAD_SUCCESS_PROVENANCES,
            payload: provenancesToAdapted(res.provenance),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_PROVENANCES,
            error: err,
          });
        });
    });
  };
}

export function saveProvenance(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`provenances`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToProvenance(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.provenance);
          dispatch({
            type: SAVE_SUCCESS_PROVENANCES,
            payload: provenanceToAdapted(res.provenance),
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_PROVENANCES,
            error: err,
          })
        );
    });
  };
}

export function updateProvenance(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`provenances/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToProvenance(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.provenance);
          dispatch({
            type: UPDATE_SUCCESS_PROVENANCES,
            payload: provenanceToAdapted(res.provenance),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_PROVENANCES,
            error: err,
          })
        );
    });
  };
}

export function deleteProvenance(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`provenances/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.provenance);
          dispatch({
            type: DELETE_SUCCESS_PROVENANCES,
            payload: provenanceToAdapted(res.provenance),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_PROVENANCES,
            error: err,
          });
        });
    });
  };
}

import React, { Component } from "react";
import { subfolder } from "../../utils/constants";

class Accueil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: 0.5,
    };
  }
  render() {
    const bgImage = {
      height: 900,
      opacity: this.state.opacity,
      backgroundImage: `url('${subfolder}/aster-noir.png')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundColor: "#b9b9b9",
    };
    return (
      <div>
        <section style={bgImage}></section>
      </div>
    );
  }
}

export default Accueil;

import React, { useState, useRef } from "react";
import { Button, Col, Form, Radio, Row, Statistic, Tooltip } from "antd";
import CustomInput from "../CustomFormItem/Input";

const FormCalculPlateforme = (props) => {
  const [plateforme, setPlateforme] = useState(1);
  const [montantBooking, setMontantBooking] = useState(0);
  const [pourcentageBooking, setPourcentageBooking] = useState(0);
  const [montantAirbnb, setMontantAirbnb] = useState(0);
  const [montantAbritel, setMontantAbritel] = useState(0);
  const [commissionAbritel, setCommissionAbritel] = useState(0);
  const { location, lignes, rubriques } = props;

  // Référence du formulaire
  const formRef = useRef();

  const resetBooking = () => {
    formRef.current.resetFields(["montant_booking", "pourcentage_booking"]);
    setMontantBooking(0);
    setPourcentageBooking(0);
  };

  const resetAirbnb = () => {
    formRef.current.resetFields(["montant_airbnb"]);
    setMontantAirbnb(0);
  };

  const resetAbritel = () => {
    formRef.current.resetFields(["montant_abritel", "commission_abritel"]);
    setMontantAbritel(0);
    setCommissionAbritel(0);
  };

  const onChangeType = (e) => {
    switch (e.target.value) {
      case 1:
        resetBooking();
        break;
      case 2:
        resetAirbnb();
        break;
      case 3:
        resetAbritel();
        break;
      default:
        break;
    }
    setPlateforme(e.target.value);
  };

  const getMontantBookingA = () => {
    return parseFloat(montantBooking) - parseFloat(pourcentageBooking);
  };

  const getMontantBookingB = () => {
    const rub10 = lignes.find((ligne) => ligne.location_ligne_rubrique.rubrique_code === "10");
    const rub20 = lignes.find((ligne) => ligne.location_ligne_rubrique.rubrique_code === "20");
    const montantRub10 = rub10 ? rub10.location_ligne_montant : 0;
    const montantRub20 = rub20 ? rub20.location_ligne_montant : 0;
    return (
      parseFloat(montantBooking) -
      parseFloat(pourcentageBooking) -
      parseFloat(montantRub10) -
      parseFloat(montantRub20)
    );
  };

  const getMontantAirbnb = () => {
    const rub10 = lignes.find((ligne) => ligne.location_ligne_rubrique.rubrique_code === "10");
    const rub20 = lignes.find((ligne) => ligne.location_ligne_rubrique.rubrique_code === "20");
    const montantRub10 = rub10 ? rub10.location_ligne_montant : 0;
    const montantRub20 = rub20 ? rub20.location_ligne_montant : 0;
    return parseFloat(montantAirbnb) - parseFloat(montantRub10) - parseFloat(montantRub20);
  };

  const getMontantAbritel = () => {
    const rub10 = lignes.find((ligne) => ligne.location_ligne_rubrique.rubrique_code === "10");
    const rub20 = lignes.find((ligne) => ligne.location_ligne_rubrique.rubrique_code === "20");
    const montantRub10 = rub10 ? rub10.location_ligne_montant : 0;
    const montantRub20 = rub20 ? rub20.location_ligne_montant : 0;
    return (
      parseFloat(montantAbritel) -
      parseFloat(commissionAbritel) -
      parseFloat(montantRub10) -
      parseFloat(montantRub20)
    );
  };

  const onFinish = () => {
    switch (plateforme) {
      case 1:
        validateBooking();
        break;
      case 2:
        validateAirbnb();
        break;
      case 3:
        validateAbritel();
        break;
      default:
        break;
    }
  };

  const validateBooking = () => {
    const rub01 = lignes.find((ligne) => ligne.location_ligne_rubrique.rubrique_code === "01");
    if (rub01) {
      rub01.location_ligne_montant = getMontantBookingB();
      props.updateLocationLigne(rub01, rub01.location_ligne_id);
    } else {
      const rub = rubriques.find((rubrique) => rubrique.rubrique_code === "01");
      if (rub) {
        const ligne = {
          location_ligne_location_id: location.location_id,
          location_ligne_montant: getMontantBookingB(),
          location_ligne_pourcentage_acompte: rub.rubrique_pourcentage_acompte,
          location_ligne_rubrique_id: rub.rubrique_id,
        };
        props.saveLocationLigne(ligne);
      }
    }
    props.closeModalPlateforme();
  };

  const validateAirbnb = () => {
    const rub01 = lignes.find((ligne) => ligne.location_ligne_rubrique.rubrique_code === "01");
    if (rub01) {
      rub01.location_ligne_montant = getMontantAirbnb();
      props.updateLocationLigne(rub01, rub01.location_ligne_id);
    } else {
      const rub = rubriques.find((rubrique) => rubrique.rubrique_code === "01");
      if (rub) {
        const ligne = {
          location_ligne_location_id: location.location_id,
          location_ligne_montant: getMontantAirbnb(),
          location_ligne_pourcentage_acompte: rub.rubrique_pourcentage_acompte,
          location_ligne_rubrique_id: rub.rubrique_id,
        };
        props.saveLocationLigne(ligne);
      }
    }
    props.closeModalPlateforme();
  };

  const validateAbritel = () => {
    const rub01 = lignes.find((ligne) => ligne.location_ligne_rubrique.rubrique_code === "01");
    const rub02 = lignes.find((ligne) => ligne.location_ligne_rubrique.rubrique_code === "02");
    if (rub01) {
      rub01.location_ligne_montant = getMontantAbritel();
      props.updateLocationLigne(rub01, rub01.location_ligne_id);
    } else {
      const rub = rubriques.find((rubrique) => rubrique.rubrique_code === "01");
      if (rub) {
        const ligne = {
          location_ligne_location_id: location.location_id,
          location_ligne_montant: getMontantAbritel(),
          location_ligne_pourcentage_acompte: rub.rubrique_pourcentage_acompte,
          location_ligne_rubrique_id: rub.rubrique_id,
        };
        props.saveLocationLigne(ligne);
      }
    }
    if (rub02) {
      rub02.location_ligne_montant = commissionAbritel;
      props.updateLocationLigne(rub01, rub01.location_ligne_id);
    } else {
      const rub = rubriques.find((rubrique) => rubrique.rubrique_code === "02");
      if (rub) {
        const ligne = {
          location_ligne_location_id: location.location_id,
          location_ligne_montant: commissionAbritel,
          location_ligne_pourcentage_acompte: rub.rubrique_pourcentage_acompte,
          location_ligne_rubrique_id: rub.rubrique_id,
        };
        props.saveLocationLigne(ligne);
      }
    }
    props.closeModalPlateforme();
  };

  return (
    <Form ref={formRef} onFinish={onFinish}>
      <Row>
        <Col span={24}>
          <Form.Item name="validation" initialValue={plateforme}>
            <Radio.Group buttonStyle="solid" onChange={onChangeType}>
              <Radio.Button className="template-radio" value={1}>
                BOOKING
              </Radio.Button>
              <Radio.Button className="template-radio" value={2}>
                AIRBNB
              </Radio.Button>
              <Radio.Button className="template-radio" value={3}>
                ABRITEL
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      {plateforme === 1 && (
        <>
          <Row gutter={12}>
            <Col span={12}>
              <CustomInput
                label="Montant location"
                inputName="montant_booking"
                formItemName="montant_booking"
                type="number"
                onChange={(e) => setMontantBooking(e.target.value)}
                // objectValue={null}
              />
            </Col>
            <Col span={12}>
              <CustomInput
                label="Pourcentage booking"
                inputName="pourcentage_booking"
                formItemName="pourcentage_booking"
                type="number"
                onChange={(e) => setPourcentageBooking(e.target.value)}
                // objectValue={null}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Tooltip title="Calcul: Montant location - Pourcentage Booking">
                <Statistic title="Montant loyer" value={getMontantBookingA()} />
              </Tooltip>
            </Col>
          </Row>
        </>
      )}
      {plateforme === 2 && (
        <>
          <Row gutter={12}>
            <Col span={12}>
              <CustomInput
                label="Montant location"
                inputName="montant_airbnb"
                formItemName="montant_airbnb"
                type="number"
                onChange={(e) => setMontantAirbnb(e.target.value)}
                // objectValue={null}
              />
            </Col>
            <Col span={12}></Col>
          </Row>
          <Row>
            <Col span={12}>
              <Tooltip title="Calcul: Montant location - Rubrique 10 - Rubrique 20">
                <Statistic title="Montant loyer" value={getMontantAirbnb()} />
              </Tooltip>
            </Col>
          </Row>
        </>
      )}
      {plateforme === 3 && (
        <>
          <Row gutter={12}>
            <Col span={12}>
              <CustomInput
                label="Montant location"
                inputName="montant_abritel"
                formItemName="montant_abritel"
                type="number"
                onChange={(e) => setMontantAbritel(e.target.value)}
                // objectValue={null}
              />
            </Col>
            <Col span={12}>
              <CustomInput
                label="Commission abritel"
                inputName="commission_abritel"
                formItemName="commission_abritel"
                type="number"
                onChange={(e) => setCommissionAbritel(e.target.value)}
                // objectValue={null}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Tooltip title="Calcul: Montant location - Commission Abritel - Rubrique 10 - Rubrique 20">
                <Statistic title="Montant loyer" value={getMontantAbritel()} />
              </Tooltip>
            </Col>
            <Col span={12}>
              <Statistic title="Commission Abritel" value={commissionAbritel} />
            </Col>
          </Row>
        </>
      )}
      <Row gutter={10} style={{ textAlign: "end" }} justify={"end"}>
        <Button
          type="primary"
          style={{ marginRight: 10 }}
          danger
          onClick={props.closeModalPlateforme}
        >
          Annuler
        </Button>
        <Button type="primary" htmlType="submit">
          Valider
        </Button>
      </Row>
    </Form>
  );
};

export default FormCalculPlateforme;

import React, { Component } from "react";
import { Col, Divider, Form, Input, Row, Select } from "antd";
import Cleave from "cleave.js/react";
import FloatLabel from "../FloatLabel";
import { validateIBAN } from "../../utils/tools";

const { Option, OptGroup } = Select;

class FormCompteBancaire extends Component {
  state = {
    typePaiement: "",
    titulaire: "",
    banque: "",
    iban: "",
    bic: "",
    readOnly: false,
  };

  componentDidMount() {
    this.props.compteBancaire
      ? this.initCompteBancaire(this.props.utilisateur, this.props.compteBancaire)
      : this.resetCompteBancaire();
  }

  UNSAFE_componentWillReceiveProps(props) {
    props.compteBancaire
      ? this.initCompteBancaire(props.utilisateur, props.compteBancaire)
      : this.resetCompteBancaire();
  }

  initCompteBancaire = (utilisateur, data) => {
    const admin = utilisateur.utilisateur_admin;
    this.setState(
      {
        typePaiement: data.compte_bancaire_type_paiement
          ? data.compte_bancaire_type_paiement.type_paiement_id
          : null,
        titulaire: data.compte_bancaire_titulaire
          ? data.compte_bancaire_titulaire
          : this.props.personne
          ? `${this.props.personne.personne_nom} ${
              this.props.personne.personne_prenom ? this.props.personne.personne_prenom : ""
            }`
          : "",
        banque: data.compte_bancaire_banque,
        iban: data.compte_bancaire_IBAN,
        bic: data.compte_bancaire_BIC,
        readOnly: data.compte_bancaire_IBAN !== null && !admin,
      },
      () => this.typeDefaut()
    );
  };

  resetCompteBancaire = () => {
    this.setState(
      {
        typePaiement: "",
        titulaire: "",
        banque: "",
        iban: "",
        bic: "",
        readOnly: false,
      },
      () => this.typeDefaut()
    );
  };

  typeDefaut = () => {
    if (!this.state.typePaiement || this.state.typePaiement === 0) {
      const typePaiementRecherche = this.props.typesPaiements.find(
        (item) => item.type_paiement_libelle === "Chèque"
      );
      this.setState({
        typePaiement: typePaiementRecherche?.type_paiement_id,
      });
    }
  };

  isVirement = (typePaiement) => {
    const tp = this.props.typesPaiements.find((item) => item.type_paiement_id === typePaiement);
    return tp?.type_paiement_libelle === "Virement";
  };

  render() {
    const { typesPaiements } = this.props;
    const { typePaiement, titulaire, banque, iban, bic, readOnly } = this.state;

    return (
      <Row gutter={16}>
        <Col span={12} style={{ padding: 20 }}>
          <Divider orientation="left">Mode de règlement</Divider>
          <Row>
            <Col span={24}>
              <FloatLabel label="Type de paiement" name="type_paiement" value={typePaiement}>
                <Form.Item name="compte_bancaire_type_paiement_id">
                  <Select
                    showSearch
                    optionFilterProp="label"
                    optionLabelProp="label"
                    onChange={(id) => this.setState({ typePaiement: id })}
                  >
                    <OptGroup label="Type de paiement">
                      {typesPaiements &&
                        typesPaiements
                          .filter((item) => item.type_paiement_compte_bancaire === true)
                          .map((item, i) => {
                            return (
                              <Option
                                key={i}
                                value={item.type_paiement_id}
                                label={item.type_paiement_libelle}
                              >
                                <Row>
                                  <Col sm={8}>{item.type_paiement_libelle}</Col>
                                </Row>
                              </Option>
                            );
                          })}
                    </OptGroup>
                  </Select>
                </Form.Item>
              </FloatLabel>
            </Col>
          </Row>
        </Col>
        {this.isVirement(typePaiement) && (
          <Col span={12} style={{ padding: 20 }}>
            <Divider orientation="left">Banque</Divider>
            <Row gutter={8}>
              <Col span={12}>
                <FloatLabel label="Titulaire" name="titulaire" value={titulaire}>
                  <Form.Item name="compte_bancaire_titulaire">
                    <Input
                      value={titulaire}
                      onChange={(e) => this.setState({ titulaire: e.target.value })}
                    />
                  </Form.Item>
                </FloatLabel>
              </Col>
              <Col span={12}>
                <FloatLabel label="Banque" name="banque" value={banque}>
                  <Form.Item name="compte_bancaire_banque">
                    <Input
                      value={banque}
                      onChange={(e) => this.setState({ banque: e.target.value })}
                    />
                  </Form.Item>
                </FloatLabel>
              </Col>
              <Col span={15}>
                <FloatLabel label="IBAN" name="iban" value={iban}>
                  <Form.Item
                    name="compte_bancaire_IBAN"
                    hasFeedback
                    validateStatus={validateIBAN(iban) ? "success" : "error"}
                  >
                    <Cleave
                      className="ant-input ant-input-lg iban-input"
                      value={iban}
                      readOnly={readOnly}
                      options={{ blocks: [4, 4, 4, 4, 4, 4, 3], uppercase: true }}
                      onChange={(e) => this.setState({ iban: e.target.value })}
                    />
                  </Form.Item>
                </FloatLabel>
              </Col>
              <Col span={9}>
                <FloatLabel label="BIC" name="bic" value={bic}>
                  <Form.Item name="compte_bancaire_BIC">
                    <Input value={bic} onChange={(e) => this.setState({ bic: e.target.value })} />
                  </Form.Item>
                </FloatLabel>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    );
  }
}

export default FormCompteBancaire;

export function equipementsToAdapted(items) {
  return items
    ? items.map((item) => {
        return equipementToAdapted(item);
      })
    : null;
}

export function equipementToAdapted(item) {
  return item
    ? {
        equipement_id: item.id,
        equipement_libelle: item.libelle,
        equipement_groupement_id: item.groupement_id,
      }
    : null;
}

export function adaptedToEquipement(item) {
  return item
    ? {
        libelle: item.equipement_libelle,
        groupement_id: item.equipement_groupement_id,
      }
    : null;
}

import React, { useState, useRef } from "react";
import { Button, Col, Descriptions, Form, Row, Select, Table, Typography } from "antd";
import { EditableRow, EditableCell } from "../EditableTable";

import { round, getInfoLocation } from "../../utils/tools";

import { toast } from "react-toastify";

import dayjs from "dayjs";

const { Option, OptGroup } = Select;
const { Text } = Typography;

const FormPrestationSupplementaire = (props) => {
  const [location, setLocation] = useState(null);
  const [rubriques, setRubriques] = useState(props.rubriques);
  const { locations, reglements, comptes, journals } = props;

  // Référence du formulaire
  const formRefPrestation = useRef();

  const onSelectLoc = (id) => {
    const loc = locations.find((item) => item.location_id === id);
    const reglementsFiltered = reglements.filter(
      (item) => item.reglement_quittance.quittance_location_id === id
    );
    loc.total = loc.location_ligne.reduce((a, b) => a + parseFloat(b.location_ligne_montant), 0);
    loc.regle = reglementsFiltered.reduce((a, b) => a + parseFloat(b.reglement_montant), 0);
    loc.du = parseFloat(loc.total) - parseFloat(loc.regle);
    setLocation(loc);
  };

  const handleSave = (row) => {
    if (!row.rubrique_quantite) {
      if (row.rubrique_optionnelle_montant !== 0) row.rubrique_quantite = 1;
    }
    setRubriques(
      rubriques.map((item) => {
        return item.rubrique_id === row.rubrique_id ? row : item;
      })
    );
  };

  const handleValidation = () => {
    const rubriquesQuittance = rubriques.filter((rub) => rub.rubrique_quantite > 0);
    createQuittance(rubriquesQuittance);
  };

  const createQuittance = (rubriquesQuittance) => {
    let montant = 0;
    const quittance = {
      quittance_libelle: "Prestations supplémentaires",
      quittance_date_debut: location.location_date_debut,
      quittance_date_fin: location.location_date_fin,
      quittance_location_id: location.location_id,
      quittance_ligne: [],
    };
    rubriquesQuittance.forEach((item) => {
      const quittanceLigne = {
        quittance_ligne_libelle: item.rubrique_libelle,
        quittance_ligne_quantite: parseInt(item.rubrique_quantite),
        quittance_ligne_montant: round(item.rubrique_optionnelle_montant, 2),
        quittance_ligne_rubrique_id: item.rubrique_id,
      };
      montant += round(item.rubrique_optionnelle_montant * item.rubrique_quantite, 2);
      quittance.quittance_ligne.push(quittanceLigne);
    });    
    props.saveQuittance(quittance).then((res) => {
      const idCompte =
        location.location_requete.requete_locataire.locataire_affectations[0]
          ?.locataire_affectation_compte_id;
      createEcriture(res, idCompte, montant);
    });
  };

  const createEcriture = (quittance, idCompte, montant) => {
    const ecritureLignes = [];
    const ligneDebit = {
      ecriture_ligne_libelle: `Contrat n°${location.location_id} prestations supplémentaires`,
      ecriture_ligne_montant_debit: montant,
      ecriture_ligne_montant_credit: 0,
      ecriture_ligne_compte_id: idCompte,
      ecriture_ligne_locataire_code: "",
      ecriture_ligne_en_reddition: false,
    };
    const ligneCredit = {
      ecriture_ligne_libelle: `Contrat n°${location.location_id} prestations supplémentaires`,
      ecriture_ligne_montant_debit: 0,
      ecriture_ligne_montant_credit: montant,
      ecriture_ligne_compte_id: comptes.find((compte) => compte.compte_compte_general === "480000")
        .compte_id,
      ecriture_ligne_locataire_code: "",
      ecriture_ligne_en_reddition: false,
    };
    ecritureLignes.push(ligneDebit);
    ecritureLignes.push(ligneCredit);
    const ecriture = {
      ecriture_journal_id: journals.find((journal) => journal.journal_code === "48").journal_id,
      ecriture_date_ecriture: dayjs().format("DD/MM/YYYY"),
      ecriture_libelle: `Contrat n°${location.location_id} prestations supplémentaires`,
      ecriture_action: `QUI${quittance.quittance_id}`,
      ecriture_ligne: ecritureLignes,
    };
    props
      .saveEcriture(ecriture)
      .then((res) => toast.success("Prestation supplémentaire créée", { containerId: "A" }))
      .catch((err) => toast.error("Erreur création écriture !", { containerId: "A" }));
  };

  let columnsA = [
    {
      title: "Libellé",
      key: "rubrique_id",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.rubrique_libelle.localeCompare(b.rubrique_libelle),
      render: (rubrique) => rubrique.rubrique_libelle,
      width: "50%",
    },
    {
      title: "Montant",
      dataIndex: "rubrique_optionnelle_montant",
      key: "rubrique_optionnelle_montant",
      render: (rubrique_optionnelle_montant) =>
        rubrique_optionnelle_montant ? rubrique_optionnelle_montant : 0,
      editable: true,
      width: "25%",
    },
    {
      title: "Quantité",
      dataIndex: "rubrique_quantite",
      key: "rubrique_quantite",
      render: (rubrique_quantite) => (rubrique_quantite ? rubrique_quantite : 0),
      editable: true,
      width: "25%",
    },
  ];

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = columnsA.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  return (
    <div style={{ padding: 20 }}>
      <Form ref={formRefPrestation}>
        <Row>
          <Col span={12}>
            <Form.Item name="location_id">
              <Select
                showSearch
                optionLabelProp="label"
                placeholder={"Locations"}
                onSelect={onSelectLoc}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <OptGroup label={"Locations"}>
                  {locations &&
                    locations
                      .filter((item) => item.location_etat === "r")
                      .map((item, i) => {
                        return (
                          <Option key={i} value={item.location_id} label={getInfoLocation(item)}>
                            <Row>
                              <Col sm={8}>{getInfoLocation(item)}</Col>
                            </Row>
                          </Option>
                        );
                      })}
                </OptGroup>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {location && (
          <>
            <Row>
              <Col span={12}>
                <Descriptions title="Règlements">
                  <Descriptions.Item label="Total">
                    {parseFloat(location?.total).toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    })}
                  </Descriptions.Item>
                  <Descriptions.Item label="Réglé">
                    {parseFloat(location?.regle).toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    })}
                  </Descriptions.Item>
                  <Descriptions.Item label="Dû">
                    {parseFloat(location?.du).toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    })}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Table
                  dataSource={rubriques.filter((item) => item.rubrique_contrat === true)}
                  components={components}
                  columns={columns}
                  pagination={false}
                  style={{ marginTop: 10 }}
                  rowClassName={() => "editable-row"}
                  locale={{ emptyText: "Aucune rubrique" }}
                  rowKey="rubrique_id"
                  summary={(pageData) => {
                    let total = 0;

                    pageData.forEach(({ rubrique_optionnelle_montant, rubrique_quantite }) => {
                      total +=
                        parseFloat(rubrique_optionnelle_montant) *
                        parseFloat(rubrique_quantite ? rubrique_quantite : 0);
                    });

                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell>
                            <strong>Total</strong>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text>
                              <strong>
                                {parseFloat(total).toLocaleString("fr-FR", {
                                  style: "currency",
                                  currency: "EUR",
                                })}
                              </strong>
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              </Col>
              <Col span={12}>
                <Button
                  type="primary"
                  style={{ marginLeft: 20, marginTop: 10 }}
                  onClick={handleValidation}
                >
                  Validation
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </div>
  );
};

export default FormPrestationSupplementaire;

import {
  LOAD_SUCCESS_TEMPLATE_LANGUE,
  LOAD_FAIL_TEMPLATE_LANGUE,
  SAVE_SUCCESS_TEMPLATE_LANGUE,
  SAVE_FAIL_TEMPLATE_LANGUE,
  UPDATE_SUCCESS_TEMPLATE_LANGUE,
  UPDATE_FAIL_TEMPLATE_LANGUE,
} from "../actions/templateLangueAction";

const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS_TEMPLATE_LANGUE:
      return {
        ...state,
        loaded: true,
        data: action.payload,
        error: null,
      };
    case LOAD_FAIL_TEMPLATE_LANGUE:
      return {
        ...state,
        loaded: false,
        data: null,
        error: action.error,
      };
    case SAVE_SUCCESS_TEMPLATE_LANGUE:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: [...state.data, action.payload],
        error: null,
      };
    case SAVE_FAIL_TEMPLATE_LANGUE:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_TEMPLATE_LANGUE:
      let updatedListe = [];
      state.data.forEach((item) => {
        if (
          item.template_langue_template.template_id ===
            action.payload.template_langue_template.template_id &&
          item.template_langue_langue.langue_id ===
            action.payload.template_langue_langue.langue_id
        ) {
          item = action.payload;
        }
        updatedListe.push(item);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: updatedListe,
        error: null,
      };
    case UPDATE_FAIL_TEMPLATE_LANGUE:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    default:
      return state;
  }
}

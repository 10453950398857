import { HttpMethod, initFetch } from "../../utils/fetcher";
import {
  adaptedToTypePaiement,
  typePaiementToAdapted,
  typePaiementsToAdapted,
} from "../../adapter/typePaiementAdapter";

export const LOAD_TYPES_PAIEMENTS = "LOAD_TYPES_PAIEMENTS";
export const LOAD_SUCCESS_TYPES_PAIEMENTS = "LOAD_SUCCESS_TYPES_PAIEMENTS";
export const LOAD_FAIL_TYPES_PAIEMENTS = "LOAD_FAIL_TYPES_PAIEMENTS";
export const SAVE_SUCCESS_TYPES_PAIEMENTS = "SAVE_SUCCESS_TYPES_PAIEMENTS";
export const SAVE_FAIL_TYPES_PAIEMENTS = "SAVE_FAIL_TYPES_PAIEMENTS";
export const UPDATE_SUCCESS_TYPES_PAIEMENTS = "UPDATE_SUCCESS_TYPES_PAIEMENTS";
export const UPDATE_FAIL_TYPES_PAIEMENTS = "UPDATE_FAIL_TYPES_PAIEMENTS";
export const DELETE_SUCCESS_TYPES_PAIEMENTS = "DELETE_SUCCESS_TYPES_PAIEMENTS";
export const DELETE_FAIL_TYPES_PAIEMENTS = "DELETE_FAIL_TYPES_PAIEMENTS";

export function loadTypesPaiements() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`typesPaiements`, HttpMethod.GET);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.type_paiement);
          dispatch({
            type: LOAD_SUCCESS_TYPES_PAIEMENTS,
            payload: typePaiementsToAdapted(res.type_paiement),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_TYPES_PAIEMENTS,
            error: err,
          });
        });
    });
  };
}

export function saveTypePaiement(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`typesPaiements`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToTypePaiement(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.type_paiement);
          dispatch({
            type: SAVE_SUCCESS_TYPES_PAIEMENTS,
            payload: typePaiementToAdapted(res.type_paiement),
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_TYPES_PAIEMENTS,
            error: err,
          })
        );
    });
  };
}

export function updateTypePaiement(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`typesPaiements/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToTypePaiement(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.type_paiement);
          dispatch({
            type: UPDATE_SUCCESS_TYPES_PAIEMENTS,
            payload: typePaiementToAdapted(res.type_paiement),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_TYPES_PAIEMENTS,
            error: err,
          })
        );
    });
  };
}

export function deleteTypePaiement(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`typesPaiements/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.type_paiement);
          dispatch({
            type: DELETE_SUCCESS_TYPES_PAIEMENTS,
            payload: typePaiementToAdapted(res.type_paiement),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_TYPES_PAIEMENTS,
            error: err,
          });
        });
    });
  };
}

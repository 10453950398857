import { journalToAdapted } from "./journalAdapter";
import { adaptedToEcrituresLignes, ecrituresLignesToAdapted } from "./ecritureLigneAdapter";

export function ecrituresToAdapted(items) {
  return items
    ? items.map((item) => {
        return ecritureToAdapted(item);
      })
    : null;
}

export function ecritureToAdapted(item) {
  return item
    ? {
        ecriture_id: item.id,
        ecriture_date_ecriture: item.date_ecriture,
        ecriture_date_saisie: item.date_saisie,
        ecriture_libelle: item.libelle,
        ecriture_lettrage: item.lettrage,
        ecriture_action: item.action,
        ecriture_validee_par: item.validee_par,
        ecriture_journal: journalToAdapted(item.journal),
        ecriture_ligne: ecrituresLignesToAdapted(item.ecriture_ligne),
      }
    : null;
}

export function adaptedToEcritures(items) {
  return items
    ? {
        ecritures: items.ecritures.map((item) => {
          return adaptedToEcriture(item);
        }),
      }
    : null;
}

export function adaptedToEcriture(item) {
  return item
    ? {
        id: item.ecriture_id,
        date_ecriture: item.ecriture_date_ecriture,
        date_saisie: item.ecriture_date_saisie,
        libelle: item.ecriture_libelle,
        lettrage: item.ecriture_lettrage,
        action: item.ecriture_action,
        validee_par: item.ecriture_validee_par,
        journal_id: item.ecriture_journal_id,
        ecriture_ligne: adaptedToEcrituresLignes(item.ecriture_ligne),
      }
    : null;
}

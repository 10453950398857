import { HttpMethod, initFetch } from "../../utils/fetcher";
import {
  adaptedToQuittance,
  quittancesToAdapted,
  quittanceToAdapted,
} from "../../adapter/quittanceAdapter";

export const LOAD_SUCCESS_QUITTANCES = "LOAD_SUCCESS_QUITTANCES";
export const LOAD_FAIL_QUITTANCES = "LOAD_FAIL_QUITTANCES";
export const SAVE_SUCCESS_QUITTANCES = "SAVE_SUCCESS_QUITTANCES";
export const SAVE_FAIL_QUITTANCES = "SAVE_FAIL_QUITTANCES";
export const UPDATE_SUCCESS_QUITTANCES = "UPDATE_SUCCESS_QUITTANCES";
export const UPDATE_FAIL_QUITTANCES = "UPDATE_FAIL_QUITTANCES";
export const DELETE_SUCCESS_QUITTANCES = "DELETE_SUCCESS_QUITTANCES";
export const DELETE_FAIL_QUITTANCES = "DELETE_FAIL_QUITTANCES";

export function loadQuittances(groupement_id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch("quittances", HttpMethod.GET);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.quittance);
          dispatch({
            type: LOAD_SUCCESS_QUITTANCES,
            payload: quittancesToAdapted(res.quittance),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_QUITTANCES,
            error: err,
          });
        });
    });
  };
}

export function saveQuittance(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch("quittances", HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToQuittance(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(quittanceToAdapted(res.quittance));
          dispatch({
            type: SAVE_SUCCESS_QUITTANCES,
            payload: quittanceToAdapted(res.quittance),
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_QUITTANCES,
            error: err,
          })
        );
    });
  };
}

export function updateQuittance(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`quittances/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToQuittance(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(quittanceToAdapted(res.quittance));
          dispatch({
            type: UPDATE_SUCCESS_QUITTANCES,
            payload: quittanceToAdapted(res.quittance),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_QUITTANCES,
            error: err,
          })
        );
    });
  };
}

export function deleteQuittance(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`quittances/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.quittance);
          dispatch({
            type: DELETE_SUCCESS_QUITTANCES,
            payload: quittanceToAdapted(res.quittance),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_QUITTANCES,
            error: err,
          });
        });
    });
  };
}

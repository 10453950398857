import React, { useState, useEffect, useRef } from "react";
import { VariableSizeGrid as Grid } from "react-window";
import ResizeObserver from "rc-resize-observer";
import classNames from "classnames";
import { Table } from "antd";

const VirtualTable = (props) => {
  const { columns, dataSource, scroll, onRow } = props;
  const [tableWidth, setTableWidth] = useState(0);
  const widthColumnSum = columns.reduce((sum, current) => {
    return current.width ? sum + current.width : sum;
  }, 0);

  const mergedColumns = columns.map((column) => {
    if (column.width) {
      return column;
    }

    return { ...column, width: Math.floor(tableWidth - widthColumnSum) - 20 };
  });
  const gridRef = useRef();
  const [connectObject] = useState(() => {
    const obj = {};
    Object.defineProperty(obj, "scrollLeft", {
      get: () => null,
      set: (scrollLeft) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({
            scrollLeft,
          });
        }
      },
    });
    return obj;
  });

  const resetVirtualGrid = () => {
    gridRef &&
      gridRef.current?.resetAfterIndices({
        columnIndex: 0,
        shouldForceUpdate: true,
      });
  };

  useEffect(() => resetVirtualGrid, [tableWidth]);

  const renderVirtualList = (rawData, { scrollbarSize, ref, onScroll }) => {
    ref.current = connectObject;
    const totalHeight = rawData.length * 54;
    return (
      <Grid
        ref={gridRef}
        className="virtual-grid"
        columnCount={mergedColumns.length}
        columnWidth={(index) => {
          const { width } = mergedColumns[index];
          return totalHeight > scroll.y && index === mergedColumns.length - 1
            ? width - scrollbarSize - 1
            : width;
        }}
        height={scroll.y}
        rowCount={rawData.length}
        rowHeight={() => 54}
        width={tableWidth}
        onScroll={({ scrollLeft }) => {
          onScroll({
            scrollLeft,
          });
        }}
      >
        {({ columnIndex, rowIndex, style }) => {
          return (
            <div
              className={classNames(
                "virtual-table-cell",
                {
                  "virtual-table-cell-last": columnIndex === mergedColumns.length - 1,
                },
                {
                  "editable-row": mergedColumns[columnIndex].editable === true,
                }
              )}
              style={style}
              align={mergedColumns[columnIndex].align}
            >
              {mergedColumns[columnIndex].dataIndex !== "" && !mergedColumns[columnIndex].render ? (
                <div> {rawData[rowIndex][mergedColumns[columnIndex].dataIndex]} </div>
              ) : (
                <div>
                  {mergedColumns[columnIndex].render(
                    rawData[rowIndex][mergedColumns[columnIndex].dataIndex],
                    rawData[rowIndex]
                  )}
                </div>
              )}
            </div>
          );
        }}
      </Grid>
    );
  };

  return (
    <ResizeObserver
      onResize={({ width }) => {
        setTableWidth(width);
      }}
    >
      <div>
        <Table
          {...props}
          className="virtual-table"
          columns={mergedColumns}
          dataSource={dataSource}
          onRow={onRow}
          pagination={false}
          components={{
            body: renderVirtualList,
          }}
        />
      </div>
    </ResizeObserver>
  );
}; // Usage

export default VirtualTable;

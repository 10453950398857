import React, { useEffect, useRef, useState } from "react";
import { Row, Table, Typography } from "antd";

const { Title } = Typography;

const ListEcartReglement = (props) => {
  const [list, setList] = useState(null);
  const { listData, locations, reglements, ecritures, tableOption } = props;

  const componentRef = useRef();

  useEffect(() => {
    listData && prepareList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listData]);

  function prepareList() {
    const myList = listData.flatMap((locataire) => {
      const locationsFiltered = locations.filter(
        (item) =>
          item.location_requete &&
          item.location_requete.requete_locataire?.locataire_id === locataire.locataire_id
      );

      const reglementsFiltered = reglements.filter(
        (item) =>
          locationsFiltered.some(
            (location) => item.reglement_quittance.quittance_location_id === location.location_id
          ) === true
      );

      const ecrituresFiltered = ecritures.filter((item) =>
        reglementsFiltered.some(
          (reglement) => item.ecriture_action === `REG${reglement.reglement_id}`
        )
      );

      let ecrituresLignes = [];
      ecrituresFiltered.forEach((ecriture) => {
        const lignes = ecriture.ecriture_ligne.filter(
          (ligne) => ligne.ecriture_ligne_compte.compte_compte_general === "418000"
        );
        if (lignes.length > 0) ecrituresLignes = ecrituresLignes.concat(lignes);
      });

      const totalReglements = reglementsFiltered.reduce(
        (a, b) => a + parseFloat(b.reglement_montant),
        0
      );

      const totalEcritures = ecrituresLignes.reduce(
        (a, b) => a + parseFloat(b.ecriture_ligne_montant_credit),
        0
      );

      return totalReglements !== totalEcritures
        ? {
            locataire_id: locataire.locataire_id,
            locataire_nom: locataire.locataire_personne.personne_nom,
            total_reglements: totalReglements,
            total_ecritures: totalEcritures,
          }
        : [];
    });
    setList(myList);
  }

  return (
    <>
      <div style={{ padding: 20 }}>
        <Row>
          <Title level={2}>Liste des écarts compta / encaissements</Title>
        </Row>
      </div>
      <div ref={componentRef}>
        {list && (
          <Table
            className="list-data"
            dataSource={list}
            columns={tableOption()}
            pagination={false}
            rowKey="locataire_id"
          />
        )}
      </div>
    </>
  );
};

export default ListEcartReglement;

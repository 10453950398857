import React, { Component } from "react";

import { Tree } from "antd";

class MyTree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedKeys: [],
    };
  }

  componentDidMount() {
    this.loopKeys(this.props.treeEtapes);
  }

  UNSAFE_componentWillReceiveProps() {
    this.loopKeys(this.props.treeEtapes);
  }

  loopKeys = (treeEtapes) => {
    treeEtapes.forEach((item) => {
      this.state.expandedKeys.push(`${item.etape_id}`);

      if (item.children && item.children.length) this.loopKeys(item.children);
    });

    this.setState({ expandedKeys: this.state.expandedKeys });
  };

  render() {
    const { expandedKeys } = this.state;

    return (
      <div>
        {expandedKeys.length > 0 && (
          <Tree
            showLine
            multiple={false}
            defaultExpandAll
            className="draggable-tree"
            treeData={this.props.treeEtapes}
            defaultExpandedKeys={expandedKeys}
            onSelect={this.props.handleSelectEtape}
          />
        )}
      </div>
    );
  }
}

export default MyTree;

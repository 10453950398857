import React, { useEffect, useRef, useState } from "react";
import { Row, Table, Typography } from "antd";

import dayjs from "dayjs";

const { Title } = Typography;

const ListEcartReddition = (props) => {
  const [list, setList] = useState(null);
  const { listData, locations, quittances, reglements, ecritures, tableOption } = props;

  const componentRef = useRef();

  useEffect(() => {
    listData && prepareList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listData]);

  function prepareList() {
    const myList = listData.flatMap((proprietaire) => {
      // LOCATIONS
      let locationsFiltered = locations.filter(
        (location) =>
          location.location_lot.lot_proprietaire.proprietaire_id === proprietaire.proprietaire_id          
      );
      const locationsFilteredId = locationsFiltered.map((item) => item.location_id);

      // QUITTANCES
      const quittancesFiltered = quittances.filter((quittance) =>
        locationsFilteredId.includes(quittance.quittance_location_id)
      );
      const quittancesFilteredId = quittancesFiltered.map((quittance) => quittance.quittance_id);

      // REGLEMENTS
      const reglementsFiltered = reglements.filter(
        (reglement) =>
          quittancesFilteredId.includes(reglement.reglement_quittance.quittance_id) &&
          reglement.reglement_reddition === false
      );

      // ECRITURES
      const lignesEcritures = [];
      let montantCompta = 0;
      const affectations = proprietaire.proprietaire_affectations;
      if (affectations.length > 0) {
        const ecrituresFiltered = ecritures.filter(
          (ecriture) =>
            ecriture.ecriture_action === null &&
            dayjs(ecriture.ecriture_date_ecriture, "DD/MM/YYYY").isSameOrBefore(dayjs())
        );
        ecrituresFiltered &&
          ecrituresFiltered.forEach((ecriture) => {
            const lignes = ecriture.ecriture_ligne.filter(
              (e) =>
                e.ecriture_ligne_compte.compte_id ===
                affectations[0].proprietaire_affectation_compte.compte_id
            );
            lignes &&
              lignes.forEach((ligne) => {
                ligne.ecriture_id = ecriture.ecriture_id;
                ligne.ecriture_journal_code = ecriture.ecriture_journal.journal_code;
                ligne.ecriture_date_ecriture = ecriture.ecriture_date_ecriture;
                if (
                  ligne.ecriture_ligne_en_reddition === false &&
                  !ligne.ecriture_ligne_libelle.includes("Regl.") &&
                  !ligne.ecriture_ligne_libelle.includes("Virement solde du") &&
                  !ligne.ecriture_ligne_libelle.includes("Virement acompte du") &&
                  !ligne.ecriture_ligne_libelle.includes("Reddition du") &&
                  !ligne.ecriture_ligne_libelle.includes("Honoraires de gestion")
                ) {
                  montantCompta += parseFloat(
                    ligne.ecriture_ligne_montant_credit - ligne.ecriture_ligne_montant_debit
                  );
                  lignesEcritures.push(ligne);
                }
              });
          });
      }

      const locs = [];
      let montantProprietaire = 0;
      reglementsFiltered.forEach((reglement) => {
        const quittance = quittancesFiltered.find(
          (e) => e.quittance_id === reglement.reglement_quittance.quittance_id
        );
        const location = {
          ...locationsFiltered.find((e) => e.location_id === quittance.quittance_location_id),
        };

        let montantReglement = 0;
        if (reglement.reglement_ligne.length > 0) {
          reglement.reglement_ligne.forEach((ligne) => {
            if (
              ligne.reglement_ligne_quittance_ligne_id.quittance_ligne_rubrique.rubrique_type ===
              "P"
            ) {
              montantReglement += parseFloat(ligne.reglement_ligne_montant);
              montantProprietaire += parseFloat(ligne.reglement_ligne_montant);
            }
          });
        }

        location.montant = montantReglement;
        locs.push(location);
      });

      let totalDebitDateButoir = 0;
      let totalCreditDateButoir = 0;

      if (affectations.length > 0) {
        const ecrituresFiltered = ecritures.filter((ecriture) =>
          dayjs(ecriture.ecriture_date_ecriture, "DD/MM/YYYY").isSameOrBefore(dayjs())
        );
        ecrituresFiltered &&
          ecrituresFiltered.forEach((ecriture) => {
            const lignes = ecriture.ecriture_ligne.filter(
              (e) =>
                e.ecriture_ligne_compte.compte_id ===
                affectations[0].proprietaire_affectation_compte.compte_id
            );
            lignes &&
              lignes.forEach((ligne) => {
                totalDebitDateButoir += parseFloat(ligne.ecriture_ligne_montant_debit);
                totalCreditDateButoir += parseFloat(ligne.ecriture_ligne_montant_credit);
              });
          });
      }

      const montantHT = parseFloat(parseFloat(montantProprietaire + montantCompta));
      const solde = totalCreditDateButoir - totalDebitDateButoir;

      const testMontantHT = montantHT.toFixed(2);
      const testSolde = solde.toFixed(2) === "-0.00" ? "0.00" : solde.toFixed(2);

      // RESULTS
      return testMontantHT !== testSolde
        ? {
            proprietaire_id: proprietaire.proprietaire_id,
            proprietaire_code: proprietaire.proprietaire_code,
            proprietaire_nom: proprietaire.proprietaire_personne.personne_nom,
            proprietaire_prenom: proprietaire.proprietaire_personne.personne_prenom,
            montantHT: montantHT,
            solde: solde,
            ecart: montantHT - solde,
          }
        : [];
    });
    setList(myList);
  }

  return (
    <>
      <div style={{ padding: 20 }}>
        <Row>
          <Title level={2}>Liste des écarts compta / reddition</Title>
        </Row>
      </div>
      <div ref={componentRef}>
        {list && (
          <Table
            className="list-data"
            dataSource={list}
            columns={tableOption()}
            pagination={false}
            rowKey="proprietaire_id"
          />
        )}
      </div>
    </>
  );
};

export default ListEcartReddition;

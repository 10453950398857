import { HttpMethod, initFetch } from "../../utils/fetcher";
import {
  adaptedToTemplateLangue,
  templateLanguesToAdapted,
  templateLangueToAdapted,
} from "../../adapter/templateLangueAdapter";

export const LOAD_SUCCESS_TEMPLATE_LANGUE = "LOAD_SUCCESS_TEMPLATE_LANGUE";
export const LOAD_FAIL_TEMPLATE_LANGUE = "LOAD_FAIL_TEMPLATE_LANGUE";
export const SAVE_SUCCESS_TEMPLATE_LANGUE = "SAVE_SUCCESS_TEMPLATE_LANGUE";
export const SAVE_FAIL_TEMPLATE_LANGUE = "SAVE_FAIL_TEMPLATE_LANGUE";
export const UPDATE_SUCCESS_TEMPLATE_LANGUE = "UPDATE_SUCCESS_TEMPLATE_LANGUE";
export const UPDATE_FAIL_TEMPLATE_LANGUE = "UPDATE_FAIL_TEMPLATE_LANGUE";

export function loadTemplatesLangues() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`templatesLangues`, HttpMethod.GET);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.template_langue);
          dispatch({
            type: LOAD_SUCCESS_TEMPLATE_LANGUE,
            payload: templateLanguesToAdapted(res.template_langue),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_TEMPLATE_LANGUE,
            error: err,
          });
        });
    });
  };
}

export function saveTemplatesLangues(jsonData, idTemplate, idLangue) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(
        `templateLangue/${idTemplate}/${idLangue}`,
        HttpMethod.POST
      );
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToTemplateLangue(jsonData)) })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.template_langue);
          dispatch({
            type: SAVE_SUCCESS_TEMPLATE_LANGUE,
            payload: templateLangueToAdapted(res.template_langue),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: SAVE_FAIL_TEMPLATE_LANGUE,
            error: err,
          });
        });
    });
  };
}

export function updateTemplatesLangues(jsonData, idTemplate, idLangue) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`templateLangue/${idTemplate}/${idLangue}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToTemplateLangue(jsonData)) })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.template_langue);
          dispatch({
            type: UPDATE_SUCCESS_TEMPLATE_LANGUE,
            payload: templateLangueToAdapted(res.template_langue),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: UPDATE_FAIL_TEMPLATE_LANGUE,
            error: err,
          });
        });
    });
  };
}

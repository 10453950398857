import {
  adaptedToRubrique,
  rubriqueToAdapted,
  rubriquesToAdapted,
} from "../../adapter/rubriqueAdapter";
import { HttpMethod, initFetch } from "../../utils/fetcher";

export const LOAD_RUBRIQUES = "LOAD_RUBRIQUES";
export const LOAD_SUCCESS_RUBRIQUES = "LOAD_SUCCESS_RUBRIQUES";
export const LOAD_FAIL_RUBRIQUES = "LOAD_FAIL_RUBRIQUES";
export const SAVE_SUCCESS_RUBRIQUES = "SAVE_SUCCESS_RUBRIQUES";
export const SAVE_FAIL_RUBRIQUES = "SAVE_FAIL_RUBRIQUES";
export const UPDATE_SUCCESS_RUBRIQUES = "UPDATE_SUCCESS_RUBRIQUES";
export const UPDATE_FAIL_RUBRIQUES = "UPDATE_FAIL_RUBRIQUES";
export const UPDATE_SUCCESS_IMPUTATION = "UPDATE_SUCCESS_IMPUTATION";
export const UPDATE_FAIL_IMPUTATION = "UPDATE_FAIL_IMPUTATION";
export const DELETE_SUCCESS_RUBRIQUES = "DELETE_SUCCESS_RUBRIQUES";
export const DELETE_FAIL_RUBRIQUES = "DELETE_FAIL_RUBRIQUES";

export function loadRubriques() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`rubriques`, HttpMethod.GET);

      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.rubrique);
          dispatch({
            type: LOAD_SUCCESS_RUBRIQUES,
            payload: rubriquesToAdapted(res.rubrique),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_RUBRIQUES,
            error: err,
          });
        });
    });
  };
}

export function saveRubrique(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`rubriques`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToRubrique(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.rubrique);
          dispatch({
            type: SAVE_SUCCESS_RUBRIQUES,
            payload: rubriqueToAdapted(res.rubrique),
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_RUBRIQUES,
            error: err,
          })
        );
    });
  };
}

export function updateRubrique(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`rubriques/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToRubrique(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.rubrique);
          dispatch({
            type: UPDATE_SUCCESS_RUBRIQUES,
            payload: rubriqueToAdapted(res.rubrique),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_RUBRIQUES,
            error: err,
          })
        );
    });
  };
}

export function deleteRubrique(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`rubriques/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.rubrique);
          dispatch({
            type: DELETE_SUCCESS_RUBRIQUES,
            payload: rubriqueToAdapted(res.rubrique),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_RUBRIQUES,
            error: err,
          });
        });
    });
  };
}

export function updateImputation(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`rubriques/imputation/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToRubrique(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.rubrique);
          dispatch({
            type: UPDATE_SUCCESS_IMPUTATION,
            payload: rubriqueToAdapted(res.rubrique),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_IMPUTATION,
            error: err,
          })
        );
    });
  };
}

export const headers = () => {
  return [
    {
      label: "#",
      key: "lot_id",
    },
    {
      label: "Référence",
      key: "lot_reference",
    },
    {
      label: "Propriétaire",
      key: "lot_proprietaire.proprietaire_personne.personne_nom",
    },
    {
      label: "Adresse",
      key: "lot_adresse.adresse_gmaps_adresse",
    },
    {
      label: "Nb pièces",
      key: "lot_nb_pieces",
    },
  ];
};

import { HttpMethod, initFetch } from "../../utils/fetcher";
import { adaptedToNuitee, nuiteeToAdapted } from "../../adapter/nuiteeAdapter";

export const SAVE_SUCCESS_NUITES = "SAVE_SUCCESS_NUITES";
export const SAVE_FAIL_NUITES = "SAVE_FAIL_NUITES";
export const UPDATE_SUCCESS_NUITES = "UPDATE_SUCCESS_NUITES";
export const UPDATE_FAIL_NUITES = "UPDATE_FAIL_NUITES";
export const DELETE_SUCCESS_NUITES = "DELETE_SUCCESS_NUITES";
export const DELETE_FAIL_NUITES = "DELETE_FAIL_NUITES";

export function saveNuite(jsonData, lot) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`nuites`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToNuitee(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.nuite);
          dispatch({
            type: SAVE_SUCCESS_NUITES,
            payload: nuiteeToAdapted(res.nuite),
            lot,
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_NUITES,
            error: err,
          })
        );
    });
  };
}

export function updateNuite(jsonData, idNuite, lot) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`nuites/${idNuite}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToNuitee(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.nuite);
          dispatch({
            type: UPDATE_SUCCESS_NUITES,
            payload: nuiteeToAdapted(res.nuite),
            lot,
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_NUITES,
            error: err,
          })
        );
    });
  };
}

export function deleteNuite(idNuite, lot) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`nuites/${idNuite}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.nuite);
          dispatch({
            type: DELETE_SUCCESS_NUITES,
            payload: nuiteeToAdapted(res.nuite),
            lot,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_NUITES,
            error: err,
          });
        });
    });
  };
}

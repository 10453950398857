import React, { Component } from "react";
import { connect } from "react-redux";

import {
  loadImmeubles,
  saveImmeuble,
  updateImmeuble,
  deleteImmeuble,
} from "../../store/actions/immeubleAction";

import { Card } from "antd";
import SelectImmeuble from "../../components/SelectImmeuble";
import { loadLots } from "../../store/actions/lotAction";

import { toast } from "react-toastify";

class Immeubles extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const groupement_id = this.props.utilisateur.groupement_id;
    const societe_id = this.props.utilisateur.societe_id;
    this.props.loadImmeubles(groupement_id);
    this.props.loadLots(societe_id);
  }

  handleSubmitImmeuble = (data) => {
    this.props
      .saveImmeuble(data)
      .then((res) => {
        toast.success("Immeuble créé !", { containerId: "A" });
      })
      .catch((err) => {
        toast.error("Échec de la création !", { containerId: "A" });
      });
  };

  handleEditImmeuble = (data, id) => {
    this.props
      .updateImmeuble(data, id)
      .then((res) => {
        toast.success("Immeuble édité !", { containerId: "A" });
      })
      .catch((err) => {
        toast.error("Échec de l'édition !", { containerId: "A" });
      });
  };

  handleDeleteImmeuble = (id) => {
    this.props
      .deleteImmeuble(id)
      .then((res) => {
        toast.success("Immeuble supprimé !", { containerId: "A" });
      })
      .catch((err) => {
        toast.error("Suppression impossible : il existe des lots rattachés à cet immeuble.", { containerId: "A" });
      });
  };

  render() {
    const { immeubles, utilisateur, immeubleProps, lots } = this.props;

    immeubles && immeubles.sort((a, b) => {
      return a.immeuble_id - b.immeuble_id;
    });

    return (
      <div>
        <Card title="" bordered={false} className="card-panel">
          {immeubles && (
            <SelectImmeuble
              immeubleProps={immeubleProps}
              utilisateur={utilisateur}
              listData={immeubles}
              handleSubmitImmeuble={this.handleSubmitImmeuble}
              handleEditImmeuble={this.handleEditImmeuble}
              handleDeleteImmeuble={this.handleDeleteImmeuble}
              lots={lots}
              addPanesLot={this.props.addPanesLot}
            />
          )}
        </Card>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadImmeubles: (groupement_id) => dispatch(loadImmeubles(groupement_id)),
    saveImmeuble: (jsonData) => dispatch(saveImmeuble(jsonData)),
    updateImmeuble: (jsonData, id) => dispatch(updateImmeuble(jsonData, id)),
    deleteImmeuble: (id) => dispatch(deleteImmeuble(id)),
    loadLots: (societe_id) => dispatch(loadLots(societe_id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    immeubles: state.immeubles.data,
    utilisateur: state.utilisateur.data,
    lots: state.lots.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Immeubles);

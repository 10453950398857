import React, { Component } from "react";

import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

import { Row, Col, Card, Form, Input, Select, Button, Popover } from "antd";

const { Option, OptGroup } = Select;
const { TextArea } = Input;

class FormParcoursClient extends Component {
  state = {
    groupe: null,
    requete: null,
    groupes: [],
    parcoursClients: [],
    currentOrdre: 0,
    nombreEtapes: 0,
  };

  componentDidMount() {
    this.init(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.init(props);
  }

  init = (props) => {
    this.setState({
      nombreEtapes: props.parametre !== undefined ? props.parametre.parametre_nombre_etapes : 1,
      requete: props.requete,
      groupes: props.groupes,
      parcoursClients: props.parcoursClients,
    });
  };

  onSelectGroupe = (id) => {
    const groupe = this.state.groupes.find((item) => item.groupe_id === id);
    this.setState({ groupe });
  };

  onKeyUpCommentaireRequete = (e) => {
    this.props.onKeyUpCommentaireRequete(e);
  };

  syncRequete = (requete) => {
    this.setState({ requete }, () => this.props.syncRequete(requete));
  };

  render() {
    const { groupes, nombreEtapes, parcoursClients } = this.state;

    const { etapes, isArchive, loadingBtn, currentOrdre } = this.props;

    const previousContent = (
      <div>
        {etapes
          .slice(0, currentOrdre)
          .reverse()
          .slice(0, nombreEtapes)
          .reverse()
          .map((item, i) => {
            return (
              <div
                key={i}
                style={{
                  textAlign: "center",
                  margin: "5px 0",
                }}
              >
                <Button loading={loadingBtn} onClick={() => this.props.goTo(item)}>
                  <strong
                    style={{
                      color: item ? item.etape_code_couleur.code_couleur_hex : "black",
                    }}
                  >
                    {item ? item.etape_libelle : ""}
                  </strong>
                </Button>
              </div>
            );
          })}
      </div>
    );

    const nextContent = (
      <div>
        {etapes
          .slice(currentOrdre + 1)
          .slice(0, nombreEtapes)
          .map((item, i) => {
            return (
              <div
                key={i}
                style={{
                  textAlign: "center",
                  margin: "5px 0",
                }}
              >
                <Button loading={loadingBtn} onClick={() => this.props.goTo(item)}>
                  <strong
                    style={{
                      color: item ? item.etape_code_couleur.code_couleur_hex : "black",
                    }}
                  >
                    {item ? item.etape_libelle : ""}
                  </strong>
                </Button>
              </div>
            );
          })}
      </div>
    );

    const sizeGroupe = (groupe) => {
      if (groupe && parcoursClients)
        return parcoursClients.filter(
          (i) => i.parcours_client_groupe.groupe_id === groupe.groupe_id
        ).length;
    };

    return (
      <div>
        <Card className="requete-card" bordered={false} disabled={isArchive}>
          <Row>
            <Col span={24}>
              <Form.Item name="requete_groupe_id">
                <Select
                  showSearch
                  optionFilterProp="label"
                  disabled={isArchive}
                  placeholder="GROUPES"
                  optionLabelProp="label"
                  onSelect={this.onSelectGroupe}
                >
                  <OptGroup label="Groupe">
                    {groupes &&
                      groupes
                        .filter((groupe) => groupe.groupe_visible === true)
                        .map((item, i) => {
                          return (
                            <Option
                              key={i}
                              value={item.groupe_id}
                              label={item.groupe_libelle}
                              disabled={!(sizeGroupe(item) > 0)}
                            >
                              <Row>
                                <Col sm={8}>{`${item.groupe_libelle}`}</Col>
                              </Row>
                            </Option>
                          );
                        })}
                  </OptGroup>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={6}>
              <Popover
                content={etapes[currentOrdre - 1] ? previousContent : "Aucune précédente"}
                title={"Étape précédente"}
                trigger="hover"
              >
                <Button
                  loading={loadingBtn}
                  htmlType="button"
                  onClick={this.props.goToPreviousEtape}
                  disabled={isArchive || (etapes[currentOrdre - 1] ? false : true)}
                >
                  <ArrowLeftOutlined />
                </Button>
              </Popover>
            </Col>
            <Col span={12} style={{ textAlign: "center" }}>
              <strong
                style={{
                  color: etapes[currentOrdre]
                    ? etapes[currentOrdre].etape_code_couleur.code_couleur_hex
                    : "black",
                }}
              >
                {etapes[currentOrdre] ? etapes[currentOrdre].etape_libelle : ""}
              </strong>
            </Col>
            <Col span={6}>
              <Popover
                trigger="hover"
                title={"Étape suivante"}
                content={etapes[currentOrdre + 1] ? nextContent : "Aucune suivante"}
              >
                <Button
                  loading={loadingBtn}
                  htmlType="button"
                  style={{ float: "right" }}
                  onClick={this.props.goToNextEtape}
                  disabled={isArchive || (etapes[currentOrdre + 1] ? false : true)}
                >
                  <ArrowRightOutlined />
                </Button>
              </Popover>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <Form.Item name="requete_commentaire">
                <TextArea
                  disabled={isArchive}
                  onKeyUp={this.onKeyUpCommentaireRequete}
                  autoSize={{ minRows: 6, maxRows: 6 }}
                  placeholder="COMMENTAIRE GÉNÉRAL SUR LA REQUETE"
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

export default FormParcoursClient;

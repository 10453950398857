import {
  DELETE_FAIL_LOCATIONS,
  DELETE_SUCCESS_LOCATIONS,
  HANDLE_SUCCESS_ALL_LOCATIONS,
  LOAD_FAIL_LOCATIONS,
  LOAD_SUCCESS_LOCATIONS,
  SAVE_FAIL_LOCATIONS,
  SAVE_FAIL_OCCUPANTS,
  SAVE_SUCCESS_LOCATIONS,
  SAVE_SUCCESS_OCCUPANTS,
  UPDATE_FAIL_LOCATIONS,
  UPDATE_FAIL_OCCUPANTS,
  UPDATE_SUCCESS_LOCATIONS,
  UPDATE_SUCCESS_OCCUPANTS,
} from "../actions/locationAction";

import {
  SAVE_SUCCESS_LOCATIONS_LIGNES,
  UPDATE_SUCCESS_LOCATIONS_LIGNES,
  DELETE_SUCCESS_LOCATIONS_LIGNES,
} from "../actions/locationLigneAction";

import {
  SAVE_SUCCESS_FEEDBACKS,
  UPDATE_SUCCESS_FEEDBACKS,
  DELETE_SUCCESS_FEEDBACKS,
} from "../actions/feedbackAction";

import { SAVE_SUCCESS_LOCATAIRES_AFFECTATIONS } from "../actions/locataireAffectationAction";
import { SAVE_SUCCESS_PROPRIETAIRES_AFFECTATIONS } from "../actions/proprietaireAffectationAction";

import { UPDATE_SUCCESS_LOCATAIRES } from "../actions/locataireAction";
import { UPDATE_SUCCESS_LOTS } from "../actions/lotAction";

import { formatDateToAPP, formatDateToMidnight, getNomLocataireRequete } from "../../utils/tools";

const formatLocation = (location) => {
  const startDate = formatDateToAPP(location.location_date_debut);
  const endDate = formatDateToAPP(location.location_date_fin);

  location.id = location.location_id;
  location.name = `Lot ${location.location_lot.lot_reference} -`;
  location.description = `${startDate} - ${endDate}`;
  location.startDate = formatDateToMidnight(location.location_date_debut);
  location.endDate = formatDateToMidnight(location.location_date_fin);

  if (location.location_etat === "b") {
    location.name = "Blocage propriétaire";
    location.color = "#6D8299";
  }

  if (location.location_etat === "n") {
    location.name = "Non disponible";
    location.color = "#D1D1D1";
  }

  if (location.location_etat === "r") {
    location.name = `Location du ${startDate} au ${endDate} (${location.location_id})`;
    location.description = location.location_requete
      ? `${getNomLocataireRequete(location.location_requete)}`
      : "";
    location.color = "#B6E2A1";
  }

  if (location.location_etat === "o") {
    location.name = `Location (Option) du ${startDate} au ${endDate} (${location.location_id})`;
    location.description = location.location_requete
      ? `${getNomLocataireRequete(location.location_requete)}`
      : "";
    location.color = "#FFE15D";
  }

  return location;
};

const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS_LOCATIONS:
      const locations = action.payload;
      locations.forEach((item) => {
        formatLocation(item);
      });
      return {
        ...state,
        loaded: true,
        data: locations,
        error: null,
      };
    case LOAD_FAIL_LOCATIONS:
      return {
        ...state,
        loaded: false,
        data: null,
        error: action.error,
      };
    case SAVE_SUCCESS_LOCATIONS:
      const item = action.payload;
      formatLocation(item);
      return {
        ...state,
        loading: false,
        loaded: true,
        data: [...state.data, item],
        error: null,
      };
    case SAVE_FAIL_LOCATIONS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_LOCATIONS:
      const updatedListe = [];
      state.data.forEach((item) => {
        if (item.location_id === action.payload.location_id) {
          item = action.payload;
          formatLocation(item);
        }
        updatedListe.push(item);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: updatedListe,
        error: null,
      };
    case UPDATE_FAIL_LOCATIONS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case DELETE_SUCCESS_LOCATIONS:
      const filtered = state.data.filter((item) => {
        return item.location_id !== action.id;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filtered,
        error: null,
      };
    case HANDLE_SUCCESS_ALL_LOCATIONS:
      const allLocations = action.payload;
      allLocations.forEach((item) => {
        formatLocation(item);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: allLocations,
        error: null,
      };
    case DELETE_FAIL_LOCATIONS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    // OCCUPANT
    case SAVE_SUCCESS_OCCUPANTS:
      const addOccupant = state.data.map((location) => {
        if (location.location_id === action.id)
          location.location_occupant = [...location.location_occupant, ...action.payload];
        return location;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: addOccupant,
        error: null,
      };
    case SAVE_FAIL_OCCUPANTS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_OCCUPANTS:
      const updateOccupant = state.data.map((location) => {
        if (location.location_id === action.id) location.location_occupant = action.payload;
        return location;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: updateOccupant,
        error: null,
      };
    case UPDATE_FAIL_OCCUPANTS:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    // Reducers location_ligne
    case SAVE_SUCCESS_LOCATIONS_LIGNES:
      const newListe = [];
      state.data.forEach((location) => {
        if (location.location_id === action.payload.location_ligne_location_id) {
          let array = location.location_ligne;
          array = [...array, action.payload];
          location.location_ligne = array;
        }
        newListe.push(location);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: newListe,
        error: null,
      };
    case UPDATE_SUCCESS_LOCATIONS_LIGNES:
      const filteredUpdate = [];
      state.data.forEach((location) => {
        const lignes = [];
        if (location.location_id === action.payload.location_ligne_location_id) {
          location.location_ligne.forEach((ligne) => {
            if (ligne.location_ligne_id === action.id) {
              ligne = action.payload;
            }
            lignes.push(ligne);
          });
          location.location_ligne = lignes;
        }
        filteredUpdate.push(location);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filteredUpdate,
        error: null,
      };
    case DELETE_SUCCESS_LOCATIONS_LIGNES:
      const filteredDelete = [];
      state.data.forEach((location) => {
        if (location.location_id === action.payload.location_ligne_location_id) {
          location.location_ligne = location.location_ligne.filter((item) => {
            return item.location_ligne_id !== action.id;
          });
        }
        filteredDelete.push(location);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filteredDelete,
        error: null,
      };
    // Reducers feedback
    case SAVE_SUCCESS_FEEDBACKS:
      const feedbackAdded = [];
      state.data.forEach((location) => {
        if (location.location_id === action.payload.location_id) {
          let array = location.feedbacks;
          array = [...array, action.payload];
          location.feedbacks = array;
        }
        feedbackAdded.push(location);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: feedbackAdded,
        error: null,
      };
    case UPDATE_SUCCESS_FEEDBACKS:
      const feedbackUpdated = [];
      state.data.forEach((location) => {
        const feedbacks = [];
        if (location.location_id === action.payload.location_id) {
          location.feedbacks.forEach((feedback) => {
            if (feedback.id === action.id) {
              feedback = action.payload;
            }
            feedbacks.push(feedback);
          });
          location.feedbacks = feedbacks;
        }
        feedbackUpdated.push(location);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: feedbackUpdated,
        error: null,
      };
    case DELETE_SUCCESS_FEEDBACKS:
      const feedbackDeleted = [];
      state.data.forEach((location) => {
        if (location.location_id === action.payload.location_id) {
          location.feedbacks = location.feedbacks.filter((feedback) => {
            return feedback.id !== action.id;
          });
        }
        feedbackDeleted.push(location);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: feedbackDeleted,
        error: null,
      };
    case SAVE_SUCCESS_LOCATAIRES_AFFECTATIONS:
      const addAffectationLoc = [];

      state.data.forEach((location) => {
        if (
          location.location_requete &&
          location.location_requete.requete_locataire.locataire_id === action.id
        ) {
          location.location_requete.requete_locataire.locataire_affectations.push(action.payload);
        }

        addAffectationLoc.push(location);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: addAffectationLoc,
        error: null,
      };
    case SAVE_SUCCESS_PROPRIETAIRES_AFFECTATIONS:
      const addAffectationPro = [];

      state.data.forEach((location) => {
        if (
          location.location_lot &&
          location.location_lot.lot_proprietaire.proprietaire_id === action.id
        ) {
          location.location_lot.lot_proprietaire.proprietaire_affectations.push(action.payload);
        }

        addAffectationPro.push(location);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: addAffectationPro,
        error: null,
      };
    case UPDATE_SUCCESS_LOCATAIRES:
      const locataireUpdated = state.data.map((location) => {
        if (location.location_requete?.requete_locataire?.locataire_id === action.id) {
          location.location_requete.requete_locataire = action.payload;
        }
        return location;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: locataireUpdated,
        error: null,
      };
    case UPDATE_SUCCESS_LOTS:
      const lotUpdated = state.data.map((location) => {
        if (location.location_lot?.lot_id === action.payload.lot_id) {
          location.location_lot = action.payload;
          location.location_lot.lot_societe_id = location.location_lot.lot_societe.societe_id;
        }
        return location;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: lotUpdated,
        error: null,
      };
    default:
      return state;
  }
}

import React, { useEffect } from "react";
import { Badge, Descriptions } from "antd";

import dayjs from "dayjs";

const ViewContrat = (props) => {
  const { location } = props;

  useEffect(() => {});

  const getEtatLocation = (location) => {
    const creation = `(créé le ${dayjs(location.location_requete.requete_created).format(
      "DD/MM/YYYY"
    )})`;
    switch (location.location_etat) {
      case "r":
        return (
          <>
            <Badge color="#B6E2A1" text="Réservé"></Badge> {creation}
          </>
        );
      case "o":
        return (
          <>
            <Badge color="#FFE15D" text="Option"></Badge> {creation}
          </>
        );
      case "a":
        return (
          <>
            <Badge color="#F44336" text="Annulé"></Badge> {creation}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Descriptions
      size="small"
      layout="vertical"
      contentStyle={{ backgroundColor: "white" }}
      column={4}
      bordered
    >
      <Descriptions.Item label="État">{getEtatLocation(location)}</Descriptions.Item>
      <Descriptions.Item label="Dates">
        {`${dayjs(location.location_date_debut).format("DD/MM/YYYY")} - ${dayjs(
          location.location_date_fin
        ).format("DD/MM/YYYY")}`}
      </Descriptions.Item>
      <Descriptions.Item label="Lot">{location.location_lot.lot_reference}</Descriptions.Item>
      <Descriptions.Item label="Date de fin d'option">
        {location.location_date_fin_option
          ? dayjs(location.location_date_fin_option).format("DD/MM/YYYY")
          : "Non renseigné"}
      </Descriptions.Item>
      <Descriptions.Item label="Montant">
        {parseFloat(
          location.location_ligne.reduce((a, b) => a + parseFloat(b.location_ligne_montant), 0)
        ).toLocaleString("fr-FR", {
          style: "currency",
          currency: "EUR",
        })}
      </Descriptions.Item>
      <Descriptions.Item label="Nb. d'adultes">{location.location_nb_adultes}</Descriptions.Item>
      <Descriptions.Item label="Nb. d'enfants">{location.location_nb_enfants}</Descriptions.Item>
      <Descriptions.Item label="Nb. de bébés">{location.location_nb_bebes}</Descriptions.Item>
      <Descriptions.Item label="Désidérata" span={4}>
        {location.location_desiderata}
      </Descriptions.Item>
      <Descriptions.Item label="Remarques" span={4}>
        {location.location_remarques}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default ViewContrat;

import { adaptedToLangue, langueToAdapted, languesToAdapted } from "../../adapter/langueAdapter";
import { HttpMethod, initFetch } from "../../utils/fetcher";

export const LOAD_SUCCESS_LANGUES = "LOAD_SUCCESS_LANGUES";
export const LOAD_FAIL_LANGUES = "LOAD_FAIL_LANGUES";
export const SAVE_SUCCESS_LANGUES = "SAVE_SUCCESS_LANGUES";
export const SAVE_FAIL_LANGUES = "SAVE_FAIL_LANGUES";
export const UPDATE_SUCCESS_LANGUES = "UPDATE_SUCCESS_LANGUES";
export const UPDATE_FAIL_LANGUES = "UPDATE_FAIL_LANGUES";
export const DELETE_SUCCESS_LANGUES = "DELETE_SUCCESS_LANGUES";
export const DELETE_FAIL_LANGUES = "DELETE_FAIL_LANGUES";

export function loadLangues() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`langues`, HttpMethod.GET);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.langue);
          dispatch({
            type: LOAD_SUCCESS_LANGUES,
            payload: languesToAdapted(res.langue),
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: LOAD_FAIL_LANGUES,
            error: err,
          });
        });
    });
  };
}

export function saveLangue(jsonData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`langues`, HttpMethod.POST);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToLangue(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.langue);
          dispatch({
            type: SAVE_SUCCESS_LANGUES,
            payload: langueToAdapted(res.langue),
          });
        })
        .catch((err) =>
          dispatch({
            type: SAVE_FAIL_LANGUES,
            error: err,
          })
        );
    });
  };
}

export function updateLangue(jsonData, id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`langues/${id}`, HttpMethod.PUT);
      // make async call to database
      fetch(url, { ...params, body: JSON.stringify(adaptedToLangue(jsonData)) })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.langue);
          dispatch({
            type: UPDATE_SUCCESS_LANGUES,
            payload: langueToAdapted(res.langue),
          });
        })
        .catch((err) =>
          dispatch({
            type: UPDATE_FAIL_LANGUES,
            error: err,
          })
        );
    });
  };
}

export function deleteLangue(id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { url, params } = initFetch(`langues/${id}`, HttpMethod.DELETE);
      // make async call to database
      fetch(url, params)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("500");
          }
        })
        .then((res) => {
          resolve(res.langue);
          dispatch({
            type: DELETE_SUCCESS_LANGUES,
            payload: langueToAdapted(res.langue),
            id: id,
          });
        })
        .catch((err) => {
          reject(err);
          dispatch({
            type: DELETE_FAIL_LANGUES,
            error: err,
          });
        });
    });
  };
}

import {
  LOAD_SUCCESS_PIECES,
  LOAD_FAIL_PIECES,
  SAVE_SUCCESS_PIECES,
  SAVE_FAIL_PIECES,
  UPDATE_SUCCESS_PIECES,
  UPDATE_SUCCESS_PIECES_FROM_CONTENUS,
  UPDATE_FAIL_PIECES,
  DELETE_SUCCESS_PIECES,
  DELETE_FAIL_PIECES,
} from "../actions/pieceAction";

const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS_PIECES:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };
    case LOAD_FAIL_PIECES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error,
      };
    case SAVE_SUCCESS_PIECES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: [...state.data, action.payload],
        error: null,
      };
    case SAVE_FAIL_PIECES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_PIECES:
      const updatedListe = [];
      state.data.forEach((item) => {
        if (item.piece_id === action.payload.piece_id) {
          item = action.payload;
        }
        updatedListe.push(item);
      });

      return {
        ...state,
        loading: false,
        loaded: true,
        data: updatedListe,
        error: null,
      };
    case UPDATE_SUCCESS_PIECES_FROM_CONTENUS:
      const updatedListeFromContenus = [];
      state.data.forEach((piece) => {
        if (piece.piece_id === action.payload.piece_contenu_piece_id) {
          let contenu = piece.piece_contenu.find(
            (item) =>
              item.piece_contenu_champ_perso.champ_perso_id ===
              action.payload.piece_contenu_champ_perso.champ_perso_id
          );
          if (contenu)
            contenu.piece_contenu_valeur = action.payload.piece_contenu_valeur;
          else piece.piece_contenu.push(action.payload);
        }
        updatedListeFromContenus.push(piece);
      });

      return {
        ...state,
        loading: false,
        loaded: true,
        data: updatedListeFromContenus,
        error: null,
      };
    case UPDATE_FAIL_PIECES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case DELETE_SUCCESS_PIECES:
      const filtered = state.data.filter((item) => {
        return item.piece_id !== action.id;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filtered,
        error: null,
      };
    case DELETE_FAIL_PIECES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    default:
      return state;
  }
}

import { personneToAdapted } from "./personneAdapter";

export function evenementsToAdapted(items) {
  return items
    ? items.map((item) => {
        return evenementToAdapted(item);
      })
    : null;
}

export function evenementToAdapted(item) {
  return item
    ? {
        evenement_id: item.id,
        evenement_personne: personneToAdapted(item.personne),
        evenement_date_creation: item.date_creation,
        evenement_detail: item.detail,
      }
    : null;
}

export function adaptedToEvenement(item) {
  return item
    ? {
        personne_id: item.evenement_personne_id,
        date_creation: item.evenement_date_creation,
        detail: item.evenement_detail,
      }
    : null;
}

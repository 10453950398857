import React, { Component } from "react";
import { Button, Col, Divider, Form, Input, Modal, Row, Tooltip } from "antd";
import { GoogleOutlined } from "@ant-design/icons";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { Wrapper } from "@googlemaps/react-wrapper";
import FloatLabel from "../FloatLabel";
import Map from "../CustomMap/map";
import Marker from "../CustomMap/marker";

class FormAdresse extends Component {
  state = {
    modalMaps: false,
    adresseGmaps: "",
    numero: "",
    voie: "",
    complement: "",
    cp: "",
    ville: "",
    departement: "",
    region: "",
    pays: "",
    lat: "",
    lng: "",
  };

  componentDidMount() {
    this.props.adresse && this.init(this.props.adresse);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.adresse !== this.props.adresse) {
      props.adresse ? this.init(props.adresse) : this.reset();
    }
  }

  init = (adresse) => {
    this.props.formRef.current &&
      this.props.formRef.current.setFieldsValue({
        adresse_gmaps_adresse: adresse.adresse_gmaps_adresse,
        adresse_numero: adresse.adresse_numero,
        adresse_voie: adresse.adresse_voie,
        adresse_situation: adresse.adresse_situation,
        adresse_code_postal: adresse.adresse_code_postal,
        adresse_ville: adresse.adresse_ville,
        adresse_departement: adresse.adresse_departement,
        adresse_region: adresse.adresse_region,
        adresse_pays: adresse.adresse_pays,
        adresse_gmaps_lat: adresse.adresse_gmaps_lat,
        adresse_gmaps_lng: adresse.adresse_gmaps_lng,
      });

    this.setState({
      adresseGmaps: adresse.adresse_gmaps_adresse,
      numero: adresse.adresse_numero,
      voie: adresse.adresse_voie,
      cp: adresse.adresse_code_postal,
      ville: adresse.adresse_ville,
      departement: adresse.adresse_departement,
      region: adresse.adresse_region,
      pays: adresse.adresse_pays,
      lat: adresse.adresse_gmaps_lat,
      lng: adresse.adresse_gmaps_lng,
      complement: adresse.adresse_situation,
    });
  };

  reset = () => {
    this.props.formRef.current.resetFields([
      "adresse_gmaps_adresse",
      "adresse_numero",
      "adresse_voie",
      "adresse_code_postal",
      "adresse_ville",
      "adresse_departement",
      "adresse_region",
      "adresse_pays",
      "adresse_gmaps_lat",
      "adresse_gmaps_lng",
      "adresse_situation",
    ]);

    this.setState({
      adresseGmaps: "",
      numero: "",
      voie: "",
      cp: "",
      ville: "",
      departement: "",
      region: "",
      pays: "",
      lat: "",
      lng: "",
      complement: "",
    });
  };

  handleChangeAdresse = (adresse) => {
    this.setState({ adresseGmaps: adresse });
  };

  handleSelectAdresse = (adresse) => {
    this.props.formRef.current.setFieldsValue({
      adresse_gmaps_adresse: adresse,
      adresse_numero: "",
      adresse_voie: "",
      adresse_ville: "",
      adresse_code_postal: "",
      adresse_region: "",
      adresse_departement: "",
      adresse_pays: "",
      adresse_situation: "",
    });
    geocodeByAddress(adresse).then((results) => {
      results[0].address_components.forEach((component, i) => {
        if (component.types[0] === "street_number") {
          this.setState({ numero: component.long_name });
          this.props.formRef.current.setFieldsValue({
            adresse_numero: component.long_name,
          });
        }
        if (component.types[0] === "route") {
          this.setState({ voie: component.long_name });
          this.props.formRef.current.setFieldsValue({
            adresse_voie: component.long_name,
          });
        }
        if (component.types[0] === "locality") {
          this.setState({ ville: component.long_name });
          this.props.formRef.current.setFieldsValue({
            adresse_ville: component.long_name,
          });
        }
        if (component.types[0] === "postal_code") {
          this.setState({ cp: component.long_name });
          this.props.formRef.current.setFieldsValue({
            adresse_code_postal: component.long_name,
          });
        }
        if (component.types[0] === "administrative_area_level_1") {
          this.setState({ region: component.long_name });
          this.props.formRef.current.setFieldsValue({
            adresse_region: component.long_name,
          });
        }
        if (component.types[0] === "administrative_area_level_2") {
          this.setState({ departement: component.long_name });
          this.props.formRef.current.setFieldsValue({
            adresse_departement: component.long_name,
          });
        }
        if (component.types[0] === "country") {
          this.setState({ pays: component.long_name });
          this.props.formRef.current.setFieldsValue({
            adresse_pays: component.long_name,
          });
        }
      });
      getLatLng(results[0]).then(({ lat, lng }) => {
        this.setState({ lat, lng });
        this.props.formRef.current.setFieldsValue({
          adresse_gmaps_lat: lat,
          adresse_gmaps_lng: lng,
        });
      });
    });
    this.setState({ adresseGmaps: adresse });
  };

  onClickShowAdresseGmaps = () => {
    this.setState({ modalMaps: true });
  };

  closeModal = () => {
    this.setState({ modalMaps: false });
  };

  render() {
    const { gps } = this.props;
    const {
      modalMaps,
      adresseGmaps,
      numero,
      voie,
      complement,
      cp,
      ville,
      departement,
      region,
      pays,
      lat,
      lng,
    } = this.state;

    const center = {
      lat: lat ? parseFloat(lat) : 0,
      lng: lng ? parseFloat(lng) : 0,
    };
    const zoom = 15;

    return (
      <div>
        <Modal
          title="Google maps"
          open={modalMaps}
          onCancel={this.closeModal}
          width={800}
          footer={null}
        >
          <div style={{ display: "flex", height: 600 }}>
            <Wrapper apiKey={"AIzaSyDfXRddj8TFtlHq5HDGBxUsnYMIEcJsvX4"}>
              <Map center={center} zoom={zoom} style={{ flexGrow: "1", height: "100%" }}>
                <Marker position={center} />
              </Map>
            </Wrapper>
          </div>
        </Modal>
        <Divider orientation="left">Adresse</Divider>
        <Row gutter={10}>
          <Col span={24}>
            <PlacesAutocomplete
              value={adresseGmaps}
              onChange={this.handleChangeAdresse}
              onSelect={this.handleSelectAdresse}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                  <FloatLabel
                    label="Recherche GOOGLE"
                    name="adresse_gmaps_adresse"
                    value={adresseGmaps}
                  >
                    <Form.Item name="adresse_gmaps_adresse">
                      <Row gutter={10}>
                        <Col span={22}>
                          <Input
                            {...getInputProps({
                              className: "location-search-input",
                            })}
                          />
                        </Col>
                        <Col span={2}>
                          <Tooltip title="Google maps">
                            <Button
                              icon={<GoogleOutlined />}
                              onClick={() => this.onClickShowAdresseGmaps()}
                            />
                          </Tooltip>
                        </Col>
                      </Row>
                    </Form.Item>
                  </FloatLabel>
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Chargement ...</div>}
                    {suggestions.map((suggestion, i) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                          key={i}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </Col>

          <Col span={8}>
            <FloatLabel label="N°" name="adresse_numero" value={numero}>
              <Form.Item name="adresse_numero">
                <Input value={numero} onChange={(e) => this.setState({ numero: e.target.value })} />
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={16}>
            <FloatLabel label="Libellé voie" name="adresse_voie" value={voie}>
              <Form.Item name="adresse_voie">
                <Input value={voie} onChange={(e) => this.setState({ voie: e.target.value })} />
              </Form.Item>
            </FloatLabel>
          </Col>

          <Col span={24}>
            <FloatLabel label="Complément adresse" name="adresse_situation" value={complement}>
              <Form.Item name="adresse_situation">
                <Input
                  value={complement}
                  onChange={(e) => this.setState({ complement: e.target.value })}
                />
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={8}>
            <FloatLabel label="CP" name="adresse_code_postal" value={cp}>
              <Form.Item name="adresse_code_postal">
                <Input value={cp} onChange={(e) => this.setState({ cp: e.target.value })} />
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={16}>
            <FloatLabel label="Ville" name="adresse_ville" value={ville}>
              <Form.Item name="adresse_ville">
                <Input value={ville} onChange={(e) => this.setState({ ville: e.target.value })} />
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={8}>
            <FloatLabel label="Département" name="adresse_departement" value={departement}>
              <Form.Item name="adresse_departement">
                <Input
                  value={departement}
                  onChange={(e) => this.setState({ departement: e.target.value })}
                />
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={8}>
            <FloatLabel label="Région" name="adresse_region" value={region}>
              <Form.Item name="adresse_region">
                <Input value={region} onChange={(e) => this.setState({ region: e.target.value })} />
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={8}>
            <FloatLabel label="Pays" name="adresse_pays" value={pays}>
              <Form.Item name="adresse_pays">
                <Input value={pays} onChange={(e) => this.setState({ pays: e.target.value })} />
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={12}>
            <FloatLabel
              label="Latitude"
              name="adresse_gmaps_lat"
              value={lat}
              style={{ display: gps ? "initial" : "none" }}
            >
              <Form.Item name="adresse_gmaps_lat">
                <Input value={lat} onChange={(e) => this.setState({ lat: e.target.value })} />
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={12}>
            <FloatLabel
              label="Longitude"
              name="adresse_gmaps_lng"
              value={lng}
              style={{ display: gps ? "initial" : "none" }}
            >
              <Form.Item name="adresse_gmaps_lng">
                <Input value={lng} onChange={(e) => this.setState({ lng: e.target.value })} />
              </Form.Item>
            </FloatLabel>
          </Col>
        </Row>
      </div>
    );
  }
}

export default FormAdresse;

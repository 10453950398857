import dayjs from "dayjs";
import {
  LOAD_SUCCESS_QUITTANCES,
  LOAD_FAIL_QUITTANCES,
  SAVE_SUCCESS_QUITTANCES,
  SAVE_FAIL_QUITTANCES,
  UPDATE_SUCCESS_QUITTANCES,
  UPDATE_FAIL_QUITTANCES,
  DELETE_SUCCESS_QUITTANCES,
  DELETE_FAIL_QUITTANCES,
} from "../actions/quittanceAction";

import {
  SAVE_SUCCESS_QUITTANCES_LIGNES,
  UPDATE_SUCCESS_QUITTANCES_LIGNES,
  DELETE_SUCCESS_QUITTANCES_LIGNES,
} from "../actions/quittanceLigneAction";

const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUCCESS_QUITTANCES:
      return {
        ...state,
        loaded: true,
        data: action.payload,
        error: null,
      };
    case LOAD_FAIL_QUITTANCES:
      return {
        ...state,
        loaded: false,
        data: null,
        error: action.error,
      };
    case SAVE_SUCCESS_QUITTANCES:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: [...state.data, action.payload],
        error: null,
      };
    case SAVE_FAIL_QUITTANCES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case UPDATE_SUCCESS_QUITTANCES:
      const updatedListe = [];

      state.data.forEach((item) => {
        if (item.quittance_id === action.payload.quittance_id) {
          let datePaiement = action.payload.quittance_date_paiement;
          action.payload.quittance_date_paiement = datePaiement
            ? dayjs(datePaiement, "DD/MM/YYYY").format("YYYY-MM-DD")
            : null;
          item = action.payload;
        }
        updatedListe.push(item);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: updatedListe,
        error: null,
      };
    case UPDATE_FAIL_QUITTANCES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    case DELETE_SUCCESS_QUITTANCES:
      const filtered = state.data.filter((item) => {
        return item.quittance_id !== action.id;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filtered,
        error: null,
      };
    case DELETE_FAIL_QUITTANCES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    // Reducers quittance_ligne
    case SAVE_SUCCESS_QUITTANCES_LIGNES:
      const newListe = [];
      state.data.forEach((quittance) => {
        if (quittance.quittance_id === action.payload.quittance_ligne_quittance_id) {
          let array = quittance.quittance_ligne;
          array = [...array, action.payload];
          quittance.quittance_ligne = array;
        }
        newListe.push(quittance);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: newListe,
        error: null,
      };
    case UPDATE_SUCCESS_QUITTANCES_LIGNES:
      const filteredUpdate = [];
      state.data.forEach((quittance) => {
        const lignes = [];
        if (quittance.quittance_id === action.payload.quittance_ligne_quittance_id) {
          quittance.quittance_ligne.forEach((ligne) => {
            if (ligne.quittance_ligne_id === action.id) {
              ligne = action.payload;
            }
            lignes.push(ligne);
          });
          quittance.quittance_ligne = lignes;
        }
        filteredUpdate.push(quittance);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filteredUpdate,
        error: null,
      };
    case DELETE_SUCCESS_QUITTANCES_LIGNES:
      const filteredDelete = [];
      state.data.forEach((quittance) => {
        if (quittance.quittance_id === action.payload.quittance_ligne_quittance_id) {
          quittance.quittance_ligne = quittance.quittance_ligne.filter((item) => {
            return item.quittance_ligne_id !== action.id;
          });
        }
        filteredDelete.push(quittance);
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: filteredDelete,
        error: null,
      };
    default:
      return state;
  }
}

import { simpleRubriqueToAdapted } from "./rubriqueAdapter";

export function quittanceLignesToAdapted(items) {
  return items
    ? items.map((item) => {
        return quittanceLigneToAdapted(item);
      })
    : null;
}

export function quittanceLigneToAdapted(item) {
  //Resource
  return item
    ? {
        quittance_ligne_id: item.id,
        quittance_ligne_libelle: item.ligne_libelle,
        quittance_ligne_montant: item.montant,
        quittance_ligne_quantite: item.quantite,
        quittance_ligne_quittance_id: item.quittance_id,
        quittance_ligne_rubrique: simpleRubriqueToAdapted(item.rubrique),
      }
    : null;
}

export function adaptedToQuittanceLignes(items) {
  return items
    ? items.map((item) => {
        return adaptedToQuittanceLigne(item);
      })
    : null;
}

export function adaptedToQuittanceLigne(item) {
  //Model
  return item
    ? {
        quittance_id: item.quittance_ligne_quittance_id,
        ligne_libelle: item.quittance_ligne_libelle,
        montant: item.quittance_ligne_montant,
        quantite: item.quittance_ligne_quantite,
        rubrique_id: item.quittance_ligne_rubrique_id,
      }
    : null;
}

import React, { Component } from "react";
import { Form, Input, Button } from "antd";
import MySketchPicker from "../../components/SketchPicker/index";
import FormItem from "antd/lib/form/FormItem";

class MyFormCodeCouleur extends Component {
  constructor(props) {
    super(props);

    this.state = {
      couleurHex: "#000",
    };
  }

  // Référence du formulaire
  formRef = React.createRef();

  componentDidMount() {
    if (this.props.couleurToEdit) {
      this.setState({
        couleurHex: this.props.couleurToEdit.code_couleur_hex,
      });
    }
  }

  /**
   * On met à jour le formulaire dès que l'on reçoit de nouvelles props
   *
   * @param {*} newProps
   */
  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.couleurToEdit) {
      this.formRef.current.setFieldsValue({
        couleurInput: newProps.couleurToEdit.code_couleur_libelle,
      });
      this.setState({
        couleurHex: newProps.couleurToEdit.code_couleur_hex,
      });
    }
  }

  handleChangeCodeCouleurComplete = (couleurHex) => {
    this.setState({ couleurHex });
  };

  /**
   *  onSubmit pour un create
   *
   * @param {*} values
   */
  handleCreate = (values) => {
    this.props.handleCreateCodeCouleur(values, this.state.couleurHex);
  };

  onFinishFailed = (errorInfo) => {};

  /**
   * onSubmit pour un edit
   *
   * @param {*} values
   */
  handleEdit = (values) => {
    this.props.handleEditCodeCouleur(values, this.state.couleurHex);
    this.formRef.current.resetFields();
  };

  render() {
    const { couleurToEdit } = this.props;
    const { couleurHex } = this.state;

    const initialValues = {
      couleurInput: couleurToEdit ? couleurToEdit.code_couleur_libelle : "",
    };

    return (
      <Form
        ref={this.formRef}
        initialValues={initialValues}
        className="ant-advanced-search-form"
        onFinish={couleurToEdit ? this.handleEdit : this.handleCreate}
        onFinishFailed={this.onFinishFailed}
      >
        <Form.Item
          name="couleurInput"
          label="Libellé Code Couleur"
          rules={[
            { required: true, message: "Libelle du code couleur obligatoire" },
          ]}
        >
          <Input />
        </Form.Item>

        <div
          style={{
            backgroundColor: couleurHex,
            width: "470px",
            height: "20px",
            marginBottom: "20px",
            borderRadius: "4px",
          }}
        />
        <MySketchPicker
          couleurHex={couleurHex}
          handleChangeCodeCouleurComplete={this.handleChangeCodeCouleurComplete}
        />
        {couleurToEdit ? (
          <FormItem style={{ float: "right" }}>
            <Button key="back" onClick={this.props.closeModalEditCodeCouleur}>
              Retour
            </Button>
            <Button
              style={{ marginLeft: "10px" }}
              htmlType="submit"
              type="primary"
            >
              Editer
            </Button>
          </FormItem>
        ) : (
          <FormItem style={{ float: "right" }}>
            <Button key="back" onClick={this.props.closeModalCreateCodeCouleur}>
              Retour
            </Button>
            <Button
              style={{ marginLeft: "10px" }}
              htmlType="submit"
              type="primary"
            >
              Ajouter
            </Button>
          </FormItem>
        )}
      </Form>
    );
  }
}

export default MyFormCodeCouleur;

import React, { Component } from "react";

import { toast } from "react-toastify";

import { Scrollbars } from "react-custom-scrollbars";

import MyTreeGroupe from "../../components/TreeGroupe/index";
import MyTreeEtapeGroupe from "../../components/TreeEtapeGroupe/index";
import MyFormGroupe from "../../components/FormGroupe/index";

import { Breadcrumb, Row, Col, Card, Popover, Select, Button, Spin, Modal } from "antd";

import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
  SnippetsOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";

import { connect } from "react-redux";

import { saveGroupe, updateGroupe, deleteGroupe } from "../../store/actions/groupeAction";
import {
  saveParcoursClient,
  updateParcoursClient,
  deleteParcoursClient,
} from "../../store/actions/parcoursClientAction";

import { sortTree, sort, setChildren } from "../../utils/tools";

const { Option } = Select;

class Groupes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataEtapes: [],
      treeEtapes: [],
      dataGroupes: [],
      treeGroupes: [],
      idEtapeSelected: null,
      idGroupeSelected: null,
      groupeSelected: null,
      keyGroupeEtapeSelected: null,
      idCopy: null,
      loadingEtape: true,
      loadingGroupe: true,
      loadingOrdre: false,
      loadingConfirmation: false,
      loadingOption: false,
      createPopoverVisible: false,
      editPopoverVisible: false,
    };
  }

  componentDidMount() {
    this.getParcoursClients(this.props);
    this.getEtapes(this.props);
    this.getGroupes(this.props);
  }

  // UNSAFE_componentWillReceiveProps(props) {
  //   this.getParcoursClients(props);
  //   this.getEtapes(props);
  //   this.getGroupes(props);
  // }

  getParcoursClients = (props) => {
    const parcoursClients = [];

    props.parcoursClients.data.forEach((item) => {
      const newEtape = {
        etape_id: item.parcours_client_etape.etape_id,
        etape_libelle: item.parcours_client_etape.etape_libelle,
        etape_ordre: item.parcours_client_etape.etape_ordre,
        etape_code_couleur: item.parcours_client_etape.etape_code_couleur,
        etape_icone: item.parcours_client_etape.etape_icone,
        isLeaf: true,
        groupe_id: item.parcours_client_groupe.groupe_id,
        ordre: item.parcours_client_ordre,
        confirmation: item.parcours_client_confirmation,
        option: item.parcours_client_option,
        key: `${item.parcours_client_groupe.groupe_id}-${item.parcours_client_etape.etape_id}`,
      };
      parcoursClients.push(newEtape);
    });
    this.setState({ treeGroupes: parcoursClients, loadingGroupe: false });
  };

  getEtapes = (props) => {
    const dataEtapes = [];

    props.etapes.data.forEach((item) => {
      if (item.etape_id !== 0) {
        const etape = {
          etape_id: item.etape_id,
          etape_libelle: item.etape_libelle,
          etape_ordre: item.etape_ordre,
          etape_code_couleur: item.etape_code_couleur,
          etape_etape_parente: item.etape_etape_parente,
          etape_icone: item.etape_icone,
          level: item.level,
        };
        dataEtapes.push(etape);
      }
    });

    sortTree(dataEtapes);
    this.setState({ dataEtapes, loadingEtape: false }, () => {
      this.initTree();
    });
  };

  initTree = () => {
    const dataEtapes = this.state.dataEtapes;

    const niveau1 = sort(dataEtapes.filter((item) => item.level === 1));
    const niveau2 = sort(dataEtapes.filter((item) => item.level === 2));
    const niveau3 = sort(dataEtapes.filter((item) => item.level === 3));

    setChildren(niveau1, niveau2);
    setChildren(niveau2, niveau3);
    setChildren(niveau3, []);

    this.setState({ treeEtapes: niveau1, loading: false });
  };

  getGroupes = (props) => {
    const dataGroupes = [];

    props.groupes.data.forEach((item) => {
      const groupe = {
        groupe_id: item.groupe_id,
        groupe_libelle: item.groupe_libelle,
        groupe_alur: item.groupe_alur,
        groupe_long_terme: item.groupe_long_terme,
        groupe_court_terme: item.groupe_court_terme,
        groupe_visible: item.groupe_visible,
      };
      dataGroupes.push(groupe);
    });

    this.setState({ dataGroupes, loadingGroupe: false });
  };

  handleChangeGroupe = (value) => {
    const groupe = this.state.dataGroupes.find((item) => item.groupe_id === value);
    this.setState({ idGroupeSelected: value, groupeSelected: groupe });
  };

  handleCreateGroupe = (values) => {
    const jsonData = {
      groupe_libelle: values.libelleInput,
      groupe_alur: values.groupe_alur,
      groupe_long_terme: values.groupe_long_terme,
      groupe_court_terme: values.groupe_court_terme,
      groupe_etablissement_id: this.props.utilisateur.etablissement_id,
      groupe_visible: true,
    };

    this.props.saveGroupe(jsonData).then((res) => {
      this.state.dataGroupes.push(res);
      this.setState({
        dataGroupes: this.state.dataGroupes,
        createPopoverVisible: false,
      });
    });
  };

  handleEditGroupe = (values) => {
    const jsonData = {
      groupe_libelle: values.libelleInput,
      groupe_alur: values.groupe_alur,
      groupe_long_terme: values.groupe_long_terme,
      groupe_court_terme: values.groupe_court_terme,
      groupe_visible: values.groupe_visible,
    };

    this.props.updateGroupe(jsonData, this.state.idGroupeSelected).then((res) => {
      console.log(res, "res");
      this.setState((state) => {
        const dataGroupes = state.dataGroupes.map((item, i) => {
          if (item.groupe_id === res.groupe_id) {
            item.groupe_libelle = res.groupe_libelle;
            item.groupe_alur = res.groupe_alur;
            item.groupe_long_terme = res.groupe_long_terme;
            item.groupe_court_terme = res.groupe_court_terme;
            item.groupe_visible = res.groupe_visible;
            return item;
          } else {
            return item;
          }
        });
        return {
          dataGroupes,
          editPopoverVisible: false,
        };
      });
    });
  };

  confirm = () => {
    Modal.confirm({
      title: "Confirmation",
      content: "Voulez-vous supprimer ce groupe ?",
      okText: "Oui",
      cancelText: "Non",
      onOk: () => this.handleDeleteGroupe(),
    });
  };

  handleDeleteGroupe = () => {
    // Si le groupe à supprimer est déjà présent dans requetes...
    const groupeRequetes = this.props.requetes.data.find(
      function (requete) {
        if (requete.requete_groupe !== null) {
          return requete.requete_groupe.groupe_id === this.state.idGroupeSelected;
        }
      }.bind(this)
    );

    // On empêche sa suppression
    if (groupeRequetes === undefined) {
      let treeEtapesGroupes = [];
      treeEtapesGroupes = this.state.treeGroupes.filter((item) => {
        return item.groupe_id === this.state.idGroupeSelected;
      });

      treeEtapesGroupes.forEach((item) => {
        // action parcoursClientAction.js : this.props.deleteParcoursClient
        this.props.deleteParcoursClient(item.groupe_id, item.etape_id);
      });

      // action groupesAction.js : this.props.deleteParcoursClientAll
      // this.props.deleteParcoursClientAll(this.state.idGroupeSelected).then(
      // action groupesAction.js : this.props.deleteGroupe
      this.props
        .deleteGroupe(this.state.idGroupeSelected)
        .then((res) => {
          const newDataGroupes = this.state.dataGroupes.filter((item) => {
            return item.groupe_id !== res.groupe_id;
          });

          this.setState({
            dataGroupes: newDataGroupes,
          });
        })
        .catch((err) => console.log(err));
      this.handleChangeGroupe(null);
    } else {
      toast.error("Au moins une requête est rattachée à ce groupe...", {
        containerId: "A",
      });
    }
  };

  handleSelectEtape = (idEtape) => {
    if (!isNaN(parseInt(idEtape))) {
      this.setState({ idEtapeSelected: parseInt(idEtape) });
    } else {
      this.setState({ idEtapeSelected: null });
    }
  };

  handleSelectGroupeEtape = (keyEtape) => {
    this.setState({ keyGroupeEtapeSelected: keyEtape });
  };

  handleCreatePopoverVisibleChange = (createPopoverVisible) => {
    this.setState({ createPopoverVisible });
  };

  handleEditPopoverVisibleChange = (editPopoverVisible) => {
    this.setState({ editPopoverVisible });
  };

  addToGroupes = () => {
    let exists = this.props.parcoursClients.data.some(
      (item) =>
        item.parcours_client_etape.etape_id === this.state.idEtapeSelected &&
        item.parcours_client_groupe.groupe_id === this.state.idGroupeSelected
    );

    if (exists) return;

    if (this.state.idEtapeSelected) {
      const pc = this.props.parcoursClients.data.filter(
        (item) => item.parcours_client_groupe.groupe_id === this.state.idGroupeSelected
      );

      const jsonData = {
        parcours_client_groupe_id: this.state.idGroupeSelected,
        parcours_client_etape_id: this.state.idEtapeSelected,
        parcours_client_ordre: pc.length + 1,
        parcours_client_actif: true,
        parcours_client_confirmation: false,
      };

      this.props
        .saveParcoursClient(jsonData)

        // TODO : Etant donné que j'ai ajouté le UNSAFE_componentWillReceiveProps, je pense que cette partie n'est plus nécéssaire.
        // TODO : A l'ajout d'une étape dans un groupe on se retrouvait aevc un problème de key déjà existante et l'étape répétée plusieurs fois
        // TODO : Je pense que la partie commentée servait à mettre à jour le state pour l'ajout mais le UNSAFE_componentWillReceiveProps permet de le faire.
        // TODO : Et donc garder cette partie reviendrait à dupliquer l'ajout.
        /* ______________________Début du bloc
        .then((res) => {
          this.setState((state) => {
            const etape = state.dataEtapes.find(
              (etape) => etape.etape_id === res.parcours_client_etape.etape_id
            );
            const newEtape = {
              etape_id: etape.etape_id,
              etape_libelle: etape.etape_libelle,
              etape_ordre: etape.etape_ordre,
              etape_code_couleur: etape.etape_code_couleur,
              etape_icone: etape.etape_icone,
              isLeaf: etape.isLeaf,
              groupe_id: state.idGroupeSelected,
              ordre: pc.length + 1,
              key: `${state.idGroupeSelected}-${etape.etape_id}`,
            };
            console.log("treeGroupes", state.treeGroupes);
            const treeGroupes = state.treeGroupes.concat({});
            return {
              treeGroupes,
            };
          });
        })
        Fin du bloc__________________________________________________*/
        .catch((err) => console.log("Err:" + err));
    }
  };

  deleteFromGroupes = () => {
    if (this.state.keyGroupeEtapeSelected) {
      const etape = this.state.treeGroupes.find(
        (etape) => etape.key === this.state.keyGroupeEtapeSelected[0]
      );

      // Si l'étape à supprimer est déjà présente dans rendusAs...
      const etapeRendusAs = this.props.renduAs.data.find(
        (item) =>
          item.rendu_a_requete.requete_groupe_id === this.state.idGroupeSelected &&
          item.rendu_a_etape_id === etape.etape_id &&
          item.rendu_a_actif
      );

      // ...On empêche sa suppression
      if (etapeRendusAs === undefined) {
        this.props.deleteParcoursClient(this.state.idGroupeSelected, etape.etape_id).then((res) => {
          this.setState((state) => {
            const treeGroupes = state.treeGroupes.filter((item) => {
              return parseInt(item.key) !== parseInt(state.keyGroupeEtapeSelected);
            });
            return {
              treeGroupes,
            };
          });
          this.updateOrdre();
        });
      } else {
        toast.error("Une requête en cours est présente dans cette étape...", {
          containerId: "A",
        });
      }
    }
  };

  onTreeGroupesChange = (treeGroupes) => {
    treeGroupes.map((item, i) => {
      item.ordre = i + 1;
      return item;
    });
    // On n'oublie pas de remettre les etapes qui ne sont pas du groupe en cours
    const treeOtherGroupes = this.state.treeGroupes.filter(
      (item) => item.groupe_id !== this.state.idGroupeSelected
    );

    this.setState({ treeGroupes: treeGroupes.concat(treeOtherGroupes) });
  };

  onLoadingUpdate = () => {
    this.setState({ loadingOrdre: true }, () => this.updateOrdre());
  };

  updateOrdre = () => {
    let treeEtapesGroupes = [];
    treeEtapesGroupes = this.state.treeGroupes.filter((item) => {
      return item.groupe_id === this.state.idGroupeSelected;
    });

    treeEtapesGroupes = treeEtapesGroupes.sort((a, b) => a.ordre - b.ordre);

    for (let i = 0; i < treeEtapesGroupes.length; i++) {
      const jsonData = {
        parcours_client_groupe_id: treeEtapesGroupes[i].groupe_id,
        parcours_client_etape_id: treeEtapesGroupes[i].etape_id,
        parcours_client_ordre: treeEtapesGroupes[i].ordre === 0 ? i : i + 1,
        parcours_client_actif: true,
        parcours_client_confirmation: treeEtapesGroupes[i].confirmation,
        parcours_client_option: treeEtapesGroupes[i].option,
      };
      this.props
        .updateParcoursClient(
          jsonData,
          treeEtapesGroupes[i].groupe_id,
          treeEtapesGroupes[i].etape_id
        )
        .then(() => {
          if (i + 1 === treeEtapesGroupes.length) {
            this.setState({ loadingOrdre: false }, () =>
              toast.info("Validé !", { containerId: "A" })
            );
          }
        });
    }
  };

  onCopy = () => {
    this.setState({ idCopy: this.state.idGroupeSelected });
    toast.info("Copié !", { containerId: "A" });
  };

  onPaste = () => {
    if (!this.state.idCopy) {
      toast.error("Aucune copie en cours...", { containerId: "A" });
    } else {
      if (this.state.idGroupeSelected === this.state.idCopy) {
        toast.error("Copie dans le même groupe...", { containerId: "A" });
      } else {
        const copyTreeGroupes = [];
        this.state.treeGroupes.forEach((item) => {
          if (item.groupe_id === this.state.idCopy) {
            const clonedEtape = {
              etape_id: item.etape_id,
              etape_libelle: item.etape_libelle,
              etape_ordre: item.etape_ordre,
              etape_code_couleur: item.etape_code_couleur,
              etape_icone: item.etape_icone,
              isLeaf: true,
              groupe_id: this.state.idGroupeSelected,
              ordre: item.ordre,
              key: `${this.state.idGroupeSelected}-${item.etape_id}`,
            };
            const jsonData = {
              parcours_client_groupe_id: this.state.idGroupeSelected,
              parcours_client_etape_id: item.etape_id,
              parcours_client_ordre: 0,
              parcours_client_actif: true,
              parcours_client_confirmation: false,
            };
            this.props.saveParcoursClient(jsonData);
            copyTreeGroupes.push(clonedEtape);
          }
        });
        this.setState({
          treeGroupes: this.state.treeGroupes.concat(copyTreeGroupes),
        });
        toast.info("Collé !", { containerId: "A" });
      }
    }
  };

  onLoadingConfirmation = () => {
    this.setState({ loadingConfirmation: true });
    this.updateConfirmation();
  };

  onLoadingOption = () => {
    this.setState({ loadingOption: true });
    this.updateOption();
  };

  updateConfirmation = () => {
    let treeEtapesGroupes = [];
    treeEtapesGroupes = this.state.treeGroupes.filter((item) => {
      return item.groupe_id === this.state.idGroupeSelected;
    });

    treeEtapesGroupes.forEach((item, i) => {
      const jsonData = {
        parcours_client_groupe_id: item.groupe_id,
        parcours_client_etape_id: item.etape_id,
        parcours_client_ordre: item.ordre,
        parcours_client_actif: true,
        parcours_client_confirmation: false,
      };

      if (item.key === this.state.keyGroupeEtapeSelected[0]) {
        jsonData.parcours_client_confirmation = true;
      }

      this.props.updateParcoursClient(jsonData, item.groupe_id, item.etape_id);
    });
    this.setState({ loadingConfirmation: false });
  };

  updateOption = () => {
    let treeEtapesGroupes = [];
    treeEtapesGroupes = this.state.treeGroupes.filter((item) => {
      return item.groupe_id === this.state.idGroupeSelected;
    });

    treeEtapesGroupes.forEach((item, i) => {
      const jsonData = {
        parcours_client_groupe_id: item.groupe_id,
        parcours_client_etape_id: item.etape_id,
        parcours_client_ordre: item.ordre,
        parcours_client_actif: true,
        parcours_client_confirmation: false,
        parcours_client_option: false,
      };

      if (item.key === this.state.keyGroupeEtapeSelected[0]) {
        jsonData.parcours_client_option = true;
      }

      this.props.updateParcoursClient(jsonData, item.groupe_id, item.etape_id);
    });
    this.setState({ loadingOption: false });
  };

  render() {
    const {
      treeEtapes,
      dataGroupes,
      treeGroupes,
      idGroupeSelected,
      groupeSelected,
      keyGroupeEtapeSelected,
      loadingEtape,
      loadingGroupe,
      loadingOrdre,
      // loadingConfirmation,
      loadingOption,
      createPopoverVisible,
      editPopoverVisible,
    } = this.state;

    let treeEtapesGroupes = [];
    if (idGroupeSelected !== null) {
      treeEtapesGroupes = treeGroupes.filter((item) => {
        return item.groupe_id === idGroupeSelected;
      });
    }

    treeEtapesGroupes.sort(function (a, b) {
      return a.ordre - b.ordre;
    });

    const createGroupeContent = (
      <div>
        <MyFormGroupe groupeSelected={null} handleCreateGroupe={this.handleCreateGroupe} />
      </div>
    );

    const editGroupeContent = (
      <div>
        <MyFormGroupe groupeSelected={groupeSelected} handleEditGroupe={this.handleEditGroupe} />
      </div>
    );

    return (
      <div>
        <Breadcrumb style={{ margin: "16px 16px" }}>
          <Breadcrumb.Item>Paramètres</Breadcrumb.Item>
          <Breadcrumb.Item>Parcours Clients</Breadcrumb.Item>
          <Breadcrumb.Item>Groupes</Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={16}>
          <Col span={11}>
            <Spin spinning={loadingGroupe} size="large" tip="Chargement...">
              <Card title="Groupes" style={{ margin: "0 16px" }}>
                <Scrollbars autoHide style={{ height: 600, width: "100%" }}>
                  <div style={{ padding: 24, background: "#fff", minHeight: 600 }}>
                    <Row>
                      <Col span={18}>
                        <Select
                          showSearch
                          optionFilterProp="label"
                          style={{ width: "100%" }}
                          optionLabelProp="label"
                          onChange={this.handleChangeGroupe}
                        >
                          {dataGroupes &&
                            dataGroupes.map((item, i) => {
                              return (
                                <Option key={i} value={item.groupe_id} label={item.groupe_libelle}>
                                  <Row>
                                    <Col sm={8}>{item.groupe_libelle}</Col>
                                  </Row>
                                </Option>
                              );
                            })}
                        </Select>
                      </Col>
                      <Col span={2}>
                        <Popover
                          content={createGroupeContent}
                          overlayStyle={{ width: "300px" }}
                          title="Ajout"
                          trigger="click"
                          open={createPopoverVisible}
                          onOpenChange={this.handleCreatePopoverVisibleChange}
                        >
                          <Button style={{ marginLeft: "10px" }}>
                            <PlusOutlined />
                          </Button>
                        </Popover>
                      </Col>
                      {idGroupeSelected && (
                        <Col span={2}>
                          <Popover
                            content={editGroupeContent}
                            overlayStyle={{ width: "300px" }}
                            title="Edition"
                            trigger="click"
                            open={editPopoverVisible}
                            onOpenChange={this.handleEditPopoverVisibleChange}
                          >
                            <Button style={{ marginLeft: "10px" }}>
                              <EditOutlined />
                            </Button>
                          </Popover>
                        </Col>
                      )}
                      {idGroupeSelected && (
                        <Col span={2}>
                          <Button onClick={this.confirm} style={{ marginLeft: "10px" }}>
                            <DeleteOutlined />
                          </Button>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      {idGroupeSelected && (
                        <Col span={20} style={{ marginTop: "20px", padding: "10px" }}>
                          {treeEtapesGroupes.length > 0 && (
                            <MyTreeGroupe
                              treeGroupes={treeEtapesGroupes}
                              loadingOrdre={loadingOrdre}
                              onTreeGroupesChange={this.onTreeGroupesChange}
                              handleSelectGroupeEtape={this.handleSelectGroupeEtape}
                            />
                          )}
                        </Col>
                      )}
                      {idGroupeSelected && (
                        <Col span={4} style={{ marginTop: "20px" }}>
                          <CopyOutlined
                            onClick={this.onCopy}
                            style={{
                              fontSize: "32px",
                              margin: "30px 30px",
                              cursor: "pointer",
                            }}
                          />
                          <br />
                          <SnippetsOutlined
                            onClick={this.onPaste}
                            style={{
                              fontSize: "32px",
                              margin: "30px 30px",
                              cursor: "pointer",
                            }}
                          />
                          <br />
                          <Button
                            type="primary"
                            loading={loadingOrdre}
                            style={{ marginTop: "40px", width: "100%" }}
                            onClick={this.onLoadingUpdate}
                          >
                            Validation
                          </Button>
                          {/* <br />
                          <Button
                            type="primary"
                            loading={loadingConfirmation}
                            style={{
                              marginTop: "40px",
                              whiteSpace: "normal",
                              height: 50,
                            }}
                            disabled={!keyGroupeEtapeSelected}
                            onClick={this.onLoadingConfirmation}
                          >
                            Confirmation de contrat
                          </Button> */}
                          <br />
                          <Button
                            type="primary"
                            loading={loadingOption}
                            style={{
                              marginTop: "40px",
                              whiteSpace: "normal",
                              height: 50,
                            }}
                            disabled={!keyGroupeEtapeSelected}
                            onClick={this.onLoadingOption}
                          >
                            Option
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </div>
                </Scrollbars>
              </Card>
            </Spin>
          </Col>
          <Col span={2}>
            <div style={{ marginTop: "300px" }}>
              <ArrowLeftOutlined
                onClick={this.addToGroupes}
                style={{
                  fontSize: "26px",
                  margin: "30px 30px",
                  cursor: "pointer",
                }}
              />
              <br />
              <ArrowRightOutlined
                onClick={this.deleteFromGroupes}
                style={{
                  fontSize: "26px",
                  margin: "30px 30px",
                  cursor: "pointer",
                }}
              />
            </div>
          </Col>
          <Col span={11}>
            <Spin spinning={loadingEtape} size="large" tip="Chargement...">
              <Card title="Etapes disponibles" style={{ margin: "0 16px" }}>
                <Scrollbars autoHide style={{ height: 600, width: "100%" }}>
                  <div
                    style={{
                      padding: 24,
                      background: "#fff",
                      minHeight: 600,
                      display: "grid",
                    }}
                  >
                    {treeEtapes && idGroupeSelected && (
                      <MyTreeEtapeGroupe
                        treeEtapes={treeEtapes}
                        treeGroupes={treeEtapesGroupes}
                        handleSelectEtape={this.handleSelectEtape}
                      />
                    )}
                  </div>
                </Scrollbars>
              </Card>
            </Spin>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveParcoursClient: (parcoursClient) => dispatch(saveParcoursClient(parcoursClient)),
    updateParcoursClient: (parcoursClient, idG, idE) =>
      dispatch(updateParcoursClient(parcoursClient, idG, idE)),
    deleteParcoursClient: (idG, idE) => dispatch(deleteParcoursClient(idG, idE)),
    saveGroupe: (groupe) => dispatch(saveGroupe(groupe)),
    updateGroupe: (groupe, id) => dispatch(updateGroupe(groupe, id)),
    deleteGroupe: (id) => dispatch(deleteGroupe(id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    etapes: state.etapes,
    codesCouleurs: state.codesCouleurs,
    groupes: state.groupes,
    parcoursClients: state.parcoursClients,
    requetes: state.requetes,
    renduAs: state.renduAs,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Groupes);

import React, { Component } from "react";

import Constants from "../../../utils/constants";

import dayjs from "dayjs";

export default class ListeQuittancementPDF extends Component {
  render() {
    const { utilisateur, quittances } = this.props;

    const chemin = `${Constants.mode}://${Constants.domain}:${Constants.port}/api/groupement/display/${utilisateur.groupement_id}`;

    let width = 200;
    let height = 30;

    if (utilisateur.groupement_logo_width) width = utilisateur.groupement_logo_width;
    if (utilisateur.groupement_logo_height) height = utilisateur.groupement_logo_height;

    const total = quittances.reduce((a, b) => a + parseFloat(b.montant), 0);

    return (
      <div>
        {/* LOGO */}
        <div align="center">
          <img src={chemin} width={width} height={height} alt="" />
        </div>

        {/* TITRE DOCUMENT */}
        <div align="center" style={{ fontSize: 16 }}>
          <strong>{`LISTE RÉCAPITULATIVE DU QUITTANCEMENT DU ${dayjs().format(
            "DD/MM/YYYY"
          )}`}</strong>
        </div>

        {/* LIGNES QUITTANCES */}
        <table className="table-signature">
          <thead>
            <tr bgcolor="#afaeae">
              <th width="50%">
                <strong>Locataire</strong>
              </th>
              <th width="15%">
                <strong>Date début</strong>
              </th>
              <th width="15%">
                <strong>Date fin</strong>
              </th>
              <th width="20%" align="right">
                <strong>Montant</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            {quittances.map((ligne, i) => {
              return (
                <tr key={i} bgcolor={i % 2 === 0 ? "#ffffff" : "#eeeeee"}>
                  <td width="50%">{`${ligne.locataire.locataire_code} 
                  ${ligne.locataire.locataire_personne.personne_nom} 
                  ${ligne.locataire.locataire_personne.personne_prenom}`}</td>
                  <td width="15%">{`${dayjs(ligne.date_debut).format("DD/MM/YYYY")}`}</td>
                  <td width="15%">{`${dayjs(ligne.date_fin).format("DD/MM/YYYY")}`}</td>
                  <td width="20%" align="right">
                    {parseFloat(ligne.montant).toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <th width="50%" align="right">
                <strong>{`Total quittancement (${quittances.length} locataires)`}</strong>
              </th>
              <td width="15%"></td>
              <td width="15%"></td>
              <td width="20%" align="right">
                <strong>
                  {parseFloat(total).toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </strong>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }
}

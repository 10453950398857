import React, { Component } from "react";

import { Row, Steps } from "antd";

import FormLocation from "./FormLocation";
import FormPdf from "./FormPdf";
import FormTarif from "./FormTarif";

import dayjs from "dayjs";

class FormContrat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: 0,
    };
  }

  onChangeCurrent = (current) => {
    this.setState({ current });
  };

  render() {
    const {
      home,
      acompte,
      caution,
      fraisAgence,
      lots,
      locations,
      requete,
      requetesLots,
      comptes,
      journals,
      ecritures,
      location,
      locationQuittances,
      rubriques,
      isArchive,
      dossier,
      tags,
      utilisateur,
      parametre,
      templatesLangues,
      words,
      reglements,
      champsPersos,
      champsPersosGroupes,
      typesLots,
    } = this.props;
    const { current } = this.state;

    return (
      <div style={{ padding: 20 }}>
        <Row>
          <Steps
            type="navigation"
            size={home ? "small" : "middle"}
            items={[
              {
                title: `Détails location du ${dayjs(location.location_date_debut).format(
                  "DD/MM/YYYY"
                )} au ${dayjs(location.location_date_fin).format("DD/MM/YYYY")} (${dayjs(
                  location.location_date_fin
                ).diff(dayjs(location.location_date_debut), "days")} nuits)`,
              },
              {
                title: "Tarif de la location",
                subTitle: "par rubriques",
              },
              {
                title: "Quittances & Documents / Encaissement",
              },
            ]}
            current={current}
            onChange={this.onChangeCurrent}
            className="site-navigation-steps"
          />
        </Row>
        <Row gutter={12} style={{ padding: 10, display: current === 0 ? "block" : "none" }}>
          {location && (
            <FormLocation
              home={home}
              location={location}
              locationQuittances={locationQuittances}
              lots={lots}
              locations={locations}
              rubriques={rubriques}
              requete={requete}
              requetesLots={requetesLots}
              comptes={comptes}
              journals={journals}
              ecritures={ecritures}
              reglements={reglements}
              fraisAgence={fraisAgence}
              caution={caution}
              acompte={acompte}
              isArchive={isArchive}
              utilisateur={utilisateur}
              parametre={parametre}
              onChangeCurrent={this.onChangeCurrent}
              saveOccupants={this.props.saveOccupants}
              updateOccupants={this.props.updateOccupants}
              saveLocationLigne={this.props.saveLocationLigne}
              updateLocation={this.props.updateLocation}
              updateLocationLigne={this.props.updateLocationLigne}
              deleteLocationLigne={this.props.deleteLocationLigne}
              saveQuittance={this.props.saveQuittance}
              updateQuittance={this.props.updateQuittance}
              deleteQuittance={this.props.deleteQuittance}
              saveEcriture={this.props.saveEcriture}
              deleteReglement={this.props.deleteReglement}
              saveLocataireAffectation={this.props.saveLocataireAffectation}
              saveProprietaireAffectation={this.props.saveProprietaireAffectation}
              updateRequeteLot={this.props.updateRequeteLot}
              resync={this.props.resync}
              saveEmail={this.props.saveEmail}
            />
          )}
        </Row>
        <Row gutter={12} style={{ padding: 10, display: current === 1 ? "block" : "none" }}>
          <FormTarif
            home={home}
            location={location}
            locationQuittances={locationQuittances}
            lots={lots}
            rubriques={rubriques}
            requete={requete}
            requetesLots={requetesLots}
            comptes={comptes}
            journals={journals}
            ecritures={ecritures}
            reglements={reglements}
            fraisAgence={fraisAgence}
            caution={caution}
            acompte={acompte}
            isArchive={isArchive}
            utilisateur={utilisateur}
            parametre={parametre}
            typesLots={typesLots}
            templatesLangues={templatesLangues}
            onChangeCurrent={this.onChangeCurrent}
            saveOccupants={this.props.saveOccupants}
            updateOccupants={this.props.updateOccupants}
            saveLocationLigne={this.props.saveLocationLigne}
            updateLocation={this.props.updateLocation}
            updateLocationLigne={this.props.updateLocationLigne}
            deleteLocationLigne={this.props.deleteLocationLigne}
            saveQuittance={this.props.saveQuittance}
            updateQuittance={this.props.updateQuittance}
            deleteQuittance={this.props.deleteQuittance}
            saveQuittanceLigne={this.props.saveQuittanceLigne}
            updateQuittanceLigne={this.props.updateQuittanceLigne}
            saveEcriture={this.props.saveEcriture}
            deleteReglement={this.props.deleteReglement}
            saveLocataireAffectation={this.props.saveLocataireAffectation}
            saveProprietaireAffectation={this.props.saveProprietaireAffectation}
            updateRequeteLot={this.props.updateRequeteLot}
            resync={this.props.resync}
            saveEmail={this.props.saveEmail}
            saveMenage={this.props.saveMenage}
            saveLigneMatrice={this.props.saveLigneMatrice}
            updateLigneMatrice={this.props.updateLigneMatrice}
            deleteLigneMatrice={this.props.deleteLigneMatrice}
            savePrixBase={this.props.savePrixBase}
            updatePrixBase={this.props.updatePrixBase}
            deletePrixBase={this.props.deletePrixBase}
          />
        </Row>
        <Row gutter={12} style={{ padding: 10, display: current === 2 ? "block" : "none" }}>
          <FormPdf
            home={home}
            isArchive={isArchive}
            acompte={acompte}
            location={location}
            locationQuittances={locationQuittances}
            rubriques={rubriques}
            dossier={dossier}
            tags={tags}
            utilisateur={utilisateur}
            parametre={parametre}
            words={words}
            lots={lots}
            ecritures={ecritures}
            comptes={comptes}
            journals={journals}
            reglements={reglements}
            champsPersos={champsPersos}
            champsPersosGroupes={champsPersosGroupes}
            saveEmail={this.props.saveEmail}
            saveDocument={this.props.saveDocument}
            processWord={this.props.processWord}
            convertToPdf={this.props.convertToPdf}
            onChangeTab={this.props.onChangeTab}
            deleteDocument={this.props.deleteDocument}
            updateDocument={this.props.updateDocument}
            updateQuittance={this.props.updateQuittance}
            deleteQuittance={this.props.deleteQuittance}
            saveQuittanceLigne={this.props.saveQuittanceLigne}
            updateQuittanceLigne={this.props.updateQuittanceLigne}
            deleteQuittanceLigne={this.props.deleteQuittanceLigne}
            saveEcriture={this.props.saveEcriture}
            resync={this.props.resync}
            addPanesReglement={this.props.addPanesReglement}
            addPanesQuittancement={this.props.addPanesQuittancement}
          />
        </Row>
      </div>
    );
  }
}

export default FormContrat;

import {  
  SAVE_FAIL_QUITTANCES_LIGNES,
} from "../actions/quittanceLigneAction";

const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SAVE_FAIL_QUITTANCES_LIGNES:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: state.data,
        error: action.error,
      };
    default:
      return state;
  }
}
